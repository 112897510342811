import styled, { css } from 'styled-components';
import { SCREEN_SIZE, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SDiscoverContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  .title {
    ${mediaUpTo(
      'mobile',
      css`
        .custom-title-text {
          font-size: 20px;
        }
      `
    )}
  }
`;

export const SCardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaFrom(
    SCREEN_SIZE.desktop,
    css`
      flex-direction: row;
      gap: 8px;

      & > div {
        flex: 1 1 0px;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      gap: 25px;
    `
  )}
`;
