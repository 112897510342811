import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IResendCodeTextProps {
  resendCode: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ResendCodeText: React.FC<IResendCodeTextProps> = ({ resendCode }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'myInfo' });
  const { isDesktopSize } = useDeviceDimension();

  return (
    <CustomRow className="resend-code-container" flexDirection={isDesktopSize ? 'column' : 'row'} justifyContent="center" marginTop={20}>
      <BodyText className="text" textType="bodyText" textAlign="center" justifyContent="center" color="charcoal70" fontWeight="R" size="N" marginRight={isDesktopSize ? 0 : 6}>
        {t("Didn't receive verification code?")}
      </BodyText>

      <BodyText className="link" onClick={resendCode} cursorPointer display="inline" textType="bodyText" textAlign="start" color="blue" fontWeight="M" size="N">
        {t('Resend Code')}
      </BodyText>
    </CustomRow>
  );
};
