import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo, mediaUpToHeight } from 'utils/helpers/styleHelpers';

export const SListItemText = styled.div`
  display: flex;
  font-family: DM Sans, sans-serif;
  padding-right: 8px;
  margin-left: 8px;
  margin-top: 8px;
  line-height: 1.4;
  color: ${getColor('charcoal70')};

  &.charcoal-bold-text {
    font-weight: 500;
    color: ${getColor('charcoal')};
  }

  &::before {
    content: '\u2022';
    color: ${getColor('charcoal')};
    display: inline-block;
    margin-right: 13px;
  }

  ${mediaUpTo(
    380,
    css`
      ${mediaUpToHeight(
        680,
        css`
          & {
            font-size: 12px;
          }
        `
      )}
    `
  )}
`;

export const SMyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px 20px;
  margin-top: 20px;
  border-radius: 20px;
  background: ${getColor('white')};

  ${mediaFrom(
    'tablet',
    css`
      max-width: 580px;
      align-self: center;
      margin-top: 0;
    `
  )}

  ${mediaUpTo(
    400,
    css`
      margin-top: 10px;
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      margin-top: 0;
      padding: 8px 20px;
    `
  )}
`;

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 6px;

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;

    .web-title-container {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      margin-bottom: 8px;
      margin-top: 48px;
      max-width: 580px;

      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}
    }

    ${mediaFrom(
      'tablet',
      css`
        max-width: 580px;
        margin: 0 auto;
      `
    )}
  }

  ${mediaUpTo(
    400,
    css`
      .custom-title-text {
        font-size: 25px;
      }

      .card-header {
        padding: 16px 30px;
      }

      .my-details-status {
        & .custom-text-inner {
          font-size: 14px;
        }

        & .custom-title-text {
          font-size: 21px;
        }
      }
    `
  )}

  ${mediaUpTo(
    'desktop',
    css`
      padding-top: 0;
      padding-left: 5px;
      .icon-exitFlow {
        width: 20px;
        height: 20px;
      }
    `
  )}

  .primary-employer-name {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
  }

  .my-details-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-header {
      margin-bottom: 36px;
      margin-top: 10px;
    }
    &-items {
      margin-bottom: 30px;
      &-inner {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 24px;
        width: 100%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 40px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }

  .web-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 98px;
    margin-bottom: 40px;
  }

  .base-input-input::placeholder {
    @media screen and (max-width: 493px) {
      font-size: 15px !important;
    }

    @media screen and (max-width: 390px) {
      font-size: 14px !important;
    }
  }

  ${mediaUpTo(
    'desktop',
    css`
      .body {
        padding: 20px;
      }
      .custom-title-text {
        font-size: 24px;
      }
      .custom-text-inner {
        font-size: 11px;
      }

      .my-details-status {
        & .custom-text-inner {
          font-size: 14px;
        }

        & .custom-title-text {
          font-size: 20px;
        }
      }

      .card-header {
        padding: 16px 40px;
      }

      label {
        font-size: 13px;
        & + div {
          & input {
            font-size: 14px;
          }
        }
      }
    `
  )}
`;

export const SLink = styled.a`
  display: inline-flex;
  color: ${getColor('blue')};
  text-decoration: underline !important;
`;

export const SActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: 0 0 32px;
  padding: 0 24px;

  button {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const SModalContent = styled.div`
  padding: 32px 24px;
`;

export const SWebModalFooter = styled.div`
  background: ${getColor('charcoal5')} !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

export const SJointButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 32px 0;

  ${mediaFrom(
    'tablet',
    css`
      flex-direction: row-reverse;
      gap: 8px;

      button {
        height: 52px;
        padding: 14px 32px;
        max-width: 296px;
      }
    `
  )}
`;
