import React, { useEffect } from 'react';
import { images } from 'assets';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { ROUTES } from 'vars/const/ROUTES';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { TBreadcrumbsPath } from 'views/OpenCashAccount/StarterPage/CashAccOpeningDesktopPage/CashAccOpeningDesktopPage';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useLazyAprilByAppNameQuery } from 'store/user/users.api';
import { useAprilInit } from 'views/DoMore/use-april-init';
import { mobileApiCall } from 'services/mobileService';
import { lsGetItem } from 'utils/helpers/storage';
import { STaxPlannerPage, SList, SListItem, SBreadcrumbsWrapper } from './TaxPlanner.styles';

const pathList: TBreadcrumbsPath[] = [
  { id: 0, name: '', path: ROUTES.doMore.path },
  { id: 1, name: '' },
];

export const TaxPlanner = () => {
  const { t } = useTranslation('doMore');
  const [getAprilKey, aprilKeyResponse] = useLazyAprilByAppNameQuery();
  const initApril = useAprilInit();
  const isMobileApp = lsGetItem('isMobileApp');

  const handleOpenAprilWidget = () => {
    if (isMobileApp) {
      mobileApiCall('showApril', 'PAYCHECK_OPTIMIZATION');
    } else {
      getAprilKey('PAYCHECK_OPTIMIZATION');
    }
  };

  useEffect(() => {
    if (aprilKeyResponse.data?.token) {
      initApril(aprilKeyResponse.data?.token);
    }
  }, [aprilKeyResponse]);

  return (
    <STaxPlannerPage flexDirection="column" minHeight="100%">
      <SBreadcrumbsWrapper>
        <Breadcrumbs paths={pathList} hasBackNav />
      </SBreadcrumbsWrapper>
      <div className="top-circle" />
      <div className="add-needs-goals-page-header">
        <div className="top-img center-image">
          <img src={images.taxPlanner} alt="tax planner" />
        </div>

        <div className="add-needs-goals-page-title">
          <Title color="charcoal" font="Poppins" fontWeight="SB" size="XS" textAlign="start" className="title">
            {t('taxPlanner.Know what’s coming!')}
          </Title>
        </div>
        <div id="april" />
        <div>
          <BodyText className="description" marginBottom={32} textType="bodyText" size="M" fontWeight="R" color="charcoal70" marginTop={10} lineHeight="24px">
            {t('taxPlanner.Estimate your upcoming tax refund. Enter your preliminary data and get insights to help you manage your tax withholding and get a jump on the upcoming tax season.')}
          </BodyText>

          <BodyText className="options-title" marginBottom={48} textType="bodyText" size="M" color="charcoal70" marginTop="spacing-med" lineHeight="24px" extraStyles={{ width: '100%' }}>
            {t('taxPlanner.Tax Planner powered by')} <img src={images.aprilLogo} alt="april" />
          </BodyText>

          <BodyText fontWeight="SB" size="L" textAlign="start" marginBottom={16} justifyContent="start">
            {t('taxPlanner.Why use Tax Planner?')}
          </BodyText>

          <CustomCard background="white">
            <SList className="options">
              <SListItem>
                <Icon name="terms" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxPlanner.Get a reliable estimate of your tax liability so you can prepare accordingly." ns="doMore" components={{ Bold: <strong /> }} values="reliable estimate" />
                </BodyText>
              </SListItem>

              <SListItem>
                <Icon name="scales" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans
                    i18nKey="taxPlanner.Identify opportunities to reduce your tax liability and/or adjust your withholding based on your priorities."
                    ns="doMore"
                    components={{ Bold: <strong /> }}
                    values="reliable estimate"
                  />
                </BodyText>
              </SListItem>

              <SListItem>
                <Icon name="rocket" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans
                    i18nKey="taxPlanner.Get a jump on the filing process for the upcoming tax season -the information you enter now will carry over so you’ve got a head start come January!"
                    ns="doMore"
                    components={{ Bold: <strong /> }}
                    values="head start come January!"
                  />
                </BodyText>
              </SListItem>
            </SList>
          </CustomCard>
        </div>
      </div>
      <Button preset="blue-filled" size="xl" onClick={handleOpenAprilWidget} marginTop={48} marginBottom={48}>
        {t('GetStarted')}
      </Button>
      <div id="april" />
    </STaxPlannerPage>
  );
};
