import styled from 'styled-components';

export const SActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;

  button {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 32px;
    height: 52px;
  }

  .cancel-btn {
    max-width: 120px;
  }

  .set-btn {
    max-width: 300px;
    margin: 0 !important;
  }
`;
