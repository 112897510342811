import React from 'react';
import flags from 'country-flag-icons/react/3x2';
import clsx from 'clsx';
import { SLayout } from './CountryFlag.styles';

interface ICountryFlagProps {
  flagCode: string;
  title: string;
  props?: any;
  type?: string;
  width?: number | string;
  height?: number | string;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
}

export const CountryFlag: React.FC<ICountryFlagProps> = ({ flagCode = 'US', title, type = 'default', props, width = 40, height = 40, marginTop, marginRight, marginLeft, marginBottom }) => (
  <SLayout className={clsx('country-flag-wrapper', { 'type-circle': type === 'circle' })} marginTop={marginTop} marginLeft={marginLeft} marginRight={marginRight} marginBottom={marginBottom}>
    {/* @ts-ignore */}
    {flags[flagCode]({ title, style: { width, height }, ...props })}
  </SLayout>
);
