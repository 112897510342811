import { matchPath, useLocation } from 'react-router-dom';
import { ROUTES, IRouteConfig } from 'vars/const/ROUTES';

const DEFAULT_ROUTE_CONFIG: IRouteConfig = {
  path: '',
  title: '',
  isProfileButtonVisible: false,
  isBackButtonVisible: true,
  isTitleVisible: true,
  navbar: true,
  headerType: 'default',
  isIFramePage: false,
  layoutClassName: '',
};

export const useRouteConfig = (): IRouteConfig => {
  const { pathname } = useLocation();

  const routeConfig = Object.values(ROUTES).find((routeItem) => matchPath(routeItem.path, pathname)) || ({} as IRouteConfig);

  return { ...DEFAULT_ROUTE_CONFIG, ...routeConfig };
};
