import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { errorExclamationMarkImage } from 'assets/images';
import { Button } from 'components/theme/Button/Button';
import { SContentLayout } from 'views/Account/AccountInformation/Modals/closingAccount/GoalsHavingIssuesModal/GoalsHavingIssuesModal.styles';
import { IModalCommonProps } from 'views/Account/Summary/InternalTransfer/InternalTransferPage.types';
import { EAccountType } from 'store/user/accounts/accounts.types';

interface TModalProps extends IModalCommonProps {
  transferHandler: (accountType: string) => void;
  accountType: string;
  needsFiservId?: string;
  goalsFiservId?: string;
  cashFiservId?: string;
}

export const UnableToCloseNonCashModal = ({ open, onClose, transferHandler, accountType, needsFiservId, goalsFiservId, cashFiservId = '' }: TModalProps) => {
  const { t } = useTranslation('accountInformation');

  return (
    <CustomModal open={open} onClose={onClose} modalName="unableToCloseNonCashModal" topPosition="12%" closable closeIconColor="charcoal70" className="unableCloseModal">
      <SContentLayout>
        <div className="unableCloseModalInner">
          <img src={errorExclamationMarkImage} alt="sand-clock" />

          <Title size="M" fontWeight="M" marginBottom={18}>
            {accountType === EAccountType.SAVE ? t('accountInformation.Goals.WeAreUnableToClose') : t('accountInformation.Needs.WeAreUnableToClose')}
          </Title>

          <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5} marginBottom={32}>
            {accountType === EAccountType.SAVE ? t('accountInformation.Goals.YouHaveRemainingBalance') : t('accountInformation.Needs.YouHaveRemainingBalance')}
          </BodyText>

          <Button preset="blue-filled" size="xl" onClick={() => transferHandler(cashFiservId)}>
            {t('accountInformation.TransferMyCashAccount')}
          </Button>
          {accountType === EAccountType.SAVE && needsFiservId && (
            <Button preset="blue-outlined" size="xl" onClick={() => transferHandler(needsFiservId)} marginTop={20}>
              {t('accountInformation.Needs.TransferMyNeedsAccount')}
            </Button>
          )}
          {accountType === 'Stuff' && goalsFiservId && (
            <Button preset="blue-outlined" size="xl" onClick={() => transferHandler(goalsFiservId)} marginTop={20}>
              {t('accountInformation.Goals.TransferMyGoalsAccount')}
            </Button>
          )}
        </div>
      </SContentLayout>
    </CustomModal>
  );
};
