import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { api } from 'store/api';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { Loader } from 'components/general/Loader/Loader';
import { lsGetItem } from 'utils/helpers/storage';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SLink } from './SuttonDisclaimerNote.styles';

interface IDisclosureFull {
  isForExternalTransfer?: boolean;
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  footerTextType?: 'ExternalTransfers' | 'Sutton' | 'Full' | 'Onboarding' | 'BalancesTransaction';
  onOpen?: () => void;
}

export const SuttonDisclaimerNote = ({ isForExternalTransfer, marginBottom, marginTop, marginLeft, marginRight, footerTextType = 'BalancesTransaction', onOpen }: IDisclosureFull) => {
  const disclosureSheet = useToggle();
  const language = lsGetItem('lastLanguage');
  const { data, isLoading, refetch } = api.useGetSuttonDisclosureQuery();
  const { isDesktopSize } = useDeviceDimension();
  const footerType = isForExternalTransfer ? 'ExternalTransfers' : footerTextType;

  useEffect(() => {
    refetch();
  }, [language]);

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
      disclosureSheet.show();
    } else {
      disclosureSheet.show();
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BodyText
        className="sutton-disclaimer-note"
        textType="bodyText"
        color="charcoal70"
        size="T"
        fontWeight="R"
        lineHeight={1.4}
        paddingRight={5}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginTop={marginTop}
        marginRight={marginRight}
      >
        <Trans i18nKey={footerType} ns="footer" components={{ Link: <SLink onClick={handleOpen} /> }} />
      </BodyText>

      {data && <ConsentSheet key={data?.disclaimerId} consentData={data} isOpen={disclosureSheet.isActive} onClose={disclosureSheet.hide} isReadonly isSheet={!isDesktopSize} />}
    </>
  );
};
