import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { useSelector } from 'react-redux';
import { selectPolicies } from 'store/user/authentication.slice';
import { IAccountItem, isThirdPartyAccount, IThirdParty } from 'store/user/accounts/accounts.types';
import { Icon } from 'components/general/Icon/Icon';
import { TextButton } from 'components/general/TextButton/TextButton';
import { ExternalAccount } from 'components/general/Accounts/External/ExternalAccount';
import { InternalAccountsGroup } from 'components/general/Accounts/Internal/InternalAccountsGroup';
import { AccountsTypeSwitcher } from 'components/general/Accounts/AccountSelect/AccountsTypeSwitcher/AccountsTypeSwitcher';
import { InternalAccount } from 'components/general/Accounts/Internal/InternalAccount';
import { groupAccounts } from 'utils/helpers/accounts/accountsHelper';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useToggle } from 'utils/hooks/useToggle';
import { TAccountSourceType } from 'vars/types/accounts.types';
import { SAccountSelectDropdown, SButton } from './AccountSelectDropdown.styles';
import { NotAvailableSelectionWarning, TNotAvailableSelectionStatus } from './NotAvailableSelectionWarning';
import { AddNewExternalAccountModal } from './AddNewExternalAccountModal';

type IAccountSelectDropdownProps = {
  isFrom?: boolean;
  isDesktopSize?: boolean;
  internalAccounts: IAccountItem[];
  externalAccounts?: IThirdParty[];
  disabledExternalAccTypes?: string[];
  selected?: IAccountItem | IThirdParty | null;
  onSelect: (account: IAccountItem | IThirdParty) => void;
  disableId?: string;
  onAddNewAccount?: () => void;
  fromAccount?: IAccountItem | IThirdParty | null;
  hasSingleInternalAccount?: boolean;
  preopen?: TAccountSourceType;
};

export const AccountSelectDropdown = ({
  isFrom,
  isDesktopSize = false,
  externalAccounts = [],
  disabledExternalAccTypes = [],
  selected,
  disableId,
  onSelect,
  onAddNewAccount,
  internalAccounts,
  fromAccount,
  hasSingleInternalAccount,
  preopen,
}: IAccountSelectDropdownProps) => {
  const { navigate, generateUrl } = useEnhancedNav();
  const { t } = useTranslation('moveMoney');
  const [activeType, setActiveType] = useState<TAccountSourceType>(preopen || 'percapita');
  const policies = useSelector(selectPolicies);
  const isFromExternalAccount = !!fromAccount && isThirdPartyAccount(fromAccount);
  const isFromInternalAccount = !!fromAccount && !isThirdPartyAccount(fromAccount);
  const isFromCompanion = isFromInternalAccount && (fromAccount.isGoalsType || fromAccount.isNeedsType);
  const internalNotAvailableWarningType = isFromInternalAccount && hasSingleInternalAccount ? 'NO_INT_ACCOUNTS' : null;
  const shouldShowAccountGroups = isFrom || !fromAccount || (!internalNotAvailableWarningType && isFromInternalAccount);
  const shouldShowOnlyCashAccounts = !internalNotAvailableWarningType && isFromExternalAccount;
  const internalAccountsGroups = groupAccounts(internalAccounts);
  const cashAccounts = internalAccounts.filter((account) => account.isCashType).sort((account) => (account.isDefault ? -1 : 1));
  const { pathname } = useLocation();
  const isEnableExternalTransfer = pathname === ROUTES.transfers.path ? (isFromInternalAccount || !fromAccount) && !policies?.DisableExternalTransferOut : true;
  const isDisabledTransferToExternalCards = !isFrom && policies?.DisableExternalTransferOutCard;

  const addNewExternalModal = useToggle(false, { disabledAddCards: isDisabledTransferToExternalCards });

  const addExternalAccountSuccessUrl = generateUrl(ROUTES.transfers.path, { searchParams: { [isFrom ? 'open-from' : 'open-to']: 'external' } });

  const externalNotAvailableWarningType = useMemo<TNotAvailableSelectionStatus | null>(() => {
    if (externalAccounts.length === 0) {
      return 'NO_EXT_ACCOUNTS';
    }

    if (isFromExternalAccount) {
      return 'EXT_TO_EXT';
    }

    if (isFromCompanion) {
      return 'COMPANION_TO_EXT';
    }

    return null;
  }, [isFromExternalAccount, externalAccounts.length, isFromCompanion]);

  const handleAddNewAccount = () => {
    onAddNewAccount?.();
    if (isDesktopSize) {
      addNewExternalModal.setData({ disabledAddCards: isDisabledTransferToExternalCards });
      addNewExternalModal.show();
    } else {
      navigate(ROUTES.addExternalAccounts.path, {
        state: { addExternalAccountSuccessUrl },
        searchParams: { disableAddCards: isDisabledTransferToExternalCards },
      });
    }
  };

  return (
    <SAccountSelectDropdown>
      <div className="container">
        <div className="tabs">
          {isDesktopSize ? (
            <>
              <SButton
                preset={activeType === 'percapita' ? 'blue-filled' : 'clear'}
                size="middleStretch"
                type="button"
                className="active"
                isActive={activeType === 'percapita'}
                onClick={() => setActiveType('percapita')}
              >
                <Icon name="percapita" color={activeType === 'percapita' ? 'white' : 'charcoal70'} marginRight={8} />
                {t('transferPage.PercapitaAccounts')}
              </SButton>
              <SButton type="button" preset={activeType === 'external' ? 'blue-filled' : 'clear'} size="middleStretch" isActive={activeType === 'external'} onClick={() => setActiveType('external')}>
                <Icon name="externalTransfer" color={activeType === 'external' ? 'white' : 'charcoal70'} marginRight={8} />
                {t('transferPage.ExternalAccounts')}
              </SButton>
            </>
          ) : (
            <AccountsTypeSwitcher isDesktopSize={isDesktopSize} onChange={setActiveType} activeType={activeType} isExternalEnable={isFrom ? true : isEnableExternalTransfer} />
          )}
        </div>

        <div className="content">
          {activeType === 'percapita' && (
            <>
              {shouldShowOnlyCashAccounts &&
                cashAccounts.map((account) => (
                  <InternalAccount
                    key={account.fiservAccountId}
                    account={account}
                    isDesktop={isDesktopSize}
                    onSelect={onSelect}
                    selected={selected === account}
                    isDisabled={account?.fiservAccountId === disableId}
                    isFrom={isFrom}
                  />
                ))}
              {shouldShowAccountGroups &&
                internalAccountsGroups.map((group) => (
                  <InternalAccountsGroup
                    group={group}
                    key={group.cash?.fiservAccountId}
                    isDesktop={isDesktopSize}
                    onAccountSelect={onSelect}
                    selectedAccount={selected}
                    disableId={disableId}
                    isFrom={isFrom}
                  />
                ))}
              <NotAvailableSelectionWarning warningType={internalNotAvailableWarningType} />
            </>
          )}

          {activeType === 'external' && (policies?.ExternalTransferEnabled || isDesktopSize) && (
            <>
              {!externalNotAvailableWarningType &&
                (isDisabledTransferToExternalCards
                  ? externalAccounts
                      ?.filter((account) => account.thirdPartyAccountType !== 'CA')
                      .map((account) => (
                        <ExternalAccount
                          account={account}
                          key={account.thirdPartyAccountId}
                          onClick={onSelect}
                          isDesktop={isDesktopSize}
                          isSelected={selected === account || (isDesktopSize && account?.thirdPartyAccountId === disableId)}
                          isDisabled={account?.thirdPartyAccountId === disableId || disabledExternalAccTypes?.includes(account?.thirdPartyAccountType)}
                          isFrom={isFrom}
                        />
                      ))
                  : externalAccounts?.map((account) => (
                      <ExternalAccount
                        account={account}
                        key={account.thirdPartyAccountId}
                        onClick={onSelect}
                        isDesktop={isDesktopSize}
                        isSelected={selected === account || (isDesktopSize && account?.thirdPartyAccountId === disableId)}
                        isDisabled={account?.thirdPartyAccountId === disableId || disabledExternalAccTypes?.includes(account?.thirdPartyAccountType)}
                        isFrom={isFrom}
                      />
                    )))}
              <NotAvailableSelectionWarning warningType={externalNotAvailableWarningType} />
              {(!externalAccounts?.length || !externalNotAvailableWarningType) && (
                <TextButton iconName="circlePlus" className="add-new-account-btn" onClick={handleAddNewAccount}>
                  {t('manageExternalAccounts.AddNewAccount')}
                </TextButton>
              )}
            </>
          )}
        </div>
      </div>

      <AddNewExternalAccountModal
        isOpen={addNewExternalModal.isActive}
        onClose={addNewExternalModal.hide}
        onAddNewAccountSuccess={() => navigate(addExternalAccountSuccessUrl)}
        disabledAddCards={addNewExternalModal?.data?.disabledAddCards}
      />
    </SAccountSelectDropdown>
  );
};
