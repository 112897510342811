import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentAuthState, selectPolicies } from 'store/user/authentication.slice';
import { useSearchParams } from 'react-router-dom';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { PreFilledSection } from './PreFilledSection/PreFilledSection';
import { FindEmployerSection } from './FindEmployerSection/FindEmployerSection';
import { AccountDetailsSection } from './AccountDetailsSection/AccountDetailsSection';
import { SLayout, STitle, SDepositWrapper } from './SetUpDepositPage.styles';

export const SetUpDepositPage = () => {
  const { t } = useTranslation('deposit');
  const policies = useSelector(selectPolicies);
  const { systemProperties } = useSelector(selectCurrentAuthState);
  const [searchParams] = useSearchParams();
  const { isFromDesktopSize, fromDesktopSmall, isTabletSize } = useDeviceDimension();
  const { cashAccounts, defaultAccountsGroup } = useAccounts();
  const selectedCashAcc = cashAccounts.find((acc) => acc.accountId === searchParams.get('accountId')) || defaultAccountsGroup?.cash;

  return (
    <SLayout>
      {isFromDesktopSize && <PageHeader title={t('setUpDeposit.webTitle')} />}
      <SDepositWrapper>
        <div className="deposit-header">
          <div className="center-image">
            <img src={images.startDeposit} alt="Start Deposit" />
          </div>

          <STitle fontWeight="M" marginBottom={isFromDesktopSize ? 20 : 0} lineHeight={1.2}>
            {t('setUpDeposit.Title')}
          </STitle>
        </div>

        <div className="options">
          {policies?.TruvEnabled && selectedCashAcc && <FindEmployerSection accountId={selectedCashAcc?.accountId} />}
          {!isFromDesktopSize && <PreFilledSection accountId={selectedCashAcc?.accountId} />}
          {fromDesktopSmall && selectedCashAcc && <AccountDetailsSection accountId={selectedCashAcc.fiservAccountId} routingNumber={systemProperties.routingNumber} />}
        </div>

        {isFromDesktopSize && <PreFilledSection accountId={selectedCashAcc?.accountId} />}

        {(!isFromDesktopSize || isTabletSize) && selectedCashAcc && <AccountDetailsSection accountId={selectedCashAcc.fiservAccountId} routingNumber={systemProperties.routingNumber} />}
      </SDepositWrapper>
      <BodyText className="deposit-footer" textType="bodyText" color="charcoal70" fontWeight="R" size="T" marginTop={65} paddingBottom={40} paddingRight={10} lineHeight={1.3}>
        {t('setUpDeposit.Disclosure')}
      </BodyText>
    </SLayout>
  );
};
