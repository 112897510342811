import React from 'react';
import { SIcon, STitleContainer, STitleText } from './Title.styles';
import { ITitle } from './Title.types';

export const Title = ({
  children,
  icon,
  iconClassName = '',
  onClick,
  fontWeight = 'SB',
  size = 'S',
  color = 'charcoal',
  lineHeight,
  font = 'Poppins',
  textAlign = 'start',
  justifyContent = 'start',
  extraStyles,
  textTag = 'div',
  className,
  isCursorPointer = true,
  ...props
}: ITitle & React.HTMLAttributes<HTMLHeadElement>) => (
  <STitleContainer {...props} className={`title ${className || ''}`} extraStyles={extraStyles} justifyContent={justifyContent} size={size} isCursorPointer={isCursorPointer}>
    {icon && <SIcon className={iconClassName}>{icon}</SIcon>}
    <STitleText role="heading" font={font} textAlign={textAlign} fontWeight={fontWeight} color={color} as={textTag} lineHeight={lineHeight} className="custom-title-text" onClick={onClick}>
      {children}
    </STitleText>
  </STitleContainer>
);
