import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import exclamation from 'assets/images/exclamation.svg';
import { SAccountInformationModal } from 'views/Account/AccountInformation/SelectedAccountInformationPage.styles';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IDoubleCheckCloseAccountModal {
  isOpen: boolean;
  onClose: () => void;
  onClickHandler: () => void;
}

export const CashDoubleCheckCloseAccountModal: React.FC<IDoubleCheckCloseAccountModal> = ({ isOpen, onClose, onClickHandler }) => {
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize } = useDeviceDimension();
  return (
    <CustomSheet isModal={isDesktopSize} isOpen={isOpen} onClose={onClose} modalName="internalAccount_cashDounleCheckCloseAccountModal" padding="15px" closable={false} wrapperPadding>
      <SAccountInformationModal>
        <div className="center-image" style={{ marginBottom: '25px' }}>
          <img src={exclamation} alt="exclamation" />
        </div>
        <Title color="charcoal" fontWeight="M" font="Poppins" size="M" marginBottom={16}>
          {t('accountInformation.AreYouSureWantCloseCashAccount?')}
        </Title>

        <div className="doubleCheckListItems">
          <ul>
            <li className="doubleCheckListItemsInner">{t('accountInformation.TransferYourRemainingNeedsBalance')}</li>
            <li className="doubleCheckListItemsInner">{t('accountInformation.BeginClosureProcess')}</li>
            <li className="doubleCheckListItemsInner">{t('accountInformation.TransactionWillBeLimited')}</li>
          </ul>
        </div>
        <BodyText textType="bodyText" size="N" color="charcoal70" marginBottom={32}>
          {t('accountInformation.NoLongerAddMoney')}
        </BodyText>

        <div className="checkAccountModalFooter">
          <Button preset="red-outlined" size="xl" onClick={onClickHandler}>
            {t('accountInformation.CloseMyCashAccount')}
          </Button>

          <Button preset="red-filled" size="xl" onClick={onClose} marginTop={20}>
            {t('profile.Cancel')}
          </Button>
        </div>
      </SAccountInformationModal>
    </CustomSheet>
  );
};
