import React, { useRef } from 'react';
import { Chip } from 'components/general/Chip/Chip';
import { TIconName } from 'components/general/Icon/Icon.types';
import { useTranslation } from 'react-i18next';
import { SChipSelectorContainer } from './ChipSelector.styles';

type TChipItem = {
  id: number;
  name: string;
  icon: string;
};

interface IChipSelector {
  onSelectedCard: (selectedId: number) => void;
  selectedCardId: number;
}

export const ChipSelector: React.FC<IChipSelector> = ({ onSelectedCard, selectedCardId }) => {
  const { t } = useTranslation('percapitaPay');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const chipItems: TChipItem[] = [
    { id: 1, name: t('percapitaPayHome.Time Card'), icon: 'circleTime' },
    { id: 2, name: t('percapitaPayHome.Request History'), icon: 'recentPayment' },
  ];

  const handleItemClick = (id: number, currentTarget: HTMLElement) => {
    onSelectedCard(id);

    if (containerRef.current) {
      const parentElement = containerRef.current;
      const parentElementWidth = parentElement?.clientWidth;
      const accountItemWidth = currentTarget?.clientWidth;

      const elementLeft = currentTarget?.offsetLeft;
      const scrollLeft = Math.max(0, elementLeft - (Number(parentElementWidth) - accountItemWidth) / 2);

      parentElement?.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  return (
    <SChipSelectorContainer ref={containerRef}>
      {chipItems.map(({ id, name, icon }) => (
        <Chip type="light" iconName={icon as TIconName} isActive={selectedCardId === id} onClick={(event: React.MouseEvent) => handleItemClick(id, event?.currentTarget as HTMLElement)} key={id}>
          <span className="body-text">{name}</span>
        </Chip>
      ))}
    </SChipSelectorContainer>
  );
};
