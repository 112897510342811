import React from 'react';
import { BodyText } from 'components/general/Typography';
import { SCustomRow, SBodyText, SCol1Row } from './CommonRow.styles';

interface ICommonRowProps {
  col1Title?: string;
  col1Description: string;
  col2: string | React.ReactNode;
  isLast?: boolean;
}

export const CommonRow = ({ col1Title, col1Description, col2, isLast = false }: ICommonRowProps) => (
  <SCustomRow isLast={isLast} alignItems="start">
    <SCol1Row flexDirection="column" alignItems="start">
      <BodyText size="T" fontWeight="M" lineHeight={1.6}>
        {col1Title}
      </BodyText>
      <BodyText size="T" fontWeight="R" color="charcoal70" lineHeight={1.6}>
        {col1Description}
      </BodyText>
    </SCol1Row>
    <SBodyText size="N" fontWeight="B" textAlign="start" lineHeight={1.6}>
      {col2}
    </SBodyText>
  </SCustomRow>
);
