import { generatePath, useLocation, useNavigate } from 'react-router-dom';

interface IUrlOptions {
  params?: any;
  searchParams?: any;
}

interface INavOptions extends IUrlOptions {
  backUrl?: string | 'current';
  state?: any;
  replace?: boolean;
}

export const useEnhancedNav = () => {
  const originalNavigate = useNavigate();
  const location = useLocation();
  const backPage = location.state?.backPage;

  const generateUrl = (url: string, options: IUrlOptions = { params: {}, searchParams: {} }) => {
    const path = generatePath(url, options.params);
    const search = new URLSearchParams(options.searchParams).toString();
    return `${path}${search ? '?' : ''}${search}`;
  };

  const navigate = (url: string, options: INavOptions = { backUrl: '', state: {}, params: {}, searchParams: {}, replace: false }) => {
    originalNavigate(generateUrl(url, { params: options.params, searchParams: options.searchParams }), {
      replace: options.replace,
      state: { backPage: options.backUrl === 'current' ? location.pathname : options.backUrl, ...options.state },
    });
  };

  return { navigate, generateUrl, backPage };
};
