import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { usersApi } from 'store/user/users.api';
import { RootState } from 'store/store';
import { TFiltersParams } from 'views/NotificationsCenter/NotificationsCenterPage.types';
import { SORT_VARIABLES } from 'views/NotificationsCenter/constants';
import { INotificationsResponse } from './notificationsCenter.types';

interface INotificationsCenterState {
  userNotifications: INotificationsResponse | null;
  filterParams: TFiltersParams;
  notificationFilterString?: string;
  notificationOrderString: string;
  notificationIsSearchVisible: boolean;
  notificationSearch: string;
}

export const initialNotificationsState: INotificationsCenterState = {
  userNotifications: null,
  filterParams: {
    filterBy: {
      read: false,
      unread: false,
      deleted: false,
      fromDate: null,
      toDate: null,
    },
    sortBy: SORT_VARIABLES[0],
  },
  notificationFilterString: "(status eq 'Read' or status eq 'New')",
  notificationOrderString: 'CreatedDate desc',
  notificationIsSearchVisible: false,
  notificationSearch: '',
};

export const notificationsCenterSlice = createSlice({
  name: 'notificationsCenter',
  initialState: initialNotificationsState,
  reducers: {
    setNotificationFilterString(state, { payload }: PayloadAction<string | undefined>) {
      state.notificationFilterString = payload;
    },
    setOrderString(state, { payload }: PayloadAction<string>) {
      state.notificationOrderString = payload;
    },
    setNotificationFilterParams(state, { payload }: PayloadAction<TFiltersParams>) {
      state.filterParams = payload;
    },
    setNotificationIsSearchVisible(state, { payload }: PayloadAction<boolean>) {
      state.notificationIsSearchVisible = payload;
    },
    setNotificationSearch(state, { payload }: PayloadAction<string>) {
      state.notificationSearch = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.getNotifications.matchFulfilled, (state, { payload }: PayloadAction<INotificationsResponse>) => {
      state.userNotifications = payload;
    });
  },
});

export const selectUserNotifications = (state: RootState) => state.notificationsCenter;
export const { setNotificationFilterString, setOrderString, setNotificationFilterParams, setNotificationIsSearchVisible, setNotificationSearch } = notificationsCenterSlice.actions;
