import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { CountriesList } from 'views/MoveMoney/InternationalTransferPage/CountriesList/CountriesList';
import { Button } from 'components/theme/Button/Button';
import allCountryFlagsData from 'views/MoveMoney/InternationalTransferPage/allCountryFlagsData.json';
import { ICountry } from 'views/MoveMoney/InternationalTransferPage/types';
import { useSetSurveyAnswerMutation } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { SSelectCountryRequestBlock } from './SelectYourCountryRequest.styles';

type TIds = {
  [key in 'surveyId' | 'surveyQuestionId']: string;
};

interface ISelectYourCountryRequestProps extends TIds {
  showRequestSubmitModal: () => void;
}

export const SelectCountryRequestBlock = ({ surveyId, surveyQuestionId, showRequestSubmitModal }: ISelectYourCountryRequestProps) => {
  const { t } = useTranslation('moveMoney');
  const [setSurveyAnswer, setSurveyAnswerResult] = useSetSurveyAnswerMutation();
  const [selectedCountry, setSelectCountry] = useState<ICountry>({} as ICountry);

  const handleSubmitCountry = () => {
    if (surveyId && surveyQuestionId && selectedCountry) {
      setSurveyAnswer({
        id: surveyId,
        data: {
          answers: [
            {
              surveyQuestionId,
              userAnswer: selectedCountry.title,
            },
          ],
        },
      })
        .unwrap()
        .then(() => showRequestSubmitModal());
    } else {
      console.log('countryRequestParams = ', { surveyId, surveyQuestionId });
    }
  };

  return (
    <SSelectCountryRequestBlock className="select-your-country-request-section">
      {setSurveyAnswerResult.isLoading && <Loader />}

      <Title size="L" fontWeight="M" marginBottom={16}>
        {t('internationalTransferPage.SelectYourCountryRequest')}
      </Title>

      <BodyText size="N" color="charcoal70" lineHeight={1.4} marginBottom={24}>
        {t('internationalTransferPage.SelectTheCountryYouWouldLikeToSee')}
      </BodyText>
      <CountriesList countries={allCountryFlagsData} onSelect={setSelectCountry} isOnlyMainLanguage />

      <Button className="button-submit" preset="blue-filled" disabled={!selectedCountry?.code} onClick={handleSubmitCountry}>
        {t('internationalTransferPage.SubmitRequest')}
      </Button>
    </SSelectCountryRequestBlock>
  );
};
