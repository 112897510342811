import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'utils/hooks/useToggle';
import { Icon } from 'components/general/Icon/Icon';
import { Link, useNavigate } from 'react-router-dom';
import { useChat } from 'utils/hooks/useChat';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { INotificationItem } from 'store/user/notificationsCenter/notificationsCenter.types';
import { selectUserNotifications } from 'store/user/notificationsCenter/notificationsCenter.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ProfileSettingsDropdown } from 'views/Profile/ProfileSettings/ProfileSettingsDropdown';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { SHeader, SCircle } from './DesktopHeader.styles';

export const DesktopHeader = () => {
  const { open } = useChat();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const { isDesktopSize } = useDeviceDimension();
  const profileSettingsMenu = useToggle(false);
  const userFullName = `${currentUser?.firstName?.[0]?.toUpperCase() ?? ''}${currentUser?.lastName?.[0]?.toUpperCase() ?? ''}`.trim();
  const { userNotifications } = useSelector(selectUserNotifications);
  const unreadNotifications = useMemo(() => {
    return userNotifications?.results.filter((item: INotificationItem) => item.status === 'New') || [];
  }, [userNotifications]);
  const profileWrapperRef = useRef(null);

  const handleNotificationsClick = () => {
    navigate(ROUTES.notificationsCenter.path);
  };

  const handleProfileIconClick = () => {
    if (isDesktopSize) {
      profileSettingsMenu.toggle();
    } else {
      navigate(ROUTES.profileSettings.path);
    }
  };

  const buttonChat = (
    <button className="chat" type="button" onClick={open}>
      {t('main.chat')}
    </button>
  );

  const buttonNotifications = (
    <button className="notifications" type="button" onClick={handleNotificationsClick}>
      {unreadNotifications.length ? <span>{unreadNotifications.length}</span> : null}
    </button>
  );

  useClickOutside(profileWrapperRef, profileSettingsMenu.hide);

  return (
    <SHeader className="main-header desktop-header">
      <h1>
        <Link to={ROUTES.home.path}>Percapita</Link>
      </h1>
      <div className="flex-view">
        <SCircle className="rounded">{buttonChat}</SCircle>
        <SCircle>{buttonNotifications}</SCircle>
        <div className="profile-btn-wrapper" ref={profileWrapperRef}>
          <button className={clsx('profile', { active: profileSettingsMenu.isActive })} type="button" onClick={handleProfileIconClick}>
            {userFullName || <Icon size="small" name="profile" color="white" cursorPointer />}
          </button>
          <ProfileSettingsDropdown isOpen={profileSettingsMenu.isActive} />
        </div>
      </div>
    </SHeader>
  );
};
