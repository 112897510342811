import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { BodyText, Title } from 'components/general/Typography';
import { ChipLanguageSwitcher } from 'components/general/ChipLanguageSwitcher/ChipLanguageSwitcher';
import { selectPolicies } from 'store/user/authentication.slice';
import { LogoutItem } from 'components/navigation/MainMenu/MenuItem/LogoutItem/LogoutItem';
import { useGetMenuDataQuery } from 'store/api';
import { IMenuItemData } from 'vars/types/menu.types';
import { MenuGroup } from 'components/navigation/MainMenu/MenuItem/MenuGroup';
import { MenuItem } from 'components/navigation/MainMenu/MenuItem/MenuItem';
import { Loader } from 'components/general/Loader/Loader';
import { SWrapper } from './ProfileSettings.styles';

export const ProfileSettings = () => {
  const { t } = useTranslation(['profile', 'preRegOnboarding']);
  const policies = useSelector(selectPolicies);
  const { isLoading, isFetching, data } = useGetMenuDataQuery();
  const profileMenuData = data?.popupProfile;

  return (
    <SWrapper>
      {(isLoading || isFetching) && <Loader />}
      <Title font="Poppins" fontWeight="SB" size="M" color="charcoal">
        {t('profile.ProfileSettings')}
      </Title>
      <CustomCard>
        {profileMenuData?.map((menuItem: IMenuItemData) =>
          menuItem.children && menuItem.children.length ? (
            <MenuGroup title={menuItem.text} items={menuItem.children} icon={menuItem.icon} key={menuItem.index} isProfileSettings />
          ) : (
            <MenuItem title={menuItem.text} description={menuItem.description} icon={menuItem.icon} path={menuItem.path} key={menuItem.index} isProfileSettings />
          )
        )}
      </CustomCard>
      <CustomCard>
        <BodyText textType="bodyText" marginBottom={20} size="M" color="charcoal" fontWeight="R">
          {t('preRegOnboarding.Language Preference', { ns: 'preRegOnboarding' })}
        </BodyText>
        <ChipLanguageSwitcher defaultValue="en" isDisabled={!policies?.SpanishEnabled} />
      </CustomCard>
      <CustomCard className="logout-btn">
        <LogoutItem />
      </CustomCard>
    </SWrapper>
  );
};
