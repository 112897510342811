import { Rule } from 'antd/lib/form';
import i18next from 'i18next';
import { validatePhoneLength } from 'utils/helpers/phoneNumber';
import { differenceInYears, isAfter, isValid } from 'date-fns';
import { parseDate } from './date';

// /\S{1}/ tests for at least a single non whitespace character.
export const getRequiredRule = (message: string, spaceRules?: boolean): Rule => ({
  required: true,
  message: i18next.t(message),
  pattern: spaceRules === true ? /^\S+$|(^\S.+\S+$)/ : /\S{1}/,
});

export const initialValueValidator = (initialValue: string, message?: string): Rule => ({
  validator: async (_, value) => {
    if (value && initialValue === value) return Promise.reject(new Error(i18next.t(message ?? 'defaultValidationMessages.TheInitialValueAndTheCurrentValue')));
    return Promise.resolve();
  },
});

export const minLengthRule = (length: number | undefined = 8, message?: string) => ({
  min: length,
  message: message ?? i18next.t('myInfo.Minimum8Characters', { ns: 'cashAccountOpening' }),
});

export const maxLengthRule = (length: number | undefined = 30, message?: string) => ({
  max: length,
  message: message ?? i18next.t('myInfo.Maximum30Characters', { ns: 'cashAccountOpening' }),
});

export const passwordMinLength = (message: string | undefined = 'Minimum 8 characters') => minLengthRule(8, message);
export const passwordMaxLength = (message: string | undefined = 'Maximum 60 characters') => maxLengthRule(60, message);

export const passwordRules: Rule[] = [
  {
    type: 'string',
    required: true,
    message: 'Input password',
  },
  passwordMinLength(),
  passwordMaxLength(),
  {
    type: 'string',
    pattern: /^(?=.*[A-Z]).+$/,
    message: 'At least 1 capital letter (A-Z)',
  },
  {
    type: 'string',
    pattern: /(?=.*\d)/,
    message: 'At least 1 number (0-9)',
  },
  {
    type: 'string',
    pattern: /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
    message: 'At least 1 special character (!$%@&#?+-_)',
  },
];

export const getDateRule = (): Rule => ({
  type: 'string',
  required: true,
  validator: async (_, value) => {
    if (!value) {
      return Promise.reject(new Error(i18next.t('preRegOnboarding.Please input date', { ns: 'preRegOnboarding' })));
    }
    return Promise.resolve();
  },
});

export const dateFormatRule = (): Rule => ({
  type: 'string',
  pattern: /^\d{2}\/\d{2}\/\d{4}$/,
  message: i18next.t('preRegOnboarding.Date should be in MM/DD/YYYY format', { ns: 'preRegOnboarding' }),
});

export const fullNameCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[a-zA-Z0-9.'\-\s]*$/,
  message: message ?? i18next.t('defaultValidationMessages.FirstName'),
});

export const usernameCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[a-zA-Z0-9'._\-!#^~$@+]{0,300}$/,
  message: message ?? i18next.t('defaultValidationMessages.Username'),
});

export const getEmailRulesOptional = (): Rule => ({
  type: 'email',
  message: i18next.t('profile.Please give a valid email'),
});

export const getEmailRules = (): Rule[] => [
  {
    required: true,
    message: i18next.t('profile.Please input your email address'),
  },

  getEmailRulesOptional(),
];

export const addressCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[a-zA-Z0-9.'\-#@%&/ ]*$/,
  message: message ?? i18next.t('defaultValidationMessages.Address'),
});

export const zipCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[a-zA-Z0-9- ]*$/,
  message: message ?? i18next.t('defaultValidationMessages.Zip'),
});

export const stateCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[a-zA-Z. ]*$/,
  message: message ?? i18next.t('defaultValidationMessages.State'),
});

export const employerNameCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[^<>]*$/,
  message: message ?? i18next.t('defaultValidationMessages.Employer'),
});

export const accountNameCharacterRule = (message?: string): Rule => ({
  type: 'string',
  pattern: /^[^<>]*$/,
  message: message ?? i18next.t('defaultValidationMessages.AccountName'),
});

export const getRequiredCityRule = (): Rule => ({
  type: 'string',
  required: true,
  message: i18next.t('profile.Please input city'),
});

export const retypePasswordRules = (matchField: string): Rule[] => [
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (getFieldValue(matchField) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Passwords must match'));
    },
  }),
];

export const getPhoneValidator = (errorMessage: string, minLength = 11): Rule => ({
  validator: async (_, value) => {
    if (value || value === 0) {
      if (!validatePhoneLength(value, minLength)) {
        throw new Error(i18next.t(errorMessage));
      }
    }
  },
});

export const ageValidator = (): Rule => ({
  validator: async (_, value) => {
    if (value.split('/').slice(-1)[0] > 2000 && differenceInYears(new Date(), new Date(value)) < 16) {
      return Promise.reject(new Error(i18next.t('preRegOnboarding.AtLeast16YearsOld')));
    }

    return Promise.resolve();
  },
});

export const searchValidator = (callback?: (value: string) => void): Rule => ({
  validator: async (_, value) => {
    const isValueValid = /^[^&<>]*$/.test(value);

    if (!isValueValid) {
      return Promise.reject(new Error(i18next.t('defaultValidationMessages.Search')));
    }

    callback?.(value);
    return Promise.resolve();
  },
});

export const noSideWhitespacesRule = (): Rule => ({
  type: 'string',
  pattern: /^\S+$|(^\S.+\S+$)/,
  message: i18next.t('preRegOnboarding.No whitespaces in the beginning or in the end', { ns: 'preRegOnboarding' }),
});

export const ageDateRule: Rule = {
  transform: (value: string | Date) => value && (typeof value === 'string' ? parseDate(value) : value),
  validator: async (_, value) => {
    if (value && !isValid(value)) {
      return Promise.reject(new Error(i18next.t('preRegOnboarding.Incomplete or wrong date', { ns: 'preRegOnboarding' })));
    }
    if (value && isAfter(value, new Date())) {
      return Promise.reject(new Error(i18next.t('preRegOnboarding.This date is in future', { ns: 'preRegOnboarding' })));
    }
    return Promise.resolve();
  },
};

export const ageInputValidator = (): Rule[] => [getDateRule(), dateFormatRule(), ageDateRule, ageValidator()];
