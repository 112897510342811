import styled from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { getColor } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SCustomCard = styled(CustomCard)`
  background-color: ${getColor('blue5')};
`;
