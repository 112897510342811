import styled, { css } from 'styled-components';
import { getColor, MEDIA_SIZE, mediaUpTo, mediaFrom } from 'utils/helpers/styleHelpers';
import { cash, goal, stash, circleQuestion } from 'assets/icons';
import { BodyText } from 'components/general/Typography';

export const SCircle = styled.div`
  width: 48px;
  height: 48px;
  background: ${getColor('charcoal5')};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 12px;
`;

export const SContainer = styled.div`
  position: relative;

  .select-account {
    display: none;
    width: 100%;

    &__control {
      position: relative;
      cursor: pointer;
      padding: 12px;
      border: 2px solid transparent;
      border-radius: 16px;

      // TODO a11y: hovered and focused states should be visually distinguishable,
      // and chevron color change is not really sufficient for that
      &--is-focused,
      &:hover {
        box-shadow: none;
        border-color: ${getColor('blue')};
      }

      &--is-focused .select-account__indicator {
        background-color: ${getColor('blue')};
      }
    }

    &__placeholder {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin: 0;
      color: ${getColor('charcoal70')};
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      display: flex;
      align-items: center;
      margin-right: 12px;
      padding: 0;
      font-family: 'DM Sans';
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${getColor('charcoal')};
    }

    &__value-container .account-value {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &__value-container .account-value > div {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }

    &__value-container .account-value::before,
    &__placeholder::before {
      content: '';
      display: block;
      width: 48px;
      aspect-ratio: 1;
      margin-right: 12px;
      border-radius: 50%;
      background-color: ${getColor('charcoal5')};
    }

    &__value-container .account-value::after,
    &__placeholder::after {
      content: '';
      position: absolute;
      top: 12px;
      left: 12px;
      width: 24px;
      aspect-ratio: 1;
      mask: url(${circleQuestion}) no-repeat center/contain;
      background-color: ${getColor('charcoal70')};
    }

    &__value-container .account-value[data-type]::before {
      background-color: ${getColor('green10')};
    }

    &__value-container .account-value[data-type]::after {
      mask-image: url(${cash});
      background-color: ${getColor('green')};
    }

    &__value-container .account-value[data-type='Save']::after {
      mask-image: url(${goal});
    }

    &__value-container .account-value[data-type='Stuff']::after {
      mask-image: url(${stash});
    }

    &__value-container .account-value .amount {
      font-weight: 500;
    }

    &__indicator > svg {
      display: none;
    }

    &__menu {
      z-index: 2;
      margin-block: 4px 0;
      border-radius: 20px;
      border: 1px solid ${getColor('charcoal5')};
      box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
    }

    &__menu-list {
      border-radius: inherit;
      padding: 0;
    }

    &__menu-list .account-option {
      position: relative;
      display: flex;
      align-items: center;
      padding: 20px;
      background-color: ${getColor('white')};
      font-family: 'DM Sans';
      font-size: 14px;
      cursor: pointer;
    }

    &__menu-list .account-option:hover {
      background-color: ${getColor('charcoal5')};
    }

    &__menu-list .account-option:not(:last-child) {
      border-bottom: 2px solid ${getColor('charcoal5')};
    }

    &__menu-list .account-option[data-type]::before {
      content: '';
      width: 24px;
      aspect-ratio: 1;
      margin-right: 12px;
      mask: url(${cash}) no-repeat center/contain;
      background-color: ${getColor('orange')};
    }

    &__menu-list .account-option[data-type='Save']::before {
      mask-image: url(${goal});
    }

    &__menu-list .account-option[data-type='Stuff']::before {
      mask-image: url(${stash});
    }

    &__menu-list .account-option .name {
      font-weight: 700;
      color: ${getColor('charcoal')};
    }

    &__menu-list .account-option .id {
      color: ${getColor('charcoal70')};
    }

    &__menu-list .account-option .badge {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 16px;
      border-bottom-left-radius: 20px;
      background-color: ${getColor('blue10')};
      font-size: 12px;
      font-weight: 700;
      color: ${getColor('blue')};
    }
  }

  button.account {
    appearance: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 16px;
    background-color: ${getColor('white')};
    font-family: 'DM Sans';
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${getColor('charcoal')};
    text-align: left;
    cursor: pointer;
    transition: border-color 100ms;

    // TODO a11y: hovered and focused states should be visually distinguishable,
    // and chevron color change is not really sufficient for that
    &:is(:focus, :hover) {
      border-color: ${getColor('blue')};
    }

    &:focus::after {
      background-color: ${getColor('charcoal')};
    }

    &::before {
      display: none;
    }

    &[data-selected]::before {
      display: inline-block;
      margin-right: 10px;
      mask: url(${cash}) no-repeat center/contain;
      background-color: ${getColor('orange')};
    }

    &[data-selected]:disabled::before {
      background-color: ${getColor('charcoal30')};
    }

    &[data-selected][data-type='Save']::before {
      mask-image: url(${goal});
    }

    &[data-selected][data-type='Stuff']::before {
      mask-image: url(${stash});
    }

    &:disabled::after {
      display: none;
    }

    &:disabled {
      color: #c2c1c1;
      cursor: not-allowed;
      border-color: transparent;
    }

    & > div.account-div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      height: 40px;
    }

    .action {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: ${getColor('charcoal40')};

      ${mediaFrom(
        428,
        css`
          font-size: 14px;
        `
      )}

      ${mediaFrom(
        'desktop',
        css`
          display: block;
        `
      )}
    }

    .details,
    .action {
      flex: 1;
    }

    .details {
      .name {
        font-weight: 700;
      }

      ${mediaUpTo(
        420,
        css`
          font-size: 12px;
        `
      )}
    }

    &[data-selected] .action {
      display: none;
    }

    .amount {
      ${mediaUpTo(
        420,
        css`
          font-size: 12px;
        `
      )}
      &.external {
        color: ${getColor('charcoal40')};
        text-align: end;
        display: none;

        ${mediaFrom(
          1200,
          css`
            display: block;
          `
        )}
      }
    }
  }

  @media screen and (min-width: ${MEDIA_SIZE.tablet + 1}px) {
    &::before {
      display: inline-block;
      margin-left: 10px;
      mask: url(${circleQuestion}) no-repeat center/contain;
      background-color: ${getColor('charcoal70')};
    }

    button.account {
      padding: 10px;

      .action {
        color: ${getColor('grey5')} !important;
      }
    }
  }
`;

interface ISAccountBadge {
  isDesktop?: boolean;
}

export const SBodyText = styled(BodyText)`
  height: 100%;
  align-items: start;
`;

export const SAccountBadge = styled.div<ISAccountBadge>`
  position: absolute;
  top: 3px;
  left: 3px;
  background: ${getColor('blue30')};
  color: ${getColor('charcoal')};
  font-family: 'DM Sans';
  border-radius: 12px 4px;
  padding: 8px 16px;
  font-size: 12px;

  ${mediaFrom(
    'tablet',
    css`
      position: absolute;
      top: 3px;
      left: 3px;
      background: ${getColor('blue20')};
      color: ${getColor('charcoal')};
      border-radius: 8px;
      padding: 8px 16px;
      font-family: 'DM Sans';
      font-size: 12px;
      font-weight: 700;
    `
  )}
`;
