import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VisaLogo } from 'assets/logos';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { useToggle } from 'utils/hooks/useToggle';
import { RemoveCardSheet } from 'views/MoveMoney/ManageCardsPage/RemoveCardSheet/RemoveCardSheet';
import { isExpired } from 'utils/helpers/dateHelpers';
import { SCard, SExpiredCard } from './ManageableCard.styles';

interface IManageableCard {
  account: IAccountItem;
  onAdd: () => void;
  onRemove: () => void;
}

export const ManageableCard: React.FC<IManageableCard> = ({ account, onRemove, onAdd }) => {
  const { t } = useTranslation('moveMoney');
  const removeCardSheet = useToggle(false);

  const cardExpired = isExpired(account?.debitCardExpiration);

  return (
    <>
      {cardExpired ? (
        <SExpiredCard>
          <div className="expired">{t('internationalTransferPage.selectableCard.Expired')}</div>
          <div className="remove" onClick={removeCardSheet.show}>
            <BodyText textType="bodyText" color="red" size="T" fontWeight="M" font="Poppins">
              {t('internationalTransferPage.selectableCard.Remove')}
            </BodyText>
            <Icon name="trash" color="red" size="smaller" cursorPointer />
          </div>
          <img className="emv-chip" src={images.emvChipExpired} alt="Chip" />
          {account?.lastFourDigits && <div className="card-number-holder">**** **** **** {account.debitCardNumber}</div>}
          <VisaLogo className="visa-logo" color="#FAF6ED" />
        </SExpiredCard>
      ) : (
        <SCard>
          {account?.isAddedToReadyRemit ? (
            <>
              <div className="added-to-international-transfers">{t('internationalTransferPage.selectableCard.AddedToInternationalTransfer')}</div>
              <div className="remove" onClick={removeCardSheet.show}>
                <BodyText textType="bodyText" color="red" size="T" fontWeight="M" font="Poppins">
                  {t('internationalTransferPage.selectableCard.Remove')}
                </BodyText>
                <Icon name="trash" color="red" size="smaller" cursorPointer />
              </div>
            </>
          ) : (
            <div className="edit" onClick={onAdd}>
              <BodyText textType="bodyText" color="blue" size="T" fontWeight="M" font="Poppins">
                {t('internationalTransferPage.selectableCard.Add')}
              </BodyText>
              <Icon name="edit" color="blue" size="smaller" cursorPointer />
            </div>
          )}

          <img className="emv-chip" src={images.emvChip} alt="Chip" />
          {account?.lastFourDigits && <div className="card-number-holder">**** **** **** {account.debitCardNumber}</div>}
          <VisaLogo className="visa-logo" color="white" />
        </SCard>
      )}

      <BodyText textType="bodyText" color={cardExpired ? 'grey50' : 'charcoal'} fontWeight="R" size="N">
        {account.nickname ? (
          <Trans i18nKey="internationalTransferPage.DebitCardName" ns="moveMoney" values={{ name: account.nickname }} />
        ) : (
          t(`internationalTransferPage.${account.isPrimary ? 'PrimaryCashAccount' : 'SecondaryCashAccount'}`)
        )}
      </BodyText>

      <RemoveCardSheet isOpen={removeCardSheet.isActive} onClose={removeCardSheet.hide} onRemove={onRemove} />
    </>
  );
};
