import styled from 'styled-components';

export const SContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

export const SWebContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    margin-top: 32px;
  }

  button {
    min-width: 164px !important;
    max-width: 180px !important;
    padding: 14px 40px;

    font-size: 18px;
    font-weight: 500;
  }
`;
