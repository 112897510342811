import { API_ROUTES } from 'vars/const/API_ROUTES';
import { IRiskSessionResponse, ITransferExternalAccountRequest, IBankNameRequest } from 'vars/types/ingo.types';
import { api } from 'store/api';

export const ingoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIframeUrl: builder.query<string, void>({
      query: () => ({
        url: API_ROUTES.ingo.iframeUrl,
        method: 'GET',
        responseHandler: (response) => response.text(),
      }),
    }),
    getIframeUrlForAltAddress: builder.mutation<string, string>({
      query: (userAlternativeAdressId: string) => ({
        url: API_ROUTES.ingo.iframeUrl,
        method: 'POST',
        body: { userAlternativeAdressId },
        responseHandler: (response) => response.text(),
      }),
    }),
    checkAltAddress: builder.mutation<boolean, string>({
      query: (tracerToken: string) => ({
        url: API_ROUTES.ingo.checkAlternativeAddress,
        method: 'POST',
        body: { tracerToken },
      }),
    }),
    activateAltAddress: builder.mutation<void, string>({
      query: (addressId: string) => ({
        url: API_ROUTES.ingo.activateAlternativeAddress,
        method: 'POST',
        body: { id: addressId },
      }),
    }),
    requestBankName: builder.mutation({
      query: (data: IBankNameRequest) => ({
        url: API_ROUTES.ingo.requestBankName,
        method: 'POST',
        body: data,
      }),
    }),
    transferToExternalAccount: builder.mutation({
      query: (data: ITransferExternalAccountRequest) => ({
        url: API_ROUTES.ingo.transferToExternalAccount,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Accounts'],
    }),
    transferFromExternalAccount: builder.mutation({
      query: (data: ITransferExternalAccountRequest) => ({
        url: API_ROUTES.ingo.transferFromExternalAccount,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Accounts'],
    }),
    getRiskSession: builder.query<IRiskSessionResponse, void>({
      query: () => ({
        url: API_ROUTES.ingo.riskSession,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetIframeUrlQuery, useTransferToExternalAccountMutation, useRequestBankNameMutation, useTransferFromExternalAccountMutation, useLazyGetRiskSessionQuery } = ingoApi;
