import React from 'react';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { HeadRow } from './HeadRow/HeadRow';
import { CommonRow } from './CommonRow/CommonRow';
import { SLayout, STopicList } from './TransactionLimitsPage.styles';
import { TopicCard } from './TopicCard/TopicCard';

export const TransactionLimitsPage = () => {
  const { t } = useTranslation('accountInformation');

  return (
    <SLayout>
      <Title font="Poppins" size="M" fontWeight="SB" color="charcoal" marginBottom={35} marginTop={5}>
        {t('transactionLimits.Title')}
      </Title>

      <STopicList>
        <TopicCard title={t('transactionLimits.DepositingFundsToAccount​')} isCollapsible>
          <HeadRow col1={t('transactionLimits.TransactionType​')} col2={t('transactionLimits.FrequencyDollarAmountLimit')} />
          <CommonRow
            col1Description={t('transactionLimits.DirectDepositsInstitution')}
            col2={
              <>
                {t('transactionLimits.NoMaximumDollarLimit')}
                <br />
                {t('transactionLimits.NoFrequencyLimit')}
              </>
            }
          />

          <CommonRow
            col1Description={t('transactionLimits.ExternalAccountTransferAppACHInbound')}
            col2={
              <>
                {t('transactionLimits.ExternalAccountTransferAppACHLimit')}
                <br />
                {t('transactionLimits.ExternalAccountTransferACHFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Description={t('transactionLimits.ExternalAccountTransferAppCardInbound')}
            col2={
              <>
                {t('transactionLimits.ExternalAccountTransferAppCardLimit')}
                <br />
                {t('transactionLimits.ExternalAccountTransferCardFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Description={t('transactionLimits.MobileCheckCaptureDescription')}
            col2={
              <>
                {t('transactionLimits.MobileCheckCaptureLimit')}
                <br />
                {t('transactionLimits.MobileCheckCaptureFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Description={t('transactionLimits.InternalTransferCashGoals')}
            col2={
              <>
                {t('transactionLimits.InternalTransferCashGoalsLimit')}
                <br />
                {t('transactionLimits.InternalTransferCashGoalsFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Description={t('transactionLimits.PeerToPeerTransferInbound')}
            col2={
              <>
                {t('transactionLimits.PeerToPeerTransferInboundLimit')}
                <br />
                {t('transactionLimits.PeerToPeerTransferInboundWeeklyLimit')}
              </>
            }
            isLast
          />
        </TopicCard>
        <TopicCard title={t('transactionLimits.WithdrawalsAndTransactionLimits​')} isCollapsible>
          <HeadRow col1={t('transactionLimits.WithdrawalType')} col2={t('transactionLimits.FrequencyDollarAmountLimit')} />
          <CommonRow
            col1Title={t('transactionLimits.POSPurchaseTransactions')}
            col1Description={t('transactionLimits.POSPurchaseTransactionsDescription')}
            col2={
              <>
                {t('transactionLimits.POSPurchaseLimit')}
                <br />
                {t('transactionLimits.InternalTransferCashGoalsFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Title={t('transactionLimits.ATMWithdrawalsCashbackPOS')}
            col1Description={t('transactionLimits.ATMWithdrawalsCashbackPOSDescription')}
            col2={
              <>
                {t('transactionLimits.ATMWithdrawalsLimit')}
                <br />
                {t('transactionLimits.InternalTransferCashGoalsFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Title={t('transactionLimits.PreauthorizedDebits')}
            col1Description={t('transactionLimits.PreauthorizedDebitsDescription')}
            col2={
              <>
                {t('transactionLimits.PreauthorizedDebitsLimit')}
                <br />
                {t('transactionLimits.InternalTransferCashGoalsFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Title={t('transactionLimits.BillPay')}
            col1Description={t('transactionLimits.BillPayDescription')}
            col2={
              <>
                {t('transactionLimits.BillPayLimit')}
                <br />
                {t('transactionLimits.InternalTransferCashGoalsFrequencyLimit')}
              </>
            }
          />
          <CommonRow
            col1Title={t('transactionLimits.ExternalAccountTransfer')}
            col1Description={t('transactionLimits.ExternalAccountTransferDescription')}
            col2={
              <>
                {t('transactionLimits.ExternalAccountTransferLimit')}
                <br />
                {t('transactionLimits.ExternalAccountTransferDailyLimit')}
                <br />
                {t('transactionLimits.ExternalAccountTransferMonthlyLimit')}
              </>
            }
          />
          <CommonRow
            col1Title={t('transactionLimits.PeerToPeerTransfersOutbound')}
            col1Description={t('transactionLimits.PeerToPeerTransfersOutboundDescription')}
            col2={
              <>
                {t('transactionLimits.PeerToPeerTransfersOutboundLimit')}
                <br />
                {t('transactionLimits.PeerToPeerTransfersOutboundWeeklyLimit')}
              </>
            }
          />
        </TopicCard>
      </STopicList>
    </SLayout>
  );
};
