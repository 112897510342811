import { BodyText, Title } from 'components/general/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { Icon } from 'components/general/Icon/Icon';
import { useAccounts } from 'utils/hooks/useAccounts';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/general/Loader/Loader';
import { useToggle } from 'utils/hooks/useToggle';
import { useRemoveCardMutation } from 'store/user/users.api';
import { SLayout } from './ManageCardsPage.styles';
import { ManageableCard } from './ManageableCard/ManageableCard';
import { ValidateCardSheet } from './ValidateCardSheet/ValidateCardSheet';

export const ManageCardsPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('moveMoney');

  const { cashAccounts, refetchInternal, isLoading } = useAccounts();
  const [removeCardAPI, removeCardAPIResult] = useRemoveCardMutation();

  const [selectedAccount, setSelectedAccount] = useState<IAccountItem | undefined>();
  const validateCardSheet = useToggle(false);

  const handleAdd = (account: IAccountItem) => {
    setSelectedAccount(account);
    validateCardSheet.show();
  };

  const handleRemove = (account: IAccountItem) => {
    removeCardAPI({ percapitaAccountId: account?.accountId, last4: account?.debitCardNumber });
  };

  useEffect(() => {
    if (removeCardAPIResult.isSuccess) refetchInternal();
  }, [refetchInternal, removeCardAPIResult.isSuccess]);

  if (isLoading || removeCardAPIResult.isLoading) return <Loader />;

  return (
    <>
      <SLayout>
        <div>
          <header>
            <Icon cursorPointer name="arrowLeft" color="blue" size="big" onClick={() => navigate(-1)} />
            <Title>{t('internationalTransferPage.Cards')}</Title>
          </header>

          <div className="subtitle">
            <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={24}>
              {t('internationalTransferPage.YouCanManage')}
            </BodyText>
          </div>

          <div className="cards-container">
            {cashAccounts
              ?.filter((account: IAccountItem) => account.debitCardNumber)
              .map((account: IAccountItem) => (
                <ManageableCard key={account.accountId} account={account} onRemove={() => handleRemove(account)} onAdd={() => handleAdd(account)} />
              ))}
          </div>
        </div>

        <SuttonDisclaimerNote />
      </SLayout>
      <ValidateCardSheet isOpen={validateCardSheet.isActive} onClose={validateCardSheet.hide} selectedAccount={selectedAccount} onSuccess={refetchInternal} />
    </>
  );
};
