import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { BodyText } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SCardLink } from './CardLink.styles';

interface ICardLink {
  onClick: () => void;
  title: string;
  name?: string;
  amount?: number;
  startIcon: JSX.Element;
}

export const CardLink: React.FC<ICardLink> = ({ title, name = '', amount, startIcon, onClick }) => {
  const { isDesktopSize } = useDeviceDimension();

  return (
    <SCardLink onClick={onClick}>
      <div className="title-container">
        <div className="start-icon-container">{startIcon}</div>
        <div>
          <BodyText size={isDesktopSize ? 'M' : 'N'} fontWeight={isDesktopSize ? 'B' : 'R'} color={isDesktopSize ? 'charcoal70' : 'charcoal'} textType="bodyText" cursorPointer>
            {title}
          </BodyText>

          {!isDesktopSize && name && (
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
              {name}
            </BodyText>
          )}
        </div>
      </div>
      <div className="amount-container">
        {amount !== undefined && (
          <CustomAmount
            multiSizable
            amount={amount}
            size={isDesktopSize ? 'xs' : 'smallerStrong'}
            remainingSize={isDesktopSize ? 'xs' : 'smallerStrong'}
            remainingWeight={700}
            cursorPointer
            color={amount < 0 ? 'red' : 'charcoal'}
          />
        )}
        <Icon onClick={onClick} name="arrowRight" color="blue" size="smallest" cursorPointer className="bottom-arrow" />
      </div>
    </SCardLink>
  );
};
