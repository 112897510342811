import styled, { css } from 'styled-components';
import { ifProp, mediaFrom, flex } from 'utils/helpers/styleHelpers';
import { Button } from 'components/theme/Button/Button';

export const SLayout = styled.div<{ isDesktopSize: boolean }>`
  .checkbox-custom.remember-this-device {
    ${flex('column')};
    padding-top: 24px;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${ifProp('isDesktopSize', 'column', 'row')};
    gap: 5px;
    flex-wrap: wrap;

    ${mediaFrom(
      'tablet',
      css`
        margin-top: 24px;
      `
    )}
  }
`;

export const SButtonWrapper = styled.div<{ marginTop: number }>`
  ${mediaFrom(
    'tablet',
    css<{ marginTop: number }>`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: ${(props) => `${props?.marginTop}px`};

      button {
        min-width: 149px !important;
        max-width: 180px !important;
        padding: 14px 32px;

        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-weight: 600;
      }
    `
  )}
`;

export const SContinueBtn = styled(Button)`
  margin: 24px 0 16px;

  ${mediaFrom(
    'mobile',
    css`
      margin: 64px 0 32px;
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      margin: 0;
    `
  )}
`;
