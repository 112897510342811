import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from 'components/general/Icon/Icon';
import { Loader } from 'components/general/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useSelector } from 'react-redux';
import { usePayments } from 'utils/hooks/usePayments';
import { useToggle } from 'utils/hooks/useToggle';
import { selectDisplayPercapitaPayModal, setHeaderTitle, setShowPercapitaPayModal } from 'store/ui.slice';
import { paymentsApi } from 'store/user/payments/payments.api';
import { clearPaymentStatus, selectPaymentsAccountsData, setSelectedAccount } from 'store/user/payments/payments.slice';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useLazyGetImmediatePayFrameUrlQuery, useLazyGetWalletAccountsQuery } from 'store/user/accounts/accounts.api';
import { selectAccountsData } from 'store/user/accounts/accounts.slice';
import { PaymentPrepareSheet } from 'views/PercapitaPay/PaymentRequest/PaymentPrepareSheet';
import { PaymentRequestSheet } from 'views/PercapitaPay/PaymentRequest/PaymentRequestSheet';
import { PaymentErrorModal } from 'views/PercapitaPay/PaymentRequest/PaymentErrorModal';
import { PercapitaPayMain } from 'components/general/PercapitaPayMain/PercapitaPayMain';
import { useAppDispatch } from 'utils/hooks/store';
import { useTranslation } from 'react-i18next';
import { PaymentSuccessModal } from 'views/PercapitaPay/PaymentRequest/PaymentSuccessModal';
import { BodyText, Title } from 'components/general/Typography';
import { useLazyGetImmediateConsentsQuery, useUpdateConsentByPolicyMutation, useLazyGetEulaPolicyQuery } from 'store/user/users.api';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { Button } from 'components/theme/Button/Button';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { WebHeader } from 'views/PercapitaPay/WebHeader/WebHeader';
import { PaymentPrepareModal } from 'views/PercapitaPay/PaymentRequest/PaymentPrepareModal';
import { PaymentConfirmModal } from 'views/PercapitaPay/PaymentRequest/PaymentConfirmModal';
import { Tooltip } from 'components/general/Tooltip/Tooltip';
import { TransferItem } from './components/TransferItem/TransferItem';
import { SLayout, SBlock, SIFrame, SIframeCloseButton, STransferBlock, SCustomButton } from './PercapitaPayMainPage.styles';
import { PayPeriodTooltipContent } from './components/PayPeriodTooltipContent';

export const PercapitaPayMainPage = () => {
  const { t } = useTranslation('percapitaPay');
  const [policyId, setPolicyId] = useState('');
  const [policyText, setPolicyText] = useState('');
  const [isCheckboxAgreed, setIsCheckboxAgreed] = useState(false);
  const [isPaymentRequestSheetStillOpen, setIsPaymentRequestSheetStillOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { displayPercapitaPayModal, percapitaPayModalType } = useSelector(selectDisplayPercapitaPayModal);
  const { immediatePayFrameUrl } = useSelector(selectAccountsData);
  const { paymentsInfo, getPaymentsInfoQueryResult } = usePayments();
  const iframeModal = useToggle();
  const prepareSheet = useToggle();
  const reviewSheet = useToggle();
  const termsSheet = useToggle();
  const infoTooltip = useToggle();
  const navigate = useNavigate();
  const [preparePayment, preparePaymentResult] = paymentsApi.usePreparePaymentMutation();
  const [requestPayment, requestPaymentResult] = paymentsApi.useRequestPaymentMutation();
  const getAccountsQuery = paymentsApi.useGetPaymentAccountsQuery();
  const [getWalletAccounts] = useLazyGetWalletAccountsQuery();
  const [getImmediateConsents] = useLazyGetImmediateConsentsQuery();
  const [getEulaPolicyAPI, { isFetching: isEulaPolicyFetching }] = useLazyGetEulaPolicyQuery();
  const [updateConsentAPI] = useUpdateConsentByPolicyMutation();
  const [getImmediatePayFrameUrl] = useLazyGetImmediatePayFrameUrlQuery();
  const { isDesktopSize } = useDeviceDimension();

  const {
    accountId,
    additionalData,
    amountSelected,
    amountToTransfer,
    checkDate,
    checkSum,
    deliveryPeriod,
    estimatedDate,
    fee,
    feePayer,
    holiday,
    paymentType,
    pushUserId,
    selectedAccount,
    hasError,
    paymentSucceed,
    accounts,
    uuid,
  } = useSelector(selectPaymentsAccountsData);

  const handleAcceptTerms = async () => {
    await updateConsentAPI(policyId);
    termsSheet.hide();
  };

  const getEulaPolicy = async () => {
    const policy = await getEulaPolicyAPI().unwrap();
    setPolicyText(policy.text);
    setPolicyId(policy.id);
  };

  const getConsents = async () => {
    const immediateConsents = await getImmediateConsents().unwrap();
    if (immediateConsents?.hasActiveConsents === false) {
      getEulaPolicy();
      termsSheet.show();
    }
  };

  const handleChangeAgree = () => {
    setIsCheckboxAgreed((preVal) => !preVal);
  };

  const maxPayPeriodTransactionsCount = Number(paymentsInfo.maxPayPeriodTransactionsCount);
  const transfersAvailable = Number(paymentsInfo.transfersAvailable);
  const earnedThisCycle = Number(paymentsInfo.earnedThisCycle);
  const isActive = earnedThisCycle > 0;
  const completedTransfers = paymentsInfo?.transferredAmount > 0 ? maxPayPeriodTransactionsCount - transfersAvailable : 0;

  const handleViewHistoryClick = () => {
    navigate(ROUTES.percapitaPayHistory.path, { state: 2 });
  };

  const handleAddAccountClick = useCallback(() => {
    if (!immediatePayFrameUrl) return;
    prepareSheet.hide();
    iframeModal.show();
  }, [immediatePayFrameUrl]);

  const handleInfoClick = () => {
    if (isDesktopSize) {
      infoTooltip.show();
      return;
    }

    infoTooltip.hide();
    dispatch(setShowPercapitaPayModal({ displayPercapitaPayModal: true, percapitaPayModalType: 'payPeriod' }));
  };

  const handleRequestPayment = () => {
    const body = {
      accountId,
      additionalData,
      amountSelected,
      amountToTransfer,
      checkDate,
      checkSum,
      deliveryPeriod,
      estimatedDate,
      fee,
      feePayer,
      holiday,
      paymentType,
      pushUserId,
      uuid,
      transactionId: uuidv4(),
    };

    requestPayment(body);
  };

  const handleCloseStatusModal = () => {
    prepareSheet.hide();
    dispatch(clearPaymentStatus());
  };

  const handleCloseIframe = () => {
    iframeModal.hide();
    prepareSheet.show();
    getAccountsQuery.refetch();
  };

  const handleTransferHistory = () => {
    navigate(ROUTES.percapitaPayHistory.path, { state: 2 });
    dispatch(clearPaymentStatus());
  };

  const accountInfo = useMemo(
    // @ts-ignore
    () => {
      const { accountType, details, alias } = accounts?.find((acc: any) => acc.id === selectedAccount) ?? {};
      return { accountType, details, alias };
    },
    [accounts, selectedAccount]
  );

  const handlePrepareConfirm = (transferAmount: number) => {
    if (transferAmount > 0) {
      reviewSheet.show();
      preparePayment({ accountId: selectedAccount, amount: transferAmount });
    }
  };

  const closePaymentSheets = () => {
    prepareSheet.hide();
    reviewSheet.hide();
  };

  useEffect(() => {
    if (requestPaymentResult.isError || requestPaymentResult.isSuccess) {
      reviewSheet.hide();
    }
  }, [requestPaymentResult.isError, requestPaymentResult.isSuccess]);

  useEffect(() => {
    if (preparePaymentResult.isError || hasError || paymentSucceed) {
      prepareSheet.hide();
    }
  }, [paymentSucceed, hasError, preparePaymentResult.isError]);

  const handleSelectAccount = (id: number) => {
    dispatch(setSelectedAccount(id));
  };

  useEffect(() => {
    if (typeof percapitaPayModalType === 'undefined' && isPaymentRequestSheetStillOpen) {
      prepareSheet.show();
      reviewSheet.show();
    }
  }, [displayPercapitaPayModal, percapitaPayModalType]);

  useEffect(() => {
    dispatch(setHeaderTitle('Percapita Pay'));
    getImmediatePayFrameUrl();
    getWalletAccounts();
    getConsents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WebHeader />
      <SLayout>
        {!isDesktopSize && (
          <Title marginBottom={32} className="title">
            {t('percapitaPayHome.Percapita Pay')}
          </Title>
        )}
        {getPaymentsInfoQueryResult?.isSuccess && (
          <SBlock>
            <PercapitaPayMain
              isActive={isActive}
              primaryButtonText={t('percapitaPayHome.Request Percapita Pay')}
              secondaryButtonText={t('percapitaPayHome.See Time Card')}
              onPrimaryButtonClick={prepareSheet.show}
              canDisablePrimaryButton
            />
            <STransferBlock>
              <div className="transfer-block">
                <BodyText marginRight={8} font="Poppins" size="M" fontWeight="SB" color="charcoal" textType="bodyText">
                  {t('percapitaPayHome.So far this pay period')}
                </BodyText>

                {isDesktopSize ? (
                  <Tooltip open={infoTooltip.isActive} width={328} placement="rightBottom" content={<PayPeriodTooltipContent />}>
                    <Icon name="info" size="smaller" color="blue" cursorPointer />
                  </Tooltip>
                ) : (
                  <Icon name="info" size="smaller" color="blue" cursorPointer onClick={handleInfoClick} />
                )}
              </div>

              <div className="transfer-items">
                <TransferItem iconName="transfersAvailableCompleted" title={t('percapitaPayHome.Completed Transfers')} value={completedTransfers} />

                {paymentsInfo.transfersAvailable !== undefined && (
                  <TransferItem iconName="transfersAvailable" title={t('percapitaPayHome.Transfers Remaining')} value={paymentsInfo.transfersAvailable} />
                )}

                {paymentsInfo.transferredAmount !== undefined && (
                  <TransferItem iconName="transferredAmount" title={t('percapitaPayHome.Amount Transferred')} value={`$${paymentsInfo.transferredAmount}`} />
                )}
              </div>

              {isDesktopSize && (
                <div className="view-transfer-history">
                  <SCustomButton onClick={handleViewHistoryClick}>
                    <BodyText color="blue" fontWeight="B" size="N" textType="bodyText">
                      {t('percapitaPayHome.View Transfer History')}
                    </BodyText>
                  </SCustomButton>
                </div>
              )}
            </STransferBlock>

            {!isDesktopSize && (
              <div className="view-transfer-history">
                <SCustomButton onClick={handleViewHistoryClick}>
                  <BodyText color="blue" fontWeight="B" size="N" textType="bodyText">
                    {t('percapitaPayHome.View Transfer History')}
                  </BodyText>
                </SCustomButton>
              </div>
            )}
          </SBlock>
        )}

        {getPaymentsInfoQueryResult?.isLoading && <Loader />}

        {/* Modals */}
        <PaymentErrorModal hasError={hasError} handleCloseStatusModal={handleCloseStatusModal} handleTransferHistory={handleTransferHistory} />

        <CustomModal
          open={iframeModal.isActive}
          onCancel={handleCloseIframe}
          padding="7px"
          topPosition="0"
          margin="0"
          contentHeight="100%"
          bodyStyle={{ height: '100%', maxHeight: '100%' }}
          isFullWidth
          isFullHeight
          modalName="percapitaPay_immediatePayModal"
          closeIcon={
            <SIframeCloseButton>
              <Icon name="close" size="small" color="blue" />
            </SIframeCloseButton>
          }
        >
          <SIFrame width="100%" height="100%" title="add account" src={immediatePayFrameUrl} />
        </CustomModal>

        <PaymentSuccessModal paymentSucceed={paymentSucceed} handleCloseStatusModal={handleCloseStatusModal} />

        {isDesktopSize ? (
          <PaymentPrepareModal
            accounts={accounts}
            getAccounts={getAccountsQuery.refetch}
            toggleRequestPercPay={prepareSheet.toggle}
            isOpen={prepareSheet.isActive}
            selectedAccount={selectedAccount}
            availableAmount={paymentsInfo.availableNow}
            availableMin={Number(paymentsInfo.availableMin)}
            handleAddAccountClick={handleAddAccountClick}
            handleSelectAccount={handleSelectAccount}
            onConfirm={handlePrepareConfirm}
            isLoading={preparePaymentResult.isLoading}
          />
        ) : (
          <PaymentPrepareSheet
            accounts={accounts}
            getAccounts={getAccountsQuery.refetch}
            toggleRequestPercPay={prepareSheet.toggle}
            isOpen={prepareSheet.isActive}
            selectedAccount={selectedAccount}
            availableAmount={paymentsInfo.availableNow}
            availableMin={Number(paymentsInfo.availableMin)}
            handleAddAccountClick={handleAddAccountClick}
            handleSelectAccount={handleSelectAccount}
            onConfirm={handlePrepareConfirm}
            isLoading={preparePaymentResult.isLoading}
          />
        )}

        {isDesktopSize ? (
          <PaymentConfirmModal
            isOpen={reviewSheet.isActive && !preparePaymentResult.isError && !preparePaymentResult.isLoading}
            isPaymentRequestStillOpen={setIsPaymentRequestSheetStillOpen}
            onClose={closePaymentSheets}
            checkDate={checkDate}
            accountInfo={accountInfo}
            amountToTransfer={amountToTransfer}
            fee={fee}
            amountSelected={amountSelected}
            estimatedDate={estimatedDate}
            handleCloseStatusModal={handleCloseStatusModal}
            handleRequestPayment={handleRequestPayment}
            toggleRequestPercPay={prepareSheet.toggle}
            isLoading={requestPaymentResult.isLoading}
          />
        ) : (
          <PaymentRequestSheet
            isOpen={reviewSheet.isActive && !preparePaymentResult.isError && !preparePaymentResult.isLoading}
            isPaymentRequestStillOpen={setIsPaymentRequestSheetStillOpen}
            onClose={closePaymentSheets}
            checkDate={checkDate}
            accountInfo={accountInfo}
            amountToTransfer={amountToTransfer}
            fee={fee}
            amountSelected={amountSelected}
            estimatedDate={estimatedDate}
            handleCloseStatusModal={handleCloseStatusModal}
            handleRequestPayment={handleRequestPayment}
            toggleRequestPercPay={prepareSheet.toggle}
            isLoading={requestPaymentResult.isLoading}
          />
        )}

        <CustomSheet
          title={t('enrollTermsAndConditions.Terms And Conditions')}
          isOpen={termsSheet.isActive}
          onClose={termsSheet.hide}
          subtitle={t('enrollTermsAndConditions.Please read and agree to this disclosure')}
          modalName="percapitaPay_termsAndConditionsSheet"
          footer={
            <>
              <Checkbox id="eConsent-checkbox" checked={isCheckboxAgreed} onChange={handleChangeAgree}>
                <BodyText textType="helperText" fontWeight="R" size="S" color="charcoal70">
                  {t('enrollTermsAndConditions.I have read the Website and Mobile Application Terms of Use set forth above and I accept them.')}
                </BodyText>
              </Checkbox>

              <Button size="large" disabled={!isCheckboxAgreed} onClick={handleAcceptTerms} marginTop={24}>
                {t('enrollTermsAndConditions.Accept')}
              </Button>
            </>
          }
        >
          {isEulaPolicyFetching ? <Loader /> : <div dangerouslySetInnerHTML={{ __html: policyText }} />}
        </CustomSheet>
      </SLayout>
    </>
  );
};
