import styled from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import MapSvg from 'assets/images/map.svg';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  svg {
    #waves {
      animation: fadeWaves 0.8s infinite alternate;
    }
  }

  @keyframes fadeWaves {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SCustomCard = styled(CustomCard)`
  display: flex;
  flex-direction: column;
  background-image: url(${MapSvg});
  background-repeat: no-repeat;
  background-size: 191px 212px;
  background-position: left -82px top 60px;
  padding: 8px 20px 24px 8px;

  .top-badge {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    min-width: 120px;
    border-radius: 12px 4px;
    margin-bottom: 8px;

    &.coming-soon {
      background: ${getColor('bluePurple40')};
      padding: 12px 18px;
    }

    &.now-available {
      background: ${getColor('gold40')};
      padding: 8px 16px;
    }
  }

  .card-title,
  .card-description {
    padding-left: 128px;
  }

  .card-description {
    flex: 1;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .learn-more-button {
    align-self: flex-end;
  }
`;
