import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { mobileApiCall } from 'services/mobileService';
import { Icon } from 'components/general/Icon/Icon';
import { useEditUserProfileDataMutation, useGetUserProfileDataMutation } from 'store/user/users.api';
import { lsSetItem } from 'utils/helpers/storage';
import { Form } from 'antd';
import { usernameCharacterRule } from 'utils/helpers/validationRules';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { handleError } from 'utils/helpers/errorHelper';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SLabelText } from 'views/Profile/ProfilePage/DataItem/DataItem.styles';

interface IFormValues {
  username: string;
}

interface IProps {
  onClose: () => void;
}

export const EditUsername = ({ onClose }: IProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const { hasErrors, checkErrors } = useFormHelpers(form);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const userProfileData = useSelector(selectCurrentUser);

  const [editUserProfileData] = useEditUserProfileDataMutation();
  const [getUserProfileData] = useGetUserProfileDataMutation();

  const handleFormChange = () =>
    setIsDisabled(hasErrors || form.getFieldValue('username')?.length === 0 || form.getFieldValue('username')?.length === undefined || userProfileData?.username === form.getFieldValue('username'));

  useEffect(() => {
    getUserProfileData({});
  }, []);

  const onFinish = async (formValues: IFormValues) => {
    const data = {
      profileData: {
        username: formValues.username,
      },
    };

    await editUserProfileData(data)
      .unwrap()
      .then(() => {
        getUserProfileData({});
      })
      .then(() => mobileApiCall('userNameChange', data.profileData.username))
      .then(() => {
        lsSetItem('savedUserName', formValues.username);
      })
      .catch((error) => handleError(error));

    onClose();
  };

  return (
    <Form onFinish={onFinish} onFieldsChange={checkErrors} form={form} requiredMark={false} layout="vertical" autoComplete="off" initialValues={{ username: userProfileData?.username ?? '' }}>
      <SLabelText textType="bodyText" font="DM Sans" fontWeight="R" color="charcoal70" size="M">
        {t(`profile.Username`)}
      </SLabelText>
      <Form.Item name="username" validateTrigger={['onBlur', 'onChange']} rules={[usernameCharacterRule()]} className="inline-field">
        <BaseInput
          onKeyUp={handleFormChange}
          placeholder={t('profile.Enter Your Preferred username here')}
          data-testid="username"
          customSuffix={
            <CustomRow justifyContent="flex-end" gap={18}>
              <Icon name="closeThin" size="smaller" color="charcoal40" cursorPointer onClick={onClose} />
              {!isDisabled && <Icon name="tick" size="smaller" color="blue" cursorPointer onClick={form.submit} />}
            </CustomRow>
          }
        />
      </Form.Item>
    </Form>
  );
};
