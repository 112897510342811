// FindFilters
export const feeFilter = JSON.stringify({
  filterValue: 'N',
  filterName: 'ATM_ACCESS_FEE_DOM_WDL',
});

export const open24Filter = JSON.stringify({
  filterValue: 'A',
  filterName: 'OPER_HRS',
});

export const wheelchairFilter = JSON.stringify({
  filterValue: 'Y',
  filterName: 'WHEELCHAIR',
});

export const initialTagsData = {
  noFee: false,
  openNow: false,
  wheelchair: false,
  sort: '',
  searchString: '',
};
