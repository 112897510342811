import React from 'react';
import { EditAddress } from './EditAddress';

interface IProps {
  isSheetOpen?: boolean;
  closeSheet?: () => void;
  type?: string;
}

export const EditMailingAddress = ({ isSheetOpen, closeSheet, type }: IProps) => <EditAddress isSheetOpen={isSheetOpen} closeSheet={closeSheet} type={type} />;
