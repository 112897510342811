import React, { useLayoutEffect, useRef, useState } from 'react';
import { BodyText } from 'components/general/Typography';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { SLayout } from './Card.styles';

type TCardItem = {
  title?: string;
  children: React.ReactElement;
  className?: string;
};

export const Card: React.FC<TCardItem> = ({ title = '', children, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const toggleBodyVisible = () => {
    setIsExpanded((prevState: boolean) => !prevState);
  };

  useLayoutEffect(() => {
    if (isExpanded) {
      if (bodyRef.current) setBodyHeight(bodyRef.current?.offsetHeight);
    } else {
      setBodyHeight(0);
    }
  }, [isExpanded, children]);

  return (
    <SLayout className={clsx('card', isExpanded && 'expanded', className)} bodyHeight={bodyHeight}>
      <div className="card-header" onClick={toggleBodyVisible}>
        <BodyText size="N" className="card-item-header-title" display="inline-flex">
          {title}
        </BodyText>

        <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} size="smallest" />
      </div>

      <div className="card-body-wrapper">
        <div className="card-body" ref={bodyRef}>
          {children}
        </div>
      </div>
    </SLayout>
  );
};
