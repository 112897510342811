import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;

  img {
    margin-top: 30px;
  }

  button {
    ${mediaFrom(
      'tablet',
      css`
        height: 52px;
        max-width: 300px;
        padding: 14px 32px;
        font-size: 18px;
        font-weight: 600;
        font-family: Poppins;
      `
    )}
  }
`;
