import React, { useEffect, useState } from 'react';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Tooltip as Atooltip } from 'antd';
import { useTheme } from 'styled-components';
import { SCloseButton, STooltipContent, STooltipWrapper } from './Tooltip.styles';

interface CustomTooltipProps {
  content: React.ReactNode;
  open: boolean;
  children: React.ReactNode;
  placement?: TooltipPlacement;
  width?: number;
}

export const Tooltip: React.FC<CustomTooltipProps> = ({ content, children, width, open, placement }) => {
  const theme = useTheme();
  const [visible, setVisible] = useState<boolean>(false);

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <STooltipWrapper>
      <Atooltip
        title={
          <>
            <STooltipContent>{content}</STooltipContent>
            <SCloseButton name="closeCircle" onClick={handleClose} cursorPointer />
          </>
        }
        open={visible}
        trigger="click"
        placement={placement}
        overlayStyle={{ maxWidth: `${width}px` }}
        onOpenChange={setVisible}
        showArrow={false}
        overlayInnerStyle={{
          borderRadius: '16px',
          border: `2px solid ${theme.charcoal10}`,
          padding: '20px',
          width: `${width}px`,
          position: 'relative',
          maxWidth: `${width}px`,
          left: '-20px',
          top: '-15px',
        }}
        color={theme.white}
      >
        <div onClick={() => setVisible(!visible)}>{children}</div>
      </Atooltip>
    </STooltipWrapper>
  );
};
