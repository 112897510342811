import React, { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setDisplayPercPlaysInfoModal } from 'store/ui.slice';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useAppDispatch } from 'utils/hooks/store';
import { Loader } from 'components/general/Loader/Loader';
import { BodyText } from 'components/general/Typography';
import { TextButton } from 'components/general/TextButton/TextButton';
import { PercapitaPlayWarningModal } from 'components/general/Modals/PercapitaPlayWarningModal/PercapitaPlayWarningModal';
import { usePercapitaPlayWarning } from 'utils/hooks/usePercapitaPlayWarning';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SIconTooltip, SButton, SToolCard } from './ToolCard.styles';

interface IToolCard {
  imgSrc?: string;
  icon?: string | ReactElement;
  buttonText: string;
  tooltip?: boolean;
  description: string | React.ReactNode;
  to?: string;
  isPercapitaPlay?: boolean;
  className?: string;
}

export const ToolCard = ({ imgSrc, icon = '', className, buttonText, tooltip = false, description, to = '', isPercapitaPlay = false }: IToolCard) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();
  const { pathname } = useLocation();
  const { isDesktopSize } = useDeviceDimension();
  const { handleGoToPlay, handleCloseWarning, isWarningActive, isStatusLoading, handleContinue, isAcceptPending } = usePercapitaPlayWarning();

  const handleButtonClick = () => {
    if (isPercapitaPlay) {
      handleGoToPlay();
      return;
    }
    navigate(to, { state: { fromPage: pathname } });
  };

  const handleTooltipClick = () => {
    dispatch(setDisplayPercPlaysInfoModal(true));
    track('navigate', '/wellness/goals-and-tools/playpercup/percapita-plays-info');
  };

  return (
    <>
      <SToolCard className={className}>
        {isStatusLoading && isPercapitaPlay && <Loader />}
        {isPercapitaPlay && <SIconTooltip name={`${isDesktopSize ? 'circleInfo' : 'circleQuestion'}`} cursorPointer onClick={handleTooltipClick} />}
        <CustomRow justifyContent="flex-start" overflowY="hidden" minHeight="48px" flex={1}>
          {imgSrc && <img src={imgSrc} alt={imgSrc} />}
          {icon}
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={24} marginTop={tooltip && !isDesktopSize ? 10 : 0} justifyContent="start">
            {description}
          </BodyText>
        </CustomRow>
        <CustomRow justifyContent={isDesktopSize ? 'flex-start' : 'flex-end'} marginLeft={isDesktopSize ? 44 : 0} marginTop={isDesktopSize ? 32 : 0}>
          {isDesktopSize ? (
            <TextButton color="blue" fontWeight="M" onClick={handleButtonClick}>
              {buttonText}
            </TextButton>
          ) : (
            <SButton preset="blue-outlined" onClick={handleButtonClick} size="small">
              {buttonText}
            </SButton>
          )}
        </CustomRow>
      </SToolCard>

      {isPercapitaPlay && <PercapitaPlayWarningModal open={isWarningActive} onClose={handleCloseWarning} onContinue={handleContinue} isLoading={isAcceptPending} />}
    </>
  );
};
