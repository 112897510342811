import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@trulioo/docv/style.css';
import 'dialog-polyfill/dialog-polyfill.css';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import { useNavHistory } from 'utils/hooks/useNavHistory';
import { useRightTimeNotifications } from 'utils/hooks/useRightTimeNotifications';
import { ROUTES } from 'vars/const/ROUTES';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';
import { AuthLayout } from 'components/layouts/AuthLayout/AuthLayout';
import { TransitionLayout } from 'components/layouts/TransitionLayout/TransitionLayout';
import { PrivateRoute } from 'components/routing/PrivateRoute';
import { PublicRoute } from 'components/routing/PublicRoute';
import { GridDesignLayout } from 'components/layouts/Design/GridDesignLayout/GridDesignLayout';
import { RegistrationPage } from 'views/Auth/Registration/RegistrationPage/RegistrationPage';
import { LoginPage } from 'views/Auth/Login/LoginPage/LoginPage';
import { ForgotPasswordPage } from 'views/Auth/ForgotPasswordPage/ForgotPasswordPage';
import { SuccessPage } from 'views/Auth/SuccessPage/SuccessPage';
import { MyIdSuccess } from 'views/OpenCashAccount/MyIdPage/ResultsPage/MyIdSuccess/MyIdSuccess';
import { MyIdPending } from 'views/OpenCashAccount/MyIdPage/ResultsPage/MyIdPending/MyIdPending';
import { CongratsPage } from 'views/OpenCashAccount/AcceptInvite/CongratsPage/CongratsPage';
import { AddJointAccountPage } from 'views/Account/AccountInformation/Modals/editAccount/AddJointAccountPage/AddJointAccountPage';
import { MyIdMatch } from 'views/OpenCashAccount/MyIdPage/ResultsPage/MyIdMatch/MyIdMatch';
import { Welcome } from 'views/Auth/Welcome/Welcome';
import { ChangePasswordPage } from 'views/Auth/ChangePasswordPage/ChangePasswordPage';
import { MainPage } from 'views/Main/MainPage/MainPage';
import { ProfilePage } from 'views/Profile/ProfilePage/ProfilePage';
import { ProfileSettings } from 'views/Profile/ProfileSettings/ProfileSettings';
import { MoveMoneyMainPage } from 'views/MoveMoney/MoveMoneyPage/MoveMoneyMainPage';
import { ExternalTransferConfirmPage } from 'views/MoveMoney/Transfers/ExternalTransferConfirmPage';
import { PrepPage } from 'views/OpenCashAccount/PrepPage/PrepPage';
import { AcceptInvite } from 'views/OpenCashAccount/AcceptInvite/AcceptInvite';
import { MobileCheckCapturePage } from 'views/MoveMoney/MoveMoneyPage/MobileCheckCapturePage/MobileCheckCapturePage';
import { AccountStatementsIframe } from 'views/StatementsAndDocuments/AccountStatementsIframe/AccountStatementsIframe';
import { CashCheckTaxIdPage } from 'views/Account/AccountInformation/CashCheckTaxIdPage/CashCheckTaxIdPage';
import { StarterPage } from 'views/OpenCashAccount/StarterPage/StarterPage';
import { MyIdPage } from 'views/OpenCashAccount/MyIdPage/MyIdPage';
import { Disclosures } from 'views/OpenCashAccount/MyInfo/DisclosuresPage/Disclosures';
import { NamePage } from 'views/OpenCashAccount/MyInfo/NamePage/NamePage';
import { AddressPage } from 'views/OpenCashAccount/MyInfo/AddressPage/AddressPage';
import { EmailPage } from 'views/OpenCashAccount/MyInfo/EmailPage/EmailPage';
import { EditAgePage } from 'views/OpenCashAccount/MyInfo/AgeTaxPage/EditAgePage/EditAgePage';
import { MyDetailsPage } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage';
import { EditTaxIdPage } from 'views/OpenCashAccount/MyInfo/AgeTaxPage/EditTaxIdPage/EditTaxIdPage';
import { SummaryPage } from 'views/OpenCashAccount/MyInfo/SummaryPage/SummaryPage';
import { VerificationStatusPage } from 'views/OpenCashAccount/MyInfo/SummaryPage/VerificationStatusPage';
import { EnrollPage } from 'views/PercapitaPay/Enroll/EnrollPage';
import { TermsAndConditionsPage } from 'views/PercapitaPay/TermsAndConditions/TermsAndConditionsPage';
import { SubscriptionsPage } from 'views/Subscriptions/SubscriptionsPage';
import { AccountApprovedPage } from 'views/PercapitaPay/AccountApproved/AccountApprovedPage';
import { EmailVerificationPage } from 'views/PercapitaPay/EmailVerification/EmailVerificationPage';
import { VerificationCodePage } from 'views/PercapitaPay/VerificationCode/VerificationCodePage';
import { HistoryPage } from 'views/PercapitaPay/History/HistoryPage';
import { SecurityAndPrivacy } from 'views/SecurityAndPrivacy/SecurityAndPrivacy';
import { SuccessfulStuffSaveAccountPage } from 'views/Main/MainPage/StuffSaveAccount/SuccessfulStuffSaveAccountPage';
import { DirectDepositPage } from 'views/Transactions/DirectDeposit/DirectDepositPage';
import { StartDepositPage } from 'views/Transactions/DirectDeposit/StartDeposit/StartDepositPage';
import { NotificationsAndAlertsPage } from 'views/NotificationsAndAlerts/NotificationsAndAlertsPage';
import { RequestToCloseCashAccountPage } from 'views/NotificationsAndAlerts/RequestToCloseCashAccount/RequestToCloseCashAccountPage';
import { DisclosuresPage } from 'views/Profile/Disclosures/DisclosuresPage';
import { StatementsAndDocumentsPage } from 'views/StatementsAndDocuments/StatementsAndDocuments';
import { AddNewSourceIframe } from 'views/MoveMoney/AddMoneyPage/AddNewSourceSection/AddNewSourceIframe/AddNewSourceIframe';
import { AddNewBankAccountPage } from 'views/AddExternal/AddBankAccountPage/AddNewBankAccountPage';
import { HelpAndSupport } from 'views/HelpAndSupport/HelpAndSupport';
import { AddNeedsGoalsPage } from 'views/Main/MainPage/Accounts/AddNeedsGoals/AddNeedsGoalsPage';
import { AddSubAccountSuccessPage } from 'views/Main/MainPage/Accounts/AddNeedsGoals/AddSubAccountSuccessPage';
import { AccountsInformationPage } from 'views/Account/AccountInformation/AccountsInformationPage';
import { SelectedAccountInformationPage } from 'views/Account/AccountInformation/SelectedAccountInformationPage';
import { QuestionSearchPage } from 'views/HelpAndSupport/Search/QuestionSearchPage';
import { PennyJarPage } from 'views/Account/PennyJar/PennyJarPage';
import { AccountPennyJarPage } from 'views/Account/PennyJar/AccountPennyJarPage';
import { DepositOnboardPage } from 'views/Transactions/DirectDeposit/DepositOnboard/DepositOnboardPage';
import { UploadDocumentPage } from 'views/Documents/UploadDocument/UploadDocumentPage';
import { ForgotPasswordCodePage } from 'views/Auth/ForgotPasswordPage/ForgotPasswordCodePage/ForgotPasswordCodePage';
import { ATMLocationsPage } from 'views/MoveMoney/ATMLocations/ATMLocationsPage';
import { OnboardingFirstStepsPage } from 'views/Onboarding/FirstSteps/OnboardingFristStepsPage';
import { LanguageSelectionPage } from 'views/Onboarding/LanguageSelection/LanguageSelectionPage';
import { JointAccountsPage } from 'views/OpenCashAccount/MyAccount/JointAccounts/JointAccountsPage';
import { SetUpDepositPage } from 'views/Transactions/DirectDeposit/SetUpDeposit/SetUpDepositPage';
import { SetupBiometricPage } from 'views/Auth/Registration/SetupBiometricPage/SetupBiometricPage';
import { TruliooUploadPage } from 'views/OpenCashAccount/TruliooUploadPage';
import { NotificationsCenterPage } from 'views/NotificationsCenter/NotificationsCenterPage';
import { NotificationItemPage } from 'views/NotificationsCenter/NotificationItemPage';
import { AddMoneyNow } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/AddMoneyNow/AddMoneyNow';
import { DirectDepositNow } from 'views/Transactions/DirectDeposit/DirectDepositNow/DirectDepositNow';
import { CheckEmailPage } from 'views/OpenCashAccount/MyInfo/EmailPage/CheckEmail/CheckEmailPage';
import { EmailVerifiedPage } from 'views/OpenCashAccount/MyInfo/EmailPage/EmailVerified/EmailVerifiedPage';
import { VerificationIFrame } from 'views/OpenCashAccount/MyInfo/SummaryPage/VerificationIFrame';
import { CashCushion } from 'views/Account/CashCushion/CashCushion';
import { CashCushionActivate } from 'views/Account/CashCushion/CashCushionActivate';
import { SelectedAccountCashCushionPage } from 'views/Account/CashCushion/SelectedAccountCashCushion';
import { ChangeAddressPage } from 'views/Profile/ProfilePage/ChangeAddressPage/ChangeAddressPage';
import { UploadDocumentsPage } from 'views/Profile/ProfilePage/UploadDocumentsPage/UploadDocumentsPage';
import { LockScreen } from 'views/LockScreen/LockScreen';
import { FeeSchedulePage } from 'views/FeeSchedulePage/FeeSchedulePage';
import { ContactInformation } from 'views/Account/ContactInformation/ContactInformation';
import { OpenNewCashPage } from 'views/OpenCashAccount/OpenNewCash/OpenNewCashPage';
import { ConfirmTransferPage } from 'views/MoveMoney/Transfers/ConfirmTransferPage';
import { OpenNewCashApprovedPage } from 'views/OpenCashAccount/OpenNewCash/OpenNewCashApproved/OpenNewCashApprovedPage';
import { ManageCardsPage } from 'views/MoveMoney/ManageCardsPage/ManageCardsPage';
import { UploadSuccessPage } from 'views/Documents/UploadDocument/UploadSuccessPage/UploadSuccessPage';
import { TransactionLimitsPage } from 'views/TransactionLimits/TransactionLimitsPage';
import { RegistrationCodePage } from './views/Auth/Registration/RegistrationCodePage/RegistrationCodePage';
import { BalancesTransactionsPage } from './views/Account/BalancesTransactionsPage/BalancesTransactionsPage';
import { MenuPage } from './views/MenuPage/MenuPage';
import { WellnessPage } from './views/Wellness/WellnessPage';
import { OnboardingPage } from './views/Onboarding/OnboardingPage/OnboardingPage';
import { Attune } from './views/Wellness/Attune/Attune';
import { PlayPercUp } from './views/Wellness/PlayPercUp/PlayPercUp';
import { InviteAndEarnPage } from './views/Invite/InviteAndEarnPage/InviteAndEarn';
import { ReferPage } from './views/Refer/ReferPage/ReferPage';
import { IFrameLayout } from './components/layouts/IFrameLayout/IFrameLayout';
import { DeviceVerifyPage } from './views/Auth/Login/DeviceVerifyPage/DeviceVerifyPage';
import { DeviceVerifyWarningPage } from './views/Auth/Login/DeviceVerifyWarningPage/DeviceVerifyWarningPage';
import { useLanguage } from './utils/hooks/useLanguage';
import { UserDataSentPage } from './views/Auth/Login/UsernameSentPage/UsernameSentPage';
import { TransferPage } from './views/MoveMoney/Transfers/TransferPage';
import { AddExternalAccountPage } from './views/AddExternal/AddExternalAccountPage';
import { DoMore } from './views/DoMore/DoMore';
import { PercapitaPayMainPage } from './views/PercapitaPay/MainPage/PercapitaPayMainPage';
import { SelectAccountPage } from './views/Main/MainPage/Accounts/AddNeedsGoals/SelectAccountPage/SelectAccountPage';
import { ThemeController } from './ThemeController';
import { WellnessFAQ } from './views/Wellness/WellnessFAQ';
import { theme } from './styles/theme';
import { Fonts } from './styles/fonts';
import { GlobalStyle } from './styles/GlobalStyle';
import { persistor, store } from './store/store';
import { i18n } from './i18n/i18n';
import { setCurrentBackUrl } from './store/ui.slice';
import { CountryRequestPage } from './views/MoveMoney/CountryRequestPage/CountryRequestPage';
import { InternationalTransferPage } from './views/MoveMoney/InternationalTransferPage/InternationalTransferPage';
import { TaxPlanner } from './views/DoMore/TaxViews/TaxPlanner';
import { TaxFiler } from './views/DoMore/TaxViews/TaxFiler';

export const AppRoutes = () => {
  const location = useLocation();
  const { isDesktopSize } = useDeviceDimension();
  const dispatch = useDispatch();

  useLanguage(true);
  useNavHistory(location.pathname);
  useRightTimeNotifications(location.pathname);

  useEffect(() => {
    dispatch(setCurrentBackUrl(null));
  }, [location]);

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to={ROUTES.login.path} replace />} />
      <Route element={<PublicRoute />}>
        {/* Dummy page for mobile app authentication */}
        <Route path={ROUTES.auth.path} element={<div />} />
        <Route path={ROUTES.app.path} element={<div />} />
        <Route path={ROUTES.getApp.path} element={<div />} />
        <Route path={ROUTES.appOpenAccountVerifySmsCode.path} element={<div />} />
        <Route path={ROUTES.onboardingLanguageSelection.path} element={<LanguageSelectionPage />} />
        <Route path={ROUTES.onboarding.path} element={<Welcome />} />
        <Route path={ROUTES.truliooUpload.path} element={<TruliooUploadPage />} />
        <Route path={ROUTES.statementsIframe.path} element={<AccountStatementsIframe />} />
        <Route path={ROUTES.taxPlanner.path} element={<TaxPlanner />} />
        <Route path={ROUTES.taxFiler.path} element={<TaxFiler />} />

        <Route path={ROUTES.playToWinPromo.path} element={<Navigate to={ROUTES.home.path} replace state={{ playToWinOpen: true }} />} />

        <Route element={<AuthLayout type="v2" />}>
          <Route element={<TransitionLayout />}>
            <Route path={ROUTES.login.path} element={<LoginPage />} />
          </Route>
        </Route>

        <Route element={<AuthLayout type="onboarding-first-steps" showHeader={false} showDesktopHeader showDesktopFooter />}>
          <Route element={<TransitionLayout />}>
            <Route path={ROUTES.onboardingFirstSteps.path} element={<OnboardingFirstStepsPage />} />
          </Route>
        </Route>

        <Route element={<AuthLayout type="onboarding" tabletWidth="100%" showFooter={false} showDesktopHeader showHeader={false} />}>
          <Route path={ROUTES.onboardingHome.path} element={<OnboardingPage />} />
        </Route>

        <Route element={<AuthLayout type={isDesktopSize ? 'v2' : ''} showDesktopHeader showMobileHeader />}>
          <Route element={<TransitionLayout />}>
            <Route element={<GridDesignLayout />}>
              <Route path={ROUTES.registration.path} element={<RegistrationPage />} />
              <Route path={ROUTES.registrationCode.path} element={<RegistrationCodePage />} />
              <Route path={ROUTES.setupBiometric.path} element={<SetupBiometricPage />} />
              <Route path={ROUTES.forgotPassword.path} element={<ForgotPasswordPage />} />
              <Route path={ROUTES.forgotPasswordCode.path} element={<ForgotPasswordCodePage />} />
              <Route path={ROUTES.forgotDataUsernameSent.path} element={<UserDataSentPage type="username" />} />
              <Route path={ROUTES.forgotDataInformationSent.path} element={<UserDataSentPage type="information" />} />
              <Route path={ROUTES.success.path} element={<SuccessPage />} />
              <Route path={ROUTES.changePassword.path} element={<ChangePasswordPage />} />
              <Route path={ROUTES.verifyDeviceWarning.path} element={<DeviceVerifyWarningPage />} />
              <Route path={ROUTES.verifyDevice.path} element={<DeviceVerifyPage />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        <Route element={<AuthLayout type="v2" />}>
          <Route element={<TransitionLayout />}>
            <Route path={ROUTES.sessionLocked.path} element={<LockScreen />} />
          </Route>
        </Route>

        {/* Percapita pay */}
        <Route element={<MainLayout headerTitle="Percapita Pay" />}>
          <Route element={<TransitionLayout />}>
            <Route element={<GridDesignLayout />}>
              <Route path={ROUTES.percapitaPayEnroll.path} element={<EnrollPage />} />
              <Route path={ROUTES.termsAndConditions.path} element={<TermsAndConditionsPage />} />
              <Route path={ROUTES.accountApproved.path} element={<AccountApprovedPage />} />
              <Route path={ROUTES.emailVerification.path} element={<EmailVerificationPage />} />
              <Route path={ROUTES.verificationCode.path} element={<VerificationCodePage />} />
              <Route path={ROUTES.percapitaPayBalance.path} element={<PercapitaPayMainPage />} />
              <Route path={ROUTES.percapitaPayHistory.path} element={<HistoryPage />} />
            </Route>
          </Route>
        </Route>

        {/* Account opening screens */}
        <Route element={<MainLayout headerTitle="Cash Account Opening" />}>
          <Route element={<TransitionLayout />}>
            <Route path={ROUTES.uploadDocument.path} element={<UploadDocumentPage />} />
            <Route path={ROUTES.uploadDocumentSuccess.path} element={<UploadSuccessPage />} />

            <Route path={ROUTES.openAccountPrep.path} element={<PrepPage />} />
            <Route path={ROUTES.myAccountJointAccounts.path} element={<JointAccountsPage />} />

            <Route element={<GridDesignLayout />}>
              <Route path={ROUTES.myInfoEConsent.path} element={<Disclosures />} />
              <Route path={ROUTES.myId.path} element={<MyIdPage />} />
              <Route path={ROUTES.myInfoDisclosures.path} element={<Disclosures />} />
              <Route path={ROUTES.myInfoName.path} element={<NamePage />} />
              <Route path={ROUTES.myInfoHomeAddress.path} element={<AddressPage />} />
              <Route path={ROUTES.myInfoEmailUsername.path} element={<EmailPage />} />
              <Route path={ROUTES.myInfoVerifySms.path} element={<CheckEmailPage />} />
              <Route path={ROUTES.myInfoEmailVerified.path} element={<EmailVerifiedPage />} />
              <Route path="/open-account/my-info/age-tax" element={<Navigate replace to={ROUTES.myInfoAge.path} />} />
              <Route path={ROUTES.myInfoAge.path} element={<EditAgePage />} />
              <Route path={ROUTES.myInfoAdditionalDetails.path} element={<MyDetailsPage />} />
              <Route path={ROUTES.myInfoTaxId.path} element={<EditTaxIdPage />} />
              <Route path={ROUTES.myInfoSummary.path} element={<SummaryPage />} />
              <Route path={ROUTES.myInfoVeriticationResult.path} element={<VerificationStatusPage />} />
              <Route path={ROUTES.userVerificationIFrame.path} element={<VerificationIFrame />} />
            </Route>
          </Route>
        </Route>

        <Route element={<MainLayout />}>
          <Route element={<TransitionLayout />}>
            <Route path={ROUTES.attune.path} element={<Attune />} />
            <Route path={ROUTES.addDebitCard.path} element={<AddNewSourceIframe />} />
            <Route path={ROUTES.starter.path} element={<StarterPage />} />
            <Route path={ROUTES.profileSettings.path} element={<ProfileSettings />} />
            <Route path={ROUTES.notificationSettings.path} element={<NotificationsAndAlertsPage />} />
            <Route path={ROUTES.requesToCloseCashAccount.path} element={<RequestToCloseCashAccountPage />} />
            <Route path={ROUTES.uploadDocuments.path} element={<UploadDocumentsPage />} />
            <Route path={ROUTES.addJointAccount.path} element={<AddJointAccountPage />} />
            <Route path={ROUTES.transfersConfirm.path} element={<ExternalTransferConfirmPage />} />
            <Route path={ROUTES.addNeedsGoalsAccount.path} element={<AddNeedsGoalsPage />} />
            <Route path={ROUTES.addNeedsGoalsAccountSelect.path} element={<SelectAccountPage />} />
            <Route path={ROUTES.setUpDeposit.path} element={<SetUpDepositPage />} />
            <Route path={ROUTES.acceptInvite.path} element={<AcceptInvite />} />
            <Route path={ROUTES.changeAddress.path} element={<ChangeAddressPage />} />
            <Route path={ROUTES.accountInformationVerify.path} element={<CashCheckTaxIdPage />} />
            <Route path={ROUTES.feeSchedulePage.path} element={<FeeSchedulePage />} />
            <Route path={ROUTES.contactInformation.path} element={<ContactInformation />} />
            <Route path={ROUTES.securityPrivacy.path} element={<SecurityAndPrivacy />} />

            <Route element={<GridDesignLayout />}>
              <Route path={ROUTES.balancesTransactions.path} element={<BalancesTransactionsPage />} />
              <Route path={ROUTES.home.path} element={<MainPage />} />
              <Route path={ROUTES.mainMenu.path} element={<MenuPage />} />
              <Route path={ROUTES.successAddNeedsGoalsAccount.path} element={<AddSubAccountSuccessPage />} />
              <Route path={ROUTES.selectedAccountInformation.path} element={<SelectedAccountInformationPage />} />
              <Route path={ROUTES.helpAndSupport.path} element={<HelpAndSupport />} />
              <Route path={ROUTES.disclosures.path} element={<DisclosuresPage />} />
              <Route path={ROUTES.statementsAndDocuments.path} element={<StatementsAndDocumentsPage />} />
              <Route path={ROUTES.notificationsCenter.path} element={<NotificationsCenterPage />} />
              <Route path={ROUTES.notificationsCenterItem.path} element={<NotificationItemPage />} />
              <Route path={ROUTES.invite.path} element={<InviteAndEarnPage />} />
              <Route path={ROUTES.refer.path} element={<ReferPage />} />
              <Route path={ROUTES.successfulAccountOpen.path} element={<SuccessfulStuffSaveAccountPage />} />
              <Route path={ROUTES.depositOnboard.path} element={<DepositOnboardPage />} />
              <Route path={ROUTES.directDeposit.path} element={<DirectDepositPage />} />
              <Route path={ROUTES.directDepositNow.path} element={<DirectDepositNow />} />
              <Route path={ROUTES.startDeposit.path} element={<StartDepositPage />} />
              <Route path={ROUTES.subscriptions.path} element={<SubscriptionsPage />} />
              <Route path={ROUTES.myIdSuccess.path} element={<MyIdSuccess />} />
              <Route path={ROUTES.openCashAccountCongrats.path} element={<CongratsPage />} />
              <Route path={ROUTES.myIdPending.path} element={<MyIdPending />} />
              <Route path={ROUTES.myIdMatch.path} element={<MyIdMatch />} />
              <Route path={ROUTES.helpAndSupportSearch.path} element={<QuestionSearchPage />} />
              <Route path={ROUTES.pennyJar.path} element={<PennyJarPage />} />
              <Route path={ROUTES.accountPennyJar.path} element={<AccountPennyJarPage />} />
              <Route path={ROUTES.cashCushion.path} element={<CashCushion />} />
              <Route path={ROUTES.cashCushionActivate.path} element={<CashCushionActivate />} />
              <Route path={ROUTES.accountCashCushion.path} element={<SelectedAccountCashCushionPage />} />
              <Route path={ROUTES.addMoneyDisclosure.path} element={<AddMoneyNow />} />
              <Route path={ROUTES.moveMoney.path} element={<MoveMoneyMainPage />} />
              <Route path={ROUTES.mobileCheckCapture.path} element={<MobileCheckCapturePage />} />
              <Route path={ROUTES.addExternalAccounts.path} element={<AddExternalAccountPage />} />
              <Route path={ROUTES.atmLocations.path} element={<ATMLocationsPage />} />
              <Route path={ROUTES.openNewCash.path} element={<OpenNewCashPage />} />
              <Route path={ROUTES.openNewCashApproved.path} element={<OpenNewCashApprovedPage />} />
              <Route path={ROUTES.transactionLimits.path} element={<TransactionLimitsPage />} />
            </Route>
          </Route>

          <Route path={ROUTES.wellness.path} element={<WellnessPage />} />
          <Route element={<GridDesignLayout />}>
            <Route path={ROUTES.profile.path} element={<ProfilePage />} />
            <Route path={ROUTES.transfers.path} element={<TransferPage />} />
            <Route path={ROUTES.confirmTransfer.path} element={<ConfirmTransferPage />} />
            <Route path={ROUTES.internationalTransfer.path} element={<InternationalTransferPage />} />
            <Route path={ROUTES.manageCards.path} element={<ManageCardsPage />} />
            <Route path={ROUTES.countryRequestPage.path} element={<CountryRequestPage />} />
            <Route path={ROUTES.wellnessFAQ.path} element={<WellnessFAQ />} />
            <Route path={ROUTES.doMore.path} element={<DoMore />} />
            <Route path={ROUTES.accountInformation.path} element={<AccountsInformationPage />} />
            <Route path={ROUTES.selectedAccountInformation.path} element={<SelectedAccountInformationPage />} />
            <Route path={ROUTES.addNewBankAccount.path} element={<AddNewBankAccountPage />} />
          </Route>
        </Route>

        <Route element={<IFrameLayout />}>
          <Route element={<TransitionLayout />}>
            <Route path={ROUTES.playPercUp.path} element={<PlayPercUp />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider>
          <ThemeController theme={theme}>
            <Fonts />
            <GlobalStyle />
            <AppRoutes />
          </ThemeController>
        </ConfigProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);
