import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo, SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import { SDottesBottomRight } from 'styles/common';

export const SLayout = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 32px 10px 32px;
  margin-left: -32px;
  margin-right: -32px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .account-selector-container {
    display: flex;
    flex-direction: column;
  }

  ${mediaFrom(
    'tablet',
    css`
    ${SDottesBottomRight};
    
    .breadcrumbs {
      padding: 0 0 25px 9%;
      margin-bottom: 48px;

      ol {
        padding-left: 0;
        
        li {
          .body-text {
            background: inherit;
            
            .custom-text-inner {
              color: ${getColor('charcoal70')};
            }
          }
        }
      }
    }

    .content-wrapper {
      align-items: center;
      padding: 0 24px;
      flex: 1;

      .account-selector-container {
        max-width: 80%;
        margin-bottom: 24px;

        ${mediaFrom(
          'desktop',
          css`
            max-width: 48%;
          `
        )}

        .account-selector-title {
          margin-bottom: 24px;
        }

        .account-selector {
          .internal-account {
            margin-bottom: 8px;

            .badge-default {
              background: ${getColor('blue20')};
            }
            
            div.info {
              align-items: center;
              
              div.icon {
                margin: 0 8px !important;
              }
            }
          }
        }

        .internal-account:last-child {
          margin-bottom: 0;
        }
      }
    }

    button.preset-primary {
      padding: 14px 32px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .disclaimer-wrapper {
    padding: 92px 17% 92px 9%;
  }
  `
  )};

  ${mediaUpTo(
    SCREEN_SIZE.mobile,
    css`
      .content-wrapper .account-selector-container {
        max-width: 70%;
      }
    `
  )};
`;
