import React, { useEffect } from 'react';
import { Button } from 'components/theme/Button/Button';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { initialValueValidator, searchValidator } from 'utils/helpers/validationRules';

interface IAddNoteSheet {
  isOpen: boolean;
  onClose: () => void;
  handleNoteAdded: (note: string) => void;
  note: string;
}

export const AddNoteSheet: React.FC<IAddNoteSheet> = ({ isOpen, onClose, handleNoteAdded, note }) => {
  const [noteForm] = useForm();
  const { t } = useTranslation('moveMoney', { keyPrefix: 'transferPage' });
  const { isDesktopSize } = useDeviceDimension();
  const { hasErrors, checkErrors } = useFormHelpers(noteForm);

  const handleSaveNote = () => {
    const noteInput = noteForm.getFieldValue('noteValue');

    handleNoteAdded(noteInput);
    onClose();
  };

  useEffect(() => {
    if (isOpen) noteForm.setFieldValue('noteValue', note);
  }, [note, isOpen]);

  return (
    <CustomSheet isModal={isDesktopSize} destroyOnClose isOpen={isOpen} header={false} modalName="transfer_addNoteModal" height="fit-content" wrapperPadding={isDesktopSize} onClose={onClose}>
      <Form form={noteForm} initialValues={{ noteValue: note }} onFinish={handleSaveNote} onFieldsChange={checkErrors}>
        <CustomRow justifyContent="flex-start" marginBottom={24}>
          <Icon name="arrowLeft" color="charcoal" cursorPointer onClick={onClose} />

          <Title font="Poppins" color="charcoal" marginLeft={15} fontWeight="SB" size="S">
            {note ? t('EditNote') : t('AddNote')}
          </Title>
        </CustomRow>

        <Form.Item name="noteValue" validateTrigger={['onBlur', 'onChange']} rules={[initialValueValidator(note), searchValidator()]}>
          <BaseInput placeholder={t('WriteYourNoteHere')} containerStyle={{ height: '84px' }} />
        </Form.Item>

        <Button preset="blue-filled" size="middleStretch" marginTop={24} type="submit" disabled={hasErrors}>
          {t('SaveNote')}
        </Button>
      </Form>
    </CustomSheet>
  );
};
