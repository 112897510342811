import { getColor, getProp, mediaFrom } from 'utils/helpers/styleHelpers';
import styled, { css } from 'styled-components';

export const STabs = styled.div<{ activeTabName: string }>`
  & > .tab-pane {
    display: none;

    &[data-tab-name=${getProp('activeTabName')}] {
      display: block;
    }
  }
`;

export const STabLabels = styled.div`
  .tab-labels__label {
    ${mediaFrom(
      'tablet',
      css`
        flex: 1 1 auto;
        border-radius: 0;
        background: none !important;
        border: none;
        border-bottom: 2px solid ${getColor('creamS10')};
        color: ${getColor('charcoal70')};
        font-size: 14px;
        font-weight: 600;

        &.active {
          border-color: ${getColor('blue')};
          color: ${getColor('charcoal')};
        }
      `
    )}
  }
`;
