import styled from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
  }

  .button-select-destination {
    display: flex;
    justify-content: flex-start;
    border: 2px solid ${getColor('charcoal10')};
    border-radius: 16px;
    padding: 12px 20px;
    margin-bottom: 32px;

    .btn__inner {
      justify-content: space-between;
      flex: 1;

      .text {
        font-size: 16px;
        font-family: DM Sans, sans-serif;
        font-weight: 400;
        color: ${getColor('charcoal40')};
      }
    }
  }
`;
