import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { SList, SListItem } from 'components/general/Modals/AddStuffSaveAccountModal/AddStuffSaveAccountModal.styles';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SCustomModal = styled(CustomModal)`
  // 1440px
  ${mediaFrom(
    'desktop',
    css`
      width: 648px !important;
      margin: 12px;

      .ant-modal-content {
        padding: 28px 24px 24px;
        margin: 0;
      }
    `
  )};
`;

export const SDeleteAccountModalBody = styled.div`
  & .center-image-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    img {
      width: 119px;
      height: 127px;
    }
  }

  & ${SList} {
    padding-left: 16px;
    margin-left: 0;
    margin-bottom: 16px;
  }

  & ${SListItem} {
    display: flex;
    padding-left: 0;
    font-size: 14px;
    margin-bottom: 4px;
    padding-right: 10px;

    &::before {
      content: '\u2022';
      color: red;
      font-weight: bold;
      display: inline-block;
      margin-right: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    button {
      width: auto;
    }
  }

  // 1440px
  ${mediaFrom(
    'desktopLarge',
    css`
      & .center-image-wrapper {
        img {
          width: 200px;
          height: 212px;
        }
      }

      .modal-title {
        .custom-title-text {
          font-size: 32px;
        }
      }

      .text-you-will {
        margin-bottom: 16px;
      }

      ul {
        margin-bottom: 24px !important;

        li {
          margin-bottom: 8px !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }

      .footer {
        button {
          font-size: 14px;
          font-weight: 600;
          padding: 11px 23px;
        }
      }
    `
  )};
`;

export const SLink = styled.a`
  color: ${getColor('charcoal')};
  font-weight: 600;

  &:hover {
    color: ${getColor('blue')};
  }
`;
