import React from 'react';
import 'antd/dist/antd.min.css';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { webID } from './utils/helpers/webID';
import { mobileInjectionInit } from './services/mobileService';
import { router } from './router';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);
mobileInjectionInit();
webID();
