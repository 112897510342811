import styled, { css } from 'styled-components';
import { getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  ${mediaUpTo(
    'mobile',
    css`
      .icon {
        height: 12px;
        width: 12px;
      }
    `
  )}

  .available-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;

    .title-container {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      gap: 5px;

      ${mediaUpTo(
        'mobile',
        css`
          .custom-title-text {
            font-size: 12px;
          }
        `
      )}
    }

    .amount-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      .custom-amount-parts {
        margin-bottom: 5px;
      }

      ${mediaUpTo(
        'mobile',
        css`
          .currency,
          .group,
          .integer {
            font-size: 16px;
            font-weight: 600;
          }

          .decimal,
          .fraction {
            font-size: 14px;
            font-weight: 600;
          }
        `
      )}

      ${mediaFrom(
        577,
        css`
          .currency,
          .group,
          .integer {
            font-size: 24px;
            font-weight: 600;
          }

          .decimal,
          .fraction {
            font-size: 20px;
            font-weight: 600;
          }
        `
      )}

      ${mediaFrom(
        'desktop',
        css`
          .currency,
          .group,
          .integer {
            font-size: 32px;
            font-weight: 500 !important;
          }

          .decimal,
          .fraction {
            font-size: 24px;
            font-weight: 500 !important;
          }
        `
      )}
    }
  }

  .move-money-see-card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;

    .move-money-container {
      button {
        color: ${getColor('white')};
        background: ${getColor('green90')};
        border: none;
        height: 44px;
        max-width: 150px;
        padding: 14px 24px;
        font-size: 16px !important;
        font-weight: 500;
      }

      ${mediaUpTo(
        'mobile',
        css`
          button {
            height: 32px;
            max-width: 120px;
            padding: 8px 20px;
            font-size: 12px !important;
          }
        `
      )}

      ${mediaFrom(
        'tablet',
        css`
          button {
            font-family: Poppins, sans-serif;
            border: 2px solid ${getColor('green20')};
            background: ${getColor('transparent')};
            color: ${getColor('green')};
            font-size: 12px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            padding: 6px 16px;
            height: 36px;
          }
        `
      )}
    }

    .card-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      .see-more-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;

        ${mediaUpTo(
          'mobile',
          css`
            img {
              width: 32px;
              margin-right: 8px;
            }
          `
        )}

        ${mediaBetween(
          'mobile',
          'tablet',
          css`
            img {
              width: 52px;
            }
          `
        )}

        ${mediaFrom(
          'tablet',
          css`
            flex-direction: row;
            font-family: Poppins, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: ${getColor('blue')};
            padding: 6px 16px;
            border: 2px solid ${getColor('blue10')};
            border-radius: 16px;
            margin-left: auto;

            img {
              width: 30px;
              margin-left: 12px;
            }
          `
        )}
      }
    }
  }
`;
