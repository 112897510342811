import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { SAccountCardAction } from './Message.styled';

interface IProps {
  title: string;
  displayText: string;
  onClick?: () => void;
}

export const AccountCardAction = ({ title, displayText, onClick }: IProps) => {
  const getAccountIconName = (rawAccName: string) => {
    const accName = rawAccName.toLowerCase();
    if (accName.includes('needs')) return 'needsAccount';
    if (accName.includes('goal')) return 'goal';
    return 'cash';
  };

  return (
    <SAccountCardAction onClick={onClick}>
      <div className="acc-icon">
        <Icon size="normal" name={getAccountIconName(title)} color="orange" />
      </div>
      <div className="center">
        <div className="title">{title}</div>
        <div className="caption" />
      </div>
      <div className="amount">{displayText}</div>
    </SAccountCardAction>
  );
};
