import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useToggle } from 'utils/hooks/useToggle';
import { errorWhale } from 'assets/images';
import survey from 'assets/images/survey.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SurveyModal } from 'views/Account/AccountInformation/SurveyModal/SurveyModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SPageHeader } from 'views/NotificationsAndAlerts/NotificationsAndAlertsPage.styles';
import { SLayout, SWrapper } from './RequestToCloseCashAccountPage.styles';

export const RequestToCloseCashAccountPage = () => {
  const { t } = useTranslation('accountInformation');
  const surveyModal = useToggle(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasNeedsAccount = state?.hasNeedsAccount;
  const hasGoalsAccount = state?.hasGoalsAccount;

  const onClickHandler = () => navigate(ROUTES.home.path);

  const handleOpenSurvey = () => {
    surveyModal.show();
  };

  const handleSubmitSurvey = () => {
    surveyModal.hide();
    onClickHandler();
  };

  return (
    <>
      <SPageHeader title={t('accountInformation.RequestToCloseCashAccount')} />
      <SLayout>
        <SWrapper>
          <div className="center-image">
            <img src={errorWhale} alt="exclamation" />
          </div>

          <Title color="charcoal" fontWeight="SB" size="S" marginBottom={15} font="Poppins" textAlign="start" marginTop={20}>
            {t('accountInformation.WerAreSorrySeeYouGo')}
          </Title>
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" textAlign="start" lineHeight={1.4}>
            <Trans i18nKey="accountInformation.TewWillReviewRequestToClose" ns="accountInformation" />
          </BodyText>
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" marginBottom={15} marginTop={25} paddingRight={4} font="DM Sans" textAlign="start">
            {t('accountInformation.MonitorYourEmailToCompleteRequiredSteps')}
          </BodyText>

          <div className="center-image">
            <img src={survey} alt="exclamation" />
          </div>
          <BodyText size="N">{t('accountInformation.FeedbackImportant')}</BodyText>

          <CustomRow justifyContent="center" gap={12} marginTop={64} marginBottom={64}>
            <Button width="auto" preset={hasNeedsAccount && hasGoalsAccount ? 'blue-outlined' : 'blue-filled'} size="large" onClick={onClickHandler}>
              {hasNeedsAccount && !hasGoalsAccount ? t('accountInformation.SkipSurvey') : t('accountInformation.GoHomeScreen')}
            </Button>
            <Button width="auto" preset="blue-filled" size="large" onClick={handleOpenSurvey}>
              {t('accountInformation.TakeSurvey')}
            </Button>
          </CustomRow>
        </SWrapper>
        <SurveyModal isOpen={surveyModal.isActive} onClose={surveyModal.hide} onClickHandler={handleSubmitSurvey} />
      </SLayout>
    </>
  );
};
