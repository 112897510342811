import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { triggerEvent } from 'utils/helpers/events';
import { Title } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useNavBack } from 'utils/hooks/useNavBack';
import { useRouteConfig } from 'utils/hooks/useRouteConfig';
import { SDesktopPageHeader, SMobilePageHeader, SDivider, SLayout } from './PageHeader.styles';
import { Crumbs } from './Crumbs/Crumbs';

export interface ICrumb {
  name: string;
  url?: string;
}

interface IPageHeader {
  title?: string;
  rightPart?: React.ReactNode;
  className?: string;
  crumbs?: ICrumb[];
  hasNavBack?: boolean;
}

export const PageHeader = ({ title = '', rightPart, className, hasNavBack = true, crumbs = [] }: IPageHeader) => {
  const { isDesktopSize } = useDeviceDimension();
  const { navigateBack } = useNavBack();
  const { backNav } = useRouteConfig();

  const handleBackBtnClick = useCallback(() => {
    triggerEvent('backClick');
    navigateBack();
  }, [triggerEvent, navigateBack]);

  return isDesktopSize ? (
    <SLayout className="desktop-page-header-wrapper">
      <SDesktopPageHeader className={clsx('desktop-page-header', className)}>
        <div className="desktop-page-header__inner">
          <div className="desktop-page-header__title-wrapper">
            {backNav && hasNavBack && <Icon name="arrowLeft" cursorPointer color="blue" onClick={handleBackBtnClick} className="desktop-page-header__back" marginRight={10} />}
            <Title textTag="h1" className="desktop-page-header__title">
              {title}
            </Title>
          </div>
          {rightPart && <div className="desktop-page-header__right">{rightPart}</div>}
        </div>
        {crumbs?.length > 0 && <Crumbs crumbs={crumbs} />}
      </SDesktopPageHeader>
      <SDivider className="divider" />
    </SLayout>
  ) : (
    <SMobilePageHeader className={clsx('mobile-page-header', className)}>
      <Title className="page-header" size="M">
        {title}
      </Title>
      <div className="mobile-page-header__right">{rightPart}</div>
    </SMobilePageHeader>
  );
};
