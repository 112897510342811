import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SCAOSummaryPage = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${mediaFrom(
    'tablet',
    css`
      justify-content: space-between;
      padding: 0 20px 40px 20px;
    `
  )}

  .top-part {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${mediaFrom(
      'tablet',
      css`
        width: 580px;
        align-self: center;
      `
    )}

    .data-card {
      background: ${getColor('white')};
      border-radius: 20px;
      padding: 22px 24px 4px 24px;
      width: 100%;

      ${mediaUpTo(
        'desktop',
        css`
          margin-top: 24px;
        `
      )}
    }

    .description-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
  }

  .web-title-container {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 16px;
    margin-top: 48px;
    width: 100%;
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }
`;
