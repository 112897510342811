import styled, { css } from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { BodyText } from 'components/general/Typography';

export const SCustomCard = styled(CustomCard)`
  position: relative;
  width: 100%;

  .icon-pad {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 0 4px;
    flex: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${getColor('green10')};

    &.security {
      background-color: ${getColor('green10')};

      & div.svg-icon {
        background-color: ${getColor('green')} !important;
      }

      &.new {
        background-color: ${getColor('green')};

        & div.svg-icon {
          background-color: white !important;
        }
      }
    }

    &.balance {
      background-color: ${getColor('charcoal5')};

      &.new {
        background-color: ${getColor('blue')};

        & div.svg-icon {
          background-color: white !important;
        }
      }
    }
  }

  .dots {
    display: flex;
    justify-content: flex-end;
    width: 24px;
    height: 24px;
  }

  .ellipsis {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: ${getColor('red')};
  }

  ${mediaFrom(
    'desktop',
    css`
      max-width: 648px;
      margin-left: auto;
      margin-right: auto;
    `
  )};

  ${mediaUpTo(
    'mobile',
    css`
      max-width: 648px;
      .dots {
        width: 20px;
        height: 20px;

        .svg-icon {
          width: 20px;
          height: 20px;
        }
      }
      .subject .custom-text-inner {
        font-size: 12px;
      }
    `
  )};
`;

export const SBodyText = styled(BodyText)`
  .custom-text-inner {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SHeader = styled.div`
  padding: 38px 56px;
  border-bottom: 1px solid rgba(236, 232, 220, 1);
  margin-bottom: 48px;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .custom-title-text {
      margin-left: 8px;
    }
  }

  .breadcrumbs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;

    .parent {
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${getColor('charcoal70')};
      margin-right: 20px;
    }
    .subject {
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: ${getColor('blue')};
      margin-left: 20px;
    }
  }

  ${mediaUpTo(
    'desktop',
    css`
      display: none;
    `
  )};
`;
