import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setShowEnrollModal } from 'store/ui.slice';
import { selectImmediateId } from 'store/user/authentication.slice';
import { Loader } from 'components/general/Loader/Loader';
import { getBackendErrorData } from 'utils/helpers/rtqErrorHandling';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useEnrollMutation, useLazyGetCurrentUserQuery, useLazyGetThirdPartyIdsQuery, useGetIsEmailVerifiedQuery } from 'store/user/users.api';
import { ROUTES } from 'vars/const/ROUTES';
import { API_RESPONSE_CODES } from 'vars/const/API_CODES';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Header } from 'views/Main/Header/Header';
import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import { useTranslation, Trans } from 'react-i18next';
import { WebHeader } from 'views/PercapitaPay/WebHeader/WebHeader';
import { Card } from './Card/Card';
import { EnrollHeader } from './EnrollHeader/EnrollHeader';
import { ListItem } from './ListItem/ListItem';
import { SDesktopLayout, SLayout, SNavigationFooter } from './EnrollPage.styles';

export const EnrollPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['percapitaPay', 'header']);
  const { isDesktopSize } = useDeviceDimension();
  const isEmailVerifiedQuery = useGetIsEmailVerifiedQuery();
  const [getCurrentUser, getCurrentUserAPIResult] = useLazyGetCurrentUserQuery();
  const [enrollAPI, enrollAPIResult] = useEnrollMutation();
  const [getThirdPartyIds, getThirdPartyIdsResult] = useLazyGetThirdPartyIdsQuery();
  const immediateId = useSelector(selectImmediateId);

  const isLoading = isEmailVerifiedQuery.isLoading || getCurrentUserAPIResult?.isFetching || getThirdPartyIdsResult.isLoading || getThirdPartyIdsResult.isFetching || enrollAPIResult.isLoading;

  useEffect(() => {
    getCurrentUser();
    getThirdPartyIds();
  }, []);

  useEffect(() => {
    if (enrollAPIResult?.isSuccess) {
      getThirdPartyIds()
        .unwrap()
        .then(() => {
          navigate(ROUTES.termsAndConditions.path);
        })
        .catch(() => {
          navigate(ROUTES.termsAndConditions.path);
        });
    }

    if (enrollAPIResult?.isError && getBackendErrorData(enrollAPIResult.error)?.Code === API_RESPONSE_CODES.GET_PROFILE_ERROR) {
      dispatch(setShowEnrollModal({ isOpen: true, email: getCurrentUserAPIResult?.data?.email }));
    }
  }, [enrollAPIResult?.isSuccess, enrollAPIResult?.isError]);

  const handleEnrollClick = async () => {
    const email = getCurrentUserAPIResult?.data?.email;

    if (email && isEmailVerifiedQuery.data) {
      await enrollAPI({ email });

      if (enrollAPIResult?.isSuccess) {
        getThirdPartyIds()
          .unwrap()
          .then(() => {
            navigate(ROUTES.termsAndConditions.path);
          })
          .catch(() => {
            navigate(ROUTES.termsAndConditions.path);
          });
      }

      if (enrollAPIResult?.isError) {
        dispatch(setShowEnrollModal({ isOpen: true, email }));
      }
    } else if (!isEmailVerifiedQuery.data || !email) {
      navigate(ROUTES.emailVerification.path, { state: {} });
    }
  };

  if (isDesktopSize) {
    return (
      <SDesktopLayout>
        <WebHeader isBackVisible={false} />

        <Header image={images.enrollPayPeriod}>
          <EnrollHeader />
        </Header>

        <CustomRow justifyContent="center" marginBottom={16}>
          <Card
            title={t(`percapitaPayEnroll.Things to Know about Percapita Pay`)}
            list={[
              <Trans i18nKey="percapitaPayEnroll.PercapitaPayNotLoan" ns="percapitaPay" />,
              <Trans i18nKey="percapitaPayEnroll.HourAndWageInformationProvided" ns="percapitaPay" />,
              <Trans i18nKey="percapitaPayEnroll.YouCanGetYourMoney" ns="percapitaPay" />,
              <Trans i18nKey="percapitaPayEnroll.YouCanMakeFourRequests" ns="percapitaPay" />,
              <>
                <Trans i18nKey="percapitaPayEnroll.FeePaidAsPart" ns="percapitaPay" />
                <br />
                {t(`percapitaPayEnroll.ExampleRequest`)}
              </>,
              <Trans i18nKey="percapitaPayEnroll.EarlyAccessFee" ns="percapitaPay" />,
            ]}
          />
        </CustomRow>

        {!immediateId && (
          <SNavigationFooter>
            <Button preset="blue-filled" onClick={handleEnrollClick}>
              {t(`percapitaPayEnroll.Start Enrollment`)}
            </Button>
          </SNavigationFooter>
        )}
      </SDesktopLayout>
    );
  }

  return (
    <SLayout>
      {isLoading && <Loader />}
      <EnrollHeader title={t(`percapitaPayEnroll.Percapita Pay`)} />

      <div className="center-image">
        <img alt="enrollPic" src={images.enrollPayPeriod} height={180} />
      </div>

      <BodyText font="Poppins" color="charcoal" marginTop={58} marginBottom={38} fontWeight="SB" size="M" textType="bodyText" className="image-subtitle">
        {t(`percapitaPayEnroll.Things to Know about Percapita Pay`)}
      </BodyText>

      <div className="enroll-list-name">
        <ListItem marginBottom={17}>
          <Trans i18nKey="percapitaPayEnroll.PercapitaPayNotLoan" ns="percapitaPay" />
        </ListItem>

        <ListItem marginBottom={17}>
          <Trans i18nKey="percapitaPayEnroll.HourAndWageInformationProvided" ns="percapitaPay" />
        </ListItem>

        <ListItem marginBottom={17}>
          <Trans i18nKey="percapitaPayEnroll.YouCanGetYourMoney" ns="percapitaPay" />
        </ListItem>

        <ListItem marginBottom={17}>
          <Trans i18nKey="percapitaPayEnroll.YouCanMakeFourRequests" ns="percapitaPay" />
        </ListItem>

        <ListItem marginBottom={17}>
          <Trans i18nKey="percapitaPayEnroll.FeePaidAsPart" ns="percapitaPay" />
          <br />
          {t(`percapitaPayEnroll.ExampleRequest`)}
        </ListItem>

        <ListItem marginBottom={17}>
          <Trans i18nKey="percapitaPayEnroll.EarlyAccessFee" ns="percapitaPay" />
        </ListItem>
      </div>

      {!immediateId && (
        <div className="footer">
          <Button preset="blue-filled" onClick={handleEnrollClick}>
            {t(`percapitaPayEnroll.Start Enrollment`)}
          </Button>
        </div>
      )}
    </SLayout>
  );
};
