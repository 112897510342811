import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SPageFooter = styled.footer`
  display: block;
  width: 100%;
  padding: 24px 0;
  flex: 0 0 auto;

  ${mediaFrom(
    'desktop',
    css`
      padding: 84px 223px 84px 0;
    `
  )}
`;
