import { feeFilter, open24Filter, wheelchairFilter } from 'views/MoveMoney/ATMLocations/constants';

interface ITags {
  [key: string]: string | boolean;
}

export const createFilterString = (tagsData: ITags, setIsNoFeeFiltered: (value: boolean) => void, setIsOpen24Filtered: (value: boolean) => void) => {
  const filter: string[] = [];
  for (const key in tagsData) {
    if (tagsData[key]) {
      switch (key) {
        case 'noFee':
          filter.push(feeFilter);
          setIsNoFeeFiltered(true);
          continue;
        case 'openNow':
          filter.push(open24Filter);
          setIsOpen24Filtered(true);
          continue;
        case 'wheelchair':
          filter.push(wheelchairFilter);
          continue;
        default:
          continue;
      }
    }
  }
  return filter;
};
