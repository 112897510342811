import styled from 'styled-components';
import { getColor, flex } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  .carousel {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    ::-webkit-scrollbar {
      display: none;
    }

    ${flex('column', 'space-between', 'flex-start')};

    &-body {
      ${flex('row', 'space-between', 'flex-start')};
      gap: 16px;

      & .add-card {
        ${flex('column', 'space-between', 'flex-start')};
      }

      & .add-card-icon {
        ${flex('column', 'space-between', 'flex-start')};
        margin-bottom: 10px;
      }
    }
    &-cards {
      ${flex('column', 'space-between', 'flex-start')};
      gap: 16px;

      &-account {
        ${flex('row', 'space-between', 'flex-start')};
        margin-top: 14px;
        &-details {
          ${flex('column', 'space-between', 'flex-start')};
        }
      }
    }
  }

  .input-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fee-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  .visible div {
    color: ${getColor('red')};
  }

  .hide div {
    color: ${getColor('transparent')};
  }

  .error-text {
    position: relative;
    margin-bottom: 10px;
    height: 15px;

    & div {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`;
