import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.header`
  display: flex;
  justify-content: space-between;

  ${mediaFrom(
    'desktop',
    css`
      padding: 48px 16% 0 !important;

      .image-container {
        img {
          height: 200px;
          width: 200px;
        }
      }
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      flex: 1;
      min-height: 250px;
      padding: 48px 24px 0 24px !important;

      .title-container {
        margin-right: 106px;

        .header-title {
          margin-bottom: 24px;
        }
      }

      .image-container {
        img {
          height: 176px;
          width: 176px;
        }
      }
    `
  )};
`;
