import { Loader } from 'components/general/Loader/Loader';
import { SmsCodeForm } from 'components/general/SmsCodeForm/SmsCodeForm';
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeEmailGenerateCodeMutation, useChangeEmailVerificationFinishMutation, useEditUserProfileDataMutation } from 'store/user/users.api';
import { handleError } from 'utils/helpers/errorHelper';
import { ResendCodeText } from 'views/OpenCashAccount/MyInfo/EmailPage/SmsCodePage/ResendCodeText/ResendCodeText';
import clsx from 'clsx';
import { Button } from 'components/theme/Button/Button';
import { SCustomSheet } from './EditEmail.styles';

interface IEditEmailVerificationSheetProps {
  className?: string;
  email?: string;
  isOpen?: boolean;
  onClose: () => void;
}

export const EditEmailVerificationSheet = ({ email, isOpen, onClose, className }: IEditEmailVerificationSheetProps) => {
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('profile');
  const [code, setCode] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const [generateEmailChangeCode, generateEmailChangeCodeResult] = useChangeEmailGenerateCodeMutation();
  const [verifyCode, verifyCodeResult] = useChangeEmailVerificationFinishMutation();
  const [editUserProfileData, editUserProfileDataResult] = useEditUserProfileDataMutation();
  const isLoading = generateEmailChangeCodeResult.isLoading || verifyCodeResult.isLoading || editUserProfileDataResult.isLoading;

  const resendCode = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (email) {
      generateEmailChangeCode({ newEmail: email });
    }
  };

  const handleCompletion = (smsCode: string) => {
    setCode(smsCode);
    setIsError(false);
  };

  const handleVerify = () => {
    setIsError(false);
    verifyCode({ code, transactionId: generateEmailChangeCodeResult?.data.transactionId });
  };

  const handleClose = () => {
    setCode('');
    onClose();
  };

  const changeEmail = () => {
    editUserProfileData({ profileData: { email } }).unwrap().then(handleClose).catch(handleError);
  };

  useEffect(() => {
    if (verifyCodeResult?.isSuccess) {
      changeEmail();
    }

    if (verifyCodeResult?.isError || generateEmailChangeCodeResult?.isError) {
      setIsError(true);
    }
  }, [verifyCodeResult?.isSuccess, verifyCodeResult?.isError, generateEmailChangeCodeResult?.isError]);

  useEffect(() => {
    if (email) {
      generateEmailChangeCode({ newEmail: email });
    }
  }, [email]);

  useEffect(() => {
    setIsError(false);

    return () => {
      setIsError(false);
    };
  }, []);

  return (
    <SCustomSheet
      isOpen={isOpen}
      isModal={isDesktopSize}
      header={false}
      wrapperPadding={false}
      modalName="editEmailVerification"
      paddingTop="32px"
      paddingBottom="56px"
      onClose={handleClose}
      className={clsx('edit-email-verification-sheet', className)}
    >
      {isLoading && <Loader />}
      <Title color="charcoal" fontWeight="SB" size="S" marginBottom={32} font="Poppins" textAlign="start">
        {t(`profile.EmailVerification`)}
      </Title>

      <CustomCard className="verification-code-container" borderRadius={20} marginTop={0} marginBottom={64} border="2px solid #EAEAEA">
        <CustomRow flexDirection="column" justifyContent="flex-start">
          <BodyText className="description" textType="bodyText" size="N" fontWeight="R" color="charcoal" marginBottom={24}>
            {t('profile.Verification')}
          </BodyText>
          {isOpen && <SmsCodeForm placeholder="_" isWrongCode={isError} handleCompletion={handleCompletion} onCompletion={setIsSubmitDisabled} inputTheme="my-info-big" />}
        </CustomRow>

        {isError && isDesktopSize && (
          <BodyText className="invalid-code-desktop" textType="bodyText" size="N" fontWeight="R" color="red" justifyContent="center" marginTop={12}>
            {t('profile.InvalidCode')}
          </BodyText>
        )}

        {isDesktopSize && <ResendCodeText resendCode={resendCode} />}
      </CustomCard>

      {isError && !isDesktopSize && (
        <BodyText className="invalid-code-mobile" textType="bodyText" size="N" fontWeight="R" font="Poppins" color="red" justifyContent="center" marginTop={12} marginBottom={32}>
          {t('profile.InvalidCode')}
        </BodyText>
      )}
      <Button preset="blue-filled" type="submit" size="middleStretch" className="button-verify" disabled={isSubmitDisabled || isLoading} onClick={handleVerify}>
        {t('profile.Verify')}
      </Button>
      {!isDesktopSize && <ResendCodeText resendCode={resendCode} />}
    </SCustomSheet>
  );
};
