import React from 'react';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';
import { IAnswer } from 'vars/types/survey.types';

interface IChoiceMultiple {
  surveyQuestionId: string;
  surveyQuestionAnswerId: string;
  userAnswer: string;
  checked?: boolean;
  onChange: (answer: IAnswer) => void;
}

export const ChoiceMultiple: React.FC<IChoiceMultiple> = ({ surveyQuestionId, surveyQuestionAnswerId, userAnswer, checked = false, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ surveyQuestionId, surveyQuestionAnswerId, userAnswer: event.target.checked ? userAnswer : '' });
  };

  return (
    <CustomRow justifyContent="flex-start" alignItems="center">
      <Checkbox checked={checked} onChange={handleChange} />
      <BodyText textType="bodyText" display="flex" textAlign="center" size="N" fontWeight="R" color="charcoal70" font="DM Sans">
        {userAnswer}
      </BodyText>
    </CustomRow>
  );
};
