import React from 'react';
import { ProfileChangePasswordPage } from 'views/Profile/ChangePassword/ProfileChangePasswordPage';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import clsx from 'clsx';
import { useToggle } from 'utils/hooks/useToggle';
import { EditLegalName } from './EditLegalName';
import { EditName } from './EditName';
import { EditAddress } from './EditAddress';
import { EditMailingAddress } from './EditMailingAddress';
import { EditEmail } from './EditEmail/EditEmail';
import { EditUsername } from './EditUsername';
import { EditPhoneNumber } from './EditPhoneNumber/EditPhoneNumber';
import { SCustomSheet } from './ProfileEditSheet.styles';
import { EditEmailVerificationSheet } from './EditEmail/EditEmailVerificationSheet';
import { PhoneCodeVerificationSheet } from './EditPhoneNumber/PhoneCodeVerificationSheet';

interface IProps {
  isSheetOpen?: boolean;
  closeSheet?: () => void;
  type?: string;
  mode?: string;
  initVerifyCode?: () => void;
}

const sheetContentMap: { [key: string]: (props: IProps) => JSX.Element } = {
  legalName: EditLegalName,
  preferredName: EditName,
  username: EditUsername,
  address: EditAddress,
  mailingAddress: EditMailingAddress,
  phoneNumber: EditPhoneNumber,
  email: EditEmail,
  password: ProfileChangePasswordPage,
};

interface IProfileEditSheetProps {
  isOpen: boolean;
  type: string;
  className?: string;
  mode?: string;
  closeSheet: () => void;
}

export const ProfileEditSheet = ({ isOpen, type, className, mode = 'edit', closeSheet }: IProfileEditSheetProps) => {
  const { isDesktopSize } = useDeviceDimension();
  const ContentComponent = type !== undefined ? sheetContentMap[type] : null;
  const verifyEmailCodeModal = useToggle(false, { email: '' });
  const verifyPhoneCodeModal = useToggle(false, { newPhone: '' });

  const initVerifyCode = (nevVal = '') => {
    if (nevVal && type === 'email') {
      verifyEmailCodeModal.setData({ email: nevVal });
      verifyEmailCodeModal.show();
    }
    if (nevVal && type === 'phoneNumber') {
      verifyPhoneCodeModal.setData({ newPhone: nevVal });
      verifyPhoneCodeModal.show();
    }
    closeSheet();
  };

  const handleClosePhoneVerification = () => {
    verifyPhoneCodeModal.setData({ newPhone: '' });
    verifyPhoneCodeModal.hide();
  };
  const handleCloseEmailVerification = () => {
    verifyEmailCodeModal.setData({ email: '' });
    verifyEmailCodeModal.hide();
  };

  return (
    <>
      <SCustomSheet
        isModal={isDesktopSize}
        height="fit-content"
        isOpen={isOpen}
        header={false}
        wrapperPadding={isDesktopSize}
        paddingTop="32px"
        padding="18px"
        onClose={closeSheet}
        className={clsx(type, className, 'profile-edit-sheet', isDesktopSize && 'desktop')}
        modalName="profileEdit"
      >
        {ContentComponent && <ContentComponent isSheetOpen={isOpen} closeSheet={closeSheet} type={type} mode={mode} initVerifyCode={initVerifyCode} />}
      </SCustomSheet>
      <EditEmailVerificationSheet className="email-code-verification-sheet" isOpen={verifyEmailCodeModal.isActive} onClose={handleCloseEmailVerification} {...verifyEmailCodeModal.data} />
      <PhoneCodeVerificationSheet className="phone-code-verification-sheet" isOpen={verifyPhoneCodeModal.isActive} onClose={handleClosePhoneVerification} {...verifyPhoneCodeModal.data} />
    </>
  );
};
