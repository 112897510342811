import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SAuthPage = styled.div`
  width: 100%;
`;

export const SAuthPageContent = styled.div`
  ${mediaFrom(
    'tablet',
    css`
      min-width: 488px;
      max-width: 580px;
      margin: 0 auto;
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      .title {
        margin-bottom: 0;
      }

      .custom-title-text {
        font-size: 18px;
      }
    `
  )}
`;

export const SAuthPageContentBox = styled.div`
  ${mediaFrom(
    'tablet',
    css`
      background: ${getColor('white')};
      padding: 32px 24px;
      border-radius: 20px;
    `
  )}
`;

export const AuthPage = {
  Content: SAuthPageContent,
  ContentBox: SAuthPageContentBox,
  Container: SAuthPage,
};
