import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SHeader = styled.div`
  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: space-between;

  .mobile-image-container img {
    width: 124px;
    height: auto;
  }

  ${mediaFrom(
    321,
    css`
      padding: 0 4px;

      .mobile-image-container img {
        width: auto;
        height: auto;
      }

      .body-title .custom-title-text {
        font-size: 28px;
      }

      .body-description .custom-text-inner {
        font-size: 14px;
      }
    `
  )}

  ${mediaFrom(
    577,
    css`
      justify-content: flex-start;

      .body-title {
        margin-top: 48px;

        .custom-title-text {
          font-size: 32px;
        }
      }

      .body-description .custom-text-inner {
        font-size: 18px;
      }
    `
  )}
  
  ${mediaFrom(
    'desktop',
    css`
      .body-title {
        margin-bottom: 16px;
      }
    `
  )}

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    ${mediaFrom(
      321,
      css`
        margin-top: 72px;
      `
    )}

    ${mediaFrom(
      577,
      css`
        margin-top: 54px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        width: 580px;
      `
    )}

    .web-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;

      img {
        width: 100%;
      }

      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}
    }

    .mobile-image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      ${mediaFrom(
        577,
        css`
          display: none;
        `
      )}
    }

    .continue-container {
      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}

      button {
        min-width: 312px !important;
        height: 52px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 54px;

        ${mediaFrom(
          577,
          css`
            margin-top: 40px;
          `
        )}
      }
    }
  }

  .mobile-footer {
    width: 100%;
    margin-bottom: 28px;
    margin-top: 20px;

    ${mediaFrom(
      321,
      css`
        margin-bottom: 48px;
      `
    )}

    ${mediaFrom(
      577,
      css`
        display: none;
      `
    )}

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }

      ${mediaFrom(
        321,
        css`
          font-size: 18px;
          padding: 12px 14px;
        `
      )}
    }
  }

  footer {
    ${mediaFrom(
      577,
      css`
        display: none;
      `
    )}

    margin-top: auto;
  }
`;
