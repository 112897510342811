import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import styled, { css } from 'styled-components';
import { TITLE_SIZE } from 'components/general/Typography/Title/Title.styles';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { Button } from 'components/theme/Button/Button';

export const SFooter = styled(CustomRow)`
  background: ${getColor('white')};
  padding: 20px 0;
  box-shadow: 0px -10px 10px ${getColor('white')};
`;

export const SMediaLayout = styled.div`
  ${mediaUpTo(
    'desktop',
    css`
      .custom-title-text {
        font-size: ${TITLE_SIZE.T};
      }
    `
  )}
`;

export const SLayout = styled.div<{ isDesktopSize?: boolean }>`
  ${({ isDesktopSize }) =>
    isDesktopSize
      ? css`
          display: flex;
          justify-content: center;
        `
      : css`
          padding-top: 32px;
        `}
    }
`;

export const SButton = styled(Button)`
  :disabled {
    background: transparent !important;
    border: 2px solid ${getColor('charcoal40')};
    color: ${getColor('charcoal40')};
  }

  ${mediaFrom(
    'desktop',
    css`
      min-width: 145px !important;
      max-width: 170px !important;
      height: 52px;

      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;

      align-self: center;

      :disabled {
        background: ${getColor('charcoal40')} !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('white')};
      }
    `
  )}
`;
