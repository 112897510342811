export const getCardTypeFromApiResp = (str: string) =>
  str
    .replace(/[0-9]/g, '')
    .replace(/([A-Z])/g, ' $1')
    .trim();

export const getOnlyNumbersFromString = (str: string) => str.replace(/\D/g, '');

export const capitalize = (str: string): string => {
  const firstLetter = str.charAt(0).toUpperCase();
  const restOfWord = str.slice(1).toLowerCase();
  return firstLetter + restOfWord;
};

export const capitalizeWords = (str: string): string => {
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  return capitalizedWords.join(' ');
};

export const snakeCaseToCamelCase = (input: string): string => {
  return input.toLowerCase().replace(/([-_]\w)/g, (match) => match[1].toUpperCase());
};

export const normalizeScreamingSnakeCase = (text: string): string => {
  const words = text.split('_');
  const formattedWords = words.map((word) => {
    const firstChar = word.charAt(0).toUpperCase();
    const remainingChars = word.slice(1, word.length).toLowerCase();
    return firstChar + remainingChars;
  });

  return formattedWords.join(' ');
};

export const toTitleCase = (input: string): string => {
  return input
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/^\w/, (c) => c.toUpperCase());
};

export const filterAlphaNumeric = (str: string) => str.replace(/[{}]/g, '');
export const implodeString = (str: (string | undefined)[], divider = ', '): string => str.filter((val) => val).join(divider);
export const getPositiveNumberFromString = (str: string) => (Number(str) < 0 ? '0' : str);

export const hasSubstr = (str: string, substr: string) => str.trim().toLowerCase().includes(substr.trim().toLowerCase());

export const toUpperCaseWithUnderline = (str: string) => str?.trim().toUpperCase().split(' ').join('_');
