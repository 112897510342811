import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useLazyGetDocumentRequestsQuery, useUploadRequestedDocumentsMutation } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { handleError } from 'utils/helpers/errorHelper';
import { useToggle } from 'utils/hooks/useToggle';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from 'store/ui.slice';
import { Footer } from 'views/Profile/ProfilePage/Footer/Footer';
import { FileItem } from './FileItem/FileItem';
import { UploadSheet } from './DocumentSheets/UploadSheet';
import { SLayout, SBody, SCustomRow } from './UploadDocumentPage.styles';

interface IRequestedDocumentItem {
  id: string;
  description: string;
  name: string;
}

export interface IUploadedDocumentItem {
  file: File & { uid?: string };
  index: number;
  documentTypeId: string;
}

interface IDocumentTextData {
  requestTitle: string;
  requestSubTitle: string;
  description: string;
}

export const UploadDocumentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('documents');
  const dispatch = useDispatch();
  const location = useLocation();
  const { isDesktopSize } = useDeviceDimension();
  const currentDocumentRequestId = location.state?.documentRequestId || null;

  const [getRequestDocumentsAPI, { isFetching, currentData, isSuccess, isError, error }] = useLazyGetDocumentRequestsQuery();
  const [uploadRequestedDocumentsAPI, uploadRequestedDocumentsAPIResult] = useUploadRequestedDocumentsMutation();

  const [requestedDocuments, setRequestedDocuments] = useState<IRequestedDocumentItem[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<IUploadedDocumentItem[]>([]);
  const [documentRequestId, setDocumentRequestId] = useState('');
  const [documentTextData, setDocumentTextData] = useState<IDocumentTextData>();

  const uploadSheet = useToggle(false);

  // Which field selection is active
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);

  // Upload handlers
  const handleUploadFieldClick = (index: number) => {
    setSelectedFieldIndex(index);
    uploadSheet.show();
  };

  const handleFileUploaded = (file: File) => {
    uploadSheet.hide();

    const uploadedFileItem = {
      file,
      index: selectedFieldIndex,
      documentTypeId: requestedDocuments[selectedFieldIndex]?.id,
    };

    setUploadedDocuments([...uploadedDocuments, uploadedFileItem]);
  };

  // Navigation handlers
  const handleUploadLater = () => {
    navigate(ROUTES.home.path);
  };

  const handleContinue = () => {
    uploadedDocuments.forEach((item: IUploadedDocumentItem) => {
      const fileData = new FormData();
      fileData.append('file', item.file);

      const requestBody = {
        fileData,
        documentRequestId,
        documentTypeId: item.documentTypeId,
      };

      uploadRequestedDocumentsAPI(requestBody);
    });
  };

  const handleDeleteFile = (fileItem: IUploadedDocumentItem) => {
    const filteredDocuments = uploadedDocuments.filter((item) => item.file.uid !== fileItem.file.uid);
    setUploadedDocuments(filteredDocuments);
  };

  const handleBack = () => {
    navigate(ROUTES.home.path);
  };

  useEffect(() => {
    getRequestDocumentsAPI({});
  }, [getRequestDocumentsAPI]);

  useEffect(() => {
    if (isSuccess && currentData?.length) {
      const extractedData = currentDocumentRequestId ? currentData.find((item: any) => item.id === currentDocumentRequestId) : currentData[0];

      dispatch(setHeaderTitle(extractedData?.requestType?.windowTitle));
      setRequestedDocuments(extractedData?.requestType.requestedDocuments);
      setDocumentRequestId(extractedData.id);

      setDocumentTextData({
        requestTitle: extractedData?.requestType?.requestTitle,
        requestSubTitle: extractedData?.requestType?.requestSubTitle,
        description: extractedData?.requestType?.description,
      });
    }
    if (isError) handleError(error);
  }, [isError, isSuccess, currentData, error]);

  useEffect(() => {
    if (uploadRequestedDocumentsAPIResult.isSuccess) navigate(ROUTES.home.path);
    if (uploadRequestedDocumentsAPIResult.isError) handleError(uploadRequestedDocumentsAPIResult.error);
  }, [uploadRequestedDocumentsAPIResult, navigate]);

  if (isFetching || uploadRequestedDocumentsAPIResult.isLoading) return <Loader />;

  return (
    <SLayout>
      <div>
        <SCustomRow justifyContent="space-between" width="100%">
          <div>
            <Title size="M" fontWeight="SB" marginBottom={32} marginTop={isDesktopSize ? 0 : 15} className="title">
              {documentTextData?.requestTitle ?? '...'}
            </Title>

            <BodyText textType="bodyText" font="Poppins" color="charcoal" fontWeight="M" size="L" marginBottom={8} lineHeight={1.4} className="subtitle">
              {documentTextData?.requestSubTitle ?? '...'}
            </BodyText>
          </div>
          {isDesktopSize && (
            <Button size="middleAlt" onClick={handleBack}>
              {t(`changeAddress.ExitAddressChanging`)}
            </Button>
          )}
        </SCustomRow>

        <SBody>
          <div className="body">
            {isDesktopSize ? (
              <Title size="T" color="charcoal" fontWeight="SB" marginBottom={24} lineHeight={1.6}>
                {documentTextData?.description ?? '...'}
              </Title>
            ) : (
              <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={32} lineHeight={1.4}>
                {documentTextData?.description ?? '...'}
              </BodyText>
            )}

            {currentData?.length > 0 && (
              <CustomCard borderRadius={20} className="upload-card">
                {requestedDocuments.map((item, index) => (
                  <FileItem
                    title={item.description}
                    deleteFile={handleDeleteFile}
                    uploadedDocuments={uploadedDocuments}
                    uploadSelected={handleUploadFieldClick}
                    index={index}
                    key={item.id}
                    isMultiple
                  />
                ))}
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight={1.4}>
                  {t('documents.Review')}
                </BodyText>
              </CustomCard>
            )}

            {/* <CustomCard borderRadius={20} className="upload-card">
          <FileItem title="test" deleteFile={handleDeleteFile} uploadedDocuments={uploadedDocuments} uploadSelected={handleUploadFieldClick} index={0} key={0} isMultiple />
          <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight={1.4}>
            {t('documents.Review')}
          </BodyText>
        </CustomCard> */}
          </div>
        </SBody>
      </div>

      {!isDesktopSize && (
        <div className="footer">
          <Button marginBottom={24} preset="blue-filled" onClick={handleContinue} disabled={uploadedDocuments.length < requestedDocuments.length}>
            {t('documents.Continue')}
          </Button>

          <BodyText textType="bodyText" font="Poppins" size="N" fontWeight="M" justifyContent="center" color="blue" marginBottom={24} cursorPointer onClick={handleUploadLater}>
            {t('documents.UploadLater')}
          </BodyText>

          <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal50" justifyContent="center" textAlign="center">
            {t('documents.PercapitaDesc')}
          </BodyText>
        </div>
      )}

      <UploadSheet fileUploaded={handleFileUploaded} show={uploadSheet.isActive} onClose={uploadSheet.hide} />

      {isDesktopSize && (
        <>
          <Footer onClick={handleContinue} onBack={handleUploadLater} />
          <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginTop={12} lineHeight={1.3} marginBottom={24} paddingBottom={24} paddingLeft={98} paddingRight={98}>
            {t(`documents.PercapitaGroupIncIsADigitalCompanyThatProvidesAccess`)}
          </BodyText>
        </>
      )}
    </SLayout>
  );
};
