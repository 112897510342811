import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    gap: 8px;

    .number {
      font-size: 16px;
      font-weight: 500;
      font-family: DM Sans;
      color: ${getColor('charcoal')};

      * {
        @media screen and (max-width: 427px) {
          font-size: 14px !important;
        }
      }

      ${mediaFrom(
        'tablet',
        css`
          display: none;
        `
      )};
    }

    .text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      * {
        @media screen and (max-width: 427px) {
          font-size: 12px !important;
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        color: ${getColor('charcoal70')};
        font-family: DM Sans;

        ${mediaFrom(
          'tablet',
          css`
            font-size: 14px;
          `
        )}
      }
    }
  }

  .clipboard-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    span {
      font-size: 16px;
      font-weight: 500;
      font-family: DM Sans;

      ${mediaFrom(
        'tablet',
        css`
          font-weight: 700;
        `
      )}

      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}
    }
  }
`;
