import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { selectHelpData } from 'store/user/help.slice';
import { ROUTES } from 'vars/const/ROUTES';
import { selectHeaderTitle } from 'store/ui.slice';
import { Icon } from 'components/general/Icon/Icon';
import { useRouteConfig } from 'utils/hooks/useRouteConfig';
import { useNavBack } from 'utils/hooks/useNavBack';
import { BodyText } from 'components/general/Typography';
import { triggerEvent } from 'utils/helpers/events';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { useTranslation } from 'react-i18next';
import { useChat } from 'utils/hooks/useChat';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { selectUserNotifications } from 'store/user/notificationsCenter/notificationsCenter.slice';
import { INotificationItem } from 'store/user/notificationsCenter/notificationsCenter.types';
import { SHeaderBar, SHeaderLeftBtn, SHeaderRightBtn, SLeftBtnContainer, SProfileBtn, SRightButtons } from './MobileHeader.styles';
import { IMobileHeaderProps, IPageState } from './MobileHeader.types';

export const MobileHeader = ({ headerTitle = '' }: IMobileHeaderProps) => {
  const location = useLocation();
  const { isMobileSmallSize } = useDeviceDimension();
  const navigate = useNavigate();
  const { navigateBack, isBackNavigatable } = useNavBack();
  const { open } = useChat();
  const { t, ready } = useTranslation('header');
  const { previousTab } = useSelector(selectHelpData);
  const { noRightButtons, title: routeTitle, isProfileButtonVisible, isTitleVisible, isIFramePage } = useRouteConfig();
  const storedHeaderTitle = useSelector(selectHeaderTitle);
  const { editingModeHeaderTitle } = (location?.state as IPageState) || '';
  const currentUser = useSelector(selectCurrentUser);
  const { userNotifications } = useSelector(selectUserNotifications);

  // Checking if page was opened from direct link
  const shouldShowRightButtons = !isIFramePage && !noRightButtons;
  const isNotificationsCenter = useMatch(ROUTES.notificationsCenter.path);
  const isWellnessQuestion = useMatch(ROUTES.wellnessFAQ.path) && previousTab === 'help-and-support';
  const isWellnessQuestionActive = useMatch(ROUTES.wellnessFAQ.path) && previousTab !== 'help-and-support';
  const isWellnessTitleVisible = useMatch('/wellness') || isWellnessQuestion || isWellnessQuestionActive;
  const policies = useSelector(selectPolicies);
  const userFullName = `${currentUser?.firstName?.[0]?.toUpperCase() ?? ''} ${currentUser?.lastName?.[0]?.toUpperCase() ?? ''}`.trim();

  const unreadNotifications = useMemo(() => {
    return userNotifications?.results.filter((item: INotificationItem) => item.status === 'New') || [];
  }, [userNotifications]);

  const handleBackBtnClick = useCallback(() => {
    triggerEvent('backClick');
    navigateBack();
  }, [triggerEvent, navigateBack]);

  const handleProfileButtonClick = () => {
    navigate(ROUTES.profile.path);
  };

  const handleBellIconClick = () => {
    if (isNotificationsCenter) {
      navigate(-1);
    } else {
      navigate(ROUTES.notificationsCenter.path);
    }
  };

  const handleProfileIconClick = () => {
    navigate(ROUTES.profileSettings.path);
  };

  return (
    <SHeaderBar>
      <SLeftBtnContainer>
        {(isBackNavigatable || isIFramePage) && (
          <SHeaderLeftBtn onClick={handleBackBtnClick} data-testid="back-btn" style={{ marginLeft: '17px' }}>
            <Icon size="small" name="arrowLeft" color="white" cursorPointer />
          </SHeaderLeftBtn>
        )}
        {isTitleVisible && !isWellnessQuestion && (
          <BodyText textType="bodyText" marginLeft={isBackNavigatable ? 0 : 14} color="white" size={isMobileSmallSize ? 'T' : 'M'} fontWeight="B">
            {ready && (editingModeHeaderTitle || storedHeaderTitle || (routeTitle && t(`header.${routeTitle}`)) || headerTitle || (isWellnessTitleVisible && t(`header.${ROUTES.wellness.title}`)))}
          </BodyText>
        )}
      </SLeftBtnContainer>
      {shouldShowRightButtons ? (
        <SRightButtons>
          <>
            {isProfileButtonVisible && (
              <SHeaderRightBtn onClick={handleProfileButtonClick} marginRight={10}>
                <Icon size="small" name="profile" color="white" cursorPointer />
              </SHeaderRightBtn>
            )}
            <SHeaderRightBtn onClick={open}>
              <Icon size="small" name="chat" color="white" cursorPointer />
            </SHeaderRightBtn>
            {policies?.NotificationsEnabled && (
              <SHeaderRightBtn onClick={handleBellIconClick} className="notifications" extraStyles={{ marginLeft: `${isMobileSmallSize ? '12px' : '20px'}` }}>
                <Icon size="small" name="bell" color="white" cursorPointer />
                {unreadNotifications.length ? <span className="notifications">{unreadNotifications.length}</span> : null}
                <span className={isNotificationsCenter ? 'point' : ''} />
              </SHeaderRightBtn>
            )}
            <SProfileBtn onClick={handleProfileIconClick} className={location.pathname === ROUTES.profileSettings.path ? 'active' : ''}>
              {userFullName || <Icon size="small" name="profile" color={location.pathname === ROUTES.profileSettings.path ? 'blue' : 'white'} cursorPointer />}
            </SProfileBtn>
          </>
        </SRightButtons>
      ) : (
        <SRightButtons>
          <SHeaderRightBtn onClick={open}>
            <Icon size="small" name="chat" color="white" cursorPointer />
          </SHeaderRightBtn>
        </SRightButtons>
      )}
    </SHeaderBar>
  );
};
