import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { SLayout } from './TopicCard.styles';

interface ITopicCardProps {
  children: string | React.ReactNode;
  title: string;
  isCollapsible?: boolean;
  isAlwaysOpen?: boolean;
}

export const TopicCard = ({ children, title, isAlwaysOpen = false, isCollapsible = false }: ITopicCardProps) => {
  const answer = useToggle();
  const arrowIconName = useMemo(() => {
    if (isCollapsible) {
      return answer.isActive ? 'chevronUp' : 'chevronDown';
    }
    return 'chevronRight';
  }, [isCollapsible, answer.isActive]);

  const handleClick = () => {
    if (isCollapsible && !isAlwaysOpen) {
      answer.toggle();
    }
  };

  return title ? (
    <SLayout className={clsx('topics', answer.isActive && 'opened')}>
      <div onClick={handleClick} className="topics__question">
        <BodyText fontWeight="B" size="M" color={answer.isActive ? 'blue' : 'charcoal'}>
          {title}
        </BodyText>
        <Icon name={arrowIconName} size="smallest" color="blue" />
      </div>
      {(isAlwaysOpen || answer.isActive) && <div className="topics__answer">{children}</div>}
    </SLayout>
  ) : null;
};
