import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { images } from 'assets';
import { Button } from 'components/theme/Button/Button';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { SLink } from 'views/Account/PennyJar/PennyJarSettings.styles';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { useConsents } from 'utils/hooks/useConsents';
import { IConsentData } from 'store/user/consents/consents.types';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { SimpleAccountSelectSheet } from 'components/general/Accounts/AccountSelect/SimpleAccountSelectSheet/SimpleAccountSelectSheet';
import { useAccounts } from 'utils/hooks/useAccounts';
import { AccountFeatureLayout } from 'components/layouts/AccountFeatureLayout/AccountFeatureLayout';
import { useAllCashAccountsFeatures } from 'utils/hooks/useAllCashAccountsFeatures';
import { CongratsModal } from './CongratsModal';

export const CashCushion = () => {
  const { t } = useTranslation('cashCushion');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { cashAccounts } = useAccounts();
  const cashAccountIdParam = searchParams.get('accountId');
  const cashAccount = cashAccounts.find((acc) => acc.accountId === cashAccountIdParam);
  const { overdraftFeatures, isLoading, setCashCushion, isFeatureChangeSuccess } = useAllCashAccountsFeatures();
  const disabledAccountsForCashCushionIds = overdraftFeatures.filter((feature) => feature.isEnabled || !feature.overdraft.isEligible).map((feature) => feature.accountId);
  const hasEligibleOverdraftFeatures = overdraftFeatures.some((feature) => feature.overdraft.isEligible);
  const accountSelectSheet = useToggle(false);
  const congratsModal = useToggle(false, { accountId: '' });
  const depAccAgrConsentSheet = useToggle();
  const otherConsents = useConsents(ConsentType.OTHERS);
  const consentData = otherConsents?.consentsData?.find((item) => item?.id === ConsentId.ESIGN_CONSENT) ?? ({} as IConsentData);

  const turnOnCashCushion = async (cashAcc: IAccountItem) => {
    accountSelectSheet.hide();
    setCashCushion(cashAcc.accountId, true);
    congratsModal.setData({ accountId: cashAcc.accountId });
  };

  const onDepositAccAgrClick = () => {
    if (consentData?.id) depAccAgrConsentSheet.show();
  };

  const handleOnContinue = async () => {
    if (cashAccount) {
      turnOnCashCushion(cashAccount);
    } else {
      if (hasEligibleOverdraftFeatures) {
        accountSelectSheet.show();
      } else {
        navigate(ROUTES.home.path);
      }
    }
  };

  const handleCloseModal = () => {
    congratsModal.hide();
    navigate(`/account_information/percapita/${congratsModal.data?.accountId}`);
  };

  useEffect(() => {
    if (isFeatureChangeSuccess) {
      accountSelectSheet.hide();
      congratsModal.show();
    }
  }, [isFeatureChangeSuccess]);

  return (
    <AccountFeatureLayout isLoading={isLoading} header={t('CashCushion')} title={t('ProtectYourself')} subtitle={t('YouAreEligible')}>
      <div className="center">
        <div className="info-card">
          <div className="logo-container">
            <img src={images.cashCushion} className="logo" alt="Cash Cushion" />
          </div>

          <div className="text-container">
            <span className="title">{t(`Example`)}</span>
            <span className="description">{t(`IfCashAccountIsDown`)}</span>
            <span className="description">{t(`YourBalanceWouldRemainNegative`)}</span>
            <span className="description">{t(`AsLongAsYouRepay15Days`)}</span>
            <span className="footnotes">{t(`CashCushionOptional`)}</span>
          </div>
        </div>
      </div>
      <div className="btn-container">
        <Button preset="blue-filled" onClick={handleOnContinue} marginBottom={20} marginTop={20}>
          {t('OptInToCashCushion')}
        </Button>
      </div>

      <BodyText color="charcoal70" size="T" marginBottom={48}>
        <Trans i18nKey={t('YouCanSeeMore')} ns="cashCushion" components={{ span: <SLink onClick={onDepositAccAgrClick} /> }} />
      </BodyText>

      <CongratsModal open={congratsModal.isActive} onClose={handleCloseModal} />
      <ConsentSheet consentData={consentData} isOpen={depAccAgrConsentSheet.isActive} onClose={depAccAgrConsentSheet.hide} isReadonly />
      <SimpleAccountSelectSheet
        accounts={cashAccounts}
        isOpen={accountSelectSheet.isActive}
        onClose={accountSelectSheet.hide}
        disableIds={disabledAccountsForCashCushionIds}
        onSelect={turnOnCashCushion}
        title={t('SelectAccountForCashCushion')}
      />
    </AccountFeatureLayout>
  );
};
