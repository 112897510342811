import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .dialog-body-wrapper {
    .body {
      display: flex;
      flex-direction: column;

      .verification-code-container {
        .sms-code-form {
          input {
            font-size: 18px !important;

            &::placeholder {
              font-size: 18px;
              font-family: DM Sans, sans-serif;
            }
          }
        }
      }

      .resend-code-container {
        .link {
          .custom-text-inner {
            font-family: Poppins, sans-serif;
            font-weight: 500;
          }
        }
      }

      &:has(.invalid-code-mobile) {
        .verification-code-container {
          margin-bottom: 0;
        }
      }

      .button-verify {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .resend-code-container {
    margin-top: 32px;
  }

  // 1440px
  ${mediaFrom(
    'desktopLarge',
    css`
      width: 45% !important;

      .dialog-body-wrapper {
        .body {
          padding: 20px;

          .title {
            margin-bottom: 24px;

            .custom-title-text {
              font-size: 32px;
              font-weight: 500;
            }
          }

          .verification-code-container {
            margin: 0 0 24px;

            .sms-code-form {
              input {
                &::placeholder {
                  top: 14px;
                  right: 0;
                  bottom: 14px;
                  left: 0;
                }
              }
            }

            .invalid-code-desktop {
              margin-top: 8px;
            }

            .resend-code-container {
              margin-top: 24px;

              .link {
                .custom-text-inner {
                  font-weight: 600;
                }
              }
            }
          }

          .button-verify {
            width: auto;
            align-self: center;
            font-size: 18px;
            font-weight: 600;
            padding: 14px 32px;
            margin: 0 !important;
          }
        }
      }
    `
  )};
`;

export const SError = styled.div`
  color: #ff4d4f;
  padding: 2px 26px;
`;
