import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { BodyText } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SFooter, SLayout } from './Footer.styles';

interface IFooter {
  isDisabled?: boolean;
  onClick: () => void;
  onBack?: () => void;
  secondBtnText?: string;
  zIndex?: number;
  absolutePosition?: boolean;
}

export const Footer: React.FC<IFooter> = ({ isDisabled = false, zIndex = 9999, absolutePosition = false, secondBtnText, onClick, onBack }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <SLayout>
      <SFooter zIndex={zIndex} absolutePosition={absolutePosition}>
        <CustomRow justifyContent="space-between" width="100%">
          <BodyText size="N" color="blue70" fontWeight="SB" font="Poppins" onClick={handleOnBack} cursorPointer>
            {t('documents.UploadLater')}
          </BodyText>
          <Button preset="blue-filled" disabled={isDisabled} onClick={onClick} size="large" width={135}>
            {secondBtnText || t('accountOpening.Continue')}
          </Button>
        </CustomRow>
      </SFooter>
    </SLayout>
  );
};
