import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { mobileApiCall } from 'services/mobileService';
import { useEditUserProfileDataMutation, useGetUserProfileDataMutation } from 'store/user/users.api';
import { lsSetItem } from 'utils/helpers/storage';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { BodyText, Title } from 'components/general/Typography';
import { Form } from 'antd';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { usernameCharacterRule } from 'utils/helpers/validationRules';
import { Button } from 'components/theme/Button/Button';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { Loader } from 'components/general/Loader/Loader';
import { handleError } from 'utils/helpers/errorHelper';
import { SModalCommonLayout } from './common/styles';

interface IFormValues {
  username: string;
}

interface IProps {
  closeSheet?: () => void;
}

export const EditUsername = ({ closeSheet }: IProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const { hasErrors, checkErrors } = useFormHelpers(form);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const userProfileData = useSelector(selectCurrentUser);

  const [editUserProfileData, editUserProfileDataResult] = useEditUserProfileDataMutation();
  const [getUserProfileData] = useGetUserProfileDataMutation();

  const handleFormChange = () =>
    setIsDisabled(hasErrors || form.getFieldValue('username')?.length === 0 || form.getFieldValue('username')?.length === undefined || userProfileData?.username === form.getFieldValue('username'));

  useEffect(() => {
    getUserProfileData({});
  }, []);

  const onFinish = (formValues: IFormValues) => {
    const data = {
      profileData: {
        username: formValues.username,
      },
    };

    closeSheet?.();
    editUserProfileData(data)
      .unwrap()
      .then(() => {
        getUserProfileData({});
      })
      .then(() => mobileApiCall('userNameChange', data.profileData.username))
      .then(() => {
        lsSetItem('savedUserName', formValues.username);
      })
      .catch((error) => handleError(error));
  };

  return (
    <SModalCommonLayout className="body-edit-username">
      <Form onFinish={onFinish} onFieldsChange={checkErrors} form={form} requiredMark={false} layout="vertical" autoComplete="off" initialValues={{ username: userProfileData?.username ?? '' }}>
        <CustomCard padding="0" marginTop={0} className="content-wrapper">
          <Title fontWeight="SB" size="S" color="charcoal" marginBottom={4} font="Poppins" className="title">
            {t(`profile.Username`)}
          </Title>

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={32} className="description">
            {t('profile.PleaseModifyYourUsernameBelow')}
          </BodyText>

          <Form.Item
            label={<CustomRequiredLabel label="profile.Username" fontFamily="DM Sans" />}
            name="username"
            validateTrigger={['onBlur', 'onChange']}
            rules={[usernameCharacterRule()]}
            className="username"
          >
            <BaseInput onKeyUp={handleFormChange} placeholder={t('profile.Enter Your Preferred username here')} data-testid="username" />
          </Form.Item>
        </CustomCard>

        <Form.Item className="form-item-button-submit">
          <Button preset="blue-filled" size="middleStretch" type="submit" disabled={isDisabled}>
            {t(`profile.Save Changes`)}
          </Button>
        </Form.Item>
        {editUserProfileDataResult.isLoading && <Loader />}
      </Form>
    </SModalCommonLayout>
  );
};
