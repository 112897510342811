import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { Icon } from 'components/general/Icon/Icon';
import { useValidateThirdPartyMutation } from 'store/user/accounts/accounts.api';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useToggle } from 'utils/hooks/useToggle';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { useTransfers } from 'utils/hooks/useTransfers';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { getRequiredRule } from 'utils/helpers/validationRules';
import { StateAddressSheet } from 'views/OpenCashAccount/MyInfo/AddressPage/AddressForm/StateAddressDrawer';
import { useRequestBankNameMutation } from 'store/ingo/ingo.api';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { ROUTES } from 'vars/const/ROUTES';
import { Loader } from 'components/general/Loader/Loader';
import { IValidateRequest } from 'store/user/accounts/accounts.types';
import { setShowAdditionalInformationModal, TAdditionalInformationModalType } from 'store/ui.slice';
import { Page } from 'components/layouts/Page/Page';
import { ResultModal } from './ResultModal';
import { SAddBankAccountPage } from './AddNewBankAccountPage.style';

export const AddNewBankAccountPage = () => {
  const { t } = useTranslation(['moveMoney', 'translations']);
  const { currentTransfer, saveCurrentTransfer } = useTransfers();
  const { isDesktopSize } = useDeviceDimension();
  const dispatch = useDispatch();
  const [requestBankName, requestBankNameResult] = useRequestBankNameMutation();
  const stateAddressSheet = useToggle(false);
  const successFailureModal = useToggle(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [bankName, setBankName] = useState('');
  const [form] = Form.useForm();
  const { hasErrors, checkErrors } = useFormHelpers(form);
  const [validateThirdPartyMutationAPI, validateThirdPartyMutationAPIResult] = useValidateThirdPartyMutation();
  const isDisabled = hasErrors || !form.getFieldValue('bankRoutingNumber') || !form.getFieldValue('bankAccountNumber');

  const handleFormChange = () => {
    checkErrors();
    const bankRoutingNumber = form.getFieldValue('bankRoutingNumber');
    const bankAccountNumber = form.getFieldValue('bankAccountNumber');

    if (bankRoutingNumber.length === 9) {
      requestBankName({ routingNumber: bankRoutingNumber, accountNumber: bankAccountNumber ?? '111111111111' });
    } else {
      setBankName('');
    }
  };

  const onFinish = async (formValues: IValidateRequest) => {
    await validateThirdPartyMutationAPI({ ...formValues, bankName })
      .unwrap()
      .then(() => {
        if (currentTransfer.fromId === 'WAITING_TO_ADD' || currentTransfer.toId === 'WAITING_TO_ADD') {
          saveCurrentTransfer({
            ...currentTransfer,
            fromId: currentTransfer.fromId === 'WAITING_TO_ADD' ? 'JUST_ADDED' : currentTransfer.fromId,
            toId: currentTransfer.toId === 'WAITING_TO_ADD' ? 'JUST_ADDED' : currentTransfer.toId,
          });
        }
        setIsSuccess(true);
      })
      .catch(() => setIsSuccess(false));
    successFailureModal.show();
  };

  useEffect(() => {
    if (requestBankNameResult.isSuccess) {
      setBankName(requestBankNameResult?.data?.bankName ?? '');
      form.setFieldValue('bankName', requestBankNameResult?.data?.bankName);
    }
  }, [requestBankNameResult]);

  const showInfoModal = useCallback(
    (modalType: TAdditionalInformationModalType) => {
      dispatch(
        setShowAdditionalInformationModal({
          displayAdditionalInformationModal: true,
          additionalInformationModalType: modalType,
        })
      );
    },
    [setShowAdditionalInformationModal]
  );

  return (
    <SAddBankAccountPage className="add-bank-account-page">
      {validateThirdPartyMutationAPIResult.isLoading && <Loader />}
      {isDesktopSize && <Page.Header title={t('AddExternalAccount')} />}
      <Page.Content className="add-bank-account-page__content">
        <div>
          <Title size="S" fontWeight="SB" color="charcoal" marginBottom={24} marginTop={8}>
            {t('AddNewBankAccount')}
          </Title>
          <Form
            onFinish={onFinish}
            initialValues={{ bankAccountNumber: '', bankRoutingNumber: '' }}
            onFieldsChange={handleFormChange}
            form={form}
            requiredMark={false}
            className="add-bank-account-form"
            layout="vertical"
            autoComplete="off"
          >
            <div>
              <Form.Item
                label={
                  <>
                    {t('addBankAccountPage.RoutingNumber')}
                    <Icon onClick={() => showInfoModal('routingNumber')} name="circleInfo" className="info" color="blue" size="smaller" marginLeft={8} />
                  </>
                }
                name="bankRoutingNumber"
                validateTrigger={['onBlur', 'onChange', 'onPaste']}
                rules={[{ required: true, min: 9, max: 9, message: t('addBankAccountPage.PleaseInputRoutingNumber') }]}
              >
                <BaseInput maxLength={9} data-testid="routing-number" inputMode="numeric" />
              </Form.Item>

              <BodyText textType="bodyText" color="charcoal" fontWeight="R" size="N" marginBottom={8}>
                {t('addBankAccountPage.BankName')}
              </BodyText>

              <div className="bank-name-container">
                <BodyText textType="bodyText" color={bankName ? 'charcoal' : 'charcoal50'} fontWeight="R" size="M">
                  {requestBankNameResult.isLoading && (
                    <>
                      <Loader inline size="small" />
                      {t(`loader.Loading`, { ns: 'translations' })}
                    </>
                  )}
                  {!requestBankNameResult.isLoading && (bankName || t('addBankAccountPage.FillsAutomatically'))}
                </BodyText>
              </div>

              <Form.Item
                label={
                  <>
                    {t('addBankAccountPage.AccountNumber')}
                    <Icon name="circleInfo" onClick={() => showInfoModal('accountNumber')} className="info" color="blue" size="smaller" marginLeft={8} />
                  </>
                }
                name="bankAccountNumber"
                validateTrigger={['onBlur', 'onChange', 'onPaste']}
                rules={[getRequiredRule(t('addBankAccountPage.PleaseInputAccountNumber'))]}
              >
                <BaseInput data-testid="account-number" inputMode="numeric" minLength={1} />
              </Form.Item>
            </div>
            {isDesktopSize && (
              <Button preset="blue-filled" type="submit" disabled={isDisabled} onClick={form.submit}>
                {t(`common.Submit`, { ns: 'translations' })}
              </Button>
            )}
          </Form>
        </div>
        {!isDesktopSize && (
          <Button preset="blue-filled" type="submit" disabled={isDisabled} onClick={form.submit}>
            {t(`common.Submit`, { ns: 'translations' })}
          </Button>
        )}
      </Page.Content>

      <StateAddressSheet open={stateAddressSheet.isActive} onClose={stateAddressSheet.hide} form={form} value="" />
      <ResultModal isSuccess={isSuccess} navPath={ROUTES.transfers.path} open={successFailureModal.isActive} onClose={successFailureModal.hide} />
      <Page.Footer />
    </SAddBankAccountPage>
  );
};
