import styled from 'styled-components';

export const STextBtn = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  float: right;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
