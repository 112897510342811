import React, { useEffect, useState } from 'react';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { useSelector } from 'react-redux';
import { selectPaymentsAccountsData } from 'store/user/payments/payments.slice';
import { selectHistoryFilterParameters, setDestinationAccount } from 'store/historyFilter/historyFilter.slice';
import { IAccount } from 'store/user/payments/payments.types';
import { TThemeColor } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/store';
import { SContent, SSelector } from './DestinationAccount.styles';

export const DestinationAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accounts } = useSelector(selectPaymentsAccountsData);
  const { destinationAccount } = useSelector(selectHistoryFilterParameters);
  const { t } = useTranslation('percapitaPay');

  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleAccountSelect = (account: IAccount) => {
    const { id, name, accountType, alias } = account;
    setSelectedAccount(account);

    dispatch(setDestinationAccount({ id, title: name, iconName: accountType, alias }));
  };

  const getDescription = (accountType: string, details: string) => {
    const cardLastDigits = details?.slice(-4, details.length);
    return accountType === 'BANK' ? `Bank Account (${cardLastDigits})` : `Debit Card (${cardLastDigits})`;
  };

  const getIcon = (accountType: string) => (accountType === 'BANK' ? { iconName: 'bank', iconColor: 'orange' as TThemeColor } : { iconName: 'debitCard', iconColor: 'blue' as TThemeColor });

  /* Reset filter handling */
  useEffect(() => {
    if (destinationAccount.title === 'percapitaPayHome.Select Account') setSelectedAccount(null);
  }, [destinationAccount]);

  return (
    <SSelector onClick={() => setIsOpen(!isOpen)}>
      <div className="content">
        {selectedAccount && <Icon name={getIcon(selectedAccount.accountType).iconName} color={getIcon(selectedAccount.accountType).iconColor} size="small" cursorPointer />}
        <BodyText textType="bodyText" color={selectedAccount ? 'charcoal' : 'charcoal40'} fontWeight="R" size="M" cursorPointer marginLeft={11}>
          {selectedAccount ? `${selectedAccount.name} ${getDescription(selectedAccount.accountType, selectedAccount.details)}` : t('percapitaPayHome.Select Account')}
        </BodyText>
      </div>
      <Icon name={isOpen ? 'chevronDown' : 'chevronRight'} color="charcoal" size="smallest" cursorPointer />

      {isOpen && (
        <SContent onClick={() => setIsOpen(!isOpen)}>
          {accounts?.map((account: IAccount) => (
            <div className="item" key={account.id} onClick={() => handleAccountSelect(account)}>
              <Icon name={getIcon(account.accountType).iconName} color={getIcon(account.accountType).iconColor} size="small" cursorPointer />

              <div className="name-container">
                <span className="account-name">{account.name}</span>
                <span className="account-nickname">{getDescription(account.accountType, account.details)}</span>
              </div>
            </div>
          ))}
        </SContent>
      )}
    </SSelector>
  );
};
