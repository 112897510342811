import styled from 'styled-components';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  button {
    min-width: 149px !important;
    max-width: 180px !important;
    padding: 14px 32px;

    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
  }
`;
