import styled, { css } from 'styled-components';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SCREEN_SIZE, getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaFrom(
    'desktop',
    css`
      flex-direction: row;
      align-items: flex-start;
    `
  )}

  .mobile-filter-container {
    ${mediaFrom(
      SCREEN_SIZE.tablet,
      css`
        display: none;
      `
    )}
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      flex: 0 0 auto;
    }
  }

  .btn-active {
    background-color: ${getColor('blue')} !important;
    border-color: ${getColor('blue')} !important;
  }

  .time-card-btn {
    width: 128px;
    height: 36px;
  }
`;

export const SWebNavigation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${mediaUpTo(
    SCREEN_SIZE.tablet,
    css`
      display: none;
    `
  )}

  ${mediaBetween(
    SCREEN_SIZE.tablet,
    1199,
    css`
      .breadcrumbs {
        padding-left: 0;
      }
    `
  )}
`;

export const SActionBar = styled(CustomRow)`
  ${mediaFrom(
    577,
    css`
      display: none;
    `
  )}

  button {
    background: ${getColor('transparent')} !important;
    border: 2px solid ${getColor('blue')} !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const SListItemLayout = styled(CustomRow)`
  .parts .literal {
    margin-right: 3px;
  }
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .content-container {
    width: 100%;

    .chip-container {
      ${mediaBetween(
        SCREEN_SIZE.tablet,
        1199,
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 65px;

          div:first-of-type {
            width: 75%;
          }
        `
      )}

      ${mediaBetween(
        815,
        1199,
        css`
          div:first-of-type {
            width: 50%;
          }
        `
      )}

      .filter {
        ${mediaUpTo(
          576,
          css`
            display: none;
          `
        )}

        ${mediaFrom(
          SCREEN_SIZE.desktop,
          css`
            display: none;
          `
        )}
      }
    }

    ${mediaFrom(
      SCREEN_SIZE.desktop,
      css`
        width: 648px;
        padding: 0 0 24px 24px;
      `
    )}
  }

  ${mediaFrom(
    'desktop',
    css`
      margin-bottom: 148px;
    `
  )}
`;
