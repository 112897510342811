import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { IModalCommonProps } from 'views/Account/Summary/InternalTransfer/InternalTransferPage.types';
import { SContentLayout } from './GoalsHavingIssuesModal.styles';

interface IModalProps extends IModalCommonProps {
  onConfirm: () => void;
  accountType: string;
}

export const AccountClosingIssuesModal = ({ open, onClose, onConfirm, accountType }: IModalProps) => {
  const { t } = useTranslation('accountInformation');

  return (
    <CustomModal open={open} onClose={onClose} modalName="accountClosingIssuesModal" closable topPosition="0" closeIconColor="charcoal70" className="goals-having-issues-modal">
      <SContentLayout>
        <div className="havingIssuesModal">
          <Title size="M" fontWeight="M" marginBottom={13} marginTop={10} paddingRight={10}>
            {accountType === 'Save' ? t('accountInformation.Goals.ClosingYourGoalsAccount') : t('accountInformation.Needs.ClosingYourNeedsAccount')}
          </Title>

          <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5} marginBottom={5}>
            {accountType === 'Save' ? t('accountInformation.Goals.DoFollowingCloseGoalsAccount') : t('accountInformation.Needs.DoFollowingCloseNeedsAccount')}
          </BodyText>

          <ul className="list">
            <li>
              <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5}>
                {accountType === 'Save' ? t('accountInformation.Goals.TransferAnyRemainingFunds') : t('accountInformation.Needs.TransferAnyRemainingFunds')}
              </BodyText>
            </li>

            <li>
              <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5}>
                {t('accountInformation.EnsureThatAllYourTransactions')}
              </BodyText>
            </li>

            <li>
              <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5}>
                {t('accountInformation.CancelOrMoveYourDirectDeposit')}
              </BodyText>
            </li>
          </ul>

          <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight={1.5} marginBottom={8} className="preFooter">
            {accountType === 'Save' ? t('accountInformation.Goals.IfYouAreClosingGoalsAccount') : t('accountInformation.Needs.IfYouAreClosingNeedsAccount')}
          </BodyText>

          <div className="footer">
            <Button preset="red-filled" size="xl" onClick={onConfirm}>
              {t('common.Continue')}
            </Button>
            <Button preset="red-outlined" size="xl" onClick={onClose} marginTop={20}>
              {t('accountInformation.Cancel')}
            </Button>
          </div>
        </div>
      </SContentLayout>
    </CustomModal>
  );
};
