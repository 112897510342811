import { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';

interface IScrollData {
  scrollable: boolean;
  scrollTop: number;
  isScrolledToTop: boolean;
  isScrolledToBottom: boolean;
  scrollClassNames: string;
}

export const useScrollHelper = <T extends HTMLElement>(scrollContainer: T | null) => {
  const [scrollData, setScrollData] = useState<IScrollData>({} as IScrollData);

  const checkScrollPos = useCallback(() => {
    if (!scrollContainer) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = scrollContainer;
    const scrollable = scrollHeight > clientHeight;
    const isScrolledToTop = scrollable && scrollTop === 0;
    const isScrolledToBottom = scrollable && scrollTop + (scrollContainer?.clientHeight ?? 0) === scrollHeight;
    const scrollClassNames = clsx({
      'scrolled-to-top': isScrolledToTop,
      'scrolled-to-bottom': isScrolledToBottom,
      scrollable,
    });

    setScrollData({ scrollTop, isScrolledToTop, isScrolledToBottom, scrollable, scrollClassNames });
  }, [setScrollData, scrollContainer]);

  useEffect(() => {
    scrollContainer?.addEventListener('scroll', checkScrollPos);

    return () => {
      scrollContainer?.removeEventListener('scroll', checkScrollPos);
    };
  }, [checkScrollPos]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      checkScrollPos();
    });
    if (scrollContainer) {
      observer.observe(scrollContainer);
    }
    return () => {
      observer.disconnect();
    };
  }, [checkScrollPos]);

  return { scrollData, checkScrollPos };
};
