import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Form } from 'antd';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { getEmailRules } from 'utils/helpers/validationRules';
import { Button } from 'components/theme/Button/Button';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { SModalCommonLayout } from 'views/Profile/ProfilePage/ProfileEditSheet/common/styles';

interface IFormValues {
  email: string;
}

interface IProps {
  closeSheet?: () => void;
  initVerifyCode?: (email: string) => void;
}

export const EditEmail = ({ closeSheet, initVerifyCode }: IProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const userProfileData = useSelector(selectCurrentUser);
  const { hasErrors, checkErrors } = useFormHelpers(form);

  const onFinish = (formValues: IFormValues) => {
    closeSheet?.();
    initVerifyCode?.(formValues.email);
  };

  return (
    <SModalCommonLayout className="body-edit-email">
      <Form onFinish={onFinish} onFieldsChange={checkErrors} form={form} requiredMark={false} layout="vertical" autoComplete="off" initialValues={{ email: userProfileData?.email }}>
        <CustomCard padding="0" marginTop={0}>
          <Title color="charcoal" fontWeight="SB" size="S" marginBottom={4} font="Poppins" className="title">
            {t(`profile.Email`)}
          </Title>

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" lineHeight={1.4} marginBottom={32} className="description">
            {t(`profile.Please enter your email address`)}
          </BodyText>

          <Form.Item
            label={<CustomRequiredLabel label="profile.EmailAddress" fontFamily="DM Sans" />}
            name="email"
            validateTrigger={['onBlur', 'onChange', 'onPaste']}
            rules={getEmailRules()}
            className="email"
          >
            <BaseInput placeholder={t('profile.SampleMailCom')} data-testid="email" autoCapitalize="off" inputMode="email" />
          </Form.Item>
        </CustomCard>
        <Form.Item className="form-item-button-submit">
          <Button preset="blue-filled" type="submit" size="middleStretch" disabled={hasErrors}>
            {t(`profile.Save Changes`)}
          </Button>
        </Form.Item>
      </Form>
    </SModalCommonLayout>
  );
};
