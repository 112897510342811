import { Icon } from 'components/general/Icon/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { useLazyGetNotificationsQuery, useMarkNotificationUnreadMutation } from 'store/user/users.api';
import { useNavigate } from 'react-router-dom';
import { INotificationItem } from 'store/user/notificationsCenter/notificationsCenter.types';
import { ROUTES } from 'vars/const/ROUTES';
import { SCard } from './SettingsCard.styles';

interface ISettingsCard {
  notification: INotificationItem | null;
  openDeleteModal: () => void;
  onClose: () => void;
  refresh?: () => void;
}

export const SettingsCard: React.FC<ISettingsCard> = ({ notification, openDeleteModal, onClose, refresh }) => {
  const { t } = useTranslation('notificationsCenter');
  const navigate = useNavigate();

  const [getNotifications] = useLazyGetNotificationsQuery();
  const [markNotificationUnreadAPI] = useMarkNotificationUnreadMutation();

  const handleDelete = () => {
    onClose();
    openDeleteModal();
  };

  const handleTurnOff = () => {
    onClose();
    navigate(ROUTES.notificationSettings.path);
  };

  const handleMarkUnread = () => {
    onClose();

    markNotificationUnreadAPI(notification?.alertHistoryId || '').then(() => {
      if (refresh) {
        refresh();
      } else {
        getNotifications();
      }
    });
  };

  return (
    <SCard>
      <div className="item-row" onClick={handleDelete}>
        <Icon name="trash" marginRight={14} cursorPointer color="charcoal70" />

        <div>
          <BodyText textType="bodyText" size="N" fontWeight="B" color="charcoal70">
            {t('notificationsCenter.Delete')}
          </BodyText>

          <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
            {t('notificationsCenter.DeleteNotification')}
          </BodyText>
        </div>
      </div>
      <div className="item-row" onClick={handleTurnOff}>
        <Icon name="bellSlash" marginRight={14} cursorPointer color="charcoal70" />

        <div>
          <BodyText textType="bodyText" size="N" fontWeight="B" color="charcoal70">
            {t('notificationsCenter.TurnOff')}
          </BodyText>

          <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
            {t('notificationsCenter.StopReceiving')}
          </BodyText>
        </div>
      </div>
      {notification?.status === 'Read' && (
        <div className="item-row" onClick={handleMarkUnread}>
          <Icon name="book" marginRight={14} cursorPointer color="charcoal70" />

          <div>
            <BodyText textType="bodyText" size="N" fontWeight="B" color="charcoal70">
              {t('notificationsCenter.MarkUnread')}
            </BodyText>

            <BodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70">
              {t('notificationsCenter.MarkNotificationUnread')}
            </BodyText>
          </div>
        </div>
      )}
    </SCard>
  );
};
