import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { SAccountBadge, SBodyText } from 'components/general/Accounts/AccountSelect/AccountSelect.styles';
import { SAccountWrapper, SInternalAccount } from './InternalAccount.styles';

type IInternalAccountProps = {
  account: IAccountItem;
  onSelect: (account: IAccountItem) => void;
  selected: boolean;
  isDisabled?: boolean;
  isDesktop?: boolean;
  isFrom?: boolean;
  isAmountHidden?: boolean;
  isArrowHidden?: boolean;
  isAddNeedsGoals?: boolean;
};

export const InternalAccount = ({ onSelect, account, selected, isDisabled = false, isDesktop = false, isFrom, isAmountHidden, isArrowHidden, isAddNeedsGoals = false }: IInternalAccountProps) => {
  const { t } = useTranslation('moveMoney');
  const jointName = account.type === 'Cash' && account.hasJointHolder;
  const { isDefault } = account;
  const isAmountVisible = (account.balance !== undefined && !isAmountHidden) || !isArrowHidden;

  const handleClick = () => {
    if (!isDisabled) {
      onSelect(account);
    }
  };

  const classNameStr = clsx('internal-account', {
    selected: selected && !isDisabled,
    default: isDefault,
    cash: account.type === 'Cash',
    disabled: (isDisabled && !selected) || (isDesktop && isDisabled),
    named: !!account.nickname,
  });

  const accountInfo = (
    <div className="info">
      <Icon className="type-icon" name={account.icon} color={isDisabled ? 'charcoal50' : 'orange'} marginLeft={5} marginTop={-4} />
      <div className="text">
        <BodyText textType="bodyText" color={isDisabled && !isDesktop ? 'charcoal50' : 'charcoal'} size="N" fontWeight="B" marginLeft={10}>
          {account.nickname || account.name}
        </BodyText>
        {account.nickname && (
          <BodyText textType="bodyText" color={isDisabled && !isDesktop ? 'charcoal50' : 'charcoal70'} size="N" fontWeight="R" marginLeft={10}>
            {account.name}
          </BodyText>
        )}
        {jointName && (
          <BodyText textType="bodyText" color={isDisabled && !isDesktop ? 'charcoal50' : 'charcoal70'} size="N" fontWeight="R" marginLeft={10} marginTop={15}>
            {t('account.JointAccountholder')}: {account.jointHolderName}
          </BodyText>
        )}
      </div>
    </div>
  );

  return (
    <SInternalAccount className={classNameStr} onClick={handleClick} isDefault={isDefault} isDesktop={isDesktop}>
      {isDefault && (
        <SAccountBadge isDesktop={isDesktop} className="badge-default">
          {t(`account.DefaultAccount`)}
        </SAccountBadge>
      )}

      {isDesktop && isDisabled && !isAddNeedsGoals && (
        <SBodyText textType="bodyText" color="blue" size="N" fontWeight="B" justifyContent="end" marginBottom={8} nowrap>
          {t(isFrom ? 'manageExternalAccounts.SelectedTransferTo' : 'manageExternalAccounts.SelectedTransferFrom')}
        </SBodyText>
      )}
      <SAccountWrapper>
        {accountInfo}
        {isAmountVisible && (
          <div className="flex-view">
            {account.balance !== undefined && !isAmountHidden && <CustomAmount className="sum" amount={account.balance} color={isDisabled && !isDesktop ? 'charcoal50' : 'charcoal'} size="xs" />}
            {!isArrowHidden && <Icon name="arrowRight" color="charcoal" size="smallest" className="arrow-icon" />}
          </div>
        )}
      </SAccountWrapper>
    </SInternalAccount>
  );
};
