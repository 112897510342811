import styled, { css } from 'styled-components';
import { SCREEN_SIZE, getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const STitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  ${mediaUpTo(
    'mobile',
    css`
      .custom-title-text {
        font-size: 20px;
      }
    `
  )}
`;

export const SEnrollCard = styled.div`
  background: ${getColor('white')};
  padding: 20px 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 32px;

  .image-container {
    display: flex;
    justify-content: center;

    img {
      width: 128px;
      height: 128px;
      object-fit: cover;
    }
  }

  .content-container {
    .custom-text-inner {
      font-size: 12px;
    }

    button {
      height: 36px;
      width: 100%;
      font-weight: 500;
      padding: 14px 24px;
    }
  }

  ${mediaUpTo(
    'mobile',
    css`
      padding: 32px 20px;

      button {
        font-size: 16px;
        height: 44px;
      }
    `
  )}

  ${mediaFrom(
    'mobile',
    css`
      padding: 56px 24px;

      .content-container {
        .custom-title-text {
          font-size: 28px;
        }
      }
    `
  )}
  
${mediaFrom(
    SCREEN_SIZE.tablet,
    css`
      padding: 56px 40px;
      flex-direction: row;

      .image-container {
        margin-right: 40px;

        img {
          width: 161px;
          height: 161px;
        }
      }

      .content-container {
        .custom-title-text {
          font-size: 32px;
        }
        .custom-text-inner {
          font-size: 14px;
        }
        button {
          height: 52px;
          padding: 14px 32px;
          font-size: 18px;
          max-width: 250px;
        }
      }
    `
  )}
`;
