import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { SLayout } from './Tooltip.styles';

type TTooltipProps = {
  children?: string | React.ReactNode;
  open: boolean;
  onClose?: () => void;
  layoutExtraStyles?: CSSProperties;
  isEnableForModal?: boolean;
  isCloseTooltipByClick?: boolean;
};

export const Tooltip: React.FC<TTooltipProps> = ({ children = '', open, onClose, layoutExtraStyles, isEnableForModal = false, isCloseTooltipByClick = true }) => {
  const layoutRef = React.useRef(null);

  const handleClose = () => {
    onClose?.();
  };

  useClickOutside(layoutRef, handleClose, isEnableForModal, isCloseTooltipByClick);

  return (
    <SLayout className={clsx('tooltip', { show: open })} $extraStyles={layoutExtraStyles} ref={layoutRef}>
      {children}
    </SLayout>
  );
};
