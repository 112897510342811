import isBefore from 'date-fns/isBefore';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { usePayments } from 'utils/hooks/usePayments';
import { BodyText } from 'components/general/Typography';

export const AvailableTodayTooltip = () => {
  const { t } = useTranslation('percapitaPay');
  const { paymentsInfo } = usePayments();

  const DAILY_PAY_LIMIT = 250;

  const submitByDate = new Date(paymentsInfo.submitByDate ?? '');
  const endDate = new Date(paymentsInfo.earnCicleEndDate ?? '');
  const hasAvailableTransfers = paymentsInfo.transfersAvailable ? paymentsInfo.transfersAvailable > 0 : false;
  const isPeriodClosed = isBefore(submitByDate, new Date()) && isBefore(submitByDate, endDate);
  return (
    <>
      <BodyText font="Poppins" color="charcoal" marginBottom={16} fontWeight="B" size="T">
        {t('percapitaPayEarnedModal.Title')}
      </BodyText>

      <BodyText lineHeight={1.4} textType="bodyText" size="T" fontWeight="R" color="charcoal70" marginBottom={16}>
        <Trans
          i18nKey="percapitaPayEarnedModal.EarnedTotal"
          ns="percapitaPay"
          values={{
            earnedThisCycle: paymentsInfo?.earnedThisCycle,
          }}
          components={{ strong: <strong style={{ color: '#353131' }} /> }}
        />

        {hasAvailableTransfers && (
          <Trans
            i18nKey="percapitaPayEarnedModal.Available"
            ns="percapitaPay"
            values={{
              availableNow: paymentsInfo?.availableNow,
            }}
            components={{ strong: <strong style={{ color: '#353131' }} /> }}
          />
        )}
        {!hasAvailableTransfers && isPeriodClosed && t('percapitaPayHome.Of that, a total of $0... because pay period is already closed.')}
        {!hasAvailableTransfers && !isPeriodClosed && t('percapitaPayHome.Of that, a total of $0... because you have already maxed out...')}
      </BodyText>

      <BodyText lineHeight={1.4} textType="bodyText" size="T" fontWeight="R" color="charcoal70" marginBottom={16}>
        <Trans
          i18nKey="percapitaPayEarnedModal.YouAreLimited"
          ns="percapitaPay"
          values={{
            limit: DAILY_PAY_LIMIT,
          }}
          components={{ strong: <strong style={{ color: '#353131' }} /> }}
        />
      </BodyText>

      <BodyText lineHeight={1.4} textType="bodyText" size="T" fontWeight="R" color="charcoal70">
        {t('percapitaPayEarnedModal.IfYouDontMakeRequest')}
      </BodyText>
    </>
  );
};
