import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { SEmptyWarning } from './AccountSelectDropdown.styles';

export type TNotAvailableSelectionStatus = 'NO_EXT_ACCOUNTS' | 'NO_INT_ACCOUNTS' | 'COMPANION_TO_EXT' | 'EXT_TO_EXT';

interface INoSelectableAccWarningData {
  title: string;
  text?: string;
}

export const WARNING_CONFIG: Record<TNotAvailableSelectionStatus, INoSelectableAccWarningData> = {
  NO_INT_ACCOUNTS: {
    title: 'noSelectableAccountWarning.NoPercapitaAccounts',
    text: 'noSelectableAccountWarning.YouCanOpenAccountInDoMore',
  },
  NO_EXT_ACCOUNTS: {
    title: 'noSelectableAccountWarning.ThereIsNoSelectableAccount',
    text: 'noSelectableAccountWarning.MustAddExternalAccount',
  },
  COMPANION_TO_EXT: {
    title: 'noSelectableAccountWarning.NoTransferToExtFromCompanion',
  },
  EXT_TO_EXT: {
    title: 'noSelectableAccountWarning.NoTransferBetweenExternal',
  },
};

export const NotAvailableSelectionWarning = ({ warningType }: { warningType: TNotAvailableSelectionStatus | null }) => {
  const { t } = useTranslation('moveMoney');
  const text = warningType && WARNING_CONFIG?.[warningType].text;

  return warningType ? (
    <SEmptyWarning className="empty-warning">
      <Icon name="empty" color="charcoal70" />
      <BodyText size="M" fontWeight="B" textAlign="center" marginBottom={5}>
        {t(WARNING_CONFIG[warningType].title)}
      </BodyText>
      {text && (
        <BodyText size="N" textAlign="center" color="charcoal70">
          <Trans ns="moveMoney" i18nKey={text} />
        </BodyText>
      )}
    </SEmptyWarning>
  ) : null;
};
