import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import styled, { css } from 'styled-components';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SSheet = styled(CustomSheet)`
  button:is(.default, .primary) {
    width: 120px;
    padding: 10px 24px;
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
  }

  .currency,
  .literal,
  .integer,
  .decimal,
  .fraction {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  ${mediaUpTo(
    320,
    css`
      .agreement {
        .custom-text-inner {
          font-size: 11px !important;
        }
      }

      .disclaimer {
        .custom-text-inner {
          font-size: 11px !important;
        }
      }

      .custom-title-text {
        font-size: 20px !important;
      }

      .transfer-details {
        font-size: 14px !important;
        font-weight: 700;
      }

      .after-transfer {
        font-size: 14px !important;
        font-weight: 700;
      }

      .data-label {
        font-size: 12px !important;
      }

      .currency,
      .literal,
      .integer,
      .decimal,
      .fraction {
        font-size: 12px !important;
        font-weight: 700 !important;
      }
    `
  )}

  button {
    height: 36px;
    width: 96px !important;
    font-size: 14px !important;
    font-weight: 500;

    ${mediaFrom(
      321,
      css`
        font-size: 16px !important;
        height: 44px;
        width: 106px !important;
      `
    )}
  }
`;
