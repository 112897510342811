import React from 'react';
import { SCardHubModal } from './CardHubModal.styles';
import { CardHubFrame } from './CardHubFrame';

interface ICardHubModalProps {
  open: boolean;
  onClose: () => void;
}

export const CardHubModal: React.FC<ICardHubModalProps> = ({ open, onClose }) => (
  <SCardHubModal open={open} width={920} topPosition="0" footer={null} onCancel={onClose} className="cardHub-iframe-modal" modalName="cardHub">
    <CardHubFrame />
  </SCardHubModal>
);
