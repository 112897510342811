import React from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { SPercapitaLayoutHeader } from './LogoHeader.styles';

export const LogoHeader = () => {
  return (
    <SPercapitaLayoutHeader className="logo-header">
      <h1>
        <a href={ROUTES.home.path}>
          <span className="visually-hidden">Percapita</span>
        </a>
      </h1>
    </SPercapitaLayoutHeader>
  );
};
