import styled, { css } from 'styled-components';
import { mediaUpTo, getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-form {
    width: 100%;
  }

  .inline-field .base-input-container,
  .inline-field .masked-input-input,
  .inline-field .ant-input,
  .inline-field .ant-input-group-addon {
    border: 0 !important;
    background-color: transparent;
    padding: 0;
  }

  .inline-field .base-input-container,
  .inline-field .masked-input-input {
    border-radius: 0;
    border-bottom: 1px solid #3e4fe5 !important;
    height: auto;
  }

  .inline-field .ant-input:focus {
    box-shadow: unset;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    .profile-data-card,
    .delete-button-card {
      width: 100%;
    }

    .profile-data-card {
      display: flex;
      flex-direction: column;

      .data-item {
        &:last-child {
          margin-bottom: 0;
        }

        .icon-edit {
          height: 14px;
          width: 14px;
          margin-right: 4px;
        }

        .button-add {
          .icon-circlePlus {
            height: 22px;
            width: 22px;
          }
        }
      }
    }

    .delete-button-card {
      display: flex;
      justify-self: flex-end;
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      padding-bottom: 20px;

      .page-title {
        margin-bottom: 12px;

        .custom-title-text {
          font-size: 18px;
        }
      }

      .cards-container {
        justify-content: flex-start;

        .profile-data-card {
          padding: 16px 20px 16px 16px;
          border-radius: 16px;

          .data-item {
            margin-bottom: 14px;

            .data-item-header {
              .custom-text-inner {
                font-size: 12px;
              }
            }

            .data-item-value {
              .custom-text-inner {
                font-size: 12px;
              }
            }
          }
        }

        .delete-button-card {
          margin: 12px 0 0;

          .svg-icon {
            height: 20px;
            width: 20px;
          }

          .custom-text-inner {
            font-size: 14px;
          }
        }
      }
    `
  )};

  ${mediaUpTo(
    320,
    css`
      .delete-button-card .svg-icon {
        width: 20px !important;
        height: 20px !important;
      }

      .delete-button-card .custom-text-inner {
        font-size: 14px !important;
      }
    `
  )}

  // > 320px
  ${mediaFrom(
    'mobile',
    css`
      padding: 16px 4px 68px 4px;

      .page-title {
        margin-bottom: 32px;

        .custom-title-text {
          font-size: 28px;
        }
      }

      .cards-container {
        .profile-data-card {
          border-radius: 20px;
          padding: 24px;

          .data-item {
            margin-bottom: 16px;

            .data-item-header .custom-text-inner,
            .data-item-value .custom-text-inner {
              font-size: 16px;
            }

            .icon-edit {
              height: 16px;
              width: 16px;
            }
          }
        }

        .delete-button-card {
          border-radius: 20px;
          margin: 20px 0 0;

          .svg-icon {
            height: 24px;
            width: 24px;
          }

          .custom-text-inner {
            font-size: 16px;
          }
        }
      }
    `
  )};

  // 576px
  ${mediaFrom(
    'tablet',
    css`
      padding: 30px 0 80px;

      .page-title {
        border-bottom: 1px solid ${getColor('creamS5')};
        padding: 0 24px 46px;
        margin-bottom: 48px;

        .custom-title-text {
          font-size: 24px;
        }
      }

      .cards-container {
        padding: 0 24px;

        .profile-data-card,
        .delete-button-card {
          max-width: 619px;
        }

        .profile-data-card {
          padding: 32px;

          .data-item {
            margin-bottom: 24px;

            .data-item-header {
              .custom-text-inner {
                font-size: 14px;
              }
            }

            .data-item-value {
              .custom-text-inner {
                font-weight: 700;
              }
            }

            .button-add {
              .button-title {
                font-family: Poppins, sans-serif;
                font-weight: 600;
                padding-top: 2px;
              }
            }
          }
        }

        .delete-button-card {
          border-radius: 24px;
          margin: 48px 0 0;

          .icon-sign {
            margin-right: 24px;
          }

          .custom-text-inner {
            font-family: DM Sans, sans-serif;
            font-weight: 700;
          }
        }
      }
    `
  )};

  // 1200px
  ${mediaFrom(
    'desktop',
    css`
      .page-title {
        padding: 0 48px 58px;
      }

      .cards-container {
        padding: 0 48px;

        .profile-data-card,
        .delete-button-card {
          max-width: 619px;
      }
    `
  )};

  // 1440px
  ${mediaFrom(
    'desktopLarge',
    css`
      padding-bottom: 106px;

      .cards-container {
        .profile-data-card,
        .delete-button-card {
          max-width: 578px;
        }
      }
    `
  )};

  // 1900px
  ${mediaFrom(
    'desktopLargest',
    css`
      padding-bottom: 80px;

      .page-title {
        padding: 0 128px 58px;
      }

      .cards-container {
        padding: 0 128px;

        .profile-data-card,
        .delete-button-card {
          max-width: 738px;
        }
      }
    `
  )};
`;
