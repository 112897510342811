import React from 'react';
import { useTranslation } from 'react-i18next';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { BodyText, Title } from 'components/general/Typography';
import { SCustomCard, SCustomModal } from './WhenAvailableModal.styles';

interface IModalProps {
  isModalVisible?: boolean;
  handleOnCancel?: () => void;
}

export const WhenAvailableModal = ({ isModalVisible = false, handleOnCancel }: IModalProps) => {
  const { t } = useTranslation('moveMoney');
  return (
    <SCustomModal open={isModalVisible} onCancel={handleOnCancel} modalName="transfer_whenAvailableModal" topPosition={0} padding="0">
      <div className="modal-header">
        <Title color="charcoal" size="sL" fontWeight="M" lineHeight={1.5}>
          {t('transferPage.WhenAvailable')}
        </Title>
      </div>
      <div className="modal-content">
        <div className="container table-header">
          <div className="item">{t('transferPage.Transaction')}</div>
          <div className="item">{t('transferPage.Availability')}</div>
        </div>
        <div className="container">
          <div className="item">{t('transferPage.BetweenPercapitaAccounts')}</div>
          <div className="item right-column-item">{t('transferPage.Immediately')}</div>
        </div>
        <div className="divider" />

        <div className="container">
          <div className="item">{t('transferPage.FromCashToExternalDebit')}</div>
          <div className="item right-column-item">{t('transferPage.Within35minutes')}</div>
        </div>
        <SCustomCard>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5} marginBottom={24}>
            {t('transferPage.YourCashAccountWillBeDebited')}
          </BodyText>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5}>
            {t('transferPage.TimingAndAvailabilityMayDepend')}
          </BodyText>
        </SCustomCard>
        <div className="divider" />

        <div className="container">
          <div className="item">{t('transferPage.FromCashToExternalViaACH')}</div>
          <div className="item right-column-item">{t('transferPage.WithinBusinessDays')}</div>
        </div>
        <SCustomCard>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5} marginBottom={24}>
            {t('transferPage.YourCashAccountWillBeDebited')}
          </BodyText>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5}>
            {t('transferPage.TimingAndAvailabilityMayDepend')}
          </BodyText>
        </SCustomCard>
        <div className="divider" />

        <div className="container">
          <div className="item">{t('transferPage.FromExternalToCash')}</div>
          <div className="item right-column-item">{t('transferPage.In7BusinessDays')}</div>
        </div>
        <SCustomCard>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5} marginBottom={24}>
            {t('transferPage.FromTransfersText2')}
          </BodyText>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5}>
            {t('transferPage.ForFasterAvailability')}
          </BodyText>
        </SCustomCard>
        <div className="divider" />

        <div className="container">
          <div className="item">
            <SuttonDisclaimerNote footerTextType="Sutton" />
          </div>
        </div>
      </div>
    </SCustomModal>
  );
};
