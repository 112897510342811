import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SIconClose, SMaskStyle } from 'components/theme/CustomModal/CustomModal.styles';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';

interface IVerificationExpiredModal {
  isOpen: boolean;
  onCancel: () => void;
  handleOnResendEmail: () => void;
}

export const VerificationExpiredModal: React.FC<IVerificationExpiredModal> = ({ isOpen, onCancel, handleOnResendEmail }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnCheckMyEmail = () => {
    navigate(ROUTES.myInfoEmailUsername.path);
  };

  return (
    <CustomModal
      open={isOpen}
      modalName="checkEmail_emailVirificationExpiredModal"
      centered
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      maskStyle={SMaskStyle}
      closeIcon={<SIconClose name="closeCircle" cursorPointer />}
      topPosition="0"
    >
      <Title textAlign="start" justifyContent="start" fontWeight="M" size="M">
        {t('myInfo.verificationExpiredModal.title')}
      </Title>

      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" marginTop={16} marginBottom={32} textAlign="start" justifyContent="start">
        {t('myInfo.verificationExpiredModal.description')}
      </BodyText>

      <CustomRow flexDirection="column" justifyContent="center" gap={16} alignItems="center">
        <Button preset="blue-filled" size="large" onClick={handleOnResendEmail}>
          {t('myInfo.verificationExpiredModal.resendEmail')}
        </Button>

        <Button size="large" onClick={handleOnCheckMyEmail}>
          {t('myInfo.verificationExpiredModal.checkEmail')}
        </Button>
      </CustomRow>
    </CustomModal>
  );
};
