import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SAuthLayoutFooter = styled.div`
  flex: 0 0 auto;
  font-size: 12px;
  text-align: center;
  padding: 35px 0 45px;
  font-family: 'DM Sans', sans-serif;
  color: ${getColor('charcoal70')};

  p {
    margin: 0;
  }

  ${mediaFrom(
    'tablet',
    css`
      text-align: left;
      padding: 35px max(80px, 10%) 85px max(80px, 10%);
    `
  )}
  ${mediaFrom(
    'desktop',
    css`
      padding-top: 70px;
    `
  )}

  ${mediaUpTo(
    'desktop',
    css`
      padding-inline: 10px;
      padding-bottom: 50px;
    `
  )}
`;
