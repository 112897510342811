import styled, { css } from 'styled-components';
import { MEDIA_SIZE, flex, getColor, ifProp } from 'utils/helpers/styleHelpers';

interface ISExternalAccount {
  isDesktop?: boolean;
}

export const SExternalAccount = styled.div<ISExternalAccount>`
  ${flex('column', 'flex-start', 'flex-start')};
  background: ${getColor('white')};
  position: relative;
  padding: 24px;
  border: 2px solid ${getColor('charcoal10')};
  border-radius: 16px;
  cursor: pointer;

  &.disabled {
    background: ${getColor('charcoal5')};
    border-color: ${getColor('charcoal10')};
    cursor: not-allowed;
  }

  &.selected {
    ${ifProp(
      'isDesktop',
      css`
        background: transparent;
        border: 2px solid ${getColor('blue')};
      `,
      css`
        background: ${getColor('blue20')};
      `
    )}
  }

  &.selected.disabled {
    border: 2px solid ${getColor('charcoal10')};
    background: ${getColor('blue5')};
  }

  .external-account__content {
    ${ifProp(
      'isDesktop',
      css`
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 0px;
        align-items: center;
        .info {
          width: 100%;
        }
      `,
      css`
        ${flex('row', 'space-between')};
        flex: 0 0 auto;
        margin: 0;
        width: 100%;

        .icon {
          flex: 0 0 auto;
        }
        .info {
          flex: 1 1 auto;
        }
      `
    )}
  }

  .external-account__buttons {
    ${flex('row', 'flex-end', 'center')};
    gap: 12px;
    align-self: flex-end;
    margin-top: 22px;

    .custom-text-inner {
      font-size: 12px;
      font-weight: 500;

      @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
`;
