import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'utils/hooks/store';
import { useSelector } from 'react-redux';
import { lsGetItem } from 'utils/helpers/storage';
import { mobileApiCall } from 'services/mobileService';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'utils/hooks/useToggle';
import { useSetRightTimeNotificationsAcitivityMutation } from 'store/user/users.api';
import { setNotificationCapabilityModal, selectDisplayNotificationHtml } from 'store/ui.slice';
import { NotInterestedModal } from './NotInterestedModal/NotInterestedModal';
import { SLayout, SCloseIcon } from './NotificationCapabilityModal.styles';

interface INotificationCapabilityModalProps {
  isNavVisible?: boolean;
}

interface IPostMessageProps {
  url?: string;
  historyId?: string;
  showFullBrowser?: string;
  showFullBrowserInApp?: string;
}

export const NotificationCapabilityModal: React.FC<INotificationCapabilityModalProps> = ({ isNavVisible = true }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobileApp = lsGetItem('isMobileApp');
  const displayNotificationHtml = useSelector(selectDisplayNotificationHtml);
  const [postMessageObj, setPostMessageObj] = useState<IPostMessageProps>();
  const [historyId, setHistoryId] = useState<string>();
  const [clickValue, setClickValue] = useState('null');
  const notInterestedSheet = useToggle();
  const [setRightTimeNotificationsAcitivityAPI] = useSetRightTimeNotificationsAcitivityMutation();

  const setAction = (userAction: string) => {
    if (historyId) {
      setRightTimeNotificationsAcitivityAPI({ rightTimeNotificationHistoryId: historyId, data: { userAction, value: clickValue } });
    }
  };

  const onClose = () => notInterestedSheet.show();

  const extractPostMessageObject = (htmlString: string) => {
    const regex = /postMessage\((.*?)\)/;
    const match = htmlString.match(regex);

    if (match && match.length >= 2) {
      const jsonString = match[1];
      try {
        const postMessageObject = JSON.parse(jsonString);
        return postMessageObject;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  };

  const addSlashIfNeeded = (url: string) => (!url.length || url[0] !== '/' ? `/${url}` : url);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;

      if (target.id !== 'clickable' && !target.closest('#clickable')) return;

      setAction('clicked');

      if (isMobileApp && postMessageObj && postMessageObj.showFullBrowser) {
        e.preventDefault();
        mobileApiCall('showFullBrowser', JSON.stringify({ inApp: postMessageObj?.showFullBrowserInApp, ...postMessageObj }));
        dispatch(setNotificationCapabilityModal(false));
      }

      if ((!isMobileApp || !postMessageObj?.showFullBrowser) && postMessageObj?.url) {
        navigate(addSlashIfNeeded(postMessageObj.url));
      }
    },
    [displayNotificationHtml, historyId]
  );

  const hideModal = (action: string) => {
    setAction(action);
    dispatch(setNotificationCapabilityModal(false));
    notInterestedSheet.hide();
  };

  const handleHide = () => hideModal('Hidden');
  const handleRemindLater = () => hideModal('Suspended');

  useEffect(() => {
    const messageObj = extractPostMessageObject(displayNotificationHtml);
    if (messageObj) {
      setPostMessageObj(messageObj);
      setHistoryId(messageObj?.historyId);
      setClickValue(messageObj?.clickValue);
    }
  }, [displayNotificationHtml]);

  return (
    <>
      <SLayout isNavVisible={isNavVisible} onClick={handleClick}>
        <SCloseIcon name="closeCircle" color="charcoal" size="small" onClick={onClose} cursorPointer />
        <div dangerouslySetInnerHTML={{ __html: displayNotificationHtml }} />
      </SLayout>
      <NotInterestedModal open={notInterestedSheet.isActive} onRemindLater={handleRemindLater} onHide={handleHide} />
    </>
  );
};
