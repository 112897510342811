import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { ROUTES } from 'vars/const/ROUTES';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { handleError } from 'utils/helpers/errorHelper';
import { getEmailRules } from 'utils/helpers/validationRules';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGenerateCodeEmailOnlyMutation } from 'store/user/authentication.api';
import { useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { selectCurrentUser, selectIsEmailVerifiedOrAbsent } from 'store/user/authentication.slice';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/general/Loader/Loader';
import { WebHeader } from 'views/PercapitaPay/WebHeader/WebHeader';
import { SubmitButtom } from './SubmitButtom/SubmitButton';
import { SLayout } from './EmailVerificationPage.styles';

interface IEmailVerification {
  state?: {
    email?: string;
  };
}

export const EmailVerificationPage = () => {
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const currentUser = useSelector(selectCurrentUser);
  const { t } = useTranslation(['percapitaPay', 'header']);
  const [form] = Form.useForm();
  const [generateCodeEmailAPI, generateCodeEmailAPIResult] = useGenerateCodeEmailOnlyMutation();
  const [getCurrentUser, getCurrentUserResult] = useLazyGetCurrentUserQuery();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const location = useLocation() as IEmailVerification;
  const triedEmail = location?.state?.email;
  const isUserEmailVerified = useSelector(selectIsEmailVerifiedOrAbsent);

  const handleOnFinish = async () => {
    await generateCodeEmailAPI({ email: form.getFieldValue('email') });
  };

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isEmailEmpty = form.getFieldValue('email') === '';
    if (!isEmailEmpty && !hasErrors) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (generateCodeEmailAPIResult?.isSuccess) {
      navigate(ROUTES.verificationCode.path, { state: { email: form.getFieldValue('email') } });
    }

    if (generateCodeEmailAPIResult?.isError) {
      handleError(generateCodeEmailAPIResult.error);
    }
  }, [generateCodeEmailAPIResult?.isSuccess, generateCodeEmailAPIResult?.isError]);

  useEffect(() => {
    if (getCurrentUserResult?.isSuccess) {
      form.setFieldValue('email', triedEmail || currentUser?.email);
      form.validateFields(['email']);
    }
  }, [getCurrentUserResult?.isSuccess]);

  return (
    <>
      <WebHeader />
      <SLayout isDesktopSize={isDesktopSize}>
        <div className="email-verification-layout">
          {(getCurrentUserResult.isLoading || generateCodeEmailAPIResult.isLoading) && <Loader />}
          {!isDesktopSize && (
            <CustomRow justifyContent="flex-start" marginBottom={32}>
              <Title size="S" fontWeight="SB" color="charcoal" font="Poppins" marginLeft={5}>
                {t('percapitaPayEmailVerification.Percapita Pay')}
              </Title>
            </CustomRow>
          )}

          <Title
            size="S"
            fontWeight={isDesktopSize ? 'SB' : 'M'}
            paddingRight={isDesktopSize ? 50 : 0}
            color="charcoal"
            marginBottom={isDesktopSize ? 18 : 4}
            font="Poppins"
            marginLeft={5}
            lineHeight={1.4}
          >
            {t('percapitaPayEmailVerification.ToEnrollPleaseEnterEmail')}
          </Title>

          {isUserEmailVerified && (
            <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" marginTop={5} marginBottom={17} marginLeft={5} lineHeight={1.3}>
              {t('percapitaPayEmailVerification.For your security, we will send a verification code to the email address you provide.')}
            </BodyText>
          )}

          <Form onFinish={handleOnFinish} onFieldsChange={onFieldsChange} onValuesChange={onFieldsChange} form={form} autoComplete="off" layout="vertical" requiredMark={false}>
            <CustomCard>
              <Form.Item
                label={<CustomRequiredLabel label="percapitaPayEmailVerification.Email" fontFamily="DM Sans" />}
                name="email"
                validateTrigger={['onBlur', 'onChange']}
                validateFirst
                rules={getEmailRules()}
              >
                <BaseInput
                  placeholder={t('percapitaPayEmailVerification.Sample')}
                  suffix={isButtonDisabled ? 'validationError' : 'tickInCircle'}
                  suffixSize="big"
                  suffixColor={isButtonDisabled ? 'red' : 'green'}
                  inputMode="email"
                  autoCapitalize="off"
                />
              </Form.Item>
              <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal" lineHeight={1.3}>
                {t('percapitaPayEmailVerification.ThisEmailMustBeTheSame')}
              </BodyText>
              {isDesktopSize && <SubmitButtom handleOnFinish={handleOnFinish} isButtonDisabled={isButtonDisabled} />}
            </CustomCard>
          </Form>
        </div>
        {!isDesktopSize && <SubmitButtom handleOnFinish={handleOnFinish} isButtonDisabled={isButtonDisabled} />}
      </SLayout>
    </>
  );
};
