import React, { useEffect, useState } from 'react';
import { useLanguage } from 'utils/hooks/useLanguage';
import { SChip, SLayout } from './ChipLanguageSwitcher.styles';
import { TChipLanguageProps } from './ChipLanguageSwitcher.types';

export const ChipLanguageSwitcher = ({ defaultValue, isDisabled }: TChipLanguageProps) => {
  const { language, changeLanguage } = useLanguage();
  const [lang, setLang] = useState(language.split('-')[0] || defaultValue || '');

  const chipHandler = (value: string) => {
    changeLanguage(value)
      .then(() => setLang(value))
      .catch(() => setLang(''));
  };

  useEffect(() => {
    const getUserLang = language.split('-')[0];
    setLang(getUserLang === 'es' ? getUserLang : 'en');
  }, [language]);

  return (
    <SLayout>
      <SChip type="blue" value="en" onClick={chipHandler} marginRight={13} isActive={lang === 'en'}>
        English
      </SChip>
      <SChip type="blue" value="es" onClick={chipHandler} isActive={lang === 'es'} isDisabled={isDisabled}>
        Español
      </SChip>
    </SLayout>
  );
};
