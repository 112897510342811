export const getGroupsPaths = (navigationItem: any) => {
  const result: any = [];

  const iterator = (item: any) => {
    if (typeof item === 'object') {
      if (item?.length) {
        item?.forEach((child: any) => iterator(child));
      } else {
        result.push(item?.path);
        if (item?.children?.length) iterator(item.children);
      }
    }
  };

  iterator(navigationItem);
  return result;
};
