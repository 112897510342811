import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserNotifications, setNotificationSearch } from 'store/user/notificationsCenter/notificationsCenter.slice';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Form } from 'antd';
import { format } from 'date-fns';
import { useToggle } from 'utils/hooks/useToggle';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { TDates } from 'components/general/BottomDrawers/FilterDrawer/FilterDrawer.types';
import { handleFilterByValue, handleOrderByValue } from 'views/NotificationsCenter/FilterSheet/FilterSheet';
import { TFiltersParams } from 'views/NotificationsCenter/NotificationsCenterPage.types';
import { TextButton } from 'components/general/TextButton/TextButton';
import { Button } from 'components/theme/Button/Button';
import { SORT_VARIABLES } from 'views/NotificationsCenter/constants';
import { RadioButton } from 'components/general/RadioButton/RadioButton';
import { DayPickerComponent } from 'components/general/DayPicker/DayPickerComponent';
import { useLanguage } from 'utils/hooks/useLanguage';
import { searchValidator } from 'utils/helpers/validationRules';
import { SSidebar } from './Sidebar.styles';

interface ISidebarProps {
  handleSearchInputChange: (value: string) => void;
  filterParamsInit: TFiltersParams;
  onFilter: (values: TFiltersParams, showDeleted: boolean, orderString: string, filterString?: string) => void;
}

export const Sidebar: React.FC<ISidebarProps> = ({ handleSearchInputChange, filterParamsInit, onFilter }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('notificationsCenter');
  const [form] = Form.useForm();
  const intervalSheet = useToggle();
  const [selectedDates, setSelectedDates] = useState<TDates>([null, null]);
  const { language } = useLanguage();
  const { notificationSearch: searchString } = useSelector(selectUserNotifications);

  const handleSortChanged = (id: string, title: string) => {
    form.setFieldValue('sortBy', { id, title });
  };

  const onFinish = (values: TFiltersParams & { search: string }) => {
    const { filterBy, sortBy, search } = values;
    const filterString = handleFilterByValue(filterBy, !!search?.length, search);
    const orderString = handleOrderByValue(sortBy);
    const filter = filterString || undefined;

    onFilter(values, filterBy.deleted, orderString, filter);
    intervalSheet.hide();
    setSelectedDates([null, null]);
  };

  const handleIntervalSelect = (dates: TDates) =>
    form.setFieldsValue({
      filterBy: {
        fromDate: dates[0],
        toDate: dates[1],
      },
    });

  const onDateRangeChange = (dates: TDates) => {
    intervalSheet.show();
    setSelectedDates(dates);
    handleIntervalSelect(dates);
  };

  const resetFields = () => {
    dispatch(setNotificationSearch(''));
    form.resetFields();
    onFinish({ ...filterParamsInit, search: '' });
  };

  return (
    <SSidebar className="sidebar">
      <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical" initialValues={filterParamsInit}>
        <Title size="sS" marginBottom={14} fontWeight="B">
          {t('notificationsCenter.Search')}
        </Title>
        <Form.Item name="search" validateTrigger={['onBlur', 'onChange']} rules={[searchValidator(handleSearchInputChange)]} style={{ marginBottom: 15 }}>
          <BaseInput placeholder={t(`notificationsCenter.TypeIn`)} suffix="search" value={searchString} onBeige />
        </Form.Item>
        <Title size="sS" marginBottom={14} fontWeight="B">
          {t(`notificationsCenter.Notification`)}
        </Title>
        <CustomRow justifyContent="flex-start" paddingRight={16}>
          <Form.Item name={['filterBy', 'read']} valuePropName="checked">
            <Checkbox>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                {t('notificationsCenter.Read')}
              </BodyText>
            </Checkbox>
          </Form.Item>
        </CustomRow>

        <CustomRow justifyContent="flex-start" paddingRight={16}>
          <Form.Item name={['filterBy', 'unread']} valuePropName="checked">
            <Checkbox>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                {t('notificationsCenter.Unread')}
              </BodyText>
            </Checkbox>
          </Form.Item>
        </CustomRow>

        <CustomRow justifyContent="flex-start" paddingRight={16}>
          <Form.Item name={['filterBy', 'deleted']} valuePropName="checked">
            <Checkbox>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                {t('notificationsCenter.Deleted')}
              </BodyText>
            </Checkbox>
          </Form.Item>
        </CustomRow>

        <Title size="sS" marginBottom={14}>
          {t('notificationsCenter.DateRange')}
        </Title>

        <Form.Item name={['filterBy', 'fromDate']} hidden />
        <Form.Item name={['filterBy', 'toDate']} hidden />

        <Form.Item shouldUpdate style={{ margin: '0 0 32px' }}>
          {({ getFieldValue }) => {
            const fromDateTimestamp = getFieldValue(['filterBy', 'fromDate'])?.valueOf() ?? '';
            const toDateTimestamp = getFieldValue(['filterBy', 'toDate'])?.valueOf() ?? '';
            const fromDateString = fromDateTimestamp ? format(fromDateTimestamp, 'PP') : '';
            const toDateString = toDateTimestamp ? format(toDateTimestamp, 'PP') : '';
            const value = fromDateTimestamp && toDateTimestamp ? `${fromDateString} - ${toDateString}` : '';

            return (
              <BaseInput
                onClick={intervalSheet.show}
                placeholder={`${t('notificationsCenter.From')} - ${t('notificationsCenter.To')}`}
                value={value}
                readOnly
                suffix="calendar"
                suffixColor="charcoal"
                suffixClick={intervalSheet.show}
                marginBottom={0}
              />
            );
          }}
        </Form.Item>
        <div className={`calendar ${intervalSheet.isActive ? 'open' : 'hide'}`}>
          <DayPickerComponent locale={language} value={selectedDates} range open onChange={onDateRangeChange} style={{ marginBottom: 32 }} />
        </div>

        <Title size="sS" marginBottom={14}>
          {t('notificationsCenter.SortBy')}
        </Title>
        <Form.Item name="sortBy" hidden />
        <Form.Item shouldUpdate style={{ marginBottom: 20 }}>
          {({ getFieldValue }) => {
            const sortValue = getFieldValue('sortBy');
            return SORT_VARIABLES.map((item) => {
              const selected = item.id === sortValue.id;
              return (
                <CustomRow marginBottom={16}>
                  <RadioButton onChange={() => handleSortChanged(item.id, item.title)} checked={selected} key={item.id}>
                    <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight={selected ? 'B' : 'R'} cursorPointer>
                      {t(item.title)}
                    </BodyText>
                  </RadioButton>
                </CustomRow>
              );
            });
          }}
        </Form.Item>

        <CustomRow justifyContent="flex-end" flexDirection="column" marginTop={32}>
          <Button preset="blue-outlined" size="middleStretch" type="submit" marginBottom={25}>
            {t('notificationsCenter.Apply')}
          </Button>
          <TextButton size="N" onClick={resetFields} color="charcoal">
            {t('notificationsCenter.Reset')}
          </TextButton>
        </CustomRow>
      </Form>
    </SSidebar>
  );
};
