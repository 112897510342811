import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import styled, { css } from 'styled-components';

export const SCustomModal = styled(CustomModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image {
      margin-bottom: 32px;
    }

    .title {
      margin-bottom: 32px;
    }

    .button-panel {
      display: flex;

      button {
        height: 52px;
        padding: 8px 24px;
      }
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      top: 11% !important;

      .ant-modal-content {
        padding: 18px 24px 24px;

        .ant-modal-body {
          .main-image {
            height: 170px;
            width: 160px;
          }

          .title {
            .custom-title-text {
              font-size: 28px;
            }
          }

          .button-panel {
            flex-direction: column;
            align-self: stretch;

            button {
              width: 100%;
              font-size: 16px;
            }

            button.preset-outline {
              order: 1;
            }

            button.preset-primary {
              margin-bottom: 16px;
              order: 0;
            }
          }
        }
      }
    `
  )};

  ${mediaFrom(
    'tablet',
    css`
      top: 0 !important;
      width: 50% !important;

      .ant-modal-content {
        padding: 36px 24px 14px !important;

        .ant-modal-body {
          .main-image {
            height: 216px;
            width: 200px;
          }

          .title {
            .custom-title-text {
              font-size: 32px;
              text-align: center;
            }
          }

          .button-panel {
            display: flex;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

            button {
              max-width: 252px;
              font-weight: 600;
            }

            button:first-child {
              margin-right: 8px;
              margin-bottom: 10px;
            }
          }
        }
      }
    `
  )};
`;
