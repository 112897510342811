import React from 'react';
import { Card } from 'views/MoveMoney/InternationalTransferPage/HowDoesItWorkSection/Card/Card';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { IconSign } from 'components/general/Icon/IconSign';
import { Icon } from 'components/general/Icon/Icon';

interface IWhatYouNeedCardProps {
  screenResponsiveSizes?: {
    [key: string]: boolean;
  };
}

export const WhatYouNeedCard: React.FC<IWhatYouNeedCardProps> = ({ screenResponsiveSizes }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <Card title={t('internationalTransferPage.WhatYouNeed')} className="what-you-need-card">
      <>
        <div className="column-left">
          <div className="what-you-need-item">
            <div className="main-icon">
              {screenResponsiveSizes?.fromDesktopNormal ? <Icon name="idFront" color="blue" /> : <IconSign iconName="idFront" bgColor="blue10" iconColor="blue" size={40} />}
            </div>

            <div className="content">
              <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                {t('internationalTransferPage.YourNameAndDateOfBirth')}
              </BodyText>
            </div>
          </div>

          <div className="what-you-need-item">
            <div className="main-icon">{screenResponsiveSizes?.fromDesktopNormal ? <Icon name="rent" color="blue" /> : <IconSign iconName="rent" bgColor="blue10" iconColor="blue" size={40} />}</div>

            <div className="content">
              <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                {t('internationalTransferPage.YourHomeAddressNoPOBoxes')}
              </BodyText>
            </div>
          </div>

          <div className="what-you-need-item">
            <div className="main-icon">{screenResponsiveSizes?.fromDesktopNormal ? <Icon name="mail2" color="blue" /> : <IconSign iconName="mail2" bgColor="blue10" iconColor="blue" size={40} />}</div>

            <div className="content">
              <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                {t('internationalTransferPage.YourEmailAddress')}
              </BodyText>
            </div>
          </div>

          <div className="what-you-need-item">
            <div className="main-icon center">
              {screenResponsiveSizes?.fromDesktopNormal ? <Icon name="idBack" color="blue" /> : <IconSign iconName="idBack" bgColor="blue10" iconColor="blue" size={40} />}
            </div>

            <div className="content">
              <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                {t('internationalTransferPage.YourSocialSecurityNumberSSN')}
              </BodyText>
            </div>
          </div>
        </div>

        <div className="column-right">
          <div className="what-you-need-item unexpired-photo-id">
            <div className="main-icon">
              {screenResponsiveSizes?.fromDesktopNormal ? <Icon name="myInfo" color="blue" /> : <IconSign iconName="myInfo" bgColor="blue10" iconColor="blue" size={40} />}
            </div>

            <div className="content">
              <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                {t('internationalTransferPage.ValidUnexpiredPhotoId')}
              </BodyText>

              <ul className="list">
                <li>
                  <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                    {t('internationalTransferPage.AcceptableUSIdsInclude')}
                  </BodyText>
                </li>

                <li className="v-center">
                  <BodyText size="N" className="content-body" color="charcoal70" lineHeight={1.4}>
                    {t('internationalTransferPage.AcceptableNonUsIdsIncludePassport')}
                  </BodyText>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="what-you-need-item additional-info">
          <BodyText size="T" className="content-body" color="charcoal70" lineHeight={1.4}>
            {t('internationalTransferPage.YourInformationIsOnlyRequiredOnce')}
          </BodyText>
        </div>
      </>
    </Card>
  );
};
