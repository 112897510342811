import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { ResultsPage } from 'views/OpenCashAccount/MyIdPage/ResultsPage/ResultsPage';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { SLayout } from './UploadSuccessPage.styles';

export const UploadSuccessPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['cashAccountOpening', 'preRegOnboarding']);

  const handleGoToMain = () => {
    navigate(ROUTES.home.path);
  };

  return (
    <SLayout>
      <ResultsPage image="success" title={t('preRegOnboarding.ThankYou', { ns: 'preRegOnboarding' })} btnTitle={t('myInfo.Go to Home')} onClick={handleGoToMain}>
        <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="M" justifyContent="center" lineHeight="24px">
          {t('accountOpening.IDUploaded')}
        </BodyText>
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="M" textAlign="center" lineHeight="24px">
          {t('accountOpening.VerifyDeadlines')}
        </BodyText>
      </ResultsPage>
    </SLayout>
  );
};
