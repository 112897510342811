import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';

export const SCustomSheet = styled(CustomSheet)`
  .body {
    display: flex;
    flex-direction: column;
    outline: none;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none !important;
    }

    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    .additional-text {
      background: ${getColor('blue10')};
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 32px;
    }

    .destination-list {
      margin-bottom: 32px;
    }

    .learn-more-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      margin-bottom: 40px;

      .learn-more-link {
        text-decoration: underline ${getColor('blue')};
        cursor: pointer;
      }
    }

    .terms-link {
      text-decoration: underline;
      color: ${getColor('blue')};
      cursor: pointer;
    }
  }
`;
