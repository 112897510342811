import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  background: ${getColor('white')} no-repeat right bottom;
  padding: 32px;
  border-radius: 20px;

  .title-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    cursor: pointer;
  }

  ul {
    position: relative;
    padding: 0;
    margin: 0;
    height: auto !important;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  & .list-item-lvl1,
  & .list-item-lvl2 {
    display: inline-flex;
    list-style: none;
    margin-left: 0;
    padding: 0;
  }

  & .list-item-lvl1:before,
  & .list-item-lvl2:before {
    // Bullet character: •
    content: '\u2022';
    color: ${getColor('blue')};
    font-weight: bold;
    display: block;
    margin-left: 8px;
    margin-right: 12px;
  }

  & .list-item-lvl2 {
    margin-left: 26px;
  }

  & .link {
    color: ${getColor('blue')};
    border-bottom: 1px solid ${getColor('blue')};
  }

  & .link:hover {
    border: none;
  }

  ${mediaFrom(
    'tablet',
    css`
      .title-container {
        margin-bottom: 24px;

        .custom-title-text {
          font-size: 20px;
        }
      }

      ul {
        .list-item-lvl1 {
          margin-bottom: 12px;

          .custom-text-inner {
            font-size: 14px !important;
          }
        }
      }
    `
  )};
`;
