import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SSelectCountryRequestBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 580px;
  width: 100%;
  outline: none;
  background: ${getColor('white')};
  border-radius: 20px;
  padding: 32px 24px 24px;

  .countries-list {
    flex: 1 1 auto;
  }

  form#selectCountryDesktopForm {
    .ant-form-item {
      display: flex;
      flex-direction: column;

      &-countries-list-wrapper {
        .countries-list {
          height: 373px;
          overflow-y: scroll;

          .country-row {
            .icon-selected {
              margin-right: 35px;
            }
          }
        }
      }

      &.button-submit-wrapper {
        flex-direction: row;
        justify-content: center;

        button.button-submit {
          width: auto;
          font-weight: 600;
        }
      }
    }
  }
`;
