import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { canHaveAccounts, canHavePercapitaPayOrEnroll } from 'vars/const/USER_PROFILE_IDS';
import { selectCurrentAuthState, selectPolicies } from 'store/user/authentication.slice';
import { useLazyGetZogoTokenQuery } from 'store/user/zogo/zogo.api';
import { useLazyGetThirdPartyIdsQuery, useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { useGetUIPreferenceQuery } from 'store/user/properties/userProperties.api';
import { usePayments } from 'utils/hooks/usePayments';
import { Loader } from 'components/general/Loader/Loader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { useAppDispatch } from 'utils/hooks/store';
import { chatActions } from 'store/chat/chat.slice';
import { useHideJointAccountHolderMutation, useLazyGetJointAccountHolderQuery } from 'store/user/accounts/accounts.api';
import { useAccountFeatures } from 'utils/hooks/useAccountFeatures';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { CardHubModal } from 'components/general/Modals/CardHubModal/CardHubModal';
import { useCardHub } from 'utils/hooks/useCardHub';
import { PlayToWin } from 'views/PlayToWin/PlayToWin';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { ROUTES } from 'vars/const/ROUTES';
import { lsGetItem } from 'utils/helpers/storage';
import { Discover } from './Discover/Discover';
import { Accounts } from './Accounts/Accounts';
import { WatchAndLearn } from './WatchAndLearn/WatchAndLearn';
import { PercapitaPayCard } from './PercapitaPayCard/PercapitaPayCard';
import { SCards, SMainPage } from './MainPage.styles';
import { YouHaveBeenInvitedCard } from './YouHaveBeenInvitedCard/YouHaveBeenInvitedCard';
import { AccountFeaturePromo } from './AccountFeaturePromo/AccountFeaturePromo';
import { InternationalTransferCard } from './InternationalTransferCard/InternationalTransferCard';

export const MainPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setPolicies } = chatActions;
  const cardHubParam = searchParams.get('card-hub');
  const { isClient } = useUserStatus();
  const isMobileApp = lsGetItem('isMobileApp');
  const policies = useSelector(selectPolicies);
  const [isDisplayInvitationCard, setDisplayInvitationCard] = useState(false);
  const [isPlayToWinOpen, setPlayToWinOpen] = useState(false);
  const currentAuthState = useSelector(selectCurrentAuthState);
  const accountFeatures = useAccountFeatures();
  const { getPaymentsInfoQueryResult } = usePayments(false);
  const cardHub = useCardHub();
  const [getZogoToken, getZogoTokenResult] = useLazyGetZogoTokenQuery();
  const UIPreferencesQuery = useGetUIPreferenceQuery();
  const [getThirdPartyIds, getThirdPartyIdsResult] = useLazyGetThirdPartyIdsQuery();
  const [getCurrentUser, getCurrentUserResult] = useLazyGetCurrentUserQuery();
  const [getJointAccountHolder, getJointAccountHolderResult] = useLazyGetJointAccountHolderQuery();
  const [hideJointAccountHolder] = useHideJointAccountHolderMutation();
  const isDisplayAccountSection = !!currentAuthState?.user?.systemProfileId && canHaveAccounts(currentAuthState.user.systemProfileId);
  const isDisplayPercPay = !!currentAuthState?.user?.systemProfileId && canHavePercapitaPayOrEnroll(currentAuthState.user.systemProfileId) && Boolean(currentAuthState?.thirdPartyIds?.Immediate);
  const isDisplayEnroll = !!currentAuthState?.user?.systemProfileId && canHavePercapitaPayOrEnroll(currentAuthState.user.systemProfileId) && !currentAuthState?.thirdPartyIds?.Immediate;
  const isDisplayPercapitaCard = isDisplayPercPay || isDisplayEnroll;
  const isLoading =
    getThirdPartyIdsResult.isLoading ||
    getCurrentUserResult.isLoading ||
    accountFeatures.isLoading ||
    UIPreferencesQuery.isLoading ||
    getJointAccountHolderResult.isLoading ||
    getJointAccountHolderResult.isFetching;

  const shouldDisplayPennyJarPromo = !policies?.PennyJarEnabled && isClient;
  const shouldDisplayCashCushion =
    policies?.CashCushionEnabled && !currentAuthState?.UIPreferences?.isCashCushionDismissed && accountFeatures.overdraftFeature.overdraft?.isEligible && !accountFeatures.overdraftFeature.isEnabled;

  const arePromoBlocksVisible = shouldDisplayPennyJarPromo || shouldDisplayCashCushion;
  const isDisplayInternationalTransferCard = !!isMobileApp && policies?.InternationalTransferEnabled;

  const onInvitationCardClose = () => {
    setDisplayInvitationCard(false);
    hideJointAccountHolder(getJointAccountHolderResult?.data?.id);
  };

  const onPlayToWinClose = () => {
    navigate(ROUTES.home.path, { state: { playToWinOpen: false } });
    setPlayToWinOpen(false);
  };

  // Open Card Hub on certain Search Param
  useEffect(() => {
    if (cardHubParam === 'open') {
      cardHub.open();
    }
  }, [cardHubParam]);

  useEffect(() => {
    if (state) {
      setPlayToWinOpen(state.playToWinOpen);
    }
  }, [state]);

  useEffect(() => {
    getZogoToken();
    getThirdPartyIds();
    getCurrentUser();
    getJointAccountHolder();
    dispatch(setPolicies(policies));
  }, []);

  useEffect(() => {
    if (getJointAccountHolderResult.isSuccess) setDisplayInvitationCard(!!getJointAccountHolderResult?.data?.id);
    if (getJointAccountHolderResult.isError) setDisplayInvitationCard(false);
  }, [getJointAccountHolderResult]);

  return (
    <SMainPage className="main-page">
      {isLoading && <Loader />}
      <SCards>
        {isDisplayAccountSection && <Accounts hasJointAccountInvites={isDisplayInvitationCard} />}

        {arePromoBlocksVisible && (
          <div className="promo-blocks">
            {shouldDisplayPennyJarPromo && <AccountFeaturePromo type="PennyJar" />}
            {shouldDisplayCashCushion && <AccountFeaturePromo type="CashCushion" />}
          </div>
        )}

        {isDisplayInvitationCard && (
          <YouHaveBeenInvitedCard onHide={onInvitationCardClose} firstName={getJointAccountHolderResult?.data?.inviterFirstName} inviteId={getJointAccountHolderResult?.data?.id} />
        )}

        {isDisplayPercapitaCard && <PercapitaPayCard isLoading={getPaymentsInfoQueryResult.isFetching} />}
        {isDisplayInternationalTransferCard && <InternationalTransferCard />}

        <Discover isZogoLoading={getZogoTokenResult.isFetching} />
        <WatchAndLearn />

        <PageFooter type="full" />
      </SCards>
      <CardHubModal open={cardHub.isActive} onClose={cardHub.close} />
      <CustomSheet padding="0px" paddingBottom="0px" paddingTop="0px" isOpen={isPlayToWinOpen} onClose={onPlayToWinClose}>
        <PlayToWin />
      </CustomSheet>
    </SMainPage>
  );
};
