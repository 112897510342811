export const getArraysIntersection = <T>(arr1: T[], arr2: T[]) => {
  let checkArr = arr1;
  let baseArr = arr2;

  if (arr2.length <= arr1.length) {
    checkArr = arr2;
    baseArr = arr1;
  }
  return checkArr.filter((val) => baseArr.includes(val));
};

export const filterUnique = (arr: any) => arr.filter((item: string | number, index: number, array: (string | number)[]) => array.filter((arrItem: string | number) => arrItem === item).length === 1);

export const sortByProp = <T>(arr: T[], propName: keyof T) => arr?.sort((item1, item2) => Number(item2[propName]) - Number(item1[propName]));

export const mergeArrays = (arr1: any[], arr2: any[]) => filterUnique([...arr1, ...arr2]);
