import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  .header {
    border-radius: 24px 24px 0 0;
    border: 2px solid ${getColor('charcoal5')};
    border-bottom: none;
    padding: 24px;
    width: 100%;
    background: ${getColor('white')};
  }

  .header.closed {
    padding: 10px 20px;
    border-radius: 16px;
    border: 2px solid ${getColor('charcoal5')};
    margin-bottom: 32px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    font-family: DM Sans;
  }

  .title-container.closed {
    margin: 0;
  }

  .title-container .title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .title-container .title em {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 700;
    font-style: normal;
    height: 32px;
    width: 32px;
    color: ${getColor('blue')};
    background: ${getColor('blue5')};
  }

  .card {
    margin: 0 0 16px 0;
    padding: 24px;
    border: 2px solid ${getColor('charcoal5')};
    border-radius: 0 0 20px 20px;
    width: 100%;
    gap: 24px;
    display: flex;
    flex-direction: column;
    background: ${getColor('white')};
    margin-bottom: 32px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox-wrapper .checkbox-custom {
    height: 24px;
  }
`;
