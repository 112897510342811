import React, { useMemo, useState } from 'react';
import { ISystemProperty } from 'store/user/properties/types';
import { DestinationListItem } from './DestinationListItem/DestinationListItem';

interface IDestinationListProps {
  countries?: ISystemProperty[];
}

export const DestinationList = ({ countries }: IDestinationListProps) => {
  const [expandedCountry, setExpandedCountry] = useState('');
  const countriesSorted = useMemo(() => countries?.slice()?.sort((a, b) => (a.country < b.country ? -1 : 1)), [countries]);

  const handleItemClick = (countryName: string) => {
    setExpandedCountry(expandedCountry === countryName ? '' : countryName);
  };

  return (
    <div className="destination-list">
      {countriesSorted?.map((item: ISystemProperty) => (
        <DestinationListItem key={item.flag} {...item} isExpanded={expandedCountry === item.country} onHeaderClick={handleItemClick} />
      ))}
    </div>
  );
};
