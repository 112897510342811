import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Icon } from 'components/general/Icon/Icon';
import { IAccountItem, isThirdPartyAccount, IThirdParty } from 'store/user/accounts/accounts.types';
import { getExternalAccountSubtitle } from 'utils/helpers/accounts/accountsHelper';
import { useCurrencyFormat } from 'utils/hooks/useCurrencyFormat';
import { useToggle } from 'utils/hooks/useToggle';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { setShowOverlayPad } from 'store/ui.slice';
import { TAccountSourceType } from 'vars/types/accounts.types';
import { SContainer, SCircle } from './AccountSelect.styles';
import { AccountSelectDropdown } from './AccountSelectDropdown/AccountSelectDropdown';

export type IAccountSelectProps = {
  internalAccounts: IAccountItem[];
  externalAccounts?: IThirdParty[];
  selected: IAccountItem | IThirdParty | null;
  onSelect: (account: IAccountItem | IThirdParty | null) => void;
  isDisabled?: boolean;
  isFrom?: boolean;
  disabledExternalAccTypes?: string[];
  fromAccount?: IAccountItem | IThirdParty | null;
  hasSingleInternalAccount?: boolean;
  onAddNewAccount?: () => void;
  disableId?: string;
  preopen?: TAccountSourceType;
};

export const AccountSelect = ({
  selected,
  externalAccounts,
  internalAccounts,
  hasSingleInternalAccount,
  disabledExternalAccTypes = [],
  fromAccount = null,
  onSelect,
  isDisabled,
  isFrom = false,
  onAddNewAccount,
  disableId,
  preopen,
}: IAccountSelectProps) => {
  const dispatch = useDispatch();
  const { formatAutoSign } = useCurrencyFormat();
  const isPreopen = preopen === 'external' || preopen === 'percapita';
  const selectContainer = useToggle(isPreopen);
  const [selectedItem, setSelectedItem] = useState<IAccountItem | IThirdParty | null>(selected);
  const { currentWindowSize, fromDesktopSmall } = useDeviceDimension();
  const isDesktopSize = currentWindowSize >= 577;
  const isExternalSelected = !!selected && isThirdPartyAccount(selected);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const selectedName = isExternalSelected ? selected.externalDisplayAccountName : selected?.nickname || selected?.name;
  const iconChevronName = useMemo(() => {
    if (selectContainer.isActive) return 'chevronUp';
    if (selected) return 'chevronDown';
    return 'chevronDown';
  }, [selectContainer.isActive, selected]);

  const handleModalAccountSelect = (account: IAccountItem | IThirdParty) => {
    const accountId = isThirdPartyAccount(account) ? account.thirdPartyAccountId : account.fiservAccountId;
    if (accountId !== disableId) {
      selectContainer.hide();
      onSelect(account);
    }
  };

  useEffect(() => {
    setSelectedItem(selected);
  }, [selected]);

  useEffect(() => {
    dispatch(setShowOverlayPad(selectContainer.isActive));
  }, [selectContainer.isActive]);

  useClickOutside(selectContainerRef, selectContainer.hide);

  return (
    <SContainer className={clsx('account-select', selectContainer.isActive && 'active')} ref={selectContainerRef}>
      <button
        type="button"
        className="account"
        data-selected={!!selected || undefined}
        data-type={isExternalSelected ? 'external' : selected?.type}
        onClick={() => selectContainer.toggle()}
        disabled={isDisabled}
      >
        {!selected && fromDesktopSmall && (
          <SCircle>
            <Icon name="circleQuestion" color="charcoal70" />
          </SCircle>
        )}
        <div className="account-div">
          {fromDesktopSmall && !selected && (
            <span className="action">{isFrom ? <Trans i18nKey="internalTransfer.SelectAnAccountFrom" /> : <Trans i18nKey="internalTransfer.SelectAnAccountTo" />}</span>
          )}

          {!fromDesktopSmall && !selected && (
            <span className="action">
              <span>
                <Trans i18nKey="internalTransfer.Select an Account" />{' '}
              </span>
              <span>----</span>
            </span>
          )}

          {!!selected && (
            <>
              <Icon className="type-icon" name={isExternalSelected ? 'creditCard' : selected.icon} color={isDisabled ? 'charcoal50' : 'orange'} marginLeft={5} marginRight={10} marginTop={-4} />
              <div className="details">
                <div className="name">{selectedName}</div>
                {(isExternalSelected || selected.nickname) && <div className="id">{isExternalSelected ? getExternalAccountSubtitle(selected) : selected.name}</div>}
              </div>
              {!isExternalSelected && <span className="amount">{formatAutoSign(selected.balance)}</span>}
              {isExternalSelected && (
                <span className="amount external">
                  <Trans i18nKey="externalTransfer.ExternalAmountCantDisplayed" ns="moveMoney" />
                </span>
              )}
            </>
          )}
          <Icon name={iconChevronName} size="smaller" className="account-chevron-icon" marginRight={14} />
        </div>
      </button>
      {selectContainer.isActive && (
        <AccountSelectDropdown
          externalAccounts={externalAccounts}
          internalAccounts={internalAccounts}
          disabledExternalAccTypes={disabledExternalAccTypes}
          onSelect={handleModalAccountSelect}
          isDesktopSize={isDesktopSize}
          selected={selectedItem}
          onAddNewAccount={onAddNewAccount}
          isFrom={isFrom}
          fromAccount={fromAccount}
          hasSingleInternalAccount={hasSingleInternalAccount}
          disableId={disableId}
          preopen={preopen}
        />
      )}
    </SContainer>
  );
};
