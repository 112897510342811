import { Loader } from 'components/general/Loader/Loader';
import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentAuthState } from 'store/user/authentication.slice';
import { useLazyGetZogoPointsQuery } from 'store/user/zogo/zogo.api';
import { selectZogoData } from 'store/user/zogo/zogo.slice';
import { ROUTES } from 'vars/const/ROUTES';
import { BigCard } from 'views/Main/MainPage/Discover/BigCard/BigCard';

interface IPercapitaPlaySection {
  isLoading: boolean;
  isDesktopSize?: boolean;
}

export const PercapitaPlaySection: React.FC<IPercapitaPlaySection> = ({ isLoading, isDesktopSize }) => {
  const { t } = useTranslation('home');
  const [getZogoPointsAPI, getZogoPointsAPIResult] = useLazyGetZogoPointsQuery();
  const { primaryPoints } = useSelector(selectZogoData);
  const { zogoUserId } = useSelector(selectCurrentAuthState);

  useEffect(() => {
    getZogoPointsAPI({ zogoUserId: zogoUserId ?? '' });
  }, []);

  if (getZogoPointsAPIResult.isFetching || isLoading) return <Loader />;

  return (
    <BigCard
      title={t('homeScreen.PercapitaPlayTitle')}
      iconName="percapitaPlay"
      iconColor="blue"
      bgColor={isDesktopSize ? 'transparent' : 'blue5'}
      description={<Trans i18nKey="homeScreen.PercapitaPlayDescription" ns="home" components={{ strong: <strong /> }} />}
      buttonText={t('home.Play Now')}
      to={ROUTES.playPercUp.path}
      tooltip
      showPoints={primaryPoints > 0}
    />
  );
};
