import React, { useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { INameForm, FormInputProps } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { fullNameCharacterRule, getRequiredRule } from 'utils/helpers/validationRules';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

export const NameForm: React.FC<FormInputProps<INameForm>> = ({ onCompletion, handleSubmit, form, openingAccountData, isEditMode }) => {
  const userData = useSelector(selectCurrentUser);
  const { t } = useTranslation('cashAccountOpening');
  const { isDesktopSize } = useDeviceDimension();

  const onFieldsChange = () => {
    const hasErrors =
      (form.isFieldsTouched(true) && form.getFieldsError().some(({ errors }): number => errors.length)) || !form.getFieldValue('firstName')?.length || !form.getFieldValue('lastName')?.length;
    onCompletion(!hasErrors);
  };

  const submitFormData = (values: INameForm) => {
    form
      .validateFields()
      .then(() => {
        onCompletion(true);
      })
      .catch(() => {
        onCompletion(false);
      });

    handleSubmit?.(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: openingAccountData?.firstName || userData?.firstName || '',
      lastName: openingAccountData?.lastName || userData?.lastName || '',
      suffix: openingAccountData?.suffix || userData?.suffix || '',
    });
    onFieldsChange();
  }, [openingAccountData, userData]);

  return (
    <Form onFieldsChange={onFieldsChange} autoComplete="off" onFinish={submitFormData} layout="vertical" requiredMark={false} form={form}>
      <Form.Item
        name="firstName"
        validateTrigger={['onBlur', 'onChange']}
        validateFirst
        label={<CustomRequiredLabel label="myInfo.FirstName" fontFamily={isEditMode ? 'DM Sans' : 'Poppins'} isDanger />}
        rules={[getRequiredRule(t('myInfo.PleaseEnterFirstNameID')), fullNameCharacterRule()]}
      >
        <BaseInput placeholder={t('myInfo.FirstNameId')} data-testid="firstNameInput" onBeige={isEditMode && !isDesktopSize} marginBottom={0} hasClearIcon={false} />
      </Form.Item>

      <Form.Item
        name="lastName"
        validateTrigger={['onBlur', 'onChange']}
        validateFirst
        style={{ marginBottom: 0 }}
        label={<CustomRequiredLabel label="myInfo.LastName" fontFamily={isEditMode ? 'DM Sans' : 'Poppins'} />}
        rules={[getRequiredRule(t('myInfo.PleaseEnterLastNameID')), fullNameCharacterRule()]}
      >
        <BaseInput placeholder={t('myInfo.LastNameId')} data-testid="lastNameInput" onBeige={isEditMode && !isDesktopSize} marginBottom={0} hasClearIcon={false} />
      </Form.Item>
    </Form>
  );
};
