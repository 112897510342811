import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo, flex } from 'utils/helpers/styleHelpers';

export const SPennyJarActivatePage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  padding-bottom: 30px;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;

    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        border-bottom: 1px solid ${getColor('creamS5')};
        gap: 10px;
        height: 87px;
        margin-bottom: 48px;
      `
    )}

    .back {
      ${mediaUpTo(
        'desktop',
        css`
          display: none;
        `
      )}
    }

    .title {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      color: ${getColor('charcoal')};

      ${mediaFrom(
        'desktop',
        css`
          font-size: 28px;
        `
      )}

      ${mediaUpTo(
        'desktop',
        css`
          display: none;
        `
      )}
    }
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-bottom: 32px;

    ${mediaFrom(
      'desktop',
      css`
        margin-bottom: 40px;
        align-items: center;
      `
    )}

    .subtitle {
      color: ${getColor('charcoal')};
      margin-bottom: 8px;
      font-family: Poppins;
      font-size: 28px;
      font-weight: 600;
      text-align: start;

      ${mediaFrom(
        'desktop',
        css`
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 24px;
          width: 50%;
        `
      )}
    }

    .description {
      color: ${getColor('charcoal70')};
      font-size: 14px;
      font-weight: 400;

      ${mediaFrom(
        'desktop',
        css`
          width: 50%;
        `
      )}
    }
  }

  .switch-activate-card {
    ${flex('row', 'center')};
    width: 100%;
    .switch-card {
      width: 100%;
      ${mediaFrom(
        'desktop',
        css`
          width: 50%;
        `
      )}
    }
  }

  .activate-card {
    ${flex('row', 'space-between')};
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .penny-jar-box {
    background: ${getColor('white')};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 20px;
    margin-bottom: 30px;
    width: 100%;
    flex-wrap: wrap;

    .item {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }

    ${mediaFrom(
      'desktop',
      css`
        width: 50%;
      `
    )}

    span {
      font-size: 16px;
      font-weight: 700;
      color: ${getColor('charcoal')};
    }
  }

  .disclosure-container {
    display: flex;

    span {
      font-size: 10px;
      font-weight: 400;
      color: ${getColor('charcoal70')};
      line-height: 16px;
    }

    ${mediaFrom(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .disclosure-web-container {
    display: flex;

    span {
      font-size: 10px;
      font-weight: 400;
      color: ${getColor('charcoal70')};
      line-height: 16px;
    }

    width: 75%;
    justify-content: flex-start;
    margin: 30px 0 30px 30px;

    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}
  }
`;

export const SLink = styled.span`
  text-decoration: underline;
  color: ${getColor('blue')};
  cursor: pointer;
`;
