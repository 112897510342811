import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { SPageLayoutCommonStyle } from 'views/OpenCashAccount/MyInfo/MyInfo.style';

export const SPageLayout = styled.div`
  ${SPageLayoutCommonStyle};
`;

export const SLayout = styled.div<{ bgFooterImage?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${mediaFrom(
    'tablet',
    css`
      align-items: center;
    `
  )}

  .form-card {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    background: ${getColor('white')};
    margin-top: 32px;

    .ant-form {
      width: 100%;
    }

    ${mediaFrom(
      'desktop',
      css`
        width: 580px;
      `
    )}
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        max-width: 580px;
        margin-bottom: 44px;
      `
    )}
  }

  .mobile-title-container {
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  .web-title-container {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 44px;
    width: 100%;
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }

  .web-disclosure {
    ${({ bgFooterImage }) => css`
      background: url(${bgFooterImage}) no-repeat bottom right;
    `};

    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 200px;
    margin-bottom: 20px;

    .disclosure {
    }

    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
  }
`;
