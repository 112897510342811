import { notification } from 'antd';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TAdditionalInformationModalType, setShowAdditionalInformationModal } from 'store/ui.slice';
import { useAppDispatch } from 'utils/hooks/store';
import { SContainer } from './AccountItem.styles';

interface IDataListItem {
  title: string;
  number: string;
  tooltip?: TAdditionalInformationModalType;
}

export const AccountItem: React.FC<IDataListItem> = ({ title, number, tooltip }) => {
  const { t } = useTranslation('deposit');
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();

  const handleTooltipClick = () => {
    if (tooltip) {
      dispatch(
        setShowAdditionalInformationModal({
          displayAdditionalInformationModal: true,
          additionalInformationModalType: tooltip,
        })
      );
    }
  };

  const onCopyToClipboard = (accountNumber: string) => {
    if (accountNumber) {
      navigator.clipboard.writeText(number);
    }

    api.info({
      message: (
        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
          {title} {accountNumber ? t('setUpDeposit.Copied') : t('setUpDeposit.NotExist')}
        </BodyText>
      ),
      placement: 'top',
      duration: 2,
      style: { borderRadius: 20 },
      maxCount: 2,
      icon: <Icon name="circleTick" color={accountNumber ? 'green' : 'red'} />,
    });
  };

  return (
    <>
      {contextHolder}
      <SContainer>
        <div className="info-container">
          <div className="text-container">
            <span>{title}</span>
            {tooltip && <Icon name="info" color="blue" pictBgColor="blue" cursorPointer marginLeft={14} size="smaller" onClick={handleTooltipClick} />}
          </div>

          {number && <div className="number">{number}</div>}
        </div>
        <div className="clipboard-container">
          {number && <span>{number}</span>}
          <Icon name="duplicate" color="blue" size="small" cursorPointer onClick={() => onCopyToClipboard(number)} />
        </div>
      </SContainer>
    </>
  );
};
