import { useSelector } from 'react-redux';
import { lsGetItem } from 'utils/helpers/storage';
import { mobileApiCall } from 'services/mobileService';
import { selectPolicies } from 'store/user/authentication.slice';
import { useToggle } from './useToggle';
import { useAnalytics } from './useAnalytics';

export const useCardHub = () => {
  const cardHubModal = useToggle();
  const { track } = useAnalytics();
  const isMobileApp = lsGetItem('isMobileApp');
  const policies = useSelector(selectPolicies);

  const openCardHub = () => {
    if (policies?.DisableDigitalIssuance) {
      return;
    }

    if (isMobileApp) {
      mobileApiCall('cardHubRequest');
    } else {
      cardHubModal.show();
    }

    track('navigate', 'cardhub');
  };

  return {
    open: openCardHub,
    close: cardHubModal.hide,
    isActive: cardHubModal.isActive,
  };
};
