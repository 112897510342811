import React from 'react';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { SContainer } from './CombinedBalance.styles';

interface ICombinedBalance {
  balance: number | undefined;
}

export const CombinedBalance: React.FC<ICombinedBalance> = ({ balance }) => {
  const { t } = useTranslation('home');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <SContainer>
      <div className="divider" />
      <div className="row">
        <BodyText textType="bodyText" fontWeight="B" size="N" color="charcoal70" marginLeft={isDesktopSize ? 64 : 0} marginRight={20}>
          {t('homeScreen.CombinedBalance')}
        </BodyText>
        {balance !== undefined && (
          <CustomAmount amount={balance} color="charcoal" size={isDesktopSize ? 'xs' : 'smallerStrong'} remainingSize={isDesktopSize ? 'xs' : 'smallerStrong'} multiSizable remainingWeight={700} />
        )}
      </div>
    </SContainer>
  );
};
