import styled, { css } from 'styled-components';
import { getColor, mediaUpTo, mediaUpToHeight } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  background: ${getColor('white')};
  border-radius: 25px;
  height: 730px;
  padding: 90px 25px 0;
  margin-bottom: 30px;

  ${mediaUpTo(
    'tablet',
    css`
      .custom-title-text {
        font-size: 25px;
      }
    `
  )}

  ${mediaUpToHeight(
    780,
    css`
      height: 650px;
      padding-top: 60px;
    `
  )};
`;
