import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/general/Input/Input';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { Title, BodyText } from 'components/general/Typography';
import { useGetSurveyQuery, useSetSurveyAnswerMutation } from 'store/user/users.api';
import { IAnswer } from 'vars/types/survey.types';
import { ChoiceMultiple } from './ChoiceMultiple/ChoiceMultiple';
import { ChoiceSingle } from './ChoiceSingle/ChoiceSingle';
import { SCustomSheet } from './SurveyModal.styles';

const SURVEY_ID = 'cf0ee3c6-bdc2-4aea-95fd-04e2da30e60c';

interface ISurveyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClickHandler: () => void;
}

export const SurveyModal: React.FC<ISurveyModalProps> = ({ isOpen, onClose, onClickHandler }) => {
  const [answerList, setAnswerList] = useState<IAnswer[]>([]);
  const { t } = useTranslation();
  const { data: survey } = useGetSurveyQuery(SURVEY_ID);
  const [setSurveyAnswer] = useSetSurveyAnswerMutation();
  const updateAnswers = (newAnswer: IAnswer) => {
    setAnswerList((prevAnswers) => {
      const index = prevAnswers.findIndex((item) => item.surveyQuestionAnswerId === newAnswer.surveyQuestionAnswerId);

      if (newAnswer.userAnswer) {
        if (index === -1) {
          return [...prevAnswers, newAnswer];
        }
        return prevAnswers.map((item) => (item.surveyQuestionAnswerId === newAnswer.surveyQuestionAnswerId ? newAnswer : item));
      }
      return prevAnswers.filter((item) => item.surveyQuestionAnswerId !== newAnswer.surveyQuestionAnswerId);
    });
  };

  const removeAnswersByQuestion = (surveyQuestionId: string) => {
    setAnswerList((prevAnswers) => prevAnswers.filter((item) => item.surveyQuestionId !== surveyQuestionId));
  };

  const isSelected = (surveyQuestionAnswerId: string) => {
    return answerList.some((item) => item.surveyQuestionAnswerId === surveyQuestionAnswerId);
  };

  const handleOptionChanged = (newAnswer: IAnswer) => {
    removeAnswersByQuestion(newAnswer.surveyQuestionId);
    updateAnswers(newAnswer);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    updateAnswers({ surveyQuestionId: name, userAnswer: value });
  };

  const handleSubmit = () => {
    setSurveyAnswer({ id: SURVEY_ID, data: { answers: answerList } });
    onClickHandler();
  };

  return (
    <SCustomSheet isOpen={isOpen} width="480px" onClose={onClose} padding="24px" modalName="accountInformationSurvey" closable={false} wrapperPadding>
      <Title fontWeight="M" size="M" paddingRight={18}>
        {survey?.text || t('survey.HowCouldWeHaveMadeYourExperienceWithPercapitaBetter')}
      </Title>
      {survey?.surveyQuestions?.map((question) => (
        <>
          <BodyText fontWeight="B" size="N" marginTop={32}>
            {question.text}
          </BodyText>

          {question.answerType === 'MultipleChoiceMultiple' && (
            <CustomRow justifyContent="flex-start" alignItems="start" flexDirection="column" gap={10} marginTop={24}>
              {question.surveyQuestionAnswers.map((answer) => (
                <ChoiceMultiple surveyQuestionId={answer.surveyQuestionId} userAnswer={answer.text} surveyQuestionAnswerId={answer.id} onChange={updateAnswers} />
              ))}
            </CustomRow>
          )}

          {question.answerType === 'MultipleChoiceSingle' && (
            <CustomRow flexDirection="column" width="100%" justifyContent="flex-start" alignItems="flex-start" gap={10} marginTop={24}>
              {question.surveyQuestionAnswers.map((answer) => (
                <ChoiceSingle surveyQuestionId={answer.surveyQuestionId} userAnswer={answer.text} surveyQuestionAnswerId={answer.id} onChange={handleOptionChanged} selected={isSelected(answer.id)} />
              ))}
            </CustomRow>
          )}

          {question.answerType === 'Text' && (
            <CustomRow marginTop={24}>
              <Input placeholder={t('accountInformation.survey.WriteYourAnswer')} name={question.id} onChange={handleInput} />
            </CustomRow>
          )}
        </>
      ))}
      <Button onClick={handleSubmit} preset="blue-filled" size="xl" marginTop={32}>
        {t('accountInformation.survey.Submit')}
      </Button>
    </SCustomSheet>
  );
};
