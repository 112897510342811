import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import React from 'react';
import { congratulationV2 } from 'assets/images';
import { Button } from 'components/theme/Button/Button';
import clsx from 'clsx';
import { SCustomModal } from './CountriesRequestSubmitModal.styles';

interface ICountriesRequestSubmitModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleContinue?: () => void;
  isDesktop?: boolean;
  className?: string;
}

export const CountriesRequestSubmitModal: React.FC<ICountriesRequestSubmitModalProps> = ({ isOpen, onClose, handleContinue, className, isDesktop }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SCustomModal className={clsx('countries-request-submit-modal', className, { desktop: isDesktop })} open={isOpen} onClose={onClose} padding="32px 24px 24px" topPosition="14%">
      <img src={congratulationV2} alt="congratulation-v2-icon" width={120} className="logo-main" />

      <Title size="M" fontWeight="M" marginBottom={16}>
        {t('internationalTransferPage.YouHaveSuccessfullySubmitted')}
      </Title>

      <BodyText className="description" size="N" color="charcoal70" lineHeight={1.4} marginBottom={32}>
        {t('internationalTransferPage.BeOnTheLookoutForAnnouncementsFromPercapita')}
      </BodyText>

      <Button preset="blue-filled" onClick={handleContinue}>
        {t('internationalTransferPage.Continue')}
      </Button>
    </SCustomModal>
  );
};
