import { Icon } from 'components/general/Icon/Icon';
import styled, { css } from 'styled-components';
import { getColor, flex, mediaFrom } from 'utils/helpers/styleHelpers';

export const SArrowRight = styled(Icon)`
  flex: 0 0 auto;
  margin-left: 21px;
`;

export const SIcon = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 18px;
  z-index: 999;
`;

export const SLayout = styled.div<{ isDesktopSize?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-bottom: 20px;

  .email-verification-layout {
    width: auto;

    ${flex('column', 'flex-start', 'flex-start')};

    ${mediaFrom(
      'tablet',
      css`
        width: 578px;
      `
    )}
  }

  button:disabled {
    background: ${getColor('charcoal40')};
    color: ${getColor('white')};
    border: none;
  }

  ${({ isDesktopSize }) =>
    isDesktopSize &&
    css`
      display: flex;
      align-items: center;
    `}
`;
