import { api } from 'store/api';
import { API_ROUTES } from 'vars/const/API_ROUTES';
import { IPostReferRequest, IPostReferResponse } from 'vars/types/refer.types';

export const referApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createReferral: builder.mutation<IPostReferResponse, IPostReferRequest>({
      query: (data) => ({
        url: API_ROUTES.user.create_referral,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
