import styled from 'styled-components';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  /* fix for main layout setting */
  padding-bottom: 10px;
`;
