import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { images } from 'assets';
import { BodyText, Title } from 'components/general/Typography';
import { useProperties } from 'utils/hooks/useProperties';
import { Button } from 'components/theme/Button/Button';
import { ROUTES } from 'vars/const/ROUTES';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { IMyInfoEditFromVerify } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { Loader } from 'components/general/Loader/Loader';
import { SContainer, SHeader } from './EmailVerifiedPage.styles';

const convertToBoolean = (data: string | null | boolean) => {
  if (typeof data === 'string') {
    return data === 'true';
  }

  return data;
};

export const EmailVerifiedPage = () => {
  const { t } = useTranslation('cashAccountOpening');
  const navigate = useNavigate();
  const location = useLocation() as IMyInfoEditFromVerify;
  const [searchParams] = useSearchParams();
  const { saveOnboardingData } = useCashAccountOpening();
  const { getProperty, getPropertyResult } = useProperties();
  const [jointType, setJointType] = useState('');
  const isEditing = location?.state?.isEditing || searchParams.get('isEditing');

  const handleOnContinue = () => {
    saveOnboardingData({ currentUrl: ROUTES.myInfoAge.path });
    navigate(convertToBoolean(isEditing) ? ROUTES.myInfoSummary.path : ROUTES.myInfoAge.path);
  };

  useEffect(() => {
    getProperty('CAOType')
      .unwrap()
      .then((response: string) => {
        setJointType(response);
      });
  }, []);

  return (
    <>
      <SHeader>
        <Header title="" stage="Email" webStage="Email" showExit={false} showBack={false} />
      </SHeader>
      <SContainer>
        <div className="content-container">
          {(getPropertyResult.isLoading || getPropertyResult.isFetching) && <Loader />}
          <div className="web-image-container">
            <img src={images.success} alt="Email verified" />
          </div>

          <div className="mobile-image-container">
            <img src={images.success} alt="Email verified" />
          </div>

          <Title className="body-title" textAlign="center" fontWeight="M" justifyContent="center" size="sM" marginTop={24} marginBottom={12}>
            {t('myInfo.ThankYou')}
          </Title>

          <BodyText className="body-description" textType="bodyText" color="charcoal70" fontWeight="R" size="T" textAlign="center" justifyContent="center">
            {t('myInfo.YourEmailVerified')}
          </BodyText>

          <div className="continue-container">
            <Button preset="blue-filled" onClick={handleOnContinue}>
              {t('myInfo.ContinueAccountOpening')}
            </Button>
          </div>
        </div>

        <footer className="mobile-footer">
          <Button preset="blue-filled" onClick={handleOnContinue}>
            {jointType === 'Joint' ? t('myInfo.ContinueAccountOpening') : t('myInfo.ContinueAccountOpening')}
          </Button>
          <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="M" justifyContent="end" marginTop={24}>
            {t('myInfo.emailVerified.nextStep')}
          </BodyText>
        </footer>
      </SContainer>
    </>
  );
};
