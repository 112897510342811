import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { Input } from 'components/general/Input/Input';
import { Button } from 'components/theme/Button/Button';
import { useUpdateAccountsByIdMutation, useRenameThirdPartyMutation } from 'store/user/accounts/accounts.api';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/general/Loader/Loader';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { accountNameCharacterRule } from 'utils/helpers/validationRules';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SHeader, SButtonContainer } from './EditAccountNameModal.styles';

interface EditAccountModalProps {
  editAccountNameOpen: boolean;
  isActive?: boolean;
  currentName?: string;
  accountId: string;
  isDefault?: boolean;
  isExternal?: boolean;
  isLoading?: boolean;
  isDesktopSize?: boolean;
  onClose: () => void;
}

export const EditAccountNameModal = ({ accountId, currentName = '', editAccountNameOpen, isActive, onClose, isDefault, isExternal, isLoading, isDesktopSize }: EditAccountModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation('accountInformation');
  const [accountNameForm] = useForm();
  const [updateAccountsById, { isLoading: isLoadingInternal }] = useUpdateAccountsByIdMutation();
  const [renameExternalAccount, { isLoading: isLoadingExternal }] = useRenameThirdPartyMutation();
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const handleFieldsChange = async () => {
    const hasErrors = accountNameForm.getFieldsError().some(({ errors }): number => errors.length);
    const newAccountName = await accountNameForm.getFieldValue('new-account-name');

    setButtonDisabled(hasErrors || currentName === newAccountName);
  };

  const handleChangeName = async () => {
    const newAccountName = accountNameForm.getFieldValue('new-account-name');

    if (isExternal && accountId) {
      await renameExternalAccount({
        accountId,
        newName: newAccountName,
      });
      onClose();
    } else {
      await updateAccountsById({
        accountId,
        data: { nickname: newAccountName, isDefault },
      });
      onClose();
    }
  };

  useEffect(() => {
    if (isActive) setButtonDisabled(true);
    accountNameForm.setFieldValue('new-account-name', currentName);
  }, [isActive, currentName]);

  if (isDesktopSize) {
    return (
      <CustomModal open={editAccountNameOpen} onCancel={onClose} modalName="editAccountNameModal" destroyOnClose centered footer={null} closeIconColor="charcoal70" topPosition="0%">
        {(isLoadingInternal || isLoadingExternal || isLoading) && <Loader />}

        <Form form={accountNameForm} initialValues={{ newAccountName: currentName }} onFinish={handleChangeName} onFieldsChange={handleFieldsChange}>
          <SHeader>
            <Icon name="arrowLeft" color="charcoal" onClick={onClose} cursorPointer size="small" />
            <span className="title">{t('accountInformation.Edit Account name')}</span>
          </SHeader>

          <BodyText size="N" marginBottom={5}>
            {t('accountInformation.Account name')}
          </BodyText>

          <Form.Item name="new-account-name" validateTrigger={['onBlur', 'onChange']} rules={[accountNameCharacterRule()]} style={{ marginBottom: 48, marginTop: 8 }}>
            <Input placeholder={t('accountInformation.Write new Account name')} />
          </Form.Item>

          <Form.Item>
            <SButtonContainer>
              <Button disabled={isButtonDisabled} size="large" preset="blue-filled" type="submit">
                {t('accountInformation.Save Account name')}
              </Button>
            </SButtonContainer>
          </Form.Item>
        </Form>
      </CustomModal>
    );
  }

  return (
    <CustomSheet
      isOpen={editAccountNameOpen}
      modalName="editAccountNameSheet"
      headerStyle={{ background: theme.white, paddingBottom: 0 }}
      header
      onClose={onClose}
      isLocked={isLoadingInternal || isLoadingExternal || isLoading}
    >
      {(isLoadingInternal || isLoadingExternal || isLoading) && <Loader />}

      <Form form={accountNameForm} initialValues={{ newAccountName: currentName }} onFinish={handleChangeName} onFieldsChange={handleFieldsChange}>
        <SHeader>
          <Icon name="arrowLeft" color="charcoal" onClick={onClose} cursorPointer size="small" />
          <span className="title">{t('accountInformation.Edit Account name')}</span>
        </SHeader>

        <BodyText size="N" marginBottom={5}>
          {t('accountInformation.Account name')}
        </BodyText>

        <Form.Item name="new-account-name" validateTrigger={['onBlur', 'onChange']} rules={[accountNameCharacterRule()]} style={{ marginBottom: 48, marginTop: 8 }}>
          <Input placeholder={t('accountInformation.Write new Account name')} />
        </Form.Item>

        <Form.Item>
          <Button disabled={isButtonDisabled} size="large" preset="blue-filled" type="submit">
            {t('accountInformation.Save Account name')}
          </Button>
        </Form.Item>
      </Form>
    </CustomSheet>
  );
};
