import { Icon } from 'components/general/Icon/Icon';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { TSizeProp } from 'components/general/Typography/Typography.types';
import { BodyText } from 'components/general/Typography';
import React from 'react';
import { SCircle } from './ListItem.styles';

interface IListItem {
  text: string | React.ReactNode;
  IconName: string;
  circleFilled?: boolean;
  size: TSizeProp;
  marginTop?: number;
}

export const ListItem: React.FC<IListItem> = ({ text, circleFilled = true, IconName, size = 'S', marginTop }) => (
  <CustomRow justifyContent="flex-start" marginBottom={9} marginTop={marginTop}>
    <BodyText
      textType="bodyText"
      size={size}
      icon={
        circleFilled ? (
          <SCircle>
            <Icon name={IconName} color="blue" />
          </SCircle>
        ) : (
          <Icon name={IconName} color="blue" />
        )
      }
      fontWeight="R"
      font="DM Sans"
      lineHeight={1.6}
      color="charcoal70"
    >
      {text}
    </BodyText>
  </CustomRow>
);
