import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SCountryRequestPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 102px;

  .desktop-page-header-wrapper .desktop-page-header,
  .body,
  .footer {
    padding: 0 128px;
  }

  .desktop-page-header-wrapper {
    .desktop-page-header {
      &__inner {
        padding: 14px 0 18px;

        .desktop-page-header__title-wrapper {
          margin-left: 0;
        }
      }

      .crumbs {
        margin: 0 0 42px;

        li {
          .crumbs__crumb-text_last {
            background: none;
          }
        }
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 92px;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 676px;
      margin-bottom: 68px;

      .body-text {
        .custom-text-inner {
          text-align: center;
        }
      }
    }
  }
  
  .select-your-country-request-section {
    ${mediaFrom(
      'tablet',
      css`
        max-height: 84vh;
      `
    )}
  }
  }
`;
