import React, { useEffect, useRef } from 'react';
import assert from 'assert';
import { BodyText } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { AmountInput, TAmountInputHandler, TAmountBlurHandler, TAmountInputOptions } from './AmountInput';
import { SAmountInput, SContainer } from './AmountInputComponent.styles';

/*
 * Rename onInput to onChange so that antd validator can connect to this component.
 * I don't believe that this callback should be named onChange in AmountInput as well,
 * because in real DOM onInput and onChange are actually two very different things;
 * just because the React team didn't bother to follow the existing DOM standard
 * doesn't make it less so.
 */

export type IAmountInputComponentProps = Omit<TAmountInputOptions, 'onInput'> & {
  wrapperClassName?: string;
  onChange?: TAmountInputHandler | null;
  onBlur?: TAmountBlurHandler | null;
  bottomText?: string;
  autoFocus?: boolean;
};

export const AmountInputComponent = ({ wrapperClassName, bottomText, autoFocus, ...options }: IAmountInputComponentProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<AmountInput | null>(null);
  const isFirstRender = useRef(true);
  const { isDesktopSize } = useDeviceDimension();

  useEffect(() => {
    assert(containerRef.current !== null);
    inputRef.current = new AmountInput(containerRef.current, {
      ...options,
      onInput: options.onChange,
      onBlur: options.onBlur,
      isDesktopSize,
    });
    inputRef.current.mount();
    if (autoFocus && inputRef.current) {
      inputRef.current.setFocusOnInput();
    }
    return () => inputRef.current?.unmount();
    // this ignore is intentional, because we don't want to recreate the input every time the options change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      assert(inputRef.current !== null);
      inputRef.current.setNoPrefix(options.noPrefix);
      inputRef.current.setIsDesktopSize(options.isDesktopSize);
    }
  }, [options.noPrefix]);

  useEffect(() => {
    if (!isFirstRender.current) {
      assert(inputRef.current !== null);
      inputRef.current.setClassName(options.className);
    }
  }, [options.className]);

  useEffect(() => {
    if (!isFirstRender.current) {
      assert(inputRef.current !== null);
      inputRef.current.setValue(options.value);
    }
  }, [options.value]);

  useEffect(() => {
    if (!isFirstRender.current) {
      assert(inputRef.current !== null);
      inputRef.current.setInputHandler(options.onChange);
    }
  }, [options.onChange]);

  useEffect(() => {
    if (!isFirstRender.current) {
      assert(inputRef.current !== null);
      inputRef.current.setDisabled(options.disabled);
    }
  }, [options.disabled]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return (
    <SContainer>
      <SAmountInput ref={containerRef} className={wrapperClassName} />
      {bottomText && (
        <BodyText textType="helperText" size="N" color="charcoal70" className="description">
          {bottomText}
        </BodyText>
      )}
    </SContainer>
  );
};
