import styled, { css } from 'styled-components';
import { SCREEN_SIZE, getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { WonderingAbout } from './LearnAndPlay/WonderingAbout/WonderingAbout';

export const SWellnessPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 24px;

  .faq-container {
    ${mediaUpTo(
      SCREEN_SIZE.tablet,
      css`
        display: none;
      `
    )}

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 34px;
    width: 100%;

    button {
      padding: 10px 16px;
      border: 2px solid ${getColor('blue')};
      color: ${getColor('blue')};
      background: transparent;
      border-radius: 100px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .wellness-page__content:has(.tab-labels) {
    max-width: 981px;
    width: 100%;
    min-height: 60px;
    display: flex;
    gap: 8px;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .tab-labels {
    margin-bottom: 18px;
  }

  ${mediaFrom(
    577,
    css`
      .wellness-page__content:has(.tab-labels) {
        .tab-labels {
          display: flex;
          flex: 1;
        }

        gap: 0;
      }
    `
  )}

  ${mediaFrom(
    'desktopLarge',
    css`
      .wellness-page__content:has(.tab-labels) {
        max-width: 992px;
      }
    `
  )}
`;

export const SWellnessTabContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;

  .earn-points-wrapper {
    display: flex;
    flex-direction: column;

    ${mediaFrom(
      'tablet',
      css`
        flex-direction: row;
        align-items: unset;
        gap: 30px;
      `
    )}
  }

  ${mediaUpTo(
    'tablet',
    css`
      .custom-title-text {
        font-size: 20px;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      max-width: 981px;
    `
  )}

  ${mediaFrom(
    'desktopLarge',
    css`
      max-width: 992px;
    `
  )}
`;

export const SWonderingAbout = styled(WonderingAbout)`
  padding-bottom: 42px;
`;

export const SWellnessFAQ = styled.div`
  padding-top: 65px;

  .wellness-faq {
    &__content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;
