import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { useEditUserProfileDataMutation, useGetUserProfileDataMutation } from 'store/user/users.api';
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Form, Col, Row } from 'antd';
import { addressCharacterRule, getRequiredCityRule, getRequiredRule, maxLengthRule, minLengthRule, stateCharacterRule, zipCharacterRule } from 'utils/helpers/validationRules';
import { Button } from 'components/theme/Button/Button';
import { Input } from 'components/general/Input/Input';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { Loader } from 'components/general/Loader/Loader';
import { useToggle } from 'utils/hooks/useToggle';
import { StateAddressSheet } from 'views/OpenCashAccount/MyInfo/AddressPage/AddressForm/StateAddressDrawer';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { AutocompleteAddress } from 'components/general/AutocompleteAddress/AutocompleteAddress';
import { TAddressFields } from 'components/general/AutocompleteAddress/AutocompleteAddress.types';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useUSPSValidateAddressMutation } from 'store/api';
import { handleUSPSAddressCheckResult } from 'views/OpenCashAccount/MyInfo/MyInfo.helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { CheckAddressModal } from './CheckAddressModal';
import { SModalAddressLayout } from './common/styles';
import { SActions } from './EditAddress.styles';

interface IFormValues {
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  isMailingDifferent?: boolean;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
}

interface IProps {
  isSheetOpen?: boolean;
  closeSheet?: () => void;
  type?: string;
  mode?: string;
}

const initialDisabledFields: Record<string, boolean | undefined> = {
  address1: false,
  address2: false,
  city: false,
  stateProvince: false,
  postalCode: false,
  mailingAddress1: false,
  mailingAddress2: false,
  mailingCity: false,
  mailingState: false,
  mailingPostalCode: false,
};

export const EditAddress = ({ isSheetOpen, closeSheet, type, mode }: IProps) => {
  const { t } = useTranslation(['profile', 'cashAccountOpening']);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isProspect } = useUserStatus();
  const [form] = Form.useForm();
  const { checkErrors } = useFormHelpers(form);
  const [checkAddress, checkAddressResult] = useUSPSValidateAddressMutation();
  const handledAddressCheckResult = handleUSPSAddressCheckResult(checkAddressResult.data);
  const addressCheckModal = useToggle();

  const [editUserProfileData, editUserProfileDataResult] = useEditUserProfileDataMutation();
  const [getUserProfileData] = useGetUserProfileDataMutation();
  const { saveOnboardingData } = useCashAccountOpening();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isMailingDifferent, setIsMailingDifferent] = useState(false);
  const [disabledFields, setDisabledFields] = useState(initialDisabledFields);

  const stateAddressSheet = useToggle(false);
  const mailingStateAddressSheet = useToggle(false);

  const userProfileData = useSelector(selectCurrentUser);
  const { address1, address2, city, stateProvince, postalCode, mailingAddress1, mailingAddress2, mailingCity, mailingStateProvince, mailingPostalCode, isMailingAddressTheSame } =
    userProfileData || {};

  const initialValues = {
    address1,
    address2,
    city,
    stateProvince,
    postalCode,
    isMailingDifferent: address1 && !isMailingAddressTheSame,
    mailingAddress1,
    mailingAddress2,
    mailingCity,
    mailingState: mailingStateProvince,
    mailingPostalCode,
  };

  const lockFormSection = (sectionName: 'mailingAddress1' | 'address1', lock: boolean) => {
    const fields =
      sectionName === 'mailingAddress1'
        ? {
            mailingAddress1: lock,
            mailingAddress2: lock,
            mailingCity: lock,
            mailingState: lock,
            mailingPostalCode: lock,
          }
        : {
            address1: lock,
            address2: lock,
            city: lock,
            stateProvince: lock,
            postalCode: lock,
          };

    setDisabledFields({
      ...disabledFields,
      ...fields,
    });
  };

  const handleFormChange = () => {
    setIsDisabled(form.getFieldsError().some(({ errors }) => errors.length));
  };

  const handleChangeDifferentMail = () => {
    setIsMailingDifferent((preVal) => {
      if (!preVal) {
        lockFormSection('mailingAddress1', false);
      } else {
        form.setFieldsValue({
          mailingAddress1: form.getFieldValue('address1'),
          mailingAddress2: form.getFieldValue('address2'),
          mailingCity: form.getFieldValue('city'),
          mailingState: form.getFieldValue('stateProvince'),
          mailingPostalCode: form.getFieldValue('postalCode'),
        });

        lockFormSection('mailingAddress1', true);
      }

      return !preVal;
    });

    handleFormChange();
  };

  const fillForm = (addressComponents: Record<TAddressFields, string>, name: string | undefined) => {
    let FIELD_NAMES: Record<string, string> = {};

    switch (name) {
      case 'mailingAddress1': {
        FIELD_NAMES = {
          ADDRESS: 'mailingAddress1',
          CITY: 'mailingCity',
          STATE_PROVINCE: 'mailingState',
          ZIP_CODE: 'mailingPostalCode',
        };
        break;
      }
      default:
        FIELD_NAMES = {
          ADDRESS: 'address1',
          CITY: 'city',
          STATE_PROVINCE: 'stateProvince',
          ZIP_CODE: 'postalCode',
        };
    }

    form.setFieldsValue({
      [FIELD_NAMES.ADDRESS]: `${addressComponents.street_number ?? ''} ${addressComponents.route?.toUpperCase() ?? ''}`.trim(),
      [FIELD_NAMES.CITY]: addressComponents.locality?.toUpperCase() ?? '',
      [FIELD_NAMES.STATE_PROVINCE]: addressComponents.administrative_area_level_1?.toUpperCase() ?? '',
      [FIELD_NAMES.ZIP_CODE]: addressComponents.postal_code ?? '',
    });

    form.validateFields().then();
  };

  const handleKeepMineClick = () => {
    addressCheckModal.hide();
  };

  const handleModalTakeSuggested = () => {
    const formValues = form.getFieldsValue();

    const updatedAddress = {
      ...formValues,
      ...handledAddressCheckResult?.suggested,
      address1: handledAddressCheckResult?.suggested.address,
      postalCode: handledAddressCheckResult?.suggested.zipCode,
    };

    form.setFieldsValue(updatedAddress);
    addressCheckModal.hide();
  };

  const onFinish = async (formValues: IFormValues) => {
    const isSameMailingAddress = mode === 'add' ? false : !isMailingDifferent;
    const homeAddressNewData = {
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      stateProvince: formValues.stateProvince,
      postalCode: formValues.postalCode,
    };
    const mailingAddressNewData = isMailingDifferent
      ? {
          mailingAddress1: formValues.mailingAddress1,
          mailingAddress2: formValues.mailingAddress2,
          mailingCity: formValues.mailingCity,
          mailingStateProvince: formValues.mailingState,
          mailingPostalCode: formValues.mailingPostalCode,
          isMailingAddressTheSame: isSameMailingAddress,
        }
      : { isMailingAddressTheSame: isSameMailingAddress };

    const updatedAddressData = {
      ...homeAddressNewData,
      ...mailingAddressNewData,
    };

    try {
      const USPSCheckRes = await checkAddress?.({
        address: `${formValues?.address1}`,
        address2: formValues?.address2,
        zip: formValues?.postalCode,
        city: formValues?.city,
        state: formValues?.stateProvince,
      }).unwrap();

      const handledUSPSCheckResult = handleUSPSAddressCheckResult(USPSCheckRes);

      if (handledUSPSCheckResult?.isSuccess) {
        saveOnboardingData(updatedAddressData);
        editUserProfileData({
          profileData: updatedAddressData,
        }).then((res: any) => {
          closeSheet?.();
          if (!isProspect) {
            navigate(ROUTES.changeAddress.path, {
              state: res?.data,
            });
          }
        });
      } else {
        addressCheckModal.show();
      }
    } catch (err) {
      console.error('Address update error: ', err);
    }
  };

  useEffect(() => {
    getUserProfileData({});
  }, []);

  useEffect(() => {
    setIsMailingDifferent(!isMailingAddressTheSame && address1);
  }, [isMailingAddressTheSame]);

  useEffect(() => {
    lockFormSection('mailingAddress1', false);

    if (isSheetOpen && !isMailingDifferent) {
      lockFormSection('mailingAddress1', true);
    }
  }, [isSheetOpen, isMailingDifferent]);

  return (
    <SModalAddressLayout>
      <Form onFinish={onFinish} onFieldsChange={checkErrors} form={form} requiredMark={false} layout="vertical" autoComplete="off" initialValues={initialValues}>
        {/* Home address */}
        <CustomCard padding="0" marginTop={0} className="content-wrapper">
          {pathname === ROUTES.atmLocations.path ? (
            <>
              <Title color="charcoal" fontWeight="SB" size="S" marginBottom={16} font="Poppins">
                {t('profile.ChangeYourLocation')}
              </Title>

              <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans">
                {t('profile.Home Address')}
              </BodyText>

              <BodyText fontWeight="M" size="M" textType="bodyText" color="charcoal" lineHeight="24px">
                {initialValues?.address1}
              </BodyText>

              <BodyText fontWeight="M" size="M" textType="bodyText" color="charcoal" lineHeight="24px" marginBottom={24}>
                {initialValues?.city}, {initialValues?.stateProvince} {initialValues?.postalCode}
              </BodyText>

              <BodyText textType="bodyText" color="charcoal" fontWeight="R" size="N" font="DM Sans" marginBottom={8}>
                {t('profile.NewLocation')}
              </BodyText>
            </>
          ) : (
            <>
              <Title color="charcoal" fontWeight="SB" size="S" marginBottom={10} font="Poppins" className="title">
                {t('profile.Home Address')}
              </Title>

              <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={32} className="description">
                {t('profile.PleaseEnterYourNewHomeAddressHere')}
              </BodyText>
            </>
          )}

          <Form.Item className="address-1" name="address1" validateTrigger={['onBlur', 'onChange']} rules={[getRequiredRule('profile.Please input street'), maxLengthRule(), addressCharacterRule()]}>
            <AutocompleteAddress onKeyUp={handleFormChange} disabled={disabledFields.address1} placeholder={t('profile.Street address line 1')} setAddressComponents={fillForm} />
          </Form.Item>

          <Form.Item className="address-2" name="address2" validateTrigger={['onBlur', 'onChange']} rules={[maxLengthRule(), addressCharacterRule()]}>
            <Input placeholder={t('profile.AptSuiteOptional')} disabled={disabledFields.address2} />
          </Form.Item>

          <Form.Item className="city" name="city" validateTrigger={['onBlur', 'onChange']} rules={[getRequiredCityRule(), addressCharacterRule()]}>
            <Input onKeyUp={handleFormChange} disabled={disabledFields.city} placeholder={t('profile.City')} />
          </Form.Item>

          <Row gutter={10}>
            <Col span={12} className="col-state-province">
              <Form.Item style={{ width: '100%' }} name="stateProvince" validateTrigger={['onBlur', 'onChange']} rules={[getRequiredRule('profile.State required'), stateCharacterRule()]}>
                <Input placeholder={t('myInfo.State', { ns: 'cashAccountOpening' })} disabled={disabledFields.stateProvince} onClick={stateAddressSheet.show} />
              </Form.Item>
            </Col>

            <Col span={12} className="col-postal-code">
              <Form.Item
                name="postalCode"
                validateTrigger={['onBlur', 'onChange']}
                validateFirst
                rules={[
                  getRequiredRule(t('myInfo.Please input your zip code', { ns: 'cashAccountOpening' })),
                  minLengthRule(5, t('myInfo.Incomplete Zip code', { ns: 'cashAccountOpening' })),
                  zipCharacterRule(),
                ]}
              >
                <BaseInput onKeyUp={handleFormChange} disabled={disabledFields.postalCode} placeholder={t('myInfo.Zip Code', { ns: 'cashAccountOpening' })} inputMode="numeric" maxLength={5} />
              </Form.Item>
            </Col>
          </Row>

          <CustomRow className="is-mailing-different-container">
            <Form.Item name="isMailingDifferent" valuePropName="checked">
              <Checkbox checked={isMailingDifferent} onChange={handleChangeDifferentMail}>
                <BodyText textType="helperText" fontWeight="R" size="S" color="charcoal70" font="DM Sans">
                  {t('myInfo.MyMailingAddressIsDifferent', { ns: 'cashAccountOpening' })}
                </BodyText>
              </Checkbox>
            </Form.Item>
          </CustomRow>
        </CustomCard>

        {/* Mailing address */}
        {isMailingDifferent && (
          <CustomCard padding="0" marginTop={0} className="content-wrapper">
            <Title color="charcoal" fontWeight="SB" size="S" marginBottom={10} font="Poppins" className="title">
              {t('profile.Mailing Address')}
            </Title>

            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={32} className="description">
              {t('profile.PleaseEnterYourNewMailingAddressHere')}
            </BodyText>

            <Form.Item name="mailingAddress1" validateTrigger={['onBlur', 'onChange']} rules={[getRequiredRule('profile.Please input street'), maxLengthRule(), addressCharacterRule()]}>
              <AutocompleteAddress onKeyUp={handleFormChange} disabled={disabledFields.mailingAddress1} placeholder={t('profile.Street address line 1')} setAddressComponents={fillForm} />
            </Form.Item>

            <Form.Item name="mailingAddress2" validateTrigger={['onBlur', 'onChange']} rules={[maxLengthRule(), addressCharacterRule()]}>
              <Input placeholder={t('profile.Street address line 2 (optional)')} disabled={disabledFields.mailingAddress2} />
            </Form.Item>

            <Form.Item name="mailingCity" validateTrigger={['onBlur', 'onChange']} rules={[getRequiredCityRule(), addressCharacterRule()]}>
              <Input onKeyUp={handleFormChange} placeholder={t('profile.City')} disabled={disabledFields.mailingCity} />
            </Form.Item>

            <Row gutter={10}>
              <Col span={12} className="col-state-province">
                <Form.Item style={{ width: '100%' }} name="mailingState" validateTrigger={['onBlur', 'onChange']} rules={[getRequiredRule('profile.State required'), stateCharacterRule()]}>
                  <Input placeholder={t('myInfo.State', { ns: 'cashAccountOpening' })} onClick={mailingStateAddressSheet.show} disabled={disabledFields.mailingState} />
                </Form.Item>
              </Col>

              <Col span={12} className="col-postal-code">
                <Form.Item
                  name="mailingPostalCode"
                  validateTrigger={['onBlur', 'onChange']}
                  validateFirst
                  rules={[getRequiredRule(t('myInfo.Please input your zip code', { ns: 'cashAccountOpening' })), minLengthRule(5), zipCharacterRule()]}
                >
                  <BaseInput
                    onKeyUp={handleFormChange}
                    disabled={disabledFields.mailingPostalCode}
                    placeholder={t('myInfo.Zip Code', { ns: 'cashAccountOpening' })}
                    inputMode="numeric"
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
            </Row>
          </CustomCard>
        )}

        <Form.Item className="submit-button">
          {pathname === ROUTES.atmLocations.path ? (
            <SActions>
              <Button className="cancel-btn" preset="blue-outlined" disabled={isDisabled} onClick={closeSheet}>
                {t(`profile.Cancel`)}
              </Button>

              <Button className="set-btn" preset="blue-filled" type="submit" disabled={isDisabled}>
                {t(`profile.SetNewLocation`)}
              </Button>
            </SActions>
          ) : (
            <Button preset="blue-filled" type="submit" size="middleStretch" disabled={isDisabled}>
              {type === 'address' && mode === 'add' && t(`profile.Submit`)}
              {type === 'address' && mode === 'edit' && t(`profile.SubmitChanges`)}
              {type === 'mailingAddress' && t(`profile.Save Changes`)}
            </Button>
          )}
        </Form.Item>

        {type === 'address' && pathname !== ROUTES.atmLocations.path && !isProspect && (
          <BodyText size="T" color="charcoal70" marginBottom={32} marginTop={24} className="footer">
            {t(`profile.WhenClickSubmit`)}
          </BodyText>
        )}

        {editUserProfileDataResult.isLoading && <Loader />}
      </Form>

      <StateAddressSheet
        changeSelection={handleFormChange}
        open={stateAddressSheet.isActive}
        onClose={stateAddressSheet.hide}
        form={form}
        value={userProfileData?.stateProvince}
        fieldName="stateProvince"
      />
      <StateAddressSheet
        changeSelection={handleFormChange}
        open={mailingStateAddressSheet.isActive}
        onClose={mailingStateAddressSheet.hide}
        form={form}
        value={userProfileData?.mailingStateProvince}
        fieldName="mailingState"
      />

      <CheckAddressModal
        isModalVisible={addressCheckModal.isActive}
        onClose={addressCheckModal.hide}
        errorType={handledAddressCheckResult?.errorType}
        errors={handledAddressCheckResult?.errorMessages}
        onConfirmClick={handleModalTakeSuggested}
        onEditClick={handleKeepMineClick}
        suggested={handledAddressCheckResult?.suggested}
        addressForm={form}
      />
    </SModalAddressLayout>
  );
};
