import styled, { css } from 'styled-components';
import { getColor, getRgba, mediaFrom } from 'utils/helpers/styleHelpers';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 4px 28px;
  margin-bottom: 10px;

  .page-header {
    display: flex;
    margin-bottom: 24px;

    .info {
      display: flex;
      flex-direction: column;
    }

    .image-map-desktop {
      display: none;
      height: 198px;
      width: 178px;
    }
  }

  .verification-times {
    border-radius: 20px;
    background: ${getColor('creamSS1')};
    padding: 24px;
    margin-bottom: 50px;

    .terms-link {
      text-decoration: underline;
      color: ${getColor('blue')};
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn-container {
    display: none;
  }

  ${mediaFrom(
    'desktopLarge',
    css`
      padding: 0 106px 22px 82px;

      .page-header,
      .destination-card,
      .how-does-it-work-section,
      .verification-times {
        width: 100%;
        align-self: center;
      }

      .destination-card,
      .how-does-it-work-section,
      .verification-times {
        max-width: 782px;
      }

      .page-header {
        justify-content: space-between;
        align-items: center;
        max-width: 800px;
        padding-left: 10px;
        margin-bottom: 105px;

        .info {
          max-width: 576px;

          .title {
            margin-bottom: 16px;

            .custom-title-text {
              font-size: 32px;
              font-weight: 500;
            }
          }
        }

        .image-map-desktop {
          display: block;
        }
      }

      .destination-card {
        padding: 32px;
        margin-bottom: 48px;

        .card-header {
          flex-direction: row;
          align-items: center;
          gap: 32px;

          .title,
          .flags-list,
          button {
            margin-bottom: 0;
          }

          .title {
            .custom-title-text {
              max-width: 285px;
              font-size: 24px;
              font-weight: 600;
            }
          }

          .image-map {
            display: none;
          }

          .flags-list {
            .flags-list-item {
            }
          }

          .send-money-button {
            display: none;
          }

          .destination-card-button {
            font-size: 18px;
            padding: 14px 32px;

            &.check-destinations-button {
              width: auto;
              font-size: 12px;
              line-height: 1.1;
              font-weight: 600;
              padding: 10px 24px;
              margin-bottom: 0;

              span {
                margin-right: 18px;
              }

              .icon-chevronDown {
                display: unset;
              }
            }
          }
        }

        .dont-see-you-country {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 12px;

          .link {
            text-decoration: underline;
          }
        }
      }

      .verification-times {
        margin-bottom: 8px;
      }

      .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        bottom: 36px;
        border-radius: 20px;
        background: linear-gradient(0deg, ${getRgba('cream70', 0.2)} 0%, ${getRgba('white', 0.6)} 100%);
        box-shadow: 0 16px 24px 0 ${getRgba('black', 0.04)};
        backdrop-filter: blur(12px);
        padding: 24px;
        margin-bottom: 36px;

        .send-money-button-desktop {
          width: auto;
          line-height: 21px;
          padding: 14px 40px;
        }
      }

      .footer {
        padding-right: 118px;
      }
    `
  )};
`;

export const SRemindModalLayout = styled(CustomModal)`
  img.main-image {
    height: 125px;
    width: 120px;
    align-self: center;
    margin-bottom: 32px;
  }
`;
