import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { Spinner } from 'components/general/Spinner/Spinner';
import { useGenerateRegistrationCodeMutation } from 'store/user/registration/registration.api';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { AuthPage } from 'components/layouts/AuthLayout/AuthPage.styles';
import { TextButton } from 'components/general/TextButton/TextButton';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { RegistrationForm } from './RegistrationForm/RegistrationForm';

export const RegistrationPage = () => {
  const { t } = useTranslation('registration');
  const navigate = useNavigate();
  const [, { isLoading }] = useGenerateRegistrationCodeMutation();
  const { isMobileSmallSize } = useDeviceDimension();

  const handleAlreadyHaveAccount = () => {
    navigate(ROUTES.login.path, { state: { isFromRegistration: true } });
  };

  return (
    <AuthPage.Container>
      <AuthPage.Content>
        {isLoading && <Spinner />}
        <Title font="Poppins" size="XL" fontWeight="M" marginBottom={8}>
          {t('registration.Title')}
        </Title>
        {!isMobileSmallSize && (
          <CustomRow justifyContent="flex-start" marginBottom={32}>
            <BodyText size="N" fontWeight="R" color="charcoal70" marginRight={15}>
              {t('registration.AlreadyHaveAccount')}
            </BodyText>
            <TextButton className="login-btn" size="N" fontWeight="M" onClick={handleAlreadyHaveAccount} font="Poppins" iconName="arrowRight" iconSize="smallest">
              {t('registration.Login')}
            </TextButton>
          </CustomRow>
        )}
        <AuthPage.ContentBox>
          <RegistrationForm />
        </AuthPage.ContentBox>
      </AuthPage.Content>
    </AuthPage.Container>
  );
};
