import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SDisclosure = styled.div`
  margin: 32px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${getColor('charcoal')};
`;

export const SCurrentAccount = styled.div`
  padding: 24px;
  background-color: ${getColor('cream70')};
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 32px;
  margin-top: 8px;

  .icon-wrapper {
    margin-right: 12px;
    padding: 0px 3px;
    border-radius: 3px;
    border: 2px solid ${getColor('orange')};
  }
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 8px;
    color: ${getColor('charcoal')};
  }
`;

export const SButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 14px 32px;
    font-size: 18px;
    font-weight: 600;
    height: 52px;
    max-width: 290px;
  }
`;
