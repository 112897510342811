import React from 'react';
import { cantOpenAccount } from 'assets/images';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
import { IModalCommonProps } from 'views/Account/Summary/InternalTransfer/InternalTransferPage.types';
import { PhoneLink } from 'views/Account/AccountInformation/SelectedAccountInformationPage.styles';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';

interface IClosingAccountModal extends IModalCommonProps {
  type: string;
}

export const ClosingAccountModal: React.FC<IClosingAccountModal> = ({ open, onClose, type }) => {
  const { t } = useTranslation('accountInformation');

  return (
    <CustomSheet isOpen={open} onClose={onClose} modalName="internalAccount_closingAccountModal">
      <img src={cantOpenAccount} alt="close-account" height={127} width={119} style={{ alignSelf: 'center', marginBottom: 32 }} />

      <Title size="M" fontWeight="M" marginBottom={16}>
        {t(`accountInformation.Close${type}Account`)}
      </Title>

      <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" font="Poppins" lineHeight={1.5} marginBottom={57}>
        <Trans i18nKey={`accountInformation.ToCloseYour${type}AccountYouWillNeed`} ns="accountInformation" components={{ phone: <PhoneLink href="tel:(888) 302-5055">(888) 302-5055</PhoneLink> }} />
      </BodyText>

      <Button preset="red-filled" size="xl" onClick={onClose}>
        {t('accountInformation.BackToAccountInformation')}
      </Button>
    </CustomSheet>
  );
};
