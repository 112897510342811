import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { BodyText, Title } from 'components/general/Typography';
import { selectCurrentAuthState, selectPolicies } from 'store/user/authentication.slice';
import { ROUTES } from 'vars/const/ROUTES';
import { Loader } from 'components/general/Loader/Loader';
import { EAccountType, IAccountItem } from 'store/user/accounts/accounts.types';
import { accountsApi, useDeleteAccountsByIdMutation, useTransferMoneyMutation } from 'store/user/accounts/accounts.api';
import { useToggle } from 'utils/hooks/useToggle';
import { useNavBack } from 'utils/hooks/useNavBack';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { MakeJointCashAccountModal } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MakeJointCashAccountModal/MakeJointCashAccountModal';
import { Icon } from 'components/general/Icon/Icon';
import { useAccounts } from 'utils/hooks/useAccounts';
import { TextButton } from 'components/general/TextButton/TextButton';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { Switcher } from 'components/general/Switcher/Switcher';
import { useAccountFeatures } from 'utils/hooks/useAccountFeatures';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { ICrumb, PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { DataListItem } from './DataListItem/DataListItem';
import { SLayout, SWrapper } from './SelectedAccountInformationPage.styles';
import { CashCloseAccountInformationModal } from './Modals/closingAccount/CashCloseAccountInformationModal';
import { CashCheckTaxIdSheet } from './CashCheckTaxIdSheet/CashCheckTaxIdSheet';
import { CashFinishCloseAccountInformationModal } from './CashFinishCloseAccountInformationModal/CashFinishCloseAccountInformationModal';
import { SureCloseCashAccount } from './CashCheckCloseAccountInformationModal/SureCloseCashAccount';
import { AccountClosingIssuesModal } from './Modals/closingAccount/GoalsHavingIssuesModal/AccountClosingIssuesModal';
import { UnableToCloseNonCashModal } from './Modals/closingAccount/UnableToCloseNonCashModal';
import { WantToCloseModal } from './Modals/closingAccount/WantToCloseModal';
import { NonCashAccountClosedModal } from './Modals/closingAccount/NonCashAccountClosedModal';
import { ClosingAccountModal } from './Modals/closingAccount/ClosingAccountModal';
import { CashDoubleCheckCloseAccountModal } from './CashDoubleCheckCloseAccountModal/CashDoubleCheckCloseAccountModal';
import { SurveyModal } from './SurveyModal/SurveyModal';
import { FailedDeleteAccount } from './FailedDeleteAccount/FaliedDeleteAccount';
import { EditAccountNameModal } from './Modals/editAccount/EditAccountNameModal/EditAccountNameModal';
import { ServiceItem } from './ServiceItem/ServiceItem';
import { SetAccountDefaultModal } from './Modals/editAccount/SetAccountDefaultModal/SetAccountDefaultModal';
import { ConfirmJointAccountModal } from './Modals/editAccount/ConfirmJointAccountModal/ConfirmJointAccountModal';
import { NotificationsSettingsModal } from './Modals/NotificationsSettingsModal';

export interface IInternalAccountBlock {
  account: IAccountItem;
  showDisclosureSheet: () => void;
  accountsDataLoading?: boolean;
}

export const InternalAccountBlock = ({ account, showDisclosureSheet, accountsDataLoading }: IInternalAccountBlock) => {
  const { navigate, generateUrl } = useEnhancedNav();
  const { setBackUrl } = useNavBack();
  const { t } = useTranslation('accountInformation');
  const location = useLocation();
  const dispatch = useDispatch();
  const { isDesktopSize } = useDeviceDimension();
  const [transferMoneyAPI, transferMoneyAPIStatus] = useTransferMoneyMutation();
  const [deleteAccountById, deleteAccountByIdResult] = useDeleteAccountsByIdMutation();
  const { closingRequested } = accountsApi.useGetAccountsByIdQuery(account.accountId)?.currentData?.account ?? {};
  const ownerJointInvitesQuery = accountsApi.useGetOwnerJointInvitesQuery();
  const cashCloseAccountInformationModal = useToggle(false);
  const cashCheckTaxIdSheet = useToggle(false);
  const cashFinishCloseAccountInformationModal = useToggle(false);
  const sureCloseCashAccount = useToggle(false);
  const cashDoubleCheckCloseAccountModal = useToggle(false);
  const surveyModal = useToggle(false);
  const nonCashClosingIssuesModal = useToggle();
  const unableToCloseModal = useToggle();
  const sureYouWantToCloseModal = useToggle();
  const nonCashAccountClosedModal = useToggle();
  const makeCashAccountModal = useToggle();
  const closingModal = useToggle();
  const failedDeleteAccountModal = useToggle();
  const editAccountNameModal = useToggle();
  const accountDefaultModal = useToggle();
  const confirmJointAccountModal = useToggle();
  const notificationsSettingsModal = useToggle();
  const { internalAccounts } = useAccounts();
  const { overdraftFeature, roundUpFeature, isLoading: featuresLoading } = useAccountFeatures(account.accountId);
  const policies = useSelector(selectPolicies);
  const [isNonCashAccount, setIsNonCashAccount] = useState(false);
  const { systemProperties } = useSelector(selectCurrentAuthState);
  const isOverdraftEligible = overdraftFeature?.overdraft?.isEligible;
  const relatedGoalsAccount = internalAccounts?.find((acc: IAccountItem) => acc.type === EAccountType.SAVE && acc.parentAccountId === account.parentAccountId);
  const relatedNeedsAccount = internalAccounts?.find((acc: IAccountItem) => acc.type === EAccountType.STUFF && acc.parentAccountId === account.parentAccountId);
  const isRoundUpEnabled = roundUpFeature?.isEnabled && (roundUpFeature?.roundUp.accountId === relatedGoalsAccount?.accountId || roundUpFeature?.roundUp.accountId === relatedNeedsAccount?.accountId);
  const isLoading = transferMoneyAPIStatus.isLoading || accountsDataLoading || featuresLoading;
  const defaultAccount = internalAccounts?.find((acc: IAccountItem) => acc.isDefault);
  const defaultAccountName = defaultAccount?.nickname || defaultAccount?.name;

  const relatedCashAccount = internalAccounts?.find((acc) => acc.type === EAccountType.CASH && acc.parentAccountId === account.parentAccountId);
  const cashAccountsCount = internalAccounts.filter((acc) => acc.type === EAccountType.CASH).length;
  const isDisplayCashCushion = overdraftFeature?.overdraft?.isEligible && !overdraftFeature.isEnabled;
  const isNonCashType = !account.isCashType;

  const accountsWithJointInvites = ownerJointInvitesQuery.data?.map((invite) => invite.accountId) ?? [];
  const isInvitePending = accountsWithJointInvites.includes(account.accountId);
  const canAddJointHolder = policies?.JointAccountEnabled && account.isCashType && !account.jointHolderName && account.owner && !isInvitePending;

  const closeBtnText = t('accountInformation.Close Account', { type: t(`accountInformation.SelectedAccountTypes.${account.displayType}`) });

  const handleOnNotNow = () => makeCashAccountModal.hide();
  const handleOnYes = () => navigate(ROUTES.myAccountJointAccounts.path, { state: { isAccountSetup: true } });

  const handleConfirmClosingIssues = () => {
    nonCashClosingIssuesModal.hide();

    if (account.balance > 0 && isNonCashType) {
      unableToCloseModal.show();
    } else {
      sureYouWantToCloseModal.show();
    }
  };

  const handleTransferRemaining = (transferToFiservId: string) => {
    const transferAmount = account.balance;
    const fromAccountId = account.fiservAccountId;
    const transferData = {
      fromAccountId,
      fromAccountType: account.type,
      toAccountId: transferToFiservId,
      amount: transferAmount,
      notes: [''],
      transactionId: uuidv4(),
    };

    transferMoneyAPI(transferData);
    unableToCloseModal.hide();
    sureYouWantToCloseModal.show();
  };

  const handleNonCashAccountModalClose = () => {
    dispatch(accountsApi.util.invalidateTags(['Accounts']));
    nonCashAccountClosedModal.hide();
    navigate(ROUTES.home.path);
  };

  const handleCloseNonCashAccount = async () => {
    setIsNonCashAccount(true);
    sureYouWantToCloseModal.hide();
    await deleteAccountById(account.accountId);
  };

  const handleCancelClosing = () => {
    sureYouWantToCloseModal.hide();
  };

  const handleTaxIdMatch = () => {
    cashCheckTaxIdSheet.hide();
    sureCloseCashAccount.show();
  };

  const handleConfirmCloseCashAccount = () => {
    cashCloseAccountInformationModal.hide();
    if (isDesktopSize) {
      navigate(ROUTES.accountInformationVerify.path, {
        state: {
          accountId: account.accountId,
          hasNeedsAccount: !!relatedNeedsAccount,
          hasGoalsAccount: !!relatedGoalsAccount,
        },
      });
    } else {
      cashCheckTaxIdSheet.show();
    }
  };

  const handleConfirmCashClosingFinally = () => {
    cashCloseAccountInformationModal.hide();
    navigate(ROUTES.home.path);
  };

  const handleOpenSurvey = () => {
    cashFinishCloseAccountInformationModal.hide();
    surveyModal.show();
  };

  const handleSubmitSurvey = () => {
    surveyModal.hide();
    navigate(ROUTES.home.path);
  };

  const handleConfirmCashCloseCheck = () => {
    setIsNonCashAccount(false);
    sureCloseCashAccount.hide();
    if (!!relatedGoalsAccount || !!relatedNeedsAccount) {
      cashDoubleCheckCloseAccountModal.show();
    } else {
      deleteAccountById(account.accountId);
    }
  };

  const handleConfirmCashCloseDoubleCheck = () => {
    cashDoubleCheckCloseAccountModal.hide();
    deleteAccountById(account.accountId);
  };

  const handleCloseAccountClosingModals = () => {
    cashCloseAccountInformationModal.hide();
    nonCashClosingIssuesModal.hide();
  };

  const onClosingAccountClick = () => {
    if (!policies?.AccountClosingEnabled) {
      closingModal.show();
      return;
    }

    if (account.isNeedsType || account.isGoalsType) {
      nonCashClosingIssuesModal.show();
    }

    if (account.isCashType) {
      cashCloseAccountInformationModal.show();
    }
  };

  const handleClickPennyJar = () => {
    setBackUrl(generatePath(ROUTES.selectedAccountInformation.path, { sourceType: 'percapita', accountId: account.accountId }));

    if (isRoundUpEnabled) {
      navigate(ROUTES.accountPennyJar.path, { params: { accountId: account.accountId } });
    } else {
      navigate(ROUTES.pennyJar.path, { searchParams: { accountId: account.accountId } });
      navigate(ROUTES.pennyJar.path, { searchParams: { accountId: account.accountId } });
    }
  };

  const getAccountType = () => {
    switch (true) {
      case account.isCashType:
        return EAccountType.CASH;
      case account.isGoalsType:
        return EAccountType.SAVE;
      case account.isNeedsType:
        return EAccountType.STUFF;
      default:
        return EAccountType.CASH;
    }
  };

  const handleBalanceClick = () => {
    navigate(ROUTES.balancesTransactions.path, { searchParams: { groupId: account?.parentAccountId, accountType: getAccountType() }, backUrl: location.pathname });
  };

  const handleOpenSetAccountDefaultModal = () => {
    if (account.isDefault) return;
    accountDefaultModal.show();
  };

  const handleCloseAccountDefaultModal = () => {
    accountDefaultModal.hide();
  };

  const handleCloseAccount = () => {
    // if (isClosurePending) return;
    onClosingAccountClick();
  };

  const handleNotificationsSettings = () => {
    if (isDesktopSize) {
      navigate(ROUTES.notificationSettings.path, { state: { isFromAccountInfo: true, accountId: account.accountId } });
    } else {
      notificationsSettingsModal.show();
    }
  };

  useEffect(() => {
    if (deleteAccountByIdResult.isError) failedDeleteAccountModal.show();
    if (deleteAccountByIdResult.isSuccess && !isNonCashAccount && !isDesktopSize) cashFinishCloseAccountInformationModal.show();
    if (deleteAccountByIdResult.isSuccess && !isNonCashAccount && isDesktopSize)
      navigate(ROUTES.requesToCloseCashAccount.path, {
        state: {
          hasNeedsAccount: !!relatedNeedsAccount,
          hasGoalsAccount: !!relatedGoalsAccount,
        },
      });
    if (deleteAccountByIdResult.isSuccess && isNonCashAccount) nonCashAccountClosedModal.show();
  }, [deleteAccountByIdResult.isError, deleteAccountByIdResult.isSuccess]);

  const crumbs: ICrumb[] = [
    {
      name: t('accountInformation.Accounts'),
      url: ROUTES.accountInformation.path,
    },
    {
      name: account.name,
    },
  ];

  return (
    <SLayout>
      {(deleteAccountByIdResult.isLoading || isLoading) && <Loader />}
      {isDesktopSize ? (
        <PageHeader title={t('accountInformation.Accounts')} crumbs={crumbs} />
      ) : (
        <CustomRow className="title" justifyContent="space-between" marginBottom={24}>
          <Title font="Poppins" color="charcoal" fontWeight="SB" size="S">
            {account.nickname} {account.shortName}
          </Title>
          <Icon name="edit" color="blue" size="smaller" onClick={editAccountNameModal.show} cursorPointer />
        </CustomRow>
      )}

      <SWrapper>
        {isDesktopSize && (
          <CustomRow justifyContent="space-between">
            <Title font="Poppins" fontWeight="M" size="L">
              {account.nickname} {account.shortName}
            </Title>
            <Icon name="edit" color="blue" size="smaller" onClick={editAccountNameModal.show} cursorPointer />
          </CustomRow>
        )}
        <CustomCard className="internal-wrapper" extraStyles={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} marginBottom={2}>
          <CustomRow>
            <BodyText size="N" color="charcoal70">
              {t('accountInformation.Balance')}
            </BodyText>
            <CustomRow onClick={handleBalanceClick} cursorPointer>
              <CustomAmount className="sum" amount={account.balance} color="charcoal" size="xl" remainingSize="smaller" multiSizable remainingWeight={600} />
              <Icon name="arrowRight" color="blue" size="smaller" cursorPointer marginLeft={8} />
            </CustomRow>
          </CustomRow>
        </CustomCard>

        <CustomCard className="internal-wrapper" marginTop={2} extraStyles={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          {account.isCashType && systemProperties?.routingNumber && (
            <DataListItem title={t('setUpDeposit.accountDetails.RoutingNumber')} value={systemProperties?.routingNumber || ''} tooltip="routingNumber" isRow />
          )}
          <DataListItem
            title={
              account.isCashType ? t('accountInformation.Cash Account Number') : t('accountInformation.AccountNumber', { type: t(`accountInformation.SelectedAccountTypes.${account.displayType}`) })
            }
            value={account.fiservAccountId}
            tooltip="accountNumber"
            isRow
            isLast
          />
        </CustomCard>

        {account.isCashType && (
          <>
            <BodyText textType="bodyText" color="charcoal" fontWeight="SB" size="M" font="Poppins" textAlign="start" marginTop={32} marginBottom={20}>
              {t('accountInformation.Services')}
            </BodyText>

            <div className="services">
              <ServiceItem
                prefix="creditCard3"
                prefixColor="orange"
                prefixSize="small"
                prefixAlignLeft
                title={t('accountInformation.AutomaticPayrollDeposit')}
                subTitle={t('accountInformation.DirectDeposit')}
                clickHandler={() => navigate(ROUTES.setUpDeposit.path, { searchParams: { accountId: account.accountId } })}
              />

              {/* Penny Jar */}
              {policies?.PennyJarEnabled && (!!relatedGoalsAccount || !!relatedNeedsAccount) && (
                <ServiceItem
                  prefix="pennyJar"
                  prefixColor="orange"
                  prefixSize="small"
                  prefixAlignLeft
                  title={t('accountInformation.DebitRoundUpNoFee')}
                  subTitle={isRoundUpEnabled ? t('accountInformation.View Penny Jar') : t('accountInformation.Set Up Penny Jar')}
                  clickHandler={handleClickPennyJar}
                  isRoundUpEnabled={isRoundUpEnabled}
                />
              )}
              {/* Cash Cushion */}
              {policies?.CashCushionEnabled && isOverdraftEligible && (
                <ServiceItem
                  to={generateUrl(ROUTES.accountCashCushion.path, { params: { accountId: account.accountId } })}
                  prefix="cashCushion"
                  prefixColor="orange"
                  prefixSize="small"
                  prefixAlignLeft
                  title={t('accountInformation.OptionToOverdraw')}
                  subTitle={isDisplayCashCushion ? t('accountInformation.Set Up Cash Cushion') : t('accountInformation.Cash Cushion')}
                  isOverdraftEligible={isOverdraftEligible}
                  isActivated={overdraftFeature.isEnabled}
                />
              )}
            </div>

            <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="M" textAlign="start" marginTop={32}>
              {t('accountInformation.AccountSettings')}
            </BodyText>

            <CustomCard extraStyles={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
              {account.isCashType && cashAccountsCount > 1 && (
                <CustomRow marginBottom={16}>
                  <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start">
                    {t('accountInformation.Default Account')}
                  </BodyText>
                  <Switcher onClick={handleOpenSetAccountDefaultModal} checked={account.isDefault} />
                </CustomRow>
              )}
              <CustomRow justifyContent="space-between">
                <CustomRow flexDirection="column" alignItems="flex-start">
                  <BodyText size="N" fontWeight="R" color="charcoal70">
                    {t('accountInformation.Joint Account holder')}
                  </BodyText>
                  <BodyText size="M" fontWeight="M" color="charcoal">
                    {isInvitePending ? t('accountInformation.Pending') : account.jointHolderName || '-'}
                  </BodyText>
                </CustomRow>
                {canAddJointHolder && (
                  <TextButton iconName="circlePlus" fontWeight="M" onClick={confirmJointAccountModal.show}>
                    {t('accountInformation.Add')}
                  </TextButton>
                )}
              </CustomRow>
            </CustomCard>
            <CustomCard extraStyles={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} marginTop={2} cursorPointer onClick={handleNotificationsSettings}>
              <CustomRow>
                <BodyText size="M" color="blue" fontWeight="M" cursorPointer>
                  {t('accountInformation.NotificationAlertSettings')}
                </BodyText>
                <Icon name="arrowRight" color="blue" size="smaller" cursorPointer />
              </CustomRow>
            </CustomCard>
          </>
        )}

        <CustomRow justifyContent="center" marginTop={50} marginBottom={24}>
          <TextButton className="close-account-btn" preIconName="brokenHeart" color="red" size="M" fontWeight="M" onClick={handleCloseAccount}>
            {closingRequested ? t('accountInformation.AccountClosureInProgress') : closeBtnText}
          </TextButton>
        </CustomRow>
      </SWrapper>

      <EditAccountNameModal
        currentName={account.nickname || account.name}
        accountId={account.accountId}
        editAccountNameOpen={editAccountNameModal.isActive}
        isActive={editAccountNameModal.isActive}
        onClose={editAccountNameModal.hide}
        isDesktopSize={isDesktopSize}
      />

      <SetAccountDefaultModal accountId={account.accountId} open={accountDefaultModal.isActive} handleClose={handleCloseAccountDefaultModal} currentDefaultAccountName={defaultAccountName} />
      <ConfirmJointAccountModal open={confirmJointAccountModal.isActive} handleClose={confirmJointAccountModal.hide} accountForJointId={account.accountId} accountName={account.name} />

      {/* Cash */}
      <CashCloseAccountInformationModal isOpen={cashCloseAccountInformationModal.isActive} onClose={handleCloseAccountClosingModals} onClickHandler={handleConfirmCloseCashAccount} />

      <CashCheckTaxIdSheet isOpen={cashCheckTaxIdSheet.isActive} onTaxIdMatch={handleTaxIdMatch} onClose={cashCheckTaxIdSheet.hide} />

      <SureCloseCashAccount isOpen={sureCloseCashAccount.isActive} onClose={sureCloseCashAccount.hide} onClickHandler={handleConfirmCashCloseCheck} />

      <FailedDeleteAccount isOpen={failedDeleteAccountModal.isActive} onClose={failedDeleteAccountModal.hide} />

      <CashFinishCloseAccountInformationModal
        isOpen={cashFinishCloseAccountInformationModal.isActive}
        onClose={handleConfirmCashClosingFinally}
        onClickHandler={handleConfirmCashClosingFinally}
        hasNeedsAccount={!!relatedNeedsAccount}
        hasGoalsAccount={!!relatedGoalsAccount}
        openSurvey={handleOpenSurvey}
      />

      <CashDoubleCheckCloseAccountModal isOpen={cashDoubleCheckCloseAccountModal.isActive} onClose={cashDoubleCheckCloseAccountModal.hide} onClickHandler={handleConfirmCashCloseDoubleCheck} />
      <SurveyModal isOpen={surveyModal.isActive} onClose={surveyModal.hide} onClickHandler={handleSubmitSurvey} />

      {/* Goals or Needs */}
      <AccountClosingIssuesModal open={nonCashClosingIssuesModal.isActive} onClose={handleCloseAccountClosingModals} onConfirm={handleConfirmClosingIssues} accountType={account.type} />
      <UnableToCloseNonCashModal
        open={unableToCloseModal.isActive}
        onClose={unableToCloseModal.hide}
        transferHandler={handleTransferRemaining}
        needsFiservId={relatedNeedsAccount?.fiservAccountId}
        goalsFiservId={relatedGoalsAccount?.fiservAccountId}
        cashFiservId={relatedCashAccount?.fiservAccountId}
        accountType={account.type}
      />
      <WantToCloseModal
        open={sureYouWantToCloseModal.isActive}
        onClose={sureYouWantToCloseModal.hide}
        onCloseMyGoalsAccountClick={handleCloseNonCashAccount}
        onCancel={handleCancelClosing}
        accountType={account.type}
      />
      <NonCashAccountClosedModal open={nonCashAccountClosedModal.isActive} onClose={handleNonCashAccountModalClose} accountType={account.type} />
      <ClosingAccountModal type={account.percapitaType} open={closingModal.isActive} onClose={closingModal.hide} />
      <MakeJointCashAccountModal
        open={makeCashAccountModal.isActive}
        onClose={makeCashAccountModal.hide}
        showDisclosureSheet={showDisclosureSheet}
        handleOnNo={handleOnNotNow}
        handleOnYes={handleOnYes}
      />

      <NotificationsSettingsModal open={notificationsSettingsModal.isActive} onClose={notificationsSettingsModal.hide} />
    </SLayout>
  );
};
