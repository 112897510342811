import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { getPhoneValidator, getRequiredRule } from 'utils/helpers/validationRules';
import { Button } from 'components/theme/Button/Button';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { getFormattedMobileNumber, getLastNNumbers, getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { SModalCommonLayout } from 'views/Profile/ProfilePage/ProfileEditSheet/common/styles';

const PHONE_MASK = '(000) 000 0000';

const PHONE_MASK_OPTIONS = {
  lazy: false,
  placeholderChar: '*',
};
interface IFormValues {
  phoneNumber: string;
}
interface IProps {
  closeSheet?: () => void;
  initVerifyCode?: (newPhone: string) => void;
}

export const EditPhoneNumber = ({ closeSheet, initVerifyCode }: IProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const { checkErrors, hasErrors } = useFormHelpers(form);
  const userProfileData = useSelector(selectCurrentUser);

  const onFinish = (formValues: IFormValues) => {
    initVerifyCode?.(getUnmaskedMobileNumber(`1${formValues.phoneNumber}`));
    closeSheet?.();
  };

  return (
    <SModalCommonLayout className="body-edit-phone-number">
      <Form
        onFinish={onFinish}
        form={form}
        requiredMark={false}
        layout="vertical"
        autoComplete="off"
        onFieldsChange={checkErrors}
        initialValues={{
          phoneNumber: getFormattedMobileNumber(getLastNNumbers(userProfileData?.primaryPhone || '', 10)),
        }}
      >
        <CustomCard padding="0" marginTop={0}>
          <Title fontWeight="SB" size="S" color="charcoal" marginBottom={10} font="Poppins" className="title">
            {t('profile.Phone Number')}
          </Title>

          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={30} lineHeight={1.4} className="description">
            {t('profile.WeSendYouVerificationCodeToVerifyYourPhoneNumber')}
          </BodyText>

          <Form.Item
            label={<CustomRequiredLabel label="profile.PleaseEnterYourPhoneNumber" fontFamily="DM Sans" />}
            name="phoneNumber"
            validateTrigger={['onBlur', 'onChange']}
            rules={[getRequiredRule('profile.Please enter your Phone Number'), getPhoneValidator('inviteEarn.PleaseInputCompleteMobileNumber')]}
            className="phone-number"
          >
            <MaskedInput mask={PHONE_MASK} maskOptions={PHONE_MASK_OPTIONS} placeholder="Enter your mobile number" inputMode="tel" />
          </Form.Item>
        </CustomCard>

        <Form.Item className="form-item-button-submit">
          <Button preset="blue-filled" size="middleStretch" type="submit" disabled={hasErrors}>
            {t('profile.Save Changes')}
          </Button>
        </Form.Item>
      </Form>
    </SModalCommonLayout>
  );
};
