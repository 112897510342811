import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { images } from 'assets';
import { Title } from 'components/general/Typography';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';

interface IOpenErrorModal {
  isOpen: boolean;
  message: string | React.ReactElement;
}

export const OpenErrorModal: FC<IOpenErrorModal> = ({ isOpen, message }) => {
  const { t } = useTranslation('addAccount');
  const navigate = useNavigate();

  const handleOnCancel = () => navigate(ROUTES.home.path);
  return (
    <CustomModal open={isOpen} onCancel={handleOnCancel} modalName="OpenAccountErrorModal" destroyOnClose centered footer={null} closeIconColor="charcoal70" topPosition="10%">
      <CustomRow marginBottom={32} justifyContent="center">
        <img src={images.errorExclamationMarkImage} alt="Open Account Error" />
      </CustomRow>

      <CustomRow flexDirection="column">
        <Title size="M" fontWeight="R" marginBottom="spacing-normal" textAlign="center">
          {message}
        </Title>
      </CustomRow>

      <Button preset="blue-filled" onClick={handleOnCancel}>
        {t('addAccount.Back to Home')}
      </Button>
    </CustomModal>
  );
};
