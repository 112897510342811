import styled, { css } from 'styled-components';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Title } from 'components/general/Typography';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { STwoBgArcTopDottesBottom } from 'styles/common';

export const STitle = styled(Title)``;

export const SBreadcrumbsWrapper = styled.div`
  .breadcrumbs {
    padding-left: 32px;
    margin-bottom: 0;
    ${mediaFrom(
      'mobile',
      css`
        padding-left: 9%;
      `
    )};

    ${mediaFrom(
      'tablet',
      css`
        padding-left: 24px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        padding-left: 10%;
      `
    )}
  }
`;

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;

  .content-container {
    margin-bottom: 40px;
  }

  .button-panel {
    display: flex;
  }

  .disclosure-container {
    padding: 68px 16%;
  }

  ${mediaFrom(
    'tablet',
    css`
      ${STwoBgArcTopDottesBottom};
      background-position: top 165px center, bottom right;
      padding: 0;

      .header {
        margin-bottom: 84px;

        .title-container {
          margin-right: 12px;
        }
        .header-subtitle {
          margin-bottom: 24px;
        }
      }

      .content-container {
        margin: 0 24px 54px 24px;
      }

      .disclosure-container {
        padding: 64px 24px 64px 24px;
      }

      .button-panel {
        bottom: 18%;
        width: 93%;
        max-width: 1180px;
        justify-content: center;
        padding: 24px;
        border-radius: 20px;
        box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(12px);
        background: ${getColor('caoFooterBg')};

        button {
          width: auto;

          &.preset-primary {
            font-size: 18px;
            padding: 14px 40px;
          }
        }
      }
    `
  )};

  ${mediaFrom(
    'desktop',
    css`
      background-position: top -110px center, bottom right;

      .header {
        max-width: 980px;
      }

      .content-container {
        max-width: 980px;
      }

      .disclosure-container {
        padding: 68px 17% 68px 9%;
      }
    `
  )}

  ${mediaFrom(
    'desktopLarge',
    css`
      background-position: top -110% center, bottom right;
    `
  )}
`;

export const SAddNeedsGoalsPage = styled(CustomRow)`
  padding: 12px 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  button.primary {
    padding: 12px 15px;
    white-space: unset;
  }

  .add-needs-goals-page {
    &-header {
      display: flex;
      flex-direction: column;
    }

    &-image {
      margin-bottom: 28px;
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      ${mediaUpTo(
        'mobile',
        css`
          & .title .custom-title-text {
            font-size: 28px;
          }
        `
      )}
    }

    &-footer {
      width: 100%;

      & .btn {
        white-space: normal;
      }
    }
  }

  footer {
    padding-bottom: 48px;
    margin-bottom: 10px;

    div[role='dialog'] {
      padding-right: 0;
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      padding: 14px 20px 0;

      .title .custom-title-text {
        font-size: 32px;
      }
      .account-subtitle .custom-title-text {
        font-size: 20px;
      }
      .add-needs-goals-page-body * {
        font-size: 16px;
        line-height: 24px;
      }
      .add-needs-goals-page {
        &-image {
          margin-bottom: 26px;
        }

        &-body {
          ul.options {
            li {
              padding-left: 28px;

              &::before {
                font-size: 14px;
                top: -2px;
                left: 6px;
              }
            }
          }
        }

        &-footer {
          .btn {
            font-size: 18px;
          }
        }
      }

      footer.page-footer {
        div[role='dialog'] {
          .custom-text-inner {
            line-height: 1.3;
          }
        }
      }
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      padding: 17px 16px 0;

      .title .custom-title-text {
        font-size: 28px;
      }
      .account-subtitle .custom-title-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      .add-needs-goals-page-body * {
        font-size: 12px;
        line-height: 16px;
      }
      .add-needs-goals-page {
        &-image {
          margin-bottom: 37px;
        }

        &-title {
          .account-subtitle {
            .custom-title-text {
              font-size: 16px;
            }
          }
        }

        &-body {
          .description {
            margin-top: 24px;
          }

          .options-title {
            margin-top: 24px;
          }

          ul.options {
            margin-bottom: 24px;

            li {
              padding-left: 20px;

              &::before {
                font-size: 10px;
                top: -4px;
              }
            }
          }
        }

        &-footer {
          .btn {
            font-size: 16px;
            padding: 8px 24px;
          }
        }
      }

      &.goals {
        .add-needs-goals-page {
          &-image {
            img {
              width: 148px;
              height: 130px;
            }
          }
        }
      }

      footer.page-footer {
        padding: 32px 0 28px;

        div[role='dialog'] {
          .custom-text-inner {
            font-size: 11px;
          }
        }
      }
    `
  )}
`;

export const SList = styled.ul`
  margin: 0 0 32px 0;
  padding: 0;
  list-style: none;
`;

export const SListItem = styled.li`
  position: relative;
  padding-left: 24px;
  margin-top: 8px;

  &::before {
    content: '\u2022';
    color: ${getColor('blue')};
    font-weight: 700;
    font-size: 10px;
    display: block;
    position: absolute;
    top: -4px;
    left: 10px;
  }
`;

export const SPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;

  & a.to-home {
    color: ${getColor('blue')};
    font-weight: 500;
  }

  .add-success {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    &-image {
      padding-left: 36px;
      margin-bottom: 32px;
    }
  }
`;
