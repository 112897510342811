import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { SContainer, STextContainer, SOnboardingSlide } from './OboardingSlide.styles';

interface IOnboardingSlideProps {
  img: string;
  title: string;
  description: string | ReactNode;
  subtitle?: string;
  className?: string;
}

export const OnboardingSlide = ({ title, subtitle, description, img, className }: IOnboardingSlideProps) => (
  <SOnboardingSlide className="onboarding-slide">
    <SContainer className={className}>
      <div className="onboarding-slide__image">
        <img src={img} alt={title} />
      </div>
      <STextContainer>
        <div className="onboarding-slide__header">
          <div className="onboarding-slide__title">{title}</div>
          {subtitle && (
            <div className="onboarding-slide__subtitle">
              <Trans
                i18nKey={String(subtitle)}
                components={{
                  Middle: <span className="middle-text" />,
                  Bold: <span className="bold-text" />,
                }}
              />
            </div>
          )}
        </div>
        <div className="onboarding-slide__description">
          <Trans
            i18nKey={String(description)}
            components={{
              Regular: <span className="regular-text" />,
              Bold: <span className="bold-text" />,
            }}
          />
        </div>
      </STextContainer>
    </SContainer>
  </SOnboardingSlide>
);
