import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { Loader } from 'components/general/Loader/Loader';
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Question } from 'components/general/FAQ/Question/Question';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { SFAQContent, SLink, SHelpAndSupportPage } from 'views/HelpAndSupport/HelpAndSupport.styles';
import { useFAQ } from 'utils/hooks/useFAQ';
import { IFAQIntent } from 'views/Wellness/HelpAndSupport/FAQ.types';
import { selectSystemProperties } from 'store/user/authentication.slice';
import { formatPhone } from 'utils/helpers/phone';

export const QuestionSearchPage = () => {
  const { t } = useTranslation('helpSupport');
  const params = useParams();
  const navigate = useNavigate();
  const { allQuestions, isLoading } = useFAQ();
  const [searchResults, setSearchResults] = useState<IFAQIntent[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const selectedQuestion = useMemo(() => allQuestions?.find((item) => item.IntentName === params?.questionName), [params, allQuestions?.length]);
  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target?.value || '');
  };

  const handleIntentClick = (intentName: string) => {
    const questionPath = generatePath(':questionName', {
      questionName: intentName,
    });
    navigate(questionPath);
  };

  useEffect(() => {
    setSearchResults(
      searchStr?.trim()
        ? allQuestions.filter((intent: IFAQIntent) => intent.Questions.some((question) => question.QuestionText.trim().toLocaleLowerCase().includes(searchStr?.trim().toLowerCase())))
        : allQuestions
    );
  }, [searchStr]);

  return (
    <SHelpAndSupportPage>
      {isLoading && <Loader />}
      <SFAQContent>
        {selectedQuestion ? (
          <Question answerText={selectedQuestion?.CorrectAnswerText ?? ''} text={selectedQuestion.Questions[0].QuestionText} intentName={selectedQuestion.IntentName} isAlwaysOpen />
        ) : (
          <>
            <Title font="Poppins" color="charcoal" fontWeight="SB" size="S" textAlign="start">
              {t('helpSupport.SearchResult')}
            </Title>
            <CustomCard marginTop={32}>
              <BaseInput value={searchStr} onChange={handleSearchChange} prefix="search" prefixColor="charcoal" />
            </CustomCard>
            {searchResults?.length > 0 &&
              searchResults.map((item: IFAQIntent) => (
                <Question answerText={item.CorrectAnswerText} key={item.IntentName} text={item.Questions[0].QuestionText} intentName={item.IntentName} onClick={handleIntentClick} />
              ))}
            {searchResults?.length === 0 && (
              <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal" marginTop={32}>
                {t('helpSupport.NoResultPart1')}
                <SLink href={supportTelVal}>{t('helpSupport.here')}</SLink>
                {t('helpSupport.NoResultPart2')}
              </BodyText>
            )}
          </>
        )}
      </SFAQContent>
      <PageFooter />
    </SHelpAndSupportPage>
  );
};
