import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SIconClose, SMaskStyle } from 'components/theme/CustomModal/CustomModal.styles';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { images } from 'assets';
import { CustomTitle } from 'components/theme/CustomTitle/CustomTitle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IResponseModalProps {
  isSuccess: boolean;
  inviteIdStatus?: string;
  navPath: string;
  open: boolean;
  onClose: () => void;
}

const ERROR_STATUS = {
  wrongPhone: 'wrong_phone',
  tooManyAccounts: 'too_many_accounts',
  expired: 'expired',
};

export const ResponseModal: React.FC<IResponseModalProps> = ({ isSuccess, inviteIdStatus = '', navPath, open, onClose }) => {
  const { t } = useTranslation('prepPage');
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const [isFailedStatus, setIsFailedStatus] = useState(false);

  const checkFailedStatus = () => Object.values(ERROR_STATUS).includes(inviteIdStatus);

  useEffect(() => {
    setIsFailedStatus(checkFailedStatus());
  }, [inviteIdStatus]);

  const handleOnCancel = () => {
    onClose();
    navigate(navPath);
  };

  return (
    <CustomModal
      open={open}
      centered
      onCancel={handleOnCancel}
      modalName="acceptInviteResponseModal"
      destroyOnClose
      footer={null}
      maskStyle={SMaskStyle}
      closeIcon={<SIconClose cursorPointer name="closeCircle" />}
      topPosition="10%"
      width="648px"
    >
      <CustomRow marginBottom={32} justifyContent="center">
        {isSuccess ? <img src={images.congratulation} alt="succeed" width={200} /> : <img src={isFailedStatus ? images.sandClock : images.errorExclamationMarkImage} width={122} alt="failureMark" />}
      </CustomRow>

      <CustomTitle size="xl" fontWeight="light" marginBottom="small" textAlign="center" lineHeight={1.2}>
        {inviteIdStatus === ERROR_STATUS.wrongPhone && t('prepPage.ResponseWrongPhone')}
        {inviteIdStatus === ERROR_STATUS.tooManyAccounts && t('prepPage.ResponseTooManyAccounts')}
        {inviteIdStatus === ERROR_STATUS.expired && t('prepPage.ResponseExpired')}
        {isSuccess && !isFailedStatus && t('prepPage.ResponseSuccess')}
        {!isSuccess && !isFailedStatus && t('prepPage.ResponseError')}
      </CustomTitle>

      {isSuccess && (
        <Button preset="blue-filled" onClick={handleOnCancel} width={isDesktopSize ? '50%' : '100%'} size="large" marginLeft="auto" marginRight="auto" marginTop={12}>
          {t('prepPage.GoToAccountHome')}
        </Button>
      )}
    </CustomModal>
  );
};
