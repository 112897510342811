import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SSidebar = styled.div`
  width: 328px;
  min-width: 328px;
  min-height: 100%;
  max-height: 100vh;
  border-right: 1px solid rgba(236, 232, 220, 1);
  padding: 32px 32px 32px 0;
  position: relative;
  display: none;

  .base-input-container {
    border: 2px solid rgba(229, 229, 229, 1) !important;
  }

  .calendar {
    display: none;
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
  }

  ${mediaFrom(
    'desktop',
    css`
      display: block;
      .calendar {
        display: block;
      }
    `
  )};
`;
