import React from 'react';
import { images } from 'assets';
import { useTranslation, Trans } from 'react-i18next';
import { Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useSetUIPreferenceMutation } from 'store/user/properties/userProperties.api';
import { SPromoCloseModal } from './Modals.styles';

export interface IPennyJarCloseModal {
  open: boolean;
  onClose: () => void;
}

export const PennyJarCloseModal: React.FC<IPennyJarCloseModal> = ({ open, onClose }) => {
  const { t } = useTranslation('home');
  const [setUIPreference] = useSetUIPreferenceMutation();

  const handleContinue = () => {
    setUIPreference({
      value: {
        isPennyJarDismissed: true,
      },
    });
    onClose();
  };

  return (
    <SPromoCloseModal open={open} onCancel={onClose} topPosition="11%" closeIconColor="charcoal70">
      <div className="content">
        <img src={images.pennyJarMainLogo} alt="pennyJarMain" />
        <Title textAlign="start" marginBottom={16} fontWeight="M" size="M" marginTop={10}>
          {t('pennyJarPromo.CloseModalTitle')}
        </Title>
        <div>
          <Trans i18nKey="pennyJarPromo.CloseModalText" ns="home" components={{ strong: <strong /> }} />
        </div>
        <Button size="large" onClick={handleContinue} marginTop={32}>
          {t('pennyJarPromo.CloseModalButtonText')}
        </Button>
      </div>
    </SPromoCloseModal>
  );
};
