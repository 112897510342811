import styled from 'styled-components';
import { flex, getColor } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div<{ bodyHeight: number }>`
  ${flex('column', 'flex-start', 'stretch')};
  background: ${getColor('white')};
  border-radius: 20px;
  margin-bottom: 16px;

  .card-header {
    ${flex('row')};
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid ${getColor('transparent')};
    cursor: pointer;
  }

  .card-body-wrapper {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;

    .card-body {
      padding: 24px;
    }
  }

  &.expanded {
    .card-header {
      border-color: ${getColor('charcoal10')};
    }

    .card-body-wrapper {
      height: ${({ bodyHeight }) => `${bodyHeight}px`};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
