import { PageFooter } from './PageFooter/PageFooter';
import { PageHeader } from './DesktopPageHeader/PageHeader';
import { SPageContainer, SPageContent } from './Page.styles';

export const Page = {
  Footer: PageFooter,
  Header: PageHeader,
  Container: SPageContainer,
  Content: SPageContent,
};
