import React, { FC, useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'views/Chat/components/Message/Message';
import { selectUserId } from 'store/user/authentication.slice';
import { chatActions, selectChatState } from 'store/chat/chat.slice';
import { useSendMessageMutation } from 'store/chat/chat.api';
import { mobileCallUrlRedirect } from 'services/mobileService';
import { IMessage } from 'store/chat/chat.types';
import { SMessagesList } from './MessagesList.styled';

export const MessagesList: FC<{ messages?: any }> = ({ messages }) => {
  const { selectedQuestion, token, activeConversation, otpSeed } = useSelector(selectChatState) || {};
  const userId = useSelector(selectUserId);
  const [isActionButtonClicked, setIsActionButtonClicked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { setMessageAsActionResponse } = chatActions;
  const [sendMessage] = useSendMessageMutation();
  const messagesListRef = useRef<HTMLDivElement>(null);

  const processMessageAction = useCallback(
    (type: string, value: string) => {
      if (type === 'openUrl' || type === 'accountRedirectCard') {
        mobileCallUrlRedirect(value);
        window.open(value, '_blank')?.focus();
        return;
      }

      const messageEntity = {
        id: activeConversation?.conversationId,
        fromUserId: userId,
        text: value,
        authToken: token,
        otpToken: otpSeed,
        isActionResponse: true,
      };

      dispatch(setMessageAsActionResponse());
      sendMessage(messageEntity);
    },
    [userId, token, otpSeed, activeConversation, dispatch, setMessageAsActionResponse, sendMessage]
  );

  const handleActionClick = useCallback(
    (type: string, text: string, value: string) => {
      if (!userId || !activeConversation) return;

      setIsActionButtonClicked((prev) => !prev);
      processMessageAction(type, value);
    },
    [userId, activeConversation, processMessageAction]
  );

  useEffect(() => {
    if (messagesListRef.current && messages?.[messages.length - 1]?.type !== 'SYSTEM') {
      const scrollHeight = messagesListRef.current?.scrollHeight;
      messagesListRef.current?.scroll({ top: scrollHeight });
    }
  }, [messages]);

  return (
    <SMessagesList ref={messagesListRef} id="chatBody">
      {messages &&
        messages
          .filter((msg1: IMessage, i: number, a: any) => a.findIndex((msg2: IMessage) => msg2.id === msg1.id) === i)
          .filter((msg: any) => msg.text?.length || msg.suggestedActions?.actions?.length)
          .map((msg: any, index: number) => (
            <Message
              key={msg.timestamp}
              isActionButtonClicked={isActionButtonClicked}
              intentId={selectedQuestion?.intentId || ''}
              isActionResponse={msg.isActionResponse}
              {...msg}
              actionClickHandler={handleActionClick}
              isIntro={index === 0}
              isFromUser={msg.from.id === userId}
              suggestedActions={msg.suggestedActions}
            />
          ))}
    </SMessagesList>
  );
};
