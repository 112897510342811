import React, { useMemo } from 'react';
import { images } from 'assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { format } from 'date-fns';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { IThirdParty } from 'store/user/accounts/accounts.types';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { Title } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { getAccountDisplayNumber, getAccountDisplayName } from 'utils/helpers/accounts/accountsHelper';
import { TransferDataRow } from './TransferDataRow/TransferDataRow';
import { SImg } from './ExternalTransferSuccessSheet.styles';

interface IExternalTransferSuccessSheetProps {
  amountTransferred: number;
  fromAccount: IThirdParty;
  toAccount: IThirdParty;
  isOpen: boolean;
  handleClose: () => void;
}

export const ExternalTransferSuccessSheet = ({ isOpen, handleClose, fromAccount, toAccount, amountTransferred }: IExternalTransferSuccessSheetProps) => {
  const { t } = useTranslation('moveMoney');
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();

  const handleOnContinue = () => {
    handleClose();
    navigate(ROUTES.home.path);
  };

  const dateStr = useMemo(() => format(new Date(), 'MMM dd, yyyy'), [isOpen]);

  return (
    <CustomSheet
      isModal={isDesktopSize}
      isOpen={isOpen}
      onClose={handleClose}
      header={false}
      closable={false}
      wrapperPadding={isDesktopSize}
      contentWrapperStyle={{ borderRadius: '24px 24px 0px 0px' }}
      modalName="transfer_externalTransferSuccessSheet"
    >
      <>
        <CustomRow marginBottom={32} alignItems="center" justifyContent="center">
          <SImg src={images.congratulation} alt="Transfer Success!" />
        </CustomRow>

        <Title justifyContent="start" fontWeight="SB" size="S" marginBottom={37}>
          {t('externalTransfer.TransferComplete')}
        </Title>

        <TransferDataRow title={t('externalTransfer.From')} value={getAccountDisplayName(fromAccount)} subvalue={getAccountDisplayNumber(fromAccount)} />
        <TransferDataRow title={t('externalTransfer.To')} value={getAccountDisplayName(toAccount)} subvalue={getAccountDisplayNumber(toAccount)} />
        <TransferDataRow title={t('externalTransfer.AmountTransferred')} value={amountTransferred} isAmount />
        <TransferDataRow title={t('externalTransfer.Date')} value={dateStr} />
        <TransferDataRow title={t('externalTransfer.FundsAvailability')} value={t(fromAccount?.thirdPartyAccountType === 'CA' ? 'externalTransfer.WithinMins' : 'transferPage.In7BusinessDays')} />

        <Button preset="blue-filled" onClick={handleOnContinue} marginTop={10} marginBottom={32}>
          {t('externalTransfer.Done')}
        </Button>

        <SuttonDisclaimerNote />
      </>
    </CustomSheet>
  );
};
