import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BodyText, Title } from 'components/general/Typography';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Icon } from 'components/general/Icon/Icon';
import { TextButton } from 'components/general/TextButton/TextButton';
import { IThirdParty } from 'store/user/accounts/accounts.types';
import { SInfoBlock, SCustomRow, SLayout, SWrapper } from './SelectedAccountInformationPage.styles';
import { DataListItem } from './DataListItem/DataListItem';
import { EditAccountModal } from './Modals/editAccount/EditAccountModal/EditAccountModal';

export const ExternalAccountInfoBlock = ({ account, accountsDataLoading }: { account: IThirdParty; accountsDataLoading?: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize } = useDeviceDimension();
  const editModal = useToggle();
  const isCardType = account.thirdPartyAccountType === 'CA';
  const accountName = account.externalDisplayAccountName?.trim();

  return (
    <SLayout>
      {isDesktopSize ? (
        <SCustomRow justifyContent="flex-start">
          <Icon name="arrowLeft" color="blue" onClick={() => navigate(-1)} marginLeft={34} marginRight={4} cursorPointer />
          <Title paddingLeft={6}>{t('accountInformation.ExternalAccount')}</Title>
        </SCustomRow>
      ) : (
        <Title font="Poppins" color="charcoal" fontWeight="SB" size="S" marginBottom={25}>
          {t('accountInformation.ExternalAccount')}
        </Title>
      )}

      <SWrapper>
        <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="M" marginBottom={20} textAlign="start" marginTop={isDesktopSize ? 48 : 0}>
          {t('accountInformation.AccountInfo')}
        </BodyText>

        <SInfoBlock marginBottom={32}>
          <DataListItem title={t('accountInformation.AccountName')} value={accountName} showCopyButton={false} />

          {account.thirdPartyAccountType && (
            <DataListItem
              title={t('accountInformation.ExternalAccountType')}
              value={isCardType ? t('accountInformation.DebitCard') : t('accountInformation.BankAccount')}
              iconName={isCardType ? 'creditCard' : 'bank'}
              showCopyButton={false}
            />
          )}

          {account.externalDisplayAccountNumber && (
            <DataListItem
              title={isCardType ? t('accountInformation.CardNumber') : t('accountInformation.Account Number')}
              value={`**** **** **** ${account.externalDisplayAccountNumber}`}
              showCopyButton={false}
            />
          )}
          <div className="bottom-container">
            <TextButton onClick={editModal.show} iconName="edit" size="M" iconSize="smaller" font="Poppins" fontWeight="M">
              {t('accountInformation.EditExternalAccount')}
            </TextButton>
          </div>
        </SInfoBlock>
      </SWrapper>
      <EditAccountModal
        isLoading={accountsDataLoading}
        account={account}
        onOpen={editModal.show}
        isOpen={editModal.isActive}
        onAccountClose={editModal.hide}
        onClose={editModal.hide}
        defaultAccountName={accountName}
      />
    </SLayout>
  );
};
