import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${mediaFrom(
    'tablet',
    css`
      align-items: center;
      padding: 0 20px 40px 20px;
      min-height: 95%;
    `
  )}

  .ant-form {
    width: 100%;
  }

  .state-and-zip-code-field {
    display: flex;
    justify-content: space-between;
  }

  .edit-title-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    margin: 48px 0 32px 0;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        max-width: 580px;
      `
    )}
  }

  .mobile-title-container {
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  .web-title-container {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 16px;
    margin-top: 48px;
    width: 100%;
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }

  .web-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
  }
`;
