import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { images } from 'assets';

export const SReferPageWrapper = styled.div`
  padding-bottom: 48px;

  ${mediaFrom(
    'desktop',
    css`
      background: url(${images.referBottomBackground}) no-repeat bottom right / 248px 196px;
    `
  )}


  .image-container {
    ${mediaFrom(
      'desktop',
      css`
        background: url(${images.referHeaderBackground}) no-repeat bottom / cover;
      `
    )}

    .title-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;

      ${mediaFrom(
        'desktop',
        css`
          display: flex;
          flex-direction: row;
          padding: 104px 148px 144px 148px;

          img {
            margin-bottom: 16px;
          }
        `
      )}

      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;

        ${mediaFrom(
          'desktop',
          css`
            margin-right: 98px;
          `
        )}
      }
    }
  }

  .content-container {
    width: 100%;

    ${mediaFrom(
      'desktop',
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title {
          width: 580px;
        }
      `
    )}

    .steps-card {
      background: ${getColor('creamSS2')};
      margin-bottom: 32px;
      padding: 24px;
      border-radius: 16px;

      ${mediaFrom(
        'desktop',
        css`
          max-width: 580px;
          background: transparent;
          padding: 0;
        `
      )}

      .steps {
        padding: 0;
        margin: 0;
        gap: 24px;
        display: flex;
        flex-direction: column;

        &-item {
          display: flex;
          align-items: center;
          gap: 16px;

          ${mediaFrom(
            'desktop',
            css`
              background: ${getColor('cream')};
              border-radius: 24px;
              padding: 28px 24px;
            `
          )}

          &-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: ${getColor('grey5')};
          }

          &-circle {
            width: 32px;
            height: 32px;
            font-weight: 700;
            background: ${getColor('blue')};
            color: white;
            font-size: 12px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            border-radius: 16px;
            justify-content: center;
          }
        }
      }
    }

    
  .disclosure-container {
    width: 100%;

    ${mediaFrom(
      'desktop',
      css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 87px;
        width: 80%;
      `
    )}
  }

  .language-selection {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const SLink = styled.a`
  color: ${getColor('blue')};
  text-decoration: underline;

  &:hover {
    color: ${getColor('blue')};
  }
`;

export const SDetailsFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .send-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      font-weight: 500;
      height: 56px;
      width: 100%;
    }
  }

  ${mediaFrom(
    'desktop',
    css`
      max-width: 580px;

      .send-btn-container button {
        min-width: 166px;
        max-width: 190px;
        height: 52px;
        font-size: 18px;
        font-weight: 600;
      }
    `
  )}
`;
