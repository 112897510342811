import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { ROUTES } from 'vars/const/ROUTES';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { IVerifyEmailForm, IMyInfoEditFromVerify } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { selectCurrentUser, selectIsEmailVerifiedOrAbsent } from 'store/user/authentication.slice';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { urlString } from 'utils/helpers/urlString/urlString';
import { MyInfoVerifyEmailForm } from './EmailForm/EmailForm';
import { EditEmailPage } from './EditEmailPage/EditEmailPage';
import { SLayout } from './EmailPage.styles';

export const EmailPage = () => {
  const navigate = useNavigate();
  const { generateCodeEmail, generateCodeEmailStatus, saveOnboardingData, openingAccountData, getOnboardingDataIsLoading } = useCashAccountOpening();
  const userData = useSelector(selectCurrentUser);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isContinueButton, setIsContinueButton] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation(['cashAccountOpening', 'percapitaPay', 'header']);
  const location = useLocation() as IMyInfoEditFromVerify;
  const isEmailVerifiedOrAbsent = useSelector(selectIsEmailVerifiedOrAbsent);
  const isEmailVerified = isEmailVerifiedOrAbsent && userData?.email;
  const isEditing = !!location?.state?.isEditing;
  const initialEmail = userData?.email || '';
  const initialValues = {
    email: openingAccountData?.email || initialEmail,
  };
  const verifySmsPath = urlString({ url: ROUTES.myInfoVerifySms.path, params: { isEditing: isEditing.toString() } });

  const handleSubmit = ({ email }: IVerifyEmailForm) => {
    if (email && (isEmailChanged || !isEmailVerified)) {
      generateCodeEmail?.(email);
      saveOnboardingData({ email });
      return;
    }

    if (isEditing && !isEmailChanged) {
      navigate(ROUTES.myInfoSummary.path);
      return;
    }

    saveOnboardingData({ currentUrl: ROUTES.myInfoVerifySms.path });
    navigate(verifySmsPath, { state: { isEditing, validateError: false } });
  };

  useEffect(() => {
    if (generateCodeEmailStatus?.isSuccess) {
      navigate(verifySmsPath, {
        state: {
          isEditing,
          editingModeHeaderTitle: isEditing ? t('header.Back Email Editing', { ns: 'header' }) : '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateCodeEmailStatus?.isSuccess]);

  useEffect(() => {
    setIsContinueButton(Boolean(!isEmailChanged && isEmailVerified));
  }, [isEmailChanged, isEmailVerified]);

  if (isEditing) {
    return (
      <EditEmailPage
        isDisabled={isSubmitDisabled || generateCodeEmailStatus?.isLoading}
        getOnboardingDataIsLoading={getOnboardingDataIsLoading}
        handleSubmit={handleSubmit}
        setIsSubmitDisabled={setIsSubmitDisabled}
        email={openingAccountData?.email || initialEmail}
        generateCodeEmailStatus={generateCodeEmailStatus}
        setIsEmailChanged={setIsEmailChanged}
      />
    );
  }

  return (
    <>
      <Header title={t('step.WhatsYourEmail')} stage="Email" webStage="Email" />
      <SLayout>
        <div className="content-container">
          <div className="web-title-container">
            <Title marginBottom={8}>{t('step.WhatsYourEmail')}</Title>
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={32} lineHeight="20px">
              {t('myInfo.EmailPageDescription')}
            </BodyText>
          </div>
          <div className="mobile-title-container">
            <BodyText textType="bodyText" color="charcoal60" size="N" fontWeight="R" marginBottom={32} lineHeight="20px" paddingTop={18}>
              {t('myInfo.EmailPageDescription')}
            </BodyText>
          </div>
          {getOnboardingDataIsLoading ? (
            <Loader />
          ) : (
            <div className="email-container">
              <MyInfoVerifyEmailForm setIsDisabled={setIsSubmitDisabled} form={form} handleSubmit={handleSubmit} initialValues={initialValues} setIsEmailChanged={setIsEmailChanged} />
            </div>
          )}
          {generateCodeEmailStatus?.isError && (
            <BodyText textType="bodyText" marginTop={30} color="red" size="N" fontWeight="R">
              {generateCodeEmailStatus?.errorMessage}
            </BodyText>
          )}
        </div>

        <Footer isDisabled={isSubmitDisabled || generateCodeEmailStatus?.isLoading} onClick={form.submit} marginBottom={30} />

        <div className="flex-column mobile-footer">
          <Button preset={isSubmitDisabled ? 'red-filled' : 'blue-filled'} onClick={form.submit} disabled={isSubmitDisabled || generateCodeEmailStatus?.isLoading} marginBottom={24}>
            {isContinueButton ? t('percapitaPayCode.Continue', { ns: 'percapitaPay' }) : t('percapitaPayEmailVerification.SendVerificationEmail', { ns: 'percapitaPay' })}
          </Button>

          <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" marginBottom={16} className="next-step" justifyContent="end">
            {isContinueButton ? `${t('accountOpening.NextStep')} ${t('myInfo.Age')}` : `${t('accountOpening.NextStep')} ${t('accountOpening.Verification')}`}
          </BodyText>
        </div>
      </SLayout>
    </>
  );
};
