import React, { useCallback } from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { CountryFlag } from 'views/MoveMoney/InternationalTransferPage/CountryFlag/CountryFlag';
import { ICountry } from 'views/MoveMoney/InternationalTransferPage/types';
import { SCountryItem } from './CountriesList.styles';

interface ICountryItemProps {
  country: ICountry;
  onSelect: (country: ICountry) => void;
  isSelected?: boolean;
  isOnlyMainLanguage?: boolean;
}

export const CountryItem = React.memo(({ country, onSelect, isSelected, isOnlyMainLanguage }: ICountryItemProps) => {
  const { t } = useTranslation('moveMoney');

  const handleSelect = useCallback(() => {
    onSelect?.(country);
  }, [country]);

  return (
    <SCountryItem className="country-item" key={country.code} onClick={handleSelect}>
      <CountryFlag flagCode={country.code} title={country.title} marginRight={16} />

      <BodyText size="M" color="charcoal" fontWeight={isSelected ? 'B' : 'R'}>
        {isOnlyMainLanguage ? country.title : t(`countries.${country.title}`)}
      </BodyText>

      {isSelected && <Icon name="circleTick" color="blue" className="icon-selected" />}
    </SCountryItem>
  );
});
