import styled, { css } from 'styled-components';
import { TProductStyleProps } from './ProductDisclosure.types';

export const Layout = styled.div<TProductStyleProps>`
  display: flex;
  flex-direction: column;
  margin: 18px 0 26px 0;

  ${({ $styles = {}, $extraStyles = {} }) => css({ ...$styles, ...$extraStyles })};
`;
