import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SMenuIcon, SMenuItem } from './MenuItem.styles';

export interface IMenuItem {
  title: string;
  description?: string;
  icon: string;
  arrowIcon?: string | null;
  path?: string;
  action?: string;
  isProfileSettings?: boolean;
  showCardHub?: () => void;
  openChat?: () => void;
  className?: string;
}

export const MenuItem = ({ title, action, description, icon, path, arrowIcon = 'chevronRight', isProfileSettings = false, showCardHub, openChat, className = '' }: IMenuItem) => {
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();

  const handleOnClick = () => {
    if (action === 'cardhub') {
      showCardHub?.();
      return;
    }

    if (action === 'open_chat') {
      openChat?.();
      return;
    }

    if (path) {
      navigate(`/${path}`, { state: { isFromMenu: true } });
    }
  };

  return (
    <SMenuItem onClick={handleOnClick} className={clsx('menu-item', className, { 'bottom-margin': isProfileSettings })}>
      <CustomRow justifyContent="flex-start">
        <SMenuIcon name={icon} color="charcoal70" cursorPointer />
        <div>
          <BodyText className="title" textType="bodyText" fontWeight="R" size="M" color="charcoal" marginLeft={isDesktopSize ? 16 : 12} cursorPointer>
            {title}
          </BodyText>
          {description && (
            <BodyText className="description" textType="bodyText" fontWeight="R" size="T" color="charcoal70" marginLeft={12} marginTop={8} cursorPointer>
              {description}
            </BodyText>
          )}
        </div>
      </CustomRow>
      {arrowIcon && !isDesktopSize && <Icon name={arrowIcon} color="blue" size="smallest" cursorPointer />}
    </SMenuItem>
  );
};
