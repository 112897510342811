import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { SDottesBottomRight } from 'styles/common';

export const DoMorePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-bottom: 32px;

  .mobile-page-header {
    margin-bottom: 24px;

    .page-header {
      .custom-title-text {
        font-size: 20px;
      }
    }
  }

  .main-content {
    margin: 0 auto;
    max-width: 980px;

    .group-title {
      margin-bottom: 16px;

      .custom-title-text {
        font-size: 12px;
      }
    }

    .options {
      display: flex;
      flex-direction: column;

      .do-more-option {
        margin-bottom: 16px;
      }
    }
  }

  .page-footer {
    padding: 32px 0 0;

    .body-text {
      padding: 0;

      .custom-text-inner {
        font-size: 11px;
        line-height: 1.3;
      }
    }
  }

  // 321-576px
  ${mediaFrom(
    321,
    css`
      padding: 16px 4px 65px 4px;

      .mobile-page-header {
        margin-bottom: 32px;

        .page-header {
          .custom-title-text {
            font-size: 28px;
          }
        }
      }

      .desktop-page-header-wrapper {
        padding: 0;
        margin-bottom: 32px;

        .desktop-page-header {
          padding: 0;

          &__inner {
            padding: 0;
          }

          &__title-wrapper {
            margin: 0;
          }

          &__title {
            .custom-title-text {
              font-size: 28px;
            }
          }
        }

        .divider {
          display: none;
        }
      }

      .main-content {
        .group-title {
          margin-bottom: 20px;

          .custom-title-text {
            font-size: 16px;
          }
        }

        .options {
          .do-more-option {
            margin-bottom: 20px;
          }
        }
      }

      .page-footer {
        padding-top: 48px;

        .body-text {
          .custom-text-inner {
            font-size: 12px;
          }
        }
      }
    `
  )}

  // 577 - 1199px
  ${mediaFrom(
    577,
    css`
      padding: 64px 24px 52px;

      .do-more-page__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .desktop-page-header-wrapper {
          max-width: 452px;
          width: 100%;
          margin-bottom: 32px;

          .divider {
            display: none;
          }

          .desktop-page-header {
            border: none;
            padding: 0;

            &__inner {
              padding: 0;
            }

            &__title-wrapper {
              margin: 0;
            }

            &__title {
              .custom-title-text {
                font-size: 28px;
              }
            }

            .custom-title-text {
              font-size: 28px;
            }
          }
        }

        .main-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .group-title,
          .options {
            max-width: 452px;
            width: 100%;
          }

          .group-title {
            margin-bottom: 20px;

            .custom-title-text {
              font-size: 16px;
            }
          }

          .options {
            .do-more-option {
              margin-bottom: 20px;
            }
          }
        }
      }

      .page-footer {
        padding-top: 42px;

        .body-text {
          .custom-text-inner {
            font-size: 12px;
          }
        }
      }
    `
  )};

  // 1200 - 1440px
  ${mediaFrom(
    'desktop',
    css`
      padding: 30px 0 78px;
      ${SDottesBottomRight};

      .do-more-page__content {
        .desktop-page-header,
        .main-content {
          max-width: 100%;
          width: 100%;
          padding: 0 48px !important;
        }

        .desktop-page-header-wrapper {
          max-width: 100%;
          margin-bottom: 0;

          .desktop-page-header {
            border-bottom: 1px solid ${getColor('creamS5')};
            padding-bottom: 58px !important;
            margin-bottom: 40px !important;

            .title {
              .custom-title-text {
                font-size: 24px;
              }
            }
          }
        }

        .main-content {
          align-items: center;

          .group-title,
          .options {
            max-width: 980px;
            width: 100%;
          }

          .group-title {
            margin-bottom: 24px;

            .custom-title-text {
              font-size: 20px;
            }
          }

          .options {
            flex-direction: row;
            flex-wrap: wrap;

            .do-more-option {
              margin: 0 24px 24px 0;
            }
          }
        }
      }

      .page-footer {
        padding: 76px 248px 0 48px;
      }
    `
  )}

  // Above 1440px
  ${mediaFrom(
    1441,
    css`
      padding-bottom: 84px;

      .do-more-page__content {
        .desktop-page-header {
          padding-left: 128px !important;
          margin-bottom: 90px;
        }

        .main-content {
          .group-title,
          .options {
            max-width: 992px;
          }
        }
      }

      .page-footer {
        padding: 74px 248px 0 128px;
      }
    `
  )}
`;
