import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/helpers/styleHelpers';

export const Wrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;

  .unlock-btn {
    width: auto;
    margin: auto;

    @media screen and (max-width: ${MEDIA_SIZE.tablet}px) {
      width: 100%;
    }
  }
`;
