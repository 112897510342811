import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

interface ISOutlinedCard {
  cursorPointer?: boolean;
}

export const SCard = styled.div<ISOutlinedCard>`
  margin: 0 0 16px 0;
  padding: 24px;
  border: 2px solid ${getColor('charcoal5')};
  border-radius: 0 0 20px 20px;
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const SCardHeader = styled.div`
  border-radius: 24px 24px 0 0;
  border: 2px solid ${getColor('charcoal5')};
  border-bottom: none;
  padding: 24px;
  width: 100%;
`;

export const SCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .checkbox-custom {
    height: 24px;
  }
`;
