import React, { useMemo, useState } from 'react';
import i18next from 'i18next';
import { FormInstance } from 'antd';
import { ValidationHelp } from 'components/general/ValidationHelp/ValidationHelp';
import { passwordFormStateHelper } from './passwordFormStateHelper';

export const useFormValidationHelper = (form: FormInstance, firstPasswordField: string, retypePasswordField?: string, ignorePasswordMatch = true) => {
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [passwordMatchError, setPasswordMatchError] = useState<string[]>([]);

  const { getPasswordSuccessState, getPasswordErrorState, getPasswordMatchError, getPasswordMatchSuccess } = passwordFormStateHelper(form);

  const handleFormChange = () => {
    const errors = form.getFieldError(firstPasswordField);
    setPasswordErrors(errors);

    if (!ignorePasswordMatch) {
      const matchError = form.getFieldError(String(retypePasswordField));
      setPasswordMatchError(matchError);
    }
  };

  const isDisabled = useMemo(() => {
    const hasErrors = !!passwordMatchError?.length || !!passwordErrors?.length;
    let isDisabledAdditionally = false;

    if (!ignorePasswordMatch) {
      isDisabledAdditionally = !form.getFieldValue(String(retypePasswordField));
    }

    return hasErrors || isDisabledAdditionally;
  }, [passwordErrors?.length, passwordMatchError?.length]);

  const formValidationHelpStates: React.ReactNode = (
    <div className="validation-help">
      <ValidationHelp
        message={i18next.t('preRegOnboarding.Minimum 8 characters', { ns: 'preRegOnboarding' })}
        isError={getPasswordErrorState('Minimum 8 characters', passwordErrors, firstPasswordField)}
        isSuccess={getPasswordSuccessState('Minimum 8 characters', 'Please input your password', passwordErrors, firstPasswordField)}
      />
      <ValidationHelp
        message={i18next.t('preRegOnboarding.Maximum 60 characters', { ns: 'preRegOnboarding' })}
        isError={getPasswordErrorState('Maximum 60 characters', passwordErrors, firstPasswordField)}
        isSuccess={getPasswordSuccessState('Maximum 60 characters', 'Please input your password', passwordErrors, firstPasswordField)}
      />
      <ValidationHelp
        message={i18next.t('preRegOnboarding.At least 1 capital letter (A-Z)', { ns: 'preRegOnboarding' })}
        isError={getPasswordErrorState('At least 1 capital letter (A-Z)', passwordErrors, firstPasswordField)}
        isSuccess={getPasswordSuccessState('At least 1 capital letter (A-Z)', 'Please input your password', passwordErrors, firstPasswordField)}
      />
      <ValidationHelp
        message={i18next.t('preRegOnboarding.At least 1 number (0-9)', { ns: 'preRegOnboarding' })}
        isError={getPasswordErrorState('At least 1 number (0-9)', passwordErrors, firstPasswordField)}
        isSuccess={getPasswordSuccessState('At least 1 number (0-9)', 'Please input your password', passwordErrors, firstPasswordField)}
      />
      <ValidationHelp
        message={i18next.t('changePassword.AtLeastOneSpecialCharacter', { ns: 'changePassword' })}
        isError={getPasswordErrorState('At least 1 special character (!$%@&#?+-_)', passwordErrors, firstPasswordField)}
        isSuccess={getPasswordSuccessState('At least 1 special character (!$%@&#?+-_)', 'Please input your password', passwordErrors, firstPasswordField)}
      />
      {!ignorePasswordMatch && (
        <ValidationHelp
          message={i18next.t('changePassword.PasswordsMustMatch', { ns: 'changePassword' })}
          isError={getPasswordMatchError(firstPasswordField, String(retypePasswordField), passwordMatchError)}
          isSuccess={getPasswordMatchSuccess(firstPasswordField, String(retypePasswordField), passwordMatchError)}
        />
      )}
    </div>
  );
  return { isDisabled, passwordErrors, passwordMatchError, formValidationHelpStates, handleFormChange };
};
