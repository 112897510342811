import React from 'react';
import { useGetMenuDataQuery } from 'store/api';
import { selectMainMenuData } from 'store/ui.slice';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { IMenuItemData } from 'vars/types/menu.types';
import { useSelector } from 'react-redux';
import { Loader } from 'components/general/Loader/Loader';
import { CardHubModal } from 'components/general/Modals/CardHubModal/CardHubModal';
import { useCardHub } from 'utils/hooks/useCardHub';
import { useChat } from 'utils/hooks/useChat';
import { MenuItem } from './MenuItem/MenuItem';
import { MenuGroup } from './MenuItem/MenuGroup';
import { SLayout } from './MainMenu.styles';

export const MainMenu = () => {
  const { open } = useChat();
  const cardHub = useCardHub();
  const getMenuDataQuery = useGetMenuDataQuery();
  const menuData = useSelector(selectMainMenuData);
  const isLoading = getMenuDataQuery.isLoading || getMenuDataQuery.isFetching;

  return (
    <SLayout>
      {isLoading && <Loader />}
      <div className="menu-content-wrapper">
        {menuData.map((menuItem: IMenuItemData) =>
          menuItem.children && menuItem.children.length ? (
            <MenuGroup title={menuItem.text} items={menuItem.children} key={menuItem.index} showCardHub={cardHub.open} openChat={open} />
          ) : (
            <MenuItem className="menu-item" title={menuItem.text} icon={menuItem.icon} path={menuItem.path} key={menuItem.index} action={menuItem.action} showCardHub={cardHub.open} openChat={open} />
          )
        )}
      </div>

      <div className="disclaimer-note-wrapper">
        <SuttonDisclaimerNote />
      </div>
      <CardHubModal open={cardHub.isActive} onClose={cardHub.close} />
    </SLayout>
  );
};
