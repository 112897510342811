import { useCallback } from 'react';
import startApril, { SDKEnvironmentType } from '@april/web-sdk';

export const useAprilInit = () => {
  const initializeWidget = useCallback((token: string) => {
    console.log('Trying to initialize april widget');
    const applicationContext = {
      applicationContext: {
        authToken: token,
        sdkEnvironment: SDKEnvironmentType.SANDBOX,
      },
      renderInElement: '#april',
    };

    startApril(
      applicationContext,
      () => {
        console.log('User exited april');
      },
      (error: string) => {
        console.error(`Error: ${error}`);
      }
    );
  }, []);

  return initializeWidget;
};
