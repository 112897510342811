import DotPatternBackground from 'assets/images/dotPatternBackground.svg';
import styled, { css } from 'styled-components';
import { getColor, mediaUpTo, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  ${mediaFrom(
    'tablet',
    css`
      background: transparent url(${DotPatternBackground}) right no-repeat;
    `
  )}
`;

export const SContainer = styled.div`
  background: transparent;
  border-radius: 25px;
  padding: 125px 20px 80px;
  margin-bottom: 48px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${mediaUpTo(
    'tablet',
    css`
      background: ${getColor('white')};
    `
  )}

  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        button {
          padding: 14px 32px;
          max-width: 110px;
          height: 52px;
          font-size: 18px;
          font-weight: 600;
        }

        .btn-not-now {
          border-color: transparent;
        }
      `
    )}
  }
`;
