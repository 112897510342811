import { Loader } from 'components/general/Loader/Loader';
import React, { useEffect, useState } from 'react';
import { IATMLocations } from 'store/user/atmLocations/atmLocations.types';
import { useLazyGetMapQuery } from 'store/user/users.api';
import { SMapContainer, SZoomButton } from './AzureMap.styles';

interface IAzureMap {
  atms?: IATMLocations[];
}

// Correct pin example format
// const examplePin = `default|la15+50|sc1|al0.66|lc003C62|co002D62||'test1'47.53923089096655 19.03888616931953`;
export const AzureMap: React.FC<IAzureMap> = ({ atms = [] }) => {
  const [getMapAPI, getMapAPIResult] = useLazyGetMapQuery();
  const [imageSrc, setImageSrc] = useState('');
  const [pinString, setPinString] = useState('');
  const [center, setCenter] = useState('');
  const [zoom, setZoom] = useState(13);

  const handleZoom = (zoomIn: boolean) => {
    if (zoomIn && zoom < 20) {
      setZoom((prev) => prev + 1);
    }

    if (!zoomIn && zoom > 1) {
      setZoom((prev) => prev - 1);
    }
  };

  const calculateCenter = () => {
    let totalLatitude = 0;
    let totalLongitude = 0;

    atms.forEach((item: IATMLocations) => {
      totalLatitude += item.location.coordinates.latitude;
      totalLongitude += item.location.coordinates.longitude;
    });

    const averageLatitude = totalLatitude / atms.length;
    const averageLongitude = totalLongitude / atms.length;

    setCenter(`${averageLongitude}, ${averageLatitude}`);
  };

  // TODO: add label if it's needed
  const setPinItem = (latitude: number, longitude: number) => {
    return `${longitude} ${latitude}|`;
  };

  const setPinCollection = () => {
    let pins = 'default|sc1|al0.8|co808080||';

    if (atms?.length) {
      atms.forEach((item: IATMLocations) => {
        pins += setPinItem(item.location.coordinates.latitude, item.location.coordinates.longitude);
      });
    }

    setPinString(pins.slice(0, -1));
  };

  useEffect(() => {
    if (atms.length) {
      calculateCenter();
      setPinCollection();
    }
  }, [atms]);

  useEffect(() => {
    if (pinString && center) getMapAPI(`/?zoom=${zoom}&tilesetId=microsoft.base.road&api-version=2024-04-01&language=en-us&center=${center}&pins=${pinString}`);
  }, [pinString, center, zoom]);

  useEffect(() => {
    let objectURL: any;

    if (getMapAPIResult.isSuccess && getMapAPIResult.currentData instanceof Blob) {
      // Clean up the previous object URL to force image refresh
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }

      objectURL = URL.createObjectURL(getMapAPIResult.currentData);
      setImageSrc(objectURL);
    }

    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [getMapAPIResult.isSuccess, getMapAPIResult.currentData]);

  if (getMapAPIResult.isFetching) return <Loader />;

  return (
    <>
      <SMapContainer>{imageSrc && getMapAPIResult.isSuccess && <img src={imageSrc} alt="Map Preview" />}</SMapContainer>
      <SZoomButton>
        <span onClick={() => handleZoom(true)}>+</span>
        <span onClick={() => handleZoom(false)}>-</span>
      </SZoomButton>
    </>
  );
};
