import styled, { css } from 'styled-components';
import { getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const SContainer = styled.div`
  .mobile-title-container ${SAccountsContainer} {
    background: ${getColor('white')};
    padding: 24px;
    border-radius: 20px;
  }

  .web-title-container {
    width: 100%;
    & + ${SAccountsContainer} {
      margin-top: 20px;
    }
  }

  ${mediaBetween(
    'tablet',
    730,
    css`
      width: 500px;
    `
  )}

  ${mediaFrom(
    731,
    css`
      width: 650px;
    `
  )}
  ${mediaFrom(
    'desktop',
    css`
      width: 45%;
    `
  )}
`;

export const SCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${getColor('white')};
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;

  ${mediaUpTo(
    'tablet',
    css`
      padding-top: 5px;
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      min-height: 465px;
      margin-left: 15px;
    `
  )}
`;
