import { css } from 'styled-components';
import bgCreamGradientV2 from 'assets/images/bgCreamGradientV2.svg';
import dottesInAngle from 'assets/images/dottesInAngle.svg';

export const SBgArcTop = css`
  background-image: url(${bgCreamGradientV2});
  background-repeat: no-repeat;
  background-position: top -4% center;
  background-size: 100% auto;
`;

export const SDottesBottomRight = css`
  background-image: url(${dottesInAngle});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: auto auto;
`;

export const STwoBgArcTopDottesBottom = css`
  background-image: url(${bgCreamGradientV2}), url(${dottesInAngle});
  background-repeat: no-repeat, no-repeat;
  background-position: top -205px center, bottom right;
  background-size: 100% auto, auto auto;
`;
