import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SContainer } from './Card.styles';

type TCardProp = {
  title: string;
  name: string;
  list: React.ReactNode[];
};

export const Card: React.FC<TCardProp> = ({ title, name, list }) => (
  <SContainer>
    <CustomRow alignItems="flex-end">
      <div>
        <div className="title-container">
          <Title fontWeight="SB" size="sM">
            {title}
          </Title>
        </div>

        <ul>
          {list.map((cardData: React.ReactNode, index: number) => (
            <li className="list-item-lvl1" id={`${name}=${index}`}>
              <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70" lineHeight="24px">
                {cardData}
              </BodyText>
            </li>
          ))}
        </ul>
      </div>
    </CustomRow>
  </SContainer>
);
