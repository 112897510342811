import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  background: ${getColor('white')} no-repeat right bottom;
  padding: 32px;
  border-radius: 20px;
  margin: 16px 11%;

  .title-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .list-container {
    .list-item {
      align-items: flex-start;
      margin-bottom: 16px;

      .list-item-icon {
        margin-top: 5px !important;
      }
    }

    .list-item:last-child {
      margin-bottom: 0;
    }
  }

  & .list-item-lvl1,
  & .list-item-lvl2 {
    display: inline-flex;
    list-style: none;
    margin-left: 0;
    padding: 0;
  }

  & .list-item-lvl1:before,
  & .list-item-lvl2:before {
    // Bullet character: •
    content: '\u2022';
    color: ${getColor('blue')};
    font-weight: bold;
    display: block;
    margin-left: 8px;
    margin-right: 12px;
  }

  & .list-item-lvl2 {
    margin-left: 26px;
  }

  & .link {
    color: ${getColor('blue')};
    border-bottom: 1px solid ${getColor('blue')};
  }

  & .link:hover {
    border: none;
  }
`;
