import styled, { css } from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { mediaUpTo, mediaFrom, getColor } from 'utils/helpers/styleHelpers';
import { Card } from './Card/Card';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 32px;

  .security-touch-id {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
  }

  ${mediaFrom(
    'desktop',
    css`
      .wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
      }
      .page-title {
        border-bottom: 1px solid ${getColor('creamS5')};
        padding: 0 24px 46px;
        margin-bottom: 48px;
        width: 100%;

        .custom-title-text {
          font-size: 24px;
        }
      }

      .cards-container {
        width: 578px;
      }
    `
  )};

  ${mediaUpTo(
    'tablet',
    css`
      .security-privacy-subtitle .custom-text-inner {
        font-size: 14px;
      }
    `
  )}
`;

export const SCustomCard = styled(CustomCard)`
  padding: 24px;
  gap: 24px;
  display: grid;
`;

export const SCard = styled(Card)`
  padding: 24px;
  color: red;
`;
