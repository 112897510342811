import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { CustomText } from 'components/theme/CustomText/CustomText';
import { SInput } from './Input.styles';
import { IInput } from './Input.types';

export const Input = ({ label, type = 'text', onBeige = false, marginTop = 0, marginBottom = 0, marginLeft = 0, marginRight = 0, isError = false, isSuccess = false, ...props }: IInput) =>
  label ? (
    <CustomRow flexDirection="column" alignItems="flex-start">
      <CustomText marginBottom={8}>{label}</CustomText>
      <SInput
        type={type}
        onBeige={onBeige}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        isError={isError}
        isSuccess={isSuccess}
        allowClear={{ clearIcon: <Icon name="circleClose" color="charcoal40" size="smaller" className="suffix" cursorPointer /> }}
        {...props}
      />
    </CustomRow>
  ) : (
    <SInput
      type={type}
      onBeige={onBeige}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      isError={isError}
      isSuccess={isSuccess}
      allowClear={{ clearIcon: <Icon name="circleClose" color="charcoal40" size="smaller" className="suffix" cursorPointer /> }}
      {...props}
    />
  );
