import React, { useMemo } from 'react';
import { SECOND_CASH_STAGES, STAGES, WEB_STAGES, WEB_SECOND_CASH_STAGES } from 'views/OpenCashAccount/MyInfo/MyInfo.constants';
import { Stepper } from 'components/general/Stepper/Stepper';
import { useLocation } from 'react-router-dom';
import { ProgressBarInputProps } from './ProgressBar.type';

const replaceStage = (stageList: string[], fromStage: string, toStage: string) => {
  return stageList?.map((item: string) => (item === fromStage ? toStage : item));
};

export const ProgressBar = ({ stage, webStage }: ProgressBarInputProps) => {
  const { state } = useLocation();
  const isSecondaryCash = state?.alreadyHasCash;

  const steps = useMemo(() => {
    if (!isSecondaryCash) {
      switch (stage) {
        case 'Join Accountholder':
          return replaceStage(STAGES, 'My Details', stage);
        default:
          return STAGES;
      }
    }
    return STAGES;
  }, [stage, isSecondaryCash]);

  const activeIndex = steps.indexOf(stage);
  const activeWebIndex = WEB_STAGES.indexOf(webStage);

  const activeSecondCashIndex = SECOND_CASH_STAGES.indexOf(stage);
  const activeSecondCashWebIndex = WEB_SECOND_CASH_STAGES.indexOf(webStage);

  return (
    <Stepper
      currentStep={isSecondaryCash ? activeSecondCashIndex : activeIndex}
      currentWebStep={isSecondaryCash ? activeSecondCashWebIndex : activeWebIndex}
      steps={isSecondaryCash ? SECOND_CASH_STAGES : steps}
      webSteps={isSecondaryCash ? WEB_SECOND_CASH_STAGES : WEB_STAGES}
    />
  );
};
