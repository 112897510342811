import styled, { css } from 'styled-components';
import { mediaUpTo, mediaBetween, SCREEN_SIZE } from 'utils/helpers/styleHelpers';

export const SCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SLayout = styled.div`
  .desktop-page-header {
    margin-left: 64px;
  }

  .desktop-page-header__title-wrapper {
    margin-left: 0px;
  }
`;

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 32px;
  max-width: 600px;

  .services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    justify-content: start;
  }

  ${mediaBetween(
    SCREEN_SIZE.mobile + 1,
    SCREEN_SIZE.tablet,
    css`
      max-width: 488px;
      margin: 0 auto;

      .services {
        gap: 8px;
      }
    `
  )}

  .internal-wrapper {
    ${mediaUpTo(
      390,
      css`
        padding: 24px 15px;
      `
    )}
    ${mediaUpTo(
      'mobile',
      css`
        padding: 12px 15px;
      `
    )}
  }

  .disclaimer-wrapper {
    margin-top: auto;
    margin-bottom: 90px;
  }
`;
