import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/store';
import { setIsHelpAndSupportTab } from 'store/location.slice';
import { Title } from 'components/general/Typography';
import { setPreviousTab } from 'store/user/help.slice';
import { SWonderingAbout, SWellnessTabContent } from 'views/Wellness/Wellness.styles';
import { EarnPercPoints } from './EarnPercPoints/EarnPercPoints';

export const LearnAndPlay = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setIsHelpAndSupportTab({ isHelpAndSupportTab: false }));
    dispatch(setPreviousTab('learn-and-play'));
  }, []);

  return (
    <SWellnessTabContent>
      <Title fontWeight="SB" size="S" justifyContent="start" marginBottom={16}>
        {t('learnPlay.Earn Percapita Points')}
      </Title>
      <EarnPercPoints />
      <SWonderingAbout />
    </SWellnessTabContent>
  );
};
