import { Icon } from 'components/general/Icon/Icon';
import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { images } from 'assets';

export const SAcceptInvitePage = styled.div`
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;

  ${mediaFrom(
    'tablet',
    css`
      background: url(${images.jointAccountAcceptBg}) top no-repeat;
      background-size: contain;
      padding-bottom: 258px;
      background-position-y: 130px;
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      background-position-y: 16px;
    `
  )}

  ${mediaFrom(
    'desktopLarge',
    css`
      background-position-y: -56px;
    `
  )}
  
  .container {
    ${mediaUpTo(
      'desktop',
      css`
        max-width: 720px;
        padding-top: 47px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        padding-top: 30px;
      `
    )}
    
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
  }

  .hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    ${mediaFrom(
      'tablet',
      css`
        flex-direction: row;
        margin-bottom: 42px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        margin-bottom: 53px;
      `
    )}
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 676px;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        gap: 25px;
      `
    )}
  }

  .hero-header {
    font-size: 28px;

    ${mediaFrom(
      'tablet',
      css`
        font-size: 40px;
      `
    )}
  }

  .hero-image {
    margin: 32px 0 34px;
    ${mediaFrom(
      'tablet',
      css`
        display: block;
        margin-top: 0;
      `
    )}
  }

  .bottom-content {
    ${mediaFrom(
      'tablet',
      css`
        background: ${getColor('white')};
        padding: 30px 32px;
        border-radius: 20px;
      `
    )}
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column-reverse;

    ${mediaFrom(
      'tablet',
      css`
        flex-direction: row;
      `
    )}
  }

  .btn-decline {
    flex-shrink: 0;
    cursor: pointer;
    margin-top: 24px;

    ${mediaFrom(
      'tablet',
      css`
        margin: 0;
      `
    )}
  }

  .buttons-right {
    display: flex;
    margin-left: auto;
    gap: 24px;
    justify-content: end;
    width: 100%;
    flex-direction: column-reverse;

    ${mediaFrom(
      'tablet',
      css`
        flex-direction: row;
        gap: 8px;
      `
    )}
    .size-large {
      width: auto;
    }
  }
`;

export const SArrowRight = styled(Icon)`
  margin-left: 12px;
`;

export const SButtonContentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  white-space: normal;
  font-size: 16px;
`;

export const SSubList = styled.div`
  margin-left: 40px;

  ul.listitem {
    list-style-type: disc;
    padding-left: 32px;
    color: ${getColor('charcoal70')};

    li:not(:last-child) {
      margin-bottom: 8px;
    }

    & > li::marker {
      color: ${getColor('blue')};
    }

    ul {
      list-style-type: disc;
      padding-left: 28px;
      margin-top: 8px;

      & > li::marker {
        color: ${getColor('charcoal70')};
      }
    }
  }
`;
