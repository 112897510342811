import React, { useEffect, useState } from 'react';
import { images } from 'assets';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { ROUTES } from 'vars/const/ROUTES';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { TBreadcrumbsPath } from 'views/OpenCashAccount/StarterPage/CashAccOpeningDesktopPage/CashAccOpeningDesktopPage';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useLazyAprilByAppNameQuery } from 'store/user/users.api';
import { useAprilInit } from 'views/DoMore/use-april-init';
import { mobileApiCall } from 'services/mobileService';
import { lsGetItem } from 'utils/helpers/storage';
import { STaxPlannerPage, SList, SListItem, SBreadcrumbsWrapper, STaxScenariosModalWrapper } from './TaxPlanner.styles';

const pathList: TBreadcrumbsPath[] = [
  { id: 0, name: '', path: ROUTES.doMore.path },
  { id: 1, name: '' },
];

export const TaxFiler = () => {
  const { t } = useTranslation('doMore');
  const [refundPolicyOpen, setRefundPolicyOpen] = useState(false);
  const [taxScenariosOpen, setTaxScenariosOpen] = useState(false);
  const [getAprilKey, aprilKeyResponse] = useLazyAprilByAppNameQuery();
  const initApril = useAprilInit();
  const isMobileApp = lsGetItem('isMobileApp');

  const handleOpenAprilWidget = () => {
    if (isMobileApp) {
      mobileApiCall('showApril', 'FILING');
    } else {
      getAprilKey('FILING');
    }
  };

  useEffect(() => {
    if (aprilKeyResponse.data?.token) {
      initApril(aprilKeyResponse.data?.token);
    }
  }, [aprilKeyResponse]);

  return (
    <STaxPlannerPage flexDirection="column" minHeight="100%">
      <SBreadcrumbsWrapper>
        <Breadcrumbs paths={pathList} hasBackNav />
      </SBreadcrumbsWrapper>
      <div className="top-circle" />
      <div className="add-needs-goals-page-header">
        <div className="top-img center-image">
          <img src={images.taxFiler} alt="tax filer" />
        </div>
        <div className="add-needs-goals-page-title">
          <Title color="charcoal" font="Poppins" fontWeight="SB" size="XS" textAlign="start" className="title">
            {t('taxFiler.Work Smarter, Not Harder')}
          </Title>
        </div>

        <div>
          <BodyText className="description" marginBottom={32} textType="bodyText" size="M" fontWeight="R" color="charcoal70" marginTop={10} lineHeight="24px">
            <Trans
              i18nKey="taxFiler.Prepare and file your taxes for free if you have a Percapita Cash Account (or for $35 if you don’t)."
              ns="doMore"
              components={{ Bold: <strong /> }}
              values="free"
            />
          </BodyText>
          <div id="april" />
          <BodyText className="options-title" marginBottom={48} textType="bodyText" size="M" color="charcoal70" marginTop="spacing-med" lineHeight="24px" extraStyles={{ width: '100%' }}>
            {t('taxFiler.Tax Planner powered by')} <img src={images.aprilLogo} alt="april" />
          </BodyText>

          <BodyText fontWeight="SB" size="L" textAlign="start" marginBottom={16} justifyContent="start">
            {t('taxFiler.Why use Tax Filer?')}
          </BodyText>

          <CustomCard background="white">
            <SList className="options">
              <SListItem>
                <Icon name="earnings" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxFiler.Maximum refund guaranteed" ns="doMore" components={{ Bold: <strong /> }} values="Maximum refund" />
                </BodyText>
                <Icon name="info" color="blue" size="smaller" marginLeft={8} onClick={() => setRefundPolicyOpen(true)} />
              </SListItem>

              <SListItem>
                <Icon name="taxFiling" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxFiler.Fast-track filing with automated data and personalized flow" ns="doMore" components={{ Bold: <strong /> }} values="automated data" />
                </BodyText>
              </SListItem>

              <SListItem>
                <Icon name="terms" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxFiler.Guided prompts to take you through common tax scenarios" ns="doMore" components={{ Bold: <strong /> }} values="Guided prompts" />
                </BodyText>
                <Icon name="info" color="blue" size="smaller" marginLeft={8} onClick={() => setTaxScenariosOpen(true)} />
              </SListItem>

              <SListItem>
                <Icon name="dollarCrossed" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxFiler.No hidden fees or upsells" ns="doMore" components={{ Bold: <strong /> }} values="No hidden fees" />
                </BodyText>
              </SListItem>

              <SListItem>
                <Icon name="profile" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxFiler.Unlimited customer support" ns="doMore" components={{ Bold: <strong /> }} values="customer support" />
                </BodyText>
              </SListItem>

              <SListItem>
                <Icon name="security" />
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" lineHeight="24px">
                  <Trans i18nKey="taxFiler.Secure filing with an IRS-authorized e-file provider" ns="doMore" components={{ Bold: <strong /> }} values="IRS-authorized" />
                </BodyText>
              </SListItem>
            </SList>
          </CustomCard>
        </div>
      </div>
      <Button preset="blue-filled" size="xl" width="100%" onClick={handleOpenAprilWidget} marginTop={48} marginBottom={48}>
        {t('GetStarted')}
      </Button>
      <div id="april" />
      <CustomModal open={refundPolicyOpen} onClose={() => setRefundPolicyOpen(false)}>
        <Title size="M" fontWeight="M" marginBottom={16}>
          {t('taxFiler.Refund Policy')}
        </Title>
        <BodyText size="N" color="grey5">
          {t(
            'taxFiler.If you find an error in the tax preparation that entitles you to a larger refund (or smaller liability), we will refund any fees you paid us to use our service to prepare that return and you may use our service to amend your return at no additional charge. To qualify, the larger refund or smaller tax liability must not be due to differences or inaccuracies in data supplied by you, your choice not to claim a deduction or credit, positions taken on your return that are contrary to law, or changes in federal or state tax laws.'
          )}
        </BodyText>
      </CustomModal>

      <CustomModal open={taxScenariosOpen} onClose={() => setTaxScenariosOpen(false)} topPosition={0}>
        <STaxScenariosModalWrapper>
          <Title size="M" fontWeight="M" marginBottom={16}>
            {t('taxFiler.Common Tax Scenarios')}
          </Title>
          <div className="list">
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Health Savings Accounts (HSAs) deduction.')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Report the pre-tax dollars for your medical expenses.')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Child and dependent care credit')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Lower your tax bill while getting help with dependent care costs.')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Student loan interest deduction')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Deduct student loan interest to reduce your taxable income when you file your tax return.')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Child Tax Credit (CTC)')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Claim this tax credit for each qualifying child on your tax return, reducing your overall tax liability.')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Education credits')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Reduce tax liability by reporting certain education-related expenses.')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.401(k) contributions deduction')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Deduct any income that you’ve diverted directly from your paycheck into a traditional 401(k).')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Educator expenses deduction')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.As a school teacher or eligible educator, you can deduct up to a certain amount spent on classroom supplies.')}
                </BodyText>
              </div>
            </div>
            <div className="item">
              <Icon name="tickInCircle" color="blue" />
              <div>
                <BodyText size="N" fontWeight="B" color="charcoal" lineHeight="20px">
                  {t('taxFiler.Business expenses')}
                </BodyText>
                <BodyText size="N" color="grey5">
                  {t('taxFiler.Deduct eligible business expenses (including for multiple businesses), including home office, travel, and advertising, on your tax return to reduce taxable income.')}
                </BodyText>
              </div>
            </div>
          </div>
        </STaxScenariosModalWrapper>
      </CustomModal>
    </STaxPlannerPage>
  );
};
