import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SSelectSheet = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 32px 0;
  }

  .header {
    height: 56px;
    display: flex;
    align-items: end;
  }

  .select-option {
    padding: 12px 0;
    font-size: 16px;
    cursor: pointer;
  }

  .select-field {
    cursor: pointer;
    user-select: none;
  }

  input::placeholder {
    color: ${getColor('grey')};
    font-size: 16px !important;
  }

  .button-close {
    border: none;
    background: none;
    cursor: pointer;
  }
`;
