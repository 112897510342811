import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SFileItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 38px 20px;
  gap: 10px;
  border: 2px solid ${getColor('charcoal10')};
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const SMultipleFiles = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 2px solid ${getColor('charcoal10')};
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  flex-direction: column;
`;
