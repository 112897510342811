import React, { FC, MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { TextButton } from 'components/general/TextButton/TextButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SAccountFeaturePromo } from './AccountFeaturePromo.styles';
import { PennyJarCloseModal, IPennyJarCloseModal } from './Modals/PennyJarCloseModal';
import { CashCushionModal, ICashCushionModal } from './Modals/CashCushionModal';

interface IAccountFeaturePromo {
  type: 'PennyJar' | 'CashCushion';
}

interface IAccountFeaturePromoConfig {
  title: string;
  description: string;
  btnText?: string;
  logoSrc: string;
  modalComponent: FC<IPennyJarCloseModal | ICashCushionModal>;
  url: string;
}

export const AccountFeaturePromo = ({ type }: IAccountFeaturePromo) => {
  const { t } = useTranslation('home');

  const config: IDictionary<IAccountFeaturePromoConfig> = {
    PennyJar: {
      title: t('pennyJarPromo.Title'),
      description: t('pennyJarPromo.Description'),
      btnText: t('pennyJarPromo.Button'),
      url: ROUTES.pennyJar.path,
      logoSrc: images.pennyJarLogo,
      modalComponent: PennyJarCloseModal,
    },
    CashCushion: {
      title: t('cashCushionPromo.Title'),
      description: t('cashCushionPromo.Description'),
      url: ROUTES.cashCushion.path,
      logoSrc: images.cashCushion,
      modalComponent: CashCushionModal,
      btnText: t('cashCushionPromo.ContinueButtonText'),
    },
  };

  const currentConfig = config[type];

  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const [isClosed, setIsClosed] = useState(false);
  const modalToggler = useToggle(false);

  const handleClosePromo = () => {
    modalToggler.hide();
    setIsClosed(true);
  };

  const handleCloseClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    modalToggler.show();
    return false;
  };

  const handleAdd = () => {
    if (type === 'PennyJar') navigate(ROUTES.pennyJar.path);
    if (type === 'CashCushion') navigate(ROUTES.cashCushion.path);
  };

  return !isClosed && currentConfig ? (
    <SAccountFeaturePromo>
      <img src={isDesktopSize ? images.pennyJarGreyDots : images.pennyJarDots} className="dots-bg" alt="dots" />
      <Icon
        name={isDesktopSize ? 'closeCircle' : 'close'}
        color={isDesktopSize ? 'charcoal' : 'charcoal70'}
        size={isDesktopSize ? 'smaller' : 'smallest'}
        cursorPointer
        onClick={handleCloseClick}
        className="close"
      />
      <div className="content" onClick={handleAdd}>
        <BodyText font="Poppins" color="charcoal" textType="bodyText" size="M" fontWeight="SB">
          {currentConfig.title}
        </BodyText>
        <BodyText color={isDesktopSize ? 'charcoal70' : 'creamS70'} lineHeight="16px" textType="bodyText" size="T" fontWeight="R">
          {currentConfig.description}
        </BodyText>
      </div>

      {currentConfig?.btnText && (
        <TextButton onClick={handleAdd} fontWeight="SB">
          {currentConfig.btnText}
        </TextButton>
      )}

      <img src={currentConfig.logoSrc} className={clsx('logo', type)} alt={type} />

      <currentConfig.modalComponent open={modalToggler.isActive} onClose={handleClosePromo} />
    </SAccountFeaturePromo>
  ) : null;
};
