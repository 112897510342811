import { IAdditionalInformationHandlerConfig } from './AdditionalInformationHandler.types';

export const MODAL_DATA: IAdditionalInformationHandlerConfig = {
  invite: {
    TITLE: 'inviteEarn.AdditionalInformation',
    TITLE_COLOR: 'charcoal',
    TEXT: `inviteEarn.YourReferralReceiveTextOrEmailFromPercapita`,
    TEXT_COLOR: 'charcoal70',
    FONT_WEIGHT: 'R',
    TOP_POSITION: '25%',
  },
  universalInfo: {
    TITLE: 'directDeposit.Direct Deposit',
    TITLE_COLOR: 'charcoal',
    TEXT: 'directDeposit.Setting up direct deposit ...',
    TEXT_COLOR: 'charcoal70',
    FONT_WEIGHT: 'R',
    TOP_POSITION: '25%',
  },
  preferredName: {
    TITLE: 'profile.WhatIsPreferredName',
    TITLE_COLOR: 'charcoal',
    TEXT: 'profile.YourPreferredNameIsTheNameWeWillUse',
    TEXT_COLOR: 'charcoal70',
    FONT_WEIGHT: 'R',
  },
  routingNumber: {
    TITLE: 'accountInformation.Routing Number',
    TITLE_COLOR: 'charcoal',
    TEXT: 'accountInformation.Your Routing Number is',
    TEXT_COLOR: 'charcoal70',
    FONT_WEIGHT: 'R',
  },
  accountNumber: {
    TITLE: 'accountInformation.Account Number',
    TITLE_COLOR: 'charcoal',
    TEXT: 'accountInformation.Your Account Number is',
    TEXT_COLOR: 'charcoal70',
    FONT_WEIGHT: 'R',
  },
};
