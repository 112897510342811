import React, { useEffect } from 'react';
import Card from 'assets/images/card.svg';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { BodyText, Title } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useToggle } from 'utils/hooks/useToggle';
import { EAccountType } from 'store/user/accounts/accounts.types';
import { Icon } from 'components/general/Icon/Icon';
import { AddMoneyModal } from 'views/Main/MainPage/Accounts/AccountCardItem/components/AddMoneyModal/AddMoneyModal';
import { CardHubModal } from 'components/general/Modals/CardHubModal/CardHubModal';
import { Button } from 'components/theme/Button/Button';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useCardHub } from 'utils/hooks/useCardHub';
import { SContainer } from './PrimaryCashAmount.styles';

interface PrimaryCashAmountProps {
  name?: string;
  cashAmount?: number;
  accountId: string;
}

export const PrimaryCashAmount = ({ name, cashAmount = 0, accountId }: PrimaryCashAmountProps) => {
  const { t } = useTranslation(['home', 'percapitaPay']);
  const { navigate } = useEnhancedNav();
  const { isDesktopSize } = useDeviceDimension();
  const addMoneyModal = useToggle(false);
  const [searchParams] = useSearchParams();
  const cardHub = useCardHub();
  const initParam = searchParams.get('init');

  const handleClickMoveMoney = () => {
    navigate(ROUTES.moveMoney.path);
  };

  const handleBoxClick = () => {
    navigate(ROUTES.balancesTransactions.path, { searchParams: { groupId: accountId, accountType: EAccountType.CASH } });
  };

  useEffect(() => {
    if (initParam === 'cardhub') {
      cardHub.open();
    }
  }, [initParam]);

  return (
    <SContainer>
      <div className="available-container" onClick={handleBoxClick}>
        <div className="title-container">
          <Title size={isDesktopSize ? 'T' : 'XS'} fontWeight={isDesktopSize ? 'SB' : 'B'}>
            {name}
          </Title>
          <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R">
            {t('homeScreen.AvailableToSpend')}
          </BodyText>
        </div>

        <div className="amount-container">
          {cashAmount !== undefined && (
            <CustomAmount
              isPoppins
              amount={cashAmount}
              multiSizable
              remainingSize="xl"
              remainingWeight={600}
              color={cashAmount < 0 ? 'red' : 'charcoal'}
              size={isDesktopSize ? 'thin' : 'large'}
              cursorPointer
              align="left"
            />
          )}
          <Icon name="chevronRight" cursorPointer size="smaller" color="blue" />
        </div>
      </div>

      <div className="move-money-see-card-container">
        <div className="move-money-container">
          <Button preset="clear" onClick={handleClickMoveMoney} size={isDesktopSize ? 'small' : 'large'}>
            {t('homeScreen.MoveMoney')}
            {isDesktopSize && <Icon name="moveMoney" color="green" size="normal" cursorPointer marginLeft={12} />}
          </Button>
        </div>

        <div className="card-container">
          <div className="see-more-container" onClick={cardHub.open}>
            {isDesktopSize && t('percapitaPayHome.See more', { ns: 'percapitaPay' })}
            <img src={Card} alt="card" />
          </div>
          {!isDesktopSize && <Icon name="chevronRight" color="blue" size="smaller" cursorPointer />}
        </div>
      </div>

      <AddMoneyModal open={addMoneyModal.isActive} onClose={addMoneyModal.hide} />
      <CardHubModal open={cardHub.isActive} onClose={cardHub.close} />
    </SContainer>
  );
};
