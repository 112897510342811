import styled, { css } from 'styled-components';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  position: relative;
  left: 0;
  top: 0;
  overflow-x: scroll;
  min-width: 485px;

  display: flex;
  flex-direction: column;
  border-right: 1.5px ${getColor('creamS5')} solid;
  padding: 32px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${mediaUpTo(
    1199,
    css`
      display: none;
    `
  )}

  .reset {
    color: ${getColor('charcoal70')} !important;
  }

  .btn {
    font-weight: 600;
    font-family: Poppins;
  }
`;

export const SDateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SCalendarContainer = styled.div`
  display: flex;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`;
