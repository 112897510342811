import React, { useMemo } from 'react';
import { SLoaderBackdrop, SLoader } from './Loader.styles';

interface ILoaderProps {
  inline?: boolean;
  size?: 'big' | 'small';
}

export const Loader = ({ inline = false, size = 'big' }: ILoaderProps) => {
  const loader = useMemo(
    () => (
      <SLoader className={`loader ${size}`}>
        <div className="loader-point" />
        <div className="loader-point" />
        <div className="loader-point" />
      </SLoader>
    ),
    [inline]
  );

  return inline ? loader : <SLoaderBackdrop className="loader-backdrop">{loader}</SLoaderBackdrop>;
};
