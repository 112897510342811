import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from 'components/general/Chip/Chip';
import { TAccountSourceType } from 'vars/types/accounts.types';
import { SAccountsTypeSwitcher } from './AccountsTypeSwitcher.styles';

interface IAccountsTypeSwitcher {
  activeType: TAccountSourceType;
  onChange: (type: TAccountSourceType) => void;
  isDesktopSize: boolean;
  isExternalActive?: boolean;
  isExternalEnable?: boolean;
}

export const AccountsTypeSwitcher = ({ activeType = 'percapita', onChange, isDesktopSize, isExternalActive = true, isExternalEnable = true }: IAccountsTypeSwitcher) => {
  const { t } = useTranslation('moveMoney');
  const accountTypeRef = useRef<HTMLDivElement | null>(null);

  const handleOnClick = (account: TAccountSourceType) => {
    onChange(account);
    if (accountTypeRef.current && !isDesktopSize) {
      const parentItemElement = accountTypeRef.current.parentElement;
      const scroll = account === 'external' ? accountTypeRef.current.scrollWidth + accountTypeRef.current.clientWidth : accountTypeRef.current.scrollWidth - accountTypeRef.current.clientWidth;

      parentItemElement?.scrollTo({
        left: scroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <SAccountsTypeSwitcher className="accounts-type-switcher" ref={accountTypeRef}>
      <Chip iconName="percapita" type="light" onClick={() => handleOnClick('percapita')} isActive={activeType === 'percapita'}>
        {t('transferPage.PercapitaAccounts')}
      </Chip>
      {isExternalActive && isExternalEnable && (
        <Chip iconName="externalTransfer" type="light" onClick={() => handleOnClick('external')} isActive={activeType === 'external'}>
          {t('transferPage.ExternalAccounts')}
        </Chip>
      )}
    </SAccountsTypeSwitcher>
  );
};
