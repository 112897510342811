import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';

interface IModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ATMLocationsInfoModal = ({ isOpen, handleClose }: IModalProps) => {
  const { t } = useTranslation('atmLocations');
  return (
    <CustomModal
      open={isOpen}
      onCancel={handleClose}
      closeIcon={<Icon name="close" size="smaller" color="charcoal70" marginTop={16} marginLeft={16} />}
      modalName="atmLocations_infoModal"
      topPosition="0"
    >
      <Title size="M" fontWeight="SB" color="charcoal" marginBottom={16} className="add-account-title">
        {t('TransactionLimits')}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={16} lineHeight={1.6}>
        {t('FollowingLimitsApply')}
      </BodyText>
      <ul style={{ paddingLeft: '24px' }}>
        <li>
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" lineHeight={1.6}>
            {t('PerDay')}
          </BodyText>
        </li>
        <li>
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" lineHeight={1.6}>
            {t('NoLimit')}
          </BodyText>
        </li>
      </ul>
    </CustomModal>
  );
};
