import styled, { css } from 'styled-components';
import { CustomTitle } from 'components/theme/CustomTitle/CustomTitle';
import { mediaUpTo, mediaBetween, SCREEN_SIZE } from 'utils/helpers/styleHelpers';

export const SPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .menu-item-container {
    gap: 24px;
    border-radius: 16px;
    padding: 20px;
  }
  .disclaimer-note-wrapper {
    margin-bottom: 48px;
  }

  ${mediaUpTo(
    'mobile',
    css`
      .custom-text-inner,
      .disclaimer-note-wrapper .custom-text-inner span {
        font-size: 11px !important;
      }
    `
  )};

  ${mediaBetween(
    SCREEN_SIZE.mobile + 1,
    SCREEN_SIZE.tablet,
    css`
      padding: 10px 0 0;

      .menu-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;

        .menu-group-title {
          .custom-text-inner {
            font-size: 16px;
          }
        }

        .menu-item-container {
          .menu-item {
            .custom-row {
              .svg-icon {
                width: 20px !important;
                height: 20px !important;
              }

              .body-text {
                .custom-text-inner {
                  font-size: 16px;
                }
              }
            }

            .icon-chevronRight {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .disclaimer-note-wrapper {
        .custom-text-inner {
          font-size: 12px;

          span {
            font-size: 12px;
          }
        }
      }
    `
  )}
`;
export const SCardTitle = styled(CustomTitle)`
  align-self: flex-start;
`;
