import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  width: 100%;

  ${mediaFrom(
    'desktop',
    css`
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 40px 20px;
    `
  )}

  .privacy {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .privacy {
      display: none;
      & div {
        display: inline;
      }
    }

    ${mediaFrom(
      577,
      css`
        width: 580px;
        margin: 0 auto;

        .privacy {
          display: block;
        }
      `
    )}

    .web-title-container {
      ${mediaUpTo(
        'tablet',
        css`
          display: none;
        `
      )}
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-bottom: 16px;
      margin-top: 32px;
      width: 100%;
    }

    .edit-title-container {
      width: 100%;
      margin-top: 15px;

      ${mediaFrom(
        'desktop',
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          margin-bottom: 16px;
          margin-top: 48px;
        `
      )}
    }

    .edit-birth-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 100%;
      border-radius: 20px;
      background: ${getColor('transparent')};
      margin-top: 8px;

      ${mediaUpTo(
        'mobile',
        css`
          .body-text {
            margin-top: 20px;
          }
        `
      )}

      ${mediaFrom(
        'tablet',
        css`
          background: ${getColor('white')};
          padding: 8px 24px;
        `
      )}
    }

    .birth-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 100%;
      border-radius: 20px;
      background: ${getColor('white')};
      padding: 8px 24px;
      margin-top: 8px;

      ${mediaUpTo(
        'mobile',
        css`
          .body-text {
            margin-top: 20px;
          }
        `
      )}
    }
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }
`;
