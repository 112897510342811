export interface IIdentityVerificationResponse {
  identityVerificationId: string;
  status: string;
  shareUrl: string;
}

export interface IIdentityVerificationPayload {
  firstName: string;
  middleName: string;
  lastName: string;
  dayOfBirth: number;
  monthOfBirth: number;
  yearOfBirth: number;
  unitNumber: string;
  city: string;
  state: string;
  postalCode: string;
  address1: string;
  address2: string;
  phone: string;
  email: string;
  id: string;
  idType: string;
  isMailingAddressTheSame: boolean;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  mailingAddress1: string;
  mailingAddress2: string;
}

export enum EPlaidVerificationType {
  DOCUMENT = 'Document',
  PII = 'PII',
  ADDRESS = 'Address',
  ALT_ADDRESS_PII = 'AlternativeAddressPII',
}

export interface IIdentityVerificationRequest {
  credentials: IIdentityVerificationPayload;
  userVerificationType: `${EPlaidVerificationType}` | undefined;
}

// {
//   "data": {
//       "identityVerificationId": "idv_d2skBdPNMETvHP",
//       "status": "Pending",
//       "shareUrl": "https://verify-sandbox.plaid.com/verify/idv_d2skBdPNMETvHP?key=cebfe591a76f1ef54a7140602495a692"
//   }
// }
