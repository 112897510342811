/*
  Ticket: DEV-6142 and DEV-6249

  ATM Withdrawal (Money Out) - Event Tran Code 0293
  Debit Card Purchase (Money Out) - Event Tran Code 0217
  Debit Card Credit (Money In) - Event Tran Code 0133
  ACH Debit (Money Out) - Event Tran Code 0280, 0206, 0274, 0212, 0262, 024, 026
  ACH Credit (Money In) - Event Tran Codes 0154, 0157, 0159, 0164, 0151
  Percapita Fee (Money Out) - Event Tran Code 0295, 0225, 0294, 02195
  Penny Jar (Money In) - Event Tran Code 0169
  Penny Jar (Money Out) - Event Tran Code 0292
  Internal Transfer (Money In) - Event Tran Code 0163, 0160
  Internal Transfer (Money Out) - Event Tran Code 0208, 028
  External Transfer (Money Out) -  Event Tran Code 0204
  External Transfer (Money In) - Event Tran Code 0139
  Bill Pay (Money Out) - Event Tran Code 0206
  International Fee (Money Out) - Event Tran Code: 0294
*/

export interface IPaymentIcon {
  trnCode: string;
  type: 'income' | 'expense';
  iconUrl?: string;
  iconName?: string;
}

export interface ITransactionCodeItem {
  icon: string;
  type: string;
  codes: string[];
  description: string;
}

export interface ITransactionCodes {
  [key: string]: ITransactionCodeItem;
}

export const TRANSACTION_CODES: ITransactionCodes = {
  atm: {
    icon: 'transaction_atm',
    type: 'expense',
    codes: ['0293'],
    description: 'accountInformation.TransactionTypes.ATMWithdrawal',
  },
  debitPurchase: {
    icon: 'transaction_credit',
    type: 'expense',
    codes: ['0217'],
    description: 'accountInformation.TransactionTypes.DebitCardPurchase',
  },
  debitCredit: {
    icon: 'transaction_credit',
    type: 'income',
    codes: ['0133'],
    description: 'accountInformation.TransactionTypes.DebitCardCredit',
  },
  achDebit: {
    icon: 'transaction_ACH',
    type: 'expense',
    codes: ['0280', '0274', '0212', '0262', '024', '026'],
    description: 'accountInformation.TransactionTypes.ACHDebit',
  },
  achCredit: {
    icon: 'transaction_ACH',
    type: 'income',
    codes: ['0154', '0157', '0159', '0164', '0151'],
    description: 'accountInformation.TransactionTypes.ACHCredit',
  },
  percapitaFee: {
    icon: 'transaction_percapita',
    type: 'expense',
    codes: ['0295', '0225', '02195'],
    description: 'accountInformation.TransactionTypes.PercapitaFee',
  },
  pennyJarIn: {
    icon: 'transaction_pennyJar',
    type: 'income',
    codes: ['0169'],
    description: 'accountInformation.TransactionTypes.PennyJar',
  },
  pennyJarOut: {
    icon: 'transaction_pennyJar',
    type: 'expense',
    codes: ['0292'],
    description: 'accountInformation.TransactionTypes.PennyJar',
  },
  internalTransferIn: {
    icon: 'creditCard2',
    type: 'income',
    codes: ['0163', '0160'],
    description: 'accountInformation.TransactionTypes.InternalTransfer',
  },
  internalTransferOut: {
    icon: 'creditCard2',
    type: 'expense',
    codes: ['0208', '028'],
    description: 'accountInformation.TransactionTypes.InternalTransfer',
  },
  externalTransferIn: {
    icon: 'transaction_external',
    type: 'income',
    codes: ['0139'],
    description: 'accountInformation.TransactionTypes.ExternalTransfer',
  },
  externalTransferOut: {
    icon: 'transaction_external',
    type: 'expense',
    codes: ['0204'],
    description: 'accountInformation.TransactionTypes.ExternalTransfer',
  },
  billPay: {
    icon: 'transaction_billPay',
    type: 'expense',
    codes: ['0206'],
    description: 'accountInformation.TransactionTypes.BillPay',
  },
  internationalFee: {
    icon: 'transaction_international',
    type: 'expense',
    codes: ['0294'],
    description: 'accountInformation.TransactionTypes.InternationalFee',
  },
};
