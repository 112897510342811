import React, { useEffect, useRef } from 'react';
import { Chip } from 'components/general/Chip/Chip';
import { STabs, STabLabels } from './Tabs.styles';

interface ITabLabelsProps {
  labels: { id: string; title: string; hidden?: boolean };
  onSelectTab: (tabName: string) => void;
  activeTabId?: string;
}

interface ITabPaneProps {
  tabId: string;
  children: React.ReactNode;
}

interface ITabsProps {
  activeTabId?: string;
  tabs: {
    id: string;
    component: React.FC;
  }[];
}

export const TabLabels = ({ labels, onSelectTab, activeTabId }: ITabLabelsProps) => {
  const tabItemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (activeTabId === labels.id && tabItemRef.current) {
      const parentItemElement = tabItemRef.current.parentElement;
      const parentElementWidth = parentItemElement?.clientWidth;
      const accountItemWidth = tabItemRef.current.clientWidth;
      const elementLeft = tabItemRef.current.offsetLeft;
      const scrollLeft = Math.max(0, elementLeft - (Number(parentElementWidth) - accountItemWidth) / 2);
      parentItemElement?.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [activeTabId]);

  return (
    <STabLabels className="tab-labels" ref={tabItemRef}>
      {!labels.hidden && (
        <Chip className="tab-labels__label" isActive={activeTabId === labels.id} onClick={() => onSelectTab(labels.id)}>
          {labels.title}
        </Chip>
      )}
    </STabLabels>
  );
};

export const TabPane = ({ tabId, children }: ITabPaneProps) => (
  <div className="tab-pane" data-tab-name={tabId}>
    {children}
  </div>
);

export const Tabs = ({ activeTabId = '', tabs }: ITabsProps) => (
  <STabs className="tabs" activeTabName={activeTabId}>
    {tabs.map((tab) => (
      <TabPane tabId={tab.id}>
        <tab.component />
      </TabPane>
    ))}
  </STabs>
);
