import styled, { css } from 'styled-components';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';
import { getDeviceHeaderSizes } from 'utils/helpers/uiHelpers';

const RIGHT_BTN_SIZE = 38;
const LEFT_BTN_SIZE = 40;

const { headerHeight } = getDeviceHeaderSizes();

export const SHeaderBar = styled.header`
  position: relative;
  top: 0;
  left: 0;
  flex: 0 0 auto;
  width: 100%;
  background-color: ${getColor('blue')};
  display: flex;
  align-items: flex-end;
  height: ${headerHeight}px;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 12px;
  z-index: 999;
  margin-top: 8px;

  ${mediaUpTo(
    'tablet',
    css`
      height: 55px;
      justify-content: space-between;
    `
  )}
`;

export const SRightButtons = styled.div`
  display: flex;
  vertical-align: center;
  position: relative;
  right: 20px;
  padding: 5px 0;

  ${mediaUpTo(
    'mobile',
    css`
      align-items: end;
      right: 11px;
      padding-bottom: 0;

      & .svg-icon {
        width: 18px;
        height: 18px;
      }

      & .notifications {
        margin-left: 15px;
        margin-right: 18px;
        & span {
          margin: 0;
        }
      }

      button:first-child {
        padding-bottom: 4px;
      }
    `
  )}
`;

export const SHeaderRightBtn = styled.button<any>`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: ${RIGHT_BTN_SIZE}px;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  position: relative;

  ${({ marginRight, extraStyles }) => css({ marginRight, ...extraStyles })};

  .notifications {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    right: -6px;
    height: 20px;
    width: 24px;
    border: 2px solid ${getColor('blue')};
    border-radius: 10px;
    padding: 2px 4px;
    background-color: ${getColor('red')};
    font-size: 12px;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    color: ${getColor('white')};
  }

  .point {
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: ${getColor('white')};
    bottom: -4px;
    left: 9px;
  }
`;

export const SHeaderLeftBtn = styled(SHeaderRightBtn)`
  width: ${LEFT_BTN_SIZE}px;
  height: ${LEFT_BTN_SIZE}px;
  background: transparent;
  margin-right: 2px;
  padding: 0;
  align-items: center;

  ${mediaUpTo(
    'mobile',
    css`
      margin-left: 12px;
      padding-top: 10px;
      .icon-arrowLeft {
        width: 18px;
        height: 18px;
      }
    `
  )}
`;

export const SLeftBtnContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 5px;
  border-radius: 50%;
  ${mediaUpTo(
    400,
    css`
      padding-right: 35px;
    `
  )}
`;

export const SProfileBtn = styled.button`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${getColor('blue70')};
  border: none;
  color: ${getColor('white')};
  font-family: DM Sans, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-left: 16px;
  margin-top: 8px;
  padding: 0;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    display: none;
  }

  &.active {
    background-color: ${getColor('blue10')};
    color: ${getColor('blue')};
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: ${getColor('white')};
      bottom: -8px;
      left: 14px;
    }
  }

  ${mediaUpTo(
    'mobile',
    css`
      width: 28px;
      height: 28px;
      min-width: 28px;
      letter-spacing: -1px;
      margin-left: 14px;
      margin-top: 12px;
    `
  )}
`;
