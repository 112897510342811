import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 24px;
  margin-bottom: 32px;

  .first-column {
    width: 55%;
  }

  .second-column {
    width: 45%;
  }

  .bordered {
    border-bottom: 1px solid #eaeaea;
  }
`;

export const SCustomCard = styled(CustomCard)`
  background: ${getColor('creamSS2')};
  border-radius: 8px;
  padding: 25px 24px;
  z-index: 99;
`;

export const SListCard = styled(CustomCard)`
  border-radius: 8px;
  padding: 25px 24px;
  margin-bottom: 64px;
`;
