import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SAmountText } from 'components/general/Modals/PercapitaPayModal/PercapitaPayModal.styles';
import { usePayments } from 'utils/hooks/usePayments';
import { BodyText } from 'components/general/Typography';

export const PayPeriodTooltipContent = () => {
  const { t } = useTranslation('percapitaPay');
  const { paymentsInfo } = usePayments();
  const payPeriod = Number(paymentsInfo.maxPayPeriodTransactionsCount) - Number(paymentsInfo.transfersAvailable);

  return (
    <>
      <BodyText font="Poppins" color="charcoal" marginBottom={16} fontWeight="B" size="T">
        {t('percapitaPayHome.So far this pay period')}
      </BodyText>

      <BodyText lineHeight={1.4} textType="bodyText" size="T" fontWeight="R" color="charcoal70" marginBottom={16}>
        <Trans
          i18nKey="percapitaPayHome.You can make up to ..."
          ns="percapitaPay"
          values={{
            maxPayPeriodTransactionsCount: paymentsInfo?.maxPayPeriodTransactionsCount,
            payPeriod,
            transfersAvailable: paymentsInfo?.transfersAvailable,
          }}
        >
          You can make up to <SAmountText>{paymentsInfo?.maxPayPeriodTransactionsCount}</SAmountText> requests per pay period. You have made
          <SAmountText>{payPeriod}</SAmountText> requests so far this period, so you have
          <SAmountText>{paymentsInfo?.transfersAvailable}</SAmountText> remaining.
        </Trans>
      </BodyText>

      <BodyText lineHeight={1.4} textType="bodyText" size="T" fontWeight="R" color="charcoal70">
        {/* prettier-ignore */}
        <Trans
                    i18nKey="percapitaPayHome.You have already requested..."
                    ns='percapitaPay'
                    values={{
                        transferredAmount: paymentsInfo?.transferredAmount,
                    }}
                >
                    You have already requested and
                    received <SAmountText>{paymentsInfo?.transferredAmount}</SAmountText> this pay period. This amount
                    has already been taken out of the amount available to you
                </Trans>
      </BodyText>
    </>
  );
};
