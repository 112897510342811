import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { SCustomCard } from './AddMoneyModal.styles';

interface IOptionItemProps {
  iconName: string;
  text: string;
  onClick: () => void;
}

export const OptionItem: React.FC<IOptionItemProps> = ({ iconName, text, onClick }) => {
  return (
    <SCustomCard onClick={onClick}>
      <Icon name={iconName} color="blue" />
      <BodyText size="N" fontWeight="B" marginLeft={16}>
        {text}
      </BodyText>
      <Icon name="arrowRight" color="blue" size="smallest" className="arrow" />
    </SCustomCard>
  );
};
