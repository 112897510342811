import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SFooter } from './Footer.styles';

interface IFooter {
  isDisabled?: boolean;
  isBackVisible?: boolean;
  firstBtnText?: string;
  secondBtnText?: string;
  zIndex?: number;
  absolutePosition?: boolean;
  marginTop?: number;
  marginBottom?: number;
  onClick: () => void;
  onBack?: () => void;
}

export const Footer: React.FC<IFooter> = ({
  isDisabled = false,
  zIndex = 9999,
  isBackVisible = true,
  absolutePosition = false,
  firstBtnText,
  secondBtnText,
  marginTop,
  marginBottom,
  onClick,
  onBack,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const StyleObj = {
    marginBottom,
    marginTop,
  };

  const handleOnBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <SFooter zIndex={zIndex} absolutePosition={absolutePosition} style={StyleObj}>
      {isBackVisible && (
        <Button preset="blue-outlined" onClick={handleOnBack} size="middleAlt">
          {firstBtnText || t('accountOpening.Back')}
        </Button>
      )}
      <Button preset="blue-filled" disabled={isDisabled} onClick={onClick} size="middleAlt">
        {secondBtnText || t('accountOpening.Continue')}
      </Button>
    </SFooter>
  );
};
