import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/store';
import { usePercapitaPlayWarning } from 'utils/hooks/usePercapitaPlayWarning';
import { setDisplayPercPlaysInfoModal } from 'store/ui.slice';
import { zogoApi } from 'store/user/zogo/zogo.api';
import { Loader } from 'components/general/Loader/Loader';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Button } from 'components/theme/Button/Button';
import { BodyText } from 'components/general/Typography';
import { PercapitaPlayWarningModal } from 'components/general/Modals/PercapitaPlayWarningModal/PercapitaPlayWarningModal';
import { Icon } from 'components/general/Icon/Icon';
import { SCard } from './EarnPercPoints.styles';

export const EarnPercPoints = () => {
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const { isDesktopSize } = useDeviceDimension();
  const zogoTokenQuery = zogoApi.useGetZogoTokenQuery();
  const { handleGoToPlay, handleCloseWarning, isWarningActive, isStatusLoading, handleContinue, isAcceptPending } = usePercapitaPlayWarning();

  const handleInfoClick = () => {
    dispatch(setDisplayPercPlaysInfoModal(true));
    track('navigate', '/wellness/learn-and-play/playpercup/percapita-plays-info');
  };

  if (isStatusLoading || zogoTokenQuery.isFetching || zogoTokenQuery.isLoading) return <Loader />;

  return (
    <>
      <SCard>
        <div className="image-text-container">
          <div className="percup-image" />

          <div className="text-button-container">
            <BodyText textType="bodyText" size="N" fontWeight="R" lineHeight={1.5} color="charcoal70">
              <Trans i18nKey="homeScreen.PercapitaPlayDefaultDescription" ns="home" components={{ strong: <strong /> }} />
            </BodyText>

            <div className="button-container">
              <Button preset="blue-filled" onClick={handleGoToPlay} size="small">
                {t('learnPlay.ReadyToPlay')}
              </Button>
            </div>
          </div>
        </div>

        <div className="info-container">
          <Icon name={`${isDesktopSize ? 'circleQuestion' : 'info'}`} color="blue" onClick={handleInfoClick} cursorPointer className="info" size="smaller" />
        </div>
      </SCard>
      <PercapitaPlayWarningModal open={isWarningActive} onClose={handleCloseWarning} onContinue={handleContinue} isLoading={isAcceptPending} />
    </>
  );
};
