import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from 'assets';
import { ROUTES } from 'vars/const/ROUTES';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { SLayout } from './DirectDepositNow.styles';

export const DirectDepositNow = () => {
  const { t } = useTranslation(['deposit', 'cashAccount']);
  const navigate = useNavigate();

  const handleOnYes = () => navigate(ROUTES.setUpDeposit.path);
  const handleOnNotNow = () => navigate(ROUTES.home.path);

  return (
    <SLayout>
      <div className="center-image">
        <img src={images.startDeposit} width={150} alt="Start Deposit" />
      </div>

      <Title size="M" fontWeight="M" textAlign="center" marginTop={30}>
        {t('setUpDeposit.SetUpDirectDepositToYourNewAccount')}
      </Title>

      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="center" marginTop={15} marginBottom={35} paddingRight={10} paddingLeft={10} lineHeight={1.3}>
        {t('setUpDeposit.YouCanHaveYourPayDepositedDirectlyIntoYourCashAccount')}
      </BodyText>

      <div>
        <Button size="large" preset="blue-filled" onClick={handleOnYes}>
          {t(`cashAccount.Yes`, { ns: 'cashAccount' })}
        </Button>
        <Button marginTop={20} size="large" preset="blue-outlined" onClick={handleOnNotNow}>
          {t(`cashAccount.Not Now`, { ns: 'cashAccount' })}
        </Button>
      </div>
    </SLayout>
  );
};
