import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  padding-left: 5px;
`;

export const SListItemText = styled.li`
  display: block;
  font-family: DM Sans;
  padding-right: 30px;
  margin-left: 56px;
  margin-top: 8px;
  line-height: 1.3;
  color: ${getColor('charcoal70')};

  &::before {
    content: '\u2022';
    color: blue;
    display: inline-block;
    margin-right: 14px;
    margin-left: -20px;
  }
`;
