import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { Title } from 'components/general/Typography';
import { IThirdParty } from 'store/user/accounts/accounts.types';
import { accountsApi } from 'store/user/accounts/accounts.api';
import { Button } from 'components/theme/Button/Button';
import { Loader } from 'components/general/Loader/Loader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { getRequiredRule, noSideWhitespacesRule } from 'utils/helpers/validationRules';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { SEditAccountNameSheet } from './ManageExternalSheet.styles';

type ITransferSelectorSheetProps = {
  isOpen: boolean;
  externalAccount: IThirdParty;
  onClose: () => void;
  title: string;
};

interface INameFormValues {
  newName: string;
}

export const EditAccountNameSheet = ({ isOpen, externalAccount, onClose, title }: ITransferSelectorSheetProps) => {
  const { t } = useTranslation('moveMoney', { keyPrefix: 'manageExternalAccounts' });
  const [newNameForm] = Form.useForm();
  const [renameAccount, renameAccountResult] = accountsApi.useRenameThirdPartyMutation();
  const { isDesktopSize } = useDeviceDimension();

  const handleSubmitName = async ({ newName }: INameFormValues) => {
    await renameAccount({
      accountId: externalAccount.id,
      newName,
    }).unwrap();
    onClose();
  };

  return (
    <SEditAccountNameSheet isModal={isDesktopSize} isOpen={isOpen} header={false} modalName="transfer_editAccountNameSheet" wrapperPadding={isDesktopSize} onClose={onClose}>
      {renameAccountResult.isLoading && <Loader />}
      <div className="container">
        <div className="header">
          <Icon name="arrowLeft" color="charcoal" onClick={onClose} cursorPointer size="small" />
          <Title size="S" font="Poppins" color="charcoal" fontWeight="SB" marginLeft={15}>
            {title}
          </Title>
        </div>
        <div className="content">
          <Form autoComplete="off" layout="vertical" initialValues={{ newName: externalAccount.externalDisplayAccountName || '' }} form={newNameForm} onFinish={handleSubmitName} requiredMark={false}>
            <Form.Item
              name="newName"
              validateTrigger={['onBlur', 'onChange']}
              validateFirst
              label={t('NewAccountName')}
              rules={[getRequiredRule(t('PleaseInputNewName')), noSideWhitespacesRule() /* noSpecialCharactersRule() */]}
            >
              <BaseInput placeholder={t('WriteNewAccountName')} data-testid="newAccountNameInput" />
            </Form.Item>
            <Button preset="blue-filled" marginTop={isDesktopSize ? 32 : 48} width={isDesktopSize ? 'fit-content' : '100%'} marginLeft="auto" marginRight="auto">
              {t('SaveAccountName')}
            </Button>
          </Form>
        </div>
      </div>
    </SEditAccountNameSheet>
  );
};
