import React from 'react';
import { CustomText } from 'components/theme/CustomText/CustomText';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomCheckbox } from 'components/theme/CustomCheckbox/CustomCheckbox';
import { Button } from 'components/theme/Button/Button';
import { convertLinksToExternal } from 'utils/helpers/urlString/urlString';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SSheetFooter } from './DisclosureSheet.styles';

interface IDisclosureSheetProps {
  name?: string;
  isOpen?: boolean;
  handleCloseSheet: () => void;
  handleButtonClick?: () => void;
  handleCheckboxClick?: () => void;
  subTitle?: string;
  acceptCheckBoxText?: string;
  isChecked?: boolean;
  acceptButtonText?: string;
  errorMessage?: string;
  disclosureText?: string;
  isError?: boolean;
  isReadOnly?: boolean;
}

export const DisclosureSheet: React.FC<IDisclosureSheetProps> = ({
  name,
  isOpen,
  handleCloseSheet,
  handleButtonClick,
  handleCheckboxClick,
  subTitle,
  acceptCheckBoxText,
  isChecked,
  acceptButtonText,
  errorMessage,
  disclosureText,
  isError,
  isReadOnly,
}) => {
  const { isDesktopSize } = useDeviceDimension();
  return (
    <CustomSheet
      title={name}
      isOpen={isOpen}
      onClose={handleCloseSheet}
      subtitle={subTitle}
      isModal={isDesktopSize}
      wrapperPadding={isDesktopSize}
      modalName="disclosureSheet"
      footer={
        !isReadOnly && (
          <SSheetFooter>
            <CustomCheckbox checked={isChecked} marginBottom={25} className="white" onClick={handleCheckboxClick}>
              <CustomText size="big" textColor="charcoal70">
                {acceptCheckBoxText}
              </CustomText>
            </CustomCheckbox>

            <Button size="large" preset="blue-filled" onClick={handleButtonClick} disabled={!isChecked} noBorder>
              {acceptButtonText}
            </Button>
          </SSheetFooter>
        )
      }
    >
      <div>
        {isError && (errorMessage || 'Some error occured while fetching data')}
        {!isError && disclosureText && <div dangerouslySetInnerHTML={{ __html: convertLinksToExternal(disclosureText) }} />}
      </div>
    </CustomSheet>
  );
};
