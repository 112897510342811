import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ROUTES } from 'vars/const/ROUTES';
import { Form } from 'antd';
import { usersApi } from 'store/user/users.api';
import { useDeleteAccountsByIdMutation } from 'store/user/accounts/accounts.api';
import { SmsForm } from 'views/OpenCashAccount/MyInfo/EmailPage/SmsCodePage/SmsForm';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Title, BodyText } from 'components/general/Typography';
import { ICrumb, PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { SureCloseCashAccount } from 'views/Account/AccountInformation/CashCheckCloseAccountInformationModal/SureCloseCashAccount';
import { CashFinishCloseAccountInformationModal } from 'views/Account/AccountInformation/CashFinishCloseAccountInformationModal/CashFinishCloseAccountInformationModal';
import { SurveyModal } from 'views/Account/AccountInformation/SurveyModal/SurveyModal';
import { FailedDeleteAccount } from 'views/Account/AccountInformation/FailedDeleteAccount/FaliedDeleteAccount';
import { SLayout, SWrapper, SCentered } from './CashCheckTaxIdPage.styles';

export interface IInternalAccountBlock {
  account: IAccountItem;
  showDisclosureSheet: () => void;
  accountsDataLoading?: boolean;
}

export const CashCheckTaxIdPage = () => {
  const theme = useTheme();

  const cashFinishCloseAccountInformationModal = useToggle(false);
  const surveyModal = useToggle(false);
  const failedDeleteAccountModal = useToggle();
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('accountInformation');
  const [validateTaxId] = usersApi.useValidateTaxIdMutation();
  const [deleteAccountById, deleteAccountByIdResult] = useDeleteAccountsByIdMutation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const sureCloseCashAccount = useToggle(false);
  const [form] = Form.useForm();
  const { state } = useLocation();
  const accountId = state?.accountId;
  const hasNeedsAccount = state?.hasNeedsAccount;
  const hasGoalsAccount = state?.hasGoalsAccount;

  const onTaxIdMatch = () => {
    sureCloseCashAccount.show();
  };

  const onClickHandler = () => {
    setIsError(false);
    const taxIdNumber = form.getFieldValue('emailCode');
    validateTaxId(taxIdNumber)
      .unwrap()
      .then((res) => {
        setIsError(!res.valid);
        if (res.valid) {
          onTaxIdMatch();
        }
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const handleConfirmCashCloseCheck = () => {
    deleteAccountById(accountId);
  };

  const handleConfirmCashClosingFinally = () => {
    navigate(ROUTES.home.path);
  };

  const handleOpenSurvey = () => {
    cashFinishCloseAccountInformationModal.hide();
    surveyModal.show();
  };

  const handleSubmitSurvey = () => {
    surveyModal.hide();
    navigate(ROUTES.home.path);
  };

  useEffect(() => {
    if (deleteAccountByIdResult.isError) failedDeleteAccountModal.show();
    if (deleteAccountByIdResult.isSuccess && !isDesktopSize) cashFinishCloseAccountInformationModal.show();
    if (deleteAccountByIdResult.isSuccess && isDesktopSize)
      navigate(ROUTES.requesToCloseCashAccount.path, {
        state: {
          hasNeedsAccount,
          hasGoalsAccount,
        },
      });
  }, [deleteAccountByIdResult.isError, deleteAccountByIdResult.isSuccess]);

  const crumbs: ICrumb[] = [
    {
      name: t('accountInformation.BackToAccountInformation'),
      url: ROUTES.accountInformation.path,
    },
    {
      name: '',
    },
  ];

  return (
    <SLayout>
      <PageHeader title={t('accountInformation.IdentityVerification')} crumbs={crumbs} />
      <SCentered>
        <SWrapper>
          <Title color="charcoal" fontWeight="SB" size="S" marginBottom={28} font="Poppins">
            {t('accountInformation.ToProceedWithRequestToCloseCashAccountValidateIdentityGivingLast4DigitsTaxID')}
          </Title>

          <CustomCard className="internal-wrapper" padding="48px 24px">
            <CustomCard borderRadius={20} border={`2px solid ${theme.charcoal10}`} marginTop={0}>
              <div>
                <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal" marginBottom={30}>
                  {t('accountInformation.EnterLast4DigitsOfYourTaxID')}
                </BodyText>
                <SmsForm onCompletion={setIsSubmitDisabled} form={form} size={4} displayInlineText={false} errorMessage="myInfo.EnterYourTaxID" />
              </div>
            </CustomCard>
            {isError && (
              <BodyText textType="bodyText" size="N" fontWeight="R" font="Poppins" color="red" justifyContent="center" marginTop={12}>
                {t('accountInformation.WrongTaxId')}
              </BodyText>
            )}
          </CustomCard>
          <CustomRow justifyContent="center" alignItems="center" marginTop={24} marginBottom={24}>
            <Button preset="blue-filled" disabled={isSubmitDisabled} onClick={onClickHandler} width="120px">
              {t('accountInformation.Verify')}
            </Button>
          </CustomRow>
        </SWrapper>
      </SCentered>
      <CashFinishCloseAccountInformationModal
        isOpen={cashFinishCloseAccountInformationModal.isActive}
        onClose={handleConfirmCashClosingFinally}
        onClickHandler={handleConfirmCashClosingFinally}
        openSurvey={handleOpenSurvey}
        hasGoalsAccount={false}
        hasNeedsAccount={false}
      />
      <SurveyModal isOpen={surveyModal.isActive} onClose={surveyModal.hide} onClickHandler={handleSubmitSurvey} />
      <SureCloseCashAccount isOpen={sureCloseCashAccount.isActive} onClose={sureCloseCashAccount.hide} onClickHandler={handleConfirmCashCloseCheck} />
      <FailedDeleteAccount isOpen={failedDeleteAccountModal.isActive} onClose={failedDeleteAccountModal.hide} />
    </SLayout>
  );
};
