import React, { useState, useCallback, useEffect, memo } from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { CountriesList } from 'views/MoveMoney/InternationalTransferPage/CountriesList/CountriesList';
import allCountryFlagsData from 'views/MoveMoney/InternationalTransferPage/allCountryFlagsData.json';
import { Loader } from 'components/general/Loader/Loader';
import { ICountry, ICountryRequestParams } from 'views/MoveMoney/InternationalTransferPage/types';
import { useSetSurveyAnswerMutation } from 'store/user/users.api';
import { SCustomSheet } from './SelectYourCountrySheet.styles';

interface ISelectYourCountrySheetProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  showCountriesRequestSubmitModal: () => void;
  countryRequestParams: ICountryRequestParams;
}

export const SelectYourCountrySheet = memo(({ isOpen, onClose, onBack, showCountriesRequestSubmitModal, countryRequestParams: { surveyId, surveyQuestionId } }: ISelectYourCountrySheetProps) => {
  const { t } = useTranslation('moveMoney');
  const [setSurveyAnswer, setSurveyAnswerResult] = useSetSurveyAnswerMutation();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({} as ICountry);

  const handleSelectCountry = useCallback((country: ICountry) => {
    setSelectedCountry(country);
  }, []);

  const submitCountry = () => {
    if (surveyId && surveyQuestionId) {
      setSurveyAnswer({
        id: surveyId,
        data: {
          answers: [
            {
              surveyQuestionId,
              userAnswer: selectedCountry?.title,
            },
          ],
        },
      }).then(() => {
        onClose();
        showCountriesRequestSubmitModal();
      });
    } else {
      console.log('countryRequestParams = ', { surveyId, surveyQuestionId });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedCountry({} as ICountry);
    }
  }, [isOpen]);

  return (
    <SCustomSheet className="select-your-country-sheet" isOpen={isOpen} onClose={onClose} height="95%" maxHeight="95%" paddingTop="34px" paddingBottom="56px">
      {setSurveyAnswerResult.isLoading && <Loader />}

      <div className="header">
        <Icon name="arrowLeft" color="charcoal" onClick={onBack} cursorPointer size="small" marginRight={12} />
        <Title>{t('internationalTransferPage.SelectYourCountryRequest')}</Title>
      </div>

      <BodyText size="N" fontWeight="R" color="charcoal70" lineHeight={1.4} marginBottom={32}>
        {t('internationalTransferPage.SelectTheCountryYouWouldLikeToSee')}
      </BodyText>

      {isOpen && <CountriesList onSelect={handleSelectCountry} countries={allCountryFlagsData} isOnlyMainLanguage />}

      <Button preset="blue-filled" size="middleAlt" disabled={!selectedCountry.code} onClick={submitCountry}>
        {t('internationalTransferPage.SubmitRequest')}
      </Button>
    </SCustomSheet>
  );
});
