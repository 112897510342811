import React, { useEffect, useMemo, useState } from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Consent } from 'components/general/Consent/Consent';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { useConsents } from 'utils/hooks/useConsents';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Button } from 'components/theme/Button/Button';
import { useToggle } from 'utils/hooks/useToggle';
import { MakeJointCashAccountModal } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MakeJointCashAccountModal/MakeJointCashAccountModal';
import { Loader } from 'components/general/Loader/Loader';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { useSelector } from 'react-redux';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import { MakeJointCashAccountWebModal } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MakeJointCashAccountModal/MakeJointCashAccountWebModal';
import { SLayout, SPageLayout } from './Disclosures.styles';

export const Disclosures = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { state } = useLocation();
  const { accounts } = useSelector(selectCurrentUser);
  const { isDesktopSize, isMobileSmallSize, currentWindowSize } = useDeviceDimension();
  const { saveOnboardingData } = useCashAccountOpening();
  const { consentsData } = useConsents(ConsentType.ACCOUNT_OPENING);
  const { consentsData: openingDisclosureData } = useConsents(ConsentType.SUTTON);
  const DepositAccountAgreementData = consentsData?.find((c) => c.id === ConsentId.DEPOSIT_ACCOUNT_AGREEMENT);

  const makeCashAccountModal = useToggle();
  const makeCashAccountWebModal = useToggle();
  const disclosureSheet = useToggle();
  const { consentsData: accountDetailsData, getConsentByFlowNameResult: accountDetailsDataResult } = useConsents(ConsentType.ACCOUNT_OPENING_DETAILS);
  const EsignConsentData = useMemo(() => accountDetailsData?.find((c) => c.id === ConsentId.ESIGN_CONSENT), [accountDetailsData]);
  const PercapitaTermsOfUseData = useMemo(() => accountDetailsData?.find((c) => c.id === ConsentId.PERCAPITA_TERMS_OF_USE), [accountDetailsData]);
  const PercapitaPrivacyPolicyData = useMemo(() => accountDetailsData?.find((c) => c.id === ConsentId.PERCAPITA_PRIVACY_POLICY), [accountDetailsData]);

  /* Secondary account */
  const isSecondaryCash = useMemo(() => (accounts?.length ? accounts?.length > 0 : false), [accounts]);
  const { consentsData: secondaryAccountDetailsData, getConsentByFlowNameResult: secondaryAccountDetailsDataResult } = useConsents(ConsentType.SECOND_ACCOUNT_OPENING_DETAILS);
  const SecondaryDepositAccountAgreementData = useMemo(() => secondaryAccountDetailsData?.find((c) => c.id === ConsentId.SECONDARY_DEPOSIT_ACCOUNT_AGREEMENT), [secondaryAccountDetailsData]);
  const SecondaryPercapitaTermsOfUseData = useMemo(() => secondaryAccountDetailsData?.find((c) => c.id === ConsentId.SECONDARY_PERCAPITA_TERMS_OF_USE), [secondaryAccountDetailsData]);
  const SecondaryPercapitaPrivacyPolicyData = useMemo(() => secondaryAccountDetailsData?.find((c) => c.id === ConsentId.SECONDARY_PERCAPITA_PRIVACY_POLICY), [secondaryAccountDetailsData]);

  const { t } = useTranslation(['cashAccount', 'cashAccountOpening']);
  const navigate = useNavigate();

  const handleOnYes = () => navigate(ROUTES.myAccountJointAccounts.path, { state: { isAccountSetup: true, isFromMyDetailsPage: true, fromPage: state?.fromPage, alreadyHasCash: isSecondaryCash } });
  const handleOnNo = () => navigate(ROUTES.openNewCash.path, { state: { fromPage: state?.fromPage } });
  const handleOnContinue = () => {
    saveOnboardingData({ currentUrl: ROUTES.myInfoDisclosures.path });
    if (isSecondaryCash) {
      if (isDesktopSize) {
        makeCashAccountWebModal.show();
      } else {
        makeCashAccountModal.show();
      }
    } else {
      navigate(ROUTES.myInfoName.path);
    }
  };

  useEffect(() => {
    if (isSecondaryCash) {
      setIsDisabled(!SecondaryDepositAccountAgreementData?.accepted);
    } else {
      setIsDisabled(!EsignConsentData?.accepted || !DepositAccountAgreementData?.accepted);
    }
  }, [EsignConsentData?.accepted, DepositAccountAgreementData?.accepted, SecondaryDepositAccountAgreementData?.accepted, isSecondaryCash]);

  const isConsentSheet = useMemo(() => !isDesktopSize || currentWindowSize === SCREEN_SIZE.tablet, [currentWindowSize, isDesktopSize]);

  if (accountDetailsDataResult.isFetching || secondaryAccountDetailsDataResult.isFetching) return <Loader />;

  return (
    <SPageLayout>
      <Header title={t('myInfo.Disclosures')} stage="eConsent" webStage="Disclosures" marginTop={16} />
      <SLayout>
        <div className="content-container">
          <div className="mobile-title-container">
            <BodyText textType="bodyText" size="N" color="charcoal50" fontWeight="R" textAlign="start" paddingTop={20}>
              {t('cashAccount.ReviewAgreeDisclosure')}
            </BodyText>
          </div>
          <div className="web-title-container">
            <Title>{t('myInfo.Disclosures')}</Title>
            <BodyText textType="bodyText" size="L" color="charcoal70" fontWeight="R" textAlign="start" marginTop={8}>
              {t('cashAccount.ReviewAgreeDisclosure')}
            </BodyText>
          </div>

          <div className="disclosure-cards">
            {isSecondaryCash && SecondaryDepositAccountAgreementData ? (
              <Consent isSheet={isConsentSheet} consentData={SecondaryDepositAccountAgreementData} flowName={ConsentType.SECOND_ACCOUNT_OPENING_DETAILS} />
            ) : (
              <>
                {DepositAccountAgreementData && <Consent isSheet={isConsentSheet} consentData={DepositAccountAgreementData} flowName={ConsentType.ACCOUNT_OPENING} />}
                {EsignConsentData && <Consent isSheet={isConsentSheet} consentData={EsignConsentData} flowName={ConsentType.ACCOUNT_OPENING_DETAILS} />}
              </>
            )}
          </div>

          <div className="disclosure-links">
            {isSecondaryCash && SecondaryPercapitaPrivacyPolicyData && SecondaryPercapitaTermsOfUseData ? (
              <>
                <Consent isSheet={isConsentSheet} consentData={SecondaryPercapitaTermsOfUseData} flowName={ConsentType.SECOND_ACCOUNT_OPENING_DETAILS} isLink isReadonly />
                <Consent isSheet={isConsentSheet} consentData={SecondaryPercapitaPrivacyPolicyData} flowName={ConsentType.SECOND_ACCOUNT_OPENING_DETAILS} isLink isReadonly />
              </>
            ) : (
              <>
                {PercapitaTermsOfUseData && <Consent isSheet={isConsentSheet} consentData={PercapitaTermsOfUseData} flowName={ConsentType.ACCOUNT_OPENING_DETAILS} isLink isReadonly />}
                {PercapitaPrivacyPolicyData && <Consent isSheet={isConsentSheet} consentData={PercapitaPrivacyPolicyData} flowName={ConsentType.ACCOUNT_OPENING_DETAILS} isLink isReadonly />}
              </>
            )}
          </div>

          <BodyText
            textType="bodyText"
            size={isMobileSmallSize ? 'T' : 'N'}
            color={isDesktopSize ? 'charcoal70' : 'charcoal50'}
            fontWeight="R"
            textAlign="start"
            className="disclosures-available"
            marginTop={10}
            marginBottom={10}
            paddingRight={8}
          >
            {t('cashAccount.DisclosureAvailable')}
          </BodyText>
        </div>
        <Footer isDisabled={isDisabled} onClick={handleOnContinue} isBackVisible={false} />
        <div className="mobile-footer">
          <Button size="large" preset="blue-filled" marginBottom={24} disabled={isDisabled} onClick={handleOnContinue}>
            {t('common.Continue')}
          </Button>
          {!isSecondaryCash && (
            <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" marginBottom={16} className="next-step" justifyContent="end">
              {t('accountOpening.NextStep')} {t('step.Name', { ns: 'cashAccountOpening' })}
            </BodyText>
          )}
        </div>

        <MakeJointCashAccountModal
          open={makeCashAccountModal.isActive}
          onClose={makeCashAccountModal.hide}
          showDisclosureSheet={disclosureSheet.show}
          handleOnNo={handleOnNo}
          handleOnYes={handleOnYes}
          isSecondaryCash={isSecondaryCash}
        />
        <MakeJointCashAccountWebModal
          open={makeCashAccountWebModal.isActive}
          onClose={makeCashAccountWebModal.hide}
          showDisclosureSheet={disclosureSheet.show}
          handleOnNo={handleOnNo}
          handleOnYes={handleOnYes}
        />
        {openingDisclosureData?.map((disclosure) => (
          <ConsentSheet key={disclosure.id} isSheet={isConsentSheet} consentData={disclosure} isOpen={disclosureSheet.isActive} onClose={disclosureSheet.hide} isReadonly />
        ))}
      </SLayout>
    </SPageLayout>
  );
};
