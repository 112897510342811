import { BodyText, Title } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { INotificationItem } from 'store/user/notificationsCenter/notificationsCenter.types';
import { useLazyGetNotificationByIdQuery, useLazyGetNotificationsQuery } from 'store/user/users.api';
import { Icon } from 'components/general/Icon/Icon';
import { formatInTimeZone } from 'date-fns-tz';
import { format, parseISO } from 'date-fns';
import { Loader } from 'components/general/Loader/Loader';
import { useToggle } from 'utils/hooks/useToggle';
import { removeBaseTag } from 'utils/helpers/urlString/urlString';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Tooltip } from 'antd';
import { SCustomCard, SHeader } from './ItemCard/ItemCard.styles';
import { SettingsModal } from './SettingsModal/SettingsModal';
import { DeleteModal } from './DeleteModal/DeleteModal';
import { SettingsCard } from './SettingsCard/SettingsCard';
import { SWrapper } from './SettingsCard/SettingsCard.styles';

const alertMap = {
  security: '3EC55B87-4D10-4E96-9BB0-0C1C76EC1B9E',
  balance: '99784479-CC78-494F-9D6C-698526D05FBD',
};

const getIcon = (typeId: string) => {
  return typeId === alertMap.security ? <Icon name="securityIcon" size="normal" cursorPointer /> : <Icon name="creditCard" size="normal" cursorPointer />;
};

export const NotificationItemPage = () => {
  const { t } = useTranslation('notificationsCenter');
  const navigate = useNavigate();
  const { id } = useParams();
  const [getNotificationById, getNotificationByIdResult] = useLazyGetNotificationByIdQuery();
  const [getNotifications, getNotificationsResult] = useLazyGetNotificationsQuery();
  const [item, setItem] = useState<INotificationItem | null>(null);

  const { isDesktopSize, isTabletSize } = useDeviceDimension();
  const formattedHtml = item ? { __html: removeBaseTag(item?.htmlMessage) } : { __html: '' };
  const settingsModal = useToggle();
  const deleteModal = useToggle();
  const settingsCard = useToggle();

  /* Time zone */
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = item?.dateSent && format(new Date(item?.dateSent), 'PP');
  const time = item?.dateSent && formatInTimeZone(parseISO(`${item.dateSent}Z`), timeZone, 'h:mm');

  const handleClickDots = () => {
    if (isDesktopSize) {
      settingsCard.show();
    } else {
      settingsModal.show();
    }
  };

  useEffect(() => {
    if (id) {
      getNotificationById(id)
        .unwrap()
        .then((res) => {
          // @ts-ignore
          setItem(res[0]);
          getNotifications(false);
        });
    }
  }, []);

  const handleRefresh = () => {
    getNotifications();
  };

  return (
    <>
      {(getNotificationByIdResult.isFetching || getNotificationsResult.isFetching) && <Loader />}
      <SHeader>
        <div className="title">
          <Icon name="arrowLeft" cursorPointer color="blue" onClick={() => navigate(-1)} className="back" />
          <Title font="Poppins" fontWeight="SB" size="M" color="charcoal">
            {t('notificationsCenter.NotificationsCenter')}
          </Title>
        </div>
        <div className="breadcrumbs">
          <span className="parent">{t('notificationsCenter.NotificationsCenter')}</span>
          <Icon name="arrowRight" cursorPointer color="charcoal70" size="smaller" />
          <span className="subject">{item?.subject}</span>
        </div>
      </SHeader>

      <SCustomCard className="card">
        <CustomRow>
          <CustomRow>
            <span className={`icon-pad ${item?.alertCategoryId === alertMap.security ? 'securityIcon' : 'balance'}`}>{item && getIcon(item?.alertCategoryId)}</span>
            <CustomRow flexDirection="column" paddingLeft={8} paddingRight={12} alignItems="flex-start">
              <BodyText textType="bodyText" size="N" fontWeight="SB" color="charcoal" lineHeight="20px" marginBottom={4}>
                {item?.subject}
              </BodyText>
              <BodyText textType="bodyText" size="T" fontWeight="M" color="charcoal70" className="time" lineHeight="16px" marginBottom={4}>
                {time}, {date}
              </BodyText>
            </CustomRow>
          </CustomRow>

          <SWrapper>
            <Tooltip
              showArrow={false}
              open={settingsCard.isActive}
              destroyTooltipOnHide
              trigger="click"
              onOpenChange={settingsCard.isActive ? settingsCard.hide : settingsCard.show}
              overlayInnerStyle={{
                background: 'none',
                boxShadow: 'none',
                width: '368px',
                position: 'relative',
                right: isTabletSize ? '70%' : '95%',
              }}
              title={<SettingsCard notification={item} onClose={settingsCard.hide} openDeleteModal={deleteModal.show} refresh={handleRefresh} />}
            >
              <span className="dots">
                <Icon name="dotsMenuThin" size="normal" color="charcoal70" onClick={handleClickDots} cursorPointer />
              </span>
            </Tooltip>
          </SWrapper>
        </CustomRow>
        <CustomRow marginTop={32}>{item?.htmlMessage ? <div dangerouslySetInnerHTML={formattedHtml as { __html: string }} /> : <div>{item?.plainMessage}</div>}</CustomRow>
      </SCustomCard>

      <SettingsModal
        open={settingsModal.isActive}
        onClose={settingsModal.hide}
        openDeleteModal={deleteModal.show}
        status={item?.status}
        alertHistoryId={item?.alertHistoryId || ''}
        refresh={handleRefresh}
      />
      <DeleteModal open={deleteModal.isActive} onClose={deleteModal.hide} alertHistoryId={item?.alertHistoryId || ''} />
    </>
  );
};
