import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { ROUTES } from 'vars/const/ROUTES';
import { BodyText, Title } from 'components/general/Typography';
import { useGetSurveyQuery } from 'store/user/users.api';
import { DEFAULT_SURVEY_ID } from 'views/MoveMoney/InternationalTransferPage/constants';
import { Loader } from 'components/general/Loader/Loader';
import { CountriesRequestSubmitModal } from 'views/MoveMoney/InternationalTransferPage/CountriesRequestSubmitModal/CountriesRequestSubmitModal';
import { useToggle } from 'utils/hooks/useToggle';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { SCountryRequestPage } from './CountryRequestPage.styles';
import { SelectCountryRequestBlock } from './SelectYourCountryRequest/SelectCountryRequestBlock';

export const CountryRequestPage = () => {
  const { t } = useTranslation('moveMoney');
  const countriesRequestSubmitModal = useToggle(false);
  const { data: surveyData, isLoading, isFetching } = useGetSurveyQuery(DEFAULT_SURVEY_ID);

  return (
    <>
      <SCountryRequestPage>
        {(isLoading || isFetching) && <Loader />}

        <PageHeader
          title={t('countryRequestPage.CountryRequest')}
          crumbs={[
            { name: t('InternationalTransfer'), url: ROUTES.internationalTransfer.path },
            { name: '', url: '' },
          ]}
        />

        <div className="body">
          <div className="body-header">
            <Title textAlign="center" size="L" fontWeight="M" marginBottom={24}>
              {surveyData?.title}
            </Title>

            <BodyText size="N" color="charcoal70" lineHeight={1.4}>
              {surveyData?.text}
            </BodyText>
          </div>

          <SelectCountryRequestBlock surveyId={DEFAULT_SURVEY_ID} surveyQuestionId={surveyData?.surveyQuestions?.[0]?.id ?? ''} showRequestSubmitModal={countriesRequestSubmitModal.show} />
        </div>

        <div className="footer">
          <SuttonDisclaimerNote />
        </div>
      </SCountryRequestPage>

      <CountriesRequestSubmitModal isOpen={countriesRequestSubmitModal.isActive} onClose={countriesRequestSubmitModal.hide} handleContinue={countriesRequestSubmitModal.hide} isDesktop />
    </>
  );
};
