import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'vars/const/ROUTES';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { selectCurrentUser, setReferralCode } from 'store/user/authentication.slice';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { ReferralCodeModal } from './ReferralCodeModal/ReferralCodeModal';
import { CashAccountOpeningPage } from './CashAccountOpeningPage';
import { CashAccOpeningDesktopPage } from './CashAccOpeningDesktopPage/CashAccOpeningDesktopPage';

interface IStarterPageProps {
  ignoreDimension?: boolean;
}

export const StarterPage: React.FC<IStarterPageProps> = ({ ignoreDimension = false }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isDesktopSize } = useDeviceDimension();
  const { isActive, show, hide } = useToggle(false);
  const referralCodeModal = useToggle();
  const { saveOnboardingData } = useCashAccountOpening();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  const { referralCode } = useSelector(selectCurrentUser);
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const isSecondaryCash = state?.alreadyHasCash;

  const handleContinueClick = () => {
    saveOnboardingData?.({ referralCode: '' });
    navigate(ROUTES.openAccountPrep.path);
  };

  const handleOnYes = () => {
    dispatch(setReferralCode(code));
    saveOnboardingData?.({ referralCode: code });
    navigate(ROUTES.openAccountPrep.path);
  };

  const onContinueClick = () => {
    if (isSecondaryCash || referralCode) {
      navigate(ROUTES.myInfoDisclosures.path, { state: { alreadyHasCash: true, fromPage: state?.fromPage } });
    } else {
      referralCodeModal.show();
    }
  };

  const handleCompletion = (codeStr: string) => {
    setCode(codeStr);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      {isDesktopSize && !ignoreDimension ? (
        <CashAccOpeningDesktopPage onContinueClick={onContinueClick} onFeeScheduleClick={show} isActive={isActive} hide={hide} />
      ) : (
        <CashAccountOpeningPage handleContinueClick={onContinueClick} handleFeeScheduleClick={show} isActive={isActive} hide={hide} isStarterPage />
      )}
      <ReferralCodeModal open={referralCodeModal.isActive} handleCompletion={handleCompletion} onClose={referralCodeModal.hide} handleOnNo={handleContinueClick} handleOnYes={handleOnYes} />
    </>
  );
};
