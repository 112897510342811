import { Icon } from 'components/general/Icon/Icon';
import { Title } from 'components/general/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { STitleHeader } from './WebHeader.styles';

interface IWebHeader {
  isBackVisible?: boolean;
}

export const WebHeader: React.FC<IWebHeader> = ({ isBackVisible = true }) => {
  const { t } = useTranslation('percapitaPay');
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <STitleHeader className="web-header">
      {isBackVisible && <Icon name="arrowLeft" color="blue" cursorPointer onClick={handleBack} />}
      <Title size="S" fontWeight="SB" justifyContent="start" className="web-header-title">
        {t('percapitaPayHome.Percapita Pay')}
      </Title>
    </STitleHeader>
  );
};
