import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { IconSign } from 'components/general/Icon/IconSign';
import { TIconName } from 'components/general/Icon/Icon.types';
import { SListItemLayout } from 'views/PercapitaPay/History/HistoryPage.styles';

interface IListItem {
  amount: number;
  fee: number;
  accountNickName: string;
  accountType: string;
  icon: TIconName;
  first?: boolean;
  last?: boolean;
  additionalData: any;
}

export const ListItem: React.FC<IListItem> = ({ icon, accountNickName, accountType, additionalData, amount, first, last, fee }) => {
  const { t } = useTranslation('percapitaPay');
  const getAttempts = () => {
    const fromNum = additionalData.attempt.slice(0, 1);
    const toNum = additionalData.attempt.slice(2);

    return `Attempt ${fromNum} of ${toNum}`;
  };

  const fullAmount: number = amount + fee;

  return (
    <SListItemLayout marginTop={first ? 0 : 20} marginBottom={last ? 0 : 20} flexDirection="row" flexWrap="wrap" justifyContent="flex-start" flex={1} alignItems="center">
      <CustomRow marginRight={14}>
        <IconSign iconName={icon} size={40} bgColor="charcoal5" iconColor="charcoal70" />
      </CustomRow>
      <CustomRow flex={1} marginRight={14}>
        {accountType !== 'REPROCESS' ? (
          <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="N">
            {accountNickName}
          </BodyText>
        ) : (
          <CustomRow flexDirection="column" alignItems="flex-start">
            <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="N">
              {t(`percapitaPayHome.Deduction Reprocess`)}
            </BodyText>
            <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="N">
              {getAttempts()}
            </BodyText>
          </CustomRow>
        )}
      </CustomRow>
      <CustomAmount amount={amount} size="smallerStrong" multiSizable remainingSize="smallerStrong" remainingWeight={600} />
      <CustomRow flex="1 1 100%" justifyContent="flex-end">
        {accountType !== 'REPROCESS' && (
          <BodyText textType="helperText" color="charcoal40" size="N" fontWeight="R">
            <Trans
              i18nKey="percapitaPayHome.full amount request - fee"
              ns="percapitaPay"
              values={{
                fullAmount,
                fee,
              }}
            >
              $({amount + fee} request - {fee} fee)
            </Trans>
          </BodyText>
        )}
      </CustomRow>
    </SListItemLayout>
  );
};
