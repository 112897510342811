import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useNavigate } from 'react-router-dom';
import { lsGetItem } from 'utils/helpers/storage';
import { mobileApiCall } from 'services/mobileService';
import { ROUTES } from 'vars/const/ROUTES';

interface IModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const PreprintedModal = ({ isOpen, handleClose }: IModalProps) => {
  const { t } = useTranslation('moveMoney', { keyPrefix: 'mobileCheckCapture' });
  const navigate = useNavigate();
  const isMobileApp = lsGetItem('isMobileApp');

  const handleCheck = () => {
    handleClose();
    if (isMobileApp) {
      mobileApiCall('openCheckCashing');
    } else {
      navigate(ROUTES.addDebitCard.path);
    }
    handleClose();
  };
  return (
    <CustomModal
      open={isOpen}
      onCancel={handleClose}
      closeIcon={<Icon name="close" size="smaller" color="charcoal70" marginTop={16} marginLeft={16} />}
      modalName="mobileCheckCapture_preprintedModal"
      topPosition="0"
    >
      <Title size="M" fontWeight="SB" color="charcoal" marginBottom={16} className="add-account-title">
        {t('HavePrePrintedPayroll')}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={16} lineHeight={1.6}>
        {t('PercapitaWillPay')}
      </BodyText>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={16} lineHeight={1.6}>
        {t('YouMayNotice')}
      </BodyText>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={32} lineHeight={1.6}>
        {t('WeAppreciateYourPatience')}
      </BodyText>

      <Button preset="blue-filled" marginBottom={8} onClick={handleCheck}>
        {t('Continue')}
      </Button>
    </CustomModal>
  );
};
