import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'components/layouts/Page/Page';
import { SAddExternalAccountPage } from './AddExternalAccountsPage.styles';
import { AddExternalAccountBlock } from './AddExternalAccountBlock';

export const AddExternalAccountPage = () => {
  const [searchParams] = useSearchParams();
  const disabledAddCards = searchParams.get('disableAddCards') === 'true';

  return (
    <SAddExternalAccountPage>
      <Page.Content>
        <AddExternalAccountBlock disabledAddCards={disabledAddCards} />
      </Page.Content>
    </SAddExternalAccountPage>
  );
};
