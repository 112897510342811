import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCircle = styled.div`
  width: 40px;
  height: 40px;
  background: ${getColor('blue5')};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
`;
