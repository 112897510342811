import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'utils/hooks/useLanguage';
import { ROUTES } from 'vars/const/ROUTES';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Button } from 'components/theme/Button/Button';
import { SInitialPage } from './LanguageSelectionPage.styles';

export const LanguageSelectionPage = () => {
  const { t } = useTranslation('preRegOnboarding');
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const { changeLanguage } = useLanguage();

  const handleLanguageChange = (languageKey: string) => {
    changeLanguage(languageKey).then(() => navigate(ROUTES.onboardingHome.path));
  };

  return (
    <SInitialPage>
      <div className="logo" />

      <div className="content">
        <div className="text-container">
          {isDesktopSize ? (
            <>
              <Title color="white" size="XXL" fontWeight="M" justifyContent="center" textAlign="center" marginBottom={16}>
                {t('preRegOnboarding.ChooseYourLanguage')}
              </Title>
              <Title color="blue20" font="DM Sans" size="S" fontWeight="M" justifyContent="center" textAlign="center">
                {t("preRegOnboarding.We're so glad you're here")}
              </Title>
            </>
          ) : (
            <BodyText textType="bodyText" color="white" font="Poppins" size="M" fontWeight="SB" justifyContent="center" textAlign="center">
              {t('preRegOnboarding.ChooseYourLanguage')}
            </BodyText>
          )}
        </div>

        <div className="language-buttons">
          <Button preset="white-filled" onClick={() => handleLanguageChange('en')}>
            English
          </Button>
          <Button preset="white-filled" onClick={() => handleLanguageChange('es')}>
            Español
          </Button>
        </div>
      </div>
    </SInitialPage>
  );
};
