import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { isAndroid } from 'react-device-detect';
import { dateOfBirthFormatter } from 'utils/helpers/date';
import { taxFormatter } from 'utils/helpers/taxFormatter';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { ROUTES } from 'vars/const/ROUTES';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { useTranslation } from 'react-i18next';
import { useKYC } from 'utils/hooks/useKYC';
import { useLazyGetPlaidVerificationStatusQuery } from 'store/user/users.api';
import { ITruliooValidateUserRequest } from 'store/trulioo/trulioo.types';
import { EKycPartStatus } from 'vars/types/authentication.types';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { lsGetItem } from 'utils/helpers/storage';
import { implodeString } from 'utils/helpers/string';
import { Loader } from 'components/general/Loader/Loader';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { mobileApiCall } from 'services/mobileService';
import { DataItem } from './DataItem/DataItem';
import { SCAOSummaryPage } from './SummaryPage.styles';
import { EPlaidVerificationType } from './identityValidation.types';

type TPlaidVerificationType = `${EPlaidVerificationType}`;

enum EVerificationStatus {
  PII_FAILURE = 'piiFailure',
  UNABLE = 'unable',
  INVALID = 'invalid',
  DOCUMENT_FAILURE = 'documentFailure',
  TRY_AGAIN = 'tryAgain',
}

const MAX_ATTEMPTS_EXCEEDED_ERROR = 2259;

export const SummaryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('cashAccountOpening');
  const isMobileApp = lsGetItem('isMobileApp');
  const [searchParams] = useSearchParams();
  const { primaryPhone: phone, email: currentUserEmail } = useSelector(selectCurrentUser) || {};
  const { doc, invalidateKycData, isLoading: isKYCDataLoading } = useKYC();
  const policies = useSelector(selectPolicies);
  const { validateUserWithTrulioo, truliooValidationStatus, openingAccountData, saveOnboardingData, validateUserWithPlaid, validateUserWithPlaidResult } = useCashAccountOpening();
  const [getPlaidVerificationStatus, getPlaidVerificationStatusResult] = useLazyGetPlaidVerificationStatusQuery({
    pollingInterval: 2000,
  });
  const [isPlaidVerificationPending, setPlaidVerificationPending] = useState(false);
  const plaidWindowRef = useRef<Window | null>(null);

  const {
    firstName,
    suffix,
    middleName,
    lastName,
    dateOfBirth,
    address,
    address2,
    city,
    stateProvince,
    zipCode,
    taxId,
    mailingCity,
    mailingState,
    mailingPostalCode,
    mailingAddress1,
    mailingAddress2,
    isMailingAddressTheSame,
  } = openingAccountData;

  const email = openingAccountData?.email || currentUserEmail;
  const fullName = `${suffix ? `${suffix} ` : ''}${firstName} ${middleName} ${lastName}`;
  const fullAddress = implodeString([address, address2, city, `${stateProvince} ${zipCode}`]);
  const mailingAddress = implodeString([mailingAddress1, mailingAddress2, mailingCity, `${mailingState} ${mailingPostalCode}`]);
  const taxtIdStr = taxFormatter(taxId);
  const predefinedPlaidVerificationType = searchParams.get('plaid-v-type') as TPlaidVerificationType;
  const [plaidVerificationType, setPlaidVerificationType] = useState<TPlaidVerificationType>(predefinedPlaidVerificationType || EPlaidVerificationType.PII);
  const formattedDateOfBirthArray = dateOfBirthFormatter(dateOfBirth || '');
  const isLoading = truliooValidationStatus?.isLoading || validateUserWithPlaidResult?.isLoading || getPlaidVerificationStatusResult.isLoading || isPlaidVerificationPending || isKYCDataLoading;

  const dataToValidate = {
    firstName,
    middleName,
    lastName,
    dayOfBirth: formattedDateOfBirthArray[1],
    monthOfBirth: formattedDateOfBirthArray[0],
    yearOfBirth: formattedDateOfBirthArray[2],
    city,
    state: stateProvince,
    postalCode: zipCode,
    address1: address,
    address2,
    phone,
    id: taxId,
    email,
    // It's SocialService value for any type of tax id
    idType: 'SocialService',
    mailingCity,
    mailingState,
    mailingPostalCode,
    mailingAddress1,
    mailingAddress2,
    isMailingAddressTheSame,
  };

  const hasAllData = !!(
    dataToValidate.firstName &&
    dataToValidate.lastName &&
    dataToValidate.dayOfBirth &&
    dataToValidate.monthOfBirth &&
    dataToValidate.yearOfBirth &&
    dataToValidate.address1 &&
    dataToValidate.city &&
    dataToValidate.state &&
    dataToValidate.postalCode &&
    dataToValidate.id &&
    dataToValidate.email
  );

  const verifyDocumentWithPlaid = () => {
    if (plaidVerificationType === EPlaidVerificationType.DOCUMENT) {
      console.log('Plaid document verification');

      if (!doc.attemptsLeft) {
        navigate(ROUTES.myInfoVeriticationResult.path, {
          state: {
            verificationStatus: EVerificationStatus.DOCUMENT_FAILURE,
          },
        });
      }

      validateUserWithPlaid?.({ credentials: dataToValidate, userVerificationType: plaidVerificationType }).then((res: any) => {
        if (isMobileApp) {
          const browserData = {
            url: res.data.shareUrl,
            inApp: true, // isIOS - false, isAndroid - true
            redirectURL: ROUTES.home.path,
          };
          mobileApiCall('showFullBrowser', JSON.stringify(browserData));
        } else {
          plaidWindowRef.current = window.open(res.data.shareUrl, '_blank');
        }
        setPlaidVerificationPending(true);
        getPlaidVerificationStatus(res.data.identityVerificationId);
      });
    }
  };

  const handlePIIVerificationError = (err: any) => {
    console.log('PII Verification Error', `${err.data?.Code}: ${err.data?.Error ?? ''}`);
    invalidateKycData();
    saveOnboardingData({ currentUrl: ROUTES.myInfoVeriticationResult.path });
    navigate(ROUTES.myInfoVeriticationResult.path, {
      state: { verificationStatus: err?.data?.Code === MAX_ATTEMPTS_EXCEEDED_ERROR ? EVerificationStatus.PII_FAILURE : EVerificationStatus.UNABLE },
    });
  };

  const onSubmit = () => {
    if (hasAllData) {
      if (policies?.PlaidIVEnabled) {
        console.log('Plaid Identity Verification');
        validateUserWithPlaid?.({ credentials: dataToValidate, userVerificationType: plaidVerificationType });
      } else {
        console.log('Trulioo Verification');
        validateUserWithTrulioo?.(dataToValidate as ITruliooValidateUserRequest);
      }
    }
  };

  // Trulioo verification result monitoring
  useEffect(() => {
    if (truliooValidationStatus?.isSuccess) {
      if (truliooValidationStatus?.data?.status?.toLowerCase() === EKycPartStatus.NO_MATCH.toLowerCase()) {
        saveOnboardingData({ currentUrl: ROUTES.myInfoVeriticationResult.path });
        navigate(ROUTES.myInfoVeriticationResult.path, { state: { verificationStatus: EVerificationStatus.INVALID } });
      } else {
        saveOnboardingData({ currentUrl: ROUTES.myId.path });
        navigate(ROUTES.myId.path);
      }
    }
    if (truliooValidationStatus?.isError) {
      handlePIIVerificationError(truliooValidationStatus?.error);
    }
  }, [truliooValidationStatus?.isSuccess, truliooValidationStatus?.isError]);

  // Plaid PII verification effect
  useEffect(() => {
    if (validateUserWithPlaidResult.isSuccess && plaidVerificationType === EPlaidVerificationType.PII) {
      if (validateUserWithPlaidResult.data.status?.toLowerCase() === EKycPartStatus.NO_MATCH.toLowerCase()) {
        saveOnboardingData({ currentUrl: ROUTES.myInfoVeriticationResult.path });
        navigate(ROUTES.myInfoVeriticationResult.path, { state: { verificationStatus: EVerificationStatus.INVALID } });
      } else {
        setPlaidVerificationType(EPlaidVerificationType.DOCUMENT);
      }
    }

    if (validateUserWithPlaidResult?.isError && plaidVerificationType === EPlaidVerificationType.PII) {
      handlePIIVerificationError(validateUserWithPlaidResult?.error);
    }
  }, [validateUserWithPlaidResult.isSuccess, validateUserWithPlaidResult.isError]);

  // Plaid document verification start
  useEffect(() => {
    if (plaidVerificationType === EPlaidVerificationType.DOCUMENT && policies?.PlaidIVEnabled) {
      verifyDocumentWithPlaid();
    }
  }, [plaidVerificationType]);

  // Waiting for Plaid document verification status
  useEffect(() => {
    const verificationStatus = getPlaidVerificationStatusResult?.data?.status?.toLowerCase();
    console.log(`Plaid document verification status: ${getPlaidVerificationStatusResult?.data?.status}`);

    if (getPlaidVerificationStatusResult.isSuccess) {
      if (verificationStatus === EKycPartStatus.PENDING.toLowerCase()) {
        return;
      }

      if (verificationStatus === EKycPartStatus.MATCH.toLowerCase()) {
        navigate(ROUTES.myIdMatch.path);
      } else {
        invalidateKycData();
        navigate(ROUTES.myInfoVeriticationResult.path, {
          state: {
            verificationStatus: doc.attemptsLeft - 1 > 0 ? EVerificationStatus.TRY_AGAIN : EVerificationStatus.DOCUMENT_FAILURE,
          },
        });
      }
      mobileApiCall('closeFullBrowser');
      plaidWindowRef.current?.close();
      setPlaidVerificationPending(false);
    }
  }, [getPlaidVerificationStatusResult?.data?.status, getPlaidVerificationStatusResult.isSuccess]);

  return (
    <SCAOSummaryPage className="cao-summary-page">
      {isLoading && <Loader />}
      <div className="top-part">
        <Header title={t('myInfo.LetsDoubleCheckYourInformation')} stage="Verification" webStage="Details" marginBottom={28} />
        <div className="content-container">
          <div className="web-title-container">
            <Title marginBottom={32} size="S">
              {t('myInfo.LetsDoubleCheckYourInformation')}
            </Title>
          </div>

          <div className="data-card">
            <DataItem name="myInfo.Name" value={fullName} editUrl={ROUTES.myInfoName.path} />
            <DataItem name="myInfo.HomeAddress" value={fullAddress || ''} editUrl={ROUTES.myInfoHomeAddress.path} />
            <DataItem name="myInfo.MailingAddress" value={isMailingAddressTheSame ? `${t('myInfo.TheSameAddress')}` : mailingAddress} editUrl={ROUTES.myInfoHomeAddress.path} />
            <DataItem name="myInfo.Email" value={email} editUrl={ROUTES.myInfoEmailUsername.path} />
            <DataItem name="myInfo.DateOfBirth" value={dateOfBirth} editUrl={ROUTES.myInfoAge.path} />
            <DataItem name="myInfo.TaxID" value={taxtIdStr} editUrl={ROUTES.myInfoTaxId.path} />
          </div>

          <div className="description-container">
            <BodyText justifyContent="start" textType="bodyText" size="N" fontWeight="R" color="charcoal70" textAlign="start" marginTop={24}>
              {t('myInfo.ItMayTakeAFewMinutes')}
            </BodyText>
          </div>
        </div>
      </div>

      <div className="mobile-footer">
        <Button size="large" preset="blue-filled" type="submit" onClick={onSubmit} marginBottom={24} disabled={isPlaidVerificationPending}>
          {t('myInfo.Continue')}
        </Button>

        <BodyText textType="bodyText" fontWeight="M" size="T" color="charcoal70" textAlign="end" marginBottom={16} className="next-step" justifyContent="end">
          {t('accountOpening.NextStep')} {t('myId.Document Verification')}
        </BodyText>
      </div>

      <Footer isDisabled={false} onClick={onSubmit} />
    </SCAOSummaryPage>
  );
};
