import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { ROUTES } from 'vars/const/ROUTES';
import { images } from 'assets';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { PennyJarActivatedImage } from 'views/Account/PennyJar/PennyJarPage.styles';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';

interface IPennyJarActivatedModal {
  open: boolean;
  onClose: () => void;
  isClosable?: boolean;
  accountId?: string;
}

export const PennyJarActivatedModal = ({ open, onClose, accountId, isClosable = true }: IPennyJarActivatedModal) => {
  const { t } = useTranslation('pennyJar');
  const { isDesktopSize } = useDeviceDimension();
  const { navigate } = useEnhancedNav();

  const handleContinue = () => navigate(ROUTES.accountPennyJar.path, { params: { accountId } });

  return (
    <CustomModal
      width={isDesktopSize ? '40%' : 'auto'}
      open={open}
      onCancel={onClose}
      topPosition={isDesktopSize ? '0' : '18%'}
      closable={isClosable}
      padding={isDesktopSize ? '20px' : '20px 24px 24px'}
      closeIconColor="charcoal70"
      modalName="pennyJarActivatedModal"
    >
      <div style={{ ...PennyJarActivatedImage }}>
        <img src={images.congratulation} alt="inviteFinished" width="165px" />
      </div>
      <div>
        <Title
          justifyContent={isDesktopSize ? 'center' : 'start'}
          textAlign={isDesktopSize ? 'center' : 'start'}
          font="Poppins"
          size={isDesktopSize ? 'L' : 'M'}
          fontWeight="M"
          color="charcoal"
          marginTop={32}
          marginBottom={16}
        >
          {t('pennyJar.SuccessfullyActivated')}
        </Title>

        <BodyText justifyContent={isDesktopSize ? 'center' : 'start'} textAlign={isDesktopSize ? 'center' : 'start'} textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight={1.5}>
          {t('pennyJar.IfWantChangeThisFeature')}
        </BodyText>
        <CustomRow width="100%" justifyContent="center">
          <Button preset="blue-filled" onClick={handleContinue} width={149} marginTop={32}>
            {' '}
            {t('pennyJar.Continue')}
          </Button>
        </CustomRow>
      </div>
    </CustomModal>
  );
};
