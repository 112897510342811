import { RefObject, useEffect } from 'react';

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void, isEnableForModal = false, isCloseTooltipByClick = true) => {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    const modalElement = document.querySelector('.ant-modal');

    if (!isCloseTooltipByClick && (ref.current === e.target || ref.current?.contains(e.target as Node))) return;

    if (modalElement && modalElement.contains(e.target as Node)) {
      if (isEnableForModal) {
        callback();
      } else {
        return;
      }
    }

    if (ref.current && !ref.current.contains(e.target as Element)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
};
