import { IFAQGroup, IFAQIntent } from 'views/Wellness/HelpAndSupport/FAQ.types';
import { api } from 'store/api';

interface IUseFAQ {
  activeGroupId?: string;
}

const getAllQuestions = (groups: IFAQGroup[]) => {
  const allIntents: IFAQIntent[] = [];
  groups.forEach((group) => {
    allIntents.push(...group.Intents);
  });
  return allIntents;
};

export const useFAQ = ({ activeGroupId }: IUseFAQ = {}) => {
  const faqQuery = api.useGetFAQQuery();

  const FAQGroups = faqQuery.data?.result ?? [];
  const activeGroup = FAQGroups?.find((group: IFAQGroup) => group.Id === activeGroupId);
  const activeGroupIntents = activeGroup?.Intents ?? [];
  const allQuestions = getAllQuestions(FAQGroups);

  return {
    activeGroup,
    activeGroupIntents,
    allQuestions,
    groups: faqQuery?.data?.result,
    isLoading: faqQuery.isLoading,
  };
};
