import { API_ROUTES } from 'vars/const/API_ROUTES';
import { api } from 'store/api';

export const truvApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBridgeToken: builder.query<any, string>({
      query: (fiservAccountId) => ({
        url: API_ROUTES.user.truvToken,
        method: 'GET',
        params: {
          accountId: fiservAccountId,
        },
      }),
    }),
  }),
});

export const { useGetBridgeTokenQuery } = truvApi;
