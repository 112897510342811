import { images } from 'assets';
import { BodyText, Title } from 'components/general/Typography';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import React, { useEffect } from 'react';
import { urlString } from 'utils/helpers/urlString/urlString';
import { useLazyGetIsEmailVerifiedQuery, useLazyValidateEmailLinkQuery } from 'store/user/users.api';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { useToggle } from 'utils/hooks/useToggle';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SContainer } from './CheckEmailPage.styles';
import { VerificationExpiredModal } from './VerificationExpiredModal';

export const CheckEmailPage = () => {
  const [getIsEmailVerified, getIsEmailVerifiedResult] = useLazyGetIsEmailVerifiedQuery();
  const [validateEmailLink] = useLazyValidateEmailLinkQuery();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { generateCodeEmail, openingAccountData } = useCashAccountOpening();
  const [searchParams] = useSearchParams();
  const verificationExpiredModal = useToggle(false);
  const { state } = useLocation();
  const { isDesktopSize } = useDeviceDimension();
  const code = searchParams.get('code');
  const userEmail = openingAccountData.email ?? '';
  const isEditing = state?.isEditing || searchParams.get('isEditing');
  const emailVerifiedPath = urlString({ url: ROUTES.myInfoEmailVerified.path, params: { isEditing: Boolean(isEditing).toString() } });

  const handleOnResendEmail = () => {
    generateCodeEmail?.(userEmail);
    if (verificationExpiredModal.isActive) verificationExpiredModal.hide();
  };

  const handleOnCancel = () => {
    verificationExpiredModal.hide();
    navigate(ROUTES.myInfoEmailUsername.path);
  };

  /* We call isEmailVerified at first */
  useEffect(() => {
    if (!code) {
      getIsEmailVerified();
    }
  }, []);

  /* Handle refetch interval */
  useEffect(() => {
    if (code) return;

    const refetchInterval = setInterval(() => {
      if ((getIsEmailVerifiedResult.isSuccess && getIsEmailVerifiedResult.currentData === true) || verificationExpiredModal.isActive || state?.validateError) {
        clearInterval(refetchInterval);
      } else {
        getIsEmailVerified();
      }
    }, 3000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(refetchInterval);
    };
  }, [getIsEmailVerifiedResult, state]);

  /* Check validate email link result */
  useEffect(() => {
    if (state?.validateError) verificationExpiredModal.show();
    if (!code && !state?.validateError) getIsEmailVerified();
  }, [state]);

  /* Check email verified result */
  useEffect(() => {
    if (state?.validateError && (getIsEmailVerifiedResult.isError || (getIsEmailVerifiedResult.isSuccess && getIsEmailVerifiedResult.currentData === false))) {
      verificationExpiredModal.show();
    }

    if (getIsEmailVerifiedResult.isSuccess && getIsEmailVerifiedResult.currentData === true) {
      navigate(emailVerifiedPath, { state: { isEditing } });
    }
  }, [getIsEmailVerifiedResult, state]);

  /* Check code if it's given in URL params */
  useEffect(() => {
    if (code) {
      validateEmailLink(code)
        .unwrap()
        .then(() => {
          navigate(emailVerifiedPath, { state: { isEditing } });
        })
        .catch(() => {
          verificationExpiredModal.show();
        });
    }
  }, [code]);

  return (
    <>
      {isDesktopSize && <Header title="" stage="Email" webStage="Email" showExit={false} />}

      <SContainer>
        <div>
          <div className="web-image-container">
            <img src={images.invite} alt="Email verify" />
          </div>

          <div className="mobile-image-container">
            <img src={images.invite} alt="Email verify" />
          </div>

          <Title justifyContent="center" textAlign="center" marginBottom={16} size={isDesktopSize ? 'L' : 'N'} fontWeight="M">
            {t('myInfo.checkEmail.title')}
          </Title>

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size={isDesktopSize ? 'L' : 'N'} justifyContent="center" textAlign="center">
            {t('myInfo.checkEmail.description1')} <span className="email">{userEmail}.</span>{' '}
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size={isDesktopSize ? 'L' : 'N'} justifyContent="center" textAlign="center">
              {t('myInfo.checkEmail.description2')}
            </BodyText>
          </BodyText>
        </div>

        <footer>
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" justifyContent="center" textAlign="center" lineHeight={1.4}>
            {t('myInfo.checkEmail.didntReceive')}
            <br />
            <span className="resend" onClick={handleOnResendEmail}>
              {' '}
              {t('myInfo.checkEmail.resend')}
            </span>{' '}
            <span className="contact">{t('myInfo.checkEmail.contact')}</span>
          </BodyText>
        </footer>
      </SContainer>

      <VerificationExpiredModal isOpen={verificationExpiredModal.isActive} onCancel={handleOnCancel} handleOnResendEmail={handleOnResendEmail} />
    </>
  );
};
