import styled, { css } from 'styled-components';
import { flex, getColor, ifProp, mediaUpTo, mediaFrom } from 'utils/helpers/styleHelpers';

interface InternalAccountProps {
  isDesktop?: boolean;
  isDefault?: boolean;
}

export const SAccountWrapper = styled.div`
  ${flex('row', 'space-between', 'flex-start')};
  & .flex-view {
    ${flex('row', 'space-between', 'center')};
  }
`;

export const SInternalAccount = styled.div<InternalAccountProps>`
  background: ${getColor('white')};
  position: relative;
  padding: 16px 24px;
  border-radius: 16px;
  cursor: pointer;
  border: 2px solid ${getColor('creamS5')};

  ${mediaUpTo(
    'tablet',
    css`
      padding: 16px;
    `
  )}

  .sum {
    margin-top: -2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    ${mediaUpTo(
      'tablet',
      css`
        font-size: 12px;
      `
    )}
  }

  .info {
    ${flex('row', 'flex-start', 'center')};

    .text {
      margin-right: 4px;

      .custom-text-inner {
        ${mediaUpTo(
          'tablet',
          css`
            font-size: 12px;
          `
        )}
      }
    }
  }

  .arrow-icon {
    margin-left: 8px;
    align-self: center;
  }

  .type-icon {
    margin-left: 0;
    align-self: center;
    margin-top: 0 !important;
  }

  ${ifProp(
    'isDesktop',
    css`
      ${getColor('creamS5')}
    `,
    css`
      ${getColor('charcoal10')}
    `
  )};

  ${ifProp(
    'isDefault',
    css`
      padding: 50px 24px 24px;
    `,
    css`
      padding: 24px;
    `
  )};

  &.cash {
    padding: 24px;

    ${mediaUpTo(
      'tablet',
      css`
        padding: 16px;
      `
    )}

    .arrow-icon {
      margin-top: 2px !important;
      margin-left: 8px;
      align-self: auto;
    }

    .type-icon {
      align-self: auto;
      margin-top: 0px !important;
    }
  }

  &.default {
    padding-top: 50px;
    ${ifProp(
      'isDesktop',
      css`
        padding-top: 50px;
        padding-bottom: 40px;
      `
    )}

    ${mediaUpTo(
      'tablet',
      css`
        padding: 50px 15px 15px;
      `
    )}
  }

  &.selected {
    ${ifProp(
      'isDesktop',
      css`
        border: 2px solid ${getColor('blue')};
      `,
      css`
        border: 2px solid ${getColor('blue')};
      `
    )}
  }

  &.disabled {
    cursor: not-allowed;
    background: ${getColor('charcoal5')};
    border-color: ${getColor('charcoal20')};
  }
`;

export const SInternalAccountGroup = styled.div<InternalAccountProps>`
  background: ${getColor('creamSS1')};
  padding: 12px;
  border-radius: 24px;

  ${mediaUpTo(
    'tablet',
    css`
      background-color: ${getColor('cream70')};
    `
  )}

  .secondary {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 24px;
    position: relative;
    margin-top: 8px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 8px;
      top: 6px;
      height: calc(100% - 12px);
      width: 4px;
      background: ${getColor('creamS20')};
      border-radius: 4px;
    }

    ${mediaUpTo(
      'tablet',
      css`
        & .internal-account {
          padding: 16px;
        }
      `
    )}
  }

  ${ifProp(
    'isDesktop',
    css`
      background: ${getColor('cream50')};
      border: 2px solid ${getColor('creamS5')};

      .secondary {
        &:before {
          background: ${getColor('creamS10')};
        }
      }
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      background-color: ${getColor('cream')};
      border: 2px solid ${getColor('creamS5')};

      .primary .internal-account {
        border: 2px solid ${getColor('creamS5')};
        &.selected {
          border: 2px solid ${getColor('blue')};
        }
      }

      .secondary .internal-account {
        border: 2px solid ${getColor('creamS5')};
        &.selected {
          border: 2px solid ${getColor('blue')};
        }
      }
    `
  )}
`;
