export const SORT_VARIABLES = [
  {
    title: 'percapitaPayHome.Newest to Oldest',
    id: '0',
  },
  {
    title: 'percapitaPayHome.Oldest to Newest',
    id: '1',
  },
  {
    title: 'percapitaPayHome.Highest to Lowest',
    id: '2',
  },
  {
    title: 'percapitaPayHome.Lowest to Highest',
    id: '3',
  },
];
