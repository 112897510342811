import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Title, BodyText } from 'components/general/Typography';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Button } from 'components/theme/Button/Button';
import { selectSystemProperties } from 'store/user/authentication.slice';
import { SAccountInformationModal } from 'views/Account/AccountInformation/SelectedAccountInformationPage.styles';

interface IStartCloseAccountInformationModal {
  isOpen: boolean;
  onClose: () => void;
  onClickHandler: () => void;
}

export const CashCloseAccountInformationModal: React.FC<IStartCloseAccountInformationModal> = ({ isOpen, onClose, onClickHandler }) => {
  const { t } = useTranslation('accountInformation');
  const { supportPhoneNumber } = useSelector(selectSystemProperties);

  return (
    <CustomModal
      open={isOpen}
      onCancel={onClose}
      modalName="cashCloseAccountInformationModal"
      topPosition="7%"
      padding="15px"
      display="none"
      closable
      className="startCloseAccountInformationModal"
      closeIconColor="charcoal70"
    >
      <SAccountInformationModal>
        <div className="startCloseAccountInformation">
          <Title color="charcoal" fontWeight="M" font="Poppins" size="M" marginBottom={15} marginTop={32}>
            {t('accountInformation.HavingProblemWithYourAccount?')}
          </Title>

          <BodyText textType="bodyText" size="N" color="charcoal70" fontWeight="R" lineHeight={1.3} paddingRight={10} className="callNumber">
            {t(`accountInformation.IfFrustratedAndCantResolveIssueGiveUsCall`)}
            <BodyText textType="bodyText" size="N" color="blue" fontWeight="R" display="inline">
              {supportPhoneNumber}
            </BodyText>
          </BodyText>

          <BodyText textType="bodyText" size="M" color="charcoal" fontWeight="M" lineHeight={1.3} marginTop={25} className="closeAccount">
            {t(`accountInformation.AreYouSureWantCloseCashAccount?`)}
          </BodyText>

          <div className="closeAccountModalFooter">
            <Button preset="red-filled" size="large" onClick={onClickHandler}>
              {t('profile.Confirm')}
            </Button>

            <Button preset="red-outlined" size="large" onClick={onClose}>
              {t('profile.Cancel')}
            </Button>
          </div>
        </div>
      </SAccountInformationModal>
    </CustomModal>
  );
};
