import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'vars/const/ROUTES';
import { Title, BodyText } from 'components/general/Typography';
import { images } from 'assets';
import { useAddJointAccountHolderMutation } from 'store/user/accounts/accounts.api';
import { ResponseModal } from 'views/OpenCashAccount/AcceptInvite/ResponseModal/ResponseModal';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SImageBorder, SLayout, SCustomButton, SBackground } from './CongratsPage.styles';

export const CongratsPage: React.FC = () => {
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('prepPage', { keyPrefix: 'congrats' });
  const [responseIsSuccess, setResponseIsSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const responseToggler = useToggle();
  const [addJointAccountHolderAPI, addJointAccountHolderAPIResult] = useAddJointAccountHolderMutation();
  const inviteIdStatus = location?.state?.inviteIdStatus;

  const goHome = () => navigate(ROUTES.home.path);
  const handleYesClick = () => {
    addJointAccountHolderAPI({ inviteId: location?.state?.inviteId });
  };
  const handleHomeClick = () => {
    navigate(ROUTES.home.path);
  };

  const handleClose = () => {
    responseToggler.hide();
    goHome();
  };

  useEffect(() => {
    if (addJointAccountHolderAPIResult.isError || addJointAccountHolderAPIResult.isSuccess) {
      setResponseIsSuccess(addJointAccountHolderAPIResult.isSuccess);
      responseToggler.show();
    }
  }, [addJointAccountHolderAPIResult.isError, addJointAccountHolderAPIResult.isSuccess]);

  return (
    <SBackground>
      <WebHeader showExit={false} showBack={false} />
      <SLayout>
        <SImageBorder>
          <img src={images.congratulation} alt="congratulation" />
          <Title textAlign="center" justifyContent="center" size={isDesktopSize ? 'L' : 'M'} fontWeight="M" marginBottom={16}>
            <span>
              {t('Title')}
              <br />
              {t('Subtitle')}
            </span>
          </Title>
          <BodyText textType="bodyText" fontWeight="R" size={isDesktopSize ? 'L' : 'N'} color="charcoal70" lineHeight={1.6} textAlign="center" justifyContent="center">
            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="center" lineHeight="24px">
              {t('Description')}
            </BodyText>
          </BodyText>
        </SImageBorder>

        <SCustomButton preset="blue-filled" onClick={handleYesClick} marginTop={isDesktopSize ? 40 : 48}>
          {t('ButtonYes')}
        </SCustomButton>

        <BodyText
          font="Poppins"
          cursorPointer
          textType="bodyText"
          fontWeight="SB"
          size={isDesktopSize ? 'L' : 'N'}
          color="blue"
          marginBottom={18}
          marginTop={24}
          onClick={handleHomeClick}
          justifyContent="center"
        >
          {t(`ButtonNo`)}
        </BodyText>
        <ResponseModal navPath={ROUTES.home.path} isSuccess={responseIsSuccess} inviteIdStatus={inviteIdStatus} open={responseToggler.isActive} onClose={handleClose} />
      </SLayout>
    </SBackground>
  );
};
