import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAccountFeatures } from 'utils/hooks/useAccountFeatures';
import { Loader } from 'components/general/Loader/Loader';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { Switcher } from 'components/general/Switcher/Switcher';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useAccounts } from 'utils/hooks/useAccounts';
import { ROUTES } from 'vars/const/ROUTES';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SPennyJarPage } from './PennyJarPage.styles';

export const AccountPennyJarPage = () => {
  const { t } = useTranslation('pennyJar');
  const { internalAccounts, isLoading } = useAccounts();
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const { accountId } = useParams();
  const { setRoundUp, roundUpFeature, isLoading: featuresLoading } = useAccountFeatures(accountId);
  const [isEnabled, setIsEnabled] = useState(false);

  const pennyJarCashAcc = internalAccounts.find((acc) => acc.accountId === accountId);
  const accountToTransfer = internalAccounts.find((acc) => acc.accountId === roundUpFeature.roundUp?.accountId);

  const handleSwitchChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    setIsEnabled(!target.checked);
  };

  const handleSaveChanges = async () => {
    if (accountId && accountToTransfer?.accountId) {
      await setRoundUp(accountId, isEnabled, accountToTransfer.accountId);
      if (!isEnabled) {
        navigate(generatePath(ROUTES.selectedAccountInformation.path, { sourceType: 'percapita', accountId }));
      }
    }
  };

  useEffect(() => {
    setIsEnabled(roundUpFeature?.isEnabled);
  }, [roundUpFeature?.isEnabled]);

  return (
    <SPennyJarPage className="penny-jar-page penny-jar-settings-page">
      {(featuresLoading || isLoading) && <Loader />}
      {isDesktopSize && <PageHeader title={t('pennyJar.SetupTitle')} />}

      <div className="container">
        <div className="content">
          <div className="settings-block">
            <div className="settings-block__text">
              <Title fontWeight="SB" size={isDesktopSize ? 'S' : 'M'} color="charcoal" marginBottom={16}>
                {t('pennyJar.Title')}
              </Title>
              <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70">
                {t('pennyJar.ActivateDescription')}
              </BodyText>
            </div>
            <div className="penny-jar-box">
              <div className="item">
                <BodyText textType="bodyText" fontWeight="B" size="M" color="charcoal">
                  {t('pennyJar.Title')}
                </BodyText>
                <Switcher onClick={handleSwitchChange} checked={isEnabled} />
              </div>
              <div className="item">
                <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal">
                  {t('pennyJar.LinkedAccount')}
                </BodyText>
                <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                  {pennyJarCashAcc?.name}
                </BodyText>
              </div>
              <div className="item">
                <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal">
                  {t('pennyJar.TransferTo')}
                </BodyText>
                <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                  {accountToTransfer?.name}
                </BodyText>
              </div>
            </div>
            <Button preset="blue-filled" className="settings-block__save-btn" onClick={handleSaveChanges} disabled={!!roundUpFeature?.isEnabled === !!isEnabled}>
              {t('pennyJar.SaveChanges')}
            </Button>
          </div>
        </div>
        <div className="footer">
          <span>{t('pennyJar.WhatIsPercapita')}</span>
        </div>
      </div>
    </SPennyJarPage>
  );
};
