/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Form } from 'antd';
import { CustomText } from 'components/theme/CustomText/CustomText';
import { ISmsCodeInputProps } from 'components/general/SmsCodeForm/SmsCodeForm.types';
import { SCodeContainer, SInputNumber } from './OTPCodeInput.styles';

export const OTPCodeInput = ({
  value = '',
  name,
  onChange,
  boxSize,
  size = 5,
  separatorsAfter = [],
  placeholder = '',
  isCompleted = false,
  isWrongCode = false,
  isDisabledFocusOnStart = false,
}: ISmsCodeInputProps) => {
  const valArr = value?.length === size ? value?.split?.('') : new Array(size).fill(value?.split(''));
  const [codeItems, setCodeItems] = useState<string[]>(valArr);
  const [hasFocused, setHasFocused] = useState(false);
  const codeRef = useRef<HTMLFieldSetElement>(null);
  const itemsElements = codeRef.current?.querySelectorAll<HTMLInputElement>('.otp-code-item') ?? [];
  const itemsArr = [...itemsElements];

  const fieldStatus = Form.Item.useStatus();
  const isError = fieldStatus?.status === 'error';

  const containerClassName = clsx('otp-code-input', { completed: isCompleted, error: isWrongCode || isError });

  const setCodeVal = (index: number, val: string) => {
    const updatedVal = [...codeItems];
    updatedVal[index] = val;
    setCodeItems(updatedVal);
  };

  const fillCode = (code: string) => {
    const cleanedCode = code.replace(/\D/g, '');
    if (cleanedCode.length === size) {
      setCodeItems(cleanedCode.split(''));
      itemsArr[size - 1].focus();
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const currentIndex = Number(target?.dataset?.index);
    const prevInput = itemsArr[currentIndex - 1];
    const nextInput = itemsArr[currentIndex + 1];
    event.preventDefault();

    if (event.key === 'Backspace') {
      prevInput?.focus();
    }

    if (event.key === 'Delete') {
      nextInput?.focus();
      nextInput?.setSelectionRange(0, 0);
    }

    if (target.value && !nextInput?.value && event.key !== 'Unidentified' && event.key !== 'Enter') {
      nextInput?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasted = event.clipboardData.getData('text/plain');
    fillCode(pasted);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = event.target?.value;
    const currentIndex = Number(event.target?.dataset?.index);

    if (inputVal && !/\d/.test(inputVal)) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (inputVal.length === size) {
      event.preventDefault();
      fillCode(inputVal);
      return;
    }

    setCodeVal(currentIndex, inputVal);
  };

  const handleFocus = (event: { target: HTMLInputElement }) => {
    if (!hasFocused && isDisabledFocusOnStart) {
      event.target.blur();
      setHasFocused(true);
    }
  };

  useEffect(() => {
    if (!isDisabledFocusOnStart) {
      itemsArr[0]?.focus();
    }
  }, []);

  useEffect(() => {
    const handlePasteOutsideInput = async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        const clipboardData = await navigator.clipboard.readText();
        fillCode(clipboardData);
      }
    };

    window.addEventListener('keydown', handlePasteOutsideInput);

    return () => {
      window.removeEventListener('keydown', handlePasteOutsideInput);
    };
  }, []);

  useEffect(() => {
    onChange?.(codeItems.join(''));
  }, [codeItems]);

  return (
    <SCodeContainer className={containerClassName} ref={codeRef}>
      {codeItems.map((itemVal, index) => (
        <>
          <SInputNumber
            autoComplete={index === 0 ? 'one-time-code' : 'off'}
            onFocus={handleFocus}
            className="otp-code-item"
            data-index={index}
            id={`${name}-num-${index}`}
            data-testid={`${name}-num-${index}`}
            minLength={1}
            maxLength={1}
            min={0}
            max={9}
            onKeyUp={onKeyUp}
            onInput={handleInput}
            boxSize={boxSize}
            type="tel"
            placeholder={placeholder}
            isCompleted={isCompleted}
            isWrongCode={isWrongCode}
            value={itemVal || ''}
            onPaste={handlePaste}
          />
          {separatorsAfter?.includes(index) && <CustomText>-</CustomText>}
        </>
      ))}
    </SCodeContainer>
  );
};
