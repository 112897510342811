import styled, { css } from 'styled-components';
import { getColor, mediaUpTo, mediaFrom } from 'utils/helpers/styleHelpers';
import { BaseInput } from 'components/general/BaseInput/BaseInput';

export const SHelpAndSupportPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-bottom: 64px;
`;

export const SDesktopSearch = styled(BaseInput)`
  min-width: 215px;
`;

export const SSearch = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SFAQContent = styled.div`
  padding: 0 5px;
  margin-bottom: 30px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 16px;

  ${mediaFrom(
    'tablet',
    css`
      max-width: 580px;
      margin: 0 auto;
      width: 100%;
    `
  )}
`;

export const SLink = styled.a`
  color: ${getColor('blue')};
  text-decoration: underline;

  :hover {
    color: ${getColor('blue')};
    text-decoration: underline;
  }
`;

export const SDisclosureContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;

  ${mediaUpTo(
    'desktop',
    css`
      .disclosure-web {
        display: none;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      justify-content: flex-start;
      padding-bottom: 50px;

      .disclosure-mobile {
        display: none;
      }
    `
  )}
`;
