import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { INotificationItem } from 'store/user/notificationsCenter/notificationsCenter.types';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { parseISO } from 'date-fns';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { formatInTimeZone } from 'date-fns-tz';
import { useToggle } from 'utils/hooks/useToggle';
import { Tooltip } from 'antd';
import { SettingsCard } from 'views/NotificationsCenter/SettingsCard/SettingsCard';
import { SWrapper } from 'views/NotificationsCenter/SettingsCard/SettingsCard.styles';
import { SBodyText, SCustomCard } from './ItemCard.styles';

interface IProps {
  notification: INotificationItem;
  openSettings: (status: string) => void;
  openDeleteModal: () => void;
  setCurrentNotification: (value: string) => void;
  refresh: () => void;
}

const alertMap = {
  security: '3EC55B87-4D10-4E96-9BB0-0C1C76EC1B9E',
  balance: '99784479-CC78-494F-9D6C-698526D05FBD',
};

const getIcon = (typeId: string) => {
  return typeId === alertMap.security ? <Icon name="securityIcon" size="normal" cursorPointer /> : <Icon name="creditCard" size="normal" cursorPointer />;
};

export const ItemCard: React.FC<IProps> = ({ notification, openSettings, setCurrentNotification, openDeleteModal, refresh }) => {
  const { isDesktopSize, isTabletSize } = useDeviceDimension();
  const navigate = useNavigate();
  const settingsCard = useToggle();
  const isNew = notification.status === 'New';

  /* Time zone */
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time = notification.dateSent && formatInTimeZone(parseISO(`${notification.dateSent}Z`), timeZone, 'h:mm a');

  const handleClickDots = () => {
    setCurrentNotification(notification.alertHistoryId);

    if (isDesktopSize) {
      settingsCard.show();
    } else {
      openSettings(notification.status);
    }
  };

  const handleClickCard = () => {
    navigate(`${ROUTES.notificationsCenter.path}/${notification.alertHistoryId}`);
  };

  return (
    <SCustomCard className={`card ${isNew ? 'new' : ''}`}>
      {isNew && <span className="ellipsis" />}
      <CustomRow>
        <CustomRow onClick={handleClickCard}>
          <span className={`icon-pad ${notification.alertCategoryId === alertMap.security ? 'securityIcon' : 'balance'} ${isNew ? 'new' : ''}`}>{getIcon(notification.alertCategoryId)}</span>
          <CustomRow flexDirection="column" paddingLeft={8} paddingRight={12} alignItems="flex-start" cursorPointer>
            <BodyText className="subject" textType="bodyText" size="N" fontWeight={isNew ? 'B' : 'R'} color="charcoal" lineHeight="20px" marginBottom={4} cursorPointer>
              {notification.subject}
            </BodyText>
            <BodyText textType="bodyText" size="T" fontWeight="M" color="charcoal70" className="time" lineHeight="16px" marginBottom={4}>
              {time}
            </BodyText>
            <SBodyText textType="bodyText" size="T" fontWeight="R" color="charcoal70" lineHeight="16px">
              {notification.plainMessage}
            </SBodyText>
          </CustomRow>
        </CustomRow>

        <SWrapper>
          <Tooltip
            showArrow={false}
            open={settingsCard.isActive}
            destroyTooltipOnHide
            trigger="click"
            onOpenChange={settingsCard.isActive ? settingsCard.hide : settingsCard.show}
            overlayInnerStyle={{
              background: 'none',
              boxShadow: 'none',
              width: '368px',
              position: 'relative',
              right: isTabletSize ? '70%' : '95%',
            }}
            title={<SettingsCard notification={notification} onClose={settingsCard.hide} openDeleteModal={openDeleteModal} refresh={refresh} />}
          >
            <span className="dots">
              <Icon name="dotsMenuThin" size="normal" color="charcoal70" onClick={handleClickDots} cursorPointer />
            </span>
          </Tooltip>
        </SWrapper>
      </CustomRow>
    </SCustomCard>
  );
};
