import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 95%;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;

  ${mediaFrom(
    'tablet',
    css`
      max-width: 720px;
      padding: 0 24px;
    `
  )}
  ${mediaFrom(
    'desktop',
    css`
      max-width: unset;
      padding: 0 128px;
    `
  )}
    ${mediaFrom(
    'tablet',
    css`
      align-items: center;
    `
  )}
    .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .ant-form {
      width: 100%;
    }

    ${mediaFrom(
      'tablet',
      css`
        max-width: 536px;
      `
    )}
    ${mediaFrom(
      'desktop',
      css`
        max-width: 580px;
      `
    )}
  }

  .mobile-title-container {
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  .web-title-container {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 16px;
    margin-top: 20px;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        margin-top: 40px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        margin-top: 48px;
      `
    )}
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;

    button {
      :disabled {
        background: transparent !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }

  .card {
    background: ${getColor('white')};
    padding: 32px 24px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto 48px;
  }
`;
