import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const PopupWrapper = styled.div`
  position: sticky;
  bottom: 48px;
  right: 3px;
  left: 3px;
  z-index: 100;
  border: 2px solid white;
  background-color: ${getColor('blue10')};
  border-radius: 16px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 32px 0px rgba(53, 49, 49, 0.16);
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;

  &.show {
    display: flex;
  }

  &.hide {
    display: none;
  }

  .point {
    width: 4px;
    height: 4px;
    background-color: ${getColor('grey')};
    border-radius: 50%;
    margin-left: 8px;
    margin-bottom: 4px;
  }
  .marked {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-left: 8px;
    color: ${getColor('charcoal70')};
    margin-bottom: 4px;
  }

  .custom-row .body-text {
    margin-bottom: 4px;
  }

  ${mediaFrom(
    'desktop',
    css`
      max-width: 740px;
    `
  )};

  ${mediaUpTo(
    'mobile',
    css`
      .marked {
        font-size: 12px;
      }

      .custom-row .body-text .custom-text-inner {
        font-size: 12px;
      }

      .svg-icon {
        width: 20px;
        height: 20px;
      }
    `
  )};
`;
