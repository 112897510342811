import React from 'react';
import { Title } from 'components/general/Typography';
import { SLayout } from './Header.styles';

interface IHeaderProps {
  headerTitle?: string;
  children: React.ReactNode;
  image?: string;
}

export const Header: React.FC<IHeaderProps> = ({ headerTitle, children, image }) => (
  <SLayout className="header">
    <section className="title-container">
      {headerTitle && (
        <Title fontWeight="M" size="XXL" font="Poppins" textAlign="start" color="charcoal" className="header-title">
          {headerTitle}
        </Title>
      )}
      {children}
    </section>

    <div className="image-container">
      <img src={image} alt={headerTitle} width="158px" height="158px" />
    </div>
  </SLayout>
);
