import React, { useState } from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { SmsForm } from 'views/OpenCashAccount/MyInfo/EmailPage/SmsCodePage/SmsForm';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { usersApi } from 'store/user/users.api';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useTheme } from 'styled-components';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Loader } from 'components/general/Loader/Loader';

interface ICheckTaxIdSheet {
  isOpen: boolean;
  onTaxIdMatch: () => void;
  onClose: () => void;
}

export const CashCheckTaxIdSheet: React.FC<ICheckTaxIdSheet> = ({ isOpen, onTaxIdMatch, onClose }) => {
  const theme = useTheme();
  const [form] = Form.useForm();
  const { t } = useTranslation('accountInformation');
  const [validateTaxId, { isLoading }] = usersApi.useValidateTaxIdMutation();
  const { isDesktopSize } = useDeviceDimension();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const onClickHandler = () => {
    setIsError(false);
    const taxIdNumber = form.getFieldValue('emailCode');
    validateTaxId(taxIdNumber)
      .unwrap()
      .then((res) => {
        setIsError(!res.valid);
        if (res.valid) {
          onTaxIdMatch();
        }
      })
      .catch(() => {
        setIsError(true);
      });
  };

  return (
    <CustomSheet isModal={isDesktopSize} isOpen={isOpen} header={false} modalName="internalAccount_cashCheckTaxIdSheet" wrapperPadding={isDesktopSize} paddingTop="32px" onClose={onClose}>
      {isLoading && <Loader />}
      <Title color="charcoal" fontWeight="SB" size="S" marginBottom={15} font="Poppins" textAlign="start">
        {t(`accountInformation.IdentityVerification`)}
      </Title>

      <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={30} lineHeight={1.4}>
        {t('accountInformation.ToProceedWithRequestToCloseCashAccountValidateIdentityGivingLast4DigitsTaxID')}
      </BodyText>

      <CustomCard borderRadius={20} border={`2px solid ${theme.charcoal10}`}>
        <div>
          <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal" marginBottom={30}>
            {t('accountInformation.EnterLast4DigitsOfYourTaxID')}
          </BodyText>
          <SmsForm onCompletion={setIsSubmitDisabled} form={form} size={4} displayInlineText={false} errorMessage="myInfo.EnterYourTaxID" />
        </div>
      </CustomCard>

      {isError && (
        <BodyText textType="bodyText" size="N" fontWeight="R" font="Poppins" color="red" justifyContent="center" marginTop={12}>
          {t('accountInformation.WrongTaxId')}
        </BodyText>
      )}

      <Button preset="blue-filled" size="middleStretch" disabled={isSubmitDisabled} onClick={onClickHandler} marginTop={25}>
        {t('accountInformation.Verify')}
      </Button>
    </CustomSheet>
  );
};
