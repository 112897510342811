// @ts-ignore
import { TOTP } from 'jsotp';

export const getOtpToken = (otpSeed: string) => {
  const totp = TOTP(otpSeed, 60);
  return totp.now();
};

const encodeStr = (str: string) => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

const decodeData = (data: BufferSource) => {
  const decoder = new TextDecoder();
  return decoder.decode(data);
};

const getHash = async (str: string) => crypto.subtle.digest('SHA-256', encodeStr(str));

const getHashHex = async (str: string) => {
  const hashBuffer = await getHash(str);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

export const bytesToString = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
};

export const bufferToB64 = (buffer: ArrayBuffer | Uint8Array) => window.btoa(bytesToString(buffer));

export const base64StringToArray = (s: string) => {
  try {
    const string = window.atob(s);
    return new Uint8Array([...string].map((char) => char.charCodeAt(0)));
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log(error);
    }
    return new Uint8Array();
  }
};

export const generateAesKey = async (algorithmName = 'AES-CBC', length = 256) => {
  return crypto.subtle.generateKey(
    {
      name: algorithmName,
      length,
    },
    true,
    ['encrypt', 'decrypt']
  );
};

export const generateAesKeyByPassphrase = async (passphrase: string, algorithmName = 'AES-CBC', format: 'raw' | 'pkcs8' | 'spki' = 'raw') => {
  return crypto.subtle.importKey(format, encodeStr(passphrase), algorithmName, true, ['encrypt', 'decrypt']);
};

export const aesEncrypt = async (data: string, aesKey: CryptoKey, algorithmName = 'AES-CBC') => {
  const iv = new Uint8Array(16);

  try {
    const encrypted = await crypto.subtle.encrypt({ name: algorithmName, iv }, aesKey, encodeStr(data));

    return { aesKey, iv, encrypted };
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log('AES encryption error', error);
    }
    return { error };
  }
};

export const aesDecrypt = async (encryptedData: ArrayBuffer, aesKey: CryptoKey, iv: Uint8Array, algorithmName = 'AES-CBC') => {
  const decrypted = await crypto.subtle.decrypt(
    {
      name: algorithmName,
      iv,
    },
    aesKey,
    encryptedData
  );

  return decodeData(decrypted);
};

export const getRequestChecksum = async (otpSeed: string, url: string, body: any = null) => {
  const otp = getOtpToken(otpSeed);
  const keyStr = `${otp}${otp}${otp}${otp}${otp}${otp[0]}${otp[1]}`;
  const aesKey = await crypto.subtle.importKey('raw', encodeStr(keyStr), 'AES-CBC', true, ['encrypt', 'decrypt']);
  const bodyStr = body ? JSON.stringify(body) : '';
  const dataStr = `${url};${bodyStr};`;
  const dataHashStr = await getHashHex(dataStr);
  const { encrypted } = await aesEncrypt(dataHashStr, aesKey);

  return encrypted ? bufferToB64(encrypted) : undefined;
};

export const encryptAndEncodeData = async (data: string | undefined, aesKeyString: string) => {
  const aesKey = await generateAesKeyByPassphrase(aesKeyString);
  const encryptedData = await aesEncrypt(data as string, aesKey);
  const encryptedBase64 = bufferToB64(encryptedData.encrypted as ArrayBuffer);
  const ivBase64 = bufferToB64(encryptedData.iv as Uint8Array);

  return {
    encryptedBase64,
    ivBase64,
  };
};

export const decodeAndDecryptData = async (dataEncoded: string, aesKeyString: string, ivEncoded: string) => {
  const encrypted = base64StringToArray(dataEncoded);
  const aesKey = await generateAesKeyByPassphrase(aesKeyString);
  const iv = base64StringToArray(ivEncoded);

  return aesDecrypt(encrypted, aesKey, iv);
};

export const getDecryptedDataFromStorage = async (data: string, aesKeyString: string) => {
  const dataFromStorage = window.localStorage.getItem(data) || window.sessionStorage.getItem(data);
  const dataIvFromStorage = window.localStorage.getItem(`${data}Iv`) || window.sessionStorage.getItem(`${data}Iv`);

  return dataFromStorage && dataIvFromStorage ? decodeAndDecryptData(dataFromStorage, aesKeyString, dataIvFromStorage) : undefined;
};

export const encryptPwd = async (value: string) => {
  const hash = await getHashHex(value);
  return hash;
};
