import React, { forwardRef } from 'react';
import { VisaLogo } from 'assets/logos';
import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { SBackCard, SFrontCard, SSelected } from './SelectableCard.styles';

interface ISelectableCard {
  isPrimary?: boolean;
  debitCardNumber: string;
  nickname: string;
  children: JSX.Element;
  className: string;
  onClick?: () => void;
}

export const SelectableCard = forwardRef<HTMLDivElement, ISelectableCard>(({ debitCardNumber, isPrimary = false, nickname, children, className, onClick }, ref) => {
  const { t } = useTranslation('moveMoney');

  return (
    <div className="card" onClick={onClick} ref={ref}>
      {className === 'selected' && (
        <>
          <SSelected>
            <SBackCard>
              <div className="selected">{t('internationalTransferPage.selectableCard.Selected')}</div>
              <div className="magnetic-card-reader" />
              <div className="signature-holder" />
              {debitCardNumber && <div className="card-number-holder">**** **** **** {debitCardNumber}</div>}
              <div className="form">{children}</div>
            </SBackCard>
          </SSelected>
          <BodyText textType="bodyText" color="charcoal" fontWeight="R" size="N">
            {nickname ? (
              <Trans i18nKey="internationalTransferPage.DebitCardName" ns="moveMoney" values={{ name: nickname }} />
            ) : (
              t(`internationalTransferPage.${isPrimary ? 'PrimaryCashAccount' : 'SecondaryCashAccount'}`)
            )}
          </BodyText>
        </>
      )}
      {className !== 'selected' && (
        <>
          <SFrontCard>
            <img className="emv-chip" src={images.emvChip} alt="Chip" />
            {debitCardNumber && <div className="card-number-holder">**** **** **** {debitCardNumber}</div>}
            <VisaLogo className="visa-logo" color="white" />
          </SFrontCard>
          <BodyText textType="bodyText" color="charcoal" fontWeight="R" size="N">
            {nickname ? (
              <Trans i18nKey="internationalTransferPage.DebitCardName" ns="moveMoney" values={{ name: nickname }} />
            ) : (
              t(`internationalTransferPage.${isPrimary ? 'PrimaryCashAccount' : 'SecondaryCashAccount'}`)
            )}
          </BodyText>
        </>
      )}
    </div>
  );
});
