import React from 'react';
import { images } from 'assets';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';

interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export const CongratsModal: React.FC<IModalProps> = ({ open, onClose }) => {
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation('cashCushion');
  return (
    <CustomModal
      width={isDesktopSize ? '648px' : 'auto'}
      open={open}
      onCancel={onClose}
      topPosition={isDesktopSize ? '0' : '18%'}
      closable
      padding={isDesktopSize ? '20px' : '20px 24px 24px'}
      closeIconColor="charcoal70"
      modalName="cashCushionCongratsModal"
    >
      <div style={{ textAlign: 'center' }}>
        <img src={images.congratulation} alt="inviteFinished" width="165px" />
      </div>
      <div>
        <Title
          justifyContent={isDesktopSize ? 'center' : 'start'}
          textAlign={isDesktopSize ? 'center' : 'start'}
          font="Poppins"
          size={isDesktopSize ? 'L' : 'M'}
          fontWeight="M"
          color="charcoal"
          marginTop={32}
          marginBottom={16}
        >
          {t('Congrats')}
        </Title>

        <BodyText justifyContent={isDesktopSize ? 'center' : 'start'} textAlign={isDesktopSize ? 'center' : 'start'} textType="bodyText" color="charcoal70" size="N" fontWeight="R" lineHeight={1.5}>
          {t('WouldLikeToTurnOff')}
        </BodyText>

        <CustomRow justifyContent="center" marginTop={32}>
          <Button preset="blue-filled" size="middleAlt" onClick={onClose}>
            {t(`percapitaPayHome.Continue`)}
          </Button>
        </CustomRow>
      </div>
    </CustomModal>
  );
};
