import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCheckRightTimeNotificationMutation } from 'store/user/users.api';
import { selectUserId } from 'store/user/authentication.slice';
import { setNotificationCapabilityModal, setNotificationHtml, selectDisplayNotificationHtml, selectPrevPath, setPrevPath } from 'store/ui.slice';

export const useRightTimeNotifications = (path?: string) => {
  const [checkRightTimeNotification, checkRightTimeNotificationResult] = useCheckRightTimeNotificationMutation();
  const displayNotificationHtml = useSelector(selectDisplayNotificationHtml);
  const prevPath = useSelector(selectPrevPath);
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const updateHtml = async () => {
    await setTimeout(() => {
      const hasHtml = !!checkRightTimeNotificationResult?.data?.notification;
      dispatch(setNotificationHtml(hasHtml ? checkRightTimeNotificationResult?.data?.notification : ''));
      dispatch(setNotificationCapabilityModal(hasHtml));
    }, 1000);
  };

  useEffect(() => {
    if (userId && path && !displayNotificationHtml && prevPath !== path) {
      checkRightTimeNotification(path);
    } else {
      dispatch(setNotificationHtml(''));
      dispatch(setNotificationCapabilityModal(false));
    }
    if (path) {
      dispatch(setPrevPath(path));
    }
  }, [path]);

  useEffect(() => {
    updateHtml();
  }, [checkRightTimeNotificationResult]);
};
