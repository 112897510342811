import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { images } from 'assets';
import { SIconClose, SCustomButton } from './JoinAccountModal.styles';

interface IJointAccountModal {
  open: boolean;
  onClose: () => void;
  isUserUnder18?: boolean;
  isAccountSetup: boolean;
  alreadyHasCash?: boolean;
  fromPrep?: boolean;
}

export const JointAccountModal: React.FC<IJointAccountModal> = ({ open, onClose, isUserUnder18 = false, isAccountSetup, alreadyHasCash = false, fromPrep = false }) => {
  const { t } = useTranslation('cashAccount');
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleOnContinue = () => {
    if (alreadyHasCash) {
      navigate(ROUTES.openNewCash.path, { state: { fromPage: state?.fromPage } });
    } else {
      if (isUserUnder18 || !isAccountSetup || fromPrep) {
        navigate(ROUTES.home.path);
      } else {
        navigate(ROUTES.myInfoSummary.path);
      }
    }

    onClose();
  };

  return (
    <CustomModal open={open} onCancel={handleOnContinue} modalName="jointAccountModal" topPosition="5%" closeIcon={<SIconClose name="closeCircle" color="charcoal70" />}>
      <>
        <div className="center-image">
          <img src={images.success} alt="Account holder has been notified" />
        </div>

        {isUserUnder18 || fromPrep ? (
          <Title size="M" fontWeight="M" justifyContent="start" marginBottom={!isAccountSetup ? 16 : 32} marginTop={32}>
            {t('cashAccount.ParentNotified')}
          </Title>
        ) : (
          <Title size="M" fontWeight="M" justifyContent="start" marginBottom={!isAccountSetup ? 16 : 32} marginTop={32}>
            {!isAccountSetup ? t('cashAccount.JointNotified') : t('cashAccount.InvitedPerson')}
          </Title>
        )}

        {!isAccountSetup && !isUserUnder18 && !fromPrep && (
          <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={32} justifyContent="start">
            {t('cashAccount.JointNotifiedDesc')}
          </BodyText>
        )}

        {isUserUnder18 ||
          (fromPrep && (
            <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={32} justifyContent="start">
              {t('cashAccount.CheckEmailWhenParentInvitedYou')}
            </BodyText>
          ))}

        <SCustomButton preset="blue-filled" onClick={handleOnContinue}>
          {t('cashAccount.Continue')}
        </SCustomButton>
      </>
    </CustomModal>
  );
};
