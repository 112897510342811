import React, { useState } from 'react';
import { Loader } from 'components/general/Loader/Loader';
import { useLocation } from 'react-router-dom';
import { SIFrame } from 'views/StatementsAndDocuments/AccountStatementsIframe/AccountStatementsIframe.styles';

export const VerificationIFrame = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const iFrameUrl = location?.state?.iFrameUrl || searchParams.get('iFrameUrl') || '';
  const iFrameTitle = location?.state?.iFrameTitle || searchParams.get('iFrameTitle');
  const [iFrameIsLoading, setIFrameIsLoading] = useState(true);
  return (
    <>
      {iFrameIsLoading && <Loader />}
      <SIFrame src={iFrameUrl} title={iFrameTitle} width="100%" height="100%" frameBorder="0" onLoad={() => setIFrameIsLoading(false)} />
    </>
  );
};
