import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SMapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    height: auto;
    max-width: 100%;
  }
`;

export const SZoomButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${getColor('white')};
  border-radius: 32px;
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  width: 56px;
  height: 92px;
  gap: 12px;
  color: ${getColor('blue')};
  cursor: pointer;

  position: relative;
  bottom: 15%;
  left: 75%;

  span {
    cursor: pointer;
  }

  ${mediaUpTo(
    320,
    css`
      bottom: 23%;
    `
  )}

  ${mediaFrom(
    321,
    css`
      bottom: 20%;
      left: 80%;
    `
  )}

  ${mediaFrom(
    468,
    css`
      bottom: 20%;
      left: 85%;
    `
  )}

  ${mediaFrom(
    950,
    css`
      left: 70%;
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      bottom: 15%;
    `
  )}
`;
