import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, BodyText } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useSelector } from 'react-redux';
import { formatPhone } from 'utils/helpers/phone';
import { selectSystemProperties } from 'store/user/authentication.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SLayout } from './ContactInformation.styles';
import { DataItem } from './DataItem/DataItem';

const CUSTOM_SERVICE = 'customerservice@percapita.com';

export const ContactInformation = () => {
  const { t } = useTranslation('accountInformation');
  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const { isDesktopSize } = useDeviceDimension();
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);

  return (
    <SLayout>
      <Title className="page-title" fontWeight="SB" color="charcoal" marginBottom={32} font="Poppins" size="M">
        {t('accountInformation.ContactInformation')}
      </Title>

      <div className="cards-container">
        <CustomCard width={isDesktopSize ? '578px' : '100%'} borderRadius={16} padding="32px 24px">
          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" font="DM Sans" size="N" marginBottom={18}>
            {t('accountInformation.ContactInformationSubtitle')}
          </BodyText>
          <DataItem label={t('accountInformation.OurEmail')} url={`mailto:${CUSTOM_SERVICE}`} value={CUSTOM_SERVICE} icon="envelopeClosed" marginBottom={18} />
          <DataItem label={t('accountInformation.OurPhoneNumber')} url={supportTelVal} value={supportPhoneNumber} icon="telephone" />
        </CustomCard>
      </div>
    </SLayout>
  );
};
