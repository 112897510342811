import styled, { css } from 'styled-components';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 2px ${getColor('blue10')};
  border-radius: 16px;
  margin-bottom: 8px;

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${getColor('transparent')};
    cursor: pointer;

    .country-name {
      flex: 1;
    }
  }

  .card-body-wrapper {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;

    .card-body {
      padding: 0 16px 16px;

      .min-transfer-amount-wrapper {
        display: flex;
        margin-bottom: 16px;
      }

      .columns {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          flex-direction: column;

          ${mediaUpTo(
            500,
            css`
              gap: 10px;
            `
          )}

          &.titles {
            flex-direction: row;
            margin-bottom: 8px;
            justify-content: space-between;
          }

          .row-item {
            display: flex;
            margin-bottom: 8px;
            justify-content: space-between;

            .cell {
              display: flex;
            }

            .transfer-methods-cell {
              width: 50%;
            }

            .fees-cell {
              justify-content: flex-start;
              width: 35%;
            }

            .max-amount-cell {
              width: 15%;
            }
          }
        }

        .transfer-methods-cell {
          width: 50%;
          padding-right: 4px;
          ${mediaUpTo(
            500,
            css`
              align-items: center;
            `
          )}
        }

        .fees-cell {
          width: 30%;
          padding-right: 4px;
          ${mediaUpTo(
            500,
            css`
              align-items: center;
            `
          )}
        }

        .max-amount-cell {
          width: 20%;
          justify-content: flex-end;
        }
      }
    }
  }

  &.expanded {
    .card-body-wrapper {
      ${mediaUpTo(
        500,
        css`
          height: 240px;
        `
      )}
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
