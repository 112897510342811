import { images } from 'assets';
import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCardsContainer = styled.div`
  overflow-x: auto;
  padding-left: 10px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 16px;

  .card {
    display: flex;
    flex-direction: column;
  }
`;

export const SSelected = styled.div`
  padding: 8px;
  border: 2px solid ${getColor('blue')};
  border-radius: 24px;
  margin-bottom: 16px;
  margin-top: 32px;
`;

export const SBackCard = styled.div`
  position: relative;
  cursor: pointer;
  width: 341px;
  height: 217px;
  border-radius: 16px;
  background: ${getColor('blue')} url(${images.cardBackDots}) no-repeat right calc(100% + 15px) / 190.16px 144.46px;

  .form {
    position: absolute;
    top: 100px;
    left: 186px;
  }

  .selected {
    position: absolute;
    top: 6px;
    left: 6px;
    background: ${getColor('blue30')};
    border-radius: 12px 4px 12px 4px;
    height: 32px;
    min-width: 120px;
    max-width: 262px;
    color: ${getColor('charcoal')};
    font-size: 12px;
    font-family: 'DM Sans';
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .magnetic-card-reader {
    position: absolute;
    top: 47.69px;
    width: 341px;
    height: 44px;
    background-color: ${getColor('charcoal')};
  }

  .signature-holder {
    position: absolute;
    top: 102px;
    left: 6px;
    width: 176px;
    height: 44px;
    background-color: ${getColor('blue90')};
  }

  .card-number-holder {
    position: absolute;
    top: 155px;
    left: 6px;
    color: ${getColor('white')};
    font-size: 18px;
    font-weight: 700;
    font-family: 'DM Sans';
  }
`;

export const SFrontCard = styled.div`
  position: relative;
  cursor: pointer;
  width: 341px;
  height: 217px;
  border-radius: 16px;
  background: ${getColor('blue')} url(${images.cardFrontDots}) no-repeat left calc(100% + 15px) / 190.16px 144.46px;
  margin-top: 32px;
  margin-bottom: 8px;

  .emv-chip {
    position: absolute;
    top: 72.66px;
    left: 37.96px;
  }

  .card-number-holder {
    position: absolute;
    top: 129px;
    left: 40px;
    color: ${getColor('white')};
    font-size: 18px;
    font-weight: 700;
    font-family: 'DM Sans';
  }

  .visa-logo {
    position: absolute;
    top: 183.34px;
    left: 249.27px;
  }
`;
