import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { SFAQQuestion } from './Question.styles';

interface IQuestionItem {
  text: string;
  intentName: string;
  onClick?: (intentName: string) => void;
  answerText: string;
  isCollapsible?: boolean;
  isAlwaysOpen?: boolean;
}

export const Question = ({ text, intentName, answerText, isAlwaysOpen = false, onClick, isCollapsible = false }: IQuestionItem) => {
  const answer = useToggle();
  const arrowIconName = useMemo(() => {
    if (isCollapsible) {
      return answer.isActive ? 'chevronUp' : 'chevronDown';
    }
    return 'chevronRight';
  }, [isCollapsible, answer.isActive]);

  const handleClick = () => {
    if (isCollapsible && !isAlwaysOpen) {
      answer.toggle();
    }
    onClick?.(intentName);
  };

  return text ? (
    <SFAQQuestion className={clsx('faq-question', answer && 'open')}>
      <div onClick={handleClick} className="faq-question__question">
        <BodyText fontWeight="B" size="M">
          {text}
        </BodyText>
        <Icon name={arrowIconName} size="smallest" />
      </div>
      {(isAlwaysOpen || answer.isActive) && <div className="faq-question__answer" dangerouslySetInnerHTML={{ __html: answerText }} />}
    </SFAQQuestion>
  ) : null;
};
