import React from 'react';
import { Outlet } from 'react-router-dom';
import { MobileHeader } from 'components/navigation/MobilePageHeader/MobileHeader';
import { NeedSupportModal } from 'components/general/Modals/NeedSupportModal/NeedSupportModal';
import { SLayout, SLayoutContent, SLayoutWrapper } from './IFrameLayout.styles';

export const IFrameLayout = () => (
  <SLayout>
    <SLayoutContent>
      <MobileHeader />
      <SLayoutWrapper>
        <Outlet />
      </SLayoutWrapper>
      <NeedSupportModal />
    </SLayoutContent>
  </SLayout>
);
