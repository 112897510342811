import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccount } from 'store/user/payments/payments.types';
import { getCardTypeFromApiResp, getOnlyNumbersFromString } from 'utils/helpers/string';
import { BankAccountSmall } from 'views/Cards/BankAccountSmall/BankAccountSmall';
import { CreditCardSmall } from 'views/Cards/CreditCardSmall/CreditCardSmall';
import { SCreditCard } from 'views/PercapitaPay/MainPage/PercapitaPayMainPage.styles';

interface ICardCarousel {
  className?: string;
  accounts: IAccount[];
  selectedAccount: number | null;
  handleSelectAccount: (accountId: number) => void;
  handleAddAccountClick: () => void;
}

export const CardCarousel: React.FC<ICardCarousel> = ({ className, accounts, selectedAccount, handleSelectAccount, handleAddAccountClick }) => {
  const { t } = useTranslation('percapitaPay');

  const handleAccountClick = (accountId: number, e: React.MouseEvent<HTMLDivElement>) => {
    handleSelectAccount(accountId);

    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  const sortedMemoizedAccounts = useMemo(() => accounts.slice().sort((a: IAccount, b: IAccount) => Number(b.primaryAccount) - Number(a.primaryAccount)), [accounts]);

  return (
    <div className={className}>
      <div className={`${className}-body`}>
        {sortedMemoizedAccounts.map((account: IAccount) => (
          <div className={`${className}-cards`} key={account.id}>
            {account.accountType === 'CARD' && <CreditCardSmall noCarousel onClick={(e) => handleAccountClick(account.id, e)} isSelected={selectedAccount === account.id} {...account} />}

            {account.accountType === 'BANK' && (
              <BankAccountSmall
                bankName={account.accountDetails?.bankName}
                accountNumber={account.accountDetails?.number}
                onClick={(e) => handleAccountClick(account.id, e)}
                isSelected={selectedAccount === account.id}
              />
            )}

            <div className={`${className}-cards-account`}>
              <div className={`${className}-cards-account-details`}>
                <BodyText color="charcoal60" fontWeight="R" size="N" textType="helperText" marginRight={3}>
                  {`${getCardTypeFromApiResp(account.alias)} (${getOnlyNumbersFromString(account.details)})`}
                </BodyText>
                {account.primaryAccount && (
                  <BodyText color="blue" fontWeight="M" size="N" textType="helperText" marginRight={14} font="Poppins" nowrap>
                    {t('percapitaPayHome.Default')}
                  </BodyText>
                )}
              </div>
              <BodyText color="blue" fontWeight="R" size="N" textType="helperText" cursorPointer onClick={handleAddAccountClick} nowrap>
                • {t('percapitaPayHome.See more')}
              </BodyText>
            </div>
          </div>
        ))}

        <div className="add-card">
          <div className="add-card-icon">
            <SCreditCard onClick={handleAddAccountClick}>
              <Icon name="circlePlus" color="charcoal40" />
            </SCreditCard>
          </div>
        </div>
      </div>
    </div>
  );
};
