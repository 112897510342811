import React from 'react';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { images } from 'assets';
import { Button } from 'components/theme/Button/Button';
import { usePercapitaPlayWarning } from 'utils/hooks/usePercapitaPlayWarning';
import { SPlayToWin } from './PlayToWin.styles';

export const PlayToWin = () => {
  const { handleGoToPlay } = usePercapitaPlayWarning();
  const { t } = useTranslation();

  return (
    <SPlayToWin>
      <div className="header">
        <img src={images.percapitaLayoutLogo} alt="percapita" />
      </div>
      <section className="main">
        <img src={images.playToWinDotsLeftBig} className="dots-left" alt="play to win" />
        <img src={images.playToWinDotsRightBig} className="dots-right" alt="play to win" />
        <img src={images.playToWinTopBg} className="main-bg" alt="play to win" />
        <img src={images.playToWinMain} className="main-img" alt="play to win" />
        <div className="main-content">
          <Title font="Poppins" color="white" className="title" fontWeight="M" marginBottom={16} marginTop={15} textAlign="center">
            {t('playToWin.Play to Win!')}
          </Title>
          <BodyText textType="bodyText" size="L" color="white" display="inline" textAlign="center" marginBottom={40}>
            <Trans i18nKey={t('playToWin.Get a $25 bonus when you earn 1000 or more Percapita Plays points.*')} components={{ Highlight: <span className="highlight" /> }} />
          </BodyText>
          <Button preset="white-filled" size="middle" className="button" onClick={handleGoToPlay}>
            {t(`playToWin.Let's Play`)}
          </Button>
        </div>
      </section>

      <section className="how-to">
        <CustomCard padding="24px 28px" className="how-to-list">
          <img src={images.playToWinDotsLeft} className="dots-left" alt="play to win" />
          <img src={images.playToWinDotsRight} className="dots-right" alt="play to win" />
          <div className="how-to-content">
            <Title textAlign="center" fontWeight="M" marginBottom={40} className="how-to-title" color="white">
              {t('playToWin.How to win')}
            </Title>
            <ol className="list">
              <li className="list-item">
                <div>
                  <Title color="white" className="subtitle" font="Poppins" size="T" fontWeight="M" marginBottom={8} textAlign="start">
                    {t('playToWin.Make sure you have an open and active Cash Account')}
                  </Title>
                  <BodyText textType="bodyText" size="N" color="bluePurple30" display="inline">
                    {t("playToWin.Set up your Cash Account if you don't yet have one to be eligible for your bonus.")}
                  </BodyText>
                </div>
              </li>
              <li className="list-item">
                <div>
                  <Title color="white" className="subtitle" font="Poppins" size="T" fontWeight="M" marginBottom={8} textAlign="start">
                    {t("playToWin.Visit the Wellness section of the Percapita app and click 'Play Now' under 'Earn Percapita Points’")}
                  </Title>
                  <BodyText textType="bodyText" size="N" color="bluePurple30" display="inline">
                    {t('playToWin.Earn 1000 points by completing the quizzes in each topic you choose.')}
                  </BodyText>
                </div>
              </li>
              <li className="list-item">
                <div>
                  <Title color="white" className="subtitle" font="Poppins" size="T" fontWeight="M" marginBottom={8} textAlign="start">
                    {t('playToWin.Get your $25!')}
                  </Title>
                  <BodyText textType="bodyText" size="N" color="bluePurple30" display="inline">
                    {t('playToWin.Receive $25 in your Percapita Cash Account within 10 days of fulfilling all requirements.')}
                  </BodyText>
                </div>
              </li>
            </ol>
            <Button size="middle" preset="white-filled" className="button" onClick={handleGoToPlay}>
              {t(`playToWin.Let's Play`)}
            </Button>
          </div>
        </CustomCard>
      </section>
      <section className="disclosures">
        <div className="disclosure">{t('playToWin.disclosure1')}</div>
        <div className="disclosure">{t('playToWin.disclosure2')}</div>
      </section>
      <img src={images.playToWinBottomLines} alt="play to win" width="100%" />
      <div className="footer">
        <img src={images.percapitaLayoutLogo} alt="percapita" className="logo" />
        <div className="copyright">
          © Percapita Group, Inc. 2024
          <br />
          <a className="link" href="https://www.percapita.com/privacy">
            Privacy Policy
          </a>
        </div>
        <img src={images.playToWinDotsBottomLeft} alt="percapita" className="dots-left" />
        <img src={images.playToWinDotsBottomRight} alt="percapita" className="dots-right" />
      </div>
    </SPlayToWin>
  );
};
