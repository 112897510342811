import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ROUTES } from 'vars/const/ROUTES';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { Button } from 'components/theme/Button/Button';
import { Title, BodyText } from 'components/general/Typography';
import { ListItem } from './ListItem/ListItem';
import { SListItemText, SLayout } from './PrepPage.styles';

export const PrepPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('prepPage');
  const { isMobileSmallSize, isDesktopSize } = useDeviceDimension();

  const handleButtonClick = () => {
    navigate(ROUTES.myInfoDisclosures.path);
  };

  const handleLinkClick = () => {
    navigate(ROUTES.home.path);
  };

  const handleUnder18Click = () => {
    navigate(ROUTES.myAccountJointAccounts.path, { state: { fromPrep: true, isUnder18: true } });
  };

  return (
    <SLayout>
      <div className="title-container">
        <Title font="Poppins" className="prep-title" size="M" color="charcoal" fontWeight="SB" lineHeight={1.2} paddingTop={2} paddingRight={60}>
          {t(`prepPage.AreYouReadyToOpenCashAccount?`)}
        </Title>
        <BodyText font="DM Sans" textType="bodyText" color="charcoal70" fontWeight="R" size="N" marginBottom={32} marginTop={13} className="prep-page-subtitle">
          {t(`prepPage.OpeningAccountWillTakeLessThan10Minutes`)}
        </BodyText>
      </div>

      <div className="card">
        <BodyText font="DM Sans" textType="bodyText" className="list-title" fontWeight="B" size="M" color="charcoal" marginBottom={18} marginTop={36}>
          {t(`prepPage.YoullNeed`)}:
        </BodyText>
        <div className="list-item">
          <ListItem size="N" text="prepPage.YourNameDateOfBirthHomeAddess(no P.o. boxes)AndEmail" marginTop={19} />
          <ListItem size="N" text="prepPage.YourSocialSecurityNumber(SSN)OrIndividualTaxpayerIdentificationNumber(ITIN)" marginTop={19} />
          <ListItem size="N" text="prepPage.YourValidUnexpiredPhotoIDOptionsInclude" marginTop={19} />
        </div>
        <div className="list-item-text">
          <SListItemText>{t(`prepPage.U.S. Passport`)}</SListItemText>
          <SListItemText>{t(`prepPage.U.S. State Driver’s License`)}</SListItemText>
          <SListItemText>{t(`prepPage.U.S. State ID`)}</SListItemText>
          <SListItemText>{t(`prepPage.U.S. Permanent Resident Card`)}</SListItemText>
          <SListItemText>{t(`prepPage.Non-U.S. Passport`)}</SListItemText>
        </div>
        <div className="list-item">
          <ListItem size="N" text={<Trans i18nKey="prepPage.ToBe18" ns="prepPage" components={{ a: <span className="link" onClick={handleUnder18Click} /> }} />} marginTop={19} />
        </div>
      </div>

      <div className="information">
        <BodyText font="DM Sans" textType="bodyText" fontWeight="B" size="N" color="charcoal" marginBottom={24} marginTop={40} paddingRight={5} lineHeight={1.4}>
          {t(`prepPage.WellReviewYourPersonalInformationYouNeedToTakePictureOfYourIdAndSelfie`)}
        </BodyText>

        <BodyText font="DM Sans" textType="bodyText" fontWeight="R" size="N" color="charcoal" marginBottom={24} marginTop={22} lineHeight={1.4}>
          {t(`prepPage.AgreeToShareInformation`)}
        </BodyText>
      </div>

      {isDesktopSize ? (
        <Footer firstBtnText={t(`prepPage.Open an Account Later`)} secondBtnText={t(`prepPage.Agree`)} onBack={handleLinkClick} isDisabled={false} onClick={handleButtonClick} />
      ) : (
        <>
          <Button preset="blue-filled" onClick={handleButtonClick} marginBottom={24} marginTop={45}>
            {t(isMobileSmallSize ? `prepPage.Continue` : `prepPage.Agree`)}
          </Button>
          <BodyText font="Poppins" textType="bodyText" fontWeight="M" size="N" color="blue" marginBottom={30} onClick={handleLinkClick} justifyContent="center">
            {t(`prepPage.Open an Account Later`)}
          </BodyText>
        </>
      )}

      <div className="disclosure-container">
        <BodyText font="DM Sans" textType="bodyText" fontWeight="R" size="T" color="charcoal70" lineHeight={1.3}>
          {t(`prepPage.PercapitaGroupInc`)}
        </BodyText>
      </div>
    </SLayout>
  );
};
