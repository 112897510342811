import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90%;
  justify-content: space-between;
  padding-bottom: 32px;
  margin: 50px auto 0;
  width: 100%;
  max-width: 576px;

  ${mediaFrom(
    'desktop',
    css`
      justify-content: flex-start;
      align-items: center;
      min-height: auto;
    `
  )}

  .web-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin: 50px auto 48px;

    img {
      width: 100%;
    }

    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .mobile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px auto 24px;

    ${mediaFrom(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  footer {
    margin-top: auto;

    ${mediaFrom(
      'desktop',
      css`
        margin-top: 40px;
      `
    )}
  }

  .resend {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    color: ${getColor('blue')};
    cursor: pointer;
  }

  .email {
    font-weight: 700;
    font-size: 14px;
    color: ${getColor('charcoal')};
  }

  .contact {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    color: ${getColor('charcoal70')};
  }
`;
