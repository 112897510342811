import styled from 'styled-components';
import { MEDIA_SIZE, getColor } from 'utils/helpers/styleHelpers';
import { CustomCheckbox } from 'components/theme/CustomCheckbox/CustomCheckbox';

export const SLayout = styled.div`
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (min-width: ${MEDIA_SIZE.minDesktop}px) {
    background-color: ${getColor('white')};
    border-radius: 25px;

    .footer button {
      max-width: fit-content;
      padding: 14px 40px;
      margin-top: 0;
    }
  }
`;

export const SCheckbox = styled(CustomCheckbox)`
  .ant-checkbox {
    &-checked {
      .ant-checkbox-inner {
        border-color: ${getColor('transparent')};

        &:after {
          background: ${getColor('transparent')};
          border-color: ${getColor('blue')};
        }
      }
    }

    & + span {
      padding-right: 6px;
      padding-left: 16px;
    }

    span {
      color: ${getColor('charcoal70')};
      top: 4px;
    }

    &-wrapper {
      background: ${getColor('transparent')};

      :hover {
        border-color: ${getColor('charcoal20')};
      }
    }

    &-inner {
      width: 24px;
      height: 24px;
      border: 2px solid ${getColor('charcoal20')} !important;
      background: ${getColor('transparent')} !important;
      border-radius: 8px;
      top: -4px;
      border-width: 2.3px;
      border-color: ${getColor('charcoal')};

      &:after {
        left: 25%;
        top: 45%;
      }
    }
  }
`;
