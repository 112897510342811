import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SFAQGroup = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${getColor('white')};
  border-radius: 20px;

  .FAQGroup {
    &__content {
      display: flex;
    }
    &__text {
      margin-left: 24px;
    }
    &__arrow {
      margin-left: 30px;
    }
  }
`;
