import { CustomText } from 'components/theme/CustomText/CustomText';
import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SSubmitError = styled(CustomText)`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -28px;
  width: 100%;
`;

export const SFields = styled.div`
  position: relative;
`;

export const SButtonWrapper = styled.div`
  ${mediaFrom(
    'tablet',
    css`
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        min-width: 154px !important;
        max-width: 180px !important;
        padding: 14px 40px;

        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
      }
    `
  )}
`;
