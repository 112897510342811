import styled, { css } from 'styled-components';
import { CustomText } from 'components/theme/CustomText/CustomText';
import { mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { SInputGroup } from 'views/Auth/Login/LoginForm/LoginForm.styles';

const inputFontSize = `
  & input {
    font-size: 12px !important;
      &::placeholder {
        font-size: 12px !important;
      }
    }
`;

export const SRegistrationFormContainer = styled.div`
  .already-registered {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 20px;

    & .custom-text-inner,
    & span {
      font-size: 13px;
    }
  }

  .remember-me {
    margin-bottom: 15px;
  }

  .register-btn {
    margin-top: 16px;
    border: 0 !important;
  }

  .fields {
    position: relative;
    margin-bottom: 46px;

    .ant-form-item-explain {
      padding: 0;
    }
  }

  &:has(.error-msg) {
    .fields {
      margin-bottom: 0;

      .ant-form-item.ant-form-item-with-help {
        margin-bottom: 12px;
      }
    }
  }

  ${mediaFrom(
    'tablet',
    css`
      .register-btn {
        margin-top: 24px;
      }

      &:has(.error-msg) {
        .fields {
          .ant-form-item.ant-form-item-with-help {
            .validation-help {
              margin-bottom: 10px;
            }
          }
        }
      }
    `
  )}

  ${mediaUpTo(
    'desktop',
    css`
      .ant-form-item,
      .remember-me {
        margin-bottom: 6px;
      }

      .validation-help-item {
        margin: 4px 0;
        .icon {
          width: 14px;
          height: 14px;
        }
      }

      .consent-checkbox {
        margin-bottom: 3px;
      }

      .fields {
        margin-bottom: 16px;
      }
    `
  )}

  .consents {
    ${mediaUpTo(
      'mobile',
      css`
        .consent-checkbox-disclosure-text {
          display: inline;
          & div {
            display: inline;
          }
        }
        & + .register-btn {
          padding: 6px 8px;
        }
      `
    )}

    ${mediaFrom(
      'tablet',
      css`
        & + .register-btn {
          width: 140px;
          padding: 14px 32px 14px 32px;
          margin-inline: auto;
          height: 52px;
        }

        &:has(.error-msg) {
          .fields {
            margin-bottom: 30px;
          }
        }
      `
    )}

  .consent-checkbox {
      ${mediaFrom(
        'desktop',
        css`
          margin-bottom: 10px;
        `
      )}

      ${mediaUpTo(
        'mobile',
        css`
          margin-bottom: 8px;
        `
      )}
    }
  }

  .fields {
    ${mediaUpTo(
      'mobile',
      css`
        & .custom-text-inner {
          font-size: 11px;
        }

        & .masked-input-input,
        & .base-input-container {
          padding: 0 20px;
          height: 40px;
          border-radius: 15px;

          ${inputFontSize}
        }

        & .base-input-container {
          ${inputFontSize}
        }

        & .ant-form-item-explain-error {
          font-size: 12px;
        }

        & .ant-form-item-control-input {
          margin-bottom: 5px;
        }

        & .ant-form-item-has-error + .remember-me {
          margin-top: 14px;
        }

        & .validation-help {
          &-item {
            &:first-child {
              margin-top: 0;
            }
            margin: 6px 0;
          }
        }
      `
    )}

    & .validation-help {
      ${mediaUpTo(
        'desktop',
        css`
          margin-top: 10px;
          &-item {
            &:first-child {
              margin-top: 0;
            }
            margin: 8px 0;
          }
        `
      )}

      ${mediaBetween(
        'tablet',
        'desktop',
        css`
          margin-bottom: 20px;
        `
      )}
    }
    ${mediaFrom(
      'desktop',
      css`
        margin-bottom: 35px;
      `
    )}
  }
`;

export const SSubmitError = styled(CustomText)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const SInputGroupRegForm = styled(SInputGroup)`
  margin: 0;
  gap: 32px;

  &-label {
    padding: 0 0 11px 15px;
  }
`;
