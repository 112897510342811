import styled, { css } from 'styled-components';
import { getProp, ifProp } from 'utils/helpers/styleHelpers';

type TFooterStyles = {
  zIndex: number;
  absolutePosition: boolean;
};

export const SFooter = styled.div<TFooterStyles>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  align-self: center;

  width: 982px;
  height: 100px;
  padding: 24px 32px;
  z-index: ${getProp('zIndex')};

  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(0deg, rgba(251, 247, 239, 0.2), rgba(251, 247, 239, 0.2));
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(12px);

  ${ifProp(
    'absolutePosition',
    css`
      position: absolute;
      bottom: 5%;
    `
  )}
`;

export const SLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  z-index: 1;
`;
