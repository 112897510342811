import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { TIconName } from 'components/general/Icon/Icon.types';
import { IconSign } from 'components/general/Icon/IconSign';
import { Title, BodyText } from 'components/general/Typography';
import { SAddAccountItem } from './AddAccountItem.style';

interface IAddAccountItemProps {
  title: string;
  subtitle?: string;
  iconName: TIconName;
  isComingSoon?: boolean;
  onClick?: (() => void) | undefined;
}

export const AddAccountItem = ({ title, subtitle, iconName, onClick, isComingSoon = false }: IAddAccountItemProps) => {
  const { t } = useTranslation('moveMoney');
  return (
    <SAddAccountItem onClick={onClick} marginTop={0}>
      <div className="move-money-card">
        <Title icon={<IconSign iconName={iconName} size={40} iconColor="blue" bgColor="blue5" />} size="sS" font="DM Sans" fontWeight="B" color="charcoal" lineHeight={1.5} marginRight={24}>
          <BodyText textType="bodyText" color="charcoal" size="M" fontWeight="B" display="inline" className="move-money-card-title">
            {title}
          </BodyText>
          {isComingSoon && (
            <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginTop={5} marginLeft={5} display="inline">
              {t('ComingSoon')}
            </BodyText>
          )}
          {subtitle && (
            <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginTop={5} lineHeight={1.4} className="move-money-card-subtitle">
              {subtitle}
            </BodyText>
          )}
        </Title>
      </div>
      {!isComingSoon && <Icon name="chevronRight" color="blue" size="smaller" cursorPointer />}
    </SAddAccountItem>
  );
};
