import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { CustomText } from 'components/theme/CustomText/CustomText';
import { Form } from 'antd';
import clsx from 'clsx';
import { SMaskedInput } from './MaskedInput.styles';
import { IMaskedInput } from './MaskedInput.types';

export const MaskedInput: React.FC<IMaskedInput> = ({
  label,
  placeholderColor = 'charcoal40',
  onBeige = false,
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  minWidth = 0,
  isError = false,
  isSuccess = false,
  isBorderHidden = false,
  allowClear = true,
  addonAfter,
  ...props
}) => {
  const { status } = Form.Item.useStatus();
  const containerClassName = clsx('masked-input-input', `input-status-${status}`);

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };
  return label ? (
    <CustomRow flexDirection="column" alignItems="flex-start" className="masked-input-container">
      <CustomText marginBottom={8}>{label}</CustomText>
      <SMaskedInput
        placeholderColor={placeholderColor}
        className={containerClassName}
        onBeige={onBeige}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        minWidth={minWidth}
        isError={isError}
        isSuccess={isSuccess}
        isBorderHidden={isBorderHidden}
        onPaste={onPaste}
        addonAfter={addonAfter}
        allowClear={allowClear && { clearIcon: <Icon name="circleClose" color="charcoal40" size="smaller" className="suffix" cursorPointer /> }}
        {...props}
      />
    </CustomRow>
  ) : (
    <SMaskedInput
      placeholderColor={placeholderColor}
      className={containerClassName}
      onBeige={onBeige}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      minWidth={minWidth}
      isError={isError}
      isSuccess={isSuccess}
      isBorderHidden={isBorderHidden}
      onPaste={onPaste}
      addonAfter={addonAfter}
      allowClear={allowClear && { clearIcon: <Icon name="circleClose" color="charcoal40" size="smaller" className="suffix" cursorPointer /> }}
      {...props}
    />
  );
};
