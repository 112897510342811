import { Icon } from 'components/general/Icon/Icon';
import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SAccountFeaturePromo = styled.div`
  position: relative;
  min-height: 168px;
  border-radius: 20px;
  padding: 24px 24px 24px 112px;
  background: ${getColor('creamSS1')};
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 48px;

  ${mediaFrom(
    'desktop',
    css`
      border: 2px solid ${getColor('creamS5')};
    `
  )}

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 25px;
  }

  .close {
    position: absolute;
    z-index: 50;
    right: 20px;
    top: 20px;
    width: 8px;
    height: 8px;

    ${mediaFrom(
      'tablet',
      css`
        width: 16px;
        height: 16px;
      `
    )}
  }

  .dots-bg {
    max-width: 100%;
    width: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .logo {
    position: absolute;

    &.PennyJar {
      width: 95px;
      bottom: 10px;
      left: 0;
    }

    &.CashCushion {
      width: 120px;
      bottom: -10px;
      left: -20px;
    }
  }
`;

export const SCloseIcon = styled(Icon)`
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  top: 0;
  margin-top: 25px;
  margin-right: 25px;

  ${mediaFrom(
    'tablet',
    css`
      width: 16px;
      height: 16px;
    `
  )}
`;
