import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { groupAccounts, prepareAccount } from 'utils/helpers/accounts/accountsHelper';
import { useGetAccountsQuery, useGetThirdPartyDataQuery } from 'store/user/accounts/accounts.api';
import { selectAccountsData } from 'store/user/accounts/accounts.slice';
import { sortByProp } from 'utils/helpers/arrays';
import { useUserStatus } from './useUserStatus';

export const useAccounts = () => {
  const { isProspect } = useUserStatus();
  const {
    refetch: refetchInternal,
    isFetching,
    isLoading,
  } = useGetAccountsQuery(undefined, {
    skip: !!isProspect,
  });
  const thirdPartyDataQuery = useGetThirdPartyDataQuery(undefined, {
    skip: !!isProspect,
  });
  const { fiservAccountsData, thirdPartyData } = useSelector(selectAccountsData);
  const internalAccounts = useMemo(
    () =>
      fiservAccountsData?.partyAcctRelRec?.length
        ? sortByProp(
            fiservAccountsData?.partyAcctRelRec?.map((rawAcc) => prepareAccount(rawAcc)),
            'isDefault'
          )
        : [],
    [fiservAccountsData?.partyAcctRelRec]
  );
  const cashAccounts = useMemo(() => internalAccounts?.filter((account) => account?.isCashType) ?? [], [internalAccounts]);
  const allAccounts = useMemo(() => [...internalAccounts, ...thirdPartyData], [internalAccounts, thirdPartyData]);
  const internalAccountsGroups = groupAccounts(internalAccounts) || [];
  const defaultAccountsGroup = internalAccountsGroups.find((accGroup) => accGroup.cash?.isDefault || accGroup.cash?.isPrimary);

  return {
    isLoading: isLoading || isFetching || thirdPartyDataQuery.isLoading || thirdPartyDataQuery.isFetching,
    refetchInternal,
    internalAccounts,
    cashAccounts,
    externalAccounts: thirdPartyData,
    refetchExternal: thirdPartyDataQuery.refetch,
    internalAccountsGroups,
    defaultAccountsGroup,
    allAccounts,
  };
};
