import React from 'react';
import i18next from 'i18next';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { SContainer } from './ValidationHelp.styles';

interface IValidationHelp {
  message: string;
  matchMessage?: string;
  isError: boolean;
  isSuccess: boolean;
}

export const ValidationHelp: React.FC<IValidationHelp> = ({ message, matchMessage, isError, isSuccess }) => {
  const getTextColor = () => {
    if (isError) {
      return 'red';
    }
    if (isSuccess) {
      return 'green';
    }
    return 'charcoal60';
  };

  const getMessage = () => {
    if (matchMessage) {
      if (isError) return i18next.t(message);
      if (isSuccess) return i18next.t(matchMessage);
      return i18next.t(message);
    }

    return i18next.t(message);
  };

  return (
    <SContainer className="validation-help-item">
      {!isError && !isSuccess && <Icon name="validationDefault" color="charcoal60" size="smaller" />}
      {isError && <Icon name="circleClose" color="red" size="smaller" />}
      {isSuccess && <Icon name="tickInCircle" color="green" size="smaller" />}
      <BodyText textType="helperText" fontWeight="R" size="N" color={getTextColor()}>
        {getMessage()}
      </BodyText>
    </SContainer>
  );
};
