import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo, getProp, SCREEN_SIZE } from 'utils/helpers/styleHelpers';
import { ISInputNumber } from 'components/general/SmsCodeForm/SmsCodeForm.types';

export const SInputNumber = styled.input<ISInputNumber>`
  position: relative;
  max-width: ${getProp('boxSize', '56px')};
  height: ${getProp('boxSize', '56px')};
  min-width: 33px;
  padding: 0;
  color: ${getColor('charcoal')};
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  border: 2px solid ${getColor('charcoal10')} !important;
  border-radius: 16px;

  &:focus {
    border: 2px solid ${getColor('charcoal10')} !important;

    &::placeholder {
      display: none;
    }
  }

  ${mediaUpTo(
    SCREEN_SIZE.mobile + 30,
    css`
      height: 40px;
      max-width: 40px;
      border-radius: 12px;
    `
  )}

  ${mediaFrom(
    SCREEN_SIZE.mobile + 31,
    css`
      height: 56px;
      max-width: 56px;
    `
  )}



    ${mediaFrom(
    'tablet',
    css`
      &::placeholder {
        position: relative;
        display: block;
        bottom: -10px;
      }
    `
  )}
`;

export const SCodeContainer = styled.fieldset`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.completed {
    ${SInputNumber} {
      color: ${getColor('green')} !important;
      -webkit-text-fill-color: ${getColor('green')} !important;
      border: 2px solid ${getColor('charcoal10')};
    }
  }

  &.error {
    ${SInputNumber} {
      color: ${getColor('red')} !important;
      -webkit-text-fill-color: ${getColor('red')} !important;
      border: 2px solid ${getColor('red')} !important;
    }
  }

  ${mediaUpTo(
    400,
    css`
      gap: 2px;
    `
  )}
`;
