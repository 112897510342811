import styled, { css } from 'styled-components';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const STaxScenariosModalWrapper = styled.div`
  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .item {
      display: flex;

      .icon {
        flex-shrink: 0;
        margin-right: 12px;
      }
    }
  }
`;

export const SBreadcrumbsWrapper = styled.div`
  position: absolute;
  z-index: 199;
  left: 25px;
  top: 60px;

  .breadcrumbs {
    margin-bottom: 0;

    .icon {
      background: ${getColor('charcoal')} !important;
    }

    ol {
      display: none;
    }
  }
`;

export const STaxPlannerPage = styled(CustomRow)`
  padding-bottom: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${getColor('cream70')};

  .top-circle {
    background: ${getColor('creamSS2')};
    width: 1312px;
    height: 1022px;
    top: -815px;
    left: -442px;
    border-radius: 100%;
    position: absolute;
  }

  .top-img {
    position: relative;
    z-index: 99;
    margin-bottom: 16px;
    margin-top: 44px;
  }

  button.primary {
    padding: 12px 15px;
    white-space: unset;
  }

  .add-needs-goals-page {
    &-header {
      display: flex;
      flex-direction: column;
    }

    &-image {
      margin-bottom: 28px;
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      ${mediaUpTo(
        'mobile',
        css`
          & .title .custom-title-text {
            font-size: 28px;
          }
        `
      )}
    }

    &-footer {
      width: 100%;

      & .btn {
        white-space: normal;
      }
    }
  }

  footer {
    padding-bottom: 48px;
    margin-bottom: 10px;

    div[role='dialog'] {
      padding-right: 0;
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      padding: 14px 20px 0;

      .title .custom-title-text {
        font-size: 32px;
      }

      .account-subtitle .custom-title-text {
        font-size: 20px;
      }

      .add-needs-goals-page-body * {
        font-size: 16px;
        line-height: 24px;
      }

      .add-needs-goals-page {
        &-image {
          margin-bottom: 26px;
        }

        &-body {
          ul.options {
            li {
              padding-left: 28px;

              &::before {
                font-size: 14px;
                top: -2px;
                left: 6px;
              }
            }
          }
        }

        &-footer {
          .btn {
            font-size: 18px;
          }
        }
      }

      footer.page-footer {
        div[role='dialog'] {
          .custom-text-inner {
            line-height: 1.3;
          }
        }
      }
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      padding: 17px 16px 0;

      .title .custom-title-text {
        font-size: 28px;
      }

      .account-subtitle .custom-title-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .add-needs-goals-page-body * {
        font-size: 12px;
        line-height: 16px;
      }

      .add-needs-goals-page {
        &-image {
          margin-bottom: 37px;
        }

        &-title {
          .account-subtitle {
            .custom-title-text {
              font-size: 16px;
            }
          }
        }

        &-body {
          .description {
            margin-top: 24px;
          }

          .options-title {
            margin-top: 24px;
          }

          ul.options {
            margin-bottom: 24px;

            li {
              padding-left: 20px;

              &::before {
                font-size: 10px;
                top: -4px;
              }
            }
          }
        }

        &-footer {
          .btn {
            font-size: 16px;
            padding: 8px 24px;
          }
        }
      }

      &.goals {
        .add-needs-goals-page {
          &-image {
            img {
              width: 148px;
              height: 130px;
            }
          }
        }
      }

      footer.page-footer {
        padding: 32px 0 28px;

        div[role='dialog'] {
          .custom-text-inner {
            font-size: 11px;
          }
        }
      }
    `
  )}
`;

export const SList = styled.div`
  gap: 36px;
  flex-direction: column;
  display: flex;
`;

export const SListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

export const SPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;

  & a.to-home {
    color: ${getColor('blue')};
    font-weight: 500;
  }

  .add-success {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    &-image {
      padding-left: 36px;
      margin-bottom: 32px;
    }
  }
`;
