import { useSelector } from 'react-redux';
import { useLazyGetRiskSessionQuery, useTransferFromExternalAccountMutation, useTransferToExternalAccountMutation } from 'store/ingo/ingo.api';
import { selectCurrentTransfer, resetCurrentTransfer, setCurrentTransfer } from 'store/user/accounts/accounts.slice';
import { ICurrentTransfer } from 'store/user/accounts/accounts.types';
import { useAppDispatch } from './store';

export const useTransfers = () => {
  const dispatch = useAppDispatch();
  const currentTransfer = useSelector(selectCurrentTransfer);
  const [transferToExternal, transferToExternalResult] = useTransferToExternalAccountMutation();
  const [transferFromExternal, transferFromExternalResult] = useTransferFromExternalAccountMutation();
  const [updateRiskSession, updateRiskSessionResult] = useLazyGetRiskSessionQuery();
  const riskSessionData = updateRiskSessionResult?.data;
  const riskSessionToken = riskSessionData?.session?.risk_session_token;
  const isTransferDataLoading = transferToExternalResult.isLoading || transferFromExternalResult.isLoading || updateRiskSessionResult.isLoading || updateRiskSessionResult.isFetching;

  const saveCurrentTransfer = (transferData: ICurrentTransfer) => {
    dispatch(setCurrentTransfer(transferData));
  };

  const clearCurrentTransfer = () => {
    dispatch(resetCurrentTransfer());
  };

  return {
    isTransferDataLoading,
    transferToExternal,
    transferToExternalResult,
    transferFromExternal,
    transferFromExternalResult,
    updateRiskSession,
    riskSessionToken,
    riskSessionData,
    clearCurrentTransfer,
    saveCurrentTransfer,
    currentTransfer,
  };
};
