import React from 'react';
import { images } from 'assets';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SContainer } from './NotificationModal.styles';

interface INotificationModal {
  isOpen: boolean;
  title: string;
  description?: string;
  btnName?: string;
  onClose: () => void;
}

export const NotificationModal: React.FC<INotificationModal> = ({ isOpen, title, description, btnName, onClose }) => {
  return (
    <CustomModal open={isOpen} modalName="request_notification" topPosition="0" width="480px" closeIconColor="charcoal70" padding="24px" onClose={onClose}>
      <SContainer>
        <div className="image-container">
          <img src={images.transferFailNoCircles} alt="fail" />
        </div>

        <div className="content-container">
          <Title size="XL" fontWeight="M" marginBottom={18}>
            {title}
          </Title>

          {description && (
            <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" justifyContent="center" textAlign="center" marginBottom={24}>
              {description}
            </BodyText>
          )}

          {btnName && (
            <Button preset="blue-filled" width="unset" onClick={onClose}>
              {btnName}
            </Button>
          )}
        </div>
      </SContainer>
    </CustomModal>
  );
};
