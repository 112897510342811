import styled from 'styled-components';
import { CustomText } from 'components/theme/CustomText/CustomText';

export const SSmsCodeWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  position: relative;
`;

export const SError = styled(CustomText)`
  position: relative;
  bottom: -15px;
  margin: 0;
`;
