import styled from 'styled-components';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;

    h1 {
      font-family: Poppins;
      font-size: 48px;
      font-weight: 500;
      line-height: 48px;
      margin-bottom: 16px;
      text-align: center;
    }

    button {
      min-width: 166px;
      max-width: 215px;
      height: 52px;
      padding: 14px 32px;

      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;
