import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useConsents } from 'utils/hooks/useConsents';
import { BodyText, Title } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Loader } from 'components/general/Loader/Loader';
import { ConsentType } from 'components/general/Consent/Consents.types';
import { DisclosureItem } from './DisclosureItem';
import { SDisclosureContainer, SDisclosuresPageContent, STitleContainer } from './DisclosuresPage.styles';

export const DisclosuresPage = () => {
  const percapitaConsents = useConsents(ConsentType.PERCAPITA);
  const ingoConsents = useConsents(ConsentType.INGO);
  const otherConsents = useConsents(ConsentType.OTHERS);
  const consentsPackage = [ingoConsents, otherConsents];

  const isLoading = percapitaConsents.getConsentByFlowNameResult.isLoading || ingoConsents.getConsentByFlowNameResult.isLoading || otherConsents.getConsentByFlowNameResult.isLoading;
  const [searchParams] = useSearchParams();
  const currentOpenId = searchParams.get('id');
  const { t } = useTranslation('moveMoney');

  return (
    <CustomRow alignItems="start" flexDirection="column" marginTop={6} marginBottom={12}>
      {isLoading && <Loader />}
      <STitleContainer>
        <Title size="M" color="charcoal" fontWeight="SB" font="Poppins" marginBottom={8}>
          {t(`Documents`)}
        </Title>

        <BodyText size="M" color="charcoal70">
          {t('YouCanFindYourDocumentsHere')}
        </BodyText>
      </STitleContainer>

      {!!percapitaConsents?.consentsData?.length && (
        <SDisclosuresPageContent>
          <BodyText className="title-section" size="M" font="Poppins" fontWeight="SB">
            {t('Percapita Disclosures')}
          </BodyText>

          <SDisclosureContainer key={currentOpenId} marginBottom={24}>
            {percapitaConsents?.consentsData?.map((data) => (
              <DisclosureItem consentData={data} isOpen={data.id === currentOpenId} key={data.id} />
            ))}
          </SDisclosureContainer>

          {consentsPackage?.length && (
            <>
              <BodyText className="title-section" size="M" font="Poppins" fontWeight="SB">
                {t('Others')}
              </BodyText>

              <SDisclosureContainer key={currentOpenId}>
                {consentsPackage.map((consent) => consent?.consentsData?.map((data) => <DisclosureItem consentData={data} isOpen={data.id === currentOpenId} key={data.id} />))}
              </SDisclosureContainer>
            </>
          )}
        </SDisclosuresPageContent>
      )}
    </CustomRow>
  );
};
