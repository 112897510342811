import React, { useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { IVerifySmsCodeForm, FormInputProps } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { getRequiredRule } from 'utils/helpers/validationRules';
import { BodyText } from 'components/general/Typography';
import { DigitalCodeInput } from 'components/general/DigitalCodeInput/DigitalCodeInput';

export const SmsForm: React.FC<FormInputProps<IVerifySmsCodeForm>> = ({ handleSubmit, onCompletion, form, size = 5, errorMessage, displayInlineText }) => {
  const { t } = useTranslation('cashAccountOpening');

  const onFieldsChange = () => {
    const hasErrors = form.isFieldsTouched() && form.getFieldsError().some(({ errors }) => errors.length);
    onCompletion(hasErrors);
  };

  const fillForm = (pasted: string) => {
    const numberRegex = /^[0-9]+$/;

    if (numberRegex.test(pasted)) {
      form.setFieldValue('emailCode', pasted);
      form.validateFields();

      const valuesArray = pasted.split('').slice(0, 5);

      valuesArray.forEach((char, index) => {
        form.setFieldValue(['list-emailCode', index], char);
      });
    }
  };

  const onPaste = async (event: React.ClipboardEvent<HTMLFormElement>) => {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');

    fillForm(pasted);
  };

  useEffect(() => {
    document.addEventListener('keydown', async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        event.preventDefault();
        const clipboardData = await navigator.clipboard.readText();

        fillForm(clipboardData);
      }
    });
  }, []);

  return (
    <Form onPaste={onPaste} onFieldsChange={onFieldsChange} onFinish={handleSubmit} autoComplete="off" layout="vertical" requiredMark={false} form={form}>
      {displayInlineText ? (
        <BodyText textType="bodyText" marginBottom={24} textAlign="center" color="charcoal" fontWeight="R" size="N">
          {t('myInfo.Enter the verification code')}
        </BodyText>
      ) : null}
      <Form.Item
        name="emailCode"
        initialValue=""
        validateTrigger={['onBlur', 'onChange']}
        validateFirst
        rules={[
          getRequiredRule(errorMessage || 'myInfo.Please input your sms code'),
          {
            type: 'string',
            min: size,
            message: t('myInfo.Please input complete code'),
          },
        ]}
      >
        <DigitalCodeInput name="emailCode" size={size} borderRadius="20px" />
      </Form.Item>
    </Form>
  );
};
