import styled, { css } from 'styled-components';
import { getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  background: ${getColor('white')};
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 32px;

  ${mediaFrom(
    'desktop',
    css`
      width: 100%;
      margin-top: 45px;
      margin-bottom: 0;
    `
  )}

  ${mediaBetween(
    'tablet',
    730,
    css`
      width: 500px;
    `
  )}

  ${mediaBetween(
    731,
    'desktop',
    css`
      width: 650px;
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      margin-bottom: 0;
    `
  )}

  .mobile-title-container {
    display: flex;
    justify-content: flex-start;
    aling-items: center;
    width: 100%;

    ${mediaFrom(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .web-title-container {
    display: flex;
    justify-content: center;
    aling-items: center;
    flex-direction: column;
    gap: 16px;

    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .create-btn {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    white-space: break-spaces;

    ${mediaFrom(
      'desktop',
      css`
        max-width: 410px;
        font-weight: 600;
      `
    )}
  }

  .list-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;

    ${mediaUpTo(
      'desktop',
      css`
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-top: 24px;
      `
    )}
  }
`;
