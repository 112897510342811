import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from 'vars/const/ROUTES';
import { ResultsPage } from 'views/OpenCashAccount/MyIdPage/ResultsPage/ResultsPage';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useAddAccountMutation } from 'store/user/accounts/accounts.api';
import { EAccountType } from 'store/user/accounts/accounts.types';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { API_RESPONSE_CODES } from 'vars/const/API_CODES';
import { useLazyGetCurrentUserQuery, usersApi } from 'store/user/users.api';
import { useAppDispatch } from 'utils/hooks/store';
import { Loader } from 'components/general/Loader/Loader';
import { useProperties } from 'utils/hooks/useProperties';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { ErrorModal } from './ErrorModal';

const JOINT_TYPES = ['Joint', 'JointOwner'];

export const MyIdMatch = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['cashAccountOpening', 'preRegOnboarding']);
  const { getProperty } = useProperties();
  const [addAccount, addAccountResult] = useAddAccountMutation();
  const [getCurrentUser, getCurrentUserResult] = useLazyGetCurrentUserQuery();
  const invalidateMenuData = useCallback(() => dispatch(usersApi.util.invalidateTags(['Menu'])), [dispatch]);
  const { saveOnboardingData, openingAccountData } = useCashAccountOpening();
  const policies = useSelector(selectPolicies);
  const [jointType, setJointType] = useState('');
  const defineBtnText = useMemo(() => (jointType === JOINT_TYPES[0] ? 'accountOpening.JoinAccount' : 'accountOpening.Open My Account'), [jointType]);
  const { referralCode } = useSelector(selectCurrentUser);
  const [transactionId] = useState(() => uuidv4());

  const handleGoToMain = () => {
    navigate(ROUTES.home.path);
  };

  const handleOpenAccount = () => {
    addAccount({
      transactionId,
      percapitaAccountType: EAccountType.CASH,
      nickname: '',
      jointInviteType: JOINT_TYPES.includes(jointType) ? jointType : '',
      inviteId: openingAccountData?.currentJointInviteId,
      userReferralCode: referralCode || getCurrentUserResult?.data?.referralCode || getCurrentUserResult?.currentData?.referralCode || openingAccountData?.referralCode,
    })
      .unwrap()
      .then(() => {
        saveOnboardingData({ referralCode: '' });
        getCurrentUser()
          .unwrap()
          .then(() => {
            navigate(ROUTES.myIdSuccess.path);
          });
      })
      .catch((error: any) => {
        if (error.status === 'FETCH_ERROR' || error.status === 'TIMEOUT_ERROR') {
          navigate(ROUTES.home.path);
        }

        if (error.data?.Code === API_RESPONSE_CODES.FISERV_ADD_CARD_ERROR || error.data?.Code === API_RESPONSE_CODES.PARTY_ID_CREATED_FAILED_TO_OPEN_ACCOUNT) {
          invalidateMenuData();
        }
      });
  };

  useEffect(() => {
    getCurrentUser();
    getProperty('CAOType')
      .unwrap()
      .then((response: string) => {
        setJointType(response);
      });
  }, []);

  return (
    <>
      {(addAccountResult?.isLoading || getCurrentUserResult?.isLoading) && <Loader />}
      <ResultsPage
        image="success"
        title={t('preRegOnboarding.ThankYou', { ns: 'preRegOnboarding' })}
        btnTitle={t(policies?.AccountOpeningEnabled ? defineBtnText : 'accountOpening.GoToMainPage')}
        onClick={policies?.AccountOpeningEnabled ? handleOpenAccount : handleGoToMain}
      >
        <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="M" justifyContent="center" lineHeight="24px">
          {t('accountOpening.IDVerified')}
        </BodyText>
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="M" textAlign="center" lineHeight="24px">
          {t('accountOpening.SubmitYourApplication')}
        </BodyText>
      </ResultsPage>
      <ErrorModal open={addAccountResult.isError} />
    </>
  );
};
