import React, { useEffect, useMemo, useState } from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { useGetATMLocationsMutation, useLazyGetATMByAddressQuery } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { IATMLocations, IApiType } from 'store/user/atmLocations/atmLocations.types';
import { useSelector } from 'react-redux';
import { selectAtmLocationsData } from 'store/user/atmLocations/atmLocations.slice';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useToggle } from 'utils/hooks/useToggle';
import { useLanguage } from 'utils/hooks/useLanguage';
import { ProfileEditSheet } from 'views/Profile/ProfilePage/ProfileEditSheet/ProfileEditSheet';
import { Tag } from 'views/Account/BalancesTransactionsPage/Tag/Tag';
import { useGetUserLocation } from './helpers/useGetUserLocation';
import { Card } from './Card/Card';
import { SCustomButton, SContentWrapper, SAddressCard, SDisclosure, SWrapper, SLocationIconContainer, SSearchForm } from './ATMLocationsPage.styles';
import { FilterSheet, ICheckboxes } from './FilterSheet/FilterSheet';
import { SearchSheet } from './SearchSheet/SearchSheet';
import { createFilterString } from './helpers/createFilterString';
import { initialTagsData } from './constants';
import { AzureMap } from './AzureMap/AzureMap';
import { ATMLocationsInfoModal } from './ATMLocationsInfoModal';

export interface ITagsData {
  [key: string]: string | boolean;
}

export const ATMLocationsPage = () => {
  const { latitude, longitude } = useGetUserLocation();
  const { t } = useTranslation('atmLocations');
  const { language } = useLanguage();
  const user = useSelector(selectCurrentUser);
  const [isMapView, setIsMapView] = useState(false);
  const { isDesktopSize, fromDesktopSmall, isMobileSmallSize } = useDeviceDimension();
  const infoModal = useToggle();

  const [getATMLocations, getATMLocationsResult] = useGetATMLocationsMutation();
  const [getATMByAddress, getATMByAddressResult] = useLazyGetATMByAddressQuery();

  const { foundAtmLocations } = useSelector(selectAtmLocationsData);
  const [filteredData, setFilteredData] = useState<IATMLocations[] | null>(null);
  const [apiType, setApiType] = useState<IApiType>('locationFilter');
  const [inputAddress, setInputAddress] = useState('');

  const [isNoFeeFiltered, setIsNoFeeFiltered] = useState(false);
  const [isOpen24Filtered, setIsOpen24Filtered] = useState(false);
  const [checkboxes, setCheckboxes] = useState<ICheckboxes>({
    noFee: false,
    openNow: false,
    wheelchair: false,
  });

  const [tagsData, setTagsData] = useState<ITagsData>(initialTagsData);

  const filterSheet = useToggle(false);
  const searchSheet = useToggle(false, { title: t('Search') });
  const editProfileSheet = useToggle(false, '');

  const filterNoFee = () => {
    setTagsData({
      ...tagsData,
      noFee: !isNoFeeFiltered,
    });
    setCheckboxes({
      ...checkboxes,
      noFee: !isNoFeeFiltered,
    });
    setIsNoFeeFiltered(!isNoFeeFiltered);
  };
  const filterOpen24 = () => {
    setTagsData({
      ...tagsData,
      openNow: !isOpen24Filtered,
    });
    setCheckboxes({
      ...checkboxes,
      openNow: !isOpen24Filtered,
    });
    setIsOpen24Filtered(!isOpen24Filtered);
  };

  const openFilterSheet = () => {
    filterSheet.show();
    setIsNoFeeFiltered(false);
    setIsOpen24Filtered(false);
  };

  const onCloseSearch = () => {
    searchSheet.hide();
    searchSheet.setData({ title: t('Search') });
  };

  const handleSearch = (address: string) => {
    setInputAddress(address);
    setFilteredData([]);
    setTagsData({
      ...initialTagsData,
      searchString: address,
    });
  };

  const openSearchByAddress = () => {
    setApiType('addressFilter');
    setIsNoFeeFiltered(false);
    setIsOpen24Filtered(false);
    setTagsData(initialTagsData);
    searchSheet.setData({ title: t('SearchByCities') });
  };

  const getDataByUserLocation = () => {
    setApiType('locationFilter');
    setInputAddress('');
    setIsNoFeeFiltered(false);
    setIsOpen24Filtered(false);
    setTagsData(initialTagsData);
    onCloseSearch();
  };

  const getFilteredData = (filter: any) => {
    if (apiType === 'locationFilter' && latitude !== undefined && longitude !== undefined) {
      getATMLocations({ latitude, longitude, filter });
    }
    if (apiType === 'addressFilter' && inputAddress) {
      getATMByAddress({ address: inputAddress, filter });
    }
  };

  const handleFilter = () => {
    const formData = {
      ...tagsData,
      ...checkboxes,
    };
    setTagsData(formData);
  };

  const closeTag = (tagName: string) => {
    if (tagName === 'searchString') {
      setInputAddress('');
      setApiType('locationFilter');
    }
    setTagsData({
      ...tagsData,
      [tagName]: false,
    });
    setCheckboxes({
      ...checkboxes,
      [tagName]: false,
    });
  };

  const renderTag = useMemo(() => {
    return Object.entries(tagsData).map((tag) => {
      const isVisible = tag[0] === 'searchString' || tag[0] === 'wheelchair';
      // eslint-disable-next-line no-nested-ternary
      const title = typeof tag[1] === 'string' ? tag[1] : tag[0] === 'wheelchair' ? t('WheelchairAccessible') : tag[0];
      return tag[1] && isVisible && <Tag title={title} key={title} onClose={() => closeTag(tag[0])} isClosable />;
    });
  }, [tagsData, language, checkboxes]);

  const desktopSearch = (searchString: string) => {
    setApiType('addressFilter');
    setIsNoFeeFiltered(false);
    setIsOpen24Filtered(false);
    setTagsData(initialTagsData);
    handleSearch(searchString);
    searchSheet.setData({ title: t('Search') });
  };

  useEffect(() => {
    const filter: string[] = createFilterString(tagsData, setIsNoFeeFiltered, setIsOpen24Filtered);
    if (filter.length) {
      getFilteredData(filter);
    } else {
      getFilteredData('');
    }
  }, [tagsData, longitude, latitude]);

  useEffect(() => {
    setFilteredData(foundAtmLocations);
  }, [foundAtmLocations]);

  return (
    <SWrapper className="wrapper">
      <div className="heading">
        <Title size="S" color="charcoal" fontWeight="SB" font="Poppins" marginBottom={16}>
          {t('ATMLocations')}
          <Icon name="info" color="blue" pictBgColor="blue" cursorPointer marginLeft={14} size="smaller" onClick={() => infoModal.show()} />
        </Title>
        <BodyText fontWeight="R" size="N" textType="bodyText" color="charcoal70" className="subtitle">
          {t('Information')}
        </BodyText>
      </div>
      <SContentWrapper>
        {isDesktopSize && (
          <SAddressCard background="beige">
            <CustomRow justifyContent="flex-start" alignItems="start" flexDirection="column">
              <BodyText fontWeight="R" size="M" textType="bodyText" color="charcoal70" lineHeight="24px">
                {t('HomeAddress')}
              </BodyText>
              <BodyText fontWeight="M" size="M" textType="bodyText" color="charcoal" lineHeight="24px">
                {user?.address1}
              </BodyText>
              <BodyText fontWeight="M" size="M" textType="bodyText" color="charcoal" lineHeight="24px">
                {user?.city}, {user?.stateProvince} {user?.postalCode}
              </BodyText>
            </CustomRow>
            <Icon name="edit" color="blue" size="small" onClick={editProfileSheet.show} cursorPointer />
          </SAddressCard>
        )}

        <CustomRow className="filter" flexWrap={isMobileSmallSize ? 'wrap' : 'unwrap'} gap={10} marginTop={16} marginBottom={32} justifyContent="space-between">
          <div className="atm-location-tooltips">
            <SCustomButton size="middle" preset="clear" type="button" onClick={filterNoFee} className={isNoFeeFiltered ? 'active' : 'first-tooltip'}>
              <Icon name="noFee" size="small" color={isNoFeeFiltered ? 'white' : 'charcoal70'} marginRight={8} />
              {t('NoFee')}
            </SCustomButton>
            <SCustomButton size="middle" preset="clear" type="button" onClick={filterOpen24} className={isOpen24Filtered ? 'active' : 'second-tooltip'}>
              <Icon name="circleTime" size="small" color={isOpen24Filtered ? 'white' : 'charcoal70'} marginRight={8} />
              {t('Open24')}
            </SCustomButton>
          </div>

          <div className="atm-location-tools">
            <button type="button" onClick={openFilterSheet}>
              <Icon name="filter" size="normal" color="charcoal70" cursorPointer />
            </button>
            {fromDesktopSmall ? (
              <SSearchForm searchType="City" onClose={() => {}} handleSearch={desktopSearch} />
            ) : (
              <button type="button" onClick={searchSheet.show}>
                <Icon name="search" size="normal" color="charcoal70" cursorPointer />
              </button>
            )}
          </div>
        </CustomRow>

        <CustomRow justifyContent="flex-start">{renderTag}</CustomRow>

        {isMapView ? (
          <AzureMap atms={filteredData || []} />
        ) : (
          <div>
            {filteredData?.length ? (
              // eslint-disable-next-line react/no-array-index-key
              <section className="locations">{filteredData && filteredData?.map((item, i) => <Card data={item} key={i} lat={latitude} long={longitude} />)}</section>
            ) : (
              <BodyText fontWeight="M" size="M" textType="bodyText" color="charcoal40" lineHeight="24px">
                {t('NothingFound')}
              </BodyText>
            )}
          </div>
        )}

        <SLocationIconContainer onClick={() => setIsMapView(!isMapView)}>
          <div className="location-btn">
            <Icon name={isMapView ? 'menuList' : 'atmLocation'} color="white" size="normal" cursorPointer />
          </div>
        </SLocationIconContainer>

        {(getATMLocationsResult.isLoading || getATMByAddressResult.isFetching) && <Loader />}
      </SContentWrapper>

      <SDisclosure>
        <SuttonDisclaimerNote footerTextType="Sutton" />
      </SDisclosure>

      <FilterSheet open={filterSheet.isActive} onClose={filterSheet.hide} onFilter={handleFilter} checkboxes={checkboxes} setCheckboxes={setCheckboxes} />

      <SearchSheet
        open={searchSheet.isActive}
        data={searchSheet.data}
        onClose={onCloseSearch}
        handleSearch={handleSearch}
        getDataByUserLocation={getDataByUserLocation}
        openSearchByAddress={openSearchByAddress}
      />

      <ProfileEditSheet isOpen={editProfileSheet.isActive} type="address" closeSheet={editProfileSheet.hide} />
      <ATMLocationsInfoModal isOpen={infoModal.isActive} handleClose={infoModal.hide} />
    </SWrapper>
  );
};
