import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { generatePath, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'utils/hooks/store';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { Title, BodyText } from 'components/general/Typography';
import { WELLNESS_FAQ_GROUP_ID } from 'vars/const/FAQ';
import { ROUTES } from 'vars/const/ROUTES';
import { selectDisplayPercPlaysInfoModal, setDisplayPercPlaysInfoModal } from 'store/ui.slice';
import { SListItem, SListItemText } from 'components/general/Modals/AdditionalInformationHandler/AdditionalInformationHandler.styles';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

export const PercapitaPlaysInfoModal = () => {
  const isModalVisible = useSelector(selectDisplayPercPlaysInfoModal);
  const { isDesktopSize } = useDeviceDimension();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('home');
  const { pathname } = useLocation();

  const handleNavigation = () => {
    dispatch(setDisplayPercPlaysInfoModal(false));
    if (!isDesktopSize) {
      const path = generatePath(ROUTES.helpAndSupport.path, { groupId: WELLNESS_FAQ_GROUP_ID });
      navigate(path, { state: { backPage: pathname, isFromPercPlaysInfoModal: true } });
    } else {
      navigate(generatePath(ROUTES.wellness.path, { tabName: 'help' }));
    }
  };

  const isFromPercPlaysInfoModal = searchParams.get('isFromPercPlaysInfoModal');

  useEffect(() => {
    if (isFromPercPlaysInfoModal) {
      navigate(ROUTES.home.path);
      dispatch(setDisplayPercPlaysInfoModal(true));
    }
  }, [isFromPercPlaysInfoModal]);

  const onCancel = () => dispatch(setDisplayPercPlaysInfoModal(false));

  return (
    <CustomModal
      open={isModalVisible}
      onCancel={onCancel}
      modalName="common_percapitaPlaysInfoModal"
      closeIconColor="charcoal70"
      topPosition="7%"
      className="percapitaPlaysInfo"
      bodyStlye={{ overflowY: 'hidden' }}
    >
      <div className="percapitaPlaysInfoHeader">
        <Title font="Poppins" color="charcoal" size="M" fontWeight="M" marginBottom={20} paddingBottom={5}>
          {t(`home.PercapitaPlaysPopUpTitle`)}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" lineHeight={1.4}>
          {t(`home.PercapitaPlaysPopUpSubTitle`)}
        </BodyText>
      </div>

      <div className="percapitaPlaysInfoBody">
        <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="N" marginBottom={12}>
          {t(`home.PercapitaPlaysPopUpColonText`)}
        </BodyText>
        <SListItem className="percapitaPlaysInfoListItem">
          <SListItemText className="percapitaPlaysInfoListItemText">{t(`home.PercapitaPlaysPopUpListText_1`)}</SListItemText>
          <SListItemText>{t(`home.PercapitaPlaysPopUpListText_2`)}</SListItemText>
        </SListItem>
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" lineHeight={1.4} marginTop={10}>
          {t(`home.PercapitaPlaysPopUpBodyText`)}
          <BodyText textType="bodyText" color="blue" fontWeight="R" size="N" lineHeight={1.4} display="inline" onClick={handleNavigation} cursorPointer>
            {t(`home.PercapitaPlaysPopUpFAQ`)}.
          </BodyText>
        </BodyText>
      </div>
      <hr className="percapitaPlaysInfoLine" />

      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" lineHeight={1.4}>
        {t(`home.PercapitaPlaysPopUpFooterText`)}
      </BodyText>
    </CustomModal>
  );
};
