import styled, { css } from 'styled-components';
import { flexCenter, getColorByProp, getProp, ifProp, round, mediaUpTo } from 'utils/helpers/styleHelpers';
import { TThemeColor } from 'styles/theme';
import { ISSvgIconProps } from './Icon.types';

export const SIconContainer = styled.div<ISSvgIconProps>`
  width: ${getProp('width')}px;
  height: ${getProp('height')}px;
  margin-left: ${getProp('marginLeft', 0)}px;
  margin-right: ${getProp('marginRight', 0)}px;
  margin-top: ${getProp('marginTop', 0)}px;
  margin-bottom: ${getProp('marginBottom', 0)}px;
  background: ${getColorByProp('color')} no-repeat center !important;
  //noinspection CssUnknownTarget
  mask: url(/svg-icons.svg#icon-${getProp('name')}) center no-repeat !important;
  mask-size: cover;
  cursor: ${ifProp('cursorPointer', 'pointer', 'inherit')};

  ${ifProp(
    'clear',
    css`
      background: url(/svg-icons.svg#icon-${getProp('name')}) center no-repeat !important;
      mask: none !important;
    `
  )};
`;

export const SIconSign = styled.div<{ bgColor: TThemeColor; size?: number }>`
  ${flexCenter};
  flex: 0 0 auto;
  ${(props) => round(props.size ?? 48, props.bgColor)};

  ${mediaUpTo(
    'tablet',
    css`
      width: 40px;
      height: 40px;
      padding: 10px;
    `
  )}
  ${mediaUpTo(
    'mobile',
    css`
      width: 32px;
      height: 32px;
      padding: 6px;
    `
  )}
`;
