import React from 'react';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../../../package.json';
import { SAuthLayoutFooter } from './AuthLayoutFooter.styles';

export const AuthLayoutFooter = () => {
  const { t } = useTranslation('preRegOnboarding');

  return (
    <SAuthLayoutFooter className="auth-footer">
      <p>{packageJson.version}</p>
      <p>{t('preRegOnboarding.CopyrightPercapita', { year: new Date().getFullYear() })}</p>
    </SAuthLayoutFooter>
  );
};
