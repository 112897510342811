import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NotificationsTab } from './NotificationsTab/NotificationsTab';
import { NotificationsTabLayout } from './NotificationsTab/NotificationsTab.styles';
import { SWrapper, SPageHeader } from './NotificationsAndAlertsPage.styles';

export const NotificationsAndAlertsPage = () => {
  const { t } = useTranslation('notificationsAlerts');
  const { state } = useLocation();

  const breadCrumbs = [
    {
      name: t('Accounts'),
      url: `/account_information/percapita/`,
    },
    {
      name: t('Your Cash Account'),
      url: `/account_information/percapita/${state?.accountId || ''}`,
    },
    {
      name: t('Notifications&AlertsSettings'),
    },
  ];
  return (
    <NotificationsTabLayout>
      <SPageHeader title={state?.isFromAccountInfo ? t('Accounts') : t('Notifications&Alerts')} hasNavBack={!!state?.isFromAccountInfo} crumbs={state?.isFromAccountInfo ? breadCrumbs : []} />

      <SWrapper>
        <NotificationsTab />
      </SWrapper>
    </NotificationsTabLayout>
  );
};
