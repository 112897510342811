import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCustomCard = styled(CustomCard)`
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 2px solid ${getColor('charcoal5')};
  padding: 20px 24px;
  margin-top: 0;
  margin-bottom: 16px;

  .arrow {
    margin-left: auto;
  }
`;
