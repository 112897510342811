import React from 'react';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { AddExternalAccountBlock } from 'views/AddExternal/AddExternalAccountBlock';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onAddNewAccountSuccess: () => void;
  disabledAddCards?: boolean;
}

export const AddNewExternalAccountModal = ({ isOpen, onClose, onAddNewAccountSuccess, disabledAddCards }: IProps) => {
  const { isDesktopSize } = useDeviceDimension();

  return (
    <CustomSheet
      className="add-external-account"
      isModal={isDesktopSize}
      isOpen={isOpen}
      modalName="addNewExternalAccountModal"
      header={false}
      wrapperPadding={isDesktopSize}
      contentWrapperStyle={{ borderRadius: '24px 24px 0px 0px' }}
      onClose={onClose}
      width={isDesktopSize ? '536px' : '100%'}
    >
      <AddExternalAccountBlock onAddNewAccountSuccess={onAddNewAccountSuccess} onAddNewAccountStart={onClose} disabledAddCards={disabledAddCards} />
    </CustomSheet>
  );
};
