import React from 'react';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { LogoutModal } from 'components/general/Modals/LogoutModal/LogoutModal';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface ILogoutItemProps {
  isVertical?: boolean;
}

export const LogoutItem: React.FC<ILogoutItemProps> = ({ isVertical = false }) => {
  const { t } = useTranslation();
  const logoutModal = useToggle(false);
  const { isDesktopSize } = useDeviceDimension();

  const handleOnClick = () => {
    logoutModal.show();
  };

  return (
    <>
      <CustomRow className="logout-btn" onClick={handleOnClick} justifyContent="flex-start" flexDirection={isVertical ? 'column' : 'row'} gap={isVertical ? 6 : 0} marginBottom={isVertical ? 0 : 25}>
        <Icon name="logout" color="red" cursorPointer />
        <BodyText color="red" size={isDesktopSize ? 'T' : 'M'} cursorPointer marginLeft={isVertical ? 0 : 12} fontWeight="B">
          {t('menu.Log Out')}
        </BodyText>
      </CustomRow>

      <LogoutModal open={logoutModal.isActive} onClose={logoutModal.hide} />
    </>
  );
};
