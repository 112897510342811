import React from 'react';
import { Icon } from 'components/general/Icon/Icon';
import { useToggle } from 'utils/hooks/useToggle';
import { BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { useGetBridgeTokenQuery } from 'store/user/truv/truv.api';
import { ListItem } from 'views/Transactions/DirectDeposit/SetUpDeposit/ListItem/ListItem';
import { TruvBridgeElement } from 'views/Transactions/DirectDeposit/TruvBridge/TruvBridge';
import { SBodyText, SDivider, SWrapper } from 'views/Transactions/DirectDeposit/SetUpDeposit/SetUpDepositPage.styles';
import { Loader } from 'components/general/Loader/Loader';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SCard } from './FindEmployerSection.styles';

export const FindEmployerSection = ({ accountId }: { accountId: string }) => {
  const { t } = useTranslation('deposit');
  const bridgeTokenQuery = useGetBridgeTokenQuery(accountId);
  const truvBridge = useToggle();
  const { fromDesktopSmall } = useDeviceDimension();

  return (
    <>
      <SWrapper>
        {bridgeTokenQuery.isLoading && <Loader />}
        <SCard>
          <div className="web-title">{t('setUpDeposit.automatically.SetupRightNow')}</div>

          <div className="mobile-title">{t('setUpDeposit.automatically.Title')}</div>

          <div className="web-items">
            <ListItem order="1" text={t('setUpDeposit.automatically.UseButton')} />
            <ListItem order="2" text={t('setUpDeposit.automatically.AbleSelect')} />
            <ListItem order="3" text={t('setUpDeposit.automatically.Instructions')} />
          </div>

          <div className="mobile-items">
            <ListItem order="1" text={t('setUpDeposit.automatically.LoginToYourEmployer')} />
            <ListItem order="2" text={t('setUpDeposit.automatically.SelectAmount')} />
          </div>

          <div className="divider" />

          <div className="web-truv-description">
            <BodyText justifyContent="center" textAlign="center" textType="bodyText" color="charcoal70" size="T" marginTop={15} marginBottom={15}>
              {t('setUpDeposit.automatically.TruvDescription')}
            </BodyText>
          </div>

          <Button preset="blue-filled" onClick={truvBridge.show} className="find-employer-btn">
            {t('setUpDeposit.automatically.FindEmployer')} {!fromDesktopSmall && <Icon name="chevronRight" color="white" size="smaller" marginLeft={10} />}
          </Button>
        </SCard>

        {!fromDesktopSmall && (
          <div className="mobile-truv-description">
            <SBodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" justifyContent="start" marginTop={20} paddingRight={10}>
              {t('setUpDeposit.automatically.TruvDescription')}
            </SBodyText>
          </div>
        )}

        {bridgeTokenQuery.isSuccess && <TruvBridgeElement bridgeToken={bridgeTokenQuery.data.bridge_token} isOpen={truvBridge.isActive} close={truvBridge.hide} />}
      </SWrapper>
      <div className="autodeposit-footer">
        <SDivider />
        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" nowrap>
          {t('setUpDeposit.Or')}
        </BodyText>
        <SDivider />
      </div>
    </>
  );
};
