import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import React from 'react';
import { images } from 'assets';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SContainer } from './AccountApprovedModal.styles';

interface IAccountApprovedModal {
  isOpen: boolean;
  onClose: () => void;
}

export const AccountApprovedModal: React.FC<IAccountApprovedModal> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnComplete = () => {
    navigate(ROUTES.percapitaPayHome.path);
  };

  return (
    <CustomModal open={isOpen} closeIconColor="charcoal70" modalName="termsAndConditions_accountApprovedModal" onClose={onClose} topPosition="0" width="50%">
      <SContainer>
        <img src={images.congratulation} alt="enroll" />

        <div>
          <Title size="M" fontWeight="M" font="Poppins" textAlign="center" justifyContent="center">
            {t('enrollAccountApproved.Congrats!')}
          </Title>
          <Title size="M" fontWeight="M" font="Poppins" textAlign="center">
            {t("enrollAccountApproved.You've been enrolled for Percapita Pay.")}
          </Title>
        </div>

        <Button preset="blue-filled" onClick={handleOnComplete}>
          {t('enrollAccountApproved.Continue')}
        </Button>
      </SContainer>
    </CustomModal>
  );
};
