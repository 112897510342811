import React from 'react';
import isBefore from 'date-fns/isBefore';
import { useTranslation, Trans } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useSelector } from 'react-redux';
import { selectDisplayPercapitaPayModal, setShowPercapitaPayModal } from 'store/ui.slice';
import { useAppDispatch } from 'utils/hooks/store';
import { usePayments } from 'utils/hooks/usePayments';
import { SAmountText } from './PercapitaPayModal.styles';

export const PercapitaPayModal: React.FC = () => {
  const { t } = useTranslation('percapitaPay');
  const dispatch = useAppDispatch();
  const { displayPercapitaPayModal, percapitaPayModalType } = useSelector(selectDisplayPercapitaPayModal);
  const { paymentsInfo } = usePayments();
  const payPeriod = Number(paymentsInfo.maxPayPeriodTransactionsCount) - Number(paymentsInfo.transfersAvailable);
  const modalName = percapitaPayModalType ? `common_percapitaPay_${percapitaPayModalType}Modal` : 'common_percapitaPayModal';

  const onCancel = () => dispatch(setShowPercapitaPayModal({ displayPercapitaPayModal: false }));
  const DAILY_PAY_LIMIT = 250;
  const isPayAvailable = percapitaPayModalType === 'earned' && Number(paymentsInfo.availableNow) <= DAILY_PAY_LIMIT;

  const submitByDate = new Date(paymentsInfo.submitByDate ?? '');
  const endDate = new Date(paymentsInfo.earnCicleEndDate ?? '');
  const hasAvailableTransfers = paymentsInfo.transfersAvailable ? paymentsInfo.transfersAvailable > 0 : false;
  const isPeriodClosed = isBefore(submitByDate, new Date()) && isBefore(submitByDate, endDate);

  return (
    <CustomModal open={displayPercapitaPayModal} onCancel={onCancel} modalName={modalName} topPosition="19.5%" closeIconColor="charcoal70">
      {isPayAvailable && (
        <>
          <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={15} fontWeight="M" size="M" extraStyles={{ paddingRight: '15px' }}>
            {t('percapitaPayEarnedModal.Title')}
          </Title>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={16}>
            <Trans
              i18nKey="percapitaPayEarnedModal.EarnedTotal"
              ns="percapitaPay"
              values={{
                earnedThisCycle: paymentsInfo?.earnedThisCycle,
              }}
              components={{ strong: <strong style={{ color: '#353131' }} /> }}
            />

            {hasAvailableTransfers && (
              <Trans
                i18nKey="percapitaPayEarnedModal.Available"
                ns="percapitaPay"
                values={{
                  availableNow: paymentsInfo?.availableNow,
                }}
                components={{ strong: <strong style={{ color: '#353131' }} /> }}
              />
            )}
            {!hasAvailableTransfers && isPeriodClosed && t('percapitaPayHome.Of that, a total of $0... because pay period is already closed.')}
            {!hasAvailableTransfers && !isPeriodClosed && t('percapitaPayHome.Of that, a total of $0... because you have already maxed out...')}
          </BodyText>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={16}>
            <Trans
              i18nKey="percapitaPayEarnedModal.YouAreLimited"
              ns="percapitaPay"
              values={{
                limit: DAILY_PAY_LIMIT,
              }}
              components={{ strong: <strong style={{ color: '#353131' }} /> }}
            />
          </BodyText>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70">
            {t('percapitaPayEarnedModal.IfYouDontMakeRequest')}
          </BodyText>
        </>
      )}

      {percapitaPayModalType === 'earned' && Number(paymentsInfo?.availableNow) > DAILY_PAY_LIMIT && Number(paymentsInfo?.transfersAvailable) > 0 && (
        <>
          <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={15} fontWeight="M" size="M" extraStyles={{ paddingRight: '15px' }}>
            {t('percapitaPayHome.Amount Available')}
          </Title>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={16}>
            {/* prettier-ignore */}
            <Trans
              i18nKey="percapitaPayHome.You have earned..."
              ns='percapitaPay'
              values={{
                earnedThisCycle: paymentsInfo?.earnedThisCycle,
                availableNow: paymentsInfo?.availableNow,
              }}
            >
              {/* eslint-disable-next-line */}
              You have earned <SAmountText>${paymentsInfo?.earnedThisCycle}</SAmountText> so far this pay period.
            </Trans>
            {/* prettier-ignore */}
            <Trans
                i18nKey="percapitaPayHome.Of that, a total of is available..."
                ns='percapitaPay'
                values={{
                  availableNow: paymentsInfo?.availableNow,
                }}
            >
              {/* eslint-disable-next-line */}
              Of that, a total of <SAmountText>{paymentsInfo?.availableNow}</SAmountText> is available to request now through Percapita Pay.
            </Trans>
          </BodyText>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={16}>
            {/* prettier-ignore */}
            <Trans i18nKey="percapitaPayHome.Individual Percapita Pay requests are capped. You can..." ns='percapitaPay'>
              {/* eslint-disable-next-line */}
              Individual Percapita Pay requests are capped at <SAmountText>{DAILY_PAY_LIMIT}</SAmountText>. You can request up to <SAmountText>{paymentsInfo?.availableMax}</SAmountText> right now. The remaining <SAmountText>{paymentsInfo?.remainingAmount}</SAmountText> will be available for future requests during this pay period.
            </Trans>
          </BodyText>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70">
            {t('percapitaPayHome.You can make up to two transfers...')}
          </BodyText>
        </>
      )}

      {percapitaPayModalType === 'payPeriod' && (
        <>
          <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={15} fontWeight="M" size="M">
            {t('percapitaPayHome.So far this pay period')}
          </Title>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={16}>
            <Trans
              i18nKey="percapitaPayHome.You can make up to ..."
              ns="percapitaPay"
              values={{
                maxPayPeriodTransactionsCount: paymentsInfo?.maxPayPeriodTransactionsCount,
                payPeriod,
                transfersAvailable: paymentsInfo?.transfersAvailable,
              }}
            >
              You can make up to <SAmountText>{paymentsInfo?.maxPayPeriodTransactionsCount}</SAmountText> requests per pay period. You have made
              <SAmountText>{payPeriod}</SAmountText> requests so far this period, so you have
              <SAmountText>{paymentsInfo?.transfersAvailable}</SAmountText> remaining.
            </Trans>
          </BodyText>

          <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70">
            {/* prettier-ignore */}
            <Trans
              i18nKey="percapitaPayHome.You have already requested..."
              ns='percapitaPay'
              values={{
                transferredAmount: paymentsInfo?.transferredAmount,
              }}
            >
              You have already requested and received <SAmountText>{paymentsInfo?.transferredAmount}</SAmountText> this pay period. This amount has already been taken out of the amount available to you
            </Trans>
          </BodyText>
        </>
      )}

      {percapitaPayModalType === 'amountRequested' && (
        <>
          <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={4} fontWeight="M" size="M" extraStyles={{ paddingRight: '15px' }}>
            {t('percapitaPayHome.How does requested amount work?')}
          </Title>

          <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70">
            {t('percapitaPayHome.This is the amount you requested...')}
          </BodyText>
        </>
      )}

      {percapitaPayModalType === 'CardVsAccount' && (
        <>
          <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={15} fontWeight="M" size="M" extraStyles={{ paddingRight: '15px' }}>
            {t('percapitaPayHome.CardVsAccount')}
          </Title>

          <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal70" lineHeight={1.5} marginBottom={22}>
            <Trans i18nKey="percapitaPayHome.If you enter your debit card credentials ..." ns="percapitaPay">
              {/* prettier-ignore */}
              If you enter your <SAmountText>debit card</SAmountText> credentials, your money will be pushed to your card and the money will be <SAmountText>available in minutes</SAmountText>.
            </Trans>
          </BodyText>

          <BodyText textType="bodyText" size="M" fontWeight="R" color="charcoal70" lineHeight={1.5}>
            {/* prettier-ignore */}
            <Trans i18nKey="percapitaPayHome.If you choose to use your bank account ..." ns='percapitaPay'>
              If you choose to use your <SAmountText>bank account</SAmountText> by inputting your bank&apos;s routing number and account number, your money will be sent to your account via ACH, which
              may <SAmountText>take 2-3 days</SAmountText>.
            </Trans>
          </BodyText>
        </>
      )}
    </CustomModal>
  );
};
