import { Icon } from 'components/general/Icon/Icon';
import { Button } from 'components/theme/Button/Button';
import styled from 'styled-components';

export const SIconClose = styled(Icon)`
  position: absolute;
  top: 14px;
  right: 14px;
  pointer-events: none;
`;

export const SCustomButton = styled(Button)`
  height: 50px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
`;
