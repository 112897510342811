import styled from 'styled-components';
import { Switch } from 'antd';
import { getColor } from 'utils/helpers/styleHelpers';

export const SContent = styled.div`
  display: flex;
  flex-direction: column;

  .btn-container {
    display: flex;
    justify-content: center;
    padding: 14px 0;
    margin: 12px 0 32px;
  }
`;

export const SAccountControls = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid ${getColor('charcoal5')};
  gap: 24px;
  margin-bottom: 16px;

  & .edit-control {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .edit-control-left,
      & .edit-text-button {
        width: 50%;
      }
    }

    &-left {
      display: flex;
      flex-direction: column;
    }
  }

  & .edit-text-button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-right: 29px;

    & .custom-text-inner {
      font-size: 15px;
      font-weight: 700;
    }

    & .icon {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0;
    }
  }
`;

export const SSwitch = styled(Switch)`
  background: ${getColor('charcoal70')};
  min-width: 48px;
  height: 24px;

  &.ant-switch-checked {
    background: ${getColor('blue')};
  }

  .ant-switch-handle {
    width: 20px;
    height: 20px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 22px);
  }
`;
