import styled, { css } from 'styled-components';
import { ifProp, getColor, mediaFrom } from 'utils/helpers/styleHelpers';

interface ISFields {
  borderRadius?: string;
  isWrapped?: boolean;
  showCaret?: boolean;
}

const CodeInputStatusStyle = css`
  .success {
    border: 2px solid ${getColor('green')} !important;
    &:hover,
    &:focus {
      border: 2px solid ${getColor('green')} !important;
    }
  }

  .error {
    border: 2px solid ${getColor('red')} !important;
    &:hover,
    &:focus {
      border: 2px solid ${getColor('red')} !important;
    }
  }

  .focused {
    border: 2px solid ${getColor('blue')} !important;
    &:hover,
    &:focus {
      border: 2px solid ${getColor('blue')} !important;
    }
  }
`;

const CodeInputStyle = css`
  display: flex;
  touch-action: pan-y;

  ${mediaFrom(
    'desktop',
    css`
      min-width: 45px;
    `
  )}

  input {
    text-align: center;
    font-weight: 700;
    touch-action: pan-y;
  }

  .custom-text {
    min-width: auto;
    &-inner {
      font-family: DM Sans, sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #353131;
      text-align: left;
      display: block;
      line-height: 1.2;
      cursor: default;
      font-style: normal;
    }
  }
`;

export const SFields = styled.div<ISFields>`
  ${CodeInputStyle};

  align-items: center;
  justify-content: center;
  gap: 2%;

  ${ifProp(
    'isWrapped',
    // tax id cell
    css`
      ${CodeInputStatusStyle};
      gap: 1% !important;

      .base-input-container {
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;
        border: none !important;
        background-color: transparent;

        & input {
          caret-color: ${ifProp('showCaret', 'auto', 'transparent')};
          font-size: 14px;

          ${mediaFrom(
            'desktop',
            css`
              :focus {
                // border-bottom: 2px solid ${getColor('charcoal40')};
                // margin: 0 8px;
              }
            `
          )}
        }
      }

      ${mediaFrom(
        'tablet',
        css`
          .custom-text {
            margin: 0 12px;
          }
        `
      )};
    `,
    // phone number cell
    css`
      .base-input-container {
        width: 56px;
        padding: 4px;

        & input {
          font-size: 16px;
        }
      }
    `
  )};
`;
