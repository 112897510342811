import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';

export const SCustomRow = styled(CustomRow)`
  background: ${getColor('creamSS1')} !important;
  padding: 24px;
`;

export const SBodyText = styled(BodyText)`
  width: 50%;
`;
