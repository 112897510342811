import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { images } from 'assets';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';

interface IInviteFinishedModal {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
  onSendAnother: () => void;
  isClosable?: boolean;
}

export const ReferSuccessModal: React.FC<IInviteFinishedModal> = ({ open, onClose, onSendAnother, onDone, isClosable = true }) => {
  const { t } = useTranslation('refer');

  return (
    <CustomModal open={open} onCancel={onClose} modalName="referSuccessModal" topPosition="0" closable={isClosable} padding="24px">
      <div className="center-image">
        <img src={images.success} alt="inviteFinished" width="120px" />
      </div>
      <div>
        <Title font="Poppins" size="M" fontWeight="M" color="charcoal" marginTop={32} marginBottom={16}>
          {t("We've sent your referral code")}
        </Title>

        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
          {t(
            'Your friend or family member will receive a notification from Percapita that says "Your Name" would like to help you get $25!, with a code they need to enter when they open their account.!'
          )}
        </BodyText>

        <BodyText textType="bodyText" size="N" fontWeight="B" marginTop={32}>
          {t('Would you like to refer someone else?')}
        </BodyText>
      </div>
      <Button marginTop={16} size="large" preset="blue-filled" onClick={onSendAnother}>
        {t(`Send Another Invite`)}
      </Button>
      <Button marginTop={16} size="large" preset="blue-outlined" onClick={onDone}>
        {t(`I'm Done for Now`)}
      </Button>
    </CustomModal>
  );
};
