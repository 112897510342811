import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const STextArea = styled.textarea`
  position: relative;
  width: 100%;
  border-radius: 16px;
  padding: 16px 20px;
  font-size: 16px;
  border: 2px solid ${getColor('charcoal10')};

  &::placeholder {
    color: ${getColor('charcoal40')};
  }

  &:focus {
    border-color: ${getColor('blue')};
  }

  &:after {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: 12px;
    line-height: 1;
  }
`;
