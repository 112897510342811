import React, { useEffect, useRef } from 'react';
import { Chip } from 'components/general/Chip/Chip';
import { useTranslation } from 'react-i18next';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { TIconName } from 'components/general/Icon/Icon.types';
import { IAccountGroup } from 'utils/helpers/accounts/accountsHelper';
import { SAccountSelectorLayout } from './AccountSelector.styles';

interface IAccountSelectorProps {
  group?: IAccountGroup;
  onSelectedAccount: (type: string) => void;
  selectedAccountType: string;
}

export const AccountSelector = ({ group, onSelectedAccount, selectedAccountType }: IAccountSelectorProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleAccountClick = (type: string, currentTarget: HTMLElement) => {
    onSelectedAccount(type);

    if (containerRef.current) {
      const parentElement = containerRef.current;
      const parentElementWidth = parentElement?.clientWidth;
      const accountItemWidth = currentTarget?.clientWidth;

      const elementLeft = currentTarget?.offsetLeft;
      const scrollLeft = Math.max(0, elementLeft - (Number(parentElementWidth) - accountItemWidth) / 2);

      parentElement?.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    // scroll to selected account
    const activeEl = containerRef.current?.getElementsByClassName('active')[0] as HTMLElement;
    if (activeEl) handleAccountClick(selectedAccountType, activeEl);
  }, [selectedAccountType]);

  return (
    <SAccountSelectorLayout ref={containerRef}>
      {group &&
        Object.values(group).map(({ type, displayType, icon }: IAccountItem) => (
          <Chip
            type="light"
            iconName={icon as TIconName}
            isActive={selectedAccountType === type}
            onClick={(value: string, id: string, event: React.MouseEvent) => handleAccountClick(type, event?.currentTarget as HTMLElement)}
          >
            <span className="body-text">{t(`account.${displayType} Account`)}</span>
          </Chip>
        ))}
    </SAccountSelectorLayout>
  );
};
