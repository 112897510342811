import styled from 'styled-components';
import { getColor, getProp } from 'utils/helpers/styleHelpers';
import { Icon } from 'components/general/Icon/Icon';

export const SMoveMoneyPage = styled.div`
  padding: 5px 0 40px;
  max-width: 476px;
  margin: 0 auto;

  .move-money-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const SCircle = styled.div`
  width: 40px;
  height: 40px;
  background: ${getColor('blue5')};
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SIcon = styled(Icon)`
  background: ${getColor('blue5')};
  width: 22px;
  position: relative;
  flex: 0 0 auto;
  top: 7px;
  margin: auto;
`;

export const SComingSoonModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`;

export const SModalTextBlock = styled.div`
  align-self: flex-start;
`;

export const SIFrame = styled.iframe`
  min-height: 600px;
  width: 100%;
  margin-top: 40px;
  height: ${getProp('height', 'auto')};
  border: 0;
`;
