import styled, { css } from 'styled-components';
import { SCREEN_SIZE, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    margin-bottom: 122px;
  }

  ${mediaFrom(
    SCREEN_SIZE.tablet,
    css`
      .container {
        max-width: 580px;
      }
    `
  )}
`;
