import styled from 'styled-components';

export const SDataItem = styled.div`
  display: flex;
  overflow-x: unset;
  margin-bottom: 16px;
  justify-content: space-between;

  .info {
    height: 100%;
    align-items: flex-start;
  }

  .icon {
    flex: 0 0 auto;
  }
`;
