import styled, { css } from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

interface ILayoutProps {
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
}

export const SLayout = styled.div<ILayoutProps>`
  display: flex;

  &.type-circle {
    position: relative;
    align-items: center;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    background: #aeb8c2;
    border: solid 1px ${getColor('charcoal30')};
    overflow: hidden;

    svg {
      position: absolute;
      top: 0;
      left: -28%;
      right: 0;
      bottom: 0;
    }
  }

  ${({ marginTop, marginLeft, marginRight, marginBottom }) => css`
    margin-top: ${marginTop}px;
    margin-left: ${marginLeft}px;
    margin-right: ${marginRight}px;
    margin-bottom: ${marginBottom}px;
  `};
`;
