import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { useLazyGetPercapitaPayHistoryQuery, useLazyGetTimeEntriesQuery } from 'store/user/payments/payments.api';
import { TThemeColor } from 'styles/theme';
import { Loader } from 'components/general/Loader/Loader';
import { useAppDispatch } from 'utils/hooks/store';
import { setShowHistoryFilterDrawer } from 'store/ui.slice';
import { activateFilter, onResetFilter, selectHistoryFilterData, setPrevFilter } from 'store/historyFilter/historyFilter.slice';
import { IPercapitaPayHistoryItem } from 'vars/types/userInfo.types';
import { TBreadcrumbsPath } from 'vars/types/menu.types';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { ROUTES } from 'vars/const/ROUTES';
import { Chip } from 'components/general/Chip/Chip';
import { TimeCard } from './TimeCard/TimeCard';
import { TransferHistory } from './TransferHistory/TransferHistory';
import { SActionBar, SContainer, SPage, SWebNavigation } from './HistoryPage.styles';
import { SideFilter } from './SideFilter/SideFilter';
import { ChipSelector } from './ChipSelector/ChipSelector';

const INACTIVE_TEXT_COLOR: TThemeColor = 'blue';
const ACTIVE_TEXT_COLOR: TThemeColor = 'white';

export const HistoryPage: React.FC = () => {
  const { t } = useTranslation('percapitaPay');
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [getHistoryAPI, getHistoryAPIResult] = useLazyGetPercapitaPayHistoryQuery();
  const [getTimeEntriesAPI, getTimeEntriesAPIResult] = useLazyGetTimeEntriesQuery();
  const { activatedFilter } = useSelector(selectHistoryFilterData);
  const {
    sortBy,
    filterBy: { minAmount, maxAmount, dateFrom, dateTo, destinationAccount },
  } = activatedFilter;

  const [selectedButton, setSelectedButton] = useState<number>(state || 1);
  const [historyData, setHistoryData] = useState<IPercapitaPayHistoryItem[]>([]);
  const [unfilteredHistoryData, setUnfilteredHistoryData] = useState<IPercapitaPayHistoryItem[]>([]);
  const [sortedByAmount, setSortedByAmount] = useState(false);

  const pathList: TBreadcrumbsPath[] = [
    { id: 0, name: t('percapitaPayHome.Percapita Pay'), path: ROUTES.percapitaPayHome.path },
    { id: 1, name: t('percapitaPayHome.Time Card') },
  ];

  // sorting & filtering
  const handleSortBy = (data: IPercapitaPayHistoryItem[]) => {
    if (sortBy.id !== '0' && sortBy.id !== '1' && sortBy.id !== '2' && sortBy.id !== '3') {
      return data;
    }

    const sortByField = sortBy.id === '0' || sortBy.id === '1' ? 'dateTime' : 'amount';
    const getIteratee = (item: IPercapitaPayHistoryItem) => (sortByField === 'dateTime' ? parseISO(item.dateTime).getTime() : item.amount);
    const sortDirection = sortBy.id === '0' || sortBy.id === '2' ? -1 : 1;
    setSortedByAmount(sortByField === 'amount');
    return [...data].sort((a, b) => sortDirection * (getIteratee(a) - getIteratee(b)));
  };

  const handleSliderFilter = () => unfilteredHistoryData.filter((history) => history.amount >= minAmount && history.amount <= maxAmount);

  const handleDestinationAccountFilter = (data: IPercapitaPayHistoryItem[]) => (destinationAccount?.alias ? data.filter((acc) => destinationAccount.alias === acc.alias) : data);

  const handleDateFilter = (data: IPercapitaPayHistoryItem[]) => {
    if (!dateFrom || !dateTo) return data;

    const toNextDay = new Date(dateTo);
    toNextDay.setDate(toNextDay.getDate() + 1);

    const fromTs = new Date(dateFrom).getTime();
    const toTs = toNextDay.getTime();

    return data.filter((history) => {
      const dateTs = parseISO(history.dateTime).getTime();
      return dateTs >= fromTs && dateTs <= toTs;
    });
  };

  const handleFilterChange = () => {
    const filteredByAmount = handleSliderFilter();
    const filterByDestinationAccount = handleDestinationAccountFilter(filteredByAmount);
    const filteredByDate = handleDateFilter(filterByDestinationAccount);
    const result = handleSortBy(filteredByDate);

    setHistoryData(result);
  };

  const handleFilterClick = () => {
    dispatch(setPrevFilter());
    dispatch(setShowHistoryFilterDrawer(true));
  };

  const handleSelectedCard = (selectedId: number) => {
    setSelectedButton(selectedId);
  };

  const handleOnTimeRefresh = () => {
    getTimeEntriesAPI({});
    dispatch(onResetFilter());
  };

  const handleOnHistoryRefresh = () => {
    getHistoryAPI('All');
    dispatch(onResetFilter());
  };

  useEffect(() => {
    getHistoryAPI('All');
    getTimeEntriesAPI({});
    dispatch(onResetFilter());
    dispatch(activateFilter());
  }, []);

  useEffect(() => {
    if (getHistoryAPIResult?.isSuccess) {
      setHistoryData(getHistoryAPIResult.data);
      setUnfilteredHistoryData(getHistoryAPIResult.data);
    }
  }, [getHistoryAPIResult?.isSuccess]);

  // start filters
  useEffect(() => {
    handleFilterChange();
  }, [activatedFilter]);

  return (
    <SPage>
      <SideFilter />

      <SContainer>
        <SWebNavigation>
          <Breadcrumbs paths={pathList} title={t('percapitaPayHome.Percapita Pay')} hasBackNav fullWidth />
        </SWebNavigation>

        <div className="content-container">
          <div className="chip-container">
            <ChipSelector onSelectedCard={handleSelectedCard} selectedCardId={selectedButton} />
            <Icon className="icon filter" name="filter" color="charcoal70" size="normal" onClick={handleFilterClick} cursorPointer />
          </div>

          <div className="mobile-filter-container">
            <Icon className="icon" name="filter" color="charcoal70" size="normal" onClick={handleFilterClick} cursorPointer />
          </div>

          <SActionBar justifyContent="flex-start" gap={20} marginTop={42} overflowY="auto">
            <Chip onClick={() => setSelectedButton(1)} isActive={selectedButton === 1}>
              <BodyText textType="bodyText" color={selectedButton === 1 ? ACTIVE_TEXT_COLOR : INACTIVE_TEXT_COLOR} size="N" fontWeight="M" font="Poppins" marginRight={14} cursorPointer>
                {t('percapitaPayHome.Time Card')}
              </BodyText>
              <Icon name="circleTime" color={selectedButton === 1 ? ACTIVE_TEXT_COLOR : INACTIVE_TEXT_COLOR} size="small" cursorPointer />
            </Chip>

            <Chip onClick={() => setSelectedButton(2)} isActive={selectedButton === 2}>
              <BodyText textType="bodyText" color={selectedButton === 2 ? ACTIVE_TEXT_COLOR : INACTIVE_TEXT_COLOR} size="N" fontWeight="M" font="Poppins" marginRight={14} cursorPointer>
                {t('percapitaPayHome.Request History')}
              </BodyText>
              <Icon name="recentPayment" color={selectedButton === 2 ? ACTIVE_TEXT_COLOR : INACTIVE_TEXT_COLOR} size="normal" cursorPointer />
            </Chip>
          </SActionBar>

          {getHistoryAPIResult.isFetching || getTimeEntriesAPIResult.isFetching ? (
            <Loader />
          ) : (
            <>
              {selectedButton === 1 && getTimeEntriesAPIResult?.currentData && (
                <TimeCard onRefresh={handleOnTimeRefresh} timeEntries={getTimeEntriesAPIResult?.currentData} isError={getTimeEntriesAPIResult?.isError} />
              )}
              {selectedButton === 2 && getHistoryAPIResult?.currentData && (
                <TransferHistory transferHistory={historyData} onRefresh={handleOnHistoryRefresh} sortedByAmount={sortedByAmount} isError={getHistoryAPIResult.isError} />
              )}
            </>
          )}
        </div>
      </SContainer>
    </SPage>
  );
};
