import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { ConsentId, ConsentType } from 'components/general/Consent/Consents.types';
import { useConsents } from 'utils/hooks/useConsents';
import { useToggle } from 'utils/hooks/useToggle';
import { Icon } from 'components/general/Icon/Icon';
import { Loader } from 'components/general/Loader/Loader';
import { Switcher } from 'components/general/Switcher/Switcher';
import { BodyText } from 'components/general/Typography';
import { useChangeAccountFeatureMutation, useLazyGetAccountFeaturesQuery } from 'store/user/accounts/accounts.api';
import { IFeatureItem } from 'store/user/accounts/accounts.types';
import { IConsentData } from 'store/user/consents/consents.types';
import { SPennyJarActivatePage, SLink } from 'views/Account/PennyJar/PennyJarSettings.styles';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';

export const CashCushionActivate = () => {
  const { t } = useTranslation('cashCushion');
  const navigate = useNavigate();
  const {
    state: { cashCushionAccountId },
  } = useLocation();
  const depAccAgrConsentSheet = useToggle();
  const otherConsents = useConsents(ConsentType.OTHERS);
  const consentData = otherConsents?.consentsData?.find((item) => item?.id === ConsentId.ESIGN_CONSENT) ?? ({} as IConsentData);
  const [isEnabled, setIsEnabled] = useState(false);

  const [getFeatures, getFeaturesResult] = useLazyGetAccountFeaturesQuery();
  const [changeAccountFeatures, changeAccountFeaturesResult] = useChangeAccountFeatureMutation();

  const handleSwitchChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    setIsEnabled(!target.checked);
    const data = [
      {
        type: 'Overdraft',
        isEnabled: !target.checked,
        overdraft: {
          isEligible: true,
          reason: '',
          amount: 0,
        },
      },
    ];
    await changeAccountFeatures({ data, cashAccountId: cashCushionAccountId });
  };

  const onDepositAccAgrClick = () => {
    if (consentData?.id) depAccAgrConsentSheet.show();
  };

  useEffect(() => {
    getFeatures(cashCushionAccountId);
  }, []);

  useEffect(() => {
    if (getFeaturesResult.isSuccess) {
      setIsEnabled(getFeaturesResult.data?.find((item: IFeatureItem) => item.type === 'Overdraft')?.isEnabled);
    }
  }, [getFeaturesResult.isSuccess]);

  return (
    <SPennyJarActivatePage>
      {getFeaturesResult.isFetching || (changeAccountFeaturesResult.isLoading && <Loader />)}
      <div>
        <div className="header">
          <Icon name="arrowLeft" cursorPointer color="blue" onClick={() => navigate(-1)} className="back" />
          <span className="title">{t('CashCushion')}</span>
        </div>

        <div className="title-container">
          <span className="subtitle">{t('CashCushion')}</span>
          <span className="description">
            <Trans i18nKey={t('CashCushionWillAllow')} ns="cashCushion" />
          </span>
        </div>
        <div className="switch-activate-card">
          <CustomCard className="switch-card">
            <div className="activate-card">
              <span>{t('CashCushion')}</span>
              <Switcher onClick={handleSwitchChange} checked={isEnabled} />
            </div>
          </CustomCard>
        </div>
      </div>

      <BodyText color="charcoal70" size="T" marginBottom={48}>
        <Trans i18nKey={t('YouCanSeeMore')} ns="cashCushion" components={{ span: <SLink onClick={onDepositAccAgrClick} /> }} />
      </BodyText>
      <ConsentSheet consentData={consentData} isOpen={depAccAgrConsentSheet.isActive} onClose={depAccAgrConsentSheet.hide} isReadonly />
    </SPennyJarActivatePage>
  );
};
