import styled, { css } from 'styled-components';
import { getColor, getRgba, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { dottesInAngle } from 'assets/images';

export const SLayout = styled.div`
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    font-size: 28px;
  }

  ${mediaUpTo(
    'mobile',
    css`
      margin: 0 8px;
      .header .title .custom-title-text {
        font-size: 20px;
      }
    `
  )};

  ${mediaUpTo(
    'desktop',
    css`
      padding: 0 5px 50px;
      margin: 0 18px;

      .header {
        margin-bottom: 30px;

        .custom-title-text {
          font-size: 28px;
        }
      }

      .content-wrapper {
        .center-image {
          margin-bottom: 32px;

          img {
            height: 126px;
            width: auto;
          }
        }

        .subtitle {
          margin-bottom: 24px;

          .custom-text-inner {
            color: ${getColor('charcoal90')};
          }
        }

        .documents-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 48px;

          .document {
            .document-header {
              justify-content: flex-start;
              margin-bottom: 8px;
            }

            .list-title {
              padding-left: 56px;
              margin-bottom: 2px;
            }
          }

          .document:first-child {
            margin-bottom: 32px;
          }
        }

        .button-upload {
          margin-bottom: 18px;
        }
      }
    `
  )};

  ${mediaFrom(
    'tablet',
    css`
      .header {
        margin: 0px -22px 41px -22px;
        padding: 14px 9% 48px;
        border-bottom: ${getColor('creamS5')} 1px solid;

        .custom-title-text {
          font-size: 24px;
        }

        button.size-middle {
          font-weight: 600;
          padding: 12px 23px;
        }
      }
    `
  )};

  ${mediaFrom(
    'tablet',
    css`
      .button-panel {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        padding: 24px 32px;
        border-radius: 20px;
        background: ${getColor('white')};
        box-shadow: 0 16px 24px 0 ${getRgba('black', 0.04)};

        .button-upload {
          height: 52px;
          width: auto;
          padding: 8px 31px;
          font-weight: 600;
          order: 1;
        }
      }
    `
  )};

  ${mediaFrom(
    'desktop',
    css`
      background-image: url(${dottesInAngle});
      background-repeat: no-repeat;
      background-position: bottom right;

      .content-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 16%;

        .center-image {
          height: 210px;
          margin-bottom: 29px;
        }

        .subtitle {
          margin-bottom: 40px;

          .custom-text-inner {
            font-size: 24px;
            text-align: center;
            padding: 0 14%;
            color: ${getColor('charcoal')};
          }
        }

        .documents-container {
          display: flex;
          justify-content: space-between;

          .document {
            flex: 1;
            display: flex;
            flex-direction: column;

            .document-header {
              display: flex;
              align-items: flex-start;

              .document-title {
                margin-bottom: 16px;
                padding-right: 20px;

                .custom-text-inner {
                  font-size: 20px;
                  font-weight: 600;
                }
              }
            }

            .list-title {
              padding-left: 64px;
              margin-bottom: 8px;

              .custom-text-inner {
                font-size: 14px;
              }
            }
          }

          .document:first-child {
            margin-right: 48px;
          }
        }
      }
    `
  )};
`;

export const SIconWrapper = styled.div`
  background-color: ${getColor('bluePurple')};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  ${mediaFrom(
    'desktop',
    css`
      margin-right: 24px;
    `
  )};
`;

export const SList = styled.ul`
  li {
    font-weight: 400;
    line-height: 16px;
    color: ${getColor('charcoal70')};
  }

  ${mediaUpTo(
    'desktop',
    css`
      margin-bottom: 8px;
      margin-left: 63px;
      padding-left: 16px;

      li {
        font-size: 12px;
      }
    `
  )};

  ${mediaFrom(
    'desktop',
    css`
      margin-bottom: 18px;
      margin-left: 0;
      padding-left: 86px;

      li {
        font-size: 14px;
        font-family: DM Sans, sans-serif;
        padding: 4px 0 8px;

        &::marker {
          color: ${getColor('blue')};
        }
      }
    `
  )};
`;
