import styled from 'styled-components';

export const SMediaLayout = styled.div`
  button {
    align-self: center;
  }
`;

export const SListItem = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin-left: 0;
`;
