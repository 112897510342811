import React from 'react';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { Loader } from 'components/general/Loader/Loader';
import { toUpperCaseWithUnderline } from 'utils/helpers/string';
import { SCategories } from './CategorySelectSheet.styles';

interface ICategory {
  label: string;
  value: string;
  icon: string;
}

interface ICategorySelectSheetProps {
  open: boolean;
  categories: ICategory[];
  onSelect: (category: string) => void;
  onClose: () => void;
  currentCategory: string;
  loading: boolean;
}

export const CategorySelectSheet: React.FC<ICategorySelectSheetProps> = ({ open, loading, categories, currentCategory, onSelect, onClose }) => {
  const { t } = useTranslation('accountInformation');

  const handleSelect = (category: string) => {
    onSelect(category);
  };

  return (
    <CustomSheet isOpen={open} height="100%" paddingTop="35px" header={false} wrapperPadding={false} onClose={onClose} modalName="transactionDetails">
      {loading && <Loader />}
      <CustomRow marginBottom={32} alignItems="center" justifyContent="flex-start">
        <Title font="Poppins" color="charcoal" fontWeight="SB" size="S">
          {t('Change Category')}
        </Title>
      </CustomRow>

      <SCategories>
        {categories.map((category) => {
          const isActive = currentCategory === category.value;
          return (
            <CustomRow justifyContent="space-between" onClick={() => handleSelect(category.value)}>
              <CustomRow>
                <Icon name={category.icon} color={isActive ? 'blue' : 'charcoal70'} fallbackName="other" />
                <BodyText marginLeft={16} color={isActive ? 'blue' : 'charcoal'} size="M">
                  <Trans i18nKey={`balancesTransactions.categories.${toUpperCaseWithUnderline(category.label)}`} ns="balancesTransactions" />
                </BodyText>
              </CustomRow>
            </CustomRow>
          );
        })}
      </SCategories>
    </CustomSheet>
  );
};
