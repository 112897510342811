import { BaseSelect } from 'components/general/BaseSelect/BaseSelect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { US_STATES } from 'vars/const/USE_STATEX';

interface IWebStateSelector {
  value?: string;
  onChange?: (val: string) => void;
}

export const WebStateSelector = ({ value, onChange }: IWebStateSelector) => {
  const { t } = useTranslation();
  const [selectedState, setSelectedState] = useState(value);

  useEffect(() => {
    setSelectedState(value);
  }, [value]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption) {
      onChange?.(selectedOption.value);
    }
  };
  const formatOptionLabel = (option: any, { context }: any) => (context === 'menu' ? option.label : option.value);

  return (
    <BaseSelect
      options={US_STATES.map((state) => ({
        value: state.value,
        label: state.label,
      }))}
      value={US_STATES.find((item) => item.value === selectedState)}
      placeholder={t('myInfo.State')}
      onChange={handleChange}
      menuPlacement="auto"
      formatOptionLabel={formatOptionLabel}
      isWebStyle
    />
  );
};
