import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { images } from 'assets';
import { ConsentSheet } from 'components/general/Consent/ConsentSheet';
import { useConsents } from 'utils/hooks/useConsents';
import { ConsentType } from 'components/general/Consent/Consents.types';
import { useToggle } from 'utils/hooks/useToggle';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { SReferPageWrapper, SLink } from './ReferPage.styles';
import { ReferForm } from './ReferForm/ReferForm';

export const ReferPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('refer');
  const { consentsData } = useConsents(ConsentType.REFERRAL);
  const { isProspect } = useUserStatus();
  const disclosureSheet = useToggle();
  const { isDesktopSize } = useDeviceDimension();

  useEffect(() => {
    if (isProspect) navigate(ROUTES.invite.path, { replace: true });
  }, []);

  return (
    <SReferPageWrapper>
      <div className="image-container">
        <div className="title-container">
          <div className="text-container">
            <Title font="Poppins" size="M" fontWeight="SB" marginBottom={isDesktopSize ? 16 : 0} marginTop={isDesktopSize ? 0 : 16} textAlign="start">
              {t('Get up to $500 when you refer friends and family!')}
            </Title>
            <BodyText textType="bodyText" size="N" fontWeight="R" color="charcoal70" marginBottom={48} lineHeight={1.6}>
              <Trans i18nKey="ReferralDisclosureNote" ns="refer" components={{ Link: <SLink onClick={disclosureSheet.show} /> }} />
            </BodyText>
          </div>
          <img src={images.referHero} alt="refer" height="180px" />
        </div>
      </div>

      <div className="content-container">
        <Title
          font={isDesktopSize ? 'Poppins' : 'DM Sans'}
          size={isDesktopSize ? 'T' : 'sS'}
          color="charcoal"
          fontWeight={isDesktopSize ? 'SB' : 'B'}
          textAlign="start"
          justifyContent="start"
          marginBottom={16}
          marginTop={isDesktopSize ? 45 : 0}
          className="title"
        >
          {t('How it works')}
        </Title>

        <div className="steps-card">
          <ul className="steps">
            <li className="steps-item">
              <div className="steps-item-circle">1</div>
              <div className="steps-item-text">{t('Provide your friend or family member’s information below.')}</div>
            </li>
            <li className="steps-item">
              <div className="steps-item-circle">2</div>
              <div className="steps-item-text">{t('Click “Send Invite” and the person you are referring will receive an invitation by text or email with a referral code.')}</div>
            </li>
            <li className="steps-item">
              <div className="steps-item-circle">3</div>
              <div className="steps-item-text">{t('When the person you referred meets the bonus criteria, you will both receive $25 into your Percapita Cash Account.')}</div>
            </li>
          </ul>
        </div>

        <Title
          font={isDesktopSize ? 'Poppins' : 'DM Sans'}
          size={isDesktopSize ? 'T' : 'sS'}
          color="charcoal"
          fontWeight={isDesktopSize ? 'SB' : 'B'}
          textAlign="start"
          justifyContent="start"
          className="title"
        >
          {t('Enter the details')}
        </Title>
        {consentsData?.map((disclosure) => (
          <ConsentSheet
            width="95%"
            key={disclosure.id}
            consentData={disclosure}
            isOpen={disclosureSheet.isActive}
            onClose={disclosureSheet.hide}
            isReadonly
            isSheet={!isDesktopSize}
            isSendEmail={false}
            height="90%"
          />
        ))}

        <ReferForm />

        <div className="disclosure-container">
          <SuttonDisclaimerNote />
        </div>
      </div>
    </SReferPageWrapper>
  );
};
