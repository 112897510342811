import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SWrapper = styled.div`
  .ant-tooltip-content {
    width: 368px;
  }
`;

export const SCard = styled.div`
  border-radius: 16px;
  border: 2px solid ${getColor('grey3')};
  padding: 20px 32px 12px 20px;
  background: ${getColor('white')};
  width: 368px;
  margin-top: 10px;

  .item-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;
