import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SAutocompleteInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & ul.autocomplete-input__suggestions {
    position: absolute;
    padding: 0;
    width: 100%;
    max-height: 245px;
    overflow: auto;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    top: 60px;
    z-index: 999 !important;
    background: ${getColor('white')};
    border: 2px solid ${getColor('charcoal10')};
    border-radius: 16px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  & li.autocomplete-input__suggestion {
    div {
      padding: 10px 20px;
      cursor: pointer;
    }

    div:hover {
      background: ${getColor('blue5')};
    }
  }
`;
