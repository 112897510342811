import styled, { css } from 'styled-components';
import { flex, getColor, mediaUpTo, mediaFrom, mediaBetween, SCREEN_SIZE, getRgba } from 'utils/helpers/styleHelpers';
import { Button } from 'components/theme/Button/Button';
import { dotPatternLeft, onboardingBgWorker } from 'assets/images';
import cashAndDebitImg from './images/cashAndDebitImg.png';
import percapitaPayImg from './images/percapitaPayImg.png';
import gamesAndMoreImg from './images/gamesAndMoreImg.png';

export const SOnboardingFirstStepsPage = styled.div`
  position: relative;
  margin: 0 auto;

  .onboarding-first-steps-page__steps {
    ${flex('column', 'space-between', 'flex-start')};
    gap: 2%;

    ${mediaFrom(
      'desktop',
      css`
        flex-direction: row;
        align-items: unset;
      `
    )};
  }

  .onboarding-first-steps-page__inner {
    width: 100%;
  }

  .onboarding-first-steps-page__content {
    position: relative;
    ${flex('column', 'space-between', 'stretch')}
    max-width: 543px;
    margin: 0 auto;
  }

  ${mediaUpTo(
    'mobile',
    css`
      padding-inline: 10px;
    `
  )}

  ${mediaUpTo(
    SCREEN_SIZE.tablet,
    css`
      padding-top: 35px;
    `
  )}

  ${mediaUpTo(
    SCREEN_SIZE.desktop - 1,
    css`
      .onboarding-first-steps-page__content {
        padding: 24px 0 0;
      }

      .custom-title-text {
        font-size: 28px;
      }

      button {
        margin-top: 0;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      position: relative;

      &:before,
      &:after {
        z-index: 5;
        left: -10%;
        bottom: 0;
        content: '';
        position: absolute;
        display: block;
        width: 120%;
        height: 50%;
        border-radius: 70% / 0 0 100px 100px;
        background: ${getRgba('blue', 0.1)};
        transform: rotate(4deg) translateY(4%);
      }

      &:after {
        transform: rotate(-4deg) translateY(4%);
      }

      .onboarding-first-steps-page__inner {
        position: relative;
        background: linear-gradient(to top, ${getRgba('black', 0.2)}, ${getRgba('black', 0.4)}) no-repeat, linear-gradient(to top, ${getRgba('blue', 0)} 0, ${getRgba('blue', 0.5)} 85%) no-repeat,
          url(${onboardingBgWorker}) no-repeat center;
        background-size: cover;
        justify-content: start;
        width: 100%;
        border-radius: 70% / 0 0 100px 100px;
        z-index: 10;
      }

      .onboarding-first-steps-page__content {
        max-width: 982px;
        padding-bottom: 50px;
      }

      .cash-account-first-steps-title {
        margin-bottom: 16px;
      }

      .step-card-button {
        padding: 10px 23px;
        font-size: 14px;
      }

      ${mediaFrom(
        SCREEN_SIZE.desktopLarge,
        css`
          &:before {
            transform: rotate(-3deg) translateY(-1%);
          }
          &:after {
            transform: rotate(3deg) translateY(-1%);
          }
        `
      )}
    `
  )}
`;

export const SStepItem = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 32px 24px;
  background: ${getColor('white')};

  & .img-container {
    ${flex('row', 'center')};
  }

  ${mediaBetween(
    'tablet',
    SCREEN_SIZE.desktop - 1,
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      align-items: end !important;
    `
  )}

  ${mediaUpTo(
    SCREEN_SIZE.desktop - 1,
    css`
      align-items: center;
      &:before {
        content: '';
        position: absolute;
        background-image: url(${dotPatternLeft});
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &.cash-account-first-steps:after {
        height: 130px;
        background-image: url(${cashAndDebitImg});
        ${mediaUpTo(
          'mobile',
          css`
            height: 100px;
          `
        )}
      }

      &.percapita-pay-first-steps:after {
        height: 106px;
        background-image: url(${percapitaPayImg});
        ${mediaUpTo(
          'mobile',
          css`
            height: 85px;
          `
        )}
      }

      &.play-games-first-steps:after {
        height: 117px;
        background-image: url(${gamesAndMoreImg});
        ${mediaUpTo(
          'mobile',
          css`
            height: 90px;
          `
        )}
      }
    `
  )}

  &.cash-account-first-steps .custom-title-text {
    padding-right: 20px;
  }

  &.cash-account-first-steps,
  &.percapita-pay-first-steps,
  &.play-games-first-steps {
    border-radius: 20px;
    position: relative;
    padding-left: 110px;

    ${mediaFrom(
      'desktop',
      css`
        padding-left: 24px;
      `
    )}

    & .btn {
      position: relative;
      z-index: 100;
    }
  }

  &.cash-account,
  &.percapita-pay,
  &.financial-wellness {
    background-image: url(${dotPatternLeft});
    padding: 32px 24px;

    .img-container {
      margin-bottom: 56px;

      img {
        width: 136px;
      }
    }
  }

  .percapita-pay-title {
    margin-bottom: 0;
  }

  .financial-wellness-title,
  .cash-account-title {
    margin-bottom: 42px;
  }

  .percapita-pay-subtitle {
    margin: 0 0 14px;
    & .middle-text,
    & .bold-text {
      color: ${getColor('charcoal')};
      font-size: 18px;
    }

    & .middle-text {
      font-family: DM Sans, sans-serif;
      font-weight: 500;
    }

    & .bold-text {
      font-weight: 700;
    }
  }

  .onboarding-description {
    font-family: DM Sans, sans-serif;
  }

  .cash-account-description,
  .financial-wellness-description {
    margin-top: 10px;
  }

  .cash-account-description,
  .percapita-pay-description,
  .financial-wellness-description {
    .custom-text-inner {
      color: ${getColor('charcoal70')};
    }
  }

  .bold-text {
    display: inline;
    font-weight: 700;
    color: ${getColor('charcoal70')};
  }

  .middle-text {
    display: inline;
    font-weight: 600;
    color: ${getColor('charcoal70')};
  }

  .regular-text {
    display: inline;
    font-weight: 400;
    color: ${getColor('charcoal70')};
  }

  ${mediaUpTo(
    SCREEN_SIZE.desktop - 1,
    css`
      &.cash-account-first-steps,
      &.percapita-pay-first-steps,
      &.play-games-first-steps {
        padding: 24px 20px 24px 112px;
        background-size: 90px;

        .custom-title-text {
          font-size: 16px;
        }

        & button {
          font-size: 12px;
          padding: 7px 15px;
          min-width: auto;
        }
      }

      .cash-account-first-steps-description,
      .percapita-pay-first-steps-description,
      .play-games-first-steps-description {
        font-size: 12px;
        font-family: DM Sans, sans-serif;
        margin-bottom: 16px;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      background-image: unset;
      ${flex('column', 'space-between')}

      &.cash-account-first-steps,
      &.percapita-pay-first-steps,
      &.play-games-first-steps {
        padding: 20px 24px 32px;

        .img-container {
          margin-bottom: 16px;
          margin-top: 10px;
        }

        .cash-account-first-steps-title,
        .percapita-pay-first-steps-title,
        .play-games-first-steps-title {
          align-items: flex-start;
          min-height: 54px;
        }

        .cash-account-first-steps-description,
        .percapita-pay-first-steps-description,
        .play-games-first-steps-description {
          font: 400 14px DM Sans, sans-serif;
          color: ${getColor('charcoal70')};
          margin-bottom: 24px;
        }

        .btn {
          min-width: auto;
          padding: 13px 23px;
          font-size: 14px;
        }
      }
    `
  )};

  ${mediaBetween(
    'mobile',
    400,
    css`
      &.cash-account-first-steps,
      &.percapita-pay-first-steps,
      &.play-games-first-steps {
        & .title {
          padding: 0;

          & .custom-title-text {
            padding: 0;
          }
        }
      }
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      &.cash-account-first-steps,
      &.percapita-pay-first-steps,
      &.play-games-first-steps {
        padding-left: 85px;
        border-radius: 20px;
        & .title {
          padding: 0;

          & .custom-title-text {
            padding: 0;
            font-size: 14px;
          }
        }
      }
    `
  )}

  .justify {
    &-center {
      ${flex('row', 'center')};
    }

    &-end {
      ${flex('row', 'flex-end')};
    }
  }
`;

export const SCustomButton = styled(Button)`
  ${mediaFrom(
    'desktop',
    css`
      justify-self: center;
      align-self: center;
      width: auto !important;
      padding: 10px 31px !important;
      background: transparent !important;
      border-color: ${getColor('white')}!important;
      font-weight: 600;

      &:hover {
        background: ${getColor('white')}!important;
        color: ${getColor('blue')};
      }
    `
  )};
`;
