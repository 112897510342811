import { images } from 'assets';
import { BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SContainer } from './InformationSentModal.styles';

interface IInformationSentModal {
  isOpen: boolean;
  isEmail?: boolean;
  email?: string;
  phoneNumber?: string;
  onClose: () => void;
}

export const InformationSentModal: React.FC<IInformationSentModal> = ({ isOpen, isEmail = false, email = '', phoneNumber = '', onClose }) => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate(ROUTES.login.path);
    onClose();
  };

  return (
    <CustomModal open={isOpen} modalName="login_informationSentModal" topPosition="0" width="40%" closeIconColor="charcoal70" padding="48px 24px" onClose={onClose}>
      <SContainer>
        <div className="image-container">
          <img src={images.success} alt="success" />
        </div>

        <div className="content-container">
          <h1>{isEmail ? t('forgot.EmailSent') : t('forgot.UsernameSent')}</h1>

          <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70" justifyContent="center" textAlign="center" marginBottom={16}>
            {isEmail ? t('forgot.CheckInbox') : t('forgot.CheckRegistered')}
          </BodyText>

          <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70" justifyContent="center" textAlign="center" marginBottom={8}>
            {isEmail ? t('forgot.ProvidedEmail') : t('forgot.ProvidedPhone')}
          </BodyText>

          <BodyText textType="bodyText" fontWeight="B" size="M" color="charcoal" justifyContent="center" textAlign="center" marginBottom={32}>
            {isEmail ? email : phoneNumber}
          </BodyText>

          <Button preset="blue-filled" onClick={handleGoToLogin}>
            {t('forgot.GoToLogin')}
          </Button>
        </div>
      </SContainer>
    </CustomModal>
  );
};
