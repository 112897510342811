import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { ISystemProperty } from 'store/user/properties/types';
import { SCustomSheet } from './AvailableDestinationsSheet.styles';
import { DestinationList } from './DestinationList';

interface AvailableDestinationsSheetProps {
  isOpen: boolean;
  onClose: () => void;
  countries?: ISystemProperty[];
  onLearnMoreClick?: () => void;
  transferTermsAndConditionsHandler?: () => void;
}

export const AvailableDestinationsSheet: React.FC<AvailableDestinationsSheetProps> = ({ isOpen, onClose, countries, onLearnMoreClick, transferTermsAndConditionsHandler }) => {
  const { t } = useTranslation('moveMoney');

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} placement="bottom" closable>
      <div className="header">
        <Title marginBottom={4}>{t('internationalTransferPage.AvailableDestinations')}</Title>
      </div>

      <div className="body">
        <BodyText size="N" color="charcoal70" lineHeight={1.4} marginBottom={16}>
          {t('internationalTransferPage.CheckTheAvailableTransferMethodsAndService')}
        </BodyText>

        <div className="additional-text">
          <BodyText size="T" lineHeight={1.3} marginBottom={24}>
            <Trans i18nKey="internationalTransferPage.CurrencyExchangeRatesAndAdditionalConversionFees" ns="moveMoney" />
          </BodyText>

          <BodyText size="T" lineHeight={1.3}>
            <Trans i18nKey="internationalTransferPage.LimitOf2TransactionsPerTransferMethod" ns="moveMoney" />
          </BodyText>
        </div>

        <DestinationList countries={countries} />

        <div className="learn-more-container">
          <BodyText className="learn-more-text" size="M" fontWeight="M">
            {t('internationalTransferPage.DontSeeYouCountryHere')}
          </BodyText>

          <BodyText className="learn-more-link" size="M" fontWeight="R" color="blue" onClick={onLearnMoreClick}>
            {t('internationalTransferPage.ClickHereToLearnMore')}
          </BodyText>
        </div>

        <BodyText size="T" color="charcoal70" lineHeight={1.3}>
          <Trans
            i18nKey="internationalTransferPage.InternationalTransferServicesProvidedThroughBancorp"
            ns="moveMoney"
            components={{
              termsLink: <span className="terms-link" onClick={transferTermsAndConditionsHandler} />,
            }}
          />
        </BodyText>
      </div>
    </SCustomSheet>
  );
};
