import React, { useRef, useState } from 'react';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { SAutocompleteInputContainer } from './AutocompleteInput.styles';

interface IAutocompleteInputProps {
  suggestions: string[];
  placeholder?: string;
  value: string;
  onChange: (val: string) => void;
  highlighting?: boolean;
  hasClearIcon?: boolean;
}

export const AutocompleteInput = ({ suggestions = [], value, onChange, placeholder, highlighting, hasClearIcon = true }: IAutocompleteInputProps) => {
  const [suitableSuggestions, setSuitableSuggestions] = useState<string[]>([]);
  const containerRef = useRef(null);

  const onInputChange = (inputValue: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(inputValue.target.value);
    setSuitableSuggestions(inputValue.target.value ? suggestions.filter((suggestion) => suggestion.toLowerCase().includes(inputValue.target.value.toLowerCase())) : []);
  };

  const onSelectSuggestion = (suggestion: string) => {
    onChange(suggestion);
    setSuitableSuggestions([]);
  };

  useClickOutside(containerRef, () => setSuitableSuggestions([]));

  return (
    <SAutocompleteInputContainer className="autocomplete-input" ref={containerRef}>
      <BaseInput value={value} onChange={onInputChange} placeholder={placeholder} hasClearIcon={hasClearIcon} type="text" />
      {suitableSuggestions?.length > 0 && (
        <ul className="autocomplete-input__suggestions">
          {suitableSuggestions?.map((suggestion: string, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className="autocomplete-input__suggestion">
              <div
                onClick={() => onSelectSuggestion(suggestion)}
                dangerouslySetInnerHTML={{ __html: highlighting ? suggestion.replace(new RegExp(value, 'gi'), (match) => `<b>${match}</b>`) : suggestion }}
              />
            </li>
          ))}
        </ul>
      )}
    </SAutocompleteInputContainer>
  );
};
