import { Title } from 'components/general/Typography';
import { Trans } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import React from 'react';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SStepItem } from './OnboardingFirstStepsPage.styles';

interface IStepCard {
  title: string;
  description: string;
  buttonText?: string;
  subtitle?: string;
  img: string;
  onClick?: () => void;
  className?: string;
}

export const StepItem = ({ title, description, subtitle, buttonText, img, onClick, className }: IStepCard) => {
  const { fromDesktopSmall } = useDeviceDimension();

  return (
    <SStepItem className={`step-card ${className}`}>
      <div className="step-card__inner">
        {fromDesktopSmall && (
          <div className="img-container">
            <img src={img} alt={title} width="120px" />
          </div>
        )}

        <Title className={`${className}-title`} size={fromDesktopSmall ? 'T' : 'sS'} fontWeight="SB" marginLeft={0} paddingRight={fromDesktopSmall ? 0 : 17} marginBottom={10}>
          {title}
        </Title>

        {subtitle && (
          <div className={`${className}-subtitle`}>
            <Trans
              i18nKey={String(subtitle)}
              components={{
                Middle: <div className="onboarding-subtitle middle-text" />,
                Bold: <div className="onboarding-subtitle bold-text" />,
              }}
            />
          </div>
        )}

        <div className={`${className}-description`}>
          <Trans
            i18nKey={String(description)}
            components={{
              Regular: <div className="onboarding-description regular-text" />,
              Bold: <div className="onboarding-description bold-text" />,
            }}
          />
        </div>
      </div>
      {buttonText && (
        <div className={fromDesktopSmall ? 'justify-center' : 'justify-end'}>
          <Button size="small" preset="blue-filled" onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </SStepItem>
  );
};
