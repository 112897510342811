import styled, { css } from 'styled-components';
import { flex, getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  ${flex('column', 'flex-start', 'stretch')};
  align-self: stretch;
  margin-bottom: 16px;

  .card {
    .card-body {
      display: flex;
      flex-direction: column;

      .how-to-use-item {
        ${flex('row', 'flex-start', 'flex-start')};
        margin-bottom: 16px;

        .number {
          ${flex('row')};
          height: 32px;
          width: 32px;
          font-size: 12px;
          font-weight: 700;
          font-family: DM Sans, sans-serif;
          border-radius: 50%;
          color: ${getColor('blue')};
          background: ${getColor('blue10')};
          margin-right: 16px;
        }

        .content-wrapper {
          ${flex('column', 'flex-start', 'flex-start')};
          flex: 1;

          .content-title {
          }

          .transfer-method-item {
            ${flex('row', 'flex-start', 'center')};
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            .svg-icon {
              margin-right: 8px;
            }
          }
        }
      }

      .what-you-need-item {
        ${flex('row', 'flex-start', 'stretch')};
        margin-bottom: 12px;

        .main-icon {
          ${flex('row', 'flex-start', 'flex-start')};
          margin-right: 16px;

          &.center {
            ${flex('row', 'flex-start')};
          }
        }

        .content {
          ${flex('column', 'center', 'flex-start')};

          ul.list {
            ${flex('column', 'flex-start', 'stretch')};
            padding: 18px 0 12px;
            margin-bottom: 0;
            list-style: none;

            li {
              ${flex('row', 'flex-start', 'flex-start')};
              padding-left: 6px;
              margin-bottom: 16px;

              &::before {
                content: '\u2022';
                color: ${getColor('blue')};
                font-weight: bold;
                margin-right: 14px;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &.v-center {
                align-items: center;
              }
            }
          }
        }

        &.unexpired-photo-id {
          .content {
            padding-top: 10px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.what-you-need-card {
      .card-body {
        .column-left {
          margin-bottom: 12px;
        }

        .column-right {
        }
      }
    }
  }

  ${mediaFrom(
    'desktopLarge',
    css`
      .section-title {
        .custom-text-inner {
          font-size: 20px;
          font-weight: 600;
          font-family: Poppins, sans-serif;
        }
      }

      .card {
        .card-header {
          padding: 32px 38px 32px 32px;

          .card-item-header-title {
            .custom-text-inner {
              font-size: 18px;
            }
          }
        }

        .card-body {
          padding: 16px 32px 32px;

          .how-to-use-item {
            margin-bottom: 32px;

            .number {
              height: 40px;
              width: 40px;
              font-size: 16px;
              margin-right: 8px;
            }

            .content-wrapper {
              padding-top: 12px;

              .content-title {
                margin-bottom: 18px;
              }

              .body-text {
                .custom-text-inner {
                  font-size: 14px;
                }
              }

              .transfer-method-item {
                margin-bottom: 12px;

                .body-text {
                  .custom-text-inner {
                    font-weight: 500;
                    color: ${getColor('charcoal70')};
                  }
                }

                .svg-icon {
                  background-color: ${getColor('blue')} !important;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .what-you-need-item {
            margin-bottom: 32px;

            .main-icon {
              margin-right: 8px;
            }

            &.unexpired-photo-id {
              .content {
                padding-top: 2px;
              }
            }
          }
        }

        &.what-you-need-card {
          .card-body {
            flex-direction: row;
            flex-wrap: wrap;

            .what-you-need-item {
              align-items: flex-start;

              .content {
                padding-top: 2px;
              }
            }

            .column-left {
              flex: 1;
              margin-bottom: 0;
              margin-right: 24px;
            }

            .column-right {
              flex: 1;

              .what-you-need-item {
                &.unexpired-photo-id {
                  ul.list {
                    padding-top: 10px;

                    li {
                      padding-left: 0;
                      margin-bottom: 8px;

                      &::before {
                        margin-right: 6px;
                      }
                    }
                  }
                }
              }
            }

            .additional-info {
              width: 100%;
              padding-top: 32px;
            }
          }
        }
      }
    `
  )};
`;
