import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${mediaFrom(
    'desktop',
    css`
      justify-content: space-between;
    `
  )}

  ${mediaFrom(
    577,
    css`
      align-items: center;
      padding: 0 20px 40px 20px;
    `
  )}

  .email-container {
    background: ${getColor('white')};
    border-radius: 20px;
    padding: 24px;
    width: 100%;

    .ant-form {
      width: 100%;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        max-width: 580px;
      `
    )}
  }

  .mobile-title-container {
    width: 100%;

    ${mediaUpTo(
      'tablet',
      css`
        display: block;
      `
    )}

    ${mediaFrom(
      577,
      css`
        display: none;
      `
    )}
  }

  .web-title-container {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 16px;
    margin-top: 48px;
    width: 100%;
  }

  .mobile-footer {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    button {
      :disabled {
        background: ${getColor('transparent')} !important;
        border: 2px solid ${getColor('charcoal40')};
        color: ${getColor('charcoal40')};
      }
    }
  }
`;
