import React from 'react';
import { IconSign } from 'components/general/Icon/IconSign';
import { Icon } from 'components/general/Icon/Icon';
import { Title, BodyText } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SOptionItem, SBodyText } from './OptionItem.styles';

type TDoMoreOptionItemProps = {
  className?: string;
  title: string;
  topBadge?: string;
  description: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const OptionItem = ({ title, topBadge, description, className, isDisabled = false, onClick }: TDoMoreOptionItemProps) => {
  const { fromDesktopSmall } = useDeviceDimension();

  const onClickHandler = () => {
    if (!isDisabled) onClick?.();
  };

  return (
    <SOptionItem className={`do-more-option ${isDisabled && 'disabled'} ${className}`} onClick={onClickHandler}>
      {topBadge && (
        <SBodyText size="T" fontWeight="M" color="bluePurple">
          {topBadge}
        </SBodyText>
      )}

      <div className="do-more-option__content">
        <div className="do-more-option__title-wrapper">
          <Title size="sM" fontWeight="B">
            {title}
          </Title>
          {!isDisabled && fromDesktopSmall && <Icon name="chevronRight" cursorPointer className="arrow-icon" size="smaller" color="blue" />}
        </div>

        <BodyText size="T" lineHeight={1.3} color="charcoal70">
          {description}
        </BodyText>
      </div>

      {isDisabled && <IconSign iconName="lock" iconColor="white" bgColor="charcoal40" size={40} />}

      {!isDisabled && !fromDesktopSmall && <Icon name="chevronRight" cursorPointer className="arrow-icon" size="xs" color="charcoal" marginLeft={15} />}
    </SOptionItem>
  );
};
