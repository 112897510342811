import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { IAccountItem, isThirdPartyAccount, IThirdParty } from 'store/user/accounts/accounts.types';
import { useGetAccountsByIdQuery } from 'store/user/accounts/accounts.api';
import { BodyText } from 'components/general/Typography';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { TextButton } from 'components/general/TextButton/TextButton';
import { EditAccountNameModal } from 'views/Account/AccountInformation/Modals/editAccount/EditAccountNameModal/EditAccountNameModal';
import { SetAccountDefaultModal } from 'views/Account/AccountInformation/Modals/editAccount/SetAccountDefaultModal/SetAccountDefaultModal';
import { ConfirmJointAccountModal } from 'views/Account/AccountInformation/Modals/editAccount/ConfirmJointAccountModal/ConfirmJointAccountModal';
import { selectCurrentAuthState, selectPolicies } from 'store/user/authentication.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useToggle } from 'utils/hooks/useToggle';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { Loader } from 'components/general/Loader/Loader';
import { useAccounts } from 'utils/hooks/useAccounts';
import { RemoveExternalAccountModal } from 'views/Account/AccountInformation/Modals/closingAccount/RemoveExternalAccountModal';
import { SContent, SAccountControls, SSwitch } from './EditAccountModal.styles';

interface EditAccountModalProps {
  account: IAccountItem | IThirdParty;
  isOpen: boolean;
  onAccountClose: () => void;
  defaultAccountName?: string;
  onClose: () => void;
  onOpen: () => void;
  isLoading?: boolean;
}

export const EditAccountModal = ({ account, isOpen, onOpen, onClose, onAccountClose, defaultAccountName, isLoading }: EditAccountModalProps) => {
  const { t } = useTranslation('accountInformation');
  const policies = useSelector(selectPolicies);
  const currentAuthState = useSelector(selectCurrentAuthState);
  const theme = useTheme();
  const { internalAccounts, isLoading: accountsDataLoading } = useAccounts();
  const confirmJointAccountModal = useToggle();
  const editAccountNameModal = useToggle();
  const accountDefaultModal = useToggle();
  const removeExternalAccountModal = useToggle();
  const pendingRequestNames = currentAuthState?.user?.pendingRequestNames;
  const { closingRequested } = useGetAccountsByIdQuery(account.accountId)?.currentData?.account ?? {};
  const isClosurePending = pendingRequestNames?.includes('CloseAccountRequestPending');
  const isInvitePending = pendingRequestNames?.includes('IsJointAccountInvite');
  const isExternal = isThirdPartyAccount(account);
  const accountName = isExternal ? account.externalDisplayAccountName : account.nickname?.trim() || account.name;
  const isDefault = !isExternal && account.isDefault;
  const isCashAccount = !isExternal && account.isCashType;
  const cashAccountsAmount = internalAccounts.filter((a) => a.type === 'Cash').length;
  const jointHolderName = !isExternal && account.jointHolderName;
  const { isDesktopSize } = useDeviceDimension();
  const title = isExternal ? t('accountInformation.EditExternalAccount') : t('accountInformation.EditAccount', { accountType: t(`accountInformation.SelectedAccountTypes.${account.type}`) });
  const closeBtnText = isExternal ? t('accountInformation.RemoveAccount') : t('accountInformation.Close Account', { type: t(`accountInformation.SelectedAccountTypes.${account.displayType}`) });

  const headerStyle = {
    background: theme.white,
    paddingBottom: 0,
    '.custom-title-text': {
      'font-size': '24px',
    },
  };

  const handleOpenSetAccountDefaultModal = () => {
    if (isDefault) return;
    onClose();
    accountDefaultModal.show();
  };

  const handleOpenConfirmJointAccountModal = () => {
    onClose();
    confirmJointAccountModal.show();
  };

  const handleCloseAccount = () => {
    if (isClosurePending) return;
    onClose();
    if (isExternal) {
      removeExternalAccountModal.show();
    } else {
      onAccountClose();
    }
  };

  const handleCloseAccountDefaultModal = () => {
    accountDefaultModal.hide();
    onOpen();
  };

  const handleCloseConfirmJointAccountModal = () => {
    confirmJointAccountModal.hide();
    onOpen();
  };

  const handleEditAccountNameModal = () => {
    onAccountClose();
    editAccountNameModal.show();
  };

  const handleCloseEditAccountNameModal = () => {
    editAccountNameModal.hide();
    onOpen();
  };

  return (
    <>
      <CustomSheet
        isOpen={isOpen}
        headerStyle={headerStyle}
        modalName="externalAccount_editAccountModal"
        title={title}
        onClose={onClose}
        isModal={isDesktopSize}
        paddingBottom="56px"
        isLocked={isLoading || accountsDataLoading}
      >
        {(isLoading || accountsDataLoading) && <Loader />}
        <SContent>
          <SAccountControls>
            <div className="edit-control-wrapper">
              <div className="edit-control-left">
                <BodyText size="N" fontWeight="R" color="charcoal70">
                  {t('accountInformation.Account name')}
                </BodyText>
                <BodyText fontWeight="M" size="M" marginTop={5}>
                  {accountName}
                </BodyText>
              </div>
              <TextButton iconName="edit" fontWeight="M" className="edit-text-button" onClick={handleEditAccountNameModal}>
                {t('accountInformation.Edit')}
              </TextButton>
            </div>
            {isCashAccount && cashAccountsAmount > 1 && (
              <div className="edit-control-wrapper">
                <div className="edit-control-left">
                  <BodyText size="N" fontWeight="R" color="charcoal70">
                    {t('accountInformation.Default Account')}
                  </BodyText>
                </div>
                <SSwitch checked={isDefault} onChange={handleOpenSetAccountDefaultModal} />
              </div>
            )}

            {jointHolderName && (
              <div className="edit-control-wrapper">
                <div className="edit-control-left">
                  <BodyText size="N" fontWeight="R" color="charcoal70">
                    {t('accountInformation.Joint Account holder')}
                  </BodyText>
                  <BodyText fontWeight="B" size="N" marginTop={5}>
                    {jointHolderName}
                  </BodyText>
                </div>
              </div>
            )}

            {isInvitePending && !jointHolderName && (
              <div className="edit-control-wrapper">
                <div className="edit-control-left">
                  <BodyText size="N" fontWeight="R" color="charcoal70">
                    {t('accountInformation.Joint Account holder')}
                  </BodyText>
                </div>
                <BodyText fontWeight="R" size="T" marginTop={5} color="charcoal70">
                  {t('accountInformation.Pending')}
                </BodyText>
              </div>
            )}

            {policies?.JointAccountEnabled && isCashAccount && !jointHolderName && (
              <div className="edit-control-wrapper">
                <div className="edit-control-left">
                  <BodyText size="N" fontWeight="R" color="charcoal70">
                    {t('accountInformation.Joint Account holder')}
                  </BodyText>
                </div>
                {account.owner && (
                  <TextButton iconName="circlePlus" fontWeight="M" className="edit-text-button" onClick={handleOpenConfirmJointAccountModal}>
                    {t('accountInformation.Add')}
                  </TextButton>
                )}
              </div>
            )}
          </SAccountControls>
          <div className="btn-container">
            <TextButton className="close-account-btn" preIconName={isExternal ? 'trash' : 'brokenHeart'} color="red" size="M" fontWeight="M" onClick={handleCloseAccount}>
              {closingRequested ? t('accountInformation.AccountClosureInProgress') : closeBtnText}
            </TextButton>
          </div>
        </SContent>
        <SuttonDisclaimerNote />
      </CustomSheet>

      {!isExternal && (
        <>
          <ConfirmJointAccountModal open={confirmJointAccountModal.isActive} handleClose={handleCloseConfirmJointAccountModal} accountName={account?.name} accountForJointId={account.accountId} />
          <SetAccountDefaultModal accountId={account.accountId} open={accountDefaultModal.isActive} handleClose={handleCloseAccountDefaultModal} currentDefaultAccountName={defaultAccountName} />
        </>
      )}

      <EditAccountNameModal
        currentName={isExternal ? account.externalDisplayAccountName : account.nickname || account.name}
        accountId={isExternal ? account.id : account.accountId}
        editAccountNameOpen={editAccountNameModal.isActive}
        isActive={editAccountNameModal.isActive}
        onClose={handleCloseEditAccountNameModal}
        isExternal={isExternal}
        isLoading={isLoading}
        isDesktopSize={isDesktopSize}
      />
      {isExternal && <RemoveExternalAccountModal open={removeExternalAccountModal.isActive} onClose={removeExternalAccountModal.hide} accountId={account.id} handleOpenEditModal={onOpen} />}
    </>
  );
};
