import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { SJointButtons, SLink, SListItemText } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IMakeJointCashAccountModal {
  open: boolean;
  onClose: () => void;
  showDisclosureSheet: () => void;
  handleOnYes: () => void;
  handleOnNo: () => void;
  isClosable?: boolean;
  accountName?: string;
  isSecondaryCash?: boolean;
}

export const MakeJointCashAccountModal = ({ open, onClose, showDisclosureSheet, handleOnYes, handleOnNo, accountName, isClosable = true, isSecondaryCash = false }: IMakeJointCashAccountModal) => {
  const { t } = useTranslation('cashAccountOpening', { keyPrefix: 'myInfo' });
  const accountNameStr = isSecondaryCash ? t('ThisAccount') : accountName || t('ThisAccount');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <CustomSheet
      isModal
      isOpen={open}
      onClose={onClose}
      closable={isClosable}
      modalName="makeJointAccountModal"
      wrapperPadding={false}
      padding="20px 24px 24px"
      width={isDesktopSize ? '648px' : 'auto'}
    >
      <div>
        <Title font="Poppins" size="M" fontWeight="M" color="charcoal" marginTop={5} marginBottom={16}>
          {t('MakeThisJointCashAccount', { accountName: accountNameStr })}
        </Title>

        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B">
          {t('YourJointAccountholderWill')}
        </BodyText>

        <div>
          <SListItemText>{t(`CoOwnTheCashAccountWithEqualRights`)}</SListItemText>
          <SListItemText>{t(`CoOwnAnyNeedsAndOrGoalsAccounts`)}</SListItemText>
          <SListItemText>{t(`HaveTheirOwnDebitCard`)}</SListItemText>
          <SListItemText>{t(`HaveUnrestrictedAccessToTheMoney`)}</SListItemText>
          <SListItemText>
            <div>
              <Trans i18nKey="myInfo.BeAbleToDoAnything" ns="cashAccountOpening" components={{ Link: <SLink onClick={showDisclosureSheet} /> }} />
            </div>
          </SListItemText>
        </div>
      </div>

      <SJointButtons>
        <Button size="large" preset="blue-filled" onClick={handleOnYes}>
          {t(`Yes`)}
        </Button>
        <Button size="large" preset="blue-outlined" onClick={handleOnNo}>
          {t(`No`)}
        </Button>
      </SJointButtons>

      <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginTop={28} lineHeight={1.3}>
        <Trans i18nKey="Full" ns="footer" components={{ Link: <SLink href="http://www.fdic.gov" /> }} target="_blank" />
      </BodyText>
    </CustomSheet>
  );
};
