import React, { useEffect, useRef, useState } from 'react';
import { TThemeColor } from 'styles/theme';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useWindowResize } from 'utils/hooks/useWindowResize';
import { SBar, SBlockWrapper, SContainer, SWebContainer, SWebStep, SWebStepsSlug } from './Stepper.styles';

export interface IStepper {
  steps: string[];
  webSteps: string[];
  currentStep: number;
  currentWebStep: number;
  passedColor?: TThemeColor;
  activeColor?: TThemeColor;
  inactiveColor?: TThemeColor;
}

type TScrollDirection = 'left' | 'right' | null;

export const Stepper = ({ steps, webSteps, currentStep = 0, currentWebStep = 0, passedColor = 'green', activeColor = 'blue', inactiveColor = 'creamS10' }: IStepper) => {
  const { t } = useTranslation('header');
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const activeItemElem = scrollContainerRef.current?.querySelector('.web-step.active');
  const scrollIntervalRef = useRef<undefined | NodeJS.Timer>();
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<TScrollDirection>(null);
  const [scrollPos, setScrollPos] = useState<TScrollDirection>(null);

  const { isResizing } = useWindowResize();

  const scrollContainerClassName = clsx('stepper-web__scroll-container', scrollPos && `scrolled-to-${scrollPos}`, { scrollable: isScrollable });

  const getBarColor = (index: number): TThemeColor => {
    if (currentStep === index) {
      return activeColor;
    }

    return currentStep > index ? passedColor : inactiveColor;
  };

  const getWebColor = (index: number): TThemeColor => {
    if (currentWebStep === index) return 'blue';
    if (currentWebStep > index) return 'charcoal70';
    return 'creamS30';
  };

  const checkScrollPos = () => {
    const scrollWidth = scrollContainerRef.current?.scrollWidth ?? 0;
    const clientWidth = scrollContainerRef.current?.clientWidth ?? 0;
    const scrollLeft = scrollContainerRef.current?.scrollLeft ?? 0;
    const isScrolledToLeft = scrollLeft === 0;
    const isScrolledToRight = scrollLeft + (scrollContainerRef.current?.clientWidth ?? 0) === scrollContainerRef.current?.scrollWidth;

    setIsScrollable(scrollWidth > clientWidth);
    setScrollPos(null);

    if (isScrolledToLeft) {
      setScrollPos('left');
      clearInterval(scrollIntervalRef.current);
    }

    if (isScrolledToRight) {
      setScrollPos('right');
      clearInterval(scrollIntervalRef.current);
    }
  };

  const handleScroll = (scrollOffset: number) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += scrollOffset;
    }
  };

  const handleStartScroll = (direction: 'left' | 'right') => {
    setScrollDirection(direction);
  };

  const handleStopScroll = () => {
    setScrollDirection(null);
  };

  useEffect(() => {
    if (scrollDirection) {
      scrollIntervalRef.current = setInterval(() => {
        handleScroll(scrollDirection === 'left' ? -15 : 15);
      }, 100);
    } else {
      clearInterval(scrollIntervalRef.current);
    }

    return () => clearInterval(scrollIntervalRef.current);
  }, [scrollDirection]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      checkScrollPos();
    }
  }, [scrollContainerRef.current, isResizing]);

  // Scroll scrolled out active element into view on mount
  useEffect(() => {
    if (activeItemElem && scrollContainerRef.current) {
      const activeElemRightPos = activeItemElem?.getBoundingClientRect().right;
      const rightScrollDiff = scrollContainerRef.current.clientWidth - activeElemRightPos;
      if (rightScrollDiff < 0) {
        console.log('rightScrollDiff', rightScrollDiff);
        scrollContainerRef.current.scrollLeft -= rightScrollDiff;
      }
    }
  }, [activeItemElem, isScrollable]);

  return (
    <>
      <SContainer className="stepper-container">
        {steps.map((step, index) => {
          return (
            <SBlockWrapper>
              <SBar bgColor={getBarColor(index)} />
            </SBlockWrapper>
          );
        })}
      </SContainer>
      <SWebContainer className="stepper-web">
        {scrollPos !== 'left' && isScrollable && (
          <div
            className="scroll-arrow scroll-arrow_left"
            onMouseDown={() => {
              handleStartScroll('left');
            }}
            onTouchStart={() => {
              handleStartScroll('left');
            }}
            onMouseUp={handleStopScroll}
            onTouchEnd={handleStopScroll}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <Icon name="chevronLeft" size="smaller" />
          </div>
        )}
        <div className={scrollContainerClassName} ref={scrollContainerRef} onScroll={checkScrollPos}>
          <SWebStepsSlug className="stepper-web-slug">
            {webSteps.map((step, index) => {
              return (
                <SWebStep className={clsx('web-step', { active: currentWebStep === index, done: currentWebStep > index })}>
                  <BodyText textType="bodyText" size="T" fontWeight="R" color={getWebColor(index)}>
                    {t(`header.${step}`)}
                  </BodyText>
                  {currentWebStep > index && <Icon name="tick" color="green" size="smallest" />}
                </SWebStep>
              );
            })}
          </SWebStepsSlug>
        </div>
        {scrollPos !== 'right' && isScrollable && (
          <div
            className="scroll-arrow scroll-arrow_right"
            onMouseDown={() => handleStartScroll('right')}
            onTouchStart={() => handleStartScroll('right')}
            onMouseUp={handleStopScroll}
            onTouchEnd={handleStopScroll}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <Icon name="chevronRight" size="smaller" />
          </div>
        )}
      </SWebContainer>
    </>
  );
};
