import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SAccountCardItem = styled.div`
  background: ${getColor('white')};
  padding: 30px 20px 20px;
  margin: 0 4px 10px;
  border-radius: 20px;

  .account-items-container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;

    .account-icon {
      flex: 0 0 auto;
    }

    .joint-accountholder-caption {
      display: flex;

      .body-text:first-child {
        margin-right: 2px;
      }
    }
  }
`;
