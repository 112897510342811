import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { SLayout, SCustomCard } from './BodySection.styles';

interface IBodySectionProps {
  textList: string[];
  note?: string;
}

export const BodySection: React.FC<IBodySectionProps> = ({ textList, note = '' }) => {
  const { t } = useTranslation('moveMoney');
  return (
    <>
      <SLayout>
        {textList.map((text) => (
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" marginBottom={12} lineHeight={1.5}>
            {text}
          </BodyText>
        ))}
      </SLayout>
      {note && (
        <SCustomCard padding="12px">
          <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="T" fontWeight="B" marginBottom={12} lineHeight={1.5}>
            {t('transferPage.Note')}
          </BodyText>
          <BodyText textType="bodyText" font="DM Sans" color="charcoal70" size="T" fontWeight="R" lineHeight={1.5}>
            {note}
          </BodyText>
        </SCustomCard>
      )}
    </>
  );
};
