import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SWrapper = styled.div`
  padding: 0 24px;

  .logout-btn .custom-row {
    margin-bottom: 0;
  }
`;

export const SDropdownContainer = styled.div`
  width: 296px;
  position: absolute;
  top: 94px;
  right: 0;
  display: flex;
  flex-direction: column;
  background: ${getColor('white')};
  padding: 8px 0;
  border-radius: 16px;
  z-index: 100;

  & > .section {
    padding: 12px 24px 20px 24px;

    &:not(:last-child) {
      border-bottom: 2px solid ${getColor('creamS5')};
    }

    &.language {
      padding-top: 16px;

      .body-text {
        margin-bottom: 12px;

        .custom-text-inner {
          font-weight: 500;
        }
      }
    }

    &.logout {
      padding: 20px 24px 12px 24px;

      .logout-btn {
        margin: 0;

        .body-text {
          margin-left: 16px;

          .custom-text-inner {
            font-weight: 500;
          }
        }
      }
    }

    .menu-item {
      .title,
      .description {
        margin-left: 16px;

        .custom-text-inner {
          font-size: 12px;
        }
      }

      .title {
        .custom-text-inner {
          font-weight: 500;
        }
      }

      .description {
        margin-top: 6px;
      }
    }
  }
`;
