import React, { useEffect, useRef, useState } from 'react';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { Loader } from 'components/general/Loader/Loader';
import { STextArea } from './EditTransactionNoteSheet.styles';

interface IEditTransactionNoteSheetProps {
  open: boolean;
  note: string;
  onClose: () => void;
  onSave: (note: string) => void;
  loading: boolean;
}

export const EditTransactionNoteSheet = ({ open, loading = false, note, onSave, onClose }: IEditTransactionNoteSheetProps) => {
  const { t } = useTranslation('accountInformation');
  const noteInputRef = useRef<HTMLTextAreaElement>(null);

  const [inputVal, setInputVal] = useState(note);

  const handleEdit = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputVal(value);
  };

  useEffect(() => {
    if (open) {
      noteInputRef.current?.focus();
      setInputVal(note);
    } else {
      setInputVal('');
    }
  }, [open]);

  return (
    <CustomSheet isOpen={open} paddingTop="35px" header={false} wrapperPadding={false} onClose={onClose} modalName="transactionDetails">
      {loading && <Loader />}
      <CustomRow marginBottom={32} alignItems="center" justifyContent="flex-start">
        <Title font="Poppins" color="charcoal" fontWeight="SB" size="S">
          {t('accountInformation.AddNote')}
        </Title>
      </CustomRow>
      <STextArea ref={noteInputRef} maxLength={300} value={inputVal} onChange={handleEdit} style={{ height: 85, resize: 'none' }} placeholder={t('accountInformation.AddYourNotePlaceholder')} />
      <Button marginTop={48} marginBottom={24} onClick={() => onSave(inputVal)} preset="blue-filled" disabled={inputVal === note}>
        {t('accountInformation.SaveNote')}
      </Button>
    </CustomSheet>
  );
};
