import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/general/Icon/Icon';
import { TChipProps, TChipStyle } from './Chip.types';
import { SChip } from './Chip.styles';

export const Chip = ({ type = 'blue', isActive = false, id, value = '', children, iconName, isDisabled = false, onClick = () => {}, className, ...props }: TChipProps) => {
  const styleProps: TChipStyle = {
    marginTop: props.marginTop,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    marginBottom: props.marginBottom,
  };

  const handleClick = (event: React.MouseEvent) => {
    if (!isDisabled) {
      onClick(value, id, event);
    }
  };

  const classNameStr = clsx(
    'chip',
    `type-${type}`,
    {
      active: isActive,
      disabled: isDisabled,
    },
    className
  );

  return (
    <SChip className={classNameStr} onClick={handleClick} data-testid={props['data-testid']} {...styleProps}>
      {iconName && <Icon name={iconName} marginRight={8} />}
      {children}
    </SChip>
  );
};
