import styled from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import pennyJarDots from 'assets/images/pennyJarDots.svg';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCardLayout = styled(CustomCard)`
  display: flex;
  border-radius: 20px;
  min-height: 164px;
  padding: 24px 20px 14px 0;
  margin-bottom: 48px;
  position: relative;
  background: ${getColor('white')} url(${pennyJarDots}) no-repeat top left / 382px 164px;

  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .img-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 16px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  button.decline,
  button.accept {
    padding: 5px 15px;
    min-width: auto;
    wrap-option: break;
  }
`;
