import React from 'react';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { images } from 'assets';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';

interface IInviteFinishedModal {
  open: boolean;
  onClose: () => void;
  isClosable?: boolean;
}

export const InviteFinishedModal: React.FC<IInviteFinishedModal> = ({ open, onClose, isClosable = true }) => {
  const { t } = useTranslation('inviteEarn');

  return (
    <CustomModal open={open} onCancel={onClose} modalName="inviteFinishedModal" topPosition="18%" closable={isClosable} padding="20px 24px 24px">
      <div className="center-image">
        <img src={images.success} alt="inviteFinished" width="120px" />
      </div>
      <div>
        <Title font="Poppins" size="M" fontWeight="M" color="charcoal" marginTop={32} marginBottom={16}>
          {t('ThankYou!')}
        </Title>

        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
          {t('YourInviteHasBeenSent')} <br />
          {t('YouEarned20PercapitaPoints!')}
        </BodyText>
      </div>
      <Button marginTop={32} size="large" preset="blue-filled" onClick={onClose}>
        {t(`common.Continue`)}
      </Button>
    </CustomModal>
  );
};
