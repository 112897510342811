import styled, { css } from 'styled-components';
import { Icon } from 'components/general/Icon/Icon';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SBgArcTop } from 'styles/common';

export const STick = styled(Icon).attrs({
  color: 'blue',
})`
  flex: 0 0 auto;
`;

export const SCustomRow = styled(CustomRow)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  z-index: 9999;
  background-color: ${getColor('cream70')};
`;

export const SDesktopLayout = styled.div`
  display: flex;
  flex-direction: column;
  ${SBgArcTop};

  .web-header {
    margin-bottom: 0;
  }

  .header {
    padding: 48px 11% 0 !important;
    margin-bottom: 100px;

    .title-container {
      margin-right: 138px;

      .enroll-subtitle {
        margin-bottom: 24px;

        .custom-title-text {
          font-size: 40px;
          font-weight: 500;
        }
      }
    }

    .image-container {
      img {
        width: 180px;
        height: 185px;
      }
    }
  }

  .enroll-list-name {
    .list-item {
      align-items: flex-start;

      .list-item-icon {
        height: 24px;
        width: 24px;
      }

      .list-item-text {
        .custom-text-inner {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const SLayout = styled.div`
  padding-bottom: 42px;
  padding-left: 5px;
  padding-top: 7px;

  .center-image {
    margin-top: 55px;
  }

  .custom-text-inner {
    line-height: 1.3;
  }

  .footer {
    margin-top: 45px;
  }

  ${mediaUpTo(
    415,
    css`
      .center-image {
        margin-top: 40px;
      }

      .image-subtitle {
        margin-top: 45px;
      }
    `
  )}

  ${mediaUpTo(
    400,
    css`
      .enroll-title {
        margin-bottom: 25px;
      }

      .enroll-subtitle {
        margin-bottom: 37px;
        & .custom-title-text {
          font-size: 26px;
        }
      }

      .center-image {
        margin-top: 40px;

        & img {
          height: 165px;
        }
      }

      .image-subtitle {
        margin-top: 45px;
      }
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      .enroll-title {
        margin-bottom: 27px;
        & .custom-title-text {
          font-size: 20px;
        }
      }

      .enroll-subtitle {
        margin-bottom: 33px;

        & .custom-title-text {
          font-size: 23px;
        }
      }

      .enroll-list-name {
        & .body-text {
          margin-left: 10px;
        }
        & .custom-text-inner {
          font-size: 12px;
        }
      }

      .center-image {
        margin-top: 45px;

        & img {
          height: 155px;
        }
      }

      & .image-subtitle {
        margin-top: 37px;
        & .custom-text-inner {
          font-size: 13px;
        }
      }
    `
  )}
`;

export const SNavigationFooter = styled.div`
  padding: 24px;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(251, 247, 239, 0.2) 0%, rgba(251, 247, 239, 0.2) 100%), rgba(255, 255, 255, 0.6);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(12px);
  margin: 0 48px 72px 48px;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    min-width: 226px !important;
    max-width: 250px !important;

    padding: 14px 40px;

    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
  }
`;
