import React, { useState } from 'react';
import { Form } from 'antd';
import { Loader } from 'components/general/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { BodyText, Title } from 'components/general/Typography';
import { useCashAccountOpening } from 'utils/hooks/useCashAccountOpening';
import { SButtonWrapper } from 'views/OpenCashAccount/MyInfo/MyInfo.style';
import { INameForm } from 'views/OpenCashAccount/MyInfo/MyInfo.type';
import { NameForm } from 'views/OpenCashAccount/MyInfo/NamePage/NameForm/NameForm';
import { WebHeader } from 'views/OpenCashAccount/MyInfo/Header/WebHeader/WebHeader';
import { Footer } from 'views/OpenCashAccount/MyInfo/Footer/Footer';
import { SLayout } from './EditNamePage.styles';

interface IEditNamePageProps {
  handleSubmit: (values: INameForm) => void;
}

export const EditNamePage: React.FC<IEditNamePageProps> = ({ handleSubmit }) => {
  const { openingAccountData, getOnboardingDataIsLoading } = useCashAccountOpening();
  const [form] = Form.useForm();
  const [isContinueActive, setIsContinueActive] = useState(false);
  const { t } = useTranslation('cashAccountOpening');

  return (
    <>
      <WebHeader showExit={false} title={t('accountOpening.GoBackToAccountInformation')} />
      <SLayout>
        <div className="content-container">
          <div className="title-container">
            <Title textAlign="start" size="M" fontWeight="SB" font="Poppins">
              {t('myInfo.Name')}
            </Title>
            <BodyText textType="bodyText" fontWeight="R" color="charcoal70" size="N" marginTop={4}>
              {t('myInfo.ChangeYourNameHere')}
            </BodyText>
          </div>

          <div className="form-card">
            {getOnboardingDataIsLoading ? <Loader /> : <NameForm handleSubmit={handleSubmit} form={form} onCompletion={setIsContinueActive} openingAccountData={openingAccountData} isEditMode />}
          </div>
        </div>

        <Footer isDisabled={!isContinueActive} onClick={form.submit} secondBtnText={t('myInfo.SaveChanges')} marginBottom={50} />

        <div className="mobile-footer">
          <SButtonWrapper>
            <Button size="large" disabled={!isContinueActive} onClick={form.submit} preset="blue-filled">
              {t('myInfo.SaveChanges')}
            </Button>
          </SButtonWrapper>
        </div>
      </SLayout>
    </>
  );
};
