import styled, { css } from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { getColor, mediaFrom, ifProp, mediaUpTo, mediaBetween } from 'utils/helpers/styleHelpers';

interface ISContainerProps {
  isWellnessDesktop?: boolean;
}

export const SContainer = styled(CustomCard)<ISContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 16px 0;

  ${mediaUpTo(
    428,
    css`
      padding: 20px 16px;
    `
  )}

  ${ifProp(
    'isWellnessDesktop',
    css`
      flex: none !important;
      flex-direction: row;
      align-items: center;
    `
  )};

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${ifProp('isWellnessDesktop', '0', '10')}px;
    align-items: center;

    ${mediaBetween(
      'tablet',
      1199,
      css`
        width: 70%;
      `
    )}

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
    }

    .icon-text-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;

      ${mediaUpTo(
        'tablet',
        css`
          .custom-text-inner {
            font-size: 16px;
            margin-top: 0;
          }
        `
      )}

      ${mediaFrom(
        577,
        css`
          .custom-text-inner {
            white-space: nowrap;
          }
        `
      )}
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;

    ${ifProp(
      'isWellnessDesktop',
      css`
        flex-direction: row;
        align-items: center;
      `
    )};
  }

  .description {
    margin-left: ${ifProp('isWellnessDesktop', '20', '80')}px;

    ${mediaUpTo(
      'tablet',
      css`
        margin-left: 70px;
      `
    )}

    strong {
      color: ${getColor('charcoal')};
    }

    ${mediaUpTo(
      428,
      css`
        .custom-text-inner {
          font-size: 12px;
        }

        margin-left: 70px;
      `
    )}
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: ${ifProp('isWellnessDesktop', '0', '20')}px;

    button {
      font-size: 12px;
      font-weight: 500;
      font-family: 'Poppins';

      ${mediaUpTo(
        428,
        css`
          padding: 8px 20px;
          height: 32px;
        `
      )}

      ${mediaBetween(
        'tablet',
        1199,
        css`
          position: absolute;
          top: 40%;
          right: 5%;
        `
      )}
    }

    ${ifProp(
      'isWellnessDesktop',
      css`
        height: 40px;
      `,
      css`
        ${mediaFrom(
          768,
          css`
            justify-content: flex-start;
            margin-left: 80px;
          `
        )}
        ${mediaFrom(
          'desktop',
          css`
            margin-left: 80px;

            button {
              padding: 8px 24px;
              font-weight: 600;
            }
          `
        )}
      `
    )};
  }

  .mobile-points {
    margin: 30px 0 0 80px;

    ${mediaUpTo(
      'tablet',
      css`
        margin-left: 70px;
      `
    )}
  }

  ${mediaFrom(
    577,
    css`
      .mobile-points {
        display: none;
      }
    `
  )}

  .web-points {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}

    .percapita-points {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      ${mediaFrom(
        'tablet',
        css`
          margin-left: 80px;
        `
      )}
    }

    .points {
      margin-left: 15px !important;

      ${mediaFrom(
        'desktop',
        css`
          margin-left: 60px !important;
        `
      )}
    }

    .title {
      margin-top: 0 !important;
      white-space: nowrap;

      ${mediaFrom(
        'desktop',
        css`
          font-size: 14px;
        `
      )}

      ::after {
        content: ':';
      }
    }
  }

  .percapita-points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .title {
      font-family: 'DM Sans';
      font-size: 12px;
      font-weight: 400;
      color: ${getColor('charcoal70')};
      margin-top: 12px;

      ${mediaFrom(
        428,
        css`
          color: ${getColor('charcoal')};
          margin-left: 0;
          margin-top: 0;

          ::after {
            content: ':';
          }
        `
      )};
    }

    .points {
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 600;

      ${mediaBetween(
        428,
        'tablet',
        css`
          font-size: 28px;
          font-weight: 600;
        `
      )}

      ${mediaFrom(
        577,
        css`
          font-size: 16px;
          font-weight: 700;
        `
      )}
    }
  }

  .icon-circleInfo {
    position: absolute;
    top: 20px;
    right: 16px;

    ${mediaBetween(
      'tablet',
      1199,
      css`
        position: relative;
        top: 0;
        right: 10%;
      `
    )}
  }

  ${mediaFrom(
    1199,
    css`
      flex: 1;

      &.goals-and-tools {
        .header {
          width: 100%;
        }

        .description .custom-text-inner {
          font-size: 14px;
        }
      }
    `
  )}
`;
