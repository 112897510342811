import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { snakeCaseToCamelCase } from 'utils/helpers/string';
import { SCard, SCardHeader, SCheckboxWrapper } from './CategoriesDrawer.styles';

type TCategoriesDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onApply: (selectedOptions: string[]) => void;
  sheetHeight?: string;
  confirmButtonOptions?: {
    title?: string;
    size: 'small' | 'middle' | 'large';
  };
  categories: string[];
  selectedCategories: string[];
};

export const CategoriesDrawer = ({ isOpen, selectedCategories, categories, onClose, onApply, sheetHeight = '90%', confirmButtonOptions }: TCategoriesDrawerProps) => {
  const [checkedCategories, setCheckedCategories] = useState<string[]>(selectedCategories);
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const { t } = useTranslation(['balancesTransactions', 'notificationsCenter', 'percapitaPay']);

  useEffect(() => {
    if (checkedCategories.length === categories.length && !selectAll) {
      setSelectAll(true);
    } else if (checkedCategories.length !== categories.length && selectAll) {
      setSelectAll(false);
    }
  }, [checkedCategories, categories.length, selectAll]);

  const handleCheckboxChange = (name: string) => {
    setCheckedCategories((prev) => (prev.includes(name) ? prev.filter((option) => option !== name) : [...prev, name]));
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setCheckedCategories([]);
    } else {
      setCheckedCategories(categories);
    }
    setSelectAll(!selectAll);
  };

  const handleApplyClick = () => {
    onApply(checkedCategories);
    onClose();
  };

  useEffect(() => {
    setCheckedCategories(selectedCategories);
  }, [selectedCategories, isOpen]);

  return (
    <CustomSheet
      isOpen={isOpen}
      onClose={onClose}
      header
      paddingBottom="56px"
      headerStyle={{ minHeight: 0, padding: 0 }}
      wrapperPadding={false}
      height={sheetHeight}
      contentWrapperStyle={{ borderRadius: '24px 24px 0px 0px' }}
      modalName="categories"
    >
      <CustomRow minHeight="100%" flexDirection="column">
        <CustomRow flexDirection="column" width="100%" justifyContent="flex-start" alignItems="flex-start">
          <CustomRow justifyContent="flex-start" alignItems="center" marginBottom={18} paddingTop={10}>
            <Icon name="arrowLeft" color="charcoal" cursorPointer size="small" onClick={onClose} />
            <Title size="S" fontWeight="SB" font="Poppins" marginLeft={15}>
              {t('balancesTransactions.Category')}
            </Title>
          </CustomRow>

          <SCardHeader>
            <SCheckboxWrapper>
              <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
                <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                  {t('notificationsCenter.SelectAll')}
                </BodyText>
              </Checkbox>
            </SCheckboxWrapper>
          </SCardHeader>
          <SCard>
            {categories.map((option) => (
              <SCheckboxWrapper>
                <Checkbox checked={checkedCategories.includes(option)} onChange={() => handleCheckboxChange(option)}>
                  <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                    {t(`balancesTransactions.categories.${option}`)}
                  </BodyText>
                </Checkbox>
                <Icon name={snakeCaseToCamelCase(option)} fallbackName="other" color="blue" size="normal" />
              </SCheckboxWrapper>
            ))}
          </SCard>
        </CustomRow>

        <Button preset="blue-filled" size={confirmButtonOptions?.size ?? 'middleStretch'} disabled={!checkedCategories.length} onClick={handleApplyClick} marginTop={32}>
          {confirmButtonOptions?.title ?? t('balancesTransactions.ApplyRequest')}
        </Button>
      </CustomRow>
    </CustomSheet>
  );
};
