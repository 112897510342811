import React from 'react';
import { useTranslation } from 'react-i18next';
import exclamation from 'assets/images/exclamation.svg';
import { Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { SAccountInformationModal } from 'views/Account/AccountInformation/SelectedAccountInformationPage.styles';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IFailedDeleteAccountModal {
  isOpen: boolean;
  onClose: () => void;
}

export const FailedDeleteAccount: React.FC<IFailedDeleteAccountModal> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <CustomSheet isModal={isDesktopSize} isOpen={isOpen} onClose={onClose} modalName="internalAccount_failedDeleteAccountModal" padding="15px" closable={false} wrapperPadding={isDesktopSize}>
      <SAccountInformationModal>
        <div className="center-image" style={{ marginBottom: '25px' }}>
          <img src={exclamation} alt="exclamation" />
        </div>
        <Title color="charcoal" justifyContent="center" fontWeight="M" font="Poppins" size="M" marginBottom={15}>
          {t('accountInformation.CloseErrorMessage')}
        </Title>

        <div className="checkAccountModalFooter">
          <Button preset="red-filled" size={isDesktopSize ? 'middle' : 'xl'} onClick={onClose} marginTop={20}>
            {t('accountInformation.Cancel')}
          </Button>
        </div>
      </SAccountInformationModal>
    </CustomSheet>
  );
};
