import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEditUserProfileDataMutation } from 'store/user/users.api';
import { BodyText, Title } from 'components/general/Typography';
import { Form } from 'antd';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { fullNameCharacterRule } from 'utils/helpers/validationRules';
import { Button } from 'components/theme/Button/Button';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { Loader } from 'components/general/Loader/Loader';
import { mobileApiCall } from 'services/mobileService';
import { lsSetItem } from 'utils/helpers/storage';
import { handleError } from 'utils/helpers/errorHelper';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { SModalCommonLayout } from './common/styles';

interface IFormValues {
  preferredName: string;
}

interface IProps {
  closeSheet?: () => void;
  mode?: string;
}

export const EditName = ({ closeSheet, mode }: IProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const { hasErrors, checkErrors } = useFormHelpers(form);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const handleFormChange = () => setIsDisabled(hasErrors || form.getFieldValue('preferredName')?.length === 0 || form.getFieldValue('preferredName')?.length === undefined);
  const userProfileData = useSelector(selectCurrentUser);
  const [editUserProfileData, editUserProfileDataResult] = useEditUserProfileDataMutation();

  const onFinish = (formValues: IFormValues) => {
    const data = {
      profileData: {
        preferredName: formValues.preferredName,
      },
    };

    editUserProfileData(data)
      .unwrap()
      .then(() => {
        mobileApiCall('preferredNameChange', data.profileData.preferredName);
      })
      .then(() => {
        lsSetItem('savedName', formValues.preferredName);
      })
      .then(() => {
        closeSheet?.();
      })
      .catch((error) => handleError(error));
  };

  return (
    <SModalCommonLayout className="body-edit-name">
      <Form
        onFinish={onFinish}
        onFieldsChange={checkErrors}
        form={form}
        requiredMark={false}
        layout="vertical"
        autoComplete="off"
        initialValues={{ preferredName: userProfileData?.preferredName ?? '' }}
      >
        <CustomCard padding="0" marginTop={0}>
          <Title fontWeight="SB" size="S" color="charcoal" marginBottom={4} font="Poppins" className="title">
            {t(`profile.Preferred Name`)}
          </Title>

          <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" lineHeight={1.4} marginBottom={32} className="description">
            {t(`profile.Please enter your preferred name as you would like it to appear in the app`)}
          </BodyText>

          <Form.Item
            label={<CustomRequiredLabel label="profile.Preferred Name" fontFamily="DM Sans" />}
            name="preferredName"
            className="preferred-name"
            validateTrigger={['onBlur', 'onChange']}
            rules={[fullNameCharacterRule(t('profile.Please use only letters and numbers in preferred name field'))]}
          >
            <BaseInput onKeyUp={handleFormChange} placeholder={t('profile.Enter Your Preferred Name Here')} data-testid="preferredFirstName" maxLength={24} />
          </Form.Item>
        </CustomCard>

        <Form.Item className="form-item-button-submit">
          <Button preset="blue-filled" size="middleStretch" type="submit" disabled={isDisabled}>
            {mode === 'edit' && t(`profile.Save Changes`)}
            {mode === 'add' && t('profile.Save')}
          </Button>
        </Form.Item>

        {editUserProfileDataResult.isLoading && <Loader />}
      </Form>
    </SModalCommonLayout>
  );
};
