import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 50%;
  margin-bottom: 56px;
`;

export const SBreadcrumbsWrapper = styled.div`
  .breadcrumbs {
    padding-left: 9%;
    margin-bottom: 0;
  }
`;
