import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { getPhoneValidator, getRequiredRule } from 'utils/helpers/validationRules';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { useToggle } from 'utils/hooks/useToggle';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { getFormattedMobileNumber, getLastNNumbers, getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SLabelText } from 'views/Profile/ProfilePage/DataItem/DataItem.styles';
import { PhoneCodeVerificationSheet } from 'views/Profile/ProfilePage/ProfileEditSheet/EditPhoneNumber/PhoneCodeVerificationSheet';

const PHONE_MASK = '(000) 000 0000';
const PHONE_MASK_OPTIONS = {
  lazy: false,
  placeholderChar: '*',
};
interface IFormValues {
  phoneNumber: string;
}
interface IEditPhoneNumberProps {
  onClose: () => void;
}

export const EditPhoneNumber = ({ onClose }: IEditPhoneNumberProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const { hasErrors, checkErrors } = useFormHelpers(form);
  const verifyPhoneCodeModal = useToggle(false, { newPhone: '' });
  const userProfileData = useSelector(selectCurrentUser);

  const onFinish = (formValues: IFormValues) => {
    verifyPhoneCodeModal.setData({ newPhone: getUnmaskedMobileNumber(`1${formValues.phoneNumber}`) });
    verifyPhoneCodeModal.show();
  };

  const handleStopEdit = () => {
    verifyPhoneCodeModal.hide();
    onClose();
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      requiredMark={false}
      layout="vertical"
      autoComplete="off"
      onFieldsChange={checkErrors}
      initialValues={{
        phoneNumber: getFormattedMobileNumber(getLastNNumbers(userProfileData?.primaryPhone || '', 10)),
      }}
    >
      <SLabelText textType="bodyText" font="DM Sans" fontWeight="R" color="charcoal70" size="M">
        {t(`profile.Phone Number`)}
      </SLabelText>

      <Form.Item
        name="phoneNumber"
        validateTrigger={['onBlur', 'onChange']}
        rules={[getRequiredRule('profile.Please enter your Phone Number'), getPhoneValidator('inviteEarn.PleaseInputCompleteMobileNumber')]}
        className="inline-field"
      >
        <MaskedInput
          mask={PHONE_MASK}
          maskOptions={PHONE_MASK_OPTIONS}
          placeholder="Enter your mobile number"
          inputMode="tel"
          allowClear={false}
          addonAfter={
            <CustomRow justifyContent="flex-end" gap={18}>
              <Icon name="closeThin" color="charcoal40" size="smaller" className="suffix" cursorPointer onClick={onClose} />
              {!hasErrors && <Icon name="tick" color="blue" size="smaller" className="suffix" cursorPointer onClick={form.submit} />}
            </CustomRow>
          }
        />
      </Form.Item>
      <PhoneCodeVerificationSheet isOpen={verifyPhoneCodeModal.isActive} className="phone-code-verification-sheet" {...verifyPhoneCodeModal.data} onClose={handleStopEdit} />
    </Form>
  );
};
