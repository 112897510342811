import React from 'react';
import { PercapitaLong, PercapitaLongWhite } from 'assets/logos';

interface IAuthTopLogo {
  isWhite?: boolean;
  onClick?: () => void;
}

export const AuthTopLogo = ({ isWhite, onClick = () => {} }: IAuthTopLogo) => {
  const LogoSvgComponent = isWhite ? PercapitaLongWhite : PercapitaLong;

  return <LogoSvgComponent onClick={onClick} className="percapita-logo" />;
};
