import styled from 'styled-components';
import { MEDIA_SIZE, getColor } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  position: relative;
  .description {
    position: absolute;
    right: 28px;
    bottom: 16px;

    @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
      bottom: 10px;
      color: ${getColor('grey')};
    }
  }
`;

export const SAmountInput = styled.div`
  input[inputmode='decimal'] {
    appearance: none;
    outline: none;
    width: 100%;
    padding: 34px 24px;
    border: 2px solid transparent;
    border-radius: 20px;
    font-family: 'Poppins';
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    text-align: right;
    color: ${getColor('charcoal')};
    transition: border-color 100ms;

    @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
      padding: 16px 56px 24px 72px;

      &::placeholder {
        color: ${getColor('grey')};
      }
    }
  }

  input[inputmode='decimal']:is(:focus, :hover) {
    border-color: ${getColor('blue')};
  }

  &.error input[inputmode='decimal'] {
    border-color: ${getColor('red')};
  }

  input[inputmode='decimal']:disabled {
    background: ${getColor('white')};
    border-color: transparent;
    color: ${getColor('charcoal30')};
  }

  .percapita-amount-input[inputmode='decimal'] {
    padding: 0;
    border: 0;
    font-size: 32px;
  }
`;
