import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SLink = styled(Link)`
  font-weight: 500;
  font-family: Poppins;
  font-size: 14px;
  & span {
    color: ${getColor('blue')} !important;
  }

  &:hover {
    color: ${getColor('blue')};
  }
}
`;

export const SActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 121px;
  margin-bottom: 12px;
  gap: 18px;

  ${mediaFrom(
    'tablet',
    css`
      margin-top: 20px;
    `
  )}

  span.ant-typography {
    color: ${getColor('charcoal60')};
  }

  .sign-up {
    display: flex;
  }
`;

export const SForgotLink = styled.span`
  color: ${getColor('blue')};
  font-weight: 500;
  height: 24px;
  line-height: 14px;
  cursor: pointer;

  &:hover, &:focus {
    color: ${getColor('blue')};
  }
}
`;

export const SFaceIdInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
