import { notification } from 'antd';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { TIconName } from 'components/general/Icon/Icon.types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { TAdditionalInformationModalType, setShowAdditionalInformationModal } from 'store/ui.slice';
import { useAppDispatch } from 'utils/hooks/store';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IDataListItem {
  title: string;
  value: string;
  iconName?: TIconName;
  tooltip?: TAdditionalInformationModalType;
  isLast?: boolean;
  showCopyButton?: boolean;
  isInvitePending?: boolean;
  isRow?: boolean;
}

export const DataListItem: React.FC<IDataListItem> = ({ title, iconName, value, showCopyButton = true, tooltip, isLast = false, isInvitePending = false, isRow = false }) => {
  const { t } = useTranslation('deposit');
  const dispatch = useAppDispatch();
  const { isMobileSmallSize } = useDeviceDimension();
  const [api, contextHolder] = notification.useNotification();

  const handleTooltipClick = () => {
    if (tooltip) {
      dispatch(
        setShowAdditionalInformationModal({
          displayAdditionalInformationModal: true,
          additionalInformationModalType: tooltip,
        })
      );
    }
  };

  const onCopyToClipboard = useCallback(
    (accountNumber: string) => {
      if (!showCopyButton) return;

      if (accountNumber) {
        navigator.clipboard.writeText(value);
      }

      api.info({
        message: (
          <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
            {title} {accountNumber ? t('setUpDeposit.Copied') : t('setUpDeposit.NotExist')}
          </BodyText>
        ),
        placement: 'top',
        duration: 2,
        style: { borderRadius: 20 },
        maxCount: 2,
        icon: <Icon name="circleTick" color={accountNumber ? 'green' : 'red'} />,
      });
    },
    [value, showCopyButton, title]
  );

  return (
    <>
      {contextHolder}
      <CustomRow marginBottom={isLast ? 0 : 24}>
        <div className={isRow ? 'data-list-row' : 'data-list-column'}>
          <CustomRow justifyContent="flex-start" className="subtitle">
            <BodyText textType="bodyText" color="charcoal70" size="M" fontWeight="R">
              {title}
            </BodyText>
            {tooltip && <Icon name="info" color="blue" pictBgColor="blue" cursorPointer marginLeft={14} size="smaller" onClick={handleTooltipClick} />}
          </CustomRow>
          {value && (
            <BodyText textType="bodyText" color="charcoal" size="M" fontWeight="M" marginTop={4} className="value">
              {iconName && <Icon name={iconName} color="charcoal70" />}
              {value}
            </BodyText>
          )}
          {isRow && showCopyButton && (
            <Icon name="duplicate" color="blue" size={isMobileSmallSize ? 'smallest' : 'small'} cursorPointer onClick={() => onCopyToClipboard(value)} className="copy-icon" />
          )}
        </div>
        {!isRow && showCopyButton && (
          <Icon name="duplicate" color="blue" size={isMobileSmallSize ? 'smallest' : 'small'} cursorPointer onClick={() => onCopyToClipboard(value)} className="copy-icon" />
        )}
        {isInvitePending && (
          <BodyText fontWeight="R" size="T" marginTop={5} color="charcoal70">
            {t('setUpDeposit.Pending')}
          </BodyText>
        )}
      </CustomRow>
    </>
  );
};
