import React from 'react';
import { Title } from 'components/general/Typography';
import { Trans } from 'react-i18next';
import { ListItem } from 'views/PercapitaPay/Enroll/ListItem/ListItem';

interface IEnrollHeaderProps {
  title?: string;
}

export const EnrollHeader: React.FC<IEnrollHeaderProps> = ({ title }) => (
  <>
    {title && (
      <Title font="Poppins" fontWeight="SB" size="S" marginBottom={37} className="enroll-title">
        {title}
      </Title>
    )}
    <Title font="Poppins" fontWeight="SB" size="M" marginBottom={43} className="enroll-subtitle">
      <Trans i18nKey="percapitaPayEnroll.Why enroll in Percapita Pay?" ns="percapitaPay" />
    </Title>
    <div className="enroll-list-name">
      <ListItem marginBottom={17}>
        <Trans i18nKey="percapitaPayEnroll.SeeHoursWorked" ns="percapitaPay" />
      </ListItem>
      <ListItem marginBottom={17}>
        <Trans i18nKey="percapitaPayEnroll.GetEarlyAccess" ns="percapitaPay" />
      </ListItem>
      <ListItem marginBottom={17}>
        <Trans i18nKey="percapitaPayEnroll.CoverUnexpectedCost" ns="percapitaPay" />
      </ListItem>
    </div>
  </>
);
