import React from 'react';
import { images } from 'assets';
import { ISuggestedAction } from 'store/chat/chat.types';
import { useTranslation } from 'react-i18next';

type TActionTransferStatus = {
  action: ISuggestedAction;
};

export const ActionTransferStatus = ({ action }: TActionTransferStatus) => {
  const { t } = useTranslation('transferBetweenAccounts');

  return (
    <div>
      {action.value === 'error' ? (
        <div className="transfer-status-error">
          <img src={images.transferFail} alt="fail" />
          <div className="transfer-status-title">{t('The transfer is incomplete.')}</div>
        </div>
      ) : (
        <div className="transfer-status-success">
          <img src={images.transferSuccess} alt="success" />
          <div className="transfer-status-title">{t('The transfer is complete.')}</div>
        </div>
      )}
    </div>
  );
};
