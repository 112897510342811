import styled, { css } from 'styled-components';
import { flex, mediaUpTo, getColor } from 'utils/helpers/styleHelpers';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Icon } from 'components/general/Icon/Icon';
import { SAccountBadge } from 'components/general/Accounts/AccountSelect/AccountSelect.styles';

export const SIcon = styled(Icon)`
  margin-left: 8px;
`;

export const SAccountSelectSheet = styled(CustomSheet)`
  display: flex;
  flex-direction: column;

  .container {
    ${flex('column', 'flex-start', 'stretch')}
  }

  .account-select-sheet__header {
    ${flex('row', 'flex-start')}
    margin-bottom: 15px;

    ${mediaUpTo(
      'tablet',
      css`
        .custom-title-text {
          font-size: 22px;
        }
      `
    )}
  }

  .accounts {
    ${flex('column', 'flex-start', 'stretch')}
    gap: 12px;
    margin-bottom: 32px;

    .selected {
      border-color: ${getColor('blue')};
      background: ${getColor('white')};
    }

    .disabled {
      border-color: ${getColor('charcoal20')};

      ${SAccountBadge} {
        background: ${getColor('charcoal40')};
        color: ${getColor('white')};
      }
    }
  }
`;
