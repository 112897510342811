import { Button } from 'components/theme/Button/Button';
import styled, { css } from 'styled-components';
import { flex, getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

interface ISButton {
  isActive?: boolean;
}

export const SButton = styled(Button)<ISButton>`
  display: flex;
  width: 100%;
  background-color: transparent;
  justify-content: center;
  font-family: Poppins;
  font-weight: 600 !important;
  font-size: 14px;
  padding: 8px;
  border: 2px solid ${getColor('creamS10')};
  color: ${getColor('charcoal70')};
  align-items: center;
`;

export const SAccountSelectDropdown = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;
  background: ${getColor('white')};
  padding: 24px 20px;
  z-index: 500;
  margin-top: 8px;
  border: 2px solid ${getColor('charcoal10')};
  border-radius: 24px;
  overflow: auto;
  box-shadow: 0 16px 16px 0 #00000029};

  .container {
    ${flex('column', 'flex-start', 'stretch')}
  }

  .header {
    ${flex('row', 'flex-start')}

    ${mediaUpTo(
      'tablet',
      css`
        .custom-title-text {
          font-size: 22px;
        }
      `
    )}
  }

  .tabs {
    display: flex;
    overflow-y: auto;
    gap: 8px;
    margin: 0 -20px;
    padding: 0 20px;

    ::-webkit-scrollbar {
      display: none !important;
    }

    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    ${flex('row', 'flex-start')};
    margin-bottom: 24px;
  }

  .content {
    ${flex('column', 'flex-start', 'stretch')};
    gap: 15px;

    & .info {
      & .svg-icon {
        position: absolute;
      }

      & .text {
        margin-left: 30px;
      }
    }

    .internal-account:not(.disabled):hover,
    .external-account:not(.disabled):hover {
      border-color: ${getColor('blue')};
    }

    .internal-account-group:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .add-new-account-btn {
    flex: 0 0 auto;
    margin-top: 25px;
    align-self: center;
  }
`;

export const SEmptyWarning = styled.div`
  ${flex('column', 'center')}
  padding: 24px;
  background: ${getColor('charcoal5')};
  border-radius: 18px;
  margin-bottom: 30px;

  .icon {
    margin-bottom: 16px;
  }

  ${mediaUpTo(
    'desktop',
    css`
      .custom-text-inner {
        font-size: 12px;
      }
    `
  )}
`;
