import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { triggerEvent } from 'utils/helpers/events';
import { ROUTES } from 'vars/const/ROUTES';
import { IPageState } from 'components/navigation/MobilePageHeader/MobileHeader.types';
import { selectAccountsData } from 'store/user/accounts/accounts.slice';
import { selectHelpData } from 'store/user/help.slice';
import { selectIsEmailVerifiedOrAbsent } from 'store/user/authentication.slice';
import { selectCurrentBackUrl, setCurrentBackUrl } from 'store/ui.slice';
import { useRouteConfig } from './useRouteConfig';

export const useNavBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const canGoBack = location.key !== 'default';
  const isActivatePennyJar = useMatch(ROUTES.accountPennyJar.path);
  const isSelectedAccountInformation = useMatch(ROUTES.selectedAccountInformation.path);
  const isSelectedInfoSummary = useMatch(ROUTES.myInfoSummary.path);
  const currentBackUrl = useSelector(selectCurrentBackUrl);
  const { pennyJarBackRoute } = useSelector(selectAccountsData);
  const isUserEmailVerified = useSelector(selectIsEmailVerifiedOrAbsent);
  const { previousTab } = useSelector(selectHelpData);
  const isWellnessQuestion = useMatch(ROUTES.wellnessFAQ.path) && previousTab === 'help-and-support';
  const { defaultBackTo, isIFramePage, backNav } = useRouteConfig();
  const { fromPage, isFromMenu, backPage } = (location?.state as IPageState) || '';
  const isBackNavigatable = defaultBackTo || isWellnessQuestion || backNav;

  const setBackUrl = (url: string | null) => {
    dispatch(setCurrentBackUrl(url));
  };

  const navigateBack = useCallback(() => {
    triggerEvent('backClick');

    if (currentBackUrl) {
      navigate(currentBackUrl);
      return;
    }

    if (isActivatePennyJar) {
      navigate(pennyJarBackRoute);
      return;
    }

    if (isSelectedInfoSummary && isUserEmailVerified) {
      navigate(ROUTES.myInfoAdditionalDetails.path);
      return;
    }

    if (backPage) {
      navigate(backPage);
      return;
    }

    if (isIFramePage) {
      if (fromPage) {
        navigate(fromPage);
      } else {
        navigate(ROUTES.home.path);
      }
      return;
    }

    if (isBackNavigatable && isFromMenu) {
      navigate(-1);
      return;
    }

    if (defaultBackTo) {
      navigate(defaultBackTo);
      return;
    }

    if (isBackNavigatable || canGoBack) {
      navigate(-1);
      return;
    }

    // Checking if page was opened from direct link
    if (canGoBack) {
      navigate(-1);
      return;
    }

    navigate(ROUTES.home.path);
  }, [location, isIFramePage, isBackNavigatable, canGoBack, navigate, isSelectedAccountInformation, isActivatePennyJar]);

  return { navigateBack, setBackUrl, isBackNavigatable, backPage };
};
