import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCountriesList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 15px;

  .countries-list__list {
    margin-top: 32px;
    overflow-y: auto;

    &.scrollable {
      overflow-x: auto;
      mask-repeat: no-repeat;
      mask: linear-gradient(to top, transparent 0, ${getColor('cream70')} 80px calc(100% - 80px), transparent 100%);

      &.scrolled-to-bottom {
        mask: linear-gradient(to top, ${getColor('cream70')} 0 calc(100% - 80px), transparent 100%);
      }

      &.scrolled-to-top {
        mask: linear-gradient(to top, transparent 0, ${getColor('cream70')} 80px 100%);
      }
    }
  }
`;

export const SCountryItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;

  .body-text {
    flex: 1;
  }
`;
