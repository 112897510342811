import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import styled, { css } from 'styled-components';
import { MEDIA_SIZE, getColor, ifProp, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { cash, goal, stash, circleQuestion, dollarSign } from 'assets/icons';

interface IInternalTransferPage {
  isDesktop?: boolean;
}

export const figureLineCSS = css`
  display: block;
  content: '';
  padding: 20px 0;
  border: 2px solid ${getColor('charcoal20')};
  border-left: 0;
`;

export const SFigureBlock = styled.div`
  margin-left: 6px;
  margin-top: 37px;

  &:before {
    ${figureLineCSS};
    border-bottom: 0;
    border-top-right-radius: 10px;
  }

  &:after {
    ${figureLineCSS};
    border-top: 0;
    border-bottom-right-radius: 10px;
  }
`;

export const SWebHeader = styled.div`
  border-bottom: 1px solid ${getColor('creamS5')};
  padding-bottom: 58px;
`;

export const SInternalTransferPage = styled.div<IInternalTransferPage>`
  display: flex;
  font-family: 'Poppins';
  line-height: 1.5;

  .disclosure-container {
    ${mediaFrom(
      'tablet',
      css`
        padding: 55px 20px 55px 5px;
        width: 105% !important;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        position: relative;
        right: 30%;
        width: 150% !important;
      `
    )}

    ${mediaFrom(
      'desktopLarge',
      css`
        right: 50%;
      `
    )}
  }

  &.confirm {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;

    & > div {
      width: 100%;
    }

    .done-btn {
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${ifProp(
    'isDesktop',
    css`
      padding: 24px 0 0;
      // border-top: 1px solid ${getColor('creamS5')};

      .sender,
      .recipient,
      form {
        width: 100%;
      }
      .form-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      .transfer-selector {
        display: flex;
        gap: 32px;
        flex-direction: column;
        width: 60%;
      }

      .form-fields {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        align-items: start;
      }
      .account-selectors .amount .custom-amount {
        margin-right: 28px;
      }
    `,
    css`
      flex-direction: column;
      form > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
      }
      .select-account {
        max-width: 516px;
      }
      form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }
    `
  )}

  .account-selectors {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;

    .account-select {
      .content {
        button.add-new-account-btn {
          .custom-text-inner {
            font-family: Poppins, sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }

  .warning-container {
    display: flex;
    align-items: center;
    padding-top: 4px;

    & small {
      margin-top: 0;
    }
  }

  .select-account {
    display: none;
    width: 100%;

    &__control {
      position: relative;
      cursor: pointer;
      padding: 12px;
      border: 2px solid transparent;
      border-radius: 16px;

      // TODO a11y: hovered and focused states should be visually distinguishable,
      // and chevron color change is not really sufficient for that
      &--is-focused,
      &:hover {
        box-shadow: none;
        border-color: ${getColor('blue')};
      }

      &--is-focused .select-account__indicator {
        background-color: ${getColor('blue')};
      }
    }

    &__placeholder {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin: 0;
      color: ${getColor('charcoal70')};
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      display: flex;
      align-items: center;
      margin-right: 12px;
      padding: 0;
      font-family: 'DM Sans';
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${getColor('charcoal')};
    }

    &__value-container .account-value {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &__value-container .account-value > div {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }

    &__value-container .account-value::before,
    &__placeholder::before {
      content: '';
      display: block;
      width: 48px;
      aspect-ratio: 1;
      margin-right: 12px;
      border-radius: 50%;
      background-color: ${getColor('charcoal5')};
    }

    &__value-container .account-value::after,
    &__placeholder::after {
      content: '';
      position: absolute;
      top: 12px;
      left: 12px;
      width: 24px;
      aspect-ratio: 1;
      mask: url(${circleQuestion}) no-repeat center/contain;
      background-color: ${getColor('charcoal70')};
    }

    &__value-container .account-value[data-type]::before {
      background-color: ${getColor('green10')};
    }

    &__value-container .account-value[data-type]::after {
      mask-image: url(${cash});
      background-color: ${getColor('green')};
    }

    &__value-container .account-value[data-type='Save']::after {
      mask-image: url(${goal});
    }

    &__value-container .account-value[data-type='Stuff']::after {
      mask-image: url(${stash});
    }

    &__value-container .account-value .amount {
      font-weight: 500;
    }

    &__indicator > svg {
      display: none;
    }

    &__menu {
      z-index: 2;
      margin-block: 4px 0;
      border-radius: 20px;
      border: 1px solid ${getColor('charcoal5')};
      box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
    }

    &__menu-list {
      border-radius: inherit;
      padding: 0;
    }

    &__menu-list .account-option {
      position: relative;
      display: flex;
      align-items: center;
      padding: 20px;
      background-color: ${getColor('white')};
      font-family: 'DM Sans';
      font-size: 14px;
      cursor: pointer;
    }

    &__menu-list .account-option:hover {
      background-color: ${getColor('charcoal5')};
    }

    &__menu-list .account-option:not(:last-child) {
      border-bottom: 2px solid ${getColor('charcoal5')};
    }

    &__menu-list .account-option[data-type]::before {
      content: '';
      width: 24px;
      aspect-ratio: 1;
      margin-right: 12px;
      mask: url(${cash}) no-repeat center/contain;
      background-color: ${getColor('orange')};
    }

    &__menu-list .account-option[data-type='Save']::before {
      mask-image: url(${goal});
    }

    &__menu-list .account-option[data-type='Stuff']::before {
      mask-image: url(${stash});
    }

    &__menu-list .account-option .name {
      font-weight: 700;
      color: ${getColor('charcoal')};
    }

    &__menu-list .account-option .id {
      color: ${getColor('charcoal70')};
    }

    &__menu-list .account-option .badge {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 16px;
      border-bottom-left-radius: 20px;
      background-color: ${getColor('blue10')};
      font-size: 12px;
      font-weight: 700;
      color: ${getColor('blue')};
    }
  }

  button.account {
    appearance: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 16px;
    background-color: ${getColor('white')};
    font-family: 'DM Sans';
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${getColor('charcoal')};
    text-align: left;
    cursor: pointer;
    transition: border-color 100ms;

    // TODO a11y: hovered and focused states should be visually distinguishable,
    // and chevron color change is not really sufficient for that
    &:is(:focus, :hover) {
      border-color: ${getColor('blue')};
    }

    &::before {
      display: none;
    }

    &[data-selected]::before {
      display: inline-block;
      margin-right: 10px;
      mask: url(${cash}) no-repeat center/contain;
      background-color: ${getColor('orange')};
    }

    &[data-selected]:disabled::before {
      background-color: ${getColor('charcoal30')};
    }

    &[data-selected][data-type='Save']::before {
      mask-image: url(${goal});
    }

    &[data-selected][data-type='Stuff']::before {
      mask-image: url(${stash});
    }

    &:disabled::after {
      display: none;
    }

    &:disabled {
      color: #c2c1c1;
      cursor: not-allowed;
      border-color: transparent;
    }

    & > div.account-div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      height: 40px;

      .account-chevron-icon {
        margin-left: 16px;
      }
    }

    .action {
      color: ${getColor('charcoal40')};
    }

    &[data-selected] .action {
      display: none;
    }
  }

  .ant-form-item-explain-error {
    font-family: DM Sans;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
  }

  .transfer-header {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: ${getColor('charcoal')};

    ${mediaUpTo(
      'mobile',
      css`
        font-size: 20px;
      `
    )}
  }

  form > header {
    display: none;
    margin-bottom: 44px;
    font-size: 24px;
    font-weight: 600;
  }

  label > span,
  .account-label {
    font-size: 16px;
    font-weight: 600;
    color: ${getColor('charcoal')};
  }

  .account-label {
    margin-bottom: 8px;
    display: inline-flex;
    align-items: center;

    ${mediaUpTo(
      'mobile',
      css`
        font-size: 12px;
      `
    )}
  }

  button.note,
  button.manage-external,
  button.when-available {
    display: flex;
    appearance: none;
    cursor: pointer;
    float: right;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 700;
    color: ${getColor('charcoal')};

    .svg-icon {
      background-color: ${getColor('blue')} !important;
    }

    ${mediaUpTo(
      'mobile',
      css`
        .custom-text-inner {
          font-size: 12px;
        }
      `
    )}
  }

  button.when-available {
    float: left;
  }

  button.manage-external {
    align-self: flex-end;
  }

  button.note > span {
    margin-right: 8px;
  }

  .amount-form-item {
    width: 100%;
    margin-top: 18px;
    position: relative;

    .icon {
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      right: 20px;
    }

    ${mediaFrom(
      1200,
      css`
        max-width: 344px;
        margin-left: auto;
      `
    )}
  }

  small {
    display: block;
    margin-top: 12px;
    font-family: 'DM Sans';
    font-size: 12px;
    font-weight: 400;
    color: ${getColor('charcoal70')};
  }

  form footer {
    margin-block: 48px 24px;
  }

  form footer button {
    width: 100%;
    padding: 14px 32px;
    font-size: 18px;
    ${mediaUpTo(
      'mobile',
      css`
        font-size: 16px;
      `
    )}
  }

  form footer .buttons {
    margin-bottom: 24px;
  }

  form footer .buttons + * {
    justify-content: center;
  }

  @media screen and (min-width: 1920px) {
    form {
      max-width: 1250px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: ${MEDIA_SIZE.tablet + 1}px) {
    form > header {
      display: block;
      margin-left: 90px;
    }

    form > div {
      row-gap: 40px;
    }

    .ant-form-item {
      margin-top: 0;
    }

    .select-account {
      display: block;
    }

    label > span,
    .account-label {
      width: 66px;
      margin-right: 24px;
      margin-bottom: 0;
      text-align: right;
      font-family: 'DM Sans';
      font-size: 14px;
      font-weight: 700;
    }

    label > span + div {
      max-width: 516px;
    }

    form small {
      margin-top: 0;
      margin-left: 90px;
    }

    input[inputmode='decimal'] {
      padding: 16px 56px 20px 72px;
    }

    .amount {
      position: relative;
    }

    .amount-input {
      position: relative;
    }

    .amount-input.touched::before {
      background-color: ${getColor('green')};
    }

    .amount-input.touched::after {
      background-color: ${getColor('white')};
    }

    .amount-input.error.touched::before {
      background-color: ${getColor('red5')};
    }

    .amount-input.error.touched::after {
      background-color: ${getColor('red')};
    }

    button.note {
      margin-bottom: 12px;
    }

    button.note > span {
      display: none;
    }

    form footer {
      margin-top: 200px;
    }

    form footer .buttons {
      margin-bottom: 32px;
      text-align: right;

      button {
        width: 100%;
      }
    }

    form footer button {
      width: max-content;
      padding: 14px 24px;

      @media screen and (min-width: 1200px) {
        display: none;
      }
    }

    form footer button:first-of-type {
      margin-right: 8px;
    }

    button.account {
      padding: 10px;
    }
  }
  
  // invalid amount
  & .amount .ant-form-item-has-error .editable-custom-amount {
    border: solid 2px ${getColor('red')};
  }
  // ----------------------
  
  & .editable-custom-amount {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 16px;
    background: ${getColor('white')};
    width: 100%;
    padding: 14px 30px;
    & .currency {
      display: none;
    }
    padding-top: 20px;
    padding-bottom: 32px;
    ${mediaUpTo(
      'tablet',
      css`
        padding: 32px 30px 45px;
        & .currency {
          display: inline-block;
        }
      `
    )}
    ${mediaFrom(
      1200,
      css`
        max-width: 344px;
        margin-left: auto;
      `
    )}
  }

  ${mediaFrom(
    577,
    css`
      .editable-custom-amount::before,
      .amount-input::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        display: block;
        width: 48px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: ${getColor('blue')};
      }

      .editable-custom-amount::after,
      .amount-input::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
        display: block;
        width: 24px;
        aspect-ratio: 1;
        mask: url(${dollarSign}) no-repeat center/contain;
        background-color: ${getColor('white')};
      }
    `
  )}

  & .amount-input-component,
  & .editable-custom-amount {
    & + .body-text {
      position: absolute;
      bottom: 15%;
      right: 20px;
    }

    ${mediaUpTo(
      577,
      css`
        & + .body-text {
          right: 30px;
        }
      `
    )}
  }
  & .amount-input-component input[inputmode='decimal'] {
    padding: 32px 30px 45px;
  }

  // invalid amount
  .details-block.is-amount-not-valid .amount-transferred .custom-amount {
      color: ${getColor('red')};
    }
  }
  // --------------

  @media screen and (max-width: 1200px) {
    .transfer-selector {
      width: 100%;
    }
    .details-block {
      display: none;
    }
  }

  .desktop-note {
    .ant-form-item {
      width: 100%;
      margin-top: 18px;

      .base-input-container {
        height: auto !important;
        padding-bottom: 59px;
      }
    }
  }

  .web-note {
    font-size: 12px;
    padding: 6px 16px;
    max-width: 154px;
    margin-left: 8px;

    .btn__inner {
      line-height: 1.6;
    }

    &:hover {
      .svg-icon {
        background-color: ${getColor('white')} !important;
      }
    }
  }

  .remove-note {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const SAccountIconContainer = styled.div`
  width: 40px;
  height: 40px;
  background: ${getColor('orange5')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 24px;
`;

export const SAccountBadge = styled.div`
  background: ${getColor('blue')};
  color: ${getColor('white')};
  position: absolute;
  top: 0;
  left: -2px;
  border-radius: 16px 0;
  padding: 4px 16px;
  font-size: 13px;
`;

export const SNoteContainer = styled(CustomCard)`
  min-height: 74px;
  border: 2px solid ${getColor('charcoal5')};
  border-radius: 20px;
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SPopupContainer = styled.div`
  @media screen and (max-width: ${MEDIA_SIZE.mobile}px) {
    .success-sheet {
      img {
        width: 124px;
      }
      .success-title .custom-title-text {
        font-size: 20px;
      }
      .success-subtitle .custom-text-inner {
        font-size: 14px;
      }

      .card {
        padding: 16px;
      }

      .custom-row {
        .custom-text-inner {
          font-size: 12px;
        }

        .svg-icon {
          width: 20px;
        }
      }

      .custom-amount span {
        font-size: 12px;
      }

      .btn {
        font-size: 14px;
      }
    }
  }
`;

export const SLink = styled.span`
  color: ${getColor('blue')} !important;
  text-decoration: underline;
  cursor: pointer;
`;
