import styled, { css } from 'styled-components';
import { getColor, getProp } from 'utils/helpers/styleHelpers';
import { ISInputContainer, ISInput } from './BaseInput.types';

const CommonFontStyles = css`
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'DM Sans', sans-serif !important;

  @media screen and (max-width: 390px) {
    font-size: 14px !important;
  }

  // disable iOS Mobile auto-zoom.
  @media only screen and (hover: none) and (pointer: coarse) {
    &:focus,
    input {
      font-size: 16px !important;
    }
  }
`;

export const SLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 8px;
  margin-top: 10px;
`;

export const SInputContainer = styled.div<ISInputContainer>`
  justify-content: ${getProp('justifyContent')};
  display: flex;
  align-items: center;
  margin-bottom: ${getProp('marginBottom')}px;
  margin-right: ${getProp('marginRight')}px;
  margin-left: ${getProp('marginLeft')}px;
  height: ${getProp('height')};
  padding: ${getProp('padding')};
  border-radius: 16px;
  width: 100%;
  background: ${getColor('white')};
  ${CommonFontStyles};
  color: ${getColor('charcoal')};
  border: 2px solid ${getColor('charcoal10')} !important;

  &:hover,
  &:focus-within {
    border-color: ${getColor('blue')} !important;
  }
  &.input-status-error {
    border-color: ${getColor('red')} !important;
  }

  &:disabled {
    color: ${getColor('charcoal40')};
    -webkit-text-fill-color: ${getColor('charcoal40')};
    background: ${getColor('charcoal5')};
    background: ${getColor('charcoal5')} !important;
    border: 2px solid ${getColor('charcoal10')} !important;
    cursor: not-allowed;

    &:focus,
    &:hover {
      border: 2px solid ${getColor('charcoal10')} !important;
    }

    input {
      background: ${getColor('charcoal5')};
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.border-grey {
    border: 2px solid ${getColor('white')} !important;
  }

  &.error {
    border: 2px solid ${getColor('red')} !important;

    &:hover,
    &:focus-within {
      border-color: ${getColor('red')} !important;
    }
  }

  .success {
    border: 2px solid ${getColor('green')} !important;

    &:hover,
    &:focus-within {
      border-color: ${getColor('green')} !important;
    }
  }

  .phone-digit {
    border: none !important;
    padding: 0 !important;

    input {
      width: 10px;
      margin: 5px;
    }

    &:hover,
    &:focus-within {
      border: none !important;
    }
  }

  .noBorder {
    border: none !important;
  }
`;

export const SInput = styled.input<ISInput>`
  border: none !important;
  width: 100%;
  height: 24px;
  padding: 0;
  outline: none;
  color: ${getColor('charcoal')};
  cursor: text;
  -webkit-text-fill-color: ${getColor('charcoal')};
  -webkit-user-select: text;
  text-overflow: ellipsis;

  &:focus,
  &:hover {
    outline: none !important;
    border: none !important;
  }

  &::placeholder {
    ${CommonFontStyles};
    color: ${getColor('charcoal40')};
    -webkit-text-fill-color: ${getColor('charcoal40')};
  }
`;

export const SSpan = styled.span`
  font-weight: 600;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
`;
