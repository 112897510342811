import styled, { css } from 'styled-components';
import { SCREEN_SIZE, getColor, mediaUpTo } from 'utils/helpers/styleHelpers';
import { Link } from 'react-router-dom';
import { Button } from 'components/theme/Button/Button';

export const PercapitayBox = styled.div`
  padding: 32px 20px 24px;
  gap: 48px;
  background: ${getColor('white')};
  border-radius: 20px;
  margin-bottom: 48px;
`;

export const StyledPercapitayBox = styled(PercapitayBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledPercapitaPayContainer = styled(PercapitayBox)`
  padding: 32px 20px 34px;

  ${mediaUpTo(
    SCREEN_SIZE.mobile,
    css`
      padding: 20px 16px;
    `
  )}
`;

export const StyledLink = styled(Link)`
  width: 100%;
  max-height: 51px;
  display: flex;
  align-items: center;
`;

export const StyledCustomButton = styled(Button)`
  width: 100%;
  max-height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
