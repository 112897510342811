import { api } from 'store/api';
import { API_ROUTES } from 'vars/const/API_ROUTES';
import { IDoMoreCardsDisclaimers, ISystemProperty } from './types';

export const systemPropertiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSystemProperties: builder.query<ISystemProperty[], string>({
      query: (propertyName) => ({
        url: API_ROUTES.user.properties.systemProperty(propertyName),
        method: 'GET',
      }),
    }),
    getDoMoreCardsDisclaimers: builder.query<IDoMoreCardsDisclaimers, void>({
      query: () => ({
        url: API_ROUTES.user.properties.systemProperty('doMoreCardsDisclaimers'),
        method: 'GET',
        responseHandler: (response) => response.json(),
      }),
    }),
  }),
});

export const { useLazyGetSystemPropertiesQuery, useGetDoMoreCardsDisclaimersQuery } = systemPropertiesApi;
