import styled, { keyframes, css } from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

// Keyframes
const jump = keyframes`
  100% {
    transform: translateY(-12px);
  }
`;

const shrink = keyframes`
  100% {
    transform: scaleX(.5);
    opacity: .01;
  }
`;

const ballAnimationDelay = (delay: number) => css`
  animation: ${jump} 0.5s ease ${delay}s infinite alternate;
`;

const shadowAnimationDelay = (delay: number) => css`
  animation: ${shrink} 0.5s ease ${delay}s infinite alternate;
`;

export const Container = styled.div`
  position: absolute;
  text-align: center;
  height: 32px;
  width: 40px;
  right: 30px;
  bottom: 68px;
  border: 2px solid ${getColor('charcoal10')};
  border-radius: 16px;
  left: 0;

  &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 16px;
    left: -6px;
    bottom: -1px;
    border-radius: 10px 0;
    border-bottom: 2px solid #eaeaea;
    border-right: 2px solid #eaeaea;
  }

  &:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    left: -5px;
    bottom: -3px;
    border-radius: 10px 0;
    border-bottom: 2px solid #eaeaea;
    border-right: 2px solid #eaeaea;
    transform: rotate(25deg);
  }
`;

export const Ball = styled.div`
  width: 3px;
  position: relative;
  display: inline-block;
  margin: 1px 1px -6px 1px;
  height: 3px;
  border-radius: 50%;
  z-index: 999;
  background-color: ${getColor('charcoal')};

  &.ball-one {
    ${ballAnimationDelay(0)}
  }
  &.ball-two {
    ${ballAnimationDelay(0.15)}
  }
  &.ball-three {
    ${ballAnimationDelay(0.25)}
  }
  &.ball-four {
    background-color: #0098fd;
    ${ballAnimationDelay(0.35)}
  }
  &.ball-five {
    background-color: #fff;
    ${ballAnimationDelay(0.45)}
  }
`;

export const Shadow = styled.div`
  position: relative;
  opacity: 0.1;
  bottom: 20px;
  width: 20px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
  display: inline-block;
  margin: 5px;

  &.shadow-one {
    ${shadowAnimationDelay(0)}
  }
  &.shadow-two {
    ${shadowAnimationDelay(0.15)}
  }
  &.shadow-three {
    ${shadowAnimationDelay(0.25)}
  }
  &.shadow-four {
    ${shadowAnimationDelay(0.35)}
  }
  &.shadow-five {
    ${shadowAnimationDelay(0.45)}
  }
`;
