import styled, { css } from 'styled-components';
import { getColor, SCREEN_SIZE, mediaFrom } from 'utils/helpers/styleHelpers';

export const SCard = styled.div`
  background: ${getColor('white')};
  padding: 20px 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  .image-container {
    display: flex;
    justify-content: center;

    img {
      width: 128px;
      height: 128px;
      object-fit: cover;
    }
  }

  .content-container {
    .custom-text-inner {
      font-size: 12px;
    }

    button {
      height: 36px;
      width: 100%;
      font-weight: 500;
      padding: 14px 24px;
    }
  }

  ${mediaFrom(
    'mobile',
    css`
      padding: 32px 20px;

      button {
        font-size: 16px;
        height: 44px;
      }
    `
  )}

  ${mediaFrom(
    SCREEN_SIZE.tablet,
    css`
      padding: 56px 40px;

      .image-container {
        margin-right: 40px;
        margin-top: 20px;

        img {
          width: 161px;
          height: 161px;
        }
      }

      .content-container {
        .custom-title-text {
          font-size: 32px;
        }
      }
    `
  )}
`;
