import { useChangeAccountFeatureMutation, useGetAccountFeaturesQuery } from 'store/user/accounts/accounts.api';
import { IFeatureItem } from 'store/user/accounts/accounts.types';

export const useAccountFeatures = (accountId = '') => {
  const featuresQuery = useGetAccountFeaturesQuery(accountId, { skip: !accountId });
  const [changeFeature, changeFeatureResult] = useChangeAccountFeatureMutation();

  const roundUpFeature = featuresQuery.data?.find((item: IFeatureItem) => item.type === 'RoundUp') ?? {};
  const overdraftFeature = featuresQuery.data?.find((item: IFeatureItem) => item.type === 'Overdraft') ?? {};
  const isLoading = featuresQuery.isLoading || featuresQuery.isFetching || changeFeatureResult.isLoading;

  const setOverdraft = async (cashAccountId: string, isEnabled: boolean) => {
    await changeFeature({
      cashAccountId,
      data: [
        {
          type: 'Overdraft',
          isEnabled,
          overdraft: {
            isEligible: true,
            reason: '',
            amount: 0,
          },
        },
      ],
    });
  };

  const setRoundUp = async (cashAccountId: string, isEnabled: boolean, destinationAccountId: string) => {
    await changeFeature({
      cashAccountId,
      data: [
        {
          type: 'RoundUp',
          isEnabled,
          roundUp: {
            accountId: destinationAccountId,
            amount: 1.0,
          },
        },
      ],
    });
  };

  return {
    isLoading,
    isError: featuresQuery.isError,
    roundUpFeature,
    overdraftFeature,
    setOverdraft,
    setRoundUp,
    changeFeatureResult,
  };
};
