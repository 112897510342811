import React from 'react';
import { RadioButton } from 'components/general/RadioButton/RadioButton';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';
import { IAnswer } from 'vars/types/survey.types';

interface IChoiceSingle {
  surveyQuestionId: string;
  surveyQuestionAnswerId: string;
  userAnswer: string;
  onChange: (answer: IAnswer) => void;
  selected: boolean;
}

export const ChoiceSingle: React.FC<IChoiceSingle> = ({ surveyQuestionId, surveyQuestionAnswerId, userAnswer, onChange, selected }) => {
  const handleRadioChange = () => {
    onChange({ surveyQuestionId, surveyQuestionAnswerId, userAnswer });
  };

  return (
    <CustomRow justifyContent="flex-start">
      <RadioButton onChange={handleRadioChange} checked={selected}>
        <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight={selected ? 'B' : 'R'} cursorPointer>
          {userAnswer}
        </BodyText>
      </RadioButton>
    </CustomRow>
  );
};
