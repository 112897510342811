import styled from 'styled-components';
import { MEDIA_SIZE } from 'utils/helpers/styleHelpers';

export const SAddressDocumentFieldset = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const SBody = styled.div`
  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .body {
      width: 45%;
    }
  }
`;
