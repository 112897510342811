import { images } from 'assets';
import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SCard = styled.div`
  position: relative;
  cursor: pointer;
  width: 388px;
  height: 246px;
  border-radius: 16px;
  background: ${getColor('blue')} url(${images.cardFrontDots}) no-repeat left calc(100% + 15px) / 190.16px 144.46px;
  margin-top: 32px;
  margin-bottom: 8px;

  .added-to-international-transfers {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 24px;
    width: 100%;
    max-width: 230px;
    border-radius: 12px 4px 12px 4px;
    background: ${getColor('green20')};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    font-family: 'DM Sans';
  }

  .edit {
    position: absolute;
    top: 16px;
    left: 277px;
    height: 32px;
    min-width: 95px;
    max-width: 120px;
    border-radius: 100px;
    background: ${getColor('white')};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
  }

  .remove {
    position: absolute;
    top: 16px;
    left: 250px;
    height: 32px;
    width: 122px;
    border-radius: 100px;
    background: ${getColor('white')};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
  }

  .emv-chip {
    position: absolute;
    top: 72.66px;
    left: 37.96px;
  }

  .card-number-holder {
    position: absolute;
    top: 131px;
    left: 36px;
    color: ${getColor('white')};
    font-size: 18px;
    font-weight: 700;
    font-family: 'DM Sans';
  }

  .visa-logo {
    position: absolute;
    top: 211px;
    left: 298px;
  }
`;

export const SExpiredCard = styled.div`
  position: relative;
  cursor: pointer;
  width: 388px;
  height: 246px;
  border-radius: 16px;
  background: #aeaaa2 url(${images.cardFrontDotsExpired}) no-repeat left calc(100% + 15px) / 190.16px 144.46px;
  margin-top: 32px;
  margin-bottom: 8px;

  .expired {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 24px;
    width: 100%;
    max-width: 104px;
    border-radius: 12px 4px 12px 4px;
    background: ${getColor('cream50')};
    color: ${getColor('charcoal')};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    font-family: 'DM Sans';
  }

  .remove {
    position: absolute;
    top: 16px;
    left: 250px;
    height: 32px;
    width: 122px;
    border-radius: 100px;
    background: ${getColor('white')};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
  }

  .emv-chip {
    position: absolute;
    top: 72.66px;
    left: 37.96px;
  }

  .card-number-holder {
    position: absolute;
    top: 131px;
    left: 36px;
    color: #e7e5e3;
    font-size: 18px;
    font-weight: 700;
    font-family: 'DM Sans';
  }

  .visa-logo {
    position: absolute;
    top: 211px;
    left: 298px;
  }
`;
