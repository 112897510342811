import { Icon } from 'components/general/Icon/Icon';
import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SFAQQuestion = styled.div`
  padding: 24px;
  background: ${getColor('white')};
  border-radius: 20px;

  .faq-question {
    &__question {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .icon {
        margin-left: 20px;
        flex: 0 0 auto;
      }
    }

    &__answer {
      padding-top: 16px;
    }
  }
`;

export const SArrowRight = styled(Icon).attrs({
  name: 'chevronRight',
  color: 'blue',
  cursorPointer: true,
})`
  width: 12px;
  height: 12px;
  flex: 0 0 auto;
`;
