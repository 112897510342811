import React from 'react';
import { Trans } from 'react-i18next';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { BodyText } from 'components/general/Typography';
import { SLink } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';
import { SPageFooter } from './PageFooter.styles';

interface IPageFooter {
  type?: 'sutton' | 'external-transfers' | 'full' | 'onboarding';
  children?: React.ReactNode;
}

export const PageFooter = ({ type = 'sutton', children }: IPageFooter) => {
  return (
    <SPageFooter className={`page-footer ${type}`}>
      {type === 'external-transfers' && !children && <SuttonDisclaimerNote isForExternalTransfer={type === 'external-transfers'} />}
      {type === 'sutton' && !children && <SuttonDisclaimerNote footerTextType="Sutton" />}
      {(type === 'full' || type === 'onboarding') && !children && (
        <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" lineHeight={1.4} paddingRight={5}>
          <Trans i18nKey={type === 'full' ? 'Full' : 'Onboarding'} ns="footer" components={{ Link: <SLink href="http://www.fdic.gov" /> }} target="_blank" />
        </BodyText>
      )}
      {children && children}
    </SPageFooter>
  );
};
