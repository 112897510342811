import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'utils/hooks/store';
import { setIsDesktopSize, setCurrentWindowSize, selectCurrentWindowSize } from 'store/ui.slice';
import { MEDIA_SIZE, SCREEN_SIZE } from 'utils/helpers/styleHelpers';

export const useDeviceDimension = () => {
  const dispatch = useAppDispatch();
  const currentWindowSize = useSelector(selectCurrentWindowSize);

  const handleWindowResize = () => {
    const windowWidth = window.innerWidth;
    dispatch(setCurrentWindowSize(windowWidth));
    dispatch(setIsDesktopSize(windowWidth >= MEDIA_SIZE.tablet));
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return {
    currentWindowSize,
    isMobileSize: currentWindowSize < SCREEN_SIZE.tablet,
    isDesktopSize: currentWindowSize >= SCREEN_SIZE.tablet,
    isFromDesktopSize: currentWindowSize > SCREEN_SIZE.tablet,
    isMobileRegularSize: currentWindowSize > SCREEN_SIZE.mobile && currentWindowSize < SCREEN_SIZE.tablet,
    isMobileSmallSize: currentWindowSize <= SCREEN_SIZE.mobile,
    isTabletSize: currentWindowSize >= SCREEN_SIZE.tablet && currentWindowSize < SCREEN_SIZE.desktop,
    fromDesktopSmall: currentWindowSize >= SCREEN_SIZE.desktop,
    fromDesktopNormal: currentWindowSize >= SCREEN_SIZE.desktopLarge,
    fromDesktopLarge: currentWindowSize >= SCREEN_SIZE.desktopLargest,
    fromTablet: currentWindowSize >= SCREEN_SIZE.tablet,
  };
};
