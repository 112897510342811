import styled, { css } from 'styled-components';
import { getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { info } from 'assets/icons';

export const SPercapitaPayMain = styled.div`
  width: 100%;
  background-color: ${getColor('white')};

  dl.balance {
    display: flex;
    justify-content: center;
    margin: 0;
    gap: 8px;

    ${mediaUpTo(
      428,
      css`
        justify-content: space-around;
      `
    )}
  }

  dl.balance > div.divider {
    width: 3px;
    height: 75px;
    background-color: ${getColor('charcoal10')};
    transform: rotate(10deg);
    margin: 0 8px;
    border-radius: 15px;

    ${mediaUpTo(
      428,
      css`
        height: 85px;
      `
    )}
  }

  dl.balance > div.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaFrom(
      'tablet',
      css`
        .earned-amount {
          color: ${getColor('charcoal40')};
        }
      `
    )}

    ${mediaUpTo(
      576,
      css`
        flex: 1;
      `
    )}

    dd div {
      ${mediaUpTo(
        428,
        css`
          .currency,
          .integer,
          .decimal {
            font-size: 24px;
          }

          .fraction {
            font-size: 20px;
          }
        `
      )}

      ${mediaBetween(
        428,
        'tablet',
        css`
          .currency,
          .integer,
          .decimal {
            font-size: 32px;
          }

          .fraction {
            font-size: 24px;
          }
        `
      )}

      ${mediaBetween(
        577,
        1199,
        css`
          .currency,
          .integer,
          .decimal {
            font-size: 32px;
          }

          .fraction {
            font-size: 20px;
          }
        `
      )}

      ${mediaFrom(
        'desktop',
        css`
          .currency,
          .integer,
          .decimal {
            font-size: 40px;
          }

          .fraction {
            font-size: 24px;
          }
        `
      )}
    }
  }

  dl.balance dt {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'DM Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: ${getColor('charcoal70')};

    ${mediaFrom(
      768,
      css`
        div:nth-child(1) {
          font-weight: 700;
        }
      `
    )}

    .available-wrapper {
      display: flex;
    }

    span {
      ${mediaFrom(
        'tablet',
        css`
          font-weight: 700;
          color: ${getColor('charcoal')};
        `
      )}
    }
  }

  dl.balance .container:first-child dt {
    button {
      top: 0;
    }

    ${mediaBetween(
      'mobile',
      428,
      css`
        button {
          left: 95%;
        }
      `
    )}

    ${mediaBetween(
      428,
      'tablet',
      css`
        button {
          left: 75%;
        }
      `
    )}

    ${mediaFrom(
      'tablet',
      css`
        align-items: flex-end;

        span {
          margin-right: 15px;
        }

        button {
          left: 90%;
        }
      `
    )}
  }

  dl.balance dt:first-child button {
    appearance: none;
    vertical-align: middle;
    width: 16px;
    aspect-ratio: 1;
    margin-left: 8px;
    padding: 0;
    border: none;
    mask: url(${info}) no-repeat 0 / contain;
    background-color: ${getColor('blue')};
    text-indent: -9999em;
    cursor: pointer;

    ${mediaFrom(
      'tablet',
      css`
        position: absolute;
        left: 85%;
      `
    )}
  }

  .actions {
    overflow: hidden;
    margin-top: 24px;

    ${mediaFrom(
      577,
      css`
        border-radius: 16px;
        background-color: ${getColor('blue5')};
        padding: 20px 24px;
        margin-top: 25px;
      `
    )}
  }

  .actions.collapsed {
    height: 0;
    margin: 0;
    padding: 0;
  }

  .actions .buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 24px;

    ${mediaUpTo(
      'tablet',
      css`
        flex-direction: column-reverse;
        gap: 8px;
      `
    )}
  }

  small {
    display: block;
    width: 100%;
    font-family: 'DM Sans';
    font-size: 12px;
    text-align: center;
    color: ${getColor('charcoal70')};
  }

  small.pay-period-days-left.several > span {
    color: ${getColor('orange')};
  }

  small.pay-period-days-left.several {
    .days-left-text {
      color: ${getColor('charcoal70')};

      ${mediaFrom(
        'tablet',
        css`
          font-weight: 500;
          font-size: 12px;
          color: ${getColor('charcoal')};
        `
      )}
    }
  }

  small.pay-period-days-left.error {
    color: ${getColor('red')};
  }

  .buttons {
    button {
      width: 100%;
      height: 32px;
      line-height: 1.5px;
    }

    ${mediaBetween(
      321,
      'tablet',
      css`
        button {
          font-size: 14px;
        }
      `
    )}

    ${mediaFrom(
      577,
      css`
        button {
          height: 48px;
          font-size: 14px;
          max-width: 190px;
          padding: 0;
        }

        & > button.new.small:first-child {
          border-color: ${getColor('blue')} !important;
        }
      `
    )}
  }
`;
