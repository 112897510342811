import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconSign } from 'components/general/Icon/IconSign';
import { SLayout } from './Cards.styles';
import { Card } from './Card/Card';

type TCardsProps = {
  onFeeScheduleClick: () => void;
};

export const Cards = ({ onFeeScheduleClick }: TCardsProps) => {
  const { t } = useTranslation('starter');

  return (
    <SLayout>
      <Card
        isCollapsible={false}
        title={t('starter.benefits.convenienceAndFlexibility.summary')}
        titleIcon={<IconSign iconName="moneyTransfer" size={28} />}
        name="moneyTransferCircle"
        list={[
          <li className="listItemLvl1">{t('starter.benefits.convenienceAndFlexibility.card')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.convenienceAndFlexibility.atms')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.convenienceAndFlexibility.pennyJar')}</li>,
        ]}
      />

      <Card
        isCollapsible={false}
        title={t('starter.benefits.securityAndControl.summary')}
        titleIcon={<IconSign iconName="security" size={28} />}
        name="securityAndControlCircle"
        list={[
          <li className="listItemLvl1">{t('starter.benefits.securityAndControl.fdic')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.securityAndControl.bills')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.securityAndControl.alerts')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.securityAndControl.support')}</li>,
        ]}
      />

      <Card
        isCollapsible={false}
        title={t('starter.benefits.noSurpriseFees.summary')}
        titleIcon={<IconSign iconName="noFee" size={28} />}
        name="noFeeIconCircle"
        list={[
          <li className="listItemLvl1">{t('starter.benefits.noSurpriseFees.balance')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.noSurpriseFees.expenditures')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.noSurpriseFees.atm')}</li>,
          <li className="listItemLvl1">
            <span>
              <span>{t('starter.benefits.noSurpriseFees.extra')}</span>
              <a onClick={onFeeScheduleClick} className="link">
                {t('starter.benefits.noSurpriseFees.feeSchedule')}
              </a>
            </span>
          </li>,
        ]}
      />

      <Card
        isCollapsible={false}
        title={t('starter.benefits.planningAndBudgetingTools.summary')}
        titleIcon={<IconSign iconName="earnings" size={28} />}
        name="earningsCircle"
        list={[
          <li className="listItemLvl1">{t('starter.benefits.planningAndBudgetingTools.money')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.planningAndBudgetingTools.accounts.summary')}</li>,
          <li className="listItemLvl2">{t('starter.benefits.planningAndBudgetingTools.accounts.needs')}</li>,
          <li className="listItemLvl2">{t('starter.benefits.planningAndBudgetingTools.accounts.goals')}</li>,
          <li className="listItemLvl1">{t('starter.benefits.planningAndBudgetingTools.more')}</li>,
        ]}
      />
    </SLayout>
  );
};
