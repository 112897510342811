import React from 'react';
import { useTranslation } from 'react-i18next';
import { TMyDetailsProps } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.types';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { IncomeSelectorSheet } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/IncomeSelectorSheet/IncomeSelectorSheet';
import { useToggle } from 'utils/hooks/useToggle';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { BaseSelect } from 'components/general/BaseSelect/BaseSelect';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { INCOME_SOURCE_DATA } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/mock/data';

export const PrimarySource: React.FC<TMyDetailsProps> = ({ value, onChange }) => {
  const { t } = useTranslation('cashAccountOpening');
  const incomeSelectorSheet = useToggle(false);
  const { isDesktopSize } = useDeviceDimension();

  const handleInputClick = () => {
    incomeSelectorSheet.show();
  };

  const handleOnSelect = (option: any) => {
    incomeSelectorSheet.hide();
    onChange(option);
  };

  return (
    <>
      <CustomRequiredLabel label={t('accountOpening.Primary Source of Income')} fontFamily="DM Sans" marginBottom={10} marginTop={32} />

      {isDesktopSize ? (
        <BaseSelect
          options={INCOME_SOURCE_DATA().map((source) => ({
            value: source.value,
            label: source.label,
          }))}
          value={INCOME_SOURCE_DATA().find((item) => item.label === value)}
          placeholder={t('accountOpening.Select Primary Source of Income')}
          onChange={onChange}
          menuPlacement="auto"
          isWebStyle
          styles={{ dropdownIndicator: () => ({ display: 'flex' }) }}
        />
      ) : (
        <BaseInput
          placeholder={t('accountOpening.Select Primary Source of Income')}
          readOnly
          onClick={handleInputClick}
          value={value}
          suffix="chevronDown"
          suffixColor="charcoal"
          suffixSize="smaller"
        />
      )}

      <IncomeSelectorSheet handleOnSelect={handleOnSelect} open={incomeSelectorSheet.isActive} onClose={incomeSelectorSheet.hide} value={value} />
    </>
  );
};
