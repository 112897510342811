import { BodyText, Title } from 'components/general/Typography';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { snakeCaseToCamelCase } from 'utils/helpers/string';
import { Icon } from 'components/general/Icon/Icon';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { SContainer } from './CategorySelect.styles';

interface ICategorySelect {
  transactionsCategories: string[];
  setCategories: (value: string[] | ((prevVar: string[]) => string[])) => void;
  selectedCategories: string[];
}

export const CategorySelect = ({ transactionsCategories, setCategories, selectedCategories }: ICategorySelect) => {
  const { t } = useTranslation(['balancesTransactions', 'notificationsCenter']);
  const [selectAll, setSelectAll] = useState(true);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (selectedCategories.length === transactionsCategories.length && !selectAll) {
      setSelectAll(true);
    } else if (selectedCategories.length !== transactionsCategories.length && selectAll) {
      setSelectAll(false);
    }
  }, [selectedCategories, transactionsCategories.length, selectAll]);

  const handleCheckboxChange = (name: string) => {
    setCategories((prev) => {
      const updatedOptions = prev.includes(name) ? prev.filter((option) => option !== name) : [...prev, name];
      setCategories(updatedOptions);

      return updatedOptions;
    });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setCategories([]);
      setCategories([]);
    } else {
      setCategories(transactionsCategories);
      setCategories(transactionsCategories);
    }
    setSelectAll(!selectAll);
  };

  const handleOpen = () => setOpened((prev) => !prev);

  return (
    <SContainer>
      <Title size="sS" marginBottom={14}>
        {t('balancesTransactions.Category')}
      </Title>

      <div className={clsx('header', { closed: !opened })}>
        <div className={clsx('title-container', { closed: !opened })}>
          <div className="title">
            {selectAll ? (
              t('balancesTransactions.AllSelected')
            ) : (
              <>
                <em>{selectedCategories.length}</em>
                {t('balancesTransactions.SelectedCategories')}
              </>
            )}
          </div>

          <Icon name={opened ? 'chevronUp' : 'chevronDown'} color="charcoal" cursorPointer onClick={handleOpen} size="smaller" />
        </div>
        {opened && (
          <div className="checkbox-wrapper">
            <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R">
                {t('notificationsCenter.SelectAll')}
              </BodyText>
            </Checkbox>
          </div>
        )}
      </div>
      {opened && (
        <div className="card">
          {transactionsCategories.map((option) => (
            <div className="checkbox-wrapper">
              <Checkbox checked={selectedCategories.includes(option)} onChange={() => handleCheckboxChange(option)}>
                <BodyText textType="bodyText" color={selectedCategories.includes(option) ? 'charcoal' : 'charcoal70'} size="N" fontWeight="R">
                  {t(`balancesTransactions.categories.${option}`)}
                </BodyText>
              </Checkbox>
              <Icon name={snakeCaseToCamelCase(option)} fallbackName="other" color={selectedCategories.includes(option) ? 'blue' : 'charcoal30'} size="normal" />
            </div>
          ))}
        </div>
      )}
    </SContainer>
  );
};
