import styled from 'styled-components';
import { getColor, ifProp } from 'utils/helpers/styleHelpers';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';

export const SCustomRow = styled(CustomRow)<{ isLast: boolean }>`
  background: ${getColor('white')} !important;
  padding: 24px;
  border-radius: ${ifProp('isLast', 20, 0)}px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color: ${getColor('charcoal10')};
  }
`;

export const SCol1Row = styled(CustomRow)`
  width: 50%;
  padding-right: 12px;
`;

export const SBodyText = styled(BodyText)`
  width: 50%;
`;
