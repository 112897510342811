import React from 'react';
import { images } from 'assets';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { SPageLayout } from './AddNeedsGoals.styles';

export const AddSubAccountSuccessPage = () => {
  const { t } = useTranslation('addAccount');
  const navigate = useNavigate();
  const { type } = useParams();
  const isNeedsType = type === 'needs';

  const handleSetAsideMoney = () => {
    navigate(ROUTES.transfers.path, { state: { preSelectedAccountType: isNeedsType ? 'Stuff' : 'Save' } });
  };

  return (
    <SPageLayout>
      <div className="add-success">
        <div className="add-success-image center-image">
          <img src={images.congratulation} alt="successAdd" />
        </div>

        <Title color="charcoal" font="Poppins" fontWeight="M" size="M" textAlign="center">
          {isNeedsType ? t('addAccount.Congrats on opening a Needs Account') : t('addAccount.Congrats on opening a Goals Account')}
        </Title>

        <BodyText textType="bodyText" fontWeight="R" size="N" textAlign="center" marginTop={16} color="charcoal70">
          {isNeedsType
            ? t('addAccount.You can now start setting aside money intended for your monthly expenses.')
            : t('addAccount.You can now start saving money for your future financial goals or setting aside money for a rainy day')}
        </BodyText>
      </div>

      <Button preset="blue-filled" onClick={handleSetAsideMoney} marginBottom={24}>
        {isNeedsType ? t('addAccount.needs.Set Aside Money') : t('addAccount.goals.Save for Future Goals')}
      </Button>

      <Link to={ROUTES.home.path} className="to-home">
        {t('addAccount.ReturnToHome')}
      </Link>
    </SPageLayout>
  );
};
