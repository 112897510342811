import styled, { css } from 'styled-components';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 30px;

  .ant-form-item {
    margin: 0;

    &-label {
      padding: 0 0 4px 0;
    }
  }

  .ant-form-item-label > label {
    display: flex;
    width: 100%;
  }
`;

export const SButtonWrapper = styled.div`
  ${mediaFrom(
    'tablet',
    css`
      align-items: center;
      display: flex;
      justify-content: center;
    `
  )}
`;

export const SFieldInputContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;
