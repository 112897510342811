import { Tooltip } from 'antd';
import styled, { css } from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';
import { Icon } from 'components/general/Icon/Icon';

export const STooltipWrapper = styled.div``;

export const STooltip = styled(Tooltip)`
  .ant-tooltip {
    max-width: 500px;
  }
`;

export const STooltipContent = styled.div``;

export const SCloseButton = styled(Icon)`
  position: absolute;
  top: 12px;
  color: ${getColor('blue')};
  right: 12px;
  width: 16px;
  height: 16px;
  padding: 4px;

  ${({ color }) => css`
    color: ${getColor(color ?? 'blue')};
  `};
`;
