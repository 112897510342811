import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';
import { Title } from 'components/general/Typography';
import { CSSProperties } from 'react';

export const STitleContainer = styled(Title)`
  margin-bottom: 35px;

  ${mediaFrom(
    'tablet',
    css`
      padding-left: 48px;
      padding-bottom: 40px;
      border-bottom: 1px solid ${getColor('creamS5')};
      margin-bottom: 48px;
      width: 100%;
    `
  )};
`;

export const SDisclosuresPageContent = styled.div`
  width: 100%;
  margin-bottom: 48px;

  ${mediaFrom(
    'tablet',
    css`
      max-width: 578px;
      margin: 0 auto;
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      .title-section .custom-text-inner {
        font-size: 24px;
      }

      .disclosure-item {
        &-text .custom-text-inner {
          font-weight: 700;
        }
      }
    `
  )}
`;

export const SDisclosureContainer = styled.div<CSSProperties>`
  width: 100%;
  margin-top: 16px;
  padding: 12px;
  border-radius: 20px;
  background: ${getColor('white')};

  .disclosure-item {
    padding: 16px 18px 16px 0;

    .icon-chevronRight {
      width: 20px;
    }
  }

  ${({ marginBottom }) => css`
    margin-bottom: ${marginBottom}px;
  `};
`;
