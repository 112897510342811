import styled from 'styled-components';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Icon } from 'components/general/Icon/Icon';
import { getColor, ifProp } from 'utils/helpers/styleHelpers';
import { Title } from 'components/general/Typography';

export const STitle = styled(Title)`
  .custom-title-text {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;

export const SImage = styled.img`
  width: 90px;
  max-height: 60px;
  position: absolute;
  right: 30px;
  bottom: 0px;
`;

export const SLayout = styled.div<{ isNavVisible?: boolean }>`
  z-index: 1000;
  padding: 0 5px;
  bottom: ${ifProp('isNavVisible', '100px', '10px')};
  width: 100%;

  position: fixed;
  right: 0;
  transform: translateX(100%);
  animation: slideIn 0.7s forwards;

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  #parent {
    padding: 10px 0px;
    height: auto !important;
  }

  #clickable > p {
    width 70% !important;
  }

  .big-circle {
    transform: translate(35%, -40%);
    width: 131px;
    height: 131px;
  }

  .small-circle {
    transform: translate(50%, 0%);
    width: 70px;
    height: 70px;
  }

  .orange {
    background-color: ${getColor('goldOrange30')};
    .big-circle {
      background-color: ${getColor('goldOrange40')};
    }
    .small-circle {
      background-color: ${getColor('goldOrange')};
    }
  }

  .purple {
    background-color: ${getColor('bluePurple20')};
    .big-circle {
      background-color: ${getColor('purple40')};
    }
    .small-circle {
      background-color: ${getColor('bluePurple')};
    }
  }

  .green {
    background-color: ${getColor('green20')};
    .big-circle {
      background-color: ${getColor('green40')};
    }
    .small-circle {
      background-color: ${getColor('green')};
    }
  }

  .yellow {
    background-color: ${getColor('gold30')};
    .big-circle {
      background-color: ${getColor('gold40')};
    }
    .small-circle {
      background-color: ${getColor('gold')};
    }
  }
`;

export const SCircle = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 50%;
  z-index: -1;
`;

export const SmallCircle = styled.div`
  background-color: ${getColor('goldOrange')};
`;

export const SCloseIcon = styled(Icon)`
  z-index: 100;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const SCustomCard = styled(CustomCard)`
  overflow: hidden;
  padding: 16px 24px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
`;
