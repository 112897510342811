import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SData = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
  width: 100%;
  position: relative;
`;

export const SLink = styled.a`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: ${getColor('charcoal')};
`;
