import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IRemoveSheet {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

export const RemoveCardSheet: React.FC<IRemoveSheet> = ({ isOpen, onClose, onRemove }) => {
  const { t } = useTranslation('moveMoney');

  const handleRemove = () => {
    onRemove();
    onClose();
  };

  return (
    <CustomSheet isModal destroyOnClose isOpen={isOpen} header={false} modalName="removeCard" height="fit-content" onClose={onClose}>
      <Title fontWeight="M" size="M" marginBottom={16} marginTop={24}>
        {t('internationalTransferPage.removeCardModal.Title')}
      </Title>

      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={32}>
        {t('internationalTransferPage.removeCardModal.Description')}
      </BodyText>

      <Button preset="blue-filled" size="large" onClick={handleRemove} marginBottom={16}>
        {t('internationalTransferPage.removeCardModal.Yes')}
      </Button>
      <Button size="large" onClick={onClose}>
        {t('internationalTransferPage.removeCardModal.No')}
      </Button>
    </CustomSheet>
  );
};
