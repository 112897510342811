import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICountry } from 'views/MoveMoney/InternationalTransferPage/types';
import { useScrollHelper } from 'utils/hooks/useScrollHelper';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { hasSubstr } from 'utils/helpers/string';
import { SCountriesList } from './CountriesList.styles';
import { CountryItem } from './CountryItem';

interface ICountriesListProps {
  countries?: ICountry[];
  onSelect?: (country: ICountry) => void;
  isOnlyMainLanguage?: boolean;
}

export const CountriesList = memo(({ countries, onSelect, isOnlyMainLanguage }: ICountriesListProps) => {
  const { t } = useTranslation('moveMoney');
  const [listContainer, setListContainer] = useState<HTMLDivElement | null>(null);
  const listRef = useCallback((node: HTMLDivElement) => {
    setListContainer(node);
  }, []);
  const { scrollData } = useScrollHelper<HTMLDivElement>(listContainer);
  const [searchStr, setSearchStr] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({} as ICountry);
  const listProcessed = useMemo(() => countries?.filter((item) => hasSubstr(item.title, searchStr)).sort((a, b) => (a.title < b.title ? -1 : 1)), [countries, searchStr]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target.value);
  };

  const handleSelect = useCallback((country: ICountry) => {
    setSelectedCountry(country);
    onSelect?.(country);
  }, []);

  return (
    <SCountriesList className="countries-list">
      <BaseInput
        value={searchStr}
        onChange={handleSearchChange}
        placeholder={t('internationalTransferPage.TypeWhatCountryYouReLookingFor')}
        suffix="search"
        suffixSize="big"
        suffixColor="charcoal"
        className="countries-list__search"
      />
      <div ref={listRef} className={`countries-list__list ${scrollData.scrollClassNames}`}>
        {listProcessed?.map((item) => (
          <CountryItem country={item} isSelected={selectedCountry.code === item.code} onSelect={handleSelect} key={item.title} isOnlyMainLanguage={isOnlyMainLanguage} />
        ))}
      </div>
    </SCountriesList>
  );
});
