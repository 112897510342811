import React, { useEffect, useState, useMemo } from 'react';
import assert from 'assert';
import { useTranslation } from 'react-i18next';
import { format, isSameDay } from 'date-fns';
import { ROUTES } from 'vars/const/ROUTES';
import { useToggle } from 'utils/hooks/useToggle';
import { useCurrencyFormat } from 'utils/hooks/useCurrencyFormat';
import { Icon } from 'components/general/Icon/Icon';
import { FilterDrawer } from 'components/general/BottomDrawers/FilterDrawer/FilterDrawer';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { accountsApi } from 'store/user/accounts/accounts.api';
import { Loader } from 'components/general/Loader/Loader';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from 'utils/helpers/date';
import { EAccountType, IAcctTrnRecItem, IGetAccountTransactionsRequest } from 'store/user/accounts/accounts.types';
import { maxBy } from 'utils/helpers/minmax';
import { BodyText, Title } from 'components/general/Typography';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { SORT_VARIABLES } from 'components/general/BottomDrawers/FilterDrawer/constants';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { SBalancesAndTransactionsPage, SMainContent, STransaction } from './BalancesTransactionsPage.styles';
import { TransactionDetailsSheet } from './TransactionDetailsSheet/TransactionDetailsSheet';
import { TFilterParamsInit } from './BalancesTransactionsPage.types';
import { TransactionFiltersSidebar } from './Slider/TransactionFiltersSidebar';
import { AccountSelector } from './AccountSelector/AccountSelector';
import { PaymentIcon } from './PaymentIcon/PaymentIcon';

const filterParamsInit: TFilterParamsInit = {
  filterBy: {
    incoming: true,
    outgoing: true,
    minAmount: '',
    maxAmount: '',
    fromDate: null,
    toDate: null,
  },
  sortBy: SORT_VARIABLES[0],
  search: '',
};

export const TRANSACTION_CATEGORIES = [
  'INCOME',
  'TRANSFER_IN',
  'TRANSFER_OUT',
  'LOAN_PAYMENTS',
  'BANK_FEES',
  'ENTERTAINMENT',
  'FOOD_AND_DRINK',
  'GENERAL_MERCHANDISE',
  'HOME_IMPROVEMENT',
  'MEDICAL',
  'PERSONAL_CARE',
  'GENERAL_SERVICES',
  'GOVERNMENT_AND_NON_PROFIT',
  'TRANSPORTATION',
  'TRAVEL',
  'RENT_AND_UTILITIES',
  'OTHER',
];

const getTransactionCaption = (transaction: IAcctTrnRecItem) => {
  let addressCaption;
  const { city, region } = transaction.acctTrnInfo?.enrichedData?.enrichments?.location ?? {};

  if (city) {
    addressCaption = `${city}${region ? ', ' : ''}${region || ''}`;
  }

  return transaction.acctTrnInfo?.userNote || addressCaption || '';
};

export const BalancesTransactionsPage = () => {
  const { t } = useTranslation('balancesTransactions');
  const { isDesktopSize, fromDesktopSmall, isMobileSmallSize } = useDeviceDimension();
  const { navigate } = useEnhancedNav();
  const { formatAutoSign } = useCurrencyFormat();
  const { internalAccountsGroups } = useAccounts();
  const [searchParams] = useSearchParams();
  const preselectedGroupId = searchParams.get('groupId');
  const accountType = searchParams.get('accountType') || EAccountType.CASH;

  const [selectedAccountTransactions, setSelectedAccountTransactions] = useState<IAcctTrnRecItem[]>([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>(TRANSACTION_CATEGORIES);
  const [filterParams, setFilterParams] = useState<TFilterParamsInit>(filterParamsInit);
  const filterSheet = useToggle<{ prevScreen?: string }>(false);
  const transactionSheet = useToggle(false);
  const pendingSumModal = useToggle(false);
  const selectedAccGroup = internalAccountsGroups.find((accGroup) => accGroup.cash?.accountId === preselectedGroupId);
  const cashAccount = selectedAccGroup?.cash;
  const selectedAccount = useMemo(() => selectedAccGroup && Object.values(selectedAccGroup).find((account) => account?.type === accountType), [accountType, selectedAccGroup]);
  const fiservAccountType = selectedAccount?.fiservAccountType ?? '';
  const availableBalance = selectedAccount?.balance ?? null;
  const accountId = selectedAccount?.fiservAccountId ?? '';

  const [getFiservAccountsAPI, getFiservAccountsAPIResult] = accountsApi.useLazyGetAccountsQuery();
  const [getAccountTransactionsAPI, getAccountTransactionsAPIResult] = accountsApi.useLazyGetAccountTransactionsQuery();
  const accountHoldsQuery = accountsApi.useGetAccountHoldsQuery(selectedAccount?.accountId ?? '', { skip: !selectedAccount?.accountId });
  const holds = accountHoldsQuery?.data;
  const pendingSum = holds && holds.reduce((acc: number, hold: any) => acc + hold.amount, 0);
  const pendingSumFormatted = formatAutoSign(pendingSum);

  const {
    filterBy: { fromDate, toDate, incoming, outgoing, minAmount, maxAmount },
    sortBy,
  } = filterParams;

  const handleTypeClick = (type: string) => {
    navigate(ROUTES.balancesTransactions.path, {
      searchParams: {
        groupId: selectedAccGroup?.cash?.parentAccountId,
        accountType: type,
      },
    });
  };

  const displayedAccountTransactions = useMemo(() => {
    let toDateNextDay: Date | null = null;
    if (toDate !== null) {
      toDateNextDay = new Date(toDate);
      toDateNextDay.setDate(toDateNextDay.getDate() + 1);
    }

    return selectedAccountTransactions
      .filter((transaction) => {
        const transactionCategory = transaction.acctTrnInfo?.primaryCategory ?? 'OTHER';

        // Both transaction types should be shown for both all checked and all unchecked checkbox states
        const shouldShowBothTransactionTypes = (!incoming && !outgoing) || (incoming && outgoing);

        if (!shouldShowBothTransactionTypes) {
          if (!incoming && transaction.acctTrnInfo.drCrType === 'Credit') {
            return false;
          }

          if (!outgoing && transaction.acctTrnInfo.drCrType === 'Debit') {
            return false;
          }
        }

        if (minAmount && transaction.acctTrnInfo.trnAmt.amt < +minAmount) {
          return false;
        }

        if (maxAmount && transaction.acctTrnInfo.trnAmt.amt > +maxAmount) {
          return false;
        }

        if (categoriesFilter.length > 0 && categoriesFilter.length < TRANSACTION_CATEGORIES.length && !categoriesFilter.includes(transactionCategory)) {
          return false;
        }

        if (search) {
          const descriptionValue = transaction.acctTrnInfo?.desc[0]?.toLowerCase().trim();
          const searchValue = search.toLowerCase().trim();
          const regex = new RegExp(searchValue);
          const result = descriptionValue?.match(regex);

          return result !== null;
        }

        return true;
      })
      .sort((a, b) => {
        switch (sortBy.id) {
          case '1':
            return new Date(a.acctTrnInfo.trnDt).getTime() - new Date(b.acctTrnInfo.trnDt).getTime();
          case '2':
            return b.acctTrnInfo.trnAmt.amt - a.acctTrnInfo.trnAmt.amt;
          case '3':
            return a.acctTrnInfo.trnAmt.amt - b.acctTrnInfo.trnAmt.amt;
          case '0':
          default:
            return new Date(b.acctTrnInfo.trnDt).getTime() - new Date(a.acctTrnInfo.trnDt).getTime();
        }
      });
  }, [filterParams, selectedAccountTransactions, categoriesFilter]);

  const [selectedTransactionId, setSelectedTransactionId] = useState('');
  const selectedTransaction = useMemo(
    () => displayedAccountTransactions.find((transaction) => transaction.acctTrnKeys.acctTrnIdent === selectedTransactionId),
    [displayedAccountTransactions, selectedTransactionId]
  );

  const groupedByDateAccountTransactions = useMemo(() => {
    if (displayedAccountTransactions.length === 0) {
      return [];
    }
    const groups: IAcctTrnRecItem[][] = [[]];
    let currentGroupDate = new Date(displayedAccountTransactions[0].acctTrnInfo.trnDt);
    for (const transaction of displayedAccountTransactions) {
      const transactionDate = new Date(transaction.acctTrnInfo.trnDt);
      if (isSameDay(currentGroupDate, transactionDate)) {
        const currentGroup = groups.at(-1);
        assert(currentGroup !== undefined);
        currentGroup.push(transaction);
      } else {
        currentGroupDate = transactionDate;
        groups.push([transaction]);
      }
    }
    return groups;
  }, [displayedAccountTransactions, getAccountTransactionsAPIResult]);

  const maxTransactionAmount = useMemo(() => {
    return maxBy(selectedAccountTransactions, (transaction) => transaction.acctTrnInfo.trnAmt.amt);
  }, [selectedAccountTransactions]);

  const openFilterSheet = (screen: string | undefined) => {
    filterSheet.show();
    filterSheet.setData({ prevScreen: screen ?? 'other' });
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearchClose = () => {
    setIsSearchVisible(false);
    setSearch('');
  };

  const handleOpenDetails = (transaction: IAcctTrnRecItem) => {
    setSelectedTransactionId(transaction.acctTrnKeys.acctTrnIdent);
    transactionSheet.show();
  };

  const onFilter = (formValues: TFilterParamsInit) => {
    setFilterParams(formValues);
  };

  const handleShowDetails = () => {
    navigate(ROUTES.selectedAccountInformation.path, {
      params: {
        sourceType: 'percapita',
        accountId: selectedAccount?.accountId,
      },
      backUrl: 'current',
    });
  };

  const customDateTranslation = (value: string) => {
    const date = format(new Date(value), 'EEE MMM dd', {});
    const translated = date.split(' ')?.map((item, index) => ([0, 1]?.includes(index) ? t(`percapitaPayHome.${item?.trim()}`) : item));

    return `${translated[0]}, ${translated[1]} ${translated[2]}`;
  };

  useEffect(() => {
    getFiservAccountsAPI();
  }, [getFiservAccountsAPI]);

  useEffect(
    () =>
      setFilterParams({
        filterBy: { ...filterParams.filterBy },
        sortBy: { ...filterParams.sortBy },
        search,
      }),
    [search]
  );

  const fetchViewedAccountTransactions = async () => {
    const dateFromDirty = filterParams.filterBy.fromDate;
    const dateToDirty = filterParams.filterBy.toDate;
    const dateFrom = dateFromDirty ? formatDate(dateFromDirty) : '';
    const dateTo = dateToDirty ? formatDate(dateToDirty) : '';

    if (selectedAccount && fiservAccountType) {
      const transactionsRequestData: IGetAccountTransactionsRequest = {
        accountId,
        accountType: fiservAccountType,
        dateFrom,
        dateTo,
      };

      const { acctTrnRec: accountTransactions } = await getAccountTransactionsAPI(transactionsRequestData).unwrap();

      return accountTransactions;
    }

    return [];
  };

  useEffect(() => {
    const getTransactionsAndUpdateState = async () => {
      const transactions = await fetchViewedAccountTransactions();
      if (transactions) {
        setSelectedAccountTransactions(transactions);
      }
    };

    getTransactionsAndUpdateState();
  }, [accountType, getAccountTransactionsAPI, toDate, fromDate, accountId]);

  useEffect(() => {
    if (getAccountTransactionsAPIResult.isSuccess && getAccountTransactionsAPIResult.data) {
      const updatedTransactions = getAccountTransactionsAPIResult.data.acctTrnRec;
      setSelectedAccountTransactions(updatedTransactions);
    }
  }, [getAccountTransactionsAPIResult.isSuccess, getAccountTransactionsAPIResult.data]);

  useEffect(() => {
    if (!preselectedGroupId) {
      const defaultAccGroup = internalAccountsGroups.find((accGroup) => accGroup.cash?.isDefault || accGroup.cash?.isPrimary);
      navigate(ROUTES.balancesTransactions.path, {
        searchParams: {
          groupId: defaultAccGroup?.cash?.accountId,
          accountType: EAccountType.CASH,
        },
        replace: true,
      });
    }
  }, [preselectedGroupId]);

  return (
    <SBalancesAndTransactionsPage isDesktopSize={fromDesktopSmall}>
      {(getFiservAccountsAPIResult.isFetching || accountHoldsQuery.isLoading || getAccountTransactionsAPIResult.isLoading || getAccountTransactionsAPIResult.isFetching) && <Loader />}
      {fromDesktopSmall && (
        <TransactionFiltersSidebar
          filterParamsInit={filterParamsInit}
          filterParams={filterParams}
          search={search}
          setSearch={setSearch}
          onFilter={onFilter}
          amountUpperLimit={maxTransactionAmount}
          allCategories={TRANSACTION_CATEGORIES}
          setCategories={setCategoriesFilter}
          selectedCategories={categoriesFilter}
        />
      )}

      <SMainContent>
        {isDesktopSize ? (
          <div className="flex-view flex-justify-start account-transaction">
            <Title paddingLeft={fromDesktopSmall ? 34 : 0}>{t(`account.AccountTransactions`)}</Title>
          </div>
        ) : (
          <>
            <div className="flex-view mobile-title">
              <Title>{cashAccount?.nickname || cashAccount?.name}</Title>
              <Icon name="chevronDown" size="smaller" marginTop={8} marginLeft={12} cursorPointer color="charcoal70" />
            </div>
            {!isMobileSmallSize && (
              <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" cursorPointer onClick={handleShowDetails} lineHeight="20px" marginTop={8}>
                {t('balancesTransactions.ShowAccountDetails')}
              </BodyText>
            )}
          </>
        )}

        <div className="flex-view flex-column-view flex-align-stretch content">
          <div className="content-inner">
            {isDesktopSize && (
              <>
                <div className="flex-view flex-justify-start">
                  <Title>{cashAccount?.nickname || cashAccount?.name}</Title>
                  <Icon name="chevronDown" size="smaller" marginTop={8} marginLeft={12} cursorPointer />
                </div>
                <BodyText textType="bodyText" color="blue" size="N" fontWeight="M" cursorPointer onClick={handleShowDetails} lineHeight="20px" marginTop={8}>
                  {t('balancesTransactions.ShowAccountDetails')}
                </BodyText>
              </>
            )}
            <AccountSelector group={selectedAccGroup} onSelectedAccount={handleTypeClick} selectedAccountType={accountType} />

            <div className="flex-view available-balance">
              <div className="flex-view flex-column-view flex-align-start">
                <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                  {t(`account.AvailableBalance`)}:
                </BodyText>

                {availableBalance != null && (
                  <CustomAmount
                    amount={availableBalance}
                    color={availableBalance < 0 ? 'red' : 'charcoal'}
                    size={isMobileSmallSize ? 'xl' : 'larger'}
                    isPoppins
                    multiSizable
                    remainingSize={isMobileSmallSize ? 'normal' : 'xl'}
                    remainingWeight={isDesktopSize ? 500 : 600}
                  />
                )}
                {pendingSum > 0 && (
                  <CustomRow marginTop={6}>
                    <BodyText textType="bodyText" display="inline" fontWeight="M" size="N" color="charcoal70">
                      {t(`account.AdditionalPending`, { pendingSumFormatted })}
                    </BodyText>
                    <Icon name="info" size="smaller" color="blue" marginLeft={10} onClick={pendingSumModal.show} />
                  </CustomRow>
                )}
              </div>

              {isDesktopSize && (
                <div className="flex-view additional-details">
                  <div className="flex-view flex-column-view flex-align-start">
                    <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" lineHeight="20px">
                      {t(`balancesTransactions.AllTransactions`)}
                    </BodyText>
                    <BodyText textType="bodyText" color="blue" size="T" fontWeight="M" cursorPointer onClick={handleShowDetails} lineHeight="20px">
                      {t(`balancesTransactions.AdditionalDetails`)}
                    </BodyText>
                  </div>
                </div>
              )}
            </div>

            {/* <TagBar filterBy={filterParams.filterBy} tagNameList={tagNameList} onClose={handleTagClose} locale={locale} />*/}

            {isSearchVisible && (
              <BaseInput
                placeholder={t(`balancesTransactions.WhatAreYouLookingFor`)}
                suffix="close"
                value={search}
                onBeige
                onChange={handleSearchInputChange}
                suffixClick={handleSearchClose}
                marginBottom={15}
                suffixSize="smallest"
              />
            )}

            <div className="transactions">
              {selectedAccountTransactions.length === 0 && !getAccountTransactionsAPIResult.isLoading && groupedByDateAccountTransactions.length === 0 && (
                <BodyText textType="bodyText" fontWeight="R" size="M" color="charcoal70" lineHeight="20px" marginBottom={50}>
                  {t(`balancesTransactions.NoTransactionsFound`)}
                </BodyText>
              )}
              {!fromDesktopSmall && (
                <div className="filter-search">
                  <Icon name="filter" color="charcoal70" size="small" cursorPointer onClick={() => openFilterSheet('main')} marginRight={16} />
                  <Icon name="search" color="charcoal70" size="small" cursorPointer onClick={() => setIsSearchVisible(true)} />
                </div>
              )}
              {groupedByDateAccountTransactions.map((group, i) => (
                // can't use transaction dates for keys since they are not stable identifiers of transaction groups
                // eslint-disable-next-line react/no-array-index-key
                <section key={i} className="group">
                  <div className="flex-view group-name">
                    <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70">
                      {customDateTranslation(group[0]?.acctTrnInfo?.trnDt)}
                    </BodyText>
                  </div>

                  <ul>
                    {group.map((transaction, j) => (
                      // FIXME: couldn't find any kind of stable identifier for a transaction
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={j}>
                        <STransaction onClick={() => handleOpenDetails(transaction)}>
                          <PaymentIcon
                            trnCode={transaction.acctTrnInfo.trnCode ?? ''}
                            iconUrl={transaction.acctTrnInfo.merchantLogo}
                            iconName={transaction.acctTrnInfo.primaryCategory}
                            type={transaction.acctTrnInfo.drCrType === 'Debit' ? 'expense' : 'income'}
                          />
                          <span className="description">
                            {transaction.acctTrnInfo?.merchantName || transaction.acctTrnInfo?.desc[0]}
                            <br />
                            <span className="transaction-bottom-caption">{getTransactionCaption(transaction)}</span>
                          </span>
                          <div className="amount">
                            <BodyText textType="helperText" fontWeight="B" size="S" color="charcoal" nowrap>
                              {transaction.acctTrnInfo.drCrType === 'Debit' ? '- ' : '+ '}
                              {formatAutoSign(transaction.acctTrnInfo?.trnAmt?.amt)}
                            </BodyText>
                          </div>
                        </STransaction>
                      </li>
                    ))}
                  </ul>
                </section>
              ))}
            </div>
          </div>
          <SuttonDisclaimerNote marginTop={isDesktopSize ? 60 : 0} marginBottom={isDesktopSize ? 80 : 0} footerTextType="Full" />
        </div>
      </SMainContent>

      {cashAccount && (
        <FilterDrawer
          isOpen={filterSheet.isActive}
          onOpen={openFilterSheet}
          onClose={filterSheet.hide}
          onFilter={onFilter}
          selectedCategories={categoriesFilter}
          amountUpperLimit={maxTransactionAmount}
          filterParamsInit={filterParamsInit}
          filterParams={filterParams}
          allCategories={TRANSACTION_CATEGORIES}
          setCategories={setCategoriesFilter}
          prevScreen={filterSheet?.data?.prevScreen}
        />
      )}

      <CustomModal open={pendingSumModal.isActive} onClose={pendingSumModal.hide} modalName="balancesTransactions_pendingBalanceModal" topPosition="19.5%" closeIconColor="charcoal70">
        <Title font="Poppins" color="charcoal" marginBottom={16} marginTop={15} fontWeight="M" size="M" extraStyles={{ paddingRight: '15px' }}>
          {t('account.PendingBalanceInfoModalTitle')}
        </Title>
        <BodyText lineHeight={1.4} textType="bodyText" size="N" fontWeight="R" color="charcoal70">
          {t('account.PendingBalanceInfoModalText')}
        </BodyText>
      </CustomModal>

      {selectedTransaction && (
        <TransactionDetailsSheet
          accountId={cashAccount?.accountId || '2'}
          isOpen={transactionSheet.isActive}
          transaction={selectedTransaction}
          onClose={transactionSheet.hide}
          isLoading={getAccountTransactionsAPIResult.isLoading || getAccountTransactionsAPIResult.isFetching}
        />
      )}
    </SBalancesAndTransactionsPage>
  );
};
