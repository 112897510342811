import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SVideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SVideoBlock = styled.div`
  padding: 0;
  overflow: hidden;
  margin: 0 0 25px;
  background: ${getColor('white')};
  border-radius: 25px;
`;

export const SBottomContainer = styled.div`
  padding: 0 18px 20px;
`;
