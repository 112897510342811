import React from 'react';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';

interface ICardItemProps {
  text: string;
  icon: string;
  marginTop?: number;
  marginBottom?: number;
}

export const CardItem = ({ text, icon, marginTop = 0, marginBottom = 0 }: ICardItemProps) => (
  <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" marginTop={marginTop} marginBottom={marginBottom}>
    <CustomRow flexDirection="row" marginRight={18}>
      <Icon name={icon} size="normal" color="charcoal70" />
    </CustomRow>
    <BodyText textType="bodyText" color="charcoal" fontWeight="M" font="DM Sans" size="N">
      {text}
    </BodyText>
  </CustomRow>
);
