import React from 'react';
import { Outlet } from 'react-router-dom';
import { SGridLayout, SContent } from './GridDesignLayout.styles';

export const GridDesignLayout = () => (
  <SGridLayout className="grid-layout">
    <SContent>
      <Outlet />
    </SContent>
  </SGridLayout>
);
