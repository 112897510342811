import styled, { css } from 'styled-components';
import { SCREEN_SIZE, mediaUpTo } from 'utils/helpers/styleHelpers';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SCustomModal = styled(CustomModal)`
  .center-image {
    margin: 12px 0px !important;
  }

  ${mediaUpTo(
    SCREEN_SIZE.tablet - 1,
    css`
      .custom-title-text {
        font-size: 20px;
      }
      .body-text {
        font-size: 16px;
      }
      button {
        font-size: 14px !important;
      }
    `
  )}
`;
