/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useDevLogger } from 'utils/hooks/useDevLogger';
import { Loader } from 'components/general/Loader/Loader';
import { usersApi } from 'store/user/users.api';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { theme } from 'styles/theme';
import { SelectableCard } from 'views/MoveMoney/ManageCardsPage/SelectableCard/SelectableCard';
import { SCardsContainer } from 'views/MoveMoney/ManageCardsPage/SelectableCard/SelectableCard.styles';
import { handleError } from 'utils/helpers/errorHelper';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';

interface IValidateCardSheet {
  isOpen: boolean;
  selectedAccount?: IAccountItem | undefined;
  internalAccounts?: IAccountItem[];
  fromSendMoney?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const ValidateCardSheet = ({ isOpen, selectedAccount, internalAccounts = [], fromSendMoney = false, onClose, onSuccess }: IValidateCardSheet) => {
  const navigate = useNavigate();
  const [addCardAPI, addCardAPIResult] = usersApi.useAddCardMutation();
  const { data: vgsData } = usersApi.useGetVGSDataQuery();
  const { t } = useTranslation('moveMoney');
  const { isDesktopSize } = useDeviceDimension();
  const { log, error } = useDevLogger();
  const [selectedAcc, setSelectedAcc] = useState<IAccountItem | undefined>();
  const [isDisabled, setIsDisabled] = useState(true);

  const debitCards = internalAccounts?.filter((account: IAccountItem) => account.debitCardNumber) ?? [];

  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const collectRef = useRef<any>(null);

  const css = {
    fontSize: '16px',
    fontWeight: '400',
    '&:placeholder': {
      fontSize: '16px',
      fontWeight: '400',
      color: theme.charcoal40,
      textAlign: 'center',
    },
    color: theme.charcoal,
    borderRadius: '16px',
    width: '76px',
    height: '48px',
    textAlign: 'center',
    background: theme.white,
  };

  useEffect(() => {
    if (fromSendMoney && debitCards.length) {
      setSelectedAcc(debitCards[0]);
    }
  }, []);

  useEffect(() => {
    if (window.VGSCollect && document.getElementById('cc-cvc') && vgsData) {
      collectRef.current = window.VGSCollect.create(vgsData.Identifier, vgsData.Environment, (state: any) => {
        log('CVC form state:', state);
        setIsDisabled(!state.card_cvc.isValid);
      }).setRouteId(vgsData.RouteId);

      collectRef.current.field('#cc-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        placeholder: 'CVV',
        validations: ['required', 'validCardSecurityCode'],
        css,
      });
    }
  }, [selectedAcc, vgsData]);

  const submitVGSCollectForm = () => {
    if (!collectRef.current) {
      error('VGS Collect form is not initialized');
      return;
    }

    collectRef.current.submit(
      '/post',
      {},
      (status: any, data: any) => {
        if (status >= 200 && status <= 300) {
          log('Successful response', data);

          const cvvToken = data.card_cvc;
          addCardAPI({ accountId: selectedAccount?.accountId || selectedAcc?.accountId, cvvToken });
        } else if (!status) {
          error('error', status);
          log('Network error occured');
        } else {
          error('Server error');
        }
      },
      (validationError: any) => {
        log(validationError);
      }
    );
  };

  useEffect(() => {
    if (addCardAPIResult.isSuccess) {
      onSuccess?.();
      onClose();
    }

    if (addCardAPIResult.isError) {
      handleError(addCardAPIResult.error);
      navigate(ROUTES.internationalTransfer.path);
    }
  }, [addCardAPIResult.isSuccess, addCardAPIResult.isError, onClose]);

  const handleCardClick = (account: IAccountItem, index: number) => {
    const container = cardContainerRef?.current;
    const card = cardRefs?.current[index];

    if (container && card) {
      const containerWidth = container.offsetWidth;
      const cardWidth = card.offsetWidth;
      const cardLeft = card.offsetLeft;
      const scrollPosition = cardLeft - containerWidth / 2 + cardWidth / 2;

      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
    setSelectedAcc(account);
  };

  if (addCardAPIResult.isLoading) return <Loader />;

  return (
    <CustomSheet isModal={isDesktopSize} destroyOnClose isOpen={isOpen} header={false} modalName="validateCard" height="fit-content" wrapperPadding={isDesktopSize} onClose={onClose}>
      <Title marginBottom={4}>
        {t('internationalTransferPage.validateCardSheet.Title')}
        {/* 
        {fromSendMoney && internalAccounts.length > 1 && t('internationalTransferPage.selectCardSheet.Title')}
        {fromSendMoney && internalAccounts.length === 1 && t('internationalTransferPage.confirmCardSheet.Title')}
        {!fromSendMoney && t('internationalTransferPage.validateCardSheet.Title')} */}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
        {fromSendMoney ? t('internationalTransferPage.validateCardSheet.Description') : t('internationalTransferPage.validateCardSheet.Description2')}
        {/*
        {fromSendMoney && internalAccounts.length > 1 && t('internationalTransferPage.selectCardSheet.Description')}
        {fromSendMoney && internalAccounts.length === 1 && t('internationalTransferPage.confirmCardSheet.Description')}
        {!fromSendMoney && t('internationalTransferPage.validateCardSheet.Description')}
        */}
      </BodyText>

      {/* Multiple card - send money */}
      <SCardsContainer ref={cardContainerRef}>
        {fromSendMoney &&
          debitCards.length &&
          debitCards.map((account: IAccountItem, index: number) => (
            <SelectableCard
              className={clsx({ selected: selectedAcc?.accountId === account.accountId })}
              isPrimary={account?.isPrimary}
              onClick={() => handleCardClick(account, index)}
              debitCardNumber={account?.debitCardNumber ?? ''}
              nickname={account?.nickname ?? ''}
              ref={(el) => (cardRefs.current[index] = el)}
            >
              <form id="vgs-collect-form">
                <div id="cc-cvc" />
              </form>
            </SelectableCard>
          ))}
      </SCardsContainer>

      {/* Single card - manage card */}
      {!fromSendMoney && (
        <SCardsContainer ref={cardContainerRef}>
          <SelectableCard className="selected" isPrimary={selectedAccount?.isPrimary} debitCardNumber={selectedAccount?.debitCardNumber ?? ''} nickname={selectedAccount?.nickname ?? ''}>
            <form id="vgs-collect-form">
              <div id="cc-cvc" />
            </form>
          </SelectableCard>
        </SCardsContainer>
      )}

      <Button onClick={submitVGSCollectForm} preset="blue-filled" disabled={isDisabled} marginBottom={24}>
        {/* 
        {fromSendMoney && internalAccounts.length > 1 && t('internationalTransferPage.selectCardSheet.Button')}
        {fromSendMoney && internalAccounts.length === 1 && t('internationalTransferPage.confirmCardSheet.Button')}
        {!fromSendMoney && t('internationalTransferPage.validateCardSheet.Button')}
        */}
        {t('internationalTransferPage.validateCardSheet.Button')}
      </Button>

      <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginBottom={12}>
        {t('internationalTransferPage.validateCardSheet.Disclosure1')}
      </BodyText>
      <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R">
        {t('internationalTransferPage.validateCardSheet.Disclosure2')}
      </BodyText>
    </CustomSheet>
  );
};
