import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TBreadcrumbsPath } from 'views/OpenCashAccount/StarterPage/CashAccOpeningDesktopPage/CashAccOpeningDesktopPage';
import { ROUTES } from 'vars/const/ROUTES';
import { Breadcrumbs } from 'views/Main/Header/Breadcrumbs/Breadcrumbs';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useAccounts } from 'utils/hooks/useAccounts';
import { InternalAccount } from 'components/general/Accounts/Internal/InternalAccount';
import { EAccountType, IAccountItem } from 'store/user/accounts/accounts.types';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { getCashAccountsWithSubIds } from 'utils/helpers/accounts/accountsHelper';
import { useAddAccountMutation } from 'store/user/accounts/accounts.api';
import { useToggle } from 'utils/hooks/useToggle';
import { OpenAccountSuccessModal } from 'views/Main/MainPage/Accounts/AddNeedsGoals/openAccountSuccessModal/openAccountSuccessModal';
import { Loader } from 'components/general/Loader/Loader';
import { StuffSaveAccountErrorModal } from 'components/general/Modals/AddStuffSaveAccountModal/StuffSaveAccountErrorModal';
import { SLayout } from './SelectAccountPage.styles';

export const SelectAccountPage = () => {
  const { navigate } = useEnhancedNav();
  const openAccountSuccessModal = useToggle();
  const errorModal = useToggle();
  const { t } = useTranslation('addAccount');
  const { type } = useParams();
  const { cashAccounts, internalAccounts } = useAccounts();
  const { isFromDesktopSize } = useDeviceDimension();
  const [currentAccount, setCurrentAccount] = useState<IAccountItem | null>(null);
  const cashAccountsWithSubIds = getCashAccountsWithSubIds(internalAccounts, type);
  const [addAccountAPI, addAccountAPIResult] = useAddAccountMutation();

  const pathList: TBreadcrumbsPath[] = [
    { id: 0, name: t('addAccount.MoreWithPercapita'), path: ROUTES.doMore.path },
    { id: 1, name: type === 'needs' ? t('addAccount.needs.Title') : t('addAccount.goals.Title') },
    { id: 2, name: '' },
  ];

  const getDescription = () => {
    const key = cashAccountsWithSubIds?.length ? 'SelectDescriptionWithDisabled' : 'SelectDescription';
    return t(`addAccount.${type}.${key}`);
  };

  const addAccountRequest = async () => {
    const percapitaAccountType = type === 'needs' ? EAccountType.STUFF : EAccountType.SAVE;

    if (currentAccount) {
      await addAccountAPI({
        percapitaAccountType,
        jointInviteType: currentAccount?.isPrimary ? undefined : 'JointOwner',
        cashAccountId: currentAccount?.accountId,
      });
    }
  };

  useEffect(() => {
    if (addAccountAPIResult?.isSuccess) openAccountSuccessModal.show();
    if (addAccountAPIResult?.isError) {
      errorModal.show();
    }
  }, [addAccountAPIResult?.isSuccess, addAccountAPIResult?.isError]);

  useEffect(() => {
    if (!isFromDesktopSize) navigate(ROUTES.addNeedsGoalsAccount.path, { params: { type } });
  }, [isFromDesktopSize]);

  return (
    <>
      <SLayout>
        {addAccountAPIResult.isLoading && <Loader />}
        <Breadcrumbs paths={pathList} title={t('addAccount.ConfirmCashAccountTitle')} hasBackNav />

        <div className="content-wrapper">
          <div className="account-selector-container">
            <Title size="sL" className="account-selector-title">
              {getDescription()}
            </Title>

            <div className="account-selector">
              {cashAccounts.map((account) => (
                <InternalAccount
                  account={account}
                  isAmountHidden
                  key={account.accountId}
                  isDesktop={isFromDesktopSize}
                  onSelect={setCurrentAccount}
                  selected={account.accountId === currentAccount?.accountId}
                  isDisabled={cashAccountsWithSubIds?.includes(account.accountId)}
                  isArrowHidden
                  isAddNeedsGoals
                />
              ))}
            </div>
          </div>

          <Button preset="blue-filled" size="middle" disabled={!currentAccount} onClick={addAccountRequest}>
            {t('addAccount.ConfirmAccount')}
          </Button>
        </div>

        <div className="disclaimer-wrapper">
          <SuttonDisclaimerNote footerTextType="Sutton" />
        </div>
      </SLayout>

      <OpenAccountSuccessModal open={openAccountSuccessModal.isActive} onClose={openAccountSuccessModal.hide} addAccountType={type} />
      <StuffSaveAccountErrorModal open={errorModal.isActive} onClose={errorModal.hide} />
    </>
  );
};
