import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { ROUTES } from 'vars/const/ROUTES';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPolicies } from 'store/user/authentication.slice';
import { useLazyCheckFreeQuery } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { Bar } from 'components/general/Bar/Bar';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { useToggle } from 'utils/hooks/useToggle';
import { useUserStatus } from 'utils/hooks/useUserStatus';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { lsGetItem } from 'utils/helpers/storage';
import { SMoveMoneyPage } from './MoveMoneyMainPage.style';
import { IFrameModal } from './IFrameModal';
import { BillPayErrorModal } from './BillPayErrorModal/BillPayErrorModal';

export const MoveMoneyMainPage = () => {
  const { t } = useTranslation('moveMoney', { keyPrefix: 'main' });
  const { isMobileSmallSize } = useDeviceDimension();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasPercapitaPay } = useUserStatus();
  const policies = useSelector(selectPolicies);
  const [checkFreeApi, { isLoading }] = useLazyCheckFreeQuery();
  const [iFrameUrl, setIFrameUrl] = useState('');
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [iFrameIsLoading, setIFrameIsLoading] = useState(true);
  const directDepositInfoModal = useToggle(false);
  const billPayErrorModal = useToggle(false);
  const [searchParams] = useSearchParams();
  const initParam = searchParams.get('init');
  const isMobileApp = lsGetItem('isMobileApp');

  const payBillClickHandler = async () => {
    track('navigate', 'bill-pay');
    checkFreeApi()
      .unwrap()
      .then((res) => {
        setIsIframeOpen(true);
        setIFrameUrl(res.url);
      });
  };

  const handleCloseIframe = () => {
    setIsIframeOpen(false);
    setIFrameUrl('');
  };

  const handleBillPayError = (errorValue = null) => {
    if (errorValue || errorValue === 0) {
      setIsIframeOpen(false);
      billPayErrorModal.show();
    }
  };

  const handleClickInternationalTransfers = () => {
    navigate(ROUTES.internationalTransfer.path);
  };

  const handleMobileCheck = () => navigate(ROUTES.mobileCheckCapture.path);

  const onMessage = (event: any) => {
    const billPayErrorValue = event?.data?.billPayError;
    handleBillPayError(billPayErrorValue);
  };

  useEffect(() => {
    if (window && window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    }

    return () => {
      window?.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    if (initParam === 'bill-pay') {
      payBillClickHandler();
    }
  }, [initParam]);

  return (
    <SMoveMoneyPage>
      {isLoading && <Loader />}
      <Title size={isMobileSmallSize ? 'T' : 'S'} color="charcoal" fontWeight="SB" font="Poppins" marginBottom={16}>
        {t('SendReceive')}
      </Title>

      <div className="move-money-group">
        <Bar title={t('AccountTransfers')} to={ROUTES.transfers.path} subtitle={t('ToFromPercapitaOrExternalAccounts')} iconName="arrowsRightLeft" />
        <Bar title={t('BillPayCheckSend')} isComingSoon={!policies?.BillPayEnabled} subtitle={t('PayBillersOrIndividuals')} onClick={payBillClickHandler} iconName="depositChecks" />
        <Bar title={t('MobileCheck')} isComingSoon={!policies?.CheckCashingEnabled} subtitle={t('CaptureChecksElectronically')} onClick={handleMobileCheck} iconName="checkCaptured" isMobileCheck />
        <Bar
          title={t('InternationalTransfer')}
          hasFeeIcon
          isComingSoon={!policies?.InternationalTransferEnabled || !isMobileApp}
          subtitle={t('SendMoneyOutside')}
          onClick={handleClickInternationalTransfers}
          iconName="internationalRemittance"
        />
      </div>

      <Title size={isMobileSmallSize ? 'T' : 'S'} color="charcoal" fontWeight="SB" font="Poppins" marginTop={32} marginBottom={16}>
        {t('GetPaid')}
      </Title>
      <div className="move-money-group">
        <Bar
          title={t('DirectDepositSetup')}
          subtitle={t('AutomaticallyDepositWages')}
          onClick={() => navigate(ROUTES.setUpDeposit.path)}
          onClickInfo={directDepositInfoModal.show}
          hasInfoIcon
          iconName="pennyJar"
        />
        <Bar
          title={t('PercapitaPay')}
          subtitle={t('AccessYourEarnedWages')}
          onClick={() => navigate(hasPercapitaPay ? ROUTES.percapitaPayHome.path : ROUTES.percapitaPayEnroll.path, { state: { backPage: pathname } })}
          iconName="internationalRemittance"
          hasFeeIcon
        />
      </div>

      <IFrameModal iFrameUrl={iFrameUrl} isIframeOpen={isIframeOpen} handleCloseIframe={handleCloseIframe} iFrameIsLoading={iFrameIsLoading} setIFrameIsLoading={setIFrameIsLoading} />

      <CustomModal open={directDepositInfoModal.isActive} modalName="moveMoneyDirectDepositModal" closeIconColor="charcoal70" onClose={directDepositInfoModal.hide} topPosition="10%">
        <Title size="M" color="charcoal" fontWeight="M" font="Poppins" marginBottom="spacing-med">
          {t('DirectDeposit')}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" font="DM Sans" size="N">
          {t('DirectDepositInfo')}
        </BodyText>
      </CustomModal>

      <BillPayErrorModal open={billPayErrorModal.isActive} onClose={billPayErrorModal.hide} />
    </SMoveMoneyPage>
  );
};
