import React from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SAccountsContainer, SCard, SContainer } from './AccountDetailsSection.styles';
import { AccountItem } from './AccountItem/AccountItem';

interface IAccountDetailsSection {
  accountId: string;
  routingNumber?: string;
}

export const AccountDetailsSection = ({ accountId, routingNumber = '' }: IAccountDetailsSection) => {
  const { t } = useTranslation('deposit');
  const { fromDesktopSmall, isMobileSize } = useDeviceDimension();

  return (
    <SContainer>
      {!fromDesktopSmall ? (
        <div className="mobile-title-container">
          <BodyText textType="bodyText" color="charcoal" size="M" fontWeight="B" justifyContent="start" marginBottom={16} marginTop={isMobileSize ? 25 : 0}>
            {t('setUpDeposit.accountDetails.Title')}
          </BodyText>

          <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" justifyContent="start" marginBottom={32}>
            {t('setUpDeposit.accountDetails.Description')}
          </BodyText>

          <SAccountsContainer>
            <AccountItem title={t('setUpDeposit.accountDetails.RoutingNumber')} number={routingNumber} tooltip="routingNumber" />
            <AccountItem title={t('setUpDeposit.accountDetails.CashAccountNumber')} number={accountId} tooltip="accountNumber" />
          </SAccountsContainer>
        </div>
      ) : (
        <SCard>
          <div className="web-title-container">
            <Title textAlign="start" marginBottom={24} fontWeight="SB" size="sL">
              {t('setUpDeposit.accountDetails.ThroughEmployers')}
            </Title>

            <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" textAlign="start">
              {t('setUpDeposit.accountDetails.ThroughHr')}
            </BodyText>

            <BodyText textType="bodyText" color="charcoal" style={{ width: '100%' }} fontWeight="B" size="N" textAlign="start" justifyContent="start" marginTop={56}>
              {t('setUpDeposit.accountDetails.InformationBelow')}
            </BodyText>
          </div>

          <SAccountsContainer>
            <AccountItem title={t('setUpDeposit.accountDetails.RoutingNumber')} number={routingNumber} tooltip="routingNumber" />
            <AccountItem title={t('setUpDeposit.accountDetails.CashAccountNumber')} number={accountId} tooltip="accountNumber" />
          </SAccountsContainer>
        </SCard>
      )}
    </SContainer>
  );
};
