import styled, { css } from 'styled-components';
import { getColor, getRgba, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  min-height: 100%;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
    flex: 0 0 auto;

    ${mediaFrom(
      'tablet',
      css`
        border-bottom: 1px solid ${getColor('creamS5')};
        gap: 10px;
        height: 87px;
        margin-bottom: 48px;
      `
    )}

    .title {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;

      ${mediaFrom(
        'desktop',
        css`
          font-size: 28px;
        `
      )}
    }

    .back {
      ${mediaUpTo(
        'desktop',
        css`
          display: none;
        `
      )}
    }
  }

  & > .container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;

    ${mediaFrom(
      'tablet',
      css`
        max-width: 1180px;
      `
    )}

    & > .content {
      margin: 0 auto;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: center;
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-card {
    background: ${getColor('white')};
    display: flex;
    padding: 24px;
    margin-bottom: 40px;
    border-radius: 20px;
    flex-direction: column;

    ${mediaFrom(
      'tablet',
      css`
        width: 75%;
        flex-direction: row;
        padding: 32px 24px;
      `
    )}

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;

      ${mediaUpTo(
        'tablet',
        css`
          margin-bottom: 32px;
        `
      )}

      .logo {
        width: 172px;
        height: 172px;
        flex: 0 0 auto;

        ${mediaFrom(
          'tablet',
          css`
            width: 155px;
            height: 157px;
          `
        )}
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 16px;

      ${mediaFrom(
        'tablet',
        css`
          margin-left: 32px;
        `
      )}

      .title {
        font-family: Poppins;
        color: ${getColor('charcoal')};
        font-size: 20px;
        font-weight: 500;

        ${mediaFrom(
          'tablet',
          css`
            font-weight: 600;
          `
        )}
      }

      .description {
        color: ${getColor('charcoal70')};
        font-size: 14px;
        font-weight: 400;
      }

      .footnotes {
        color: ${getColor('charcoal70')};
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
    margin-bottom: 32px;

    ${mediaFrom(
      'tablet',
      css`
        justify-content: center;
        align-items: center;
        max-width: 676px;
        margin-bottom: 64px;
      `
    )}

    .title {
      color: ${getColor('charcoal')};
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 3px;
      text-align: start;

      ${mediaFrom(
        'tablet',
        css`
          font-size: 40px;
          font-weight: 500;
          text-align: center;
          margin-bottom: 24px;
          line-height: 44px;
        `
      )}
    }

    .description {
      color: ${getColor('charcoal70')};
      font-size: 14px;
      font-weight: 400;

      ${mediaFrom(
        'tablet',
        css`
          font-size: 18px;
          text-align: center;
          color: ${getColor('charcoal')};
        `
      )}
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: space-between;
  }

  .btn-container {
    display: flex;
    margin-bottom: 20px;
    align-self: stretch;

    ${mediaFrom(
      'tablet',
      css`
        position: sticky;
        bottom: 55px;
        justify-content: center;
        margin-bottom: 50px;
        padding: 24px;
        align-items: center;
        border-radius: 20px;
        background: linear-gradient(0deg, ${getRgba('cream70', 0.2)} 0%, ${getRgba('white', 0.6)} 100%);
        box-shadow: 0 16px 24px 0 ${getRgba('black', 0.04)};
        backdrop-filter: blur(12px);

        .btn {
          width: auto;
        }
      `
    )}
  }

  .disclosure-web-container {
    display: flex;

    span {
      font-size: 10px;
      font-weight: 400;
      color: ${getColor('charcoal70')};
      line-height: 16px;
    }

    width: 75%;
    justify-content: flex-start;
    margin: 30px 0 30px 30px;

    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  .footer {
    display: flex;
    flex: 0 0 auto;
    padding-bottom: 18px;

    ${mediaFrom(
      'tablet',
      css`
        padding-bottom: 38px;
      `
    )}

    span {
      font-size: 10px;
      font-weight: 400;
      color: ${getColor('charcoal70')};
      line-height: 16px;
    }
  }

  .penny-jar-box {
    background: ${getColor('white')};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 20px;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 32px;

    .item {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      color: ${getColor('charcoal')};
    }
  }

  .settings-block {
    display: flex;
    flex-direction: column;

    max-width: 578px;
    margin-bottom: 50px;

    &__text {
      margin-bottom: 35px;
    }

    &__save-btn {
      width: auto;

      ${mediaFrom(
        'tablet',
        css`
          align-self: center;
        `
      )};
    }
  }
`;
