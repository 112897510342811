import styled, { css } from 'styled-components';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SGridLayout = styled.div`
  display: flex;
  min-height: 100%;
  max-width: 100%;
  padding: 0;
  align-items: stretch;
  flex: 1 1 auto;
  overscroll-behavior: none;

  ${mediaUpTo(
    'tablet',
    css`
      padding: 0 20px;
    `
  )};

  ${mediaFrom(
    'tablet',
    css`
      padding: 0 24px;
    `
  )};

  ${mediaFrom(
    'desktop',
    css`
      padding: 0 24px 0 48px;
    `
  )}
`;

export const SContent = styled.div`
  flex: 1 1 auto;
  min-height: 100%;
  max-width: 100%;
`;
