import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { CardLink } from 'views/Main/MainPage/Accounts/CardLink/CardLink';
import { IAccountGroup } from 'utils/helpers/accounts/accountsHelper';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { ROUTES } from 'vars/const/ROUTES';
import { EAccountType } from 'store/user/accounts/accounts.types';
import { Icon } from 'components/general/Icon/Icon';
import { PrimaryCashAmount } from './components/PrimaryCachAmount/PrimaryCashAmount';
import { SAccountCardItem } from './AccountCardItem.styles';
import { CombinedBalance } from './CombinedBalance/CombinedBalance';

interface AccountCardItemProps {
  isAccCollapsed?: boolean | null;
  parentId: string;
  group?: IAccountGroup;
}

export const AccountCardItem = ({ isAccCollapsed, parentId, group }: AccountCardItemProps) => {
  const { t } = useTranslation(['home', 'addAccount', 'wellness', 'cashAccountOpening']);
  const { navigate } = useEnhancedNav();
  const cashAccount = group?.cash;
  const needsAccount = group?.needs;
  const goalsAccount = group?.goals;
  const needsAccountTitle: string = needsAccount?.nickname?.trim() || t(cashAccount?.owner ? 'homeScreen.NeedsAccount' : 'homeScreen.JointNeedsAccount');
  const goalsAccountTitle: string = goalsAccount?.nickname?.trim() || t(cashAccount?.owner ? 'homeScreen.GoalsAccount' : 'homeScreen.JointGoalsAccount');

  const combinedAmount = () =>
    group
      ? Object.values(group).reduce((sum, item) => {
          if (item.balance) {
            return sum + item.balance;
          }
          return sum;
        }, 0)
      : 0;

  const handleCardClick = (parentAccId: string, accountType: EAccountType) => {
    navigate(ROUTES.balancesTransactions.path, {
      searchParams: {
        groupId: parentAccId,
        accountType,
      },
    });
  };

  return (
    <SAccountCardItem>
      <PrimaryCashAmount name={cashAccount?.nickname || cashAccount?.name} cashAmount={cashAccount?.balance} accountId={parentId} />

      {!isAccCollapsed && (
        <div className="account-items-container">
          {needsAccount && (
            <CardLink
              onClick={() => handleCardClick(needsAccount?.parentAccountId, EAccountType.STUFF)}
              title={needsAccountTitle}
              name={needsAccount.name}
              amount={needsAccount?.balance}
              startIcon={<Icon className="account-icon" name="needsAccount" color="orange" cursorPointer />}
            />
          )}

          {goalsAccount && (
            <CardLink
              onClick={() => handleCardClick(goalsAccount?.parentAccountId, EAccountType.SAVE)}
              title={goalsAccountTitle}
              name={goalsAccount.name}
              amount={goalsAccount?.balance}
              startIcon={<Icon className="account-icon" name="goal" color="orange" cursorPointer />}
            />
          )}

          {(needsAccount || goalsAccount) && <CombinedBalance balance={combinedAmount()} />}

          {cashAccount?.isJoint && (cashAccount?.ownerName || cashAccount?.jointHolderName) && (
            <div className="joint-accountholder-caption">
              <BodyText textType="bodyText" color="charcoal70" size="T" display="inline" fontWeight="SB" marginTop={10}>
                {t('accountOpening.JointAccountholder')}:
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="T" display="inline" fontWeight="R" marginTop={10}>
                {cashAccount?.ownerName || cashAccount?.jointHolderName}
              </BodyText>
            </div>
          )}
        </div>
      )}
    </SAccountCardItem>
  );
};
