import Slider from 'react-slick';
import styled, { css, WebTarget } from 'styled-components';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';
import { Title } from 'components/general/Typography';

export const SSlider = styled(Slider as WebTarget)`
  margin: 0 -15px;
  padding: 15px;

  li {
    margin: 0 4px 0;
  }

  .slick-dots {
    color: ${getColor('cream10')};
    position: relative;
    bottom: auto;
  }

  .slick-dots li button {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 2px solid ${getColor('blue')};
    background: transparent;
    padding: 0;
  }

  .slick-dots li.slick-active button {
    opacity: 1;
    color: ${getColor('blue')};
    background: ${getColor('blue')};
  }
`;

export const SCollapseButton = styled.button`
  outline: none;
  margin: 0;
  border: none;
  align-self: flex-end;
  background: none;
  white-space: nowrap;
`;

export const STitle = styled(Title)`
  ${mediaUpTo(
    'tablet',
    css`
      .custom-title-text {
        font-size: 20px;
      }
    `
  )}
`;
