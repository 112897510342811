import React, { useMemo } from 'react';
import { BodyText } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { IUploadedDocumentItem } from 'views/Documents/UploadDocument/UploadDocumentPage';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SFileItemContainer, SMultipleFiles } from './FileItem.styles';

interface IFileItem {
  title?: string;
  isMultiple?: boolean;
  index: number;
  uploadedDocuments: IUploadedDocumentItem[];
  uploadSelected: (index: number) => void;
  deleteFile?: (file: IUploadedDocumentItem) => void;
}

export const FileItem: React.FC<IFileItem> = ({ title, isMultiple, uploadSelected, deleteFile, uploadedDocuments, index }) => {
  const { t } = useTranslation('documents');
  const uploadedFiles = useMemo(() => uploadedDocuments.filter((item: IUploadedDocumentItem) => item.index === index), [uploadedDocuments, index]);
  const uploadedFilesNames = uploadedFiles.map((fileObj) => fileObj.file.name).join(', ');

  const handleUploadSelect = () => {
    uploadSelected(index);
  };

  return (
    <>
      {title && (
        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" marginBottom={16} marginTop={index === 0 ? 0 : 32}>
          {title}
        </BodyText>
      )}

      {(!isMultiple || uploadedFiles.length === 0) && (
        <SFileItemContainer onClick={handleUploadSelect}>
          <BodyText textType="bodyText" fontWeight="B" size="M" cursorPointer color="blue">
            {uploadedFilesNames || t('documents.UploadFile')}
          </BodyText>
          <Icon name="uploadFile" size="normal" color="blue" cursorPointer />
        </SFileItemContainer>
      )}

      {isMultiple && uploadedFiles.length > 0 && (
        <SMultipleFiles>
          {uploadedFiles?.map((fileObj) => (
            <CustomRow justifyContent="space-between" width="100%" marginBottom={6}>
              <BodyText textType="bodyText" fontWeight="R" size="M">
                {fileObj.file.name}
              </BodyText>
              <Icon name="close" size="mini" onClick={() => deleteFile && deleteFile(fileObj)} cursorPointer />
            </CustomRow>
          ))}
          <CustomRow marginTop={12} onClick={handleUploadSelect}>
            <BodyText textType="bodyText" fontWeight="B" size="M" cursorPointer color="blue" marginRight={6}>
              {t('documents.AddMore')}
            </BodyText>
            <Icon name="circlePlus" size="normal" color="blue" cursorPointer />
          </CustomRow>
        </SMultipleFiles>
      )}
    </>
  );
};
