import { Trans } from 'react-i18next';
import React from 'react';
import { BodyText } from 'components/general/Typography';

export const tableData = [
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" size="T" color="charcoal">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.monthlyFee" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $0.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.atmWithdrawalFee" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-subtitle" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal" marginBottom={16}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.inNetwork" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.inNetworkRefersToAllATMInEitherTheMoneyPass" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $0.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.atmWithdrawalFee" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-subtitle" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal" marginBottom={16}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.outOfNetwork" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.outOfNetworkMeansAnATMThatDoesNotParticipateInMoneyPass" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $2.50
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.atmBalanceInquiry" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $0.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.overdraftFee" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $0.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.mobileCheckCapture" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-subtitle" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal" marginBottom={16}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.moneyInMinutes" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.checksFundsLessTheFeeAmount" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText className="cell-amount-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70" marginBottom={16}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.0forPayrollOrGovernment" ns="starter" components={{ Bold: <span className="amount-bold" /> }} />
        </BodyText>

        <BodyText className="cell-amount-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.5ForOtherCheckTypesMinimum" ns="starter" components={{ Bold: <span className="amount-bold" /> }} /> <br />
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.minimum5Check" ns="starter" />
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.mobileCheckCapture" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-subtitle" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal" marginBottom={16}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.moneyIn10Days" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.checkFundsWillArriveInYourAccount" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $0.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={16}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.foreignTransactionFee" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-description" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal70">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.appliesToDebitCardTransactionsAndCash" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          1%
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.stopPayment" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $15.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.replacementCard" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-subtitle" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.standardShipping" ns="starter" /> <br />
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.7To10Days" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $0.00
        </BodyText>
      </div>
    ),
  },
  {
    fee: (
      <div className="cell-fee-wrapper">
        <BodyText className="cell-fee-title" textType="bodyText" fontWeight="M" lineHeight={1.3} size="T" color="charcoal" marginBottom={4}>
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.expeditedShipping" ns="starter" />
        </BodyText>

        <BodyText className="cell-fee-subtitle" textType="bodyText" fontWeight="R" lineHeight={1.3} size="T" color="charcoal">
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.expeditedShipping" ns="starter" /> <br />
          <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.1To3Days" ns="starter" />
        </BodyText>
      </div>
    ),
    amount: (
      <div className="cell-amount-wrapper">
        <BodyText textType="bodyText" fontWeight="B" size="T" color="charcoal">
          $20.00
        </BodyText>
      </div>
    ),
  },
];
