import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/general/Icon/Icon';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Form } from 'antd';
import { getEmailRules } from 'utils/helpers/validationRules';
import { useFormHelpers } from 'utils/hooks/useFormHelpers';
import { useToggle } from 'utils/hooks/useToggle';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { SLabelText } from 'views/Profile/ProfilePage/DataItem/DataItem.styles';
import { EditEmailVerificationSheet } from 'views/Profile/ProfilePage/ProfileEditSheet/EditEmail/EditEmailVerificationSheet';

interface IFormValues {
  email: string;
}

interface IProps {
  onClose: () => void;
}

export const EditEmail = ({ onClose }: IProps) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const verifyCodeModal = useToggle(false, { email: '' });
  const userProfileData = useSelector(selectCurrentUser);
  const { hasErrors, checkErrors } = useFormHelpers(form);

  const onFinish = (formValues: IFormValues) => {
    verifyCodeModal.setData({ email: formValues.email });
    verifyCodeModal.show();
  };

  const handleStopEdit = () => {
    verifyCodeModal.setData({ email: '' });
    verifyCodeModal.hide();
    onClose();
  };

  return (
    <Form onFinish={onFinish} onFieldsChange={checkErrors} form={form} requiredMark={false} layout="vertical" autoComplete="off" initialValues={{ email: userProfileData?.email }}>
      <SLabelText textType="bodyText" font="DM Sans" fontWeight="R" color="charcoal70" size="M">
        {t(`profile.Email`)}
      </SLabelText>

      <Form.Item name="email" validateTrigger={['onBlur', 'onChange', 'onPaste']} rules={getEmailRules()} className="inline-field">
        <BaseInput
          placeholder={t('profile.SampleMailCom')}
          data-testid="email"
          autoCapitalize="off"
          inputMode="email"
          customSuffix={
            <CustomRow justifyContent="flex-end" gap={18}>
              <Icon name="closeThin" size="smaller" color="charcoal40" cursorPointer onClick={onClose} />
              {!hasErrors && <Icon name="tick" size="smaller" color="blue" cursorPointer onClick={form.submit} />}
            </CustomRow>
          }
        />
      </Form.Item>
      <EditEmailVerificationSheet isOpen={verifyCodeModal.isActive} {...verifyCodeModal.data} onClose={handleStopEdit} />
    </Form>
  );
};
