import React from 'react';
import { Title } from 'components/general/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { SCustomModal } from './FeeScheduleModal.styles';
import { tableData } from './Table/TableData';

type TFeeScheduleModalProps = {
  isActive: boolean;
  hide: () => void;
};

const columns = [
  {
    title: (
      <Title size="XS" font="DM Sans" fontWeight="B">
        <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.fee" ns="starter" />
      </Title>
    ),
    dataIndex: 'fee',
    key: 'fee',
    width: 210,
    render: (text: string) => <div className="td-content-wrapper">{text}</div>,
  },
  {
    title: (
      <Title size="XS" font="DM Sans" fontWeight="B">
        <Trans i18nKey="starter.benefits.noSurpriseFees.feeScheduleModal.amount" ns="starter" />
      </Title>
    ),
    dataIndex: 'amount',
    key: 'amount',
    render: (text: string) => <div className="td-content-wrapper">{text}</div>,
  },
];

export const FeeScheduleModal = ({ isActive, hide }: TFeeScheduleModalProps) => {
  const { t } = useTranslation('starter');

  return (
    <SCustomModal open={isActive} onClose={hide} contentHeight="97%" isFullHeight topPosition="4%" modalName="feeSchedule">
      <Title className="modal-title" fontWeight="SB" size="sL" font="Poppins" textAlign="start" color="charcoal" marginBottom={24}>
        {t('starter.benefits.noSurpriseFees.feeScheduleModal.feeSchedule')}
      </Title>

      <div className="body-content">
        <Table className="table" size="large" columns={columns} dataSource={tableData} pagination={false} />
        <SuttonDisclaimerNote marginTop={24} marginLeft={24} marginBottom={24} marginRight={24} />
      </div>
    </SCustomModal>
  );
};
