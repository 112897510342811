import styled from 'styled-components';
import { Button } from 'components/theme/Button/Button';
import { MEDIA_SIZE } from 'utils/helpers/styleHelpers';

export const SCustomButton = styled(Button)`
  margin: 0 auto;
  width: auto;
  @media screen and (max-width: ${MEDIA_SIZE.tablet}px) {
    width: 100%;
  }
`;
