import styled, { css } from 'styled-components';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import { Icon } from 'components/general/Icon/Icon';
import { getColor, mediaFrom, mediaUpTo, mediaUpToHeight } from 'utils/helpers/styleHelpers';
import { StylesConfig } from 'react-select';
import { theme } from 'styles/theme';

export const SIcon = styled(Icon)`
  background: ${getColor('blue')};
  padding: 10px;
  border-radius: 50%;
  float: right;
`;

export const SEditIcon = styled(Icon)`
  position: absolute;
  right: 8%;
`;

export const SIconWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 24px 0;
`;

export const SPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 0 35px 5px;
  min-height: 100%;
  width: 100%;

  .ant-form-item {
    margin-bottom: 18px;
  }

  .tel-link {
    margin-bottom: 15px;
  }

  .disclosures-header {
    padding-top: 10px;
  }

  .disclosures-consent {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ${mediaUpTo(
    400,
    css`
      padding-bottom: 5px;
      .disclosures-header {
        padding-top: 7px;
        & .custom-title-text {
          font-size: 25px;
        }
        & .consent-wrapper .custom-text-inner {
          font-size: 14px;
        }
      }
    `
  )}

  ${mediaUpToHeight(
    800,
    css`
      .disclosures-consent {
        margin-top: 15px;
      }
      .disclosures-available {
        margin-bottom: 55px;
      }
    `
  )}

  .open-account {
    &-name {
      padding-top: 10px;
      ${mediaUpTo(
        400,
        css`
          & .custom-title-text {
            font-size: 25px;
          }
        `
      )}

      ${mediaUpTo(
        'tablet',
        css`
          padding-top: 0;
          &-card {
            padding: 20px;
          }
        `
      )}
    }

    &-footer {
      margin-top: 172px;

      ${mediaUpToHeight(
        815,
        css`
          margin-bottom: 20px;
        `
      )}

      ${mediaUpTo(
        400,
        css`
          margin-top: 120px;
        `
      )}

      ${mediaUpTo(
        'tablet',
        css`
          margin-top: 100px;
        `
      )}
    }
  }

  .state-and-zip-code-field {
    display: flex;
    justify-content: space-between;
  }

  .age-tax-page {
    &-header {
      display: flex;
      flex-direction: column;
      ${mediaUpTo(
        'tablet',
        css`
          & .custom-title-text {
            font-size: 25px;
          }

          & .icon-exitFlow {
            width: 24px;
            height: 24px;
          }
        `
      )}
    }
    &-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .summary-page {
    ${mediaUpTo(
      400,
      css`
        &-header .custom-title-text {
          font-size: 25px;
        }
      `
    )}

    ${mediaUpTo(
      'tablet',
      css`
        &-header .custom-title-text {
          font-size: 23px;
        }
        &-card {
          padding: 24px;

          & .body-text {
            padding-right: 30px;
          }
          & .custom-text-inner {
            font-size: 15px;
          }
        }
      `
    )}

    &-footer {
      display: flex;
      flex-direction: column;
      padding-top: 48px;
      justify-content: flex-end;
    }
  }

  ${mediaUpTo(
    'tablet',
    css`
      .icon-exitFlow {
        width: 20px;
        height: 20px;
      }
    `
  )}
`;

export const SResultPageContainer = styled(SPageContainer)`
  padding-top: 75px;
  justify-content: flex-start;
  align-items: center;
`;

export const SCircle = styled.div`
  width: 42px;
  height: 42px;
  background: ${getColor('blue5')};
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SIconMail = styled(Icon)`
  background: ${getColor('blue5')};
  width: 20px;
  position: relative;
  flex: 0 0 auto;
  top: 7px;
  margin: auto;
`;

export const SButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const SCustomRow = styled(CustomRow)`
  gap: 10px;
  .input-status-success {
    min-width: 100%;
  }
`;

export const SCustomButton = styled(Button)`
  padding: 15px 32px;
`;

export const SAgeFormWrapper = styled.div`
  margin-bottom: 24px;
`;

export const SSelectWrapper = styled.div`
  position: relative;
  width: 49%;
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const SContent = styled.div`
  position: relative;
  background: ${getColor('white')};
  border-radius: 20px;
  padding: 24px;
`;

export const SMailingWrapper = styled.div`
  display: block;
  &.hide {
    display: none;
  }
`;

export const stateSelectorStyles: StylesConfig = {
  menuList: (baseStyles) => ({
    ...baseStyles,
    paddingTop: 10,
    paddingBottom: 0,
    borderRadius: '16px',
    position: 'relative',
    width: '100%',
    color: theme.charcoal,
    cursor: 'pointer',
    boxShadow: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '25vh',
    overflowY: 'scroll',
  }),
};

export const SPageLayoutCommonStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .web-header {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  ${mediaFrom(
    'tablet',
    css`
      padding: 0;
    `
  )};
`;
