import { useEffect, useState } from 'react';
import { useChangeAccountFeatureMutation, useLazyGetAccountFeaturesQuery } from 'store/user/accounts/accounts.api';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useToggle } from 'utils/hooks/useToggle';

export const useAllCashAccountsFeatures = () => {
  const [getFeatures, { isLoading: featuresLoading }] = useLazyGetAccountFeaturesQuery();
  const { cashAccounts, internalAccounts, isLoading: accountsLoading } = useAccounts();
  const [featuresArray, setFeaturesArray] = useState<any[]>([]);
  const [changeFeature, changeFeatureResult] = useChangeAccountFeatureMutation();
  const loadingFlag = useToggle();

  const setRoundUp = (cashAccountId: string, isEnabled: boolean, destinationAccountId: string) => {
    changeFeature({
      cashAccountId,
      data: [
        {
          type: 'RoundUp',
          isEnabled,
          roundUp: {
            accountId: destinationAccountId,
            amount: 1.0,
          },
        },
      ],
    });
  };

  const setCashCushion = (cashAccountId: string, isEnabled: boolean) => {
    changeFeature({
      cashAccountId,
      data: [
        {
          type: 'Overdraft',
          isEnabled,
          overdraft: {
            isEligible: true,
            reason: '',
            amount: 0,
          },
        },
      ],
    });
  };

  useEffect(() => {
    loadingFlag.show();
    async function handleGetFeatures() {
      if (cashAccounts?.length) {
        const arr = await Promise.all(
          cashAccounts.map(async (acc) => {
            // eslint-disable-next-line @typescript-eslint/return-await
            return await getFeatures(acc.accountId).then((res) => {
              return (
                res.data?.length &&
                res.data.flat().map((featureItem: { [key: string]: any }) => ({
                  accountId: acc.accountId,
                  ...featureItem,
                }))
              );
            });
          })
        );
        setFeaturesArray(arr.flat());
      }
      loadingFlag.hide();
    }

    handleGetFeatures();
  }, [cashAccounts]);

  return {
    features: featuresArray,
    roundUpFeatures: featuresArray
      .filter((feature) => feature?.type === 'RoundUp')
      .map((feature) => (internalAccounts.find((acc) => acc.accountId === feature.roundUp.accountId) ? feature : { ...feature, isEnabled: false, roundUp: {} })),
    overdraftFeatures: featuresArray.filter((feature) => feature?.type === 'Overdraft'),
    setRoundUp,
    setCashCushion,
    isLoading: featuresLoading || accountsLoading || changeFeatureResult.isLoading || loadingFlag.isActive,
    isFeatureChangeSuccess: changeFeatureResult.isSuccess,
  };
};
