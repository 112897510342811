import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SPlayToWin = styled.div`
  color: ${getColor('white')};
  background: #9a57b2;

  .header {
    height: 100px;
    background: ${getColor('blue')};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 24px;
    }
  }

  .button {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: 14px 32px;
  }

  .main {
    background: ${getColor('bluePurple')};
    padding: 0 0 64px;
    position: relative;

    .main-content {
      position: relative;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .main-bg {
      position: absolute;
    }

    .dots-left {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }

    .dots-right {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9;
    }

    .main-img {
      width: 100%;
      height: 453px;
      position: relative;
      z-index: 9;
    }

    .custom-title-text {
      font-size: 40px;
      line-height: 52px;
    }

    .highlight {
      color: ${getColor('gold')};
    }
  }

  .how-to {
    padding: 0 20px;

    .how-to-title > .custom-title-text {
      font-size: 34px;
      line-height: 42px;
    }

    .how-to-content {
      position: relative;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .how-to-list {
    padding: 40px 16px;
    background: ${getColor('bluePurple80')};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .dots-left {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }

    .dots-right {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      list-style: none;
      counter-reset: listcounter;
      padding-left: 0;
      margin-bottom: 40px;

      &-item {
        display: flex;

        &:before {
          content: counter(listcounter);
          counter-increment: listcounter;
          width: 56px;
          height: 56px;
          color: ${getColor('bluePurple')};
          background: #dfc3e9;
          border-radius: 100px;
          margin-right: 16px;
          flex-shrink: 0;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .disclosures {
    padding: 24px 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .disclosure {
      color: ${getColor('bluePurple30')};
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .footer {
    height: 189px;
    background: ${getColor('blue')};
    padding: 40px 0;
    position: relative;

    .logo {
      height: 33px;
      margin: 0 auto 40px;
      display: block;
    }

    .copyright {
      text-align: center;
      font-size: 12px;

      .link {
        color: ${getColor('white')};
        text-decoration: underline;
      }
    }

    .dots-left {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 9;
    }

    .dots-right {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 9;
    }
  }
`;
