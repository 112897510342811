import styled, { css } from 'styled-components';
import { getColor, mediaUpTo, mediaUpToHeight } from 'utils/helpers/styleHelpers';

const havingIssuesModalStyle = css`
  & .custom-title-text {
    font-size: 24px;
  }

  & .custom-text-inner {
    font-size: 12px;
  }

  & .preFooter .custom-text-inner {
    font-size: 14px;
  }

  & .footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 10px;

    & > button {
      padding: 8px 12px;
      margin: 20px 0 0;
      font-size: 14px;
      &:first-child {
        margin-right: 10px !important;
        margin-top: 0;
      }
    }
  }
`;

export const SContentLayout = styled.div`
  display: flex;
  flex-direction: column;

  .link-phone {
    color: ${getColor('blue')};
    text-decoration: underline;
  }

  & .list {
    list-style: none;
    padding-left: 8px;
  }

  & .list li {
    display: flex;
  }

  & .list li:before {
    content: '\u2022';
    color: ${getColor('red')};
    font-weight: bold;
    margin-right: 10px;
  }

  & .footer {
    margin-top: 20px;
  }

  .havingIssuesModal {
    display: flex;
    flex-direction: column;

    ${mediaUpToHeight(
      845,
      css`
        ${havingIssuesModalStyle}
      `
    )}
    
    ${mediaUpTo(
      'mobile',
      css`
        ${havingIssuesModalStyle}
      `
    )}
  }

  .unableCloseModalInner,
  .wantCloseModalInner {
    display: flex;
    flex-direction: column;

    & img {
      width: 113px; 
      align-self: center; 
      margin-bottom: 32px;
    }

    ${mediaUpTo(
      'mobile',
      css`
        & .custom-title-text {
          font-size: 24px;
        }

        & button {
          font-size: 15px;
        }
      `
    )}
  }

  .wantCloseModalInner {

    & img {
      width: 119px; 
      align-self: center; 
      margin-bottom: 28px;
    }

    & button {
    ${mediaUpTo(
      'mobile',
      css`
        font-size: 16px;
      `
    )}
  }
`;
