import { Icon } from 'components/general/Icon/Icon';
import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { Button } from 'components/theme/Button/Button';

export const SIconTooltip = styled(Icon).attrs({
  color: 'blue',
})`
  position: absolute;
  flex: 0 0 auto;
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
`;

export const SButton = styled(Button)`
  min-width: 80px !important;
  margin-top: 20px;

  ${mediaFrom(
    'tablet',
    css`
      justify-content: flex-start;
      padding-bottom: 20px !important;
      margin-top: 10px;
      font-weight: 600;
    `
  )}
`;

export const SList = styled.ul`
  padding-left: 25px;

  li {
    color: ${getColor('blue')};
    font-weight: 700;
    font-size: 14px;
    margin-right: 4px;
  }
`;

export const SToolCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${getColor('white')};
  border-radius: 20px;
  padding: 24px 20px;
  width: auto;
  margin-top: 0;
  margin-bottom: 30px;
  position: relative;

  ${mediaUpTo(
    'tablet',
    css`
      .custom-text-inner {
        font-size: 12px;
      }

      &.invite-friend ${SButton} {
        border-color: ${getColor('blue20')};
      }
    `
  )}

  ${mediaFrom(
    'tablet',
    css`
      padding: 24px;
      width: 50%;

      .icon-refer,
      .icon-percapitaPlay {
        width: 20px;
        height: 20px;
        flex: 0 0 auto;
      }
    `
  )}

  strong {
    color: ${getColor('charcoal')};
  }
`;
