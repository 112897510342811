import React from 'react';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { BigCard } from './BigCard/BigCard';
import { SCardsContainer, SDiscoverContainer } from './Discover.styles';
import { PercapitaPlaySection } from './PercapitaPlaySection/PercapitaPlaySection';

interface IDiscover {
  isZogoLoading: boolean;
}

export const Discover: React.FC<IDiscover> = ({ isZogoLoading }) => {
  const { t } = useTranslation(['home', 'wellness']);
  const { isDesktopSize } = useDeviceDimension();

  return (
    <SDiscoverContainer>
      <Title className="title" justifyContent="start" marginBottom="spacing-med">
        {t('homeScreen.Wellness')}
      </Title>

      <SCardsContainer>
        <PercapitaPlaySection isLoading={isZogoLoading} isDesktopSize={isDesktopSize} />
        <BigCard
          title="goalsTools.Goals & Tools"
          iconName="goalsAndTools"
          iconColor="blue"
          bgColor={isDesktopSize ? 'transparent' : 'blue5'}
          description={t('goalsTools.See your financial health quiz results and set, track, and update your financial goals.', { ns: 'wellness' })}
          buttonText="goalsTools.Show Details"
          to={ROUTES.wellness.path}
        />
      </SCardsContainer>
    </SDiscoverContainer>
  );
};
