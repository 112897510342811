import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';
import { getColor } from 'utils/helpers/styleHelpers';

export const SLoaderBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => transparentize(0.4, getColor('white')(props) as string)};
`;

const pointAnimation = keyframes`
  0% {
    scale: 1%;
    left: 0;
  }
  50% {
    scale: 100%;
    left: calc(50% - var(--point-size)/2);
  }
  100% {
    scale: 1%;
    left: calc(100% - var(--point-size));
  }
`;

export const SLoader = styled.div`
  
  --point-size: 23.6px;
  
  display: inline-flex;
  width: 120px;
  height: var(--point-size);
  align-items: center;
  position: relative;

  &.small {
    --point-size: 10px;
    width: 50px;
  }

  div {
    width: var(--point-size);
    height: var(--point-size);
    border-radius: 50%;
    border: none;
    background: ${getColor('blue')};
    position: absolute;
    animation: ${pointAnimation} 2.1s linear infinite;
    overflow: visible;
    scale: 0;

    &:nth-child(1) {
      background: #F6C951;
      animation-delay: 0.7s;
    }
    &:nth-child(2) {
      background: #404EDC;
    }
    &:nth-child(3) {
      background: #A165C0;
      animation-delay: -0.7s;
    }
  }
  
}
`;
