import styled from 'styled-components';
import { MEDIA_SIZE, flex, getColor } from 'utils/helpers/styleHelpers';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';

export const SManageExternalSheet = styled(CustomSheet)`
  .container {
    ${flex('column', 'flex-start', 'stretch')};
  }

  .header {
    ${flex('row', 'flex-start')}
  }

  .content:not(.external-account .content) {
    ${flex('column', 'flex-start', 'stretch')}
    flex: 0 0 auto;
    margin: 32px 0 48px;
  }

  .accounts {
    ${flex('column', 'flex-start', 'stretch')};
    gap: 8px;
    margin-top: 32px;
  }

  .add-new-account-btn {
    margin-top: 28px;
    align-self: center;

    .custom-text-inner {
      font-weight: 500;

      @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
        font-weight: 600;
      }
    }
  }
`;

export const SEditAccountNameSheet = styled(CustomSheet)`
  .container {
    ${flex('column', 'flex-start', 'stretch')};
  }

  .header {
    ${flex('row', 'flex-start')}
  }

  .content:not(.external-account .content) {
    ${flex('column', 'flex-start', 'stretch')}
    flex: 0 0 auto;
    margin: 32px 0 48px;
  }

  .external-account {
    background: ${getColor('cream50')};
    border: none;
  }
`;
