import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/general/Typography';
import { SContentLayout } from 'views/Account/AccountInformation/Modals/closingAccount/GoalsHavingIssuesModal/GoalsHavingIssuesModal.styles';
import { Button } from 'components/theme/Button/Button';
import { EAccountType } from 'store/user/accounts/accounts.types';
import { IModalCommonProps } from 'views/Account/Summary/InternalTransfer/InternalTransferPage.types';

export const NonCashAccountClosedModal: React.FC<IModalCommonProps> = ({ open, onClose, accountType }) => {
  const { t } = useTranslation('accountInformation');

  return (
    <CustomModal open={open} onClose={onClose} modalName="nonCashAccountClosedModal" topPosition="26%" closeIconColor="charcoal70" className="accountClosedModal">
      <SContentLayout>
        <Title size="M" fontWeight="M" marginBottom={30} marginTop={15}>
          {accountType === EAccountType.SAVE ? t('accountInformation.Goals.YourGoalsAccountIsNowClosed') : t('accountInformation.Needs.YourNeedsAccountIsNowClosed')}
        </Title>

        <Button preset="blue-filled" size="xl" onClick={onClose}>
          {t('accountInformation.GoToHomeScreen')}
        </Button>
      </SContentLayout>
    </CustomModal>
  );
};
