export const SORT_VARIABLES = [
  {
    title: 'notificationsCenter.NewestToOldest',
    id: '0',
  },
  {
    title: 'notificationsCenter.OldestToNewest',
    id: '1',
  },
];
