import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpToHeight } from 'utils/helpers/styleHelpers';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SCustomModal = styled(CustomModal)`
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }

  .sutton-disclaimer-note {
    padding: 0px 24px 24px;
    ${mediaFrom(
      'tablet',
      css`
        background: ${getColor('charcoal5')};
        border-radius: 0 0 12px 12px;
      `
    )}
  }

  ${mediaUpToHeight(
    800,
    css`
      .sutton-disclaimer-note {
        padding: 0 12px 12px;
        .body-text .custom-text-inner {
          font-size: 10px;
        }
      }
    `
  )}
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  & .mobile-checking-body {
    margin-top: 10px;
  }

  & .coming-soon {
    background: ${getColor('bluePurple40')};
    display: flex;
    align-self: flex-start;
    padding: 10px 24px;
    border-radius: 12px;
    ${mediaFrom(
      'tablet',
      css`
        background: ${getColor('purple10')};
        padding: 5px 10px;
      `
    )}
  }

  & .list-item {
    display: flex;
    position: relative;
    padding-left: 10px;
    padding-right: 13px;

    .first-description {
      left: 7px;
    }

    &-text {
      position: absolute;
      left: 5px;
      top: 2px;
    }
  }

  img {
    height: 160px;
  }

  button.btn {
    margin-top: 24px;
    ${mediaFrom(
      576,
      css`
        margin-top: 32px;
      `
    )}
  }

  ${mediaUpToHeight(
    800,
    css`
      padding: 12px;

      img {
        height: 70px;
        width: 70px;
      }
      .custom-text-inner {
        font-size: 12px;
      }

      .mobile-checking-body {
        .body-text {
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .list-item {
          padding-right: 0;
          .body-text {
            margin: 0;
          }
        }
      }

      button.btn {
        margin-top: 8px;
      }

      .coming-soon {
        padding: 4px 16px;
        .custom-text-inner {
          font-size: 10px;
        }
      }
    `
  )}
`;
