import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 22px;

    .space-between {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .flex-start {
        display: flex;
        justify-content: flex-start;
      }

      .flex-end {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    min-width: 149px !important;
    max-width: 180px !important;
    height: 52px;
    padding: 14px 32px;

    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
  }

  .request {
    position: relative;

    .info-tips {
      position: absolute;
      border: 2px solid ${getColor('grey3')};
      bottom: calc(100% + 8px);
      left: calc(100% - 16px);
      margin: 0;
      width: 328px;
      z-index: 10001;

      &.hide {
        display: none;
      }
      &.show {
        display: block;
      }
    }
  }
`;
