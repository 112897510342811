import React from 'react';
import { Title } from 'components/general/Typography';
import { ListItem } from 'views/PercapitaPay/Enroll/ListItem/ListItem';
import { SContainer } from './Card.styles';

type TCardProp = {
  title: string;
  list: React.ReactNode[];
};

export const Card: React.FC<TCardProp> = ({ title, list }) => (
  <SContainer>
    <div className="title-container">
      <Title fontWeight="SB" size="sL">
        {title}
      </Title>
    </div>

    <div className="list-container">
      {list.map((cardData: React.ReactNode) => (
        <ListItem icon="checked" size="smallest" color="charcoal70">
          {cardData}
        </ListItem>
      ))}
    </div>
  </SContainer>
);
