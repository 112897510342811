import React, { useState } from 'react';
import { IMenuItemData } from 'vars/types/menu.types';
import { BodyText } from 'components/general/Typography';
import { Collapse } from 'antd';
import { SMenuCollapsible, SMenuContainer, SMenuGroupContainer } from 'components/navigation/MainMenu/DrawerMenu.styles';
import { MenuItem } from './MenuItem';

interface IMenuGroup {
  title: string;
  items: IMenuItemData[];
  icon?: string;
  isProfileSettings?: boolean;
  showCardHub?: () => void;
  openChat?: () => void;
}

const { Panel } = Collapse;

export const MenuGroup: React.FC<IMenuGroup> = ({ title, items, icon = '', showCardHub, openChat, isProfileSettings = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (expanded: string | string[]) => {
    setIsExpanded(expanded.includes(title));
  };

  if (isProfileSettings)
    return (
      <SMenuCollapsible ghost onChange={handleToggle}>
        <Panel header={<MenuItem title={title} icon={icon} arrowIcon={isExpanded ? 'chevronDown' : 'chevronRight'} />} showArrow={false} key={title}>
          <SMenuGroupContainer>
            {items.map((item) => (
              <MenuItem title={item.text} icon={item.icon} path={item.path} key={item.path} action={item.action} showCardHub={showCardHub} openChat={openChat} isProfileSettings />
            ))}
          </SMenuGroupContainer>
        </Panel>
      </SMenuCollapsible>
    );

  return (
    <>
      <BodyText className="menu-group-title" font="Poppins" textType="bodyText" fontWeight="SB" justifyContent="start" textAlign="start" size="M" color="charcoal" marginBottom={18}>
        {title}
      </BodyText>

      <SMenuContainer className="menu-item-container">
        {items.map((item) => (
          <MenuItem className="menu-item" title={item.text} icon={item.icon} path={item.path} key={item.path} action={item.action} showCardHub={showCardHub} openChat={openChat} />
        ))}
      </SMenuContainer>
    </>
  );
};
