export enum ConsentType {
  LOGIN = 'Login',
  ACCOUNT_OPENING = 'AccountOpening',
  EWA_ENROLL = 'EWAEnroll',
  PERCAPITA = 'Percapita',
  INGO = 'Ingo',
  OTHERS = 'Others',
  SUTTON = 'Sutton',
  ACCOUNT_OPENING_DETAILS = 'AccountOpeningDetails',
  SECOND_ACCOUNT_OPENING_DETAILS = 'SecondAccountOpeningDetails',
  JOINT_ACCOUNT_HOLDER = 'JointAccountHolder',
  REFERRAL = 'Referral',
  READY_REMIT = 'ReadyRemit',
}

export const ConsentId = {
  JOINT_ACCOUNT_DISCLAIMER: 'f054c2da-7c09-4c25-97af-737368aa78fa',
  ESIGN_CONSENT: '2872cb73-3691-46b8-a1ab-cfd4d08b319f',
  DEPOSIT_ACCOUNT_AGREEMENT: 'cc31e0aa-5280-43b6-b064-5212e2cba93d',
  PERCAPITA_TERMS_OF_USE: '630f15fc-37f3-43ec-a0c3-5b1a7b014870',
  PERCAPITA_PRIVACY_POLICY: '5aa46a7a-299d-4c93-bd78-36878917cc1b',
  SECONDARY_DEPOSIT_ACCOUNT_AGREEMENT: '53562abc-cc46-4589-8e0e-8fc9a13eee53',
  SECONDARY_PERCAPITA_TERMS_OF_USE: '630f15fc-37f3-43ec-a0c3-5b1a7b014870',
  SECONDARY_PERCAPITA_PRIVACY_POLICY: '5aa46a7a-299d-4c93-bd78-36878917cc1b',
  READY_REMIT_TERMS_OF_USE: 'a4467d52-cb5d-48ef-b478-0286ada2e0b0',
};
