import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Form } from 'antd';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { useToggle } from 'utils/hooks/useToggle';
import { Icon } from 'components/general/Icon/Icon';
import { SLayout } from 'views/Profile/ProfilePage/ChangeAddressPage/ChangeAddressPage.styles';
import { FileItem } from 'views/Documents/UploadDocument/FileItem/FileItem';
import { IUploadedDocumentItem } from 'views/Documents/UploadDocument/UploadDocumentPage';
import { UploadSheet } from 'views/Documents/UploadDocument/DocumentSheets/UploadSheet';
import { useLazyGetDocumentRequestsQuery, useUploadRequestedDocumentsMutation } from 'store/user/users.api';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setHeaderTitle } from 'store/ui.slice';
import { handleError } from 'utils/helpers/errorHelper';
import { ROUTES } from 'vars/const/ROUTES';
import { Loader } from 'components/general/Loader/Loader';
import { SelectSheet } from 'components/general/SelectSheet/SelectSheet';
import { Footer } from 'views/Profile/ProfilePage/Footer/Footer';
import { BaseSelect } from 'components/general/BaseSelect/BaseSelect';
import { UploadLaterModal } from './UploadLaterModal';
import { SAddressDocumentFieldset, SBody } from './UploadDocumentsPage.styles';

interface IRequestedDocumentItem {
  id: string;
  description: string;
  name: string;
}

interface IRequestedDocumentItem {
  id: string;
  description: string;
  name: string;
}

const documentTypeSelectOptions: any = () => {
  return {
    ID: [
      {
        label: i18next.t(`changeAddress.DriversLicense`, { ns: 'documents' }),
        value: "Driver's License",
      },
      {
        label: i18next.t(`changeAddress.Passport`, { ns: 'documents' }),
        value: 'Passport',
      },
      {
        label: i18next.t(`changeAddress.StateID`, { ns: 'documents' }),
        value: 'State ID',
      },
      {
        label: i18next.t(`changeAddress.PermanentResidence`, { ns: 'documents' }),
        value: 'Permanent Residence ID Card',
      },
    ],
    'Proof of Address Change': [
      {
        label: i18next.t(`changeAddress.UtilityBill`),
        value: 'Utility Bill',
      },
      {
        label: i18next.t(`changeAddress.SocialSecurityCard`),
        value: 'Social Security or ITIN Card',
      },
      {
        label: i18next.t(`changeAddress.LeaseRentalAgreement`),
        value: 'Lease/Rental Agreement',
      },
      {
        label: i18next.t(`changeAddress.MortgageStatement`),
        value: 'Mortgage Statement',
      },
      {
        label: i18next.t(`changeAddress.SchoolAdmissionsTranscript`),
        value: 'School Admissions/Transcript',
      },
      {
        label: i18next.t(`changeAddress.Paystub`),
        value: 'Paystub',
      },
      {
        label: i18next.t(`changeAddress.Bank Statement`),
        value: 'Bank Statement',
      },
      {
        label: i18next.t(`changeAddress.InsuranceStatement`),
        value: 'Auto or Homeowner’s Insurance Statement',
      },
      {
        label: i18next.t(`changeAddress.MotorVehicleRegistration`),
        value: 'Motor Vehicle Registration',
      },
    ],
  };
};

export const UploadDocumentsPage = () => {
  const { t } = useTranslation('documents');
  const location = useLocation();
  const navigate = useNavigate();
  const { isDesktopSize } = useDeviceDimension();
  const currentDocumentRequestId = location.state?.documentRequestId || null;
  const uploadSheet = useToggle(false);
  const uploadLaterModal = useToggle(false);
  const dispatch = useDispatch();
  const [uploadRequestedDocumentsAPI, uploadRequestedDocumentsAPIResult] = useUploadRequestedDocumentsMutation();
  const [getRequestDocumentsAPI, { isFetching, currentData, isSuccess, isError, error }] = useLazyGetDocumentRequestsQuery();

  const [uploadedDocuments, setUploadedDocuments] = useState<IUploadedDocumentItem[]>([]);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);
  const [documentRequestId, setDocumentRequestId] = useState('');
  const [requestedDocuments, setRequestedDocuments] = useState<IRequestedDocumentItem[]>([]);

  const handleUploadFieldClick = (index: number) => {
    setSelectedFieldIndex(index);
    uploadSheet.show();
  };

  const handleBack = () => {
    navigate(ROUTES.profile.path);
  };

  const handleFileUploaded = (file: File) => {
    uploadSheet.hide();

    const uploadedFileItem = {
      file,
      index: selectedFieldIndex,
      documentTypeId: requestedDocuments[selectedFieldIndex]?.id,
    };
    setUploadedDocuments((prevUploadedDocuments) => [...prevUploadedDocuments, uploadedFileItem]);
  };

  const handleDeleteFile = (fileItem: IUploadedDocumentItem) => {
    const filteredDocuments = uploadedDocuments.filter((item) => item.file.uid !== fileItem.file.uid);
    setUploadedDocuments(filteredDocuments);
  };

  const handleContinue = () => {
    uploadedDocuments.forEach((item: IUploadedDocumentItem) => {
      const fileData = new FormData();
      fileData.append('file', item.file);

      const requestBody = {
        fileData,
        documentRequestId,
        documentTypeId: item.documentTypeId,
      };

      uploadRequestedDocumentsAPI(requestBody);
    });
  };

  useEffect(() => {
    if (isSuccess && currentData?.length) {
      const extractedData = currentDocumentRequestId ? currentData.find((item: any) => item.id === currentDocumentRequestId) : currentData[0];

      dispatch(setHeaderTitle(extractedData?.requestType?.windowTitle));
      setRequestedDocuments(extractedData?.requestType.requestedDocuments);
      setDocumentRequestId(extractedData?.id);
    }
    if (isError) handleError(error);
  }, [isError, isSuccess, currentData, error]);

  useEffect(() => {
    getRequestDocumentsAPI({});
  }, [getRequestDocumentsAPI]);

  useEffect(() => {
    if (uploadRequestedDocumentsAPIResult.isSuccess) navigate(ROUTES.uploadDocumentSuccess.path);
    if (uploadRequestedDocumentsAPIResult.isError) handleError(uploadRequestedDocumentsAPIResult.error);
  }, [uploadRequestedDocumentsAPIResult, navigate]);

  useEffect(() => {
    uploadSheet.hide();
  }, []);

  if (isFetching || uploadRequestedDocumentsAPIResult.isLoading) return <Loader />;

  return (
    <SLayout>
      <div className="flex header">
        <Title size="M">{t(`uploadDocuments.Title`)}</Title>
        {isDesktopSize ? (
          <Button size="middleAlt" onClick={handleBack}>
            {t(`changeAddress.ExitAddressChanging`)}
          </Button>
        ) : (
          <Icon name="exitFlow" marginRight={4} cursorPointer onClick={handleBack} />
        )}
      </div>
      <SBody>
        <div className="body">
          {isDesktopSize ? (
            <Title size="T" color="charcoal" fontWeight="SB" marginBottom={24} lineHeight={1.6}>
              {t(`uploadDocuments.Description`)}
            </Title>
          ) : (
            <BodyText size="N" color="charcoal70" marginTop={16} lineHeight="20px">
              {t(`uploadDocuments.Description`)}
            </BodyText>
          )}

          <Form onFinish={handleContinue}>
            <CustomCard padding="8px 0">
              {currentData?.length > 0 && (
                <CustomCard borderRadius={20} className="upload-card" marginTop={8}>
                  {requestedDocuments
                    ?.filter((item) => item.name !== 'Identifying document')
                    ?.map((item, index) => (
                      <SAddressDocumentFieldset>
                        <BodyText textType="bodyText" font="DM Sans" size="N" fontWeight="B">
                          {item.name === 'ID' ? t(`changeAddress.UnexpiredGovernment`) : t('changeAddress.ProofAddressDocument')}
                        </BodyText>
                        {isDesktopSize ? (
                          <BaseSelect
                            options={documentTypeSelectOptions()[item.name]}
                            placeholder={t('changeAddress.Select Document Type')}
                            menuPlacement="auto"
                            isWebStyle
                            styles={{ dropdownIndicator: () => ({ display: 'flex' }) }}
                          />
                        ) : (
                          <SelectSheet placeholder={t('changeAddress.Select Document Type')} options={documentTypeSelectOptions()[item.name]} />
                        )}

                        <FileItem uploadedDocuments={uploadedDocuments} deleteFile={handleDeleteFile} uploadSelected={handleUploadFieldClick} index={index} key={item.id} isMultiple />
                      </SAddressDocumentFieldset>
                    ))}
                </CustomCard>
              )}
            </CustomCard>

            {!isDesktopSize && (
              <Button marginBottom={24} preset="blue-outlined" type="submit" marginTop={48}>
                {t('documents.Continue')}
              </Button>
            )}
          </Form>

          {!isDesktopSize && (
            <>
              <BodyText textType="bodyText" font="Poppins" size="N" fontWeight="M" justifyContent="center" color="blue" marginBottom={24} cursorPointer onClick={() => uploadLaterModal.show()}>
                {t('documents.UploadLater')}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" marginTop={25} lineHeight={1.3} marginBottom={24}>
                {t(`documents.PercapitaGroupIncIsADigitalCompanyThatProvidesAccess`)}
              </BodyText>
            </>
          )}
        </div>
      </SBody>
      {isDesktopSize && (
        <>
          <Footer onClick={handleContinue} onBack={() => uploadLaterModal.show()} zIndex={0} />
          <BodyText
            textType="bodyText"
            color="charcoal70"
            size="T"
            fontWeight="R"
            marginTop={64}
            lineHeight={1.3}
            marginBottom={24}
            paddingLeft={98}
            paddingTop={24}
            paddingRight={98}
            paddingBottom={35}
          >
            {t(`documents.PercapitaGroupIncIsADigitalCompanyThatProvidesAccess`)}
          </BodyText>
        </>
      )}

      <UploadSheet fileUploaded={handleFileUploaded} show={uploadSheet.isActive} onClose={uploadSheet.hide} multiple />

      <UploadLaterModal open={uploadLaterModal.isActive} onClose={uploadLaterModal.hide} />
    </SLayout>
  );
};
