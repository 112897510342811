import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IThirdParty } from 'store/user/accounts/accounts.types';
import { getExternalAccountSubtitle } from 'utils/helpers/accounts/accountsHelper';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText } from 'components/general/Typography';
import { TextButton } from 'components/general/TextButton/TextButton';
import { SBodyText } from 'components/general/Accounts/AccountSelect/AccountSelect.styles';
import { SExternalAccount } from './ExternalAccount.styles';

type IExternalAccountProps = {
  account: IThirdParty;
  isSelected?: boolean;
  isDisabled?: boolean;
  isDesktop?: boolean;
  isFrom?: boolean;
  onClick?: (account: IThirdParty) => void;
  onRemove?: (account: IThirdParty) => void;
  onEdit?: (account: IThirdParty) => void;
};

export const ExternalAccount = ({ onClick, isDesktop, isFrom, account, isSelected, isDisabled = false, onRemove, onEdit }: IExternalAccountProps) => {
  const { t } = useTranslation(['moveMoney'], { keyPrefix: 'manageExternalAccounts' });
  const isCardType = account.thirdPartyAccountType === 'CA';
  const classNameStr = clsx('external-account', {
    selected: (isSelected && !isDisabled) || (isDesktop && isSelected),
    disabled: isDisabled,
  });

  const handleClick = () => {
    if (!isDisabled) {
      onClick?.(account);
    }
  };

  const accountInfo = (
    <div className="external-account__content">
      <Icon name={isCardType ? 'creditCard' : 'bank'} color={isDesktop && isDisabled && !isSelected ? 'charcoal50' : 'orange'} marginLeft={5} />
      <div className="info">
        <BodyText textType="bodyText" color={isDisabled ? 'charcoal50' : 'charcoal'} size="N" fontWeight="B" marginLeft={10}>
          {account.externalDisplayAccountName}
        </BodyText>
        <BodyText textType="bodyText" color={isDisabled ? 'charcoal50' : 'charcoal'} size="N" fontWeight="R" marginLeft={10} marginTop={2}>
          {getExternalAccountSubtitle(account)}
        </BodyText>
      </div>
      {isDesktop && isDisabled && isSelected && (
        <SBodyText textType="bodyText" color="blue" size="N" fontWeight="B" nowrap>
          {t(isFrom ? 'SelectedTransferTo' : 'SelectedTransferFrom')}
        </SBodyText>
      )}
      <Icon name="arrowRight" color="charcoal" size="smallest" className="arrow-icon" />
    </div>
  );

  return (
    <SExternalAccount className={classNameStr} onClick={handleClick} isDesktop={isDesktop}>
      {accountInfo}
      {(onEdit || onRemove) && (
        <div className="external-account__buttons">
          {onEdit && (
            <TextButton size="S" onClick={() => onEdit(account)}>
              {t('EditName')}
            </TextButton>
          )}
          {onRemove && (
            <TextButton size="S" onClick={() => onRemove(account)}>
              {t('Remove')}
            </TextButton>
          )}
        </div>
      )}
    </SExternalAccount>
  );
};
