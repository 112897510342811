import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import styled, { css } from 'styled-components';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCustomModal = styled(CustomModal)`
  .button-panel {
    display: flex;
    flex-direction: column;
  }

  ${mediaUpTo(
    'tablet',
    css`
      top: 12% !important;

      .button-panel {
        button {
          font-size: 16px;
        }
      }
    `
  )};

  ${mediaFrom(
    'tablet',
    css`
      top: 0 !important;
      width: auto !important;
      max-width: 648px;
      margin: 0 24px;

      .ant-modal-content {
        margin: 0;
        padding: 32px 24px;

        .title {
          .custom-title-text {
            font-size: 32px;
            line-height: 36px;
          }
        }

        .button-panel {
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          button {
            width: auto;
            font-weight: 600;
          }

          button.go-to-home {
            order: 1;
            margin: 0 !important;
          }

          button.submit {
            order: 0;
            margin-right: 8px;
          }
        }
      }
    `
  )};
`;
