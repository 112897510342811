import styled, { css } from 'styled-components';
import { mediaBetween } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${mediaBetween(
    'tablet',
    635,
    css`
      .custom-title-text {
        font-size: 18px;
      }
    `
  )}
`;
