import React from 'react';
import { cantOpenAccount } from 'assets/images';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { SCustomModal } from './CancelAddressChangeModal.styles';

type TModalProps = { open: boolean; onClose: () => void; handleCancelRequest: () => void };

export const CancelAddressChangeModal: React.FC<TModalProps> = ({ open, onClose, handleCancelRequest }) => {
  const { t } = useTranslation('profile');

  return (
    <SCustomModal open={open} onClose={onClose} modalName="cancelAddressChange">
      <img src={cantOpenAccount} alt="cant opent account" className="main-image" />

      <Title fontWeight="M" className="title">
        {t('profile.AreYouSureYouWantToCancel')}
      </Title>

      <div className="button-panel">
        <Button onClick={onClose}>{t('profile.NoKeepMyRequest')}</Button>

        <Button preset="blue-filled" onClick={handleCancelRequest}>
          {t('profile.YesIWantToCancel')}
        </Button>
      </div>
    </SCustomModal>
  );
};
