import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { SInviteFooter } from './InviteFooter.styles';

interface IInviteFooter {
  isProspect: string | boolean;
  isClient: string | boolean;
  onDecline: () => void;
  onJoinLater: () => void;
  onContinue: () => void;
}

export const InviteFooter: React.FC<IInviteFooter> = ({ isProspect, isClient, onDecline, onJoinLater, onContinue }) => {
  const { t } = useTranslation('prepPage');

  return (
    <SInviteFooter>
      {isClient && (
        <>
          <BodyText font="Poppins" textType="bodyText" fontWeight="SB" size="N" color="blue70" onClick={onDecline} cursorPointer>
            {t(`prepPage.Decline`)}
          </BodyText>

          <div className="buttons-wrapper">
            <Button onClick={onJoinLater} className="later-btn">
              {t(`prepPage.JoinLater`)}
            </Button>
            <Button preset="blue-filled" onClick={onContinue} className="join-btn">
              {t(`prepPage.BeginOpening`)}
            </Button>
          </div>
        </>
      )}
      {isProspect && (
        <div className="button-center">
          <Button preset="blue-filled" onClick={onContinue}>
            {t(`prepPage.Continue`)}
          </Button>
        </div>
      )}
    </SInviteFooter>
  );
};
