import { useCallback } from 'react';

export const useDevLogger = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  const log = useCallback(
    (...args: any[]) => {
      if (isDevelopment) {
        console.log(...args);
      }
    },
    [isDevelopment]
  );

  const warn = useCallback(
    (...args: any[]) => {
      if (isDevelopment) {
        console.warn(...args);
      }
    },
    [isDevelopment]
  );

  const error = useCallback(
    (...args: any[]) => {
      if (isDevelopment) {
        console.error(...args);
      }
    },
    [isDevelopment]
  );

  return { log, warn, error };
};
