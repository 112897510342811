import styled, { css } from 'styled-components';
import { getProp, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';

export const SAddAccountItem = styled(CustomCard)`
  padding: 16px 24px 16px 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;

  ${mediaUpTo(
    400,
    css`
      padding: 15px 20px;
    `
  )}
  ${mediaFrom(
    'tablet',
    css`
      padding: 24px 20px;
      border: 2px solid #e5e5e5;
    `
  )}
`;

export const SCustomRow = styled.div<{
  padding: number;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${getProp('padding')}px;
  width: 100%;

  .move-money-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  ${mediaUpTo(
    400,
    css`
      .move-money-card-subtitle .custom-text-inner {
        font-size: 12px;
      }
    `
  )}

  ${mediaUpTo(
    'mobile',
    css`
      .move-money-card-subtitle {
        margin-right: 15px;
        & .custom-text-inner {
          font-size: 11px;
        }
      }
    `
  )}
`;

export const SCustomButton = styled(Button)`
  padding: 0;
  border: none;
  justify-content: left;
  width: 100%;
  > div {
    width: 100%;
  }
`;
