import styled, { css } from 'styled-components';
import { getColor, mediaUpTo } from 'utils/helpers/styleHelpers';

export const STitleHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 42px;
  padding: 30px 48px 58px;
  border-bottom: 1px solid ${getColor('creamS5')};

  ${mediaUpTo(
    'tablet',
    css`
      display: none;
    `
  )}
`;
