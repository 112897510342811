import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { AddNewSourceModal } from 'components/general/Modals/AddNewSourceModal/AddNewSourceModal';
import { Loader } from 'components/general/Loader/Loader';
import { Title, BodyText } from 'components/general/Typography';
import { ingoApi, useLazyGetIframeUrlQuery } from 'store/ingo/ingo.api';
import { INGO_INSTANT_PAYMENTS } from 'vars/const/externalUrls';
import { useScript } from 'utils/hooks/useScript';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { initWebPlugin } from 'utils/helpers/ingo/ingoHelper';
import { useToggle } from 'utils/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { useTransfers } from 'utils/hooks/useTransfers';
import { SIFrame, SLayout } from './AddNewSourceIframe.style';

interface IAddNewSourceIframeLocation {
  state?: {
    fundingDestination: 'ach' | 'debit';
    altAddressId?: string;
  };
}

export const AddNewSourceIframe = () => {
  const { t } = useTranslation('moveMoney');
  const { currentTransfer, saveCurrentTransfer } = useTransfers();
  useScript(INGO_INSTANT_PAYMENTS);
  const location = useLocation() as IAddNewSourceIframeLocation;
  const [getIframeUrl, getIframeUrlResult] = useLazyGetIframeUrlQuery();
  const [checkAltAddress, checkAltAddressResult] = ingoApi.useCheckAltAddressMutation();
  const [connectionAttempts, setConnectionAttempts] = useState(0);
  const [modalIsSuccess, setModalIsSuccess] = useState(false);
  const { track } = useAnalytics();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { fundingDestination } = location?.state ?? {};
  const loader = useToggle(false);
  const addNewSourceModal = useToggle(false);
  const isLoading = loader.isActive || getIframeUrlResult.isLoading || checkAltAddressResult.isLoading;
  const checkRetryLimit = 5;

  const executeModal = (isSuccess: boolean) => {
    setModalIsSuccess(isSuccess);
    addNewSourceModal.show();
  };

  const checkAddress = (tracer_token: string) => {
    let retryCount = 0;
    loader.show();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheck = async () => {
      try {
        const checkAltAddressRes = await checkAltAddress(tracer_token).unwrap();
        executeModal(checkAltAddressRes);
        loader.hide();
      } catch {
        if (retryCount < checkRetryLimit) {
          retryCount++;
          setTimeout(() => {
            handleCheck();
          }, 1000);
        } else {
          executeModal(false);
          loader.hide();
        }
      }
    };

    handleCheck();
  };

  const onSuccess = async (event: any) => {
    if (currentTransfer?.fromId === 'WAITING_TO_ADD' || currentTransfer?.toId === 'WAITING_TO_ADD') {
      saveCurrentTransfer({
        ...currentTransfer,
        fromId: currentTransfer?.fromId === 'WAITING_TO_ADD' ? 'JUST_ADDED' : currentTransfer?.fromId,
        toId: currentTransfer?.toId === 'WAITING_TO_ADD' ? 'JUST_ADDED' : currentTransfer?.toId,
      });
    }
    checkAddress(event.tracer_token);
  };

  const onFailure = async (event: any) => {
    track('ingo_error', JSON.stringify(event));
    executeModal(false);
  };

  const executeWebPlugin = async () => {
    const iframeUrl = await getIframeUrl().unwrap();
    if (iframeUrl) {
      initWebPlugin({
        iframeElement: iframeRef.current,
        iframeUrl,
        fundingDestination: fundingDestination || 'ach',
        onCloseHandler: onSuccess,
        onFailureHandler: onFailure,
        IngoInstantPayments: window?.IngoInstantPayments,
      });
    }
  };

  useEffect(() => {
    if (getIframeUrlResult.isError) {
      executeModal(false);
    }
  }, [getIframeUrlResult.isError]);

  useEffect(() => {
    if (window?.IngoInstantPayments) {
      executeWebPlugin();
    } else {
      setConnectionAttempts(connectionAttempts + 1);
    }
  }, [connectionAttempts]);

  return (
    <SLayout>
      {isLoading && <Loader />}
      <Title size="S" fontWeight="SB" color="charcoal" marginBottom={15} marginTop={35} marginLeft={15} marginRight={15}>
        {fundingDestination === 'ach' ? t('addBankAccountPage.AddANewBankAccount') : t('addBankAccountPage.AddANewDebitCard')}
      </Title>
      <BodyText textType="bodyText" color="charcoal70" fontWeight="R" font="DM Sans" size="M" lineHeight={1.6} marginTop={8} marginBottom={12} marginLeft={15} marginRight={15}>
        {t('addBankAccountPage.AddANewBankAccountDescription')}
      </BodyText>
      <SIFrame id="ingo-iframe" ref={iframeRef} style={{ visibility: loader.isActive ? 'hidden' : 'visible' }} />
      <AddNewSourceModal open={addNewSourceModal.isActive} onClose={addNewSourceModal.hide} isSuccess={modalIsSuccess} navPath={ROUTES.transfers.path} />
    </SLayout>
  );
};
