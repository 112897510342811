import React, { useState } from 'react';
import { Form } from 'antd';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { minLengthRule } from 'utils/helpers/validationRules';
import { SError, SSmsCodeWrapper } from './SmsCodeForm.styles';
import { ISmsFormProps, ISmsFormValues } from './SmsCodeForm.types';
import { OTPCodeInput } from './OTPCodeInput/OTPCodeInput';

export const SmsCodeForm = ({
  handleCompletion,
  onCompletion,
  checkError,
  generateError,
  size = 5,
  boxSize = '56px',
  separatorsAfter = [],
  isWrongCode = false,
  isDisabledFocusOnStart = false,
  placeholder = '',
}: ISmsFormProps) => {
  const [form] = Form.useForm();
  const currentError = checkError || generateError;
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { isDesktopSize } = useDeviceDimension();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    const smsCode = values.smsCode;
    const isCompleted = smsCode.length === size;
    const hasErrors = !isCompleted || form.getFieldsError().some(({ errors }) => errors.length);
    if (!hasErrors && isCompleted) {
      onCompletion?.(false);
      handleCompletion(smsCode);
      setIsSuccess(true);
    } else {
      onCompletion?.(true);
      setIsSuccess(false);
    }
  };

  const onFinish = (values: ISmsFormValues) => {
    handleCompletion(values.smsCode);
  };

  return (
    <Form className="sms-code-form" autoComplete="off" form={form} requiredMark={false} initialValues={{ smsCode: '' }} onFieldsChange={handleFormChange} onFinish={onFinish}>
      <SSmsCodeWrapper className="sms-code-wrapper">
        <Form.Item name="smsCode" rules={[minLengthRule(size)]} noStyle required validateTrigger={['onChange']}>
          <OTPCodeInput
            size={size}
            placeholder={placeholder || (isDesktopSize ? '—' : '')}
            isWrongCode={isWrongCode}
            isCompleted={isSuccess}
            hasSubmitError={Boolean(currentError)}
            isDisabledFocusOnStart={isDisabledFocusOnStart}
            boxSize={boxSize}
            separatorsAfter={separatorsAfter}
          />
        </Form.Item>
        {currentError && (
          <SError textColor="red" size="big" textAlign="center">
            {currentError}
          </SError>
        )}
      </SSmsCodeWrapper>
    </Form>
  );
};
