import styled, { css } from 'styled-components';
import { mediaUpTo, mediaBetween, mediaFrom, SCREEN_SIZE } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  width: 100%;
  ${mediaBetween(
    SCREEN_SIZE.tablet + 1,
    SCREEN_SIZE.desktop - 1,
    css`
      margin: 0px 20px;
    `
  )};

  ${mediaFrom(
    SCREEN_SIZE.desktop,
    css`
      width: 50%;
    `
  )};

  .balances-save-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    button {
      padding: 14px 24px !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      max-width: 100px !important;
      height: 52px !important;
    }
  }

  .settings-save-btn {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  & .settings-available {
    display: flex;
    margin-left: 15px;
    position: absolute;
    right: 0;

    ${mediaUpTo(
      415,
      css`
        flex-direction: column;
        align-items: flex-end;
      `
    )}

    &-balance {
      margin-left: 10px;

      ${mediaUpTo(
        415,
        css`
          margin-top: 15px;
        `
      )}
    }
  }

  & .settings-available {
    display: flex;
    margin-left: 15px;
    position: absolute;
    right: 0;

    ${mediaUpTo(
      415,
      css`
        flex-direction: column;
        align-items: flex-end;
      `
    )}

    &-balance {
      margin-left: 10px;

      ${mediaUpTo(
        415,
        css`
          margin-top: 15px;
        `
      )}
    }
  }

  .accounts-switcher {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    // width: 100%;
    // position: relative;

    & > div {
      max-width: calc(100vw - 84px);
      position: relative;
      display: flex;
      gap: 8px;
      overflow: hidden;
      overflow-x: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .chip {
      padding: 8px 12px;
      // max-width: 167px;
      font-family: DM Sans;
      font-weight: 700;
      justify-content: flex-start;

      .acc-name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon {
        min-width: 24px;
      }
    }
  }
  .notifications-wrapper {
    margin-bottom: 32px;
    border: none;

    ${mediaUpTo(
      'tablet',
      css`
        border: 2px solid #f5f4f4;
      `
    )}
  }
`;

export const NotificationsTabLayout = styled.div`
  ${mediaUpTo(
    'tablet',
    css`
      margin: 0px 18px;
    `
  )};

  .settings {
    &-outer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 34px;
      position: relative;

      .settings-inner:last-child {
        & .body-text:first-child {
          padding-right: 35px;
        }
      }
    }

    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-checkbox {
      display: inline-flex;
      & .body-text {
        padding-right: 24px;
        margin-bottom: 10px;
      }
    }

    &-available-balance {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
    }

    &-alert-source-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &-security-outer {
      display: flex;
      flex-direction: column;
    }

    &-security-inner {
      display: flex;
      align-items: flex-start;
    }
  }

  ${mediaUpTo(
    'mobile',
    css`
      .title .custom-title-text {
        font-size: 14px;
      }
      .page-header .custom-title-text {
        font-size: 20px;
      }
    `
  )}
`;
