import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: flex-start;
  align-items: center;
  background: ${getColor('white')};
  border-radius: 20px;
  margin-top: 32px;

  ${mediaFrom(
    'desktop',
    css`
      min-height: 465px;
      margin-top: 0;
    `
  )}

  .web-title {
    font-size: 20px;
    color: ${getColor('charcoal')};
    font-weight: 600;
    margin-bottom: 20px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .mobile-title {
    font-size: 16px;
    color: ${getColor('charcoal')};
    font-weight: 700;
    width: 100%;
    margin-bottom: 24px;
    font-family: DM Sans;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${mediaUpTo(
      'mobile',
      css`
        font-size: 12px !important;
        line-height: 16px !important;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .web-items {
    width: 100%;

    ${mediaUpTo(
      'mobile',
      css`
        font-size: 12px;
        line-height: 16px;
      `
    )}

    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .mobile-items {
    width: 100%;

    ${mediaFrom(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .web-truv-description {
    ${mediaUpTo(
      'desktop',
      css`
        display: none;
      `
    )}
  }

  .find-employer-btn {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;

    ${mediaFrom(
      'desktop',
      css`
        width: 194px;
        height: 52px;
        padding: 14px 32px;
        margin-top: 10px;

        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
      `
    )}
  }
`;
