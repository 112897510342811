import styled from 'styled-components';
import { getColor, MEDIA_SIZE } from 'utils/helpers/styleHelpers';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Button } from 'components/theme/Button/Button';

export const SCustomSheet = styled(CustomSheet)`
  ::backdrop {
    background-color: #000000e5;
  }
`;

export const SButton = styled(Button)`
  padding: 10px 24px !important;
  font-size: 16px !important;
  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    max-width: 100px;
  }
`;

export const SDisclosure = styled.div`
  margin: 32px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${getColor('charcoal')};
`;

export const SCurrentAccount = styled.div`
  padding: 24px;
  background-color: ${getColor('cream70')};
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 32px;
  margin-top: 8px;

  .icon-wrapper {
    margin-right: 12px;
    padding: 0px 3px;
    border-radius: 3px;
    border: 2px solid ${getColor('orange')};
  }
`;

export const SAccountName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const SAccountCaption = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${getColor('charcoal70')};
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 8px;
    color: ${getColor('charcoal')};
  }
`;
