import { Icon } from 'components/general/Icon/Icon';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Button } from 'components/theme/Button/Button';
import styled, { css } from 'styled-components';
import { getColor, getProp, ifProp, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { TThemeColor } from 'styles/theme';

interface ICardProps {
  isActive?: boolean;
  background?: TThemeColor;
}

export const SLayout = styled.div`
  padding-top: 10px;
  padding-left: 5px;

  ${mediaFrom(
    'desktop',
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        width: 100%;
        margin-left: 26%;
      }
    `
  )}

  ${mediaUpTo(
    400,
    css`
      .custom-title-text {
        font-size: 22px;
      }

      .custom-text-inner {
        font-size: 12px;
      }
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      .custom-title-text {
        font-size: 20px;
      }
    `
  )}
`;

export const SCustomButton = styled(Button)`
  border: 0;
  padding: 0;
  width: auto;
  font-size: 14px;

  :disabled {
    border: none;
  }
`;

export const SBlock = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: ${getColor('white')};
  padding: 34px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  ${mediaFrom(
    'desktop',
    css`
      width: 980px;
      margin-bottom: 40px;
    `
  )}

  ${mediaUpTo(
    400,
    css`
      padding-top: 22px;
    `
  )}

  ${mediaUpTo(
    'tablet',
    css`
      padding-left: 15px;
    `
  )}

  .transfer-items {
    ${mediaFrom(
      'desktop',
      css`
        display: flex;
        justify-content: space-around;
      `
    )}
  }

  .transfer-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    ${mediaUpTo(
      'mobile',
      css`
        margin-top: 12px;

        & > .body-text {
          margin-right: 11px;
          & .custom-text-inner {
            font-size: 13px;
          }
        }

        & .icon-info {
          width: 14px;
          height: 14px;
        }
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        justify-content: center;
      `
    )}
  }

  .view-transfer-history {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 10px;
    ${mediaUpTo(
      400,
      css`
        margin-top: 35px;
      `
    )}
    ${mediaUpTo(
      'tablet',
      css`
        margin-top: 40px;
      `
    )}

    ${mediaFrom(
      'desktop',
      css`
        margin-top: 32px;

        button {
          border: none;
          font-size: 14px;
          font-weight: 500;
          margin-top: 0;

          &:hover {
            .btn__inner .custom-text-inner {
              color: ${getColor('white')};
            }
          }
        }
      `
    )}
  }
`;

export const STransferBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  gap: 12px;

  ${mediaUpTo(
    'tablet',
    css`
      gap: 8px;
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      padding: 24px 20px;
      border-radius: 16px;
      border: 3px solid ${getColor('charcoal5')};
    `
  )}
`;

export const SCircle = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${getColor('white')};
  border: 2px solid ${getColor('blue')};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SCustomRow = styled(CustomRow)`
  padding-bottom: 28px;
`;

export const SCreditCard = styled.div<ICardProps>`
  width: 210px;
  height: 128px;
  border-radius: 20px;
  background: ${getColor('transparent')};
  border: ${ifProp('isActive', css`2px dashed ${getColor('blue')}`, css`2px dashed ${getColor('charcoal40')}`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SIcon = styled(Icon)`
  margin-bottom: 16px;
`;

export const SAmountResult = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const SModalCloseButton = styled(Icon)`
  position: absolute;
  top: 14px;
  border: 2px solid ${getColor('blue')};
  color: ${getColor('blue')};
  right: 14px;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  padding: 4px;

  ${({ color }) => css`
    color: ${getColor(color ?? 'blue')};
    border-color: ${getColor(color ?? 'blue')};
  `};
`;

export const SIframeCloseButton = styled.div`
  svg {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

export const SIFrame = styled.iframe`
  min-height: 600px;
  width: 100%;
  margin-top: 40px;
  height: ${getProp('height', 'auto')};
  border: 0;
`;

export const SStatusImage = styled.img`
  width: auto;
  max-width: 100%;
  margin: 0 auto 30px;
`;
