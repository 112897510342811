import React, { useEffect, useState } from 'react';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { Form } from 'antd';
import { CustomRequiredLabel } from 'components/theme/CustomRequiredLabel/CustomRequiredLabel';
import { BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { Icon } from 'components/general/Icon/Icon';
import { JointAccountSuccessModal } from 'views/Account/AccountInformation/Modals/editAccount/JointAccountSuccessModal/JointAccountSuccessModal';
import { fullNameCharacterRule, getEmailRulesOptional, getPhoneValidator, getRequiredRule } from 'utils/helpers/validationRules';
import { BaseInput } from 'components/general/BaseInput/BaseInput';
import { MaskedInput } from 'components/general/MaskedInput/MaskedInput';
import { useTranslation } from 'react-i18next';
import { getUnmaskedMobileNumber } from 'utils/helpers/phoneNumber';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { useInviteJointAccountHolderMutation } from 'store/user/accounts/accounts.api';
import { IJointAccountsForm } from 'views/OpenCashAccount/MyAccount/JointAccounts/JointAccountsForm/JointAccountsForm.types';
import { useToggle } from 'utils/hooks/useToggle';
import { useLazyGetCurrentUserQuery } from 'store/user/users.api';
import { Loader } from 'components/general/Loader/Loader';
import { handleError } from 'utils/helpers/errorHelper';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/user/authentication.slice';
import { isUnder18 } from 'utils/helpers/date';
import { SHeader } from './AddJointAccountModal.styles';

interface AddJointAccountModalProps {
  open: boolean;
  onClose: (isInviteError: boolean) => void;
  accountForJointId: string;
}

const PHONE_MASK = '(000) 000 0000';

const PHONE_MASK_OPTIONS = {
  lazy: true,
};

export const AddJointAccountModal = ({ open, onClose, accountForJointId }: AddJointAccountModalProps) => {
  const { t } = useTranslation(['accountInformation', 'accountOpening', 'errors', 'profile']);
  const [form] = Form.useForm();
  const [inviteJointAccountHolderAPI, inviteJointAccountHolderAPIResult] = useInviteJointAccountHolderMutation();
  const [getCurrentUserAPI, getCurrentUserAPIResult] = useLazyGetCurrentUserQuery();
  const [isActive, setIsActive] = useState(false);
  const jointAccountSuccess = useToggle();
  const { isDesktopSize } = useDeviceDimension();
  const user = useSelector(selectCurrentUser);
  const isUserUnder18 = !!user.birthday && isUnder18(new Date(user.birthday));

  const handleSuccess = () => {
    onClose(false);
    jointAccountSuccess.show();
  };

  const isValid = () => {
    const errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    return errors.length === 0;
  };

  const handleFormChange = (): void => {
    const phoneNumber = form.getFieldValue('phone');
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    setIsActive(phoneNumberDigits.length !== 0 && phoneNumberDigits.length !== undefined && phoneNumberDigits.length === 10);
  };

  const hasEmptyValues = () => {
    const values = Object.values(form.getFieldsValue());
    return values.includes(undefined);
  };

  const onFinish = (formValues: IJointAccountsForm) => {
    inviteJointAccountHolderAPI({
      isPrimaryOwner: !isUserUnder18,
      ...formValues,
      phone: getUnmaskedMobileNumber(`1${formValues.phone}`),
      accountId: accountForJointId,
    });
    form.resetFields();
  };

  const onFieldsChange = () => {
    setIsActive(isValid() && !hasEmptyValues());
  };

  useEffect(() => {
    if (inviteJointAccountHolderAPIResult.isSuccess) {
      handleSuccess();
      getCurrentUserAPI();
    }
  }, [inviteJointAccountHolderAPIResult.isSuccess]);

  useEffect(() => {
    if (inviteJointAccountHolderAPIResult.isError) {
      onClose(true);
      handleError(inviteJointAccountHolderAPIResult.error);
    }
  }, [inviteJointAccountHolderAPIResult.isError]);

  return (
    <>
      <CustomSheet
        isModal={isDesktopSize}
        wrapperPadding={isDesktopSize}
        isOpen={open}
        modalName="addJointAccountModal"
        headerStyle={{ background: '#fff', paddingBottom: 0 }}
        onClose={() => onClose(false)}
      >
        <SHeader>
          <Icon name="arrowLeft" color="charcoal" onClick={() => onClose(false)} cursorPointer size="small" />
          <span className="title">{t('accountInformation.AddJointAccountholder')}</span>
        </SHeader>
        <BodyText color="charcoal70" size="M" marginTop={15} marginBottom={20}>
          {t(`accountOpening.JointAccountholderHaveToCompleteCashAccountProcess`)}
        </BodyText>

        {inviteJointAccountHolderAPIResult.isLoading || getCurrentUserAPIResult.isFetching ? (
          <Loader />
        ) : (
          <Form onFinish={onFinish} onFieldsChange={onFieldsChange} autoComplete="off" layout="vertical" requiredMark={false} form={form}>
            <Form.Item
              style={{ marginBottom: 32 }}
              label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's First Name")} isDanger />}
              name="firstName"
              validateTrigger={['onBlur', 'onChange']}
              validateFirst
              rules={[getRequiredRule(t('errors.PleaseInputTheirFirstName', { ns: 'errors' })), fullNameCharacterRule()]}
            >
              <BaseInput placeholder={t(`accountOpening.EnterTheirFirstName`)} />
            </Form.Item>

            <Form.Item
              label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's Last Name")} isDanger />}
              name="lastName"
              style={{ marginBottom: 32 }}
              validateTrigger={['onBlur', 'onChange']}
              validateFirst
              rules={[getRequiredRule(t('errors.PleaseInputTheirLastName', { ns: 'errors' })), fullNameCharacterRule()]}
            >
              <BaseInput placeholder={t(`accountOpening.EnterTheirLastName`)} />
            </Form.Item>

            <Form.Item
              label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's Email")} isDanger />}
              name="email"
              style={{ marginBottom: 32 }}
              validateTrigger={['onBlur', 'onChange']}
              validateFirst
              rules={[getRequiredRule(t('errors.PleaseInputTheirEmail', { ns: 'errors' })), getEmailRulesOptional()]}
            >
              <BaseInput placeholder={t(`accountOpening.EnterTheirEmail`)} inputMode="email" autoCapitalize="off" />
            </Form.Item>

            <Form.Item
              label={<CustomRequiredLabel label={t("accountOpening.Joint Accountholder's Mobile Number")} isDanger />}
              name="phone"
              style={{ marginBottom: 32 }}
              validateTrigger={['onBlur', 'onChange']}
              rules={[getRequiredRule(t('profile.Please enter your Phone Number', { ns: 'profile' })), getPhoneValidator(t('inviteEarn.PleaseInputCompleteMobileNumber', { ns: 'inviteEarn' }))]}
            >
              <MaskedInput mask={PHONE_MASK} maskOptions={PHONE_MASK_OPTIONS} placeholder={t(`accountOpening.EnterTheirMobileNumber`)} onChange={handleFormChange} inputMode="tel" />
            </Form.Item>
            <Button marginBottom={16} size="xl" type="submit" disabled={!isActive} preset="blue-filled">
              {t('accountInformation.AddJointAccountholder')}
            </Button>
          </Form>
        )}
      </CustomSheet>
      <JointAccountSuccessModal open={jointAccountSuccess.isActive} handleClose={jointAccountSuccess.hide} />
    </>
  );
};
