import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from 'components/general/Typography';
import { TRANSACTION_CODES } from 'views/Account/BalancesTransactionsPage/PaymentIcon/transactionTypes';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

interface IPaymentDescription {
  trnCode: string;
  isSheet?: boolean;
}

export const PaymentDescription: React.FC<IPaymentDescription> = ({ trnCode, isSheet = true }) => {
  const { t } = useTranslation('accountInformation');
  const { isMobileSmallSize } = useDeviceDimension();

  const getDescription = () => {
    let description = '';

    Object.keys(TRANSACTION_CODES).forEach((item: string) => {
      if (TRANSACTION_CODES[item].codes && TRANSACTION_CODES[item].codes.includes(trnCode)) {
        description = TRANSACTION_CODES[item].description;
      }
    });

    return description;
  };

  return (
    <BodyText textType="bodyText" color={isSheet ? 'charcoal' : 'charcoal70'} size={isMobileSmallSize ? 'T' : 'N'} fontWeight={isSheet ? 'B' : 'R'}>
      {t(getDescription())}
    </BodyText>
  );
};
