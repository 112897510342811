import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { BodyText } from 'components/general/Typography';
import { TThemeColor } from 'styles/theme';
import { TIconName, TIconSize } from 'components/general/Icon/Icon.types';
import { Icon } from 'components/general/Icon/Icon';
import { TSizeProp, TWeightProp } from 'components/general/Typography/Typography.types';
import { STextBtn } from './TextButton.styles';

export type TTextFontProp = 'Poppins' | 'DM Sans';
interface ITextButtonProps {
  children: React.ReactNode;
  iconName?: TIconName;
  preIconName?: TIconName;
  iconSize?: TIconSize;
  color?: TThemeColor;
  size?: TSizeProp;
  fontWeight?: TWeightProp;
  font?: TTextFontProp;
  onClick?: () => void;
  linkTo?: string;
  className?: string;
  buttonType?: 'submit' | 'button';
}

export const TextButton = ({
  children,
  iconName,
  preIconName,
  color = 'blue',
  size = 'N',
  iconSize = 'small',
  fontWeight = 'R',
  font = 'DM Sans',
  onClick,
  linkTo,
  className,
  buttonType = 'button',
}: ITextButtonProps) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    onClick?.();

    if (linkTo) {
      navigate(linkTo);
    }
  }, [linkTo, onClick]);

  return (
    <STextBtn onClick={handleClick} className={clsx('text-btn', className)} type={buttonType}>
      {preIconName && <Icon name={preIconName} size="small" color={color} marginRight={6} />}
      <BodyText size={size} color={color} font={font} fontWeight={fontWeight}>
        {children}
      </BodyText>
      {iconName && <Icon name={iconName} size={iconSize} color={color} marginLeft={6} />}
    </STextBtn>
  );
};
