import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCard = styled.div<{ bodyHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  border-radius: 20px;
  background: ${getColor('white')};
  padding: 62px 24px 32px;
  margin-bottom: 32px;

  .card-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }

  .card-body-wrapper {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  &.expanded {
    .card-body-wrapper {
      height: ${({ bodyHeight }) => `${bodyHeight}px`};
    }
  }

  .image-map {
    height: 212px;
    width: 191px;
    align-self: center;
    margin-bottom: 24px;
  }

  .flags-list {
    display: flex;
    margin-bottom: 24px;
    gap: 16px;
  }

  .manage-cards-button {
    border: none;
    margin-top: 12px;
  }

  .destination-card-button {
    font-size: 18px;
    padding: 14px 32px;

    &.check-destinations-button {
      margin-bottom: 12px;

      .icon-chevronDown {
        display: none;
      }
    }
  }

  .card-body-wrapper {
    .card-body {
      padding-top: 36px;

      .additional-text {
        background: ${getColor('blue10')};
        border-radius: 8px;
        padding: 8px 16px;
        margin-bottom: 24px;
      }

      .destination-list {
        max-height: 420px;
        overflow: hidden;
        overflow-y: auto;

        ::-webkit-scrollbar {
          display: none !important;
        }

        -ms-overflow-style: none !important;
        scrollbar-width: none !important;

        .card {
          .header {
            .country-name {
              .custom-text-inner {
                font-weight: 700;
              }
            }
          }

          .card-body-wrapper {
            .card-body {
              padding-top: 12px;

              .min-transfer-amount-wrapper {
                .body-text {
                  .custom-text-inner {
                    font-size: 12px;
                  }
                }
              }

              .columns {
                .row.titles {
                  .column-title {
                    .custom-text-inner {
                      font-size: 12px;
                      font-weight: 700;
                      color: ${getColor('charcoal40')};
                    }
                  }
                }
              }
            }
          }
        }
      }

      .terms-link {
        color: ${getColor('blue')};
      }
    }
  }

  .desktop-manage-btn {
    ${mediaUpTo(
      1439,
      css`
        display: none;
      `
    )}
  }

  .mobile-manage-btn {
    ${mediaFrom(
      'desktopLarge',
      css`
        display: none;
      `
    )}
  }
`;
