import styled from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';

export const SCustomSheet = styled(CustomSheet)`
  .header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  .dialog-body-wrapper {
    .body {
      display: flex;
      flex-direction: column;

      overflow-y: auto;

      ::-webkit-scrollbar {
        display: none !important;
      }

      -ms-overflow-style: none !important;
      scrollbar-width: none !important;

      form#selectCountryForm {
        display: flex;
        flex-direction: column;
        flex: 1;

        .form-item-countries-list-wrapper {
          flex: 1;

          .countries-list {
            overflow-y: auto;

            ::-webkit-scrollbar {
              display: none !important;
            }

            -ms-overflow-style: none !important;
            scrollbar-width: none !important;
          }
        }
      }
    }
  }

  .countries-list {
    flex: 1 1 auto;
  }

  button.preset-primary {
    height: 44px;
    width: 100%;
  }
`;
