import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 8px;
    color: ${getColor('charcoal')};
  }
`;
