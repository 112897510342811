import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';

export const STransactionSign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  position: relative;

  & .transaction-sign-internal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${getColor('charcoal5')};

    img {
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    & .icon-transaction_internal {
      width: 30px;
      height: 30px;
    }
  }

  .arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -7px;
    right: -7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${getColor('white')};

    &:before {
      content: '';
      position: relative;
      width: 6px;
      height: 1px;
      background: ${getColor('white')};
    }

    &:after {
      content: '';
      position: relative;
      width: 5px;
      height: 5px;
      border: 1px solid ${getColor('white')};
      border-top: none;
      border-right: none;
      transform: rotate(45deg);
    }

    &.income {
      background: ${getColor('green')};
      &:after {
        margin-left: -7px;
        transform: rotate(45deg);
      }
    }

    &.expense {
      flex-direction: row-reverse;
      background: ${getColor('red')};
      &:after {
        margin-right: -7px;
        transform: rotate(-136deg);
      }
    }
  }
`;
