import styled, { css } from 'styled-components';
import { getColor, SCREEN_SIZE, mediaFrom } from 'utils/helpers/styleHelpers';

export const SAccountsTypeSwitcher = styled.div`
  display: flex;
  gap: 6px;

  .chip {
    margin-bottom: 10px;
    background: ${getColor('transparent')};
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    line-height: 16px;
    font-size: 12px;

    &.active {
      background: ${getColor('blue')};
      border-color: ${getColor('blue')};
      color: ${getColor('white')};

      .icon {
        background: ${getColor('white')} !important;
      }
    }
  }

  ${mediaFrom(
    SCREEN_SIZE.tablet + 1,
    css`
      width: 100%;
      gap: 8px;
      .chip {
        width: 100%;
        font-size: 14px;
        height: 48px;
      }
    `
  )}
`;
