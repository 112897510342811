import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/general/Typography';
import { ROUTES } from 'vars/const/ROUTES';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { selectCurrentAuthState, selectPolicies } from 'store/user/authentication.slice';

import { Loader } from 'components/general/Loader/Loader';
import { useGetJointAccountHolderQuery } from 'store/user/accounts/accounts.api';
import { useToggle } from 'utils/hooks/useToggle';
import handsOnLaptop from './CustomImage/images/handsOnLaptop.png';
import peopleOnChair from './CustomImage/images/peopleOnChair.png';
import peopleSmile from './CustomImage/images/peopleSmile.png';
import percapitaPhoneCard from './CustomImage/images/percapitaPhoneCard.png';
import { CustomImage } from './CustomImage/CustomImage';

import { SWelcomeLayout, SWelcomeContainer, SWelcomeBackgroundImage, SWelcomeParagraph, SCustomButton, SPercapitaLogo } from './Welcome.styles';
import { ReferralCodeModal } from './ReferralCodeModal/ReferralCodeModal';

export const Welcome = () => {
  const navigate = useNavigate();
  const referralCodeModal = useToggle();
  const { t, ready } = useTranslation('preRegOnboarding');
  const { isDesktopSize } = useDeviceDimension();
  const { isProspectOnly } = useSelector(selectCurrentAuthState);
  const policies = useSelector(selectPolicies);

  const jointAccountHolderData = useGetJointAccountHolderQuery();

  const handleNextStep = useCallback(() => {
    navigate(isProspectOnly || jointAccountHolderData.isSuccess ? ROUTES.home.path : ROUTES.onboardingFirstSteps.path);
  }, [isProspectOnly, jointAccountHolderData.isSuccess, navigate]);

  const handleContinue = useCallback(() => {
    if (policies?.EnableUserReferralCodeOnRegister) {
      referralCodeModal.show();
    } else {
      handleNextStep();
    }
  }, [handleNextStep, policies?.EnableUserReferralCodeOnRegister, referralCodeModal]);

  if (!ready || jointAccountHolderData.isLoading) return <Loader />;

  return (
    <SWelcomeLayout>
      <SWelcomeBackgroundImage>
        <SWelcomeContainer>
          <SPercapitaLogo height={50} width={170} isWhite={!isDesktopSize} />

          {isDesktopSize && (
            <>
              <CustomImage img={handsOnLaptop} position="topRight" width="35%" />
              <CustomImage img={peopleOnChair} position="topLeft" width="20%" />
              <CustomImage img={percapitaPhoneCard} position="bottomLeft" width="20%" />
              <CustomImage img={peopleSmile} position="bottomRight" width="20%" />
            </>
          )}

          <SWelcomeParagraph>
            {isDesktopSize ? (
              <Title color="charcoal" fontWeight="M" size="XXXL" lineHeight={1.3} textAlign="center">
                {t('preRegOnboarding.Welcome')} {t('preRegOnboarding.ToPercapita')}
              </Title>
            ) : (
              <div className="welcomeTitleWrapper">
                <Title color="cream" fontWeight="M" size="M" lineHeight={1.3}>
                  {t('preRegOnboarding.Welcome')}
                </Title>
                <Title color="cream" fontWeight="M" size="M" lineHeight={1.3}>
                  {t('preRegOnboarding.ToPercapita')}
                </Title>
              </div>
            )}

            <Title
              color={isDesktopSize ? 'charcoal70' : 'cream'}
              fontWeight={isDesktopSize ? 'M' : 'R'}
              size={isDesktopSize ? 'S' : 'T'}
              font={isDesktopSize ? 'DM Sans' : 'Poppins'}
              marginTop={33}
              justifyContent={isDesktopSize ? 'center' : 'start'}
            >
              {t("preRegOnboarding.We're so glad you're here")}
            </Title>
          </SWelcomeParagraph>

          <SCustomButton preset={isDesktopSize ? 'blue-filled' : 'white-filled'} size="large" onClick={handleContinue}>
            {t("preRegOnboarding.Let's Get Started")}
          </SCustomButton>
        </SWelcomeContainer>
      </SWelcomeBackgroundImage>

      <ReferralCodeModal open={referralCodeModal.isActive} onClose={handleNextStep} handleOnNo={handleNextStep} handleOnYes={handleNextStep} />
    </SWelcomeLayout>
  );
};
