import styled, { css } from 'styled-components';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { mediaFrom } from 'utils/helpers/styleHelpers';

export const SCustomSheet = styled(CustomSheet)`
  .dialog-body-wrapper {
    .body {
      padding: 32px 20px 56px;

      .base-input-container {
        margin-bottom: 0;
      }
    }
  }

  // 1440px
  ${mediaFrom(
    'desktopLarge',
    css`
      width: 45% !important;

      .dialog-body-wrapper {
        .body {
          .title {
            .custom-title-text {
              font-size: 32px;
              font-weight: 500;
            }
          }
        }
      }
    `
  )};
`;
