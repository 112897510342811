import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'components/general/Icon/Icon';
import { SCrumbs } from './Crumbs.styles';

interface ICrumb {
  name: string;
  url?: string;
}

interface ICrumbsProps {
  crumbs: ICrumb[];
}

export const Crumbs = ({ crumbs }: ICrumbsProps) => (
  <SCrumbs className="crumbs">
    {crumbs.map((crumb, index, arr) => (
      <>
        <li className="crumbs__crumb">
          {index + 1 === arr.length ? (
            <span className="crumbs__crumb-text crumbs__crumb-text_last">{crumb.name}</span>
          ) : (
            <Link to={crumb?.url ?? ''} className="crumbs__crumb-text">
              {crumb.name}
            </Link>
          )}
        </li>
        {index + 1 < arr.length && <Icon size="smaller" className="crumbs__arrow" name="chevronRight" color="charcoal40" />}
      </>
    ))}
  </SCrumbs>
);
