import React, { useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components/general/Loader/Loader';
import { Title } from 'components/general/Typography';
import { useTranslation } from 'react-i18next';
import { useFAQ } from 'utils/hooks/useFAQ';
import { WELLNESS_FAQ_GROUP_ID } from 'vars/const/FAQ';
import { Question } from 'components/general/FAQ/Question/Question';
import { useAppDispatch } from 'utils/hooks/store';
import { setPreviousTab } from 'store/user/help.slice';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { ROUTES } from 'vars/const/ROUTES';
import { IFAQIntent } from './HelpAndSupport/FAQ.types';
import { SWellnessFAQ } from './Wellness.styles';

export const WellnessFAQ = () => {
  const { t } = useTranslation('helpSupport');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { isDesktopSize } = useDeviceDimension();
  const { activeGroupIntents, isLoading } = useFAQ({ activeGroupId: WELLNESS_FAQ_GROUP_ID });
  const activeQuestion = useMemo(() => activeGroupIntents?.find((intent: IFAQIntent) => intent.IntentName === params.questionName), [params.questionName, activeGroupIntents]);

  const handleQuestionClick = (questionName: string) => {
    if (isDesktopSize) return;

    navigate(
      generatePath(ROUTES.wellnessFAQ.path, {
        questionName,
      })
    );
  };

  const memoizedActiveGroupQuestions = useMemo(
    () =>
      activeGroupIntents.map((intent: IFAQIntent) => (
        <Question
          answerText={intent.CorrectAnswerText}
          intentName={intent.IntentName}
          text={intent.Questions[0].QuestionText}
          key={intent.IntentName}
          onClick={handleQuestionClick}
          isCollapsible={isDesktopSize}
        />
      )),
    [activeGroupIntents, isDesktopSize]
  );

  useEffect(() => {
    dispatch(setPreviousTab('help-and-support'));
  }, [dispatch]);

  return (
    <SWellnessFAQ className="wellness-faq">
      {isLoading && <Loader />}
      {!activeQuestion && (
        <div className="wellness-faq__title">
          <Title size="S" fontWeight="SB" color="charcoal" font="Poppins" extraStyles={{ width: '70%' }}>
            {t('helpSupport.Financial Wellness FAQs')}
          </Title>
        </div>
      )}
      <div className="wellness-faq__content">
        {!activeQuestion && memoizedActiveGroupQuestions}
        {activeQuestion && (
          <Question
            answerText={activeQuestion.CorrectAnswerText}
            intentName={activeQuestion.IntentName}
            text={activeQuestion.Questions[0].QuestionText}
            key={activeQuestion.IntentName}
            onClick={handleQuestionClick}
            isAlwaysOpen
          />
        )}
      </div>
    </SWellnessFAQ>
  );
};
