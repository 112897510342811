import React, { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components/general/Loader/Loader';
import { useAccountFeatures } from 'utils/hooks/useAccountFeatures';
import { ROUTES } from 'vars/const/ROUTES';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';

export const SelectedAccountCashCushionPage = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { generateUrl } = useEnhancedNav();

  const { overdraftFeature, isLoading: featuresLoading, isError } = useAccountFeatures(accountId);
  const isOverdraftEnabled = overdraftFeature?.isEnabled;

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.home.path, { replace: true });
    }
  }, [isError]);

  if (featuresLoading) return <Loader />;

  return isOverdraftEnabled ? (
    <Navigate to={ROUTES.cashCushionActivate.path} state={{ cashCushionAccountId: accountId }} replace />
  ) : (
    <Navigate to={generateUrl(ROUTES.cashCushion.path, { searchParams: { accountId } })} replace />
  );
};
