import React from 'react';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { StyledLink } from 'views/Main/MainPage/PercapitaPayCard/PercapitaPay/PercapitaPay.styles';
import { ROUTES } from 'vars/const/ROUTES';
import { images } from 'assets';
import { SCard } from './OpenCashAccountCard.styles';

export const OpenCashAccountCard = () => {
  const { t } = useTranslation('cashAccount');
  const { isDesktopSize } = useDeviceDimension();

  return (
    <SCard>
      <div className="image-container">
        <img src={images.cash} alt="Cash account" />
      </div>
      <div className="content-container">
        <Title fontWeight={isDesktopSize ? 'M' : 'SB'} size={isDesktopSize ? 'L' : 'T'} font="Poppins" color="charcoal" marginTop={25}>
          {t('cashAccount.SayGoodbyeToSurpriseFees')}
        </Title>
        <BodyText textType="bodyText" size={isDesktopSize ? 'L' : 'N'} fontWeight={isDesktopSize ? 'B' : 'M'} marginTop={isDesktopSize ? 24 : 10} color="charcoal70" lineHeight={1.0}>
          {t('cashAccount.NoSurpriseFees')}
        </BodyText>
        <BodyText textType="bodyText" size="N" fontWeight="R" marginTop={20} marginBottom={26} color="charcoal70" lineHeight={1.4}>
          {t('cashAccount.ThePercapitaCashAccountAndDebitCardAvailableFor')}
        </BodyText>
        <StyledLink to={ROUTES.starter.path}>
          <Button preset="blue-filled" size="middleStretch" width={isDesktopSize ? 'auto' : '100%'}>
            {t('cashAccount.Add Cash Account')}
          </Button>
        </StyledLink>
      </div>
    </SCard>
  );
};
