import styled from 'styled-components';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';

export const SCustomModal = styled(CustomModal)`
  .logo-main {
    align-self: center;
    margin-bottom: 32px;
  }

  &.desktop {
    top: 0 !important;
    width: 648px !important;

    .ant-modal-body {
      img.logo-main {
        width: 200px;
      }

      .title {
        .custom-title-text {
          font-size: 32px;
          text-align: center;
        }
      }

      .description {
        .custom-text-inner {
          text-align: center;
        }
      }

      button {
        align-self: center;
        width: auto;
        font-weight: 600;
      }
    }
  }
`;
