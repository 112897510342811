import styled from 'styled-components';
import { MEDIA_SIZE, getColor, flex } from 'utils/helpers/styleHelpers';

export const SBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: ${getColor('white')};
  padding: 16px;
  border-radius: 20px;

  & > .left {
    display: flex;
  }

  & .coming-soon {
    background: ${getColor('bluePurple40')};
    padding: 5px 10px;
    border-radius: 12px;
  }

  .title {
    ${flex('column', 'space-between', 'flex-start')}
    .heading {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
    }
    .custom-title-text {
      @media screen and (max-width: ${MEDIA_SIZE.mobile}px) {
        font-size: 14px;
      }
    }
  }

  .icon-sign {
    margin-right: 12px;
  }

  .fee {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: 20px;
    border-radius: 16px;
    background-color: ${getColor('blue10')};
    padding: 2px;
    flex-shrink: 0;

    .icon {
      min-width: 16px;
    }

    .custom-text-inner {
      text-wrap: nowrap;
    }
  }
`;
