import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SInviteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding: 24px 32px;

  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;

  z-index: 9999;
  height: 100px;
  width: 100%;

  ${mediaUpTo(
    'desktop',
    css`
      max-width: 720px;
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      max-width: 1180px;
    `
  )}

    border-radius: 20px;
  background: ${getColor('caoFooterBg')};
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(12px);

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    aling-items: center;
    gap: 8px;

    button {
      height: 52px;
      padding: 14px 14px;

      ${mediaFrom(
        'desktop',
        css`
          padding: 14px 40px;
          font-size: 18px;
        `
      )}

      font-size: 16px;
      font-weight: 500;
      font-family: Poppins;
    }

    .later-btn {
      max-width: 190px;
    }

    .join-btn {
      max-width: 440px;
    }
  }

  .button-center {
    display: flex;
    justify-content: center;
    aling-items: center;
    width: 100%;

    button {
      padding: 14px 40px;
      max-width: 180px;
      height: 52px;
      font-size: 18px;
      font-weight: 500;
      font-family: Poppins;
    }
  }
`;
