import styled, { css } from 'styled-components';
import { mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  padding-bottom: 48px;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;

    ${mediaUpTo(
      576,
      css`
        .svg-icon {
          display: none;
        }
      `
    )}
  }

  .subtitle {
    ${mediaUpTo(
      365,
      css`
        width: 70%;
      `
    )}

    ${mediaFrom(
      366,
      css`
        width: 80%;
      `
    )}

    ${mediaFrom(
      428,
      css`
        width: 100%;
      `
    )}
  }

  .cards-container {
    padding-bottom: 48px;
    padding-right: 20px;
  }
`;
