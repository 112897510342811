import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { ROUTES } from 'vars/const/ROUTES';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { setShowComingSoonModal } from 'store/ui.slice';
import { useDispatch } from 'react-redux';
import { SCardLayout, SCustomCard } from './InternationalTransferCard.styles';

export const InternationalTransferCard = () => {
  const { t } = useTranslation('home', { keyPrefix: 'homeScreen.internationalTransferCard' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isComingSoon = false;

  const topBadge = useMemo(() => {
    const text = isComingSoon ? t('comingSoon') : t('nowAvailable');
    const badgeClassName = isComingSoon ? 'coming-soon' : 'now-available';

    return (
      <div className={clsx('top-badge', badgeClassName)}>
        {/* animated icon */}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.23529 15.138V8.65523M9.23529 15.138L13.768 17.6865C13.8932 17.7569 14.035 17.7937 14.1793 17.7932C14.3235 17.7927 14.465 17.755 14.5898 17.6837C14.7145 17.6125 14.818 17.5103 14.89 17.3874C14.962 17.2644 15 17.125 15 16.9831V6.81008C15 6.66817 14.962 6.52877 14.89 6.40581C14.818 6.28285 14.7145 6.18065 14.5898 6.10944C14.465 6.03822 14.3235 6.00048 14.1793 6C14.035 5.99953 13.8932 6.03632 13.768 6.1067L9.23529 8.65523M9.23529 15.138H6.76471M9.23529 8.65523H4.29412C3.42046 8.65523 2.58259 8.99673 1.96482 9.6046C1.34706 10.2125 1 11.0369 1 11.8966C1 12.7563 1.34706 13.5807 1.96482 14.1886C2.58259 14.7965 3.42046 15.138 4.29412 15.138M6.76471 15.138V18.7845C6.76471 19.1069 6.63456 19.416 6.4029 19.644C6.17123 19.8719 5.85703 20 5.52941 20C5.20179 20 4.88759 19.8719 4.65593 19.644C4.42426 19.416 4.29412 19.1069 4.29412 18.7845V15.138M6.76471 15.138H4.29412"
            stroke="#f19c49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M20 4L18 6" stroke="#f19c49" strokeWidth="1.5" strokeLinecap="round" id="waves" />
          <path d="M20 12L22.9495 12" stroke="#f19c49" strokeWidth="1.5" strokeLinecap="round" id="waves" />
          <path d="M20 20L18 18" stroke="#f19c49" strokeWidth="1.5" strokeLinecap="round" id="waves" />
        </svg>

        <BodyText size="T" fontWeight="M" marginLeft={8}>
          {text}
        </BodyText>
      </div>
    );
  }, [isComingSoon, t]);

  const onLearnMoreClick = () => {
    if (isComingSoon) {
      dispatch(setShowComingSoonModal(true));
    } else {
      navigate(ROUTES.internationalTransfer.path, { state: { backPage: pathname } });
    }
  };

  return (
    <SCardLayout>
      <Title>{t('internationalTransfer')}</Title>

      <SCustomCard>
        {topBadge}

        <Title className="card-title" size="sM" lineHeight="24px" marginBottom={8}>
          {t('safelySendMoneyOverseas')}
        </Title>

        <BodyText className="card-description" size="N" lineHeight="20px" color="charcoal70">
          {t('easilySendMoneyAroundTheWorld')}
        </BodyText>

        <Button className="learn-more-button" preset="blue-filled" size="middle" onClick={onLearnMoreClick}>
          {t('learnMore')}
        </Button>
      </SCustomCard>
    </SCardLayout>
  );
};
