import { TThemeColor } from 'styles/theme';
import { css, DefaultTheme } from 'styled-components';
import { rgba } from 'polished';

type TIfPropVal = ReturnType<typeof css<any>>;
type TMediaSize = 'maxDesktop' | 'minDesktop' | 'tablet' | 'mobile';
type TScreenSize = 'mobile' | 'tablet' | 'desktop' | 'desktopLarge' | 'desktopLargest';

export const MEDIA_SIZE: Record<TMediaSize, number> = {
  maxDesktop: 1600,
  minDesktop: 1280,
  tablet: 768,
  mobile: 376,
};

export const SCREEN_SIZE: Record<TScreenSize, number> = {
  mobile: 320,
  tablet: 576,
  desktop: 1200,
  desktopLarge: 1440,
  desktopLargest: 1900,
};

export const getColor = (colorName: TThemeColor) => (props: { theme: DefaultTheme }) => props.theme[colorName];
export const getRgba =
  (colorName: TThemeColor, alpha = 0) =>
  (props: { theme: DefaultTheme }) =>
    rgba(props.theme[colorName], alpha);
export const getColorByProp =
  (propName: string, fallback = 'none') =>
  (props: any) =>
    props.theme[props[propName]] || fallback;
export const getColorIf = (propFlagName: string, ifColorName: TThemeColor, elseColorName: TThemeColor) => (props: any) =>
  props[propFlagName] ? props.theme[ifColorName] : props.theme[elseColorName] || 'transparent';
export const getProp =
  (propName: string, fallbackVal: string | number = '') =>
  (props: any) =>
    props[propName] || fallbackVal;
export const ifProp =
  (propFlagName: string, ifVal: string | number | TIfPropVal, elseVal: string | number | TIfPropVal = '') =>
  (props: any) =>
    props[propFlagName] ? ifVal : elseVal;

export const mediaFrom = (from: number | TScreenSize, cssItem: string | ReturnType<typeof css<any>>) => css`
  @media only screen and (min-width: ${typeof from === 'number' ? from : SCREEN_SIZE[from]}px) {
    ${cssItem};
  }
`;

export const mediaUpTo = (upTo: number | TScreenSize, cssItem: string | ReturnType<typeof css> | any) => css`
  @media only screen and (max-width: ${typeof upTo === 'number' ? upTo : SCREEN_SIZE[upTo]}px) {
    ${cssItem};
  }
`;

export const mediaBetween = (from: number | TScreenSize, to: number | TScreenSize, cssItem: string | ReturnType<typeof css>) => css`
  @media only screen and (min-width: ${typeof from === 'number' ? from : SCREEN_SIZE[from]}px) and (max-width: ${typeof to === 'number' ? to : SCREEN_SIZE[to]}px) {
    ${cssItem};
  }
`;

export const mediaUpToHeight = (upTo: number, cssItem: string | ReturnType<typeof css>) => css`
  @media only screen and (max-height: ${upTo}px) {
    ${cssItem};
  }
`;

export const round = (size: number, bgColor: TThemeColor = 'transparent') => css`
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
  background: ${getColor(bgColor)};
`;

type TJustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
type TAlignItems = 'flex-start' | 'flex-end' | 'center' | 'stretch';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const flex = (direction: 'row' | 'column' = 'row', justifyContent: TJustifyContent = 'center', alignItems: TAlignItems = 'center', inlineFlex = false) => css`
  display: ${ifProp('inlineFlex', 'inline-flex', 'flex')};
  flex-direction: ${direction};
  justify-content: ${justifyContent};
  align-items: ${alignItems};
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
