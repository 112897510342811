import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { congratulationV2 } from 'assets/images';
import { ROUTES } from 'vars/const/ROUTES';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'components/theme/Button/Button';
import { SCustomModal } from './openAccountSuccessModal.styles';

interface IOpenAccountSuccessModal {
  open: boolean;
  onClose: () => void;
  addAccountType?: string;
}

export const OpenAccountSuccessModal = ({ open, onClose, addAccountType }: IOpenAccountSuccessModal) => {
  const { t } = useTranslation('addAccount');
  const navigate = useNavigate();

  const handleSetAsideMoney = () => {
    navigate(ROUTES.transfers.path, { state: { preSelectedAccountType: addAccountType === 'needs' ? 'Stuff' : 'Save' } });
  };

  return (
    <SCustomModal open={open} modalName="openAccountSuccess" topPosition="0" width="47%" closeIconColor="charcoal70" padding="51px 24px 32px 24px" onClose={onClose}>
      <div className="add-success-image center-image">
        <img src={congratulationV2} alt="successAdd" />
      </div>

      <Title color="charcoal" font="Poppins" fontWeight="M" size="XL" textAlign="center" lineHeight={1.1} marginBottom={16}>
        {addAccountType === 'needs' ? t('addAccount.Congrats on opening a Needs Account') : t('addAccount.Congrats on opening a Goals Account')}
      </Title>

      <BodyText textType="bodyText" fontWeight="R" size="N" textAlign="center" color="charcoal70" marginBottom={32}>
        {addAccountType === 'needs'
          ? t('addAccount.You can now start setting aside money intended for your monthly expenses.')
          : t('addAccount.You can now start saving money for your future financial goals or setting aside money for a rainy day')}
      </BodyText>

      <Button preset="blue-filled" size="middle" onClick={handleSetAsideMoney} marginBottom={24}>
        {addAccountType === 'needs' ? t('addAccount.needs.Set Aside Money') : t('addAccount.goals.Save for Future Goals')}
      </Button>

      <Link to={ROUTES.home.path} className="to-home">
        {t('addAccount.ReturnToHome')}
      </Link>
    </SCustomModal>
  );
};
