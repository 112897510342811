import styled, { css } from 'styled-components';
import { getColor, mediaUpTo, SCREEN_SIZE } from 'utils/helpers/styleHelpers';

export const SDivider = styled.div`
  border-bottom: 1px solid ${getColor('creamS5')};
  margin-left: -64px;
  margin-right: -64px;
`;

export const SLayout = styled.div`
  margin-bottom: 48px;
`;

export const SDesktopPageHeader = styled.header`
  display: block;
  font-size: 12px;
  padding: 5px 0 36px;

  .desktop-page-header__inner {
    display: flex;
    width: 100%;
    padding: 18px 0px;
    justify-content: space-between;
  }

  .desktop-page-header__title-wrapper {
    display: flex;
    align-items: center;
    margin-left: 32px;
  }
`;

export const SMobilePageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;

  ${mediaUpTo(
    SCREEN_SIZE.mobile,
    css`
      .title .custom-title-text {
        font-size: 20px;
      }
    `
  )}
`;
