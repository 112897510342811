import styled, { css } from 'styled-components';
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { MEDIA_SIZE, getColor, mediaBetween, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';
import { images } from 'assets';

export const STitle = styled(Title)`
  .custom-title-text {
    padding-right: 10px;
    ${mediaFrom(
      951,
      css`
        font-size: 42px !important;
      `
    )}
    ${mediaBetween(
      716,
      950,
      css`
        font-size: 38px !important;
      `
    )}

    ${mediaBetween(
      'tablet',
      715,
      css`
        font-size: 28px !important;
      `
    )}
    
    ${mediaUpTo(
      'tablet',
      css`
        font-size: 28px !important;
        line-height: 32px !important;
      `
    )}
    
    ${mediaUpTo(
      'mobile',
      css`
        font-size: 20px !important;
        line-height: 24px !important;
      `
    )}
  }
`;

export const SDepositWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${mediaFrom(
    'desktop',
    css`
      width: 80%;
      margin-inline: auto;
    `
  )}
`;

export const SLayout = styled.div`
  width: 100%;
  padding: 0 18px;
  margin: 0 auto;

  ${mediaUpTo(
    'tablet',
    css`
      .deposit-footer {
        margin-top: 35px;
      }
    `
  )}

  ${mediaFrom(
    'desktop',
    css`
      padding: 0 64px;
    `
  )}


  * {
    @media screen and (max-width: 427px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .web-view {
    ${mediaFrom(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  ${mediaFrom(
    'tablet',
    css`
      background: url(${images.layoutBg});
      background-position: top;
    `
  )}

  .back-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid ${getColor('creamS5')};
    margin-bottom: 40px;
    padding: 24px 0;

    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  .setup-right-now {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}
  }

  .autodeposit-footer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 37px;
    margin-bottom: 36px;
    align-items: center;

    ${mediaFrom(
      'desktop',
      css`
        width: 10%;
      `
    )}
  }

  .deposit-header {
    justify-content: space-between;
    display: flex;
    width: 100%;

    ${mediaFrom(
      'tablet',
      css`
        flex-direction: row-reverse;
        align-items: center;

        .custom-title-text {
          max-width: 760px;
          font-size: 32px;
        }
      `
    )}

    ${mediaBetween(
      'tablet',
      'desktop',
      css`
        padding-inline: 32px;
      `
    )}


    ${mediaUpTo(
      'tablet',
      css`
        flex-direction: column;
      `
    )}
  }

  .options {
    ${mediaBetween(
      'tablet',
      730,
      css`
        width: 500px;
      `
    )}

    ${mediaFrom(
      731,
      css`
        width: 650px;
      `
    )}
    

    ${mediaFrom(
      'desktop',
      css`
        gap: 16px;
        display: flex;
        margin-top: 30px;
        width: 100%;
      `
    )}
  }

  .listItem {
    display: flex;
    margin-bottom: 24px;
    gap: 16px;
    justify-content: flex-start;
  }

  .center-image {
    margin-bottom: 20px;
  }

  .center-image img {
    ${mediaUpTo(
      MEDIA_SIZE.tablet,
      css`
        width: 205px;
      `
    )}

    ${mediaBetween(
      'tablet',
      715,
      css`
        width: 170px;
      `
    )}
  
    ${mediaUpTo(
      427,
      css`
        width: 136px;
      `
    )}
  }
`;

export const SCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;

  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: ${getColor('blue5')};

  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  color: ${getColor('blue')}; ;
`;

export const SDivider = styled.div`
  height: 2px;
  width: 100%;
  background: ${getColor('creamS5')};

  ${mediaFrom(
    'desktop',
    css`
      min-width: 30px;
    `
  )}
`;

export const SBodyText = styled(BodyText)`
  ${mediaFrom(
    'desktop',
    css`
      display: none;
    `
  )}
`;

export const SCustomCard = styled(CustomCard)`
  @media screen and (min-width: ${MEDIA_SIZE.tablet}px) {
    display: flex;
    flex-direction: column;

    button {
      max-width: fit-content;
      margin: auto auto 0 auto;
    }
  }
`;

export const SWrapper = styled.div`
  ${mediaFrom(
    'desktop',
    css`
      gap: 16px;
      display: flex;
      width: 45%;
    `
  )}

  .divider {
    ${mediaUpTo(
      'tablet',
      css`
        display: none;
      `
    )}

    width: 100%;
    height: 2px;
    background: ${getColor('cream')};
  }
`;
