import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICON_SIZE_MAP } from 'components/general/Icon/Icon.constants';
import { BodyText } from 'components/general/Typography';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { Icon } from 'components/general/Icon/Icon';
import { useCardHub } from 'utils/hooks/useCardHub';
import { CardHubModal } from 'components/general/Modals/CardHubModal/CardHubModal';
import { TThemeColor } from 'styles/theme';
import { SServiceItem } from './ServiceItem.styles';

interface IServiceItem {
  title: string;
  subTitle: string;
  prefix?: string;
  prefixSize?: keyof typeof ICON_SIZE_MAP;
  prefixColor?: TThemeColor;
  prefixAlignLeft?: boolean;
  to?: string;
  clickHandler?: () => void;
  isRoundUpEnabled?: boolean;
  isOverdraftEligible?: boolean;
  isActivated?: boolean;
}

export const ServiceItem = ({
  title,
  prefixAlignLeft = false,
  subTitle,
  prefix,
  prefixSize = 'normal',
  prefixColor = 'charcoal',
  to,
  clickHandler,
  isRoundUpEnabled = false,
  isOverdraftEligible,
  isActivated = false,
}: IServiceItem) => {
  const navigate = useNavigate();
  const { t } = useTranslation('accountInformation');
  const { isDesktopSize } = useDeviceDimension();
  const cardHub = useCardHub();

  const handleClickItem = () => {
    if (to) {
      if (to === 'cardHub') {
        cardHub.open();
      } else {
        navigate(to);
      }
    } else if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <SServiceItem className="service-item" onClick={handleClickItem}>
      <div className="service-item__header">
        {prefix && prefixAlignLeft && <Icon name={prefix} size={prefixSize} className="prefix" cursorPointer color={prefixColor} marginRight={8} marginBottom={12} />}
        {(isRoundUpEnabled || isActivated) && (
          <div className="badge">
            <BodyText size="T">{t('accountInformation.Activated')}</BodyText>
            <Icon name="circleTick" size="smallest" color="green" marginLeft={8} />
          </div>
        )}
        {prefix === 'cashCushion' && !isOverdraftEligible && (
          <div className="badge grey">
            <BodyText size="T">{t('accountInformation.Ineligible')}</BodyText>
          </div>
        )}
      </div>
      <div className="service-item__content">
        <div>
          {title && (
            <BodyText className="item-title" textType="bodyText" color="charcoal70" fontWeight="R" size={isDesktopSize ? 'N' : 'T'} cursorPointer lineHeight="16px">
              {title}
            </BodyText>
          )}
          <BodyText className="item-subtitle" textType="bodyText" color="charcoal" fontWeight={isDesktopSize ? 'B' : 'M'} size="M" cursorPointer lineHeight="24px">
            {subTitle}
          </BodyText>
        </div>
        <Icon name="chevronRight" color={isDesktopSize ? 'blue' : 'charcoal'} pictBgColor="charcoal" size="smaller" marginLeft={8} cursorPointer />
      </div>
      {to === 'cardHub' && <CardHubModal open={cardHub.isActive} onClose={cardHub.close} />}
    </SServiceItem>
  );
};
