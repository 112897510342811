import React from 'react';
import { IMessage } from 'store/chat/chat.types';
import { BodyText } from 'components/general/Typography';
import { format } from 'date-fns';
import { MessageContent } from './MessageContent';
import { SMessage } from './Message.styled';
import { AccountCardAction } from './AccountCardAction';
import { ActionTransferStatus } from './ActionTransferStatus';

interface IMessageProps extends IMessage {
  actionClickHandler: (type: string, text: string, value: string) => void;
  isClickActionButton: boolean;
}

export const Message = ({
  from,
  origin,
  timestamp,
  hasSuggestion,
  isIntent,
  intentId,
  id,
  onSelect,
  selected,
  isCorrectAnswer,
  suggestedActions,
  actionClickHandler,
  isActionResponse,
  isIntro,
  isFromUser,
  entities,
  ...props
}: IMessageProps) => {
  const transferStateAction = suggestedActions?.actions?.find((action) => action.type === 'transferStatusCard');
  return (
    <SMessage className="message" isActionResponse={isActionResponse} origin={origin} isFromUser={isFromUser} isCorrectAnswer={isCorrectAnswer} isIntro={isIntro}>
      <div className="content">
        <MessageContent origin={origin} entities={entities} {...props} isFromUser={isFromUser} isCorrectAnswer={isCorrectAnswer} isIntro={isIntro} suggestedActions={suggestedActions} />
        <time dateTime={format(new Date(timestamp), 'HH:mm')}>{format(new Date(timestamp), 'HH:mm aaa')}</time>
      </div>
      {suggestedActions?.actions && (
        <section>
          {transferStateAction && <ActionTransferStatus action={transferStateAction} />}
          <BodyText textType="helperText" size="T" fontWeight="R" color="charcoal70" marginTop="spacing-normal">
            Choose from these options:
          </BodyText>
          <ul className="actions">
            {suggestedActions.actions.map((action) => {
              if (action.type === 'transferStatusCard') return null;
              if (action.type === 'accountCard' || action.type === 'accountRedirectCard') {
                return <AccountCardAction onClick={() => actionClickHandler(action.type, action.text, action.value)} displayText={action.displayText || ''} title={action.title} />;
              }

              return (
                <li key={action.text}>
                  <button type="button" className={action.type} onClick={() => actionClickHandler(action.type, action.text, action.value)}>
                    {action.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </section>
      )}
    </SMessage>
  );
};
