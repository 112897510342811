import React, { useState, useEffect } from 'react';
import { Button } from 'components/theme/Button/Button';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { SmsCodeForm } from 'components/general/SmsCodeForm/SmsCodeForm';
import { useProperties } from 'utils/hooks/useProperties';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { useEditUserProfileDataMutation } from 'store/user/users.api';
import { SModalContent, SActionButtons } from './ReferralCodeModal.styles';

interface IReferralCodeModal {
  open: boolean;
  onClose: () => void;
  handleOnYes: () => void;
  handleOnNo: () => void;
  isClosable?: boolean;
  isError?: boolean;
}

const REFERRAL_CODE_SIZE = 6;

export const ReferralCodeModal = ({ open, onClose, handleOnYes, handleOnNo, isError, isClosable = true }: IReferralCodeModal) => {
  const { t } = useTranslation('preRegOnboarding', { keyPrefix: 'preRegOnboarding' });
  const { getProperty } = useProperties();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [refCode, setRefCode] = useState('');
  const [editUserProfileData] = useEditUserProfileDataMutation();

  const getOnboardingPreferences = async () => {
    const onboardingPreferences = JSON.parse(await getProperty('OnboardingPreferences').unwrap());
    setRefCode(onboardingPreferences?.MyInfo?.referralCode ?? '');
  };

  const handleCompletion = (referralCode: string) => {
    setIsSubmitDisabled(false);
    setRefCode(referralCode);
  };

  const handleReferralCodeCompletion = async () => {
    await editUserProfileData({ profileData: { referralCode: refCode } });
    handleOnYes();
  };

  useEffect(() => {
    setIsSubmitDisabled(refCode.length < REFERRAL_CODE_SIZE);
  }, [refCode]);

  useEffect(() => {
    getOnboardingPreferences();
  }, []);

  return (
    <CustomSheet isOpen={open} onClose={onClose} closable={isClosable} modalName="referralCodeModal" width="650px" padding="0" className="make-joint-cash-account-modal">
      <SModalContent>
        <Title font="Poppins" size="M" fontWeight="M" color="charcoal" marginTop={5} marginBottom={16}>
          {t('RefCodeTitle')}
        </Title>

        <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginBottom={48} lineHeight={1.4}>
          {t('If yes, enter the code below and click “Continue.” If no, click “Skip')}
        </BodyText>

        <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" justifyContent="center" marginBottom={16} lineHeight={1.4}>
          {t('EnterCode')}
        </BodyText>
        <SmsCodeForm size={REFERRAL_CODE_SIZE} codeVal={refCode} boxSize="52px" handleCompletion={handleCompletion} onCompletion={setIsSubmitDisabled} isWrongCode={isError} isDisabledFocusOnStart />
        {isError && (
          <BodyText textType="bodyText" color="red" size="T" fontWeight="R" marginBottom={16} marginTop={8} lineHeight={1.4}>
            {t('PleaseTryAgain')}
          </BodyText>
        )}
      </SModalContent>

      <SActionButtons>
        <Button preset="blue-filled" onClick={handleReferralCodeCompletion} disabled={isSubmitDisabled} width="90%" marginBottom={18}>
          {t('SuccessfullRegistration.Continue')}
        </Button>
        <Button preset="blue-outlined" onClick={handleOnNo} width="90%" marginBottom={32}>
          {t('Skip')}
        </Button>
      </SActionButtons>
    </CustomSheet>
  );
};
