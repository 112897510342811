import styled from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';
import { BaseSlider } from 'components/general/Slider/Slider';

export const AccountsBox = styled.div`
  padding: 32px 20px 24px;
  gap: 48px;
  background: ${getColor('white')};
  border-radius: 20px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AccountsSlider = styled(BaseSlider)`
  margin: 0 -15px 30px;
  padding: 0 11px;
  overflow: hidden;

  .slick-list {
    overflow: visible;
  }

  .custom-text-inner {
    font-size: 12px;
  }
`;

export const SLayout = styled.div`
  .slick-slider {
    touch-action: auto !important;
  }
`;
