import styled, { css } from 'styled-components';
import { round, flexCenter, flex, mediaFrom } from 'utils/helpers/styleHelpers';

export const SPhoneNumberBlock = styled.div`
  .phone-block {
    ${flex('row', 'flex-start')};
    gap: 10px;
    flex-wrap: wrap;
  }

  .icon-wrapper {
    ${flexCenter};
    ${round(48, 'white')};
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 60px;
      ${mediaFrom(
        'tablet',
        css`
          margin-top: 30px;
          min-width: 149px !important;
          max-width: 180px !important;
        `
      )}
    }
  }
`;
