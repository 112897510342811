import React, { useEffect } from 'react';
import { useAppDispatch } from 'utils/hooks/store';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTES } from 'vars/const/ROUTES';
import { Loader } from 'components/general/Loader/Loader';
import { useLazyGetZogoTokenQuery } from 'store/user/zogo/zogo.api';
import { setIsHelpAndSupportTab, setWaitlistLocation } from 'store/location.slice';
import { BodyText, Title } from 'components/general/Typography';
import { setPreviousTab } from 'store/user/help.slice';
import { IconSign } from 'components/general/Icon/IconSign';
import { Icon } from 'components/general/Icon/Icon';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';
import { SWellnessTabContent } from 'views/Wellness/Wellness.styles';
import { ToolCard } from './ToolCard/ToolCard';

export const GoalsAndTools = () => {
  const dispatch = useAppDispatch();
  const { isDesktopSize } = useDeviceDimension();
  const { t } = useTranslation(['wellness', 'home']);
  const [getZogoTokenAPI, getZogoTokenAPIResult] = useLazyGetZogoTokenQuery();

  useEffect(() => {
    dispatch(setWaitlistLocation(ROUTES.wellness.path));
    dispatch(setIsHelpAndSupportTab({ isHelpAndSupportTab: false }));
    dispatch(setPreviousTab('goals-and-tools'));
    getZogoTokenAPI();
  }, []);

  if (getZogoTokenAPIResult.isLoading) return <Loader />;

  return (
    <SWellnessTabContent>
      <Title fontWeight="SB" size="S" justifyContent="start" marginBottom={16} marginTop={isDesktopSize ? 32 : 18}>
        {t('learnPlay.Earn Percapita Points')}
      </Title>
      <div className="earn-points-wrapper">
        <ToolCard
          icon={isDesktopSize ? <Icon name="percapitaPlay" color="blue" size="xl" /> : <IconSign iconName="percapitaPlay" />}
          description={
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" display="inline" justifyContent="start">
              <Trans i18nKey="homeScreen.PercapitaPlayDefaultDescription" ns="home" components={{ strong: <strong /> }} />
            </BodyText>
          }
          buttonText={t('goalsTools.Play Now!')}
          tooltip
          to={ROUTES.playPercUp.path}
          isPercapitaPlay
        />
        <ToolCard
          className="invite-friend"
          icon={isDesktopSize ? <Icon name="refer" size="bigger" color="blue" /> : <IconSign iconName="refer" />}
          description={t('goalsTools.InviteFriendsAndFamily')}
          buttonText={t('goalsTools.Invite a Friend')}
          to={ROUTES.refer.path}
        />
      </div>
    </SWellnessTabContent>
  );
};
