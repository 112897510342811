import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import styled from 'styled-components';

export const SCustomSheet = styled(CustomSheet)`
  .settings {
    &-outer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 34px;
      height: 50px;
      position: relative;

      .settings-inner:last-child {
        & .body-text:first-child {
          padding-right: 35px;
        }
      }
    }

    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-checkbox {
      display: inline-flex;
      & .body-text {
        padding-right: 24px;
        margin-bottom: 10px;
      }
    }

    &-available-balance {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
    }

    &-alert-source-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &-security-outer {
      display: flex;
      flex-direction: column;
    }

    &-security-inner {
      display: flex;
      align-items: flex-start;
    }

    &-save-btn {
      max-width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;
