import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  // @ts-ignore
  .use(resourcesToBackend((language, namespace) => import(`./locales/langs/${language}/${namespace}.json`)))
  .init({
    ns: [
      'translations',
      'header',
      'home',
      'moveMoney',
      'inviteEarn',
      'atmLocations',
      'cashAccountOpening',
      'percapitaPay',
      'login',
      'registration',
      'verification',
      'documents',
      'notificationsCenter',
      'profile',
      'balancesTransactions',
      'changePassword',
      'addNewSource',
      'connectShare',
      'mySubscriptions',
      'preRegOnboarding',
      'prepPage',
      'deposit',
      'onboardingFirstSteps',
      'pennyJar',
      'accountInformation',
      'securityPrivacy',
      'starter',
      'errors',
      'addAccount',
      'cashAccount',
      'creditCardInfo',
      'helpSupport',
      'wellness',
      'footer',
    ],
    defaultNS: 'translations',
    fallbackNS: 'translations',
    interpolation: { escapeValue: false },
    debug: false,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      htmlTag: document.documentElement,
      caches: ['localStorage'],
    },
    react: {
      useSuspense: false,
    },
  });

export { i18n };
