import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/theme/Button/Button';
import { CustomSheet } from 'components/theme/CustomSheet/CustomSheet';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { images } from 'assets';
import { CustomAmount } from 'components/theme/CustomAmount/CustomAmount';
import { ROUTES } from 'vars/const/ROUTES';
import { SAccountIconContainer, SNoteContainer } from 'views/Account/Summary/InternalTransfer/InternalTransferPage.styles';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { IAccountItem } from 'store/user/accounts/accounts.types';
import { useDeviceDimension } from 'utils/hooks/useDeviceDimension';

type IInternalTransferSuccessSheet = {
  isOpen: boolean;
  toAccount: IAccountItem;
  fromAccount: IAccountItem;
  transferNote: string;
  transferAmount: number;
  onClose: () => void;
};

export const InternalTransferSuccessSheet = ({ isOpen, toAccount, fromAccount, transferNote, transferAmount, onClose }: IInternalTransferSuccessSheet) => {
  const theme = useTheme();
  const { t } = useTranslation(['translations', 'moveMoney']);
  const navigate = useNavigate();
  const currentDate = useMemo(() => format(new Date(), 'MMM dd, yyyy'), [isOpen]);
  const { isDesktopSize } = useDeviceDimension();

  const handleDone = () => {
    onClose();
    navigate(ROUTES.home.path);
  };

  return (
    <CustomSheet
      className="success-sheet"
      isModal={isDesktopSize}
      isOpen={isOpen}
      header={false}
      modalName="transfer_internalTransferSuccessModal"
      height="100%"
      wrapperPadding={isDesktopSize}
      onClose={handleDone}
    >
      <CustomRow marginBottom={32} flexDirection="column">
        <img src={images.congratulation} alt="successInternalTransfer" />

        <Title className="success-title" font="Poppins" color="charcoal" fontWeight="SB" size="S" marginTop={10} extraStyles={{ alignSelf: 'flex-start' }}>
          {t('internalTransfer.Transfer Successful')}
        </Title>
      </CustomRow>

      <BodyText className="success-subtitle" textType="bodyText" font="Poppins" color="charcoal" size="M" fontWeight="SB" textAlign="start">
        {t('internalTransfer.Transaction Receipt')}
      </BodyText>

      <CustomCard className="card" border={`2px solid ${theme.charcoal5}`} borderRadius={20} marginTop={16} marginBottom={32}>
        <CustomRow marginBottom={27}>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
            {t('internalTransfer.Transfer Amount')}
          </BodyText>
          <CustomAmount size="smallerStrong" amount={transferAmount} />
        </CustomRow>

        <CustomRow marginBottom={27}>
          <CustomRow width="30%">
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginRight={10}>
              {t('internalTransfer.Account from')}
            </BodyText>
          </CustomRow>

          {fromAccount && (
            <CustomRow justifyContent="flex-end" width="70%" extraStyles={{ flexWrap: 'wrap' }}>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" extraStyles={{ whiteSpace: 'break-spaces' }}>
                {fromAccount.nickname || fromAccount.shortName}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={5} nowrap>
                {fromAccount.name}
              </BodyText>
            </CustomRow>
          )}
        </CustomRow>

        <CustomRow marginBottom={27}>
          <CustomRow width="30%">
            <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginRight={10}>
              {t('internalTransfer.Account to')}
            </BodyText>
          </CustomRow>

          {toAccount && (
            <CustomRow justifyContent="flex-end" width="70%" extraStyles={{ flexWrap: 'wrap' }}>
              <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B" extraStyles={{ whiteSpace: 'break-spaces' }}>
                {toAccount.nickname || toAccount.shortName}
              </BodyText>
              <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={5} nowrap>
                {toAccount.name}
              </BodyText>
            </CustomRow>
          )}
        </CustomRow>

        <CustomRow>
          <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R">
            {t('internalTransfer.Date')}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="B">
            {currentDate}
          </BodyText>
        </CustomRow>
      </CustomCard>

      {transferNote && (
        <>
          <BodyText textType="bodyText" font="Poppins" color="charcoal" size="M" fontWeight="SB" textAlign="start" marginBottom={16}>
            {t('internalTransfer.Note')}
          </BodyText>

          <SNoteContainer>
            <BodyText textType="bodyText" color="charcoal40" size="T" fontWeight="R">
              {transferNote}
            </BodyText>
          </SNoteContainer>
        </>
      )}

      <BodyText textType="bodyText" font="Poppins" color="charcoal" size="M" fontWeight="SB" textAlign="start" marginTop={32}>
        {t('internalTransfer.New Balances')}
      </BodyText>

      <CustomCard className="card" border={`2px solid ${theme.charcoal5}`} borderRadius={20} marginTop={16} marginBottom={32}>
        {fromAccount && (
          <CustomRow marginBottom={14}>
            <CustomRow justifyContent="flex-start">
              <SAccountIconContainer>
                <Icon name={fromAccount.icon} color="orange" />
              </SAccountIconContainer>
              <CustomRow flexDirection="column" alignItems="flex-start">
                <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginLeft={12} extraStyles={{ whiteSpace: 'break-spaces' }}>
                  {fromAccount.nickname || fromAccount.shortName}
                </BodyText>
                <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={12} nowrap>
                  {fromAccount.name}
                </BodyText>
              </CustomRow>
            </CustomRow>
            {fromAccount.balance !== undefined && <CustomAmount size="smallerStrong" amount={fromAccount.balance} />}
          </CustomRow>
        )}
        {toAccount && (
          <CustomRow>
            <CustomRow justifyContent="flex-start">
              <SAccountIconContainer>
                <Icon name={toAccount.icon} color="orange" />
              </SAccountIconContainer>
              <CustomRow flexDirection="column" alignItems="flex-start">
                <BodyText textType="bodyText" color="charcoal" size="N" fontWeight="R" marginLeft={12} extraStyles={{ whiteSpace: 'break-spaces' }}>
                  {toAccount.nickname || toAccount.shortName}
                </BodyText>
                <BodyText textType="bodyText" color="charcoal70" size="N" fontWeight="R" marginLeft={12} nowrap>
                  {toAccount.name}
                </BodyText>
              </CustomRow>
            </CustomRow>
            {toAccount.balance !== undefined && <CustomAmount size="smallerStrong" amount={toAccount.balance} />}
          </CustomRow>
        )}
      </CustomCard>

      <Button preset="blue-filled" onClick={handleDone} marginTop={24} marginBottom={32}>
        {t('internalTransfer.Done')}
      </Button>

      <SuttonDisclaimerNote />
    </CustomSheet>
  );
};
