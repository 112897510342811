import styled, { css } from 'styled-components';
import { getColor } from 'utils/helpers/styleHelpers';
import { CSSProperties } from 'react';

type SLayoutProps = {
  $extraStyles?: CSSProperties;
};

export const SLayout = styled.div<SLayoutProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 40px;
  left: 110px;
  background: ${getColor('white')};
  border-radius: 16px;
  border: solid ${getColor('grey2')} 2px;
  padding: 20px 32px 20px 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  
  &.show {
    opacity: 1;
  }

  ${({ $extraStyles }) => css({ ...$extraStyles })}
}
`;
