import styled, { css } from 'styled-components';
import { getColor, mediaFrom } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .points-bar {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    width: 237px;
    max-width: 100%;
    height: 55px;
    background: ${getColor('white')};
    padding-right: 18px;
    margin-right: -24px;
    overflow: hidden;
    ${mediaFrom(
      321,
      css`
        .custom-text-inner {
          font-size: 14px;
        }
      `
    )}

    .coin {
      margin-left: 10px;
    }
  }
`;
