import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { Checkbox } from 'components/general/Checkbox/Checkbox';
import { BodyText } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { PopupWrapper } from './ManagingPopup.styles';

interface IPopupProps {
  open: boolean;
  checkedState: {
    [key: string]: boolean;
  };
  total: number;
  checkAll: (type: string) => void | undefined;
  onDelete: (value: boolean) => void;
  onRead: (value: boolean) => void;
}

export const ManagingPopup: React.FC<IPopupProps> = ({ open, checkedState, total, checkAll, onDelete, onRead }) => {
  const { t } = useTranslation('notificationsCenter');
  const [isChecked, setChecked] = useState(false);
  const selectedItems = Object.values(checkedState).filter((value) => value).length;

  const onChange = () => {
    setChecked(!isChecked);
    if (!isChecked) {
      checkAll('check');
    } else {
      checkAll('uncheck');
    }
  };

  const handleDelete = async () => {
    onDelete(isChecked);
    setChecked(false);
    checkAll('uncheck');
  };

  const handleRead = async () => {
    onRead(isChecked);
    setChecked(false);
    checkAll('uncheck');
  };

  return (
    <PopupWrapper className={`wrapper ${open ? 'show' : 'hide'}`}>
      <CustomRow justifyContent="flex-start" alignItems="center">
        <Checkbox bgColor="white" checked={isChecked} onChange={onChange}>
          <BodyText size="N" fontWeight="M" paddingTop={6}>
            {t(`notificationsCenter.SelectAll`)}
          </BodyText>
        </Checkbox>{' '}
        <span className="point" />{' '}
        <span className="marked">
          {isChecked ? total : selectedItems} {t(`notificationsCenter.Selected`)}
        </span>
      </CustomRow>
      <CustomRow>
        <Icon name="envelope" color="charcoal" onClick={handleRead} cursorPointer />
        <Icon name="trash" color="charcoal" marginLeft={16} onClick={handleDelete} cursorPointer />
      </CustomRow>
    </PopupWrapper>
  );
};
