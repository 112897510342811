import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import styled from 'styled-components';

export const SPromoCloseModal = styled(CustomModal)`
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 160px;
      margin-bottom: 15px;
    }
  }
`;
