import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserMutation } from 'store/user/users.api';
import exclamation from 'assets/images/exclamationBig.png';
import { lsGetItem } from 'utils/helpers/storage';
import { mobileApiCall } from 'services/mobileService';
import { Title, BodyText } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { SList, SListItem } from 'components/general/Modals/AddStuffSaveAccountModal/AddStuffSaveAccountModal.styles';
import { ROUTES } from 'vars/const/ROUTES';
import { deleteUserData, selectSystemProperties } from 'store/user/authentication.slice';
import { formatPhone } from 'utils/helpers/phone';
import { useAccounts } from 'utils/hooks/useAccounts';
import { SCustomModal, SDeleteAccountModalBody, SLink } from './DeleteAccountModal.styles';

interface IDeleteAccountModal {
  open: boolean;
  onClose: () => void;
}

export const DeleteAccountModal: React.FC<IDeleteAccountModal> = ({ open, onClose }) => {
  const { t } = useTranslation('profile');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { internalAccounts } = useAccounts();
  const { supportPhoneNumber } = useSelector(selectSystemProperties);
  const supportTelVal = useMemo(() => `tel:${formatPhone(supportPhoneNumber)}`, [supportPhoneNumber]);
  const hasCashAccounts = internalAccounts?.some((acc) => acc?.type === 'Cash');

  const [deleteUser] = useDeleteUserMutation();
  const isMobileApp = lsGetItem('isMobileApp');

  const handleLogout = async () => {
    await deleteUser({});
    if (isMobileApp) {
      mobileApiCall('accountDeleted');
    }
    navigate(ROUTES.registration.path);
    onClose();
    dispatch(deleteUserData());
  };

  return (
    <SCustomModal open={open} onCancel={onClose} modalName="deleteAccount" topPosition="8%" padding="16px 24px 24px" display="none">
      <SDeleteAccountModalBody>
        <div className="center-image-wrapper">
          <img src={exclamation} alt="exclamation" />
        </div>

        <Title className="modal-title" fontWeight="M" color="charcoal" font="Poppins" size="M" marginBottom={16}>
          {t('profile.DeleteAccount')}
        </Title>

        {hasCashAccounts ? (
          <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight="R" lineHeight={1.4} marginTop={10} marginBottom={10} paddingLeft={10} paddingTop={5}>
            {t('profile.There is an open Cash Account')} <SLink href={supportTelVal}>{supportPhoneNumber}.</SLink>
          </BodyText>
        ) : (
          <>
            <BodyText className="text-you-will" textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight="B" marginBottom={8} paddingLeft={10}>
              {t('profile.YouWill')}
            </BodyText>

            <SList>
              <SListItem>{t('profile.LoseProfileData')}</SListItem>
              <SListItem>{t('profile.LosePercapitaProgress')}</SListItem>
              <SListItem>{t('profile.ContinueReceive')}</SListItem>
              <SListItem>{t('profile.CompleteAgain')}</SListItem>
            </SList>

            <BodyText textType="bodyText" font="DM Sans" color="charcoal" size="N" fontWeight="B" paddingLeft={10} marginBottom={32}>
              {t('profile.AreYouSure')}
            </BodyText>
          </>
        )}

        <div className="footer">
          <Button preset="red-outlined" size="xl" onClick={onClose}>
            {t('profile.Cancel')}
          </Button>

          <Button preset="red-filled" size="xl" onClick={handleLogout} disabled={hasCashAccounts}>
            {t('profile.Confirm')}
          </Button>
        </div>
      </SDeleteAccountModalBody>
    </SCustomModal>
  );
};
