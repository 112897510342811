import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Title, BodyText } from 'components/general/Typography';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { SLayout, SCustomCard, SListCard } from './FeeSchedulePage.style';

export const FeeSchedulePage = () => {
  const { t } = useTranslation('feeSchedule');

  return (
    <SLayout>
      <Title size="S" fontWeight="SB" color="charcoal" marginBottom={10} marginTop={6}>
        {t(`feeSchedule.FeeSchedule`)}
      </Title>

      <SCustomCard marginTop={24} width="100%" padding="28px 25px 24px">
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start">
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="B" size="N" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.Fee`)}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="N" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.Amount`)}
          </BodyText>
        </CustomRow>
      </SCustomCard>
      <SListCard marginTop={-24} width="100%">
        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.MonthlyFee`)}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $0.00
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ATMWithdrawalFee`)}
          </BodyText>
          <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $0.00
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.InNetwork`)}
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal70" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.InNetworkRefers`)}
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ATMWithdrawalFee`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $2.50
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.OutOfNetwork`)}
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal70" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.MoneyPassOrVisaPlusAlliance`)}
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ATMBalanceInquiry`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $0.00
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.OverdraftFee`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $0.00
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" className="bordered" alignItems="start" justifyContent="flex-start" paddingBottom={24} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.MobileCheckCapture`)}
            <BodyText textType="bodyText" color="charcoal" marginTop={6} fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
              {t(`feeSchedule.MoneyInMinutes`)}
            </BodyText>
            <BodyText textType="bodyText" color="charcoal70" marginTop={18} fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
              {t(`feeSchedule.CheckFunds1`)}
            </BodyText>
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal70" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            <Trans i18nKey="feeSchedule.Percents0" ns="feeSchedule" />
            <BodyText textType="bodyText" marginTop={18} fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
              <Trans i18nKey="feeSchedule.Percents5" ns="feeSchedule" />
            </BodyText>
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.MobileCheckCapture`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $0.00
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.MoneyIn10Days`)}
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal70" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.CheckFunds1`)}
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ForeignTransactionFee`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            1%
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal70" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.AppliesToDebitCardTransactions`)}
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.StopPayment`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $15.00
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ReplacementCard`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $0.00
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start">
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.StandardShipping`)}
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" className="bordered" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.UpTo10Days`)}
          </BodyText>
        </CustomRow>

        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={12} paddingTop={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="M" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ExpeditedShipping`)}
          </BodyText>
          <BodyText textType="bodyText" className="second-column" color="charcoal" fontWeight="B" size="T" font="DM Sans" lineHeight={1.4}>
            $20.00
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start">
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.ExpeditedShipping`)}
          </BodyText>
        </CustomRow>
        <CustomRow flexDirection="row" width="100%" justifyContent="flex-start" paddingBottom={24}>
          <BodyText textType="bodyText" className="first-column" color="charcoal" fontWeight="R" size="T" font="DM Sans" lineHeight={1.4}>
            {t(`feeSchedule.UpTo3Days`)}
          </BodyText>
        </CustomRow>
      </SListCard>
    </SLayout>
  );
};
