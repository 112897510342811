import styled, { css } from 'styled-components';
import { getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SContainer = styled.div`
  .divider {
    height: 1.5px;
    background: ${getColor('beige')};
    margin: 0 -20px;
    width: inherit;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 24px 30px 0 0;

    ${mediaUpTo(
      'mobile',
      css`
        span,
        .custom-text-inner {
          font-size: 12px !important;
        }
      `
    )}

    ${mediaFrom(
      321,
      css`
        .custom-text-inner {
          font-size: 14px;
        }
      `
    )}

    ${mediaFrom(
      577,
      css`
        .custom-text-inner {
          color: ${getColor('charcoal')};
          font-size: 16px;
        }
      `
    )}
  }
`;
