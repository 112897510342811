import React from 'react';
import { SCustomRow, SBodyText } from './HeadRow.styles';

interface IHeadRowProps {
  col1: string;
  col2: string;
}

export const HeadRow = ({ col1, col2 }: IHeadRowProps) => (
  <SCustomRow alignItems="center">
    <SBodyText size="N" fontWeight="B" lineHeight={1.6}>
      {col1}
    </SBodyText>
    <SBodyText size="N" fontWeight="B" lineHeight={1.6}>
      {col2}
    </SBodyText>
  </SCustomRow>
);
