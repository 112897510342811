import { useSelector } from 'react-redux';
import { selectCurrentAuthState } from 'store/user/authentication.slice';
import { areEqualUsers, canHavePercapitaPayOrEnroll, USER_PROFILE_IDS } from 'vars/const/USER_PROFILE_IDS';

export const useUserStatus = () => {
  const { user, thirdPartyIds } = useSelector(selectCurrentAuthState);

  const hasPercapitaPayOrCanEnroll = user?.systemProfileId && canHavePercapitaPayOrEnroll(user.systemProfileId);
  const hasPercapitaPay = hasPercapitaPayOrCanEnroll && !!thirdPartyIds?.Immediate;
  const canErollToPercapitaPay = hasPercapitaPayOrCanEnroll && !thirdPartyIds?.Immediate;

  return {
    isClient: areEqualUsers(user?.systemProfileId ?? '', USER_PROFILE_IDS.CLIENT_ccc),
    isProspect: areEqualUsers(user?.systemProfileId ?? '', USER_PROFILE_IDS.PROSPECT_fd3),
    hasPercapitaPay,
    canEnrollToPercapitaPay: canErollToPercapitaPay,
  };
};
