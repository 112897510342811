import styled, { css } from 'styled-components';
import { SCREEN_SIZE, getColor, mediaFrom, mediaUpTo } from 'utils/helpers/styleHelpers';

export const SCardLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 0;
  cursor: pointer;
  width: 100%;

  .title-container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    gap: 15px;

    .custom-text-inner {
      font-size: 12px;
    }

    ${mediaFrom(
      321,
      css`
        .custom-text-inner {
          font-size: 14px !important;
        }
      `
    )}

    ${mediaFrom(
      577,
      css`
        .custom-text-inner {
          font-size: 16px !important;
        }
      `
    )}

    .start-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      padding: 12px;
      background: ${getColor('orange5')};
      border-radius: 24px;
      flex: 0 0 auto;

      ${mediaUpTo(
        'tablet',
        css`
          width: 36px;
          height: 36px;
          padding: 8px;
          border-radius: 16px;
        `
      )}

      ${mediaFrom(
        SCREEN_SIZE.tablet,
        css`
          background: none;
        `
      )};
    }
  }

  .amount-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 17px;

    .currency,
    .group,
    .integer {
      font-size: 12px;
      font-weight: 700;
    }

    .decimal,
    .fraction {
      font-size: 12px !important;
      font-weight: 700;
    }

    ${mediaFrom(
      321,
      css`
        .currency,
        .group,
        .integer {
          font-size: 14px;
          font-weight: 700;
        }

        .decimal,
        .fraction {
          font-size: 14px !important;
          font-weight: 700;
        }
      `
    )}

    ${mediaFrom(
      577,
      css`
        .currency,
        .group,
        .integer {
          font-size: 16px;
        }

        .decimal,
        .fraction {
          font-size: 16px !important;
        }
      `
    )}
  }
`;
