import React from 'react';
import { Trans } from 'react-i18next';
import { Loader } from 'components/general/Loader/Loader';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { SLayout } from './AccountFeatureLayout.styles';

interface IAccountFeatureLayoutProps {
  isLoading?: boolean;
  header: string;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

export const AccountFeatureLayout = ({ isLoading, header, title, subtitle, children }: IAccountFeatureLayoutProps) => {
  return (
    <SLayout>
      {isLoading && <Loader />}
      <PageHeader title={header} />

      <div className="container">
        <div className="content">
          <div className="title-container">
            <span className="title">{title}</span>
            <span className="description">
              <Trans i18nKey={subtitle} ns="cashCushion" />
            </span>
          </div>
          <div className="main-content">{children}</div>
        </div>
      </div>
    </SLayout>
  );
};
