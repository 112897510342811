import React, { useEffect } from 'react';
import { BodyText, Title } from 'components/general/Typography';
import { Icon } from 'components/general/Icon/Icon';
import { Button } from 'components/theme/Button/Button';
import { SuttonDisclaimerNote } from 'components/general/DisclaimerNote/SuttonDisclaimerNote';
import { Loader } from 'components/general/Loader/Loader';
import { ICountryRequestParams } from 'views/MoveMoney/InternationalTransferPage/types';
import { DEFAULT_SURVEY_ID } from 'views/MoveMoney/InternationalTransferPage/constants';
import { useLazyGetSurveyQuery } from 'store/user/users.api';
import { SCustomSheet } from './ICantSelectMyCountrySheet.styles';

interface ISheetProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onSelectDestinationClick: () => void;
  setCountryRequestParams: (data: ICountryRequestParams) => void;
}

export const ICantSelectMyCountrySheet: React.FC<ISheetProps> = ({ isOpen, onClose, onBack, onSelectDestinationClick, setCountryRequestParams }) => {
  const [getSurvey, { data: surveyData, isLoading, isFetching, isSuccess }] = useLazyGetSurveyQuery();

  useEffect(() => {
    if (isOpen) {
      getSurvey(DEFAULT_SURVEY_ID)
        .unwrap()
        .then(({ surveyQuestions }) => {
          const surveyQuestionId = surveyQuestions?.[0]?.id ?? '';
          setCountryRequestParams({ surveyId: DEFAULT_SURVEY_ID, surveyQuestionId });
        });
    }
  }, [isOpen]);

  return (
    <SCustomSheet isOpen={isOpen} onClose={onClose} paddingTop="36px" minHeight="45%" paddingBottom="48px">
      {(isFetching || isLoading) && <Loader />}

      {isSuccess && (
        <>
          <div className="header">
            <Icon name="arrowLeft" color="charcoal" onClick={onBack} cursorPointer size="small" marginRight={12} />
            <Title>{surveyData?.title}</Title>
          </div>

          <BodyText size="N" fontWeight="R" color="charcoal70" lineHeight={1.4} marginBottom={32}>
            {surveyData?.text}
          </BodyText>

          <BodyText size="T" fontWeight="SB" font="Poppins" color="charcoal" lineHeight={1.4} marginBottom={16}>
            {surveyData?.surveyQuestions?.[0]?.text}
          </BodyText>

          <Button className="button-select-destination" onClick={onSelectDestinationClick}>
            <div className="text">{surveyData?.surveyQuestions?.[0]?.hint}</div>
            <Icon name="chevronRight" color="charcoal" size="smallest" />
          </Button>

          <SuttonDisclaimerNote />
        </>
      )}
    </SCustomSheet>
  );
};
