import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'vars/const/ROUTES';
import { useTranslation } from 'react-i18next';
import { BodyText, Title } from 'components/general/Typography';
import { Header } from 'views/OpenCashAccount/MyInfo/Header/Header';
import { JointAccountsForm } from './JointAccountsForm/JointAccountsForm';
import { SLayout } from './JointAccounts.styles';

export const JointAccountsPage = () => {
  const { t } = useTranslation('cashAccountOpening');
  const location = useLocation();
  const isFromTaxIdPage = location.state?.backPage?.includes(ROUTES.myInfoTaxId.path);
  const isAccountSetup = location.state?.isAccountSetup || false;
  const isFromMyDetailsPage = location.state?.isAccountSetup || false;
  const { isUserUnder18, fromPrep } = location.state ?? false;

  return (
    <SLayout>
      <Header
        title={t((!isUserUnder18 && !fromPrep) || isAccountSetup ? 'accountOpening.AddJointAccountholder' : 'accountOpening.ParentGuardianInformation')}
        stage={fromPrep || isFromTaxIdPage ? '' : 'Join Accountholder'}
        webStage="Details"
        marginBottom={28}
        fontWeight={isAccountSetup && !isFromMyDetailsPage ? 'M' : 'SB'}
        size={isAccountSetup && !isFromMyDetailsPage ? 'S' : 'M'}
        titleMarginRight={isAccountSetup ? 32 : 0}
        showExit
      />
      <div className="content-container">
        <div className="mobile-title-container">
          {isUserUnder18 || fromPrep ? (
            <Title fontWeight="M" size="S" font="DM Sans" paddingRight={20}>
              {t(`accountOpening.SinceYouAreUnder18`)}
            </Title>
          ) : (
            <Title fontWeight="M" size="S" marginBottom={10}>
              {t('accountOpening.Your joint accountholder will have to complete the application process as well.')}
            </Title>
          )}

          {isUserUnder18 || fromPrep ? (
            <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal60" marginTop={15} marginBottom={32} lineHeight={1.6} paddingRight={5}>
              {t(`accountOpening.IsUnder18Subtitle`)}
            </BodyText>
          ) : (
            <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" marginBottom={16}>
              {t(`accountOpening.Enter their contact information below and we will send them an invitation.`)}
            </BodyText>
          )}
        </div>

        <div className="web-title-container">
          <Title fontWeight="SB" size="S" marginBottom={10}>
            {(!isUserUnder18 && !fromPrep) || isAccountSetup ? t('accountOpening.AddJointAccountholder') : t('accountOpening.ParentGuardianInformation')}
          </Title>

          {isUserUnder18 || fromPrep ? (
            <Title fontWeight="SB" size="T" font="DM Sans" color="charcoal70" paddingRight={20}>
              {t(`accountOpening.SinceYouAreUnder18`)}
            </Title>
          ) : (
            <Title fontWeight="SB" size="T" color="charcoal70" marginBottom={10}>
              {t('accountOpening.Your joint accountholder will have to complete the application process as well.')}
            </Title>
          )}

          {isUserUnder18 || fromPrep ? (
            <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal60" marginTop={15} marginBottom={32} lineHeight={1.6} paddingRight={20}>
              {t(`accountOpening.IsUnder18Subtitle`)}
            </BodyText>
          ) : (
            <BodyText textType="bodyText" fontWeight="R" size="N" color="charcoal70" marginBottom={16}>
              {t(`accountOpening.Enter their contact information below and we will send them an invitation.`)}
            </BodyText>
          )}
        </div>

        <JointAccountsForm isArrow={isAccountSetup} isAccountSetup={isAccountSetup} isUserUnder18={isUserUnder18 || fromPrep} fromPrep={fromPrep} />
      </div>
    </SLayout>
  );
};
