import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Button } from 'components/theme/Button/Button';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { Icon } from 'components/general/Icon/Icon';
import { BodyText, Title } from 'components/general/Typography';
import { Loader } from 'components/general/Loader/Loader';
import { ACCOUNT_TYPES } from 'views/PercapitaPay/constants/accountTypes';
import { useLanguage } from 'utils/hooks/useLanguage';
import { CurrencyFormatters } from 'utils/helpers/CurrencyFormatters';
import { CustomModal } from 'components/theme/CustomModal/CustomModal';
import { SContainer } from './PaymentConfirmModal.styles';

interface IPaymentConfirmModal {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  checkDate: string;
  accountInfo: any;
  amountToTransfer: string | number;
  fee: string | number;
  amountSelected: string | number;
  estimatedDate: string;
  handleCloseStatusModal: () => void;
  handleRequestPayment: () => void;
  toggleRequestPercPay: () => void;
  isPaymentRequestStillOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentConfirmModal: React.FC<IPaymentConfirmModal> = ({
  isOpen,
  onClose,
  isLoading,
  checkDate,
  accountInfo,
  amountToTransfer,
  fee,
  amountSelected,
  estimatedDate,
  handleCloseStatusModal,
  handleRequestPayment,
  toggleRequestPercPay,
  isPaymentRequestStillOpen,
}) => {
  const { t } = useTranslation('percapitaPay');
  const theme = useTheme();
  const { locale } = useLanguage();
  const centPrecisionFormatter = CurrencyFormatters.getCentPrecisionFormatter(locale);
  const { details, accountType, alias } = accountInfo;
  const cardLastDigits = details?.slice(-4, details?.length);
  const aliasAdditional = `(${ACCOUNT_TYPES[accountType]} ${cardLastDigits})`;
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const handleConfirm = () => {
    handleRequestPayment();
    toggleRequestPercPay();
    isPaymentRequestStillOpen(false);
  };

  const handleInfoClick = () => {
    isPaymentRequestStillOpen(true);
    setIsInfoOpen(!isInfoOpen);
  };

  const closeModal = () => {
    handleCloseStatusModal();
    onClose();
  };

  return (
    <CustomModal open={isOpen} closeIconColor="charcoal70" closable modalName="percapitaPay_paymentConfirmModal" topPosition="0" width="50%" onClose={closeModal}>
      <Title font="Poppins" color="charcoal" marginTop={10} marginBottom={40} fontWeight="M" size="XL">
        {t('percapitaPayHome.Percapita Pay Review')}
      </Title>

      {isLoading && <Loader />}
      <SContainer>
        <div className="flex-column">
          <div className="space-between">
            <div className="flex-start request">
              <BodyText marginRight={9} color="charcoal" textType="bodyText" size="N" fontWeight="R">
                {t('percapitaPayHome.Amount Requested')}
              </BodyText>

              <Icon name="info" color="blue" size="smaller" cursorPointer onClick={handleInfoClick} />
              <CustomCard className={`info-tips ${isInfoOpen ? 'show' : 'hide'}`}>
                <BodyText marginBottom={4} color="charcoal" textType="bodyText" size="N" fontWeight="B">
                  {t('percapitaPayHome.HowWork')}
                </BodyText>
                <BodyText color="charcoal70" textType="bodyText" size="N" fontWeight="R">
                  {t('percapitaPayHome.AmountYouRequested')}
                </BodyText>
              </CustomCard>
            </div>

            <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="B">
              {centPrecisionFormatter.format(Number(amountSelected))}
            </BodyText>
          </div>

          <div className="space-between">
            <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R">
              {t('percapitaPayHome.Account')}
            </BodyText>

            <div className="flex-end">
              <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="B" marginRight={4}>
                {alias}
              </BodyText>

              <BodyText color="charcoal70" textType="bodyText" size="T" fontWeight="R">
                {aliasAdditional}
              </BodyText>
            </div>
          </div>

          <div className="space-between">
            <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R">
              {t('percapitaPayHome.Date')}
            </BodyText>

            <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="B">
              {format(new Date(), 'MMM dd, yyyy')}
            </BodyText>
          </div>
        </div>

        <CustomCard border={`2px solid ${theme.charcoal5}`}>
          <BodyText color="charcoal" textType="bodyText" size="M" fontWeight="B" marginBottom={30}>
            {t('percapitaPayHome.Transaction Receipt')}
          </BodyText>

          <div className="flex-column">
            <div className="space-between">
              <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R">
                {t('percapitaPayHome.TransferAmount')}
              </BodyText>

              <BodyText color="green" textType="bodyText" size="N" fontWeight="B">
                {centPrecisionFormatter.format(Number(amountToTransfer))}
              </BodyText>
            </div>

            <div className="space-between">
              <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R">
                {t('percapitaPayHome.Early Access Fee')}
              </BodyText>

              <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="B">
                {centPrecisionFormatter.format(Number(fee))}
              </BodyText>
            </div>

            {estimatedDate.length && (
              <div className="space-between">
                <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R">
                  {t('percapitaPayHome.Estimated Delivery Time')}
                </BodyText>

                <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="B">
                  {format(parseISO(estimatedDate), 'PP')}
                </BodyText>
              </div>
            )}

            {checkDate.length && (
              <div className="space-between">
                <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R" paddingRight={20}>
                  {t('percapitaPayHome.Deducted From Next Paycheck On')}
                </BodyText>

                <BodyText color="charcoal" textType="bodyText" size="N" fontWeight="R">
                  {format(parseISO(checkDate), 'PP')}
                </BodyText>
              </div>
            )}
          </div>
        </CustomCard>

        <BodyText color="charcoal70" textType="bodyText" size="T" fontWeight="R" textAlign="center" justifyContent="center" marginTop={24} marginBottom={24}>
          {t('percapitaPayHome.This transaction will appear ...')}
        </BodyText>

        <div className="center">
          <Button preset="blue-filled" size="middleStretch" onClick={handleConfirm}>
            {t('percapitaPayHome.Continue')}
          </Button>
        </div>
      </SContainer>
    </CustomModal>
  );
};
